

















































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { router } from '@/router';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';

import SettingsStore from '@/modules/settings/settings.store';
import AccountStore from '@/store/account.store';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ProfileAgencyApi } from '@/api/profile/agency.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import { Permission } from '@/const/permission.enum';
import { CreateCompanyMessage, UpdateCompanyMessage, AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required, email, requiredIf } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import moment from 'moment';
import { SearchInitModes, TravellersSelectionModes } from '@/api/profile/profile.model';

interface CompanyModelForm {
  name: string;
  code: string;
  id: string;
  isDisabled: any;
  parentId: string | null;
  rootId: string;
  isSelected: boolean;
  phoneNumber: string;
  faxNumber: string;
  address: string;
  emailAddress: string;
  isAgency?: boolean;
  shortDateFormat?: string | null;
  timeFormat?: string | null;
  defaultLanguage?: string | null;
  defaultSearchMode?: SearchInitModes;
  invoicingCode?: string | null;
  travellersSelectionMode?: TravellersSelectionModes;
}

const phoneRegex = value => {
  if (value === null || value === '') {
    return true;
  }
  return /^\+?(?:\d●?)*\d$/.test(value);
};


@Component({})
export default class CompanyStructureAddEdit extends Vue {
  companyData: CompanyModelForm | null = null;
  loading: boolean = false;
  formPending: boolean = false;
  isAgencyAssignedLoading: boolean = false;
  Form: UpdateCompanyMessage = {
    name: null,
    code: null,
    address: '',
    phoneNumber: '',
    faxNumber: '',
    emailAddress: '',
    parentId: null,
    isDisabled: {
      value: false
    },
    agencyAssigned: null,
    shortDateFormat: null,
    timeFormat: null,
    defaultLanguage: null,
    defaultSearchMode: SearchInitModes.EnterNames,
    invoicingCode: null,
    travellersSelectionMode: TravellersSelectionModes.ByScope,
  };
  agencyList: any[] = [];
  filterAgencyList: any[] = [];
  isParentCompanyLoading: boolean = false;
  parentCompanyOptions = [];
  selectedParentCompany: any = null;
  serverErrors: any[] = [];
  $v;
  selectedShortDateFormat: any = null;
  selectedTimeFormat: any = null;
  delayTimer: any | undefined = null;
  languageOptions: LanguagesModel[] = [];

  availableSearchModes = [
    {
      label: translate('settings-company.enter-traveller-names'),
      value: SearchInitModes.EnterNames,
    },
    {
      label: translate('settings-company.book-for-guests'),
      value: SearchInitModes.SelectGuests,
    },
    {
      label: translate('settings-company.book-for-guests-with-no-company-selection'),
      value: SearchInitModes.SelectGuestsWithNoCompanySelection,
    },
  ];
  availableTravellersSelectionModes = [
    {
      label: translate('settings-company.selection-mode-by-scope'),
      value: TravellersSelectionModes.ByScope,
    },
    {
      label: translate('settings-company.selection-mode-by-custom-fields'),
      value: TravellersSelectionModes.ByCustomFields,
    },
    /* remove comment when ByDesignatedTravellers is implemented
    {
      label: translate('settings-company.selection-mode-by-designated-travellers'),
      value: TravellersSelectionModes.ByDesignatedTravellers,
    },
    */
  ];
  availableDateFormats = [
    {
      label: AvailableDateFormats.AvailableDateFormat1 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat1) + ')',     
      value: AvailableDateFormats.AvailableDateFormat1,
    },
    {
      label: AvailableDateFormats.AvailableDateFormat2 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat2) + ')' ,     
      value: AvailableDateFormats.AvailableDateFormat2,
    },
    {
      label: AvailableDateFormats.AvailableDateFormat3 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat3) + ')',     
      value: AvailableDateFormats.AvailableDateFormat3,
    },

    {
      label: AvailableDateFormats.AvailableDateFormat4 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat4) + ')',    
      value: AvailableDateFormats.AvailableDateFormat4,
    },        
  ];
  availableTimeFormats = [
    {
      label: AvailableTimeFormats.AvailableTimeFormat1 + ' (' + moment().format(AvailableTimeFormats.AvailableTimeFormat1) + ')',
      value: AvailableTimeFormats.AvailableTimeFormat1,
    },
    {
      label: AvailableTimeFormats.AvailableTimeFormat2 + ' (' + moment().format(AvailableTimeFormats.AvailableTimeFormat2) + ')',
      value: AvailableTimeFormats.AvailableTimeFormat2,
    }      
  ];

  get configurationId() {
    return this.$route.params.configurationId;
  }

  get isEdit() {
    return this.$route.query.edit === 'true';
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get canWriteAgencyAssignment() {
    return AccountStore.HasPermission('WriteAgencyAssignment');
  }

  get canReadCompanyInfo() {
    return AccountStore.HasPermission('ReadCompanyInfo');
  }

  get canWriteCompanyInfo() {
    return AccountStore.HasPermission('WriteCompanyInfo');
  }

  get isAgency() {
    if (this.companyData) {
      return this.companyData.isAgency;
    }
    return false;
  }

  get language() {
    if (this.languageOptions) {
      if (this.Form && this.Form.defaultLanguage) {
        return this.languageOptions.find(option => option.code === this.Form.defaultLanguage);
      } else if (this.companyData && this.companyData.defaultLanguage) {
        return this.languageOptions.find(option => option.code === this.companyData!.defaultLanguage);
      } else {
        return this.languageOptions.find(option => option.code === 'en');
      }
    }
  }

  set language(option) {
    if (option && this.Form) {
      this.Form.defaultLanguage = option.code;
    }
  }

  get selectedSearchMode() {
    return this.availableSearchModes.find(item => item.value === this.Form.defaultSearchMode);
  }

  set selectedSearchMode(value) {
    if (!value) {
      return;
    }
    this.Form.defaultSearchMode = value.value;
  }

  get selectedTravellersSelectionMode() {
    return this.availableTravellersSelectionModes.find(item => item.value === this.Form.travellersSelectionMode);
  }

  set selectedTravellersSelectionMode(value) {
    if (!value) {
      return;
    }
    this.Form.travellersSelectionMode = value.value;
  }

  async updateData() {
    if (this.configurationId) {
      if (this.isEdit && this.companyData) {
        this.Form.name = this.companyData.name;
        this.Form.code = this.companyData.code;
        this.Form.isDisabled = this.companyData.isDisabled;
        this.Form.parentId = this.companyData.parentId;
        this.Form.phoneNumber = this.companyData.phoneNumber;
        this.Form.emailAddress = this.companyData.emailAddress;
        this.Form.address = this.companyData.address;
        this.Form.faxNumber = this.companyData.faxNumber;
        this.Form.isDisabled = { value: this.companyData.isDisabled };
        this.Form.invoicingCode = this.companyData.invoicingCode;
        this.Form.defaultSearchMode = this.companyData.defaultSearchMode;
        this.Form.travellersSelectionMode = this.companyData.travellersSelectionMode;

        if (this.companyData.timeFormat) { 
          this.selectedTimeFormat = this.availableTimeFormats.find(availableTimeFormat => { return availableTimeFormat.value === this.companyData!.timeFormat; });
        } else {
          this.selectedTimeFormat = this.availableTimeFormats[0];
        }
        if (this.companyData.shortDateFormat) { 
          this.selectedShortDateFormat = this.availableDateFormats.find(availableDateFormat => { return availableDateFormat.value === this.companyData!.shortDateFormat; });
        } else {
          this.selectedShortDateFormat = this.availableDateFormats[0];
        }
      } else {
        this.Form.name = '';
        this.Form.code = '';
        this.Form.parentId = this.companyData ? this.companyData.id : '';
        this.selectedTimeFormat = this.availableTimeFormats[0];
        this.selectedShortDateFormat = this.availableDateFormats[0];
      }
    }
  }

  tooltip() {
    if (this.selectedParentCompany) {
      return `
        <span> ${this.selectedParentCompany.name} (${this.selectedParentCompany.code})</span>`;
    }
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        parentId: {},
        name: { 
          required,
          maxLength: maxLength(128),
        },
        code: {
          required,
          maxLength: maxLength(32),
        },
        address: {
          maxLength: maxLength(512),
        },
        phoneNumber: {
          required: requiredIf(() => {
            return this.companyData && this.companyData.isAgency;
          }),
          maxLength: maxLength(20),
          phoneRegex,
        },
        invoicingCode: {
          maxLength: maxLength(128)
        },
        faxNumber: {
          maxLength: maxLength(30),
          phoneRegex,
        },
        emailAddress: {
          required: requiredIf(() => {
            return this.companyData && this.companyData.isAgency;
          }),
          maxLength: maxLength(320),
          email,
        },
        isDisabled: {},

      },
      selectedShortDateFormat: {
        required,
      },
      selectedTimeFormat: {
        required,
      },
      selectedSearchMode: {
        required,
      },
      selectedTravellersSelectionMode: {
        required,
      },
      language: {}
    };
  }

  returnToConfigurations() {
    router.push({
      name: 'company-structure-configurations'
    });
  }

  searchAgency(phrase: string) {
    this.serverErrors = [];
    this.findAgencyAssigned(phrase);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isAgencyAssignedLoading',
  })
  async findAgencyAssigned(phrase: string) {
    this.isAgencyAssignedLoading = true;
    try {
      const agencies = await ProfileAgencyApi.getAgencies(phrase);
      if (phrase.length > 0 && agencies && agencies.data && agencies.data.length) {
        this.filterAgencyList = agencies.data;
      } else if (phrase.length === 0 && agencies && agencies.data && agencies.data.length) {
        this.filterAgencyList = agencies.data;
      } else {
        this.filterAgencyList = [];
      }
      this.isAgencyAssignedLoading = false;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.isAgencyAssignedLoading = false;
    }
  }

  async addSubUnit() {
    this.$v.Form.$touch();
    this.$v.selectedShortDateFormat.$touch();
    this.$v.selectedTimeFormat.$touch();

    if (this.$v.Form.$pending || this.$v.Form.$error ||
      this.$v.selectedShortDateFormat.$anyError ||
      this.$v.selectedTimeFormat.$anyError
    ) {
      return;
    }
    this.serverErrors = [];
    try {
      this.formPending = true;
      if (this.isEdit) {
        this.Form.shortDateFormat = this.selectedShortDateFormat.value;
        this.Form.timeFormat = this.selectedTimeFormat.value;

        let request = {
          ...this.Form,
          isDisabled: this.Form.isDisabled.value,
        };
        await ProfileCompanyApi.updateCompany(request, this.configurationId, Permission.WriteCompanyStructure);
        this.returnToConfigurations();
      } else {
        let message = new CreateCompanyMessage();
        message.name = this.Form.name!;
        message.code = this.Form.code!;
        message.phoneNumber = this.Form.phoneNumber;
        message.emailAddress = this.Form.emailAddress;
        message.address = this.Form.address;
        message.faxNumber = this.Form.faxNumber;
        message.parentId = this.Form.parentId;
        message.isDisabled = this.Form.isDisabled ? this.Form.isDisabled.value : true;
        message.shortDateFormat = this.selectedShortDateFormat.value;
        message.timeFormat = this.selectedTimeFormat.value;
        message.defaultLanguage = this.language ? this.language.code : 'en';
        message.defaultSearchMode = this.Form.defaultSearchMode;
        message.invoicingCode = this.Form.invoicingCode;
        message.travellersSelectionMode = this.Form.travellersSelectionMode;

        await ProfileCompanyApi.createCompany(message);
        this.returnToConfigurations();
      }
      const obj = {
        type: translate('common.success'),
        title: translate('common.data-saved'),
        message: translate('common.saved')
      };
      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }

  async loadLanguages() {
    if (this.$hasAccess('WriteCompanyInfo')) {
      try {
        const result = await LanguagesApi.getLanguages();
        if (result && result.data) {
          this.languageOptions = result.data;
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    }
  }

  async saveAgencyAssignment() {
    this.serverErrors = [];
    try {
      let params = {
        agencyId: this.Form.agencyAssigned!.id
      };

      await ProfileApi.assignedAgency(this.companyData!.id, params);
      const obj = {
        type: translate('common.success'),
        title: translate('common.data-saved'),
        message: translate('common.saved')
      };
      EventHandler.$emit('show-toast', obj);
      await this.loadData();      
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  focusOnButton() {
    setTimeout(() => {
      this.$forceUpdate();
      ((this.$refs.saveAgencyAssignmentButton as Vue).$refs.button as HTMLElement).focus({preventScroll: true});
    });
  }

  changeParentCompanyId(company) {
    this.Form.parentId = company.id;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isParentCompanyLoading'
  })
  async findParentCompanies(query: string) {
    if (query && query.length > 1) {
      this.isParentCompanyLoading = true;
      const response = await ProfileCompanyApi.getCompanyUnits(this.companyData!.rootId, query, Permission.ReadCompanyInfo);

      if (response && response.data) {
        this.parentCompanyOptions = [];
        this.parentCompanyOptions = response.data;
      }

      this.isParentCompanyLoading = false;
    } else {
      this.isParentCompanyLoading = false;
      this.parentCompanyOptions = [];
    }
  }

  async loadData() {
    if (this.currentCompany) {
      this.serverErrors = [];
      try {
        this.loading = true;
        const result = await ProfileCompanyApi.getById(this.configurationId, Permission.AccessSettings);

        if (result && result.data) {
          this.companyData = result.data;
          SettingsStore.setIsAgency(result.data.isAgency);
          await this.updateData();
        }

        if (this.companyData && this.companyData.id && this.canWriteAgencyAssignment) {
          const agencies = await ProfileAgencyApi.getAgencies('');
          if (agencies && agencies.data) {
            this.agencyList = agencies.data;
          }

          this.isAgencyAssignedLoading = true;
          const selectedAgency = await ProfileApi.getAssignedAgency(this.companyData.id);
          if (selectedAgency && selectedAgency.data && selectedAgency.data !== '') {
            this.Form.agencyAssigned = selectedAgency.data;
            if (!this.companyData.parentId) {
              SettingsStore.setAgencyAssigned(selectedAgency.data);
            }
          } else {
            this.Form.agencyAssigned = this.filterAgencyList[0];
            if (!this.companyData.parentId) {
              SettingsStore.setAgencyAssigned(null);
            }
          }
          this.isAgencyAssignedLoading = false;
        }

        if (this.companyData && (this.companyData.parentId || (!this.isEdit && this.companyData.id))) {
          let id = this.isEdit ? this.companyData.parentId : this.companyData.id;
          const companyResponse = await ProfileCompanyApi.getById(id, Permission.ReadCompanyInfo);
          if (companyResponse && companyResponse.data) {
            this.selectedParentCompany = companyResponse.data;
            if (!this.isEdit) {
              this.Form.shortDateFormat = this.selectedParentCompany.shortDateFormat;
              this.Form.timeFormat = this.selectedParentCompany.timeFormat;
              this.Form.defaultSearchMode = this.selectedParentCompany.defaultSearchMode;
              this.Form.defaultLanguage = this.selectedParentCompany.defaultLanguage;
              this.selectedShortDateFormat = this.availableDateFormats
                .find(availableDateFormat => availableDateFormat.value === this.Form.shortDateFormat);
              this.selectedTimeFormat = this.availableTimeFormats
                .find(availableTimeFormat => availableTimeFormat.value === this.Form.timeFormat);
              this.Form.travellersSelectionMode = this.selectedParentCompany.travellersSelectionMode;
            }
          }
        }

        this.loading = false;
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
        this.isAgencyAssignedLoading = false;
      }
    }
  }

  async created() {
    await this.loadLanguages();
    await this.loadData();
  }
}
