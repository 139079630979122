



































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { userFullName } from '@/core/user-full-name';
import BasketItem from './BasketItem.vue';
import BasketStore from './basket.store';
import BasketCustomFieldsView from './BasketCustomFieldsView.vue';
import BasketHotelPlaceholder from './BasketHotelPlaceholder.vue';
import BasketItineraryEmailPopup from './BasketItineraryEmailPopup.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import hasAccessFn from '@/core/permissions/has-access.service';
import BasketTripHistoryView from './BasketTripHistoryView.vue';
import AccountStore from '@/store/account.store';
import EventBus from '@/services/event-handler';
import BasketApprovalLevelProfileDetails from './BasketApprovalLevelProfileDetails.vue';
import BasketApprovalLevelAnyApprover from './BasketApprovalLevelAnyApprover.vue';
import { WonderSearchApi } from '@/api/wonder-search/wonder-search.api';
import BasketTripCommentsView from './BasketTripCommentsView.vue';
import BasketTripNotes from '@/modules/basket/BasketTripNotes.vue';

@Component({
  components: {
    BasketTripNotes,
    BasketItem,
    BasketCustomFieldsView,
    BasketHotelPlaceholder,
    BasketItineraryEmailPopup,
    BasketExpirationCountdown,
    BasketTripHistoryView,
    BasketApprovalLevelProfileDetails,
    BasketApprovalLevelAnyApprover,
    BasketTripCommentsView,
  }
})
export default class BasketItemsView extends Vue {
  @Prop() basketExpired!: boolean;
  confirming: boolean = false;
  hasCustomFields: boolean = false;
  dateTimeInterval: number = -1;
  currentDateTime: any = moment();
  errors: any[] = [];
  itineraryTab: any = {
    name: 'itinerary',
    counter: null,
  };
  customFieldsTab: any = {
    name: 'custom-fields',
    counter: null,
  };
  tripHistoryTab: any = {
    name: 'trip-history',
    counter: null,
  };
  commentsTab: any = {
    name: 'comments',
    counter: null,
  };
  selectedTab: string = this.itineraryTab;

  get isAgency() {
    return AccountStore.current!.profile.isAgency;
  }

  get basket() {
    return BasketStore.basket;
  }

  get basketStatus() {
    return BasketStore.status && BasketStore.status.status;
  }

  get canShowTripNotes() {
    return this.status && this.status !== 'Draft';
  }

  get showUnprocessedNotificationsInfo() {
    return this.basketItems.some(({ statusTag, type, supplier }) => {
      const isNotificationFromAirline = statusTag === 'NotificationFromAirline';
      const isAir = type === 'Air';
      const isAirFranceKlm = supplier === 'AirFranceKlm';
      const isLufthansa = supplier === 'Lufthansa';
      return isNotificationFromAirline && isAir && (isAirFranceKlm || isLufthansa);
    });
  }

  get tabsWithCounters() {
    return this.tabs.map(tab => {
      if (tab.name === 'comments') {
        return {
          ...tab,
          counter: BasketStore.comments.length,
        };
      }
      return tab;
    });
  }

  get workflowError() {
    return BasketStore.workflowError;
  }

  get hasApprovalWorkflowAction() {
    return BasketStore.hasApprovalWorkflowAction;
  }

  get canShowCustomFieldsInfo() {
    return this.hasApprovalWorkflowAction &&
      this.hasRequiredCustomFields &&
      this.canApproveTrip &&
      this.canValidateTrips;
  }

  get hasRequiredCustomFields() {
    return !!BasketStore.customFields.find(field => field.canBeModified && field.mandatory);
  }

  get canApproveTrip() {
    return BasketStore.canApproveTrip;
  }

  get canValidateTrips() {
    return AccountStore.HasPermission('ValidateTrips');
  }

  get status() {
    return BasketStore.status ? BasketStore.status.status : null;
  }

  get statusTag() {
    return BasketStore.status ? BasketStore.status.statusTag : null;
  }

  get statusData() {
    return BasketStore.status;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get canShowTabs() {
    return this.tabs.length && this.status !== 'Draft';
  }

  get canViewComments() {
    return this.$hasAccess('CanViewTripComments');
  }

  get tabs() {
    let tabs: any[] = [];

    if (this.hasCustomFields) {
      tabs.push(this.customFieldsTab);
    }
    if (this.canViewComments) {
      tabs.push(this.commentsTab);
    }
    if (this.$hasAccess('CanSeeTripHistory')) {
      tabs.push(this.tripHistoryTab);
    }
    if (tabs.length) {
      tabs.splice(0, 0, this.itineraryTab);
    }
    return tabs;
  }

  get shouldShowBookerData() {
    return this.basketBookerData && this.status && this.status !== 'Draft';
  }

  get basketExpirationDate() {
    if (BasketStore.basketExpirationDate) {
      return BasketStore.basketExpirationDate;
    }
  }

  get basketApprovalDeadline() {
    return BasketStore.basketApprovalDeadline;
  }

  get basketApproverData() {
    return BasketStore.basketApproverData;
  }

  get approverData() {
    if (!this.basketApproverData 
      || !(this.basketApproverData.finalApprover || this.basketApproverData.currentApprover)) {
      
      return null;
    }

    const finalApproverData = this.basketApproverData.finalApprover;
    if (finalApproverData) {
      
      return {
        firstName: finalApproverData.firstName,
        middleName: finalApproverData.middleName || '',
        lastName: finalApproverData.lastName,
        businessUnitName: finalApproverData.companyName,
        id: finalApproverData.id,
        level: finalApproverData.level,
      };
    }

    const currentApproverData = this.basketApproverData.currentApprover;

    return {
      firstName: currentApproverData.firstName,
      middleName: currentApproverData.middleName || '',
      lastName: currentApproverData.lastName,
      businessUnitName: currentApproverData.companyName,
      id: currentApproverData.id,
      level: currentApproverData.level,
    };
  }

  get basketBookerData() {
    return BasketStore.basketBookerData;
  }

  get bookerData() {
    if (!this.basketBookerData) {
      return null;
    }
    return {
      firstName: this.basketBookerData.firstName,
      middleName: this.basketBookerData.middleName || '',
      lastName: this.basketBookerData.lastName,
      businessUnitName: this.basketBookerData.companyName,
      id: this.basketBookerData.id,
    };
  }

  get bookerProfileLink() {
    if (this.bookerData) {
      return sanitizeUrl('/profile/' + this.bookerData.id);
    }
    return '';
  }

  get approverProfileLink() {
    if (this.approverData) {
      return sanitizeUrl('/profile/' + this.approverData.id);
    }
    return '';
  }

  get canViewProfile() {
    return this.$hasAccess('CanViewProfile');
  }

  userFullName(user) {
    return userFullName(user);
  }

  dateTime(date) {
    return moment.parseZone(date).format('Do MMMM YYYY, HH:mm') + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  onItemAgencyFeeChange({ itemId, fee }) {
    let itemFound = this.basketItems.find(item => {
      return item.id === itemId;
    });
    if (itemFound && itemFound.type === 'Air') {
      itemFound.price.agencyFee = fee;
    }
  }

  async gotoJourneyAssistant() {
    try {
      const response = await WonderSearchApi.getConversationId(this.$route.params.id);
      this.$router.push({
        name: 'journey-assistant',
        params: {
          conversationId: response.data.conversationId,
        }
      });
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  refreshDateTime() {
    this.currentDateTime = moment();
  }

  switchToCustomFieldsTab() {
    const customFieldsTab = this.tabs.find(tab => tab.name === 'custom-fields');
    if (customFieldsTab) {
      this.selectedTab = customFieldsTab;
    }
  }

  async created() {
    this.dateTimeInterval = setInterval(this.refreshDateTime, 1000);

    if (this.statusData && this.statusData.pendingActions && 0 < this.statusData.pendingActions.length
      && !!this.statusData.pendingActions.find(pendingAction => pendingAction.code === 'START_BOOKING_WIZARD')) {

      await BasketStore.getWizardSteps(true);
    }
    EventBus.$on('switch-to-custom-fields', this.switchToCustomFieldsTab);
  }

  beforeDestroy() {
    clearInterval(this.dateTimeInterval);
    EventBus.$off('switch-to-custom-fields', this.switchToCustomFieldsTab);
  }
}

