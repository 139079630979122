




































































import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import { TrainOrdersApi } from '@/api/train-engine/train-orders.api';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

const eventName = 'update:show';


@Component({
  components: {
  },
})
export default class BasketRawProviderBookingPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() item: any;

  errors: any = [];
  bookingRawDetailsData: any = null;
  loading: boolean = true;
  bookingRawDetailsDataToCopy: any = null;

  async created() {
    this.errors = [];
    try {
      if (this.item.type === 'Rail') {
        const { data } = await TrainOrdersApi.getProviderRawBookingDetails(this.item.providerReferenceId, this.item.supplier);
        this.bookingRawDetailsData = data;
        this.bookingRawDetailsDataToCopy = data;
        
      } else if (this.item.type === 'Accommodation') {
        const { data } = await AccommodationSearchApi.getProviderRawBookingDetails(this.item.providerReferenceId, this.item.supplier);
        this.bookingRawDetailsData = data;
        this.bookingRawDetailsDataToCopy = JSON.stringify(data);

      } else if (this.item.type === 'Air') {
        const { data } = await OffersApi.getProviderRawBookingDetails(this.item.providerReferenceId, this.item.supplier);
        this.bookingRawDetailsData = data;
        this.bookingRawDetailsDataToCopy = data;
        
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  onSuccess() {
    EventBus.$emit('show-toast', {
      type: translate('common.success'),
      message: translate('basket.copy-succes')
    });
  }

  onError() {
    EventBus.$emit('show-toast', {
      type: translate('common.error'),
      message: translate('basket.copy-error')
    });
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}
