

















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiTitleErrors extends Vue {
  @Prop({
    default: () => { return []; }
  }) errors!: any;

}

