import ImportProfilesWrapper from '@/modules/settings/import-profiles/ImportProfilesWrapper.vue';
import ImportProfilesRoute from '@/router/routes/settings/import-profiles/import-profiles.route';

export default {
  path: 'import-profiles',
  component: ImportProfilesWrapper,
  displayName: 'settings-sidebar.import-profiles',
  meta: {
    introClass: 'intro-settings',
    permission: 'ImportProfiles',
    icon: 'groups',
    parentName: 'import-profiles',
  },
  children: [
    ImportProfilesRoute,
  ],
};