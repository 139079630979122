var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hotel-cancellation-policy"},[(_vm.loaded)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._l((_vm.message.rules),function(rule,index){return _c('div',{key:'rule' + index},[(!rule.rail.isRefundable)?_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('search-train.non-refundable-cancellation-info', {
              trainnumbers: rule.rail.trainNumbers,
            }))+"\n          ")]):_c('p',[(null !== rule.feePercentage)?[_vm._v("\n              "+_vm._s(_vm.$t('search-train.percentage-refundable-cancellation-info', {
                trainnumbers: rule.rail.trainNumbers,
                percentage: rule.feePercentage * 100,
              }))+"\n            ")]:[_vm._v("\n              "+_vm._s(_vm.$t('search-train.price-refundable-cancellation-info', {
                trainnumbers: rule.rail.trainNumbers,
                deadline: _vm.dateFilter(rule.deadline) ,
                price: rule.feePrice.currency.symbol + ' ' + rule.feePrice.amount,
              }))+"\n            ")]],2)])})],2)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{attrs:{"clas":"col"}},[_c('UiErrors',{attrs:{"errors":_vm.errors}})],1)]),_c('div',{staticClass:"row modal-footer"},[_c('UiTextAndIconButton',{staticClass:"popup-btn",attrs:{"type-name":"secondary","icon":"clear","icon-color":"red","size-name":"default"},on:{"click":_vm.hideForm}},[_vm._v("\n      "+_vm._s(_vm.$t('hotel-cancellation-policy.back'))+"\n    ")]),_c('UiTextAndIconButton',{staticClass:"popup-btn",attrs:{"icon":"check","icon-color":"green","type-name":"secondary","size-name":"default"},on:{"click":_vm.confirm}},[_vm._v("\n      "+_vm._s(_vm.$t('hotel-cancellation-policy.confirm-cancellation'))+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }