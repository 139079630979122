

































import settings from '@/settings';
import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';
import { ProfileApi } from '@/api/profile/profile.api';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';
import Croppie from 'croppie';

const IMAGE_BOUNDARY_OFFSET = 50;
const BASE64_STRING_PREFIX = 'data:image/' + settings.avatar.returnFormat + ';base64,';

@Component({})
export default class ChangeAvatarPopup extends Vue {
  @Prop({ default: false }) showPopup: boolean = false;
  url: string = `/assets/img/avatars/0.png`;
  image?: any = null;
  base64StringSize: number = settings.avatar.base64SizeLimitInB;
  croppie?: Croppie = null;
  errors: any[] = [];

  get profileId() {
    return router.currentRoute.params.id;
  }

  selectFile() {
    const input = this.$refs.avatarUploadInput as HTMLElement;
    input.click();
  }

  setupCroppie() {
    const placeholder = this.$refs.avatarPlaceholder as HTMLElement;
    if (placeholder) {
      this.croppie = new Croppie(placeholder, {
        viewport: {
          width: settings.avatar.width,
          height: settings.avatar.height,
          type: 'circle'
        },
        boundary: {
          width: settings.avatar.width + IMAGE_BOUNDARY_OFFSET,
          height: settings.avatar.height + IMAGE_BOUNDARY_OFFSET,
        },
        showZoomer: true,
        enableOrientation: true,
      });    
      this.croppie.bind({
        url: this.image,
      });
    }
  }

  onFileChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    this.errors = [];
    this.createImage(files[0]);
  }

  createImage(file) {
    let image = new Image();
    let reader = new FileReader();
    reader.onload = (e: ProgressEvent) => {
      if (e.target) {
        let fileReader = e.target as FileReader;
        this.image = fileReader.result;
      }
      EventHandler.$emit('avatarUploaded', this.image);
    };
    reader.readAsDataURL(file);
  }

  async upload() {
    this.errors = [];
    if (!this.image) {
      this.errors.push({ message: translate('profile-personal.no-photo-selected') });
      return;
    }
    let result = await this.croppie.result({
      type: 'base64',
      format: 'png',
      size: { width: settings.avatar.width, height: settings.avatar.height },
    });
    let baseStringSplitted = result.split(',');
    if ( baseStringSplitted[1] ) {
      this.base64StringSize = this.getBase64StringSize(baseStringSplitted[1]);
    }
    if ( this.base64StringSize < settings.avatar.base64SizeLimitInB ) {
      if (this.profileId) {
        const requestMessage = {
          profileId: this.profileId,
          request: [
            {
              size: {
                type: 'large',
                length: this.base64StringSize,
                height: 640,
                width: 640,
              },
              format: settings.avatar.returnFormat,
              image: baseStringSplitted[1],
            }
          ]
        };
        try {
          let response = await ProfileApi.updateAvatars(requestMessage.profileId, requestMessage.request);
          if (response.status === 204) {
            AccountStore.setProfileAvatar({
              id: requestMessage.profileId,
              avatar: BASE64_STRING_PREFIX + requestMessage.request[0].image,
              usageTimestamp: new Date().getTime(),
            });
          }
        } catch (error) {
          this.errors = this.$handleErrors(error);
        } finally {
          if (!this.errors.length) {
            EventHandler.$emit('avatar-uploaded');
          }
        }
      }
    } else {
      EventHandler.$emit('show-toast', {
        type: 'error',
        title: 'Image upload error',
        message: 'File too big to upload',
      });
      return;
    }
  }

  onUpload(data) {
    this.image = data;
    if (this.croppie) {
      this.croppie.destroy();
    }
    this.setupCroppie();
  }

  getBase64StringSize(base64string: string) {
    let padding: number, sizeInBytes: number;
    if (base64string.endsWith('==')) {
      padding = 2;
    } else if (base64string.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    sizeInBytes = ( 3 * base64string.length / 4 ) - padding;
    return sizeInBytes;
  }

  closeAvatarPopup() {
    this.image = null;
    EventHandler.$emit('close-avatar-popup');
  }

  @Watch('showPopup')
  onShowPopup(value) {
    if (value) {
      this.image = null;
      this.errors = [];
    } else {
      if (this.croppie) {
        this.croppie.destroy();
      }
      this.croppie = null;
    }
  }

  mounted() {
    EventHandler.$on('avatarUploaded', this.onUpload);
  }

  beforeDestroy() {
    EventHandler.$off('avatarUploaded', this.onUpload);
    if (this.croppie) {
      this.croppie.destroy();
    }
  }
}
