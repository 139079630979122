








































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { VclCode } from 'vue-content-loading';

import TrainSearchStore from './train-search.store';
import { TrainOfferResult, Price, Fare, Train } from '@/api/train-engine/train-search.model';
import { SupplierName } from '@/services/supplier-name.service';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import formatPrice from '@/filters/format-price.filter';
import TrainTimeline from './TrainTimeline.vue';
import TrainDetailsBasket from './TrainDetailsBasket.vue';
import TrainFareDiscount from './TrainFareDiscount.vue';
import BasketTrainPriceDetails from './BasketTrainPriceDetails.vue';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { trainLogo } from '@/const/train-logo-mappings.const';

@Component({
  components: {
    TrainTimeline,
    TrainDetailsBasket,
    TrainFareDiscount,
    VclCode,
    HeightTransition,
    BasketTrainPriceDetails,
  }
})
export default class TrainMobileExchangeModifiedRow extends Vue {
  @Prop() offer!: TrainOfferResult;
  @Prop() item?: any;
  @Prop() totalPrice!: Price;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;

  showDiscountInfoPopup: boolean = false;
  height: any | null = null;
  imageProcessed: boolean = false;
  imageLoading: boolean = true;
  imageVisible: boolean = true;

  get styles() {
    if (this.height) {
      return {
        height: this.height + 'px',
      };
    }
    return {};
  }

  get timelinesCollapsed() {
    return false;
  }

  get feeValue() {
    if (!this.item || !this.item.price || !this.item.price.agencyFee) {
      return null;
    }

    return this.item.price.agencyFee;
  }

  get agencyMarkupValue() {
    if (!this.item || !this.item.price || !this.item.price.agencyMarkup) {
      return null;
    }

    return this.item.price.agencyMarkup;
  }

  get systemMarkupValue() {
    if (!this.item || !this.item.price || !this.item.price.systemMarkup) {
      return null;
    }

    return this.item.price.systemMarkup;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
      'trainModificationDetailsView'
    ].indexOf(this.$route.name || '');
  }

  get exchangeRequest() {
    if (!this.modifyMode) {
      return null;
    }
    return TrainSearchStore.exchangeRequest;
  }

  get basketMode() {
    return -1 < [
      'basket',
    ].indexOf(this.$route.name || '') &&
    -1 === [
      'Confirmed',
      'Cancelled',
      'Held',
      'PendingApproval',
    ].indexOf(this.item.status);
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  displayImg(ride) {
    if (!this.imageLoading || !ride || !ride.trainInfo) {
      return '';
    }
    const key = this.offer.supplier === 'Benerail' ? ride.trainInfo.trainCode : ride.trainInfo.trainType;
    return trainLogo[key];
  }

  filterTrains(trains) {
    const filterTrain = trains
      .filter(item => item.type !== 'Stop')
      .filter((item, index, array) => {
        const i = array.findIndex(el => el.trainInfo.trainType === item.trainInfo.trainType);
        if (i < index) {
          return false;
        }

        return true;
      });

    return filterTrain;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  shouldFadeLeg(legNumber) {
    if (!this.modifyMode || !this.exchangeRequest) {
      return false;
    }

    const leg = this.exchangeRequest.legs.find(l => l.legNumber === legNumber);
    if (!leg) {
      return false;
    }

    return !leg.isForExchange;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  showDiscountInfo() {
    this.showDiscountInfoPopup = true;
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {
      if (window.innerWidth > 800 && !this.height) {
        this.$nextTick(() => {
          const rect = (this.$refs.mainCard as HTMLElement).getBoundingClientRect();
          this.height = rect.height;
        });
      }
    }
  }

  onOfferFeeEdited({
    offerId,
    travelPolicy,
  }) {
    if (this.item.id !== offerId || !this.offer) {
      return;
    }

    this.offer.travelPolicy = travelPolicy;
  }

  created() {
    EventBus.$on('offer-fee-edited', this.onOfferFeeEdited);
  }

  beforeDestroy() {
    EventBus.$off('offer-fee-edited', this.onOfferFeeEdited);
  }
}
