















































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import SeatMapConst from '@/const/seat-map.const';
import BasketStore from '../basket.store';
import { SeatModel } from '@/api/trip/basket.model';
import SeatPassanger from './SeatPassanger.vue';
import SeatAvatar from './SeatAvatar.vue';
import ExitMarker from './ExitMarker.vue';
import SeatPlace from './SeatPlace.vue';

@Component({
  components: {
    SeatPassanger,
    SeatAvatar,
    ExitMarker,
    SeatPlace,
  },
})
export default class AirCabin extends Vue {
  @Prop() offer!: any;
  @Prop() flight!: any;
  @Prop() map!: any;
  @Prop() deck!: any;
  @Prop() cabin!: any;
  @Prop() cabinIndex!: number;
  @Prop() maxWidth!: number;
  @Prop() travellers!: any[];
  @Prop() travellerPickedId!: string;
  @Prop({ default: () => [] }) avatars!: {
    row: number,
    column: string,
    profileId: string,
    offerItemId?: string | null,
  }[];

  consts: any = SeatMapConst;

  isSeatUnavailableForTraveller(seat) {
    return seat && seat.offers ? !seat.offers.find(item => item.profileId === this.travellerPickedId) : true;
  }

  get cabinAvatars() {
    return this.avatars.filter(({ row }) => this.cabin.rows.find(({ rowNumber }) => row === rowNumber));
  }

  get margin() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileMargin;
    } else {
      return SeatMapConst.margin;
    }
  }

  get rowHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileRowHeight;
    } else {
      return SeatMapConst.rowHeight;
    }
  }

  get colWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileColWidth;
    } else {
      return SeatMapConst.colWidth;
    }
  }

  get extendedColWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileExtendedColWidth;
    } else {
      return SeatMapConst.extendedColWidth;
    }
  }

  get offsetRow() {
    if (window.innerWidth <= 1024) {
      return SeatMapConst.mobileOffsetRow;
    } else {
      return SeatMapConst.offsetRow;
    }
  }

  get xOffset() {
    return ((this.maxWidth || this.getWidth()) - this.mapWidth) / 2;
  }

  get yOffset() {
    if (0 === this.cabinIndex) {
      return 0;
    }

    const previousCabins = this.deck.cabins
      .slice(0, this.cabinIndex);
    
    let result = 0;
    previousCabins.forEach(cabin => {
      cabin.rows.forEach(row => {
        if (row.isExitRow) {
          result += 2.5;
        } else {
          result += 1;
        }
      });
      result += 1.5;
    });
    return result * this.rowHeight;
  }

  getWidth(): number {
    let cabin;
    this.deck.cabins.forEach(item => {
      if (!cabin || cabin.columns.length < item.columns.length) {
        cabin = item;
      }
    });
    if (!cabin) {
      return 0;
    }
    const colNumber =  cabin.columns.length;
    return (colNumber + 4) * this.colWidth + 3 * (this.extendedColWidth - this.colWidth);
  }

  getHeight(): number {
    if (!this.rows.length) {
      return 20;
    }
    return this.cabin.rows.reduce((prev, next) => {
      if (next.isExitRow) {
        return prev + this.rowHeight + this.offsetRow;
      }
      return prev + this.rowHeight;
    }, this.offsetRow);
  }

  get selectedSeatsOfTheFlightAndDeck() {
    const flight = this.map;
    
    const bookedSelectedSeats = flight && flight.selectedSeats ?
      flight.selectedSeats.filter(item => {
        item.row = parseInt(item.seatNumber.match(/(\d+)/)[0]);
        item.column = item.seatNumber.match(/[^0-9]+/)[0];

        const rn = Number(item.row);
        const deckRows = this.rows;
        const deckMin = deckRows.find(item => item.rowNumber !== null).rowNumber;
        const deckMax = deckRows.reduce((old, cur) => Math.max(old, cur.rowNumber), 0);

        return rn >= deckMin && rn <= deckMax;
      }).map(item => {
        const rowItem = this.rows.find(i => i.rowNumber === item.row);
        const rowOffset = rowItem ? rowItem.offset : 1;

        return {
          ...item,
          travellerId: item.profileId,
          rowOffset,
        };
      }) : [];

    const selectedSeats = this.value.seats.filter(item => {
      const rn = Number(item.row);
      const deckRows = this.rows;
      const deckMin = deckRows.find(item => item.rowNumber !== null).rowNumber;
      const deckMax = deckRows.reduce((old, cur) => Math.max(old, cur.rowNumber), 0);

      return (item.flightId === this.flight.id) &&
        rn >= deckMin &&
        rn <= deckMax;
    }).map(item => {
      const rowItem = this.rows.find(i => i.rowNumber === item.row);
      const rowOffset = rowItem ? rowItem.offset : 1;

      return {
        ...item,
        rowOffset,
      };
    });

    return selectedSeats.concat(bookedSelectedSeats);
  }

  get columns() {
    let columnOffsets: number[] = [];
    
    this.cabin.columns.forEach((column, index) => {
      if (index === 0) {
        columnOffsets.push(3 * this.colWidth);
      } else if (column.column === 'A') {
        columnOffsets.push(columnOffsets[index - 1] + this.extendedColWidth);
      } else {
        columnOffsets.push(columnOffsets[index - 1] + this.colWidth);
      }
    });
    return this.cabin.columns.map((item, index) => {
      return {
        ...item,
        offset: columnOffsets[index],
      };
    });
  }

  get columnsAlleys() {
    return this.columns.filter(column => column.isCorridor).map((item, index) => {
      return {
        ...item,
        offset: item.offset,
      };
    });
  }

  get rowOffsets() {
    let rowOffsets: number[] = [];

    this.cabin.rows.forEach((row, index) => {
      let current = 0;

      if (index > 0) {
        current = rowOffsets[rowOffsets.length - 1];
      }

      if (row.isExitRow) {
        current += 2.5;
      } else {
        current += 1;
      }
      rowOffsets.push(current);
    });

    return rowOffsets;
  }

  get lastRow() {
    return this.rows[this.rows.length - 1];
  }

  get rows() {
    return this.cabin.rows.map((row, index) => {
      return {
        ...row,
        offset: this.rowOffsets[index],
      };
    });
  }

  get exitRows() {
    if (!this.rows) {
      return [];
    }
    return this.rows.filter(row => row.isExitRow);
  }

  get value() {
    return BasketStore.selectedSeats;
  }

  set value(val) {
    BasketStore.setSelectedSeats(val);
  }

  get minRowNumber() {
    let counter = 0;
    while (counter < this.rows.length && this.rows[counter].rowNumber === null) {
      counter++;
    }
    if (counter >= this.rows.length) {
      return 1;
    }
    const knownNumber = this.rows[counter].rowNumber;
    return knownNumber - counter;
  }

  get maxRowNumber() {
    let counter = 0;
    while (counter < this.rows.length && this.rows[this.rows.length - 1 - counter].rowNumber === null) {
      counter++;
    }
    if (counter >= this.rows.length) {
      return this.rows.length;
    }
    const knownNumber = this.rows[this.rows.length - 1 - counter].rowNumber;
    return knownNumber + counter;
  }

  get noWingInCabin() {
    return this.deck && this.deck.wing &&
      (this.deck.wing.startRow > this.maxRowNumber ||
      this.deck.wing.endRow < this.minRowNumber);
  }

  get wingStartInCabin() {
    return this.deck && this.deck.wing &&
      this.deck.wing.startRow >= this.minRowNumber &&
      this.deck.wing.startRow <= this.maxRowNumber;
  }

  get wingEndInCabin() {
    return this.deck && this.deck.wing &&
      this.deck.wing.endRow >= this.minRowNumber &&
      this.deck.wing.endRow <= this.maxRowNumber;
  }

  get mapWidth() {
    const colNumber =  this.cabin.columns.length;
    
    return (colNumber + 4) * this.colWidth + 3 * (this.extendedColWidth - this.colWidth);
  }

  getSeatOffsetX(seat) {
    const column = this.columns.find(column => column.column === seat.column);
    return column.offset - this.colWidth / 2 + this.margin / 2;
  }

  getSeatOffsetY(item) {
    const rowItem = this.rows.find(i => i.rowNumber === item.row);
    const offset = rowItem ? rowItem.offset : 1;
    return offset * this.rowHeight;
  }

  seatMapClick($event) {
    const result = this.findSeat($event);
    if (!result) {
      return;
    }
    const {
      row,
      column,
      seat,
    } = result;
    // should select/deselect for current traveller
    this.$emit('clickSeat', {
      row,
      column,
      offers: seat.offers,
    });
  }

  seatMapOver($event) {
    const result = this.findSeat($event);

    if (result) {
      this.$emit('seatOver', {
        result,
        $event,
        cabinIndex: this.cabinIndex,
        x: this.getSeatOffsetX(result.seat),
        y: this.yOffset + this.getSeatOffsetY(result.seat),
      });
    }
  }

  hideTooltip() {
    this.$emit('hideTooltip');
  }

  findSeat($event) {
    let seatClicked: boolean = false;
    let seatColumn: string = '';
    let seatRow: string = '';
    const x = $event.layerX - this.xOffset;
    const y = $event.layerY - this.yOffset;

    this.columns.forEach(column => {
      if (
        x >= column.offset - this.colWidth / 2 &&
        x <= column.offset + this.colWidth / 2 - this.margin / 2
      ) {
        seatColumn = column.column;
      }
    });
    this.rows.forEach((row, index) => {
      if (
        y >= (row.offset + .7) * this.rowHeight - this.margin / 2 &&
        y <= (row.offset + 1.7) * this.rowHeight - this.margin / 2 &&
        row.rowNumber !== null
      ) {
        seatRow = row.rowNumber;
      }
    });

    if (!seatRow || !seatColumn) {
      return;
    }
    // check seat
    const row = this.rows.find(row => row.rowNumber === seatRow);
    if (!row) {
      return;
    }
    const seat = row.seats.find(seat => seat.column === seatColumn);
    if (!seat || !seat.isFree || seat.noSeat) {
      return;
    }

    return {
      row: seatRow,
      column: seatColumn,
      seat,
    };
  }

  travellerNumber(id) {
    return this.travellers.findIndex(item => item.id === id) + 1;
  }
}
