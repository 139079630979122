import {
  Traveller,
  Price,
} from '@/api/air-engine/air-search.model';

import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

export interface SearchTraveller {
  id: string;
  firstName: string;
  lastName: string;
  isMainTraveller: boolean;
  isVirtual?: boolean;
  roomId?: number;
  childAge?: number;
  businessUnitId?: string;
  businessUnitName?: string;
  companyCode?: string;
  companyId?: string;
  companyName?: string;
}

export interface GuestSearchTraveller {
  passengerTypeCode: string;
  isMainTraveller: boolean;
  isVirtual?: boolean;
  roomId?: number;
  childAge?: number;
  businessUnitId?: string;
  businessUnitName?: string;
  companyCode?: string;
  companyId?: string;
  companyName?: string;
}

export interface Accommodation {
  [p: string]: any;
}

export interface InitAccommodationSearchMessage {
  travellers?: (SearchTraveller | GuestSearchTraveller)[];
  destinationLocation?: HotelLocationModel | null;
  radius: number | null;
  checkInDate: string;
  checkOutDate: string;
  destination?: AccommodationSearchSessionDestination;
  packageRates?: boolean;
  searchCompanyId: string | null;
}

export interface AccommodationSearchSessionMetadata {
  searchId: string;
  basketId: string;
  agencyMarkup: Price;
  missionId: any | null;
}

export interface AccommodationSearchSessionForm {
  metadata: AccommodationSearchSessionMetadata;
  requestMessage: InitAccommodationSearchMessage;
}

export interface AccommodationSearchSessionDestination {
  type: string;
  cityName: string;
  airportName: string;
}

export interface AccommodationSearchResult {
  properties: PropertySearchResult[];
  propertiesCount: number;
  filtersStatistics: any[];
  currency: Currency;
}

export interface PropertySearchResult {
  property: Property;
  searchPoint?: any;
  searchPointDistance: DistanceObject;
  propertySearchStatistics?: PropertySearchStatistics;
  selectedOffer?: any;
  agencyFee?: any;
  agencyMarkup?: any;
}

export interface DistanceObject {
  unit: string;
  value: number;
}

export interface Property {
  id: string;
  name: string;
  type: string;
  category: number;
  iataCode: string;
  address: string;
  review: PropertyReviewInfo;
  amenities: string[];
  isFavourite: boolean;
  mainImageUrl: string;
  loyaltyProgram: string;
}

export interface MapProperty {
  id: string;
  name: string;
  category: number;
  mainImageUrl: string;
  travelPolicy: string;
  cheapestOfferPrice: Price;
  lat: number;
  lon: number;
}

export interface MapPropertiesResponse {
  canLoadMoreResults: boolean;
  filtersStatistics: any[];
  hasMoreResults: boolean;
  properties: MapProperty[];
  propertiesCount: number;
  currency: Currency;
}

export interface PropertyReviewInfo {
  link: string;
  rating: number;
  reviewProvider: string;
  reviewsCount: number;
}

export interface PropertySearchStatistics {
  cheapestOfferPrice: Price;
  cheapestOfferTax: Price;
  hasRefundableOffers: boolean;
  hasTravalPolicyCompliantOffers: boolean;
  hasNegoOffers: boolean;
  hasMealOffers: boolean;
  isEcoFriendly: boolean;
}

export interface AccommodationOfferSearchResult {
  propertyId: string;
  offers: Offer[];
  propertySearchStatistics?: PropertySearchStatistics;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface AgencyMarkup {
  amount: number;
  currency: Currency;
}

export interface Offer {
  id: string;
  offerProvider: string;
  roomType: string;
  roomStandard: string;
  mealType: string;
  price: Price;
  tax: Price;
  isRefundable: boolean | null;
  refundableUntil: string;
  isTravelPolicyCompliant: boolean;
  agencyMarkup: Price;
  pricePlusMarkup: Price;
}

export interface SortOptions {
  PRICE?: string;
  DISTANCE?: string;
}

export interface OffersQuery {
  searchId: string;
  sortQuery: string;
}

export interface LatLng {
  lat: number;
  lon: number;
}

export interface MapCoordinates {
  latLng: LatLng;
  zoom: number;
}

export interface AccommodationSearchProviderError {
  supplier: string;
  errorCode: string;
  providerError: string;
  serviceTag: string;
}

export interface AccommodationSearchSessionState {
  searchId: string;
  stateId: string;
  totalResults: number;
  searchState: SearchState;
  newResultsAvailable: boolean;
  providersErrors: AccommodationSearchProviderError[];
}


export enum SearchState {
  Undefined = '',
  InProgress = 'InProgress',
  Completed = 'Completed',
  CompletedWithErrors = 'CompletedWithErrors',
}
