import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { BillingConfiguration, BillingConfigurationItem, BillingConfigurationModeEnum } from '@/api/billing/billing.model';
import { BillingApi } from '@/api/billing/billing.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'billingCustomFields'
})
class BillingCustomFieldsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  customFieldsConfiguration: any = {
    configurationId: '',
    name: '',
    tripCustomFields: [],
    travellerCustomFields: [],
  };
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setCustomFieldsConfiguration(payload) {
    this.customFieldsConfiguration = payload;
  }

  @Mutation
  setName(value) {
    this.customFieldsConfiguration.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'billing-custom-fields-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }
  
  @Action
  clearData() {
    this.setCustomFieldsConfiguration({
      configurationId: '',
      name: '',
      tripCustomFields: [],
      travellerCustomFields: [],
    });
    this.setCurrentConfigurationName('');
  }

  @Action
  async getCustomFieldsConfiguration(currentSelectedId) {
    this.setLoading(true);
    this.setShowError(false);
    this.setCustomError(false);
    try {
      if (currentSelectedId) {
        const result = await BillingApi.getCustomFieldsConfiguration(currentSelectedId);
        if (result && result.data) {
          this.setCustomFieldsConfiguration(result.data);
        }
      }
      else {
        this.setLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addCustomFieldsConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result = await BillingApi.addCustomFieldsConfiguration(request);
      if (result && result.data) {
        this.setConfigurationId(result.data);
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-back-office.config-saved')
        });

        await this.getCustomFieldsConfiguration(result.data.configurationId);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCustomFieldsConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await BillingApi.updateCustomFieldsConfiguration(data.id, data.configuration);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-back-office.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(BillingCustomFieldsStore);
