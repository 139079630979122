


























































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import LayoutStore from '@/modules/layout/layout.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import { DocumentTypeList } from '@/const/document-type-list.const';
import { DocumentType, ProfileDocument } from '@/api/profile/documents.model';
import DocumentEditPopup from '@/modules/profile/documents/DocumentEditPopup.vue';
import DictionaryStore from '@/store/dictionary.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import { BasketApi } from '@/api/trip/basket.api';
import { TravellerDocumentModel } from '@/api/trip/basket.model';

@Component({
  components: {
    DocumentEditPopup,
  }
})
export default class BasketTravellerDocuments extends Vue {
  @Prop({ default: '' }) profileId!: string;
  @Prop({ default: 0 }) index!: number;
  @Prop({ default: false }) disableFoid!: boolean;
  @Prop({ default: false }) isShouldChangeDocuments!: boolean;
  @Prop({ default: true }) canEditExistingDocuments!: boolean;

  documentRemovalErrorMessage: string | null = null;
  documents: TravellerDocumentModel[] = [];
  showDocumentEditPopup: boolean = false;
  deleteDocumentPopup: Boolean = false;
  editingDocument: ProfileDocument | null = null;
  documentToRemove: ProfileDocument | null = null;
  formPending: boolean = false;
  errMessages: any[] = [];
  showDocuments: boolean = true;
  willAnyDocumentExpireBeforeTripEnd: boolean = false;


  get documentTypes() {
    return DocumentTypeList
      .map(item => {
        return {
          ...item,
          name: translate('profile-documents-by-type.' + item.value),
        };
      });
  }

  get foidErrors() {
    if (!this.showFoidErrors || !this.isProfileMissingFoid) {
      return [];
    }
    return [{
      message: translate('basket.foid-is-required'),
    }];
  }

  get isProfileMissingFoid() {
    if (!this.isFoidRequired) {
      return false;
    }

    return !BasketStore.selectedDocumentsAsFoid
      .find(item => item.profileId === this.profileId);
  }

  get basketId() {
    return BasketStore.basketId;
  }

   get showFoidErrors() {
    return BasketStore.showSelectDocumentsAsFoidError;
  }

  get availableDocumentTypes() {
    return this.documentTypes.filter(type => {
      return this.documents.find((doc: any) => doc.type === type.value);
    });
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get DocumentType() {
    return DocumentType;
  }

  get isFoidRequired() {
    if (this.disableFoid) {
      return false;
    }
    return BasketStore.isFoidRequired;
  }

  get selectedAsFoid() {
    const profileAttachment = BasketStore.selectedDocumentsAsFoid.find(item => item.profileId === this.profileId);

    if (!profileAttachment) {
      return null;
    }

    return profileAttachment.documentId;
  }

  set selectedAsFoid(documentId) {
    BasketStore.setSelectedDocumentsAsFoid({
      profileId: this.profileId,
      documentId,
      document: this.documents.find(item => item.id === documentId),
    });
  }

  get allApisDocuments() {
    return this.currentMandatoryApisRules
      .reduce((prev, cur) => {
        const differentTypes = cur.apisTypeInfos
          .reduce((p, c) => {
            if (-1 === prev.indexOf(c.apisType)) {
              return [...p, c.apisType];
            }
            return p;
          }, []);
        return [...prev, ...differentTypes];
      }, []);
  }

  get currentMandatoryApisRules() {
    return BasketStore.currentMandatoryApisRules
      .filter(item => !item.dobRule);
  }

  get hasMissingApisDocuments() {
    return this.missingApisDocuments.length > 0;
  }

  get missingApisDocuments() {
    return this.allApisDocuments
      .filter(type => !this.documents.find(item => item.type === type));
  }

  get missingApisDocumentsString() {
    return this.missingApisDocuments
      .map(item => translate('profile-documents-by-type.' + item))
      .join(', ');
  }

  get shouldShowApisRulesValidationErrors() {
    return BasketStore.shouldShowApisRulesValidationErrors;
  }



  getCountryName(code: string) {
    let country = this.allCountries!.find((country: any) => { return country.code === code; });
    if (country) {
      return country.countryName;
    }
    else {
      return code;
    }
  }

  documentsOfGivenType(type) {
    return this.documents.filter((doc: any) => doc.type === type.value);
  }

  canUseFoidWithDocumentType(documentType) {
    return -1 < [
      'Passport',
      'NationalId',
      'DrivingLicense',
    ].indexOf(documentType.value);
  }

  documentTypeNameClasses(documentType) {
    if (
      !this.isFoidRequired ||
      !this.canUseFoidWithDocumentType(documentType)
    ) {
      return '';
    }
    return 'document-type-with-foid';
  }

  onDocumentChange(reloadDocuments) {
    if (this.editingDocument) {
      BasketStore.removeSelectedDocumentsAsFoid(this.editingDocument!.id);
    }
    this.showDocumentEditPopup = false;
    this.editingDocument = null;
    if (reloadDocuments) {
      this.init();
    }
  }

   showWarningDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  showWarningIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  async init() {
    this.showDocumentEditPopup = false;
    this.editingDocument = null;

    try {
      const documentsResult = await BasketApi.getBasketTravellerDocuments(this.basketId);

      if (documentsResult && documentsResult.data) {
        const travellerDocuments = documentsResult.data.find(item => item.profileId === this.profileId);

        if (travellerDocuments) {
          this.documents = travellerDocuments.documents;
          this.willAnyDocumentExpireBeforeTripEnd = travellerDocuments.willAnyDocumentExpireBeforeTripEnd;
        }
      }
    } catch (error) {
      this.errMessages = $handleErrors(error, true);
    } finally {
      EventBus.$emit('travellers-documents-loaded', this.profileId);
    }
  }

  formatDocumentRemoveDisplayName(document) {
    if (document) {
      const docType = this.documentTypes.find(type => {
          return type.value === document.type;
        });
      if (docType && docType.name) {
        return document.number + ' ' + docType.name;
      } else {
        return document.number;
      }
    }
    return '';
  }

  initRemoveDocument(document, documentType) {
    if (document.willExpireBeforeTripEnd && this.isFoidRequired && this.canUseFoidWithDocumentType(documentType)) {
      return;
    }

    this.documentToRemove = document;
    this.deleteDocumentPopup = true;
  }

  async removeDocument() {
    if (!this.documentToRemove) {
      return;
    }
    try {
      this.formPending = true;
      BasketStore.removeSelectedDocumentsAsFoid(this.documentToRemove.id);
      await DocumentsApi.removeDocument(this.profileId, this.documentToRemove.id);
      this.deleteDocumentPopup = false;
      this.documents = this.documents
        .filter((doc: any) => doc.id !== this.documentToRemove!.id);
    } catch (error) {
      if (error.response.data.error && !error.response.data.error.details) {
        this.documentRemovalErrorMessage = error.response.data.error.message;
      }
    } finally {
      this.formPending = false;
    }
  }

  editDocument(document, documentType) {
    if (document.willExpireBeforeTripEnd && this.isFoidRequired && this.canUseFoidWithDocumentType(documentType)) {
      return;
    }

    if (document) {
      this.editingDocument = Object.assign({}, document);
      this.showDocumentEditPopup = true;
    }
  }

  showAddNewDocumentPopup() {
    this.showDocumentEditPopup = true;
  }

  onValidation() {
    if (
      (this.isFoidRequired && this.isProfileMissingFoid) ||
      (this.shouldShowApisRulesValidationErrors && this.hasMissingApisDocuments)
    ) {
      this.showDocuments = true;
    }
    BasketStore.setHasMissingApisDocuments(this.hasMissingApisDocuments);
  }

  reportFoidError() {
    if (this.isProfileMissingFoid) {
      BasketStore.setFoidErrorForProfileIdAndIndex({
        profileId: this.profileId,
        index: this.index,
      });
    }
  }

  onScrollToError(profileId) {
    if (profileId !== this.profileId) {
      return;
    }
    LayoutStore.scrollToElementAlways(this.$refs.foidInfo as HTMLElement);
  }



  mounted() {
    this.init();
  }

  created() {
    EventBus.$on('assign-travellers-data', this.onValidation);
    EventBus.$on('scroll-to-foid-error', this.onScrollToError);
    EventBus.$on('report-foid-errors', this.reportFoidError);
  }

  beforeDestroy() {
    EventBus.$off('assign-travellers-data', this.onValidation);
    EventBus.$off('scroll-to-foid-error', this.onScrollToError);
    EventBus.$off('report-foid-errors', this.reportFoidError);
  }
}

