import BillingCustomFieldsConfigurations from '@/modules/settings/billing-custom-fields/BillingCustomFieldsConfigurations.vue';

export default {
  path: '',
  name: 'billing-custom-fields-configurations',
  component: BillingCustomFieldsConfigurations,
  displayName: 'Billing Custom Fields Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditBackOfficeCustomFields',
    depthCategory: 'billing-custom-fields-configurations',
    depth: 1,
    parentName: 'billing-custom-fields-configurations',
  }
};