













































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import AccountStore from '@/store/account.store';

@Component({
})
export default class BasketAirAmadeusRefundView extends Vue {
  @Prop() offer!: any;
  @Prop() travellers!: any;
  @Prop() refundDetails!: any;

  get travellersMapped() {
    return this.travellers
      .map(traveller => {
        if (!(this.refundDetails as any).passengerPriceDifferences) {
          return traveller;
        }
        const priceInfo = (this.refundDetails as any).passengerPriceDifferences
          .find(p => p.profileId === traveller.id);

        for (let key in priceInfo) {
          if (typeof priceInfo[key] === 'object' && priceInfo[key] !== null) {
            priceInfo[key].isToggled = false;
          }
        }

        return {
          ...traveller,
          priceInfo,
        };
      })
      .filter(traveller => {
        return traveller.priceInfo != null;
      });
  }

  get isAgency() {
    if (!AccountStore.current) {
      return false;
    }
    return AccountStore.current.profile.isAgency;
  }

  userFullName(user) {
    return userFullName(user);
  }

  clickTax(key, index, subKey?) {
    if (!!subKey) {
      this.travellersMapped[index].priceInfo[key]['isToggled' + subKey] = !this.travellersMapped[index].priceInfo[key]['isToggled' + subKey];
    } else {
      this.travellersMapped[index].priceInfo[key].isToggled = !this.travellersMapped[index].priceInfo[key].isToggled;
    }
    this.$forceUpdate();
  }
}

