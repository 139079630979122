import moment from 'moment';
import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

export class CarSearchStateParams {
  pickupLocation: HotelLocationModel | null = null;
  returnLocation: HotelLocationModel | null = null;
  pickupDate: string = moment()
    .add(1, 'week')
    .format('YYYY-MM-DD');
  returnDate: string = moment()
    .add(1, 'week').add(1, 'days')
    .format('YYYY-MM-DD');
  departureTime: number | null = 630;
  returnTime: number | null = 990;
  differentReturnLocation: boolean = false;
  searchRadius: number = 5;
  extras: string[] = [];

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.pickupLocation !== undefined) {
      this.pickupLocation = params.pickupLocation;
    }
    if (params.returnLocation !== undefined) {
      this.returnLocation = params.returnLocation;
    }
    if (params.pickupDate !== undefined) {
      this.pickupDate = params.pickupDate;
    }
    if (params.returnDate !== undefined) {
      this.returnDate = params.returnDate;
    }
    if (params.departureTime !== undefined) {
      this.departureTime = params.departureTime;
    }
    if (params.returnTime !== undefined) {
      this.returnTime = params.returnTime;
    }
    if (params.differentReturnLocation !== undefined) {
      this.differentReturnLocation = params.differentReturnLocation;
    }
    if (params.searchRadius !== undefined) {
      this.searchRadius = params.searchRadius;
    }
    if (params.extras !== undefined) {
      this.extras = params.extras;
    }
  }
}