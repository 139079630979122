import AirRailResults from '@/modules/search/air-rail/AirRailResults.vue';
import AirRailTravellersIntro from '@/modules/search/air-rail/AirRailTravellersIntro.vue';
import AirRailSubIntro from '@/modules/search/air-rail/AirRailSubintro.vue';
import AirRailResultsFilters from '@/modules/search/air-rail/AirRailResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/air-rail-results-travellers/:searchId',
  name: 'airRailTravellers',
  components: {
    default: AirRailResults,
    subintro: AirRailSubIntro,
    intro: AirRailTravellersIntro,
    sidebar: AirRailResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        airRail: AnimationName.UP,
      },
    }
  }
};
