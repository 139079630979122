import LoyaltyAirForm from '@/modules/profile/loyalty-programs/forms/LoyaltyAirForm.vue';
import LoyaltyAccommodationForm from '@/modules/profile/loyalty-programs/forms/LoyaltyAccommodationForm.vue';
import LoyaltyRailForm from '@/modules/profile/loyalty-programs/forms/LoyaltyRailForm.vue';
import LoyaltyCarForm from '@/modules/profile/loyalty-programs/forms/LoyaltyCarForm.vue';

export const FormComponent = {
  Accommodation: LoyaltyAccommodationForm,
  Car: LoyaltyCarForm,
  Air: LoyaltyAirForm,
  Rail: LoyaltyRailForm,
};

type TFormComponent = typeof FormComponent;

export const getComponentByType = (type: string): TFormComponent[keyof TFormComponent] => {
  return FormComponent[type];
};
