




























































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation} from 'vue-plugin-helper-decorator';
import { required, minLength, maxLength, email, requiredIf } from 'vuelidate/lib/validators';

import { router } from '@/router';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import SettingsStore from '@/modules/settings/settings.store';
import { CreateCompanyMessage } from '@/api/profile/company.model';
import { isCompanyCode } from '@/core/validation/is-company-code.validator';
import EventHandler from '@/services/event-handler';

const phoneRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^\+?(?:\d{1,4}?){6,14}\d$/g.test(value);
};

@Component({})
export default class CreateCompany extends Vue {
  Form = new CreateCompanyMessage();
  serverErrors: any = [];
  saving: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        code: {
          required,
          isCompanyCode,
          maxLength: maxLength(32),
        },
        phoneNumber: {
          required: requiredIf(() => {
            return this.Form.isAgency;
          }),
          minLength: minLength(3),
          maxLength: maxLength(16), 
          phoneRegex,
        },
        emailAddress: {
          required: requiredIf(() => {
            return this.Form.isAgency;
          }),
          maxLength: maxLength(320),
          email,
        },
      },
    };
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isAgency() {
    return this.Form.isAgency;
  }

  set isAgency(value) {
    if (!value) {
      this.Form.phoneNumber = this.Form.emailAddress = '';
    }
    this.Form.isAgency = value;
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    this.saving = true;

    try {
      const response = await ProfileCompanyApi.createCompany(this.Form);
      // todo: redirect to the newly created company
      router.push({
        path: '/settings/' + response.data.id,
      });

      this.whenSaved();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  goBack() {
    router.go(-1);
  }

  whenSaved() {
    EventHandler.$emit('show-toast', {
      type: 'success',
      title: 'Saved',
      message: 'Data saved'
    });
  }

  created() {
    this.Form.isAgency = true;
  }
}

