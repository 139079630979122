













































































































import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import AccountStore from '@/store/account.store';
import AirRailSearchStore from './air-rail-search.store';

@Component({
  components: {
    VueContentLoading
  }
})
export default class AirRailSort extends Vue {
  isProviderErrorsVisible: boolean = false;
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  imagesConst = '/assets/img/loader/1.gif';
  toggleSortOptions: boolean = false;

  get loading() {
    return AirRailSearchStore.loading;
  }

  get sortOptions() {
    return AirRailSearchStore.sortOptions;
  }

  get isDisabled() {
    return AirRailSearchStore.isSelected;
  }

  get priceSorterEnabled() {
    return this.sortOptions.PRICE !== undefined;
  }

  get departureTimeSorterEnabled() {
    return this.sortOptions.DEPARTURE_TIME !== undefined;
  }

  get currentSortType() {
    if (this.priceSorterEnabled) {
      return 'sort-by-cheapest-offer';
    } else if (this.departureTimeSorterEnabled) {
      return 'sort-by-earliest-departure';
    }
    return 'sort-by-shortest-flight';
  }

  get offerVisible() {
    return AirRailSearchStore.offersVisible;
  }

  get totalOffers() {
    return AirRailSearchStore.totalOffers;
  }

  get filters() {
    return AirRailSearchStore.filters;
  }

  get offers() {
    return AirRailSearchStore.offers;
  }

  get recommendationsCount() {
    return AirRailSearchStore.recommendationsCount;
  }

  get isNotFiltered() {
    return this.totalOffers === this.recommendationsCount;
  }

  get isSearchCompleted() {
    return AirRailSearchStore.searchCompleted;
  }

  get providersErrors() {
    return AirRailSearchStore.providersErrors;
  }

  get groupedProvidersErrors() {
    return _.groupBy(AirRailSearchStore.providersErrors, 'supplier');
  }

  get classes() {
    return {
      'justify-content-end': this.offers && this.offers.length === 0,
      'justify-content-between': this.offers && this.offers.length > 0,
    };
  }

  get canSeeProviderWarnings() {
    return AccountStore.HasPermission('CanSeeProviderWarnings');
  }


  toggleSort(sorterCode) {
    if (this.isDisabled) {
      return;
    }
    if (this.sortOptions[sorterCode] === undefined) {
      AirRailSearchStore.setNewSort({ sorterCode, isAscending: true });
      AirRailSearchStore.setOffersVisible(10);

      AirRailSearchStore.setSearchFreezed(true);
      const searchId = this.$route.params.searchId;
      AirRailSearchStore.getOffers(searchId);
    }
    this.toggleSortOptions = false;
  }

  toggleSortDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.toggleSortOptions = !this.toggleSortOptions;
  }

  showProviderErrors() {
    this.isProviderErrorsVisible = true;
  }


  hideProviderErrors() {
    this.isProviderErrorsVisible = false;
  }

}
