







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { TRailSeatSelectionsModel } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import TrainsTile from '@/modules/basket/basket-rail-seats-extras/TrainsTile.vue';
import SeatsTrenitaliaContent from '@/modules/basket/basket-rail-seats-extras/content/SeatsTrenitaliaContent.vue';
import BasketStore from '@/modules/basket/basket.store';
import { Price } from '@/api/expense/expense.model';

const DefaultPrice = {
  amount: 0,
  currency: {
    code: 'EUR',
  }
};

@Component({
  components: {
    TrainsTile,
    SeatsTrenitaliaContent,
  },
})
export default class RailTrenitaliaSeatSelection extends Vue {
  @Prop() basketItem!: any;

  get railSeatSelectionsModel() {
    return BasketStore.railSeatSelectionsModel;
  }
  set railSeatSelectionsModel(value: TRailSeatSelectionsModel) {
    BasketStore.setRailSeatSelectionsModel(value);
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: this.bookingStep,
      type: 'seats',
      prices: this.seatPrices,
    });
  }

  get isLoading() {
    return BasketStore.basketRailSeatsMapLoading;
  }

  get passengers() {
    return (BasketStore.basket && BasketStore.basket.travellers) || [];
  }

  get basketRailSeatsMap() {
    return BasketStore.basketRailSeatsMap;
  }

  get trains() {
    return this.basketRailSeatsMap.map((item) => ({
      trainNumber: item.trainNumber,
      to: item.to,
      departureDate: item.departureDate,
      from: item.from,
      segmentId: item.segmentId,
      type: item.carrier,
    }));
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get seatPrices() {
    return this.railSeatSelectionsModel.seatSelections.reduce((prices, seatSelection) => {
      const seatMap = this.basketRailSeatsMap.find((item) => item.segmentId === seatSelection.segmentId);
      if (!seatMap) {
        return prices;
      }
      const coach = seatMap.coaches.find((item) => item.number === seatSelection.coachNumber);
      if (!coach) {
        return prices;
      }
      const seat = coach.seatMapItems.find((item) => item.seatNumber === seatSelection.seatNumber);
      if (!seat || !seat.prices) {
        return prices;
      }
      const seatPrice = seat.prices.find((item) => item.travellerId === seatSelection.profileId);
      if (!seatPrice) {
        return prices;
      }
      return prices.concat(seatPrice.price);
    }, [] as Price[]);
  }

  get isAvailableLeastOneSeatMap() {
    return this.basketRailSeatsMap.some((item) => item.coaches.length > 0);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.setRailSeatSelectionsModel({ seatSelections: [] });
    await BasketStore.loadRailSeatsMap(this.basketItem.id);
    BasketStore.setBookingStepLoading(false);
  }
}
