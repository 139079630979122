import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { TravelPolicyApi } from '@/api/travel-policy/travel-policy.api';
import { CarRuleConfigurationCreateModel, CarRuleCreateModel } from '@/api/travel-policy/travel-policy.model';
import DictionaryStore from '@/store/dictionary.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import _ from 'lodash';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'travelPolicyCar'
})
class TravelPolicyCarstore extends VuexModule {
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow ;
  travelPolicy: CarRuleConfigurationCreateModel = {
    configurationName: '',
    rootCompanyId: '',
    carRules: [],
    hideNonCompliantRates: false,
    isPostSalesEnabledInNonCompliantBooking: false,
  };
  currentSelectedRules: CarRuleCreateModel | null = null;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  get getCarTravelPolicy() {
    return this.travelPolicy;
  }

  get getCarRules() {
    return this.travelPolicy.carRules;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get currentCarRules() {
    return this.currentSelectedRules;
  }

  get allRentalCompanies() {
    return DictionaryStore.allRentalCompanies;
  }

  @Mutation
  selectedCarRule(payload) {
    this.currentSelectedRules = payload;
  }

  @Mutation
  public SetCurrentConfiguration(currentConfiguration: ConfigurationRow | null) {
    this.currentConfiguration = currentConfiguration!;
  }

  @Mutation
  clearCarRules() {
    this.travelPolicy = {
      configurationName: '',
      rootCompanyId: '',
      carRules: [],
      hideNonCompliantRates: false,
      isPostSalesEnabledInNonCompliantBooking: false,
    };
  }

  @Mutation
  setHideNonCompliantRates(value) {
    this.travelPolicy.hideNonCompliantRates = value;
  }

  @Mutation
  setIsPostSalesEnabledInNonCompliantBooking(value) {
    this.travelPolicy.isPostSalesEnabledInNonCompliantBooking = value;
  }
 
  @Mutation
  setCarRules(payload) {
    this.travelPolicy.carRules = payload;
  }

  @Mutation
  addCarRule(payload) {
    if (this.travelPolicy.carRules) {
      this.travelPolicy.carRules.push(payload);
    }
  }

  @Mutation
  editCarRule(payload) {
    let index = _.findIndex(this.travelPolicy.carRules, fr => fr.id === payload.id);
    if (index >= -1) {
      this.travelPolicy.carRules[index] = payload;
    }
  }

  @Mutation
  addName(payload) {
    this.travelPolicy.configurationName = payload;
  }

  @Action
  async getRules(currentSelectedId) {
    try {
      TravelPolicyStore.clearErrors();

      const result = await TravelPolicyApi.getCarRules(currentSelectedId);
      if (result && result.data) {
        this.addName(result.data.configurationName);
        TravelPolicyStore.setCurrentConfigName(result.data.configurationName);
        this.setCarRules(result.data.carRules);
        this.setHideNonCompliantRates(result.data.hideNonCompliantRates);
        this.setIsPostSalesEnabledInNonCompliantBooking(result.data.isPostSalesEnabledInNonCompliantBooking);
        TravelPolicyStore.setLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        TravelPolicyStore.setCustomError(true);
      } else {
        TravelPolicyStore.setErrors(error);
      }
    }
  }

  @Action
  async getCarRulesConfiguration(currentSelectedId) {
    TravelPolicyStore.setLoading(true);
    if (currentSelectedId) {
      if (this.travelPolicy.carRules && this.travelPolicy.carRules.length) {
        this.setCarRules(this.travelPolicy.carRules);
      } else {
        await this.getRules(currentSelectedId);
      }
    } else {
      TravelPolicyStore.setLoading(false);
    }
  }
}

export default getModule(TravelPolicyCarstore);