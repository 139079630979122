import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  SearchTripsResult,
  SearchTripsMessage,
  SearchBookingsMessage,
  SearchBookingsResult,
  PagingQuery,
  SearchApprovalsMessage,
  BasketTravellerShortProfile,
  ApprovalWorkflowModel,
  DelayTicketingDeadlineModel,
  SearchApprovalsResult,
  TripComment,
  BasketTripCommentModel,
} from './trip.model';
import { TBasketTripNote } from '@/api/trip/basket.model';

class TripApiClass {
  public search(query: PagingQuery, params: SearchTripsMessage): Promise<AxiosResponse<SearchTripsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/trips/search?page={page}&size={size}',
      method: RequestMethod.POST,
    }, query, params);
  }

  public searchBookings(query: PagingQuery, params: SearchBookingsMessage): Promise<AxiosResponse<SearchBookingsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/bookings/search?page={page}&size={size}',
      method: RequestMethod.POST,
    }, query, params);
  }

  public exportTrips(params: SearchTripsMessage) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/export-to-file',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public modifyTravellersDetails(itemId, passengers): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/passengers',
      method: RequestMethod.POST
    }, {
      itemId,
    }, {
      passengers,
    });
  }

  public onHold(arrangerId: any, limit: any): Promise<AxiosResponse<SearchTripsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/trips/search?page=1&size=' + limit,
      method: RequestMethod.POST,
    }, {
      page: 0,
    }, {
      period: 'Upcoming',
      basketStatuses: ['Held'],
      bookers: [arrangerId],
      sortDirection: 'Ascending',
      sortOnField: 'HoldDeadline'
    });
  }

  public lastBooked(arrangerId: any, limit: any): Promise<AxiosResponse<SearchTripsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/trips/search?page=1&size=' + limit,
      method: RequestMethod.POST,
    }, {
      page: 1
    }, {
      period: 'Upcoming',
      basketStatuses: ['Confirmed'],
      bookers: [arrangerId],
      sortDirection: 'Descending',
      sortOnField: 'CreateDate'
    });
  }

  public pendingApproval(approverId: any, limit: any): Promise<AxiosResponse<SearchApprovalsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/approvals/search?page=1&size=' + limit,
      method: RequestMethod.POST,
    }, {
      page: 1
    }, {
      period: 'All',
      basketStatuses: ['PendingApproval'],
      approvers: [approverId],
      sortDirection: 'Ascending',
      sortOnField: 'ApprovalDeadline'
    });
  }

  public getApprovalWorkflowForBasket(basketId): Promise<AxiosResponse<ApprovalWorkflowModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/approval',
      method: RequestMethod.GET,
    }, basketId, {});
  }

  public getApproverBasketList(query: PagingQuery, params: SearchApprovalsMessage): Promise<AxiosResponse<SearchApprovalsResult>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/approvals/search?page={page}&size={size}',
      method: RequestMethod.POST,
    }, query, params);
  }

  public setApproversForBasket(basketId, approvers) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/approver',
      method: RequestMethod.PUT,
    }, { basketId } , { approvers });
  }

  public changeApprover(tripId, approverId, sendEmail) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{tripId}/change-approver?approverId={approverId}&sendEmail={sendEmail}',
      method: RequestMethod.PUT,
    }, { tripId, approverId, sendEmail }, {});
  }

  public rejectApproval(basketId, rejectionReason) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/rejectApproval',
      method: RequestMethod.POST,
    }, { basketId }, { rejectionReason });
  }

  public acceptApproval(basketId) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/acceptApproval',
      method: RequestMethod.POST,
    }, { basketId }, {});
  }

  public getDelayTickietingForBasket(basketId): Promise<AxiosResponse<DelayTicketingDeadlineModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/delay-ticketing/deadline',
      method: RequestMethod.GET,
    }, basketId, {});
  }

  public updateDelayTickieting(basketId, deadline) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{basketId}/delay-ticketing/deadline',
      method: RequestMethod.PUT,
    }, { basketId }, deadline);
  }

  public getPaymentMethods(basketId) {
    return http.execute({
      path: settings.apiTrip + '/trips/{basketId}/payment-methods',
      method: RequestMethod.GET,
    }, {basketId} , {});
  }

  public updateTraveller(tripId: string, traveller: BasketTravellerShortProfile) {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{tripId}/traveller',
      method: RequestMethod.PUT,
    }, { tripId }, traveller);
  }
  
  public getNotifications(query: PagingQuery, options) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications/search?page={page}&size={size}',
      method: RequestMethod.POST,
    }, query, options);
  }

  public getNotificationsCount(tripId) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications/count?tripId={tripId}',
      method: RequestMethod.GET,
    }, { tripId }, {});
  }

  public getNotificationMessage(notificationId) {
    return http.execute({
      path: settings.apiTrip + '/api/v2/notifications/{notificationId}/message',
      method: RequestMethod.GET,
    }, { notificationId }, {});
  }

  public notificationMarkAsRead(notificationId) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications/{notificationId}/mark-as-read',
      method: RequestMethod.POST,
    }, { notificationId }, {});
  }

  public notificationMark(notificationId, params) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications/{notificationId}',
      method: RequestMethod.PUT,
    }, { notificationId }, params);
  }

  public getExchangeDetailsSummary(tripId: string, exchangeItemId: string) {
    return http.execute({
      path: settings.apiTrip + '/trips/{tripId}/exchange-details-offers-summary/{exchangeItemId}',
      method: RequestMethod.POST,
    }, { tripId, exchangeItemId }, {});
  }

  public getExchangeDetailsApproval(tripId: string, exchangeItemId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{tripId}/exchange-details-approval/{exchangeItemId}',
      method: RequestMethod.GET,
    }, { tripId, exchangeItemId }, {});
  }

  public setExchangeDetailsApproval(tripId: string, exchangeItemId: string, data: any) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{tripId}/exchange-details-approver/{exchangeItemId}',
      method: RequestMethod.PUT,
    }, { tripId, exchangeItemId }, data);
  }

  public getExternalMissionTrips(externalMissionId) {
    return http.execute({
      path: settings.apiTrip + '/external-mission-trips/{externalMissionId}',
      method: RequestMethod.GET,
    }, { externalMissionId }, {});
  }

  public getProvidersNotificationsConfiguration(id: string) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications-configuration/{id}',
      method: RequestMethod.GET,
    }, { id }, {});
  }

  public createProvidersNotificationsConfiguration(configuration) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications-configuration',
      method: RequestMethod.POST,
    }, {}, configuration);
  }

  public updateProvidersNotificationsConfiguration(id: string, configuration) {
    return http.execute({
      path: settings.apiTrip + '/api/notifications-configuration/{id}',
      method: RequestMethod.PUT,
    }, { id }, configuration);
  }

  public getTripCustomerCodeInfo(tripId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/backoffice/{tripId}/customer-status',
      method: RequestMethod.POST,
    }, { tripId }, {});
  }

  public getTripComments(tripId: string): Promise<AxiosResponse<BasketTripCommentModel[]>> {
    return http.execute({
      path: settings.apiTrip + '/api/tripComments?tripId={tripId}',
      method: RequestMethod.GET,
    }, { tripId }, {});
  }

  public addTripComment(comment: TripComment) {
    return http.execute({
      path: settings.apiTrip + '/api/tripComments',
      method: RequestMethod.POST,
    }, { }, comment);
  }

  public deleteTripComment(id: string) {
    return http.execute({
      path: settings.apiTrip + '/api/tripComments/{id}',
      method: RequestMethod.DELETE,
    }, { id }, {});
  }

  public searchForGestourCustomer(tripId: string, params: any, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiTrip + '/api/backoffice/{tripId}/customer-search',
      method: RequestMethod.POST,
    }, { tripId }, params, true, cancelTokenSource);
  }

  public saveGestourCustomer(tripId: string, params: any) {
    return http.execute({
      path: settings.apiTrip + '/api/backoffice/{tripId}/customer',
      method: RequestMethod.PUT,
    }, { tripId }, params, true);
  }

  public createItineraryEmail(tripId, configuration) {
    return http.execute({
      path: settings.apiTrip + '/api/trips/{tripId}/emails/itinerary',
      method: RequestMethod.POST,
    }, { tripId }, configuration);
  }

  public postConfirmSynchronization(itemId: string, tripId: string, body: {
    needUpdateTripBookingDataInBackOffice: boolean,
    needSendTripItineraryEmailToTravellers: boolean,
  }) {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/post-confirm-synchronization/{tripId}',
      method: RequestMethod.POST,
    }, { itemId, tripId }, body);
  }

  public failedSynchronization(itemId: string, failedSynchronizationAction: string) {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/failed-synchronization?failedSynchronizationAction={failedSynchronizationAction}',
      method: RequestMethod.POST,
    }, { itemId, failedSynchronizationAction }, {});
  }

  public saveTripNote(tripId: string, note: TBasketTripNote) {
    return http.execute({
      path: settings.apiTrip + '/trip/{tripId}/notes',
      method: RequestMethod.PUT,
    }, { tripId }, note);
  }

  public removeTripNote(tripId: string) {
    return http.execute({
      path: settings.apiTrip + '/trip/{tripId}/notes',
      method: RequestMethod.DELETE,
    }, { tripId }, {});
  }

  public getTripHistoryTripNotes(tripId: string) {
    return http.execute({
      path: settings.apiTrip + '/api/trip-history/{tripId}/trip',
      method: RequestMethod.GET,
    }, { tripId }, {});
  }
}

export const TripApi: TripApiClass = new TripApiClass();
