


















































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import HotelSearchStore from './hotel-search.store';
import {
  PropertySearchResult,
  AccommodationOfferSearchResult,
  Offer,
} from '@/api/accommodation-engine/accommodation-search.model';
import HotelDetailsSection from './HotelDetailsSection.vue';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import EditHotelFeePopup from './EditHotelFeePopup.vue';
import EditHotelAgencyMarkupPopup from './EditHotelAgencyMarkupPopup.vue';
import EventBus from '@/services/event-handler';

@Component({
   components: {
    HotelDetailsSection,
    EditHotelFeePopup,
    EditHotelAgencyMarkupPopup,
  },
})
export default class HotelResultDetails extends Vue {

  offerResult: AccommodationOfferSearchResult | null = null;
  searchId: string | null = null;
  offersLoaded: boolean = false;

  get showError() {
    return HotelSearchStore.showError;
  }

  get serverErrors() {
    return HotelSearchStore.serverErrors;
  }

  get offers(): Offer[] {
    if (!this.offerResult) {
      return [];
    }
    return this.offerResult.offers;
  }

  get hasNoOffers() {
    return this.offersLoaded && 0 === this.offers.length;
  }

  get result() {
    return HotelSearchStore.offers.find(x => x.property.id === this.$route.params.propertyId ); 
  }

  async loadOffers() {
    this.offerResult = await HotelSearchStore.loadPropertyOffers({
      searchId: this.$route.params.searchId,
      propertyId: this.$route.params.propertyId,
    });
    this.offersLoaded = true;
    HotelSearchStore.updateOfferStats({
      offerId: this.$route.params.propertyId,
      stats: this.offerResult.propertySearchStatistics,
    });
  }

  goToDetails(result) {
    HotelSearchStore.setDetailedOffer(result);
    router.push({
      name: 'hotelDetails',
      params: { 
        searchId: this.$route.params.searchId, 
        propertyId: result.property.id,
      },
    });
  }

  async initView() {
    this.searchId = this.$route.params.searchId;
    if (this.searchId) { 
      HotelSearchStore.getSearchSession(this.searchId);
      HotelSearchStore.search(this.searchId);
      await this.loadOffers();
    }
  }

  showResults() {
    router.push({
      name: 'hotel',
      params: { 
        searchId: this.$route.params.searchId, 
      },
    });
  }

  onHotelRoomEditFee(offer) {
    if (!this.offers) {
      return;
    }
    const index = this.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-fee-popup');
  }

  onHotelRoomEditAgencyMarkup(offer) {
    if (!this.offers) {
      return;
    }
    const index = this.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-agency-markup-popup');
  }



  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    this.initView();
  }



  created() {
    EventBus.$on('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$on('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
  }

  beforeDestroy() {
    EventBus.$off('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$off('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
  }

}
