





































import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';
import { TCoach, TRailSeatSelection } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import TrainSeatIcon from './TrainSeatIcon.vue';
import TrainSeatPassanger from './TrainSeatPassanger.vue';
import TrainSeatAvatar from './TrainSeatAvatar.vue';
import EventBus from '@/services/event-handler';
import BasketStore from '../basket.store';
import SeatAvatar from '@/modules/basket/seatmap/SeatAvatar.vue';

@Component({
  components: {
    SeatAvatar,
    TrainSeatIcon,
    TrainSeatPassanger,
    TrainSeatAvatar,
  },
})
export default class TrainCabinNormalSeat extends Vue {
  @Prop({ default: null }) item!: any | null;
  @Prop({ default: null }) currentTraveller!: any | null;
  @Prop({ default: null }) segmentId!: string | null;
  @Prop() currentCoach!: TCoach;
  @Prop({ default: () => [] }) seatSelections!: TRailSeatSelection[];

  get x1idx() {
    return this.item.x;
  }

  get x2idx() {
    return this.item.x + this.item.width;
  }

  get y1idx() {
    return this.item.y;
  }

  get y2idx() {
    return this.item.y + this.item.height;
  }

  get x() {
    return consts.margin + this.x1idx * consts.colWidth + consts.thickness;
  }

  get y() {
    return consts.margin + this.y1idx * consts.rowHeight + consts.thickness;
  }

  get width() {
    return Math.max(0, (this.x2idx - this.x1idx) * consts.colWidth - 2 * consts.thickness);
  }

  get height() {
    return Math.max(0, (this.y2idx - this.y1idx) * consts.rowHeight - 2 * consts.thickness);
  }

  get passangerX() {
    return this.x + (this.width - consts.colWidth * 1.5) / 2;
  }

  get passangerY() {
    return this.y + (this.height - consts.rowHeight * 1.5) / 2;
  }

  get currentJourney() {
    return this.segmentId ? { segmentId: this.segmentId } : BasketStore.basketRailSeatsJourney;
  }

  get passanger() {
    if (!this.currentCoach || !this.currentJourney) {
      return null;
    }
    return this.seatSelections.find(seat => {
      return seat.seatNumber === this.item.seatNumber && seat.coachNumber === this.currentCoach.number && seat.segmentId === this.currentJourney.segmentId;
    });
  }

  get passangerNumber() {
    if (!this.passanger) {
      return 1;
    }
    const { profileId } = this.passanger;
    const index = BasketStore.basketTravellers.findIndex(value => {
      return value.id === profileId;
    });

    return index + 1;
  }

  get isTakenByPassanger() {
    return !!this.passanger;
  }

  onClick($event) {
    if (this.item.legend) {
      return;
    }
    if (this.item.seatAvailability !== 'Open') {
      return;
    }
    EventBus.$emit('select-seat', this.item);
  }

  onMouseEnter($event) {
    if (this.item.legend) {
      return;
    }
    EventBus.$emit('mouseenter-seat', {
      item: this.item,
      e: $event,
    });
  }

  onMouseLeave($event) {
    if (this.item.legend) {
      return;
    }
    EventBus.$emit('mouseleave-seat', {
      item: this.item,
      e: $event,
    });
  }
}
