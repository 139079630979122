



































































import { Vue, Component } from 'vue-property-decorator';

import { AirApi } from '@/api/air-engine/air-search.api';
import AirSearchStore from './air-search.store';
import AirResultsRow from '@/modules/search/air/AirResultsRow.vue';
import SearchStore from '../search.store';

@Component({
  components: {
    AirResultsRow,
  },
})
export default class AirUpsell extends Vue {
  loading: boolean = true;
  serverErrors: any[] = [];

  get isForced() {
    return this.$route.params.forced === '1';
  }

  get recommendationId() {
    return this.$route.params.recommendationId;
  }

  get providerSearchId() {
    return this.$route.params.providerSearchId;
  }

  get allErrors() {
    return [
      ...this.serverErrors,
      ...AirSearchStore.errMessages,
    ];
  }

  get searchId() {
    return this.$route.params.searchId;
  }

  get currentOffer() {
    return AirSearchStore.upsellCurrentOffer;
  }

  get upsellOffers() {
    return AirSearchStore.upsellOffers;
  }

  get searchMode() {
    return SearchStore.airSearchParameters.searchMode;
  }

  selectProposal(offer) {
    AirSearchStore.setUpsellChosen(offer.id);
    AirSearchStore.AddOfferToCartNow({
      forceAdd: this.isForced,
      recommendationId: offer.id,
    });
  }

  async getSearchSession(searchId) {
    try {
      const response = await AirApi.getAirSearchSession({
        searchId
      });
      SearchStore.updateAirDefaultState(response.data.request);
      SearchStore.updateAirCurrentState(response.data.request);
      SearchStore.updateSearchModeStatus(response.data.request.searchMode);
      let travellers = {
        travellers: response.data.request.travellers
      };
      SearchStore.updateTravellersDefaultState(travellers);
      AirSearchStore.updateSearchStateId(response.data.metadata.stateId);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async getUpsellData() {
    try {
      const response = await AirApi.getUpsellData(this.searchId, this.recommendationId, this.providerSearchId);
      AirSearchStore.setUpsellOffers(response.data.offers);
      AirSearchStore.setUpsellCurrentOffer(response.data.selectedOffer);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async created() {
    if (this.currentOffer) {
      this.loading = false;
      return;
    }
    
    await Promise.all([
      this.getSearchSession(this.searchId),
      this.getUpsellData(),
    ]);
    this.loading = false;
  }
}

