var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.element.item)?_c('div',{staticClass:"basket-trip-history__basket-item mb-6"},[_c('BasketItem',{ref:"basketItems",attrs:{"item":_vm.element.item,"basket":_vm.basket,"show-actions":false}})],1):_vm._e(),_c('div',{staticClass:"basket-trip-history__item-history mb-8"},[_c('b-table',{ref:"table",attrs:{"striped":"","stacked":"md","fields":_vm.fields,"items":_vm.changes,"per-page":_vm.params.size,"current-page":_vm.currentPage,"sort-by":_vm.params.sort,"sort-desc":_vm.params.desc,"no-sort-reset":true},on:{"update:sortBy":function($event){return _vm.$set(_vm.params, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.params, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.params, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.params, "desc", $event)}},scopedSlots:_vm._u([{key:"actionBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"trips-table__traveller-name-column"},[(item.invokerMapped)?_c('div',{staticClass:"traveller-name-wrapper"},[_c('div',{staticClass:"trips-table__traveller-avatar"},[_c('UiAvatar',{attrs:{"variant":"small","user":item.invokerMapped,"selected":item.invokerMapped.isMainTraveller}})],1),_c('div',{staticClass:"trips-table__traveller"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.userFullName(item.invokerMapped)}),expression:"{ content: userFullName(item.invokerMapped)}"}],staticClass:"trips-table__traveller-name text-overflow-dotted"},[_vm._v("\r\n              "+_vm._s(_vm.userFullName(item.invokerMapped))+" "),(item.invokerMapped.isGuest)?_c('span',[_vm._v("("+_vm._s(_vm.$t('search.guest'))+")")]):_vm._e()]),_c('div',{staticClass:"trips-table__traveller-unit"},[_vm._v("\r\n              "+_vm._s(item.invokerMapped.businessUnitName)+"\r\n            ")])])]):_c('div',{staticClass:"trips-table__traveller-name-column"},[_c('div',{staticClass:"traveller-name-wrapper traveller-name-wrapper--system"},[_vm._v("\r\n            "+_vm._s(_vm.$t('notifications.system'))+"\r\n          ")])])])]}},{key:"actionType",fn:function(ref){
var item = ref.item;
return [(_vm.$te('basket-trip-history-action-types.' + item.changeReason))?[_vm._v(_vm._s(_vm.$t('basket-trip-history-action-types.' + item.changeReason)))]:[_vm._v(_vm._s(item.changeReason))]]}},{key:"actionDatetime",fn:function(ref){
var item = ref.item;
return [_vm._v("\r\n        "+_vm._s(item.changeDateFormated)+"\r\n      ")]}},{key:"changes",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('BasketTripHistoryItemChanges',{attrs:{"item":item,"index":index}})]}}])}),(_vm.showMore)?_c('div',{staticClass:"show-hide-advanced-criteria-link"},[(_vm.showingMore)?_c('a',{attrs:{"href":"///"},on:{"click":function($event){$event.preventDefault();return _vm.showMoreNow(true)}}},[_vm._v(_vm._s(_vm.$t('search.show-more')))]):_c('a',{attrs:{"href":"///"},on:{"click":function($event){$event.preventDefault();return _vm.showMoreNow(false)}}},[_vm._v(_vm._s(_vm.$t('search.show-less')))])]):_vm._e(),_c('SabreTripHistoryItemInvoluntaryChanges',{attrs:{"item":_vm.element.item}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }