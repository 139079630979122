import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/locales/en.json';

Vue.use(VueI18n);

const loadedLanguages = ['en'];

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: {'en': messages} // set locale messages
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

function setHTMLLanguage(lang) {
  document.documentElement.lang = lang;
}

export function loadLanguageAsync(lang) {
  setHTMLLanguage(lang);
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    }
  );

}

export const translate = (key: string, ...parameters): string => {
  if (!key) {
    return '';
  }
  return i18n.t(key, ...parameters) as string;
};

export const translationExists = (key: string): boolean => {
  if (!key) {
    return false;
  }
  return i18n.te(key) as boolean;
};
