























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  ProfileDocument,
  CreateKnownTravellerMessage,
} from '@/api/profile/documents.model';
import DictionaryStore from '@/store/dictionary.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import BasketStore from '@/modules/basket/basket.store';
import EventBus from '@/services/event-handler';

@Component({})
export default class KnownTravellerDocumentData extends Vue {
  @Prop({ default: false }) documentType: any;
  @Prop({ default: null }) document: any;
  @Prop({ default: null }) profileId: any;
  @Prop({}) isGuestMode!: boolean;
  @Prop({ default: false }) withoutSaving!: boolean;

  Form: ProfileDocument | null = null;
  serverErrors: any[] = [];
  errMessage: string = '';
  wasSubmitted: boolean = false;
  formPending: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        number: {
          required,
          maxLength: maxLength(14),
        }
      },
    };
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isEditing() {
    return !!this.document;
  }

  async created() {
    if (this.isEditing) {
      this.Form = new ProfileDocument();
      this.Form.type = this.documentType.value;
      this.Form.number = this.document.number;
      this.Form.id = this.document.id;
      this.Form.tempId = this.document.id;
    } else {
      this.Form = new ProfileDocument();
    }
  }

  notifyParent(reloadDocuments, document) {
    this.$emit('document-changed', reloadDocuments, document);
  }

  async saveDetails() {
    this.serverErrors = [];
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    let request: CreateKnownTravellerMessage = {
      type: this.documentType.value,
      number: this.Form!.number,
    };

    if (this.withoutSaving) {
      this.notifyParent(true, request);
      return;
    }

    if (this.isEditing) {

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document = {
            ...request,
            tempId: this.Form!.tempId,
          };
          BasketStore.updateGuestBasketTravellerDocument({ id: this.profileId, document: document});
        } else {
          await DocumentsApi.updateKnownTravellerDocument(this.profileId, this.document.id, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    } else {
      this.wasSubmitted = true;

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document: any = request;
          document.tempId = _.uniqueId();
          BasketStore.setGuestBasketTravellerDocument({ id: this.profileId, document: document });
        } else {
          await DocumentsApi.createKnownTravellerDocument(this.profileId, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    }
  }

  onTypeChanged() {
    ((this.$refs.firstInput as Vue).$refs.input as HTMLElement).focus();
  }

  mounted() {
    EventBus.$on('document-type-changed', this.onTypeChanged);
  }

  beforeDestroy() {
    EventBus.$off('document-type-changed', this.onTypeChanged);
  }
}
