
export interface HotelFeesConfiguration {
  configurationId?: string;
  isVisibleToCustomer: boolean;
  isFeeAdjustableToTravelPolicy: boolean;
  isEnabled: boolean;
  feesRules: HotelFeeRule[];
}

export interface HotelFeeRule {
  id?: any;
  status?: any;
  order: number;
  feeType: FeeTypeEnum;
  fixedFee: {
    amount: number;
    currency: Currency;
  };
  percentageFee: number;
  calculationMethod: FeesCalculationMethodEnum;
  paymentMethod: string;
  isLodgeCardInformationSelected: boolean;
  supplier: string[];
  tripTypes: string[];
  fareTypes: string[];
}

export interface Currency {
  code: string;
  symbol: string | null;
}

export enum FeeTypeEnum {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
}

export enum FeesCalculationMethodEnum {
  PerBooking = 'PerBooking',
  PerPassengerInBooking = 'PerPassengerInBooking',
  PerLeg = 'PerLeg',
  PerPassengerInLeg = 'PerPassengerInLeg',
}

export enum SupplierEnum {
  Unknown = 'Unknown',
  Expedia = 'Expedia',
  Sabre = 'Sabre',
  Koedia = 'Koedia',
  Amadeus = 'Amadeus',
  Teldar = 'Teldar',
  HCorpo = 'HCorpo',
  WonderHotel = 'WonderHotel',
}
