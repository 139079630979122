


























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class AssignTravellerToRoomSlot extends Vue {
  @Prop() aslot!: any;
  @Prop() index!: any;
  @Prop() availableTravellers!: any[];
  @Prop() travellers!: any[];


  get chosenTraveller() {
    const profileId = this.aslot.profileId;
    if (!profileId) {
      return [];
    }
    return [this.travellers.find(t => t.id === profileId)];
  }

  set chosenTraveller(value) {
    this.$emit('change', {
      index: this.index,
      profileId: !!value.length ? value[value.length - 1].id : null,
    });
  }
}

