



































































































import { Vue, Component } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import DictionaryStore from '@/store/dictionary.store';
import LayoutStore from './layout.store';
import AppNavbar from './AppNavbar.vue';
import FakeNavbar from './FakeNavbar.vue';
import IntroTransition from './IntroTransition.vue';
import HeaderTransition from './HeaderTransition.vue';
import SidebarTransition from './SidebarTransition.vue';
import AsideTransition from './AsideTransition.vue';
import DefaultTransition from './DefaultTransition.vue';
import BackgroundTransition from './BackgroundTransition.vue';
import SubintroTransition from './SubintroTransition.vue';
import SubmenuTransition from './SubmenuTransition.vue';
import FakeSidebar from './FakeSidebar.vue';
import AppFooter from './AppFooter.vue';
import AppMissingPermissions from '@/modules/layout/AppMissingPermissions.vue';
import { Debounce } from '@/core/decorators/debounce.decorator';
import PreloadError from '@/modules/layout/PreloadError.vue';
import MessagesTransition from './MessagesTransition.vue';
import CompareOfferBox from './CompareOfferBox.vue';

@Component({
  components: {
    AppNavbar,
    FakeNavbar,
    AppMissingPermissions,
    IntroTransition,
    SubintroTransition,
    SubmenuTransition,
    HeaderTransition,
    SidebarTransition,
    AsideTransition,
    DefaultTransition,
    BackgroundTransition,
    FakeSidebar,
    AppFooter,
    PreloadError,
    MessagesTransition,
    CompareOfferBox
  },
})
export default class LayoutWrapper extends Vue {
  get hasUser() {
    return AccountStore.loaded;
  }

  get missingPermissions() {
    return AccountStore.missingPermissionsError;
  }

  get viewClasses() {
    return {
      'with-sidebar': !LayoutStore.slots.sidebar.meta.empty && !LayoutStore.sidebarHidden,
      'with-aside': !LayoutStore.slots.aside.meta.empty && !LayoutStore.asideHidden,
    };
  }

  get mainViewClasses() {
    return {
      'fullscreen': this.isLayoutFullscreen,
      'main-view-basket':  -1 !== [
          'basketAddSegment',
          'basket',
          'basketTravellers',
        ].indexOf(this.$route.name || ''),
      'main-view-air':  -1 !== [
          'airDetails',
          'airResultsTravellersList',
          'airFilters',
          'airModificationTravellers',
          'airModification',
          'airResultDetails',
          'airTravellers',
          'air',
        ].indexOf(this.$route.name || ''),
        'main-view-hotel':  -1 !== [
          'hotelDetailsSearch',
          'hotelDetailsTravellers',
          'hotelDetails',
          'hotelFilters',
          'hotelMap',
          'hotelResultDetails',
          'hotelResultsSearch',
          'hotelResultsTravellersList',
          'hotelRoomFilters',
          'hotelTravellers',
          'hotel',
        ].indexOf(this.$route.name || ''),
        'main-view-train':  -1 !== [
          'trainDetails',
          'trainFilters',
          'trainModification',
          'trainResultsTravellersList',
          'trainTravellers',
          'train',
        ].indexOf(this.$route.name || ''),
    };
  }

  get scrollableClasses() {
    return {
      'mobile-menus-active': LayoutStore.isMenuActive,
      'fullscreen': this.isLayoutFullscreen // overflow-y hidden
    };
  }

  get isLayoutFullscreen() {
    return LayoutStore.isLayoutFullscreen;
  }

  get isNoPaddingBottom() {
    return LayoutStore.isNoPaddingBottom;
  }

  get settingsLoaded() {
    return AccountStore.settingsLoaded;
  }

  get preloadError() {
    return AccountStore.preloadError;
  }

  get dictsError() {
    return DictionaryStore.dictionariesShouldBeLoaded && DictionaryStore.dictionariesLoadedWithErrors;
  }



  onScroll() {
    this.updateContentScroll();
  }

  onResize() {
    this.updateContentScroll();
  }

  updateContentScroll() {
    LayoutStore.areaScrolled((this.$refs.scrollableArea as HTMLElement).scrollTop);
  }

  @Debounce({
    delay: 100,
    maxWait: 200,
  })
  windowResize() {
    LayoutStore.slots.sidebar.updateDimensions();
    LayoutStore.slots.aside.updateDimensions();
    LayoutStore.slots.background.updateDimensions();
  }



  mounted() {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                   navigator.userAgent &&
                   navigator.userAgent.indexOf('CriOS') === -1 &&
                   navigator.userAgent.indexOf('FxiOS') === -1;

    LayoutStore.initStyles({
      scrollableArea: this.$refs.scrollableArea,
      popupsArea: (this.$refs.popupsArea as Vue).$el,
      isSafari,
    });

    window.onresize = () => {
      if (this.isLayoutFullscreen) {
        this.windowResize();
      }
    };
  }
}
