
















import { Vue, Component, Prop } from 'vue-property-decorator';
import BasketStore from '@/modules/basket/basket.store';

const SETTINGS = {
  offset: 1,
  width: 20,
  height: 20,
};
const SETTINGS_MOBILE = {
  offset: 2,
  width: 30,
  height: 30,
};

@Component
export default class SeatAvatar extends Vue {
  @Prop({ default: 0 }) x!: number;
  @Prop({ default: 0 }) y!: number;
  @Prop() avatar!: {
    row: number,
    column: string,
    profileId: string,
  };

  get profile() {
    return BasketStore.basketTravellers.find((item) => item.id === this.avatar.profileId);
  }

  get settings() {
    if (window.innerWidth <= 1024) {
      return SETTINGS_MOBILE;
    }
    return SETTINGS;
  }

  onAvatarClick() {
    this.$emit('avatar-click', this.avatar);
  }
}
