















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import AirRailSearchStore from '@/modules/search/air-rail/air-rail-search.store';
import AirRailSearchParamOnResults from './AirRailSearchParamOnResults.vue';

@Component({
  components: {
    AirRailSearchParamOnResults,
  }
})
export default class AirRailSearchShortParams extends Vue {
  searchId: string | null = null;

  get airRailSearchParameters() {
    return SearchStore.getAirRailDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get currentAirSearchIdFromApi() {
    return AirRailSearchStore.currentSearchIdFromApi;
  }

  showCriteria() {
    let params = {
      ...this.$route.params
    };

    if (this.$route.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
      params.searchId = this.currentAirSearchIdFromApi;
    }

    router.push({
      name: 'airRailDetails',
      params,
    });
  }
  showTravellers() {
    let params = {
      ...this.$route.params
    };

    if (this.$route.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
      params.searchId = this.currentAirSearchIdFromApi;
    }

    router.push({
      name: 'airRailTravellers',
      params,
    });
  }
}
