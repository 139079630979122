























































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { translate } from '@/i18n';
import SearchStore from '@/modules/search/search.store';
import _ from 'lodash';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';

@Component({})
export default class UiTravellersTravellerRoom extends Vue {
  @Prop() traveller!: any;
  @Prop() rooms!: any[];
  @Prop() index!: number;
  @Prop() travellers!: any[];
  

  get travellerUrl() {
    return sanitizeUrl('/profile/' + this.traveller.id);
  }

  get availableRooms() {
    return this.rooms.map((room, index) => {
      return {
        name: translate('search-hotel.list-room-number', { number: index + 1 }),
        label: translate('search-hotel.list-room-number', { number: index + 1 }),
        value: index,
        room,
      };
    });
  }

  get selectedRoom() {
    return this.availableRooms.find(item => item.room.profiles.includes(this.traveller.id));
  }

  set selectedRoom(value) {
    this.$emit('room-changed', value);
  }

  get travellerPseudoName() {
    const adultArray = ['ADT', 'SRC', 'SEA'];
    const childArray = ['YTH', 'CHD', 'INF'];
    let name = '';
    let counter = 1;
    let same = adultArray;

    if (-1 < adultArray.indexOf(this.traveller.passengerTypeCode)) {
      name += 'Adult ';
    } else {
      name += 'Child ';
      same = childArray;
    }

    for (let i = 0; i < this.index; i++) {
      if (-1 < same.indexOf(this.travellers[i].passengerTypeCode)) {
        counter++;
      }
    }
    name += counter;

    return name;
  }
}
