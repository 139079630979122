
















































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { translate } from '@/i18n';
import { TripApi } from '@/api/trip/trip.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import { userFullName } from '@/core/user-full-name';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import BasketStore from '@/modules/basket/basket.store';
import AccountStore from '@/store/account.store';

@Component({})
export default class BasketTripHistoryItemTripNotes extends Vue {
  loading: boolean = false;
  errors: any[] = [];
  changes: {
    changeDate: string,
    changeParameters: {
      fieldTag: string | null,
      fieldName: string,
      valueFrom: string | null,
      valueTo: string | null,
    }[],
    changeReason: string,
    changeType: string,
  }[] = [];

  params: any = {
    page: 1,
    start: 0,
    size: 100,
    sort: '',
    desc: true,
    usePageParam: false,
  };
  fields = {
    actionBy: {
      label: translate('basket-trip-history.action-by'),
      class: 'basket-trip-history__column-action-by',
    },
    actionType: {
      label: translate('basket-trip-history.action-type'),
      class: 'basket-trip-history__column-action-type',
    },
    actionDatetime: {
      label: translate('basket-trip-history.action-datetime'),
      class: 'basket-trip-history__column-action-datetime',
    },
    changes: {
      label: translate('basket-trip-history.action-details'),
      class: 'basket-trip-history__column-changes',
    },
  };

  userFullName = userFullName;

  get basketId() {
    return BasketStore.basketId;
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }

  async created() {
    try {
      this.loading = true;
      const { data } = await TripApi.getTripHistoryTripNotes(this.basketId);
      this.changes = data.changes.map((item) => ({
        ...item,
        changeDateFormated: moment.utc(item.changeDate).local().format(this.dateTimeFormat),
        invokerMapped: {
          id: item.invoker.invokerId,
          firstName: item.invoker.invokerFirstName,
          middleName: item.invoker.invokerMiddleName || '',
          lastName: item.invoker.invokerLastName,
          username: item.invoker.invokerUsername,
          businessUnitName: item.invoker.invokerBusinessUnitName,
        }
      }));
    } catch (error) {
      this.errors = $handleErrors(error);
    } finally {
      this.loading = false;
    }
  }
}
