import ImportProfiles from '@/modules/settings/import-profiles/ImportProfiles.vue';

export default {
  path: '',
  name: 'import-profiles',
  component: ImportProfiles,
  displayName: 'Import profiles',
  meta: {
    introClass: 'intro-settings',
    permission: 'ImportProfiles',
    depthCategory: 'import-profiles',
    parentName: 'import-profiles',
    depth: 1,
  },
};