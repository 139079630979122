









































































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { BillingCustomFieldsConfigurationsDataRow } from './billing-custom-fiields-configurations.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import BillingStore from '@/modules/settings/billing/billing.store';
import SettingsStore from '@/modules/settings/settings.store';
import EventHandler from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class BillingCustomFieldsConfigurations extends BaseTable<BillingCustomFieldsConfigurationsDataRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  serverErrorsMessages = {
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  serverErrors: any = [];
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  get addNewConfigurationUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/billing-custom-fields/billing-custom-fields-configuration');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.companyId + '/billing-custom-fields/' + item.id + '/billing-custom-fields-configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;
      BillingStore.setCurrentConfigurationName(item.name);

      if (!preventRedirect && this.$hasAccess('CanEditBackOfficeCustomFields')) {
        router.push({
          name: 'billing-custom-fields-configuration',
          params: {
            configurationId: item.id
          }
        });
      } else if (!preventRedirect && !this.$hasAccess('CanEditBackOfficeCustomFields')) {
        router.push({
          name: 'billing-custom-fields-configuration',
          params: {
            configurationId: item.id
          },
          query: {
            readOnly: 'true'
          }
        });
      }
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  copy(item) {
    if (item) {
      this.resetData();
      this.configuration = item;
      BillingStore.setCopy(true);
      BillingStore.getBillingConfiguration(item.id);
      router.push({
        name: 'billing-custom-fields-configuration',
      });
    }
  }

  resetData() {
    BillingStore.clearData();
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.CanEditBackOfficeCustomFields);
      this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-approval.configuration-removed'),
        message: translate('settings-approval.info-removed')
      });
    }
    catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    }
    finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    this.serverErrors = [];
    try {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.backOfficeCustomFieldsConfiguration,
        this.params.size,
        this.params.page,
        Permission.CanEditBackOfficeCustomFields,
        request.searchName
      );

      if (result && result.data) {
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  toggleBillingConfigurationMenu(item) {
    if (!this.configuration || this.configuration.id !== item.id) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.configuration = item;
      this.menuActive = !this.menuActive;
    }
  }
}

