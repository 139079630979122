




























import { Vue, Component } from 'vue-property-decorator';

import GlobalSettingsStore from './global-settings.store';

@Component({})
export default class GlobalSettingsSidebar extends Vue {

  get routes() {
    return GlobalSettingsStore.routes;
  }

}
