import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { Query, TravellerModel, LocationModel, AirLocationModel, RailLocationModel } from './home.model';
import { LanguageCode } from '../dictionary/dictionary.model';

class HomeApiClass {
  public findTraveller(query: Query): Promise<AxiosResponse<Array<TravellerModel>>> {
    return http.execute({
      path: settings.apiProfile + '/api/travellers?searchPhrase={query}&includeGuests=true',
      method: RequestMethod.GET,
    }, query, {});
  }

  public findTravellerInbusinessUnitId(query: string, companyId: string, travellerType: string): Promise<AxiosResponse<Array<TravellerModel>>> {
    return http.execute({
      path: settings.apiProfile + '/api/travellers?searchPhrase={query}&businessUnitId={companyId}&includeGuests=true&travellerType={travellerType}',
      method: RequestMethod.GET,
    }, { query, companyId, travellerType }, {});
  }

  public findTravellerInCompany(query: string, companyId: string): Promise<AxiosResponse<Array<TravellerModel>>> {
    return http.execute({
      path: settings.apiProfile + '/api/travellers?searchPhrase={query}&companyId={companyId}&includeGuests=true',
      method: RequestMethod.GET,
    }, { query, companyId }, {});
  }

  public findFromLocation(query: Query): Promise<AxiosResponse<Array<LocationModel>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/airports?query={query}',
      method: RequestMethod.GET,
    }, query, {});
  }

  public findToLocation(query: Query): Promise<AxiosResponse<Array<LocationModel>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/airports?query={query}',
      method: RequestMethod.GET,
    }, query, {});
  }

  public findAirRailLocation(query: string, languageCode: LanguageCode): Promise<AxiosResponse<Array<AirLocationModel>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/air-rail?query={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }

  public findAirLocation(query: string, languageCode: LanguageCode, onlyAirports: boolean = false): Promise<AxiosResponse<Array<AirLocationModel>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/v2/autocomplete/airports?query={query}&languageCode={languageCode}&onlyAirports={onlyAirports}',
      method: RequestMethod.GET,
    }, {
      query,
      languageCode,
      onlyAirports
    }, {});
  }

  public findRailLocation(query: string, languageCode: LanguageCode): Promise<AxiosResponse<Array<RailLocationModel>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/railway?query={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }
}

export const HomeApi: HomeApiClass = new HomeApiClass();
