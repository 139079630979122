


























































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { CustomFieldsExpenseApi } from '@/api/expense/custom-fields-expense.api';
import { CustomFieldExpenseModel } from '@/api/expense/custom-fields-expense.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class CustomFieldsExpense extends BaseTable<CustomFieldExpenseModel[]> {
  @Prop({}) readonly!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  currentPage: number = 1;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: any | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    expenseType: {
      sortable: false,
      label: translate('settings-expense.expense-type'),
    },
    name: {
      sortable: false,
      label: translate('settings-expense.custom-name'),
    },
    order: {
      sortable: false,
      label: translate('settings-expense.order'),
    },
    active: {
      sortable: false,
      label: translate('settings-expense.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get customFieldsList() {
    return ExpensePolicyStore.customFieldsList;
  }

  get customFieldDefinition() {
    return ExpensePolicyStore.customFieldDefinition;
  }

  get customFiledsListMaper() {
    return this.customFieldsList.map(fieled => {
      return {
        ...fieled,
        customFiledName: this.customFieldDefinition.find(def => def.id === fieled.customFieldDefinitionId)
      };
    });
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.customFieldsList.length || 0;
  }

  toggleMenu(item) {
    if (this.selected !== item) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    router.push({
      name: 'custom-field-expense',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: router.currentRoute.params.itemId,
        configurationId: this.$route.params.configurationId,
        expenseExtensionId: item.expenseExstensionId
      }
    });
  }

  async removeField() {
    try {
      await CustomFieldsExpenseApi.removeCustomField(this.selected.expenseExstensionId);
      this.removePopup = false;
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };
      EventHandler.$emit('show-toast', obj);
      ExpensePolicyStore.getCustomFieldsList(this.policyId);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async created() {
    await ExpensePolicyStore.getCustomFieldDefinition();
  }

}
