import { BaseTableParams } from '@/core/base-table-params';
import {
  SearchTripPeriod,
  SearchTripsSortOnEnum,
  SearchTripsSortDirectionEnum
} from '@/api/trip/trip.model';

export class BookingsTableParams extends BaseTableParams {
  period: SearchTripPeriod = SearchTripPeriod.All;
  destination: any = '';
  destinationCityId: string = '';
  destinationCityName: string = '';
  startDate: any = {
    from: '',
    to: '',
  };
  endDate: any = {
    from: '',
    to: '',
  };
  createDate: any = {
    from: '',
    to: '',
  };
  deadline: any = {
    from: '',
    to: '',
  };
  wmCode: string = '';
  bookingStatuses: any[] = [];
  serviceTypes: any[] = [];
  bookers: any[] = [];
  travellers: any[] = [];
  companies: any[] = [];
  companyCode: any = '';
  bookingReference: string = '';
  providers: string[] = [];
  providerStatuses: string[] = [];
  paymentMethods: string[] = [];
  bookingChannels: string[] = [];
  bookingTypes: string[] = [];

  sortOnField: string = SearchTripsSortOnEnum.CreateDate;
  sortDirection: string = SearchTripsSortDirectionEnum.Descending;
  page: number = 1;
  usePageParam: boolean = true;

  public constructor(params: any) {
    super(params);
  }
}
