











































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, decimal, integer, maxValue, minValue } from 'vuelidate/lib/validators';

import EventBus from '@/services/event-handler';
import consts from '@/const/numbers.const';
import basketStore from './basket.store';

@Component({})
export default class BasketCustomFieldItem extends Vue {
  @Prop() field!: any;
  @Prop({ default: false }) readonly!: boolean;

  phrase: string = '';
  $v;

  get maxInt32Value() {
    return consts.integer.max;
  }

  get minInt32Value() {
    return consts.integer.min;
  }

  get fieldType() {
    if (!this.field) {
      return '';
    }

    return this.field.customField.typeName || 'String';
  }

  get disabled() {
    return this.readonly || !this.field.canBeModified;
  }

  get dictionaryOptions() {
    if (!this.field) {
      return [];
    }

     const defaultValue = {
        id: null,
        name: null,
        code: null,
      };
    if (!this.phrase) {
      return [defaultValue]
        .concat(this.field.customField.dictionaryItems)
        .sort((a, b) => (a.name || '').localeCompare((b.name || '')));
    }
    let filterDictionaryOptions = this.field.customField.dictionaryItems
      .filter(item => {
        return item.name.toLowerCase().includes(this.phrase.toLowerCase()) ||
          item.code.toLowerCase().includes(this.phrase.toLowerCase());
      });
    return [defaultValue]
      .concat(filterDictionaryOptions)
      .sort((a, b) => (a.name || '').localeCompare((b.name || '')));
  }

  get fieldValue() {
    return this.dictionaryOptions.find(item => item.code === this.field.valueCode);
  }

  set fieldValue(val) {
    this.field.valueCode = val ? val.code : null;
    this.field.value = val ? val.name : null;
  }

  @Validation()
  validationObject() {
    const ifRegex = (value, vm) => {
      const fieldType = vm.customField.typeName;
      const fieldDef = vm.customField;

      if (
        fieldType &&
        fieldDef.validationRegExpRule !== '' &&
        (-1 === ['Dictionary', 'Bool'].indexOf(fieldType))
      ) {
        const newValidationRegExpRule = new RegExp(fieldDef.validationRegExpRule);

        if (value) {
          return ( newValidationRegExpRule.test(value) );
        } else {
          return true;
        }
      }

      return true;
    };
    if (this.readonly) {
      return {
        field: {
          value: {},
        },
      };
    }

    let value: any = {
      ifRegex,
    };

    if (this.field.mandatory && !this.readonly) {
      value.required = required;
    }

    if (this.field && this.field.customField) {
      if ('Double' === this.field.customField.typeName) {
        value.decimal = decimal;
      } else if ('Integer' === this.field.customField.typeName) {
        value.integer = integer;
        value.maxValue = maxValue(this.maxInt32Value);
        value.minValue = minValue(this.minInt32Value);
      }
    }
    return {
      field: {
        value,
      },
    };
  }

  close() {
    const autocomplete = (this.$refs.dictionaryAutocomplete as Vue).$el as HTMLElement;
    const el = autocomplete.getElementsByTagName('input')[0];

    setTimeout(() => {
      el.blur();
    }, 100);
  }

  filterDictionaryOptions(phrase) {
    this.phrase = phrase;
  }

  checkForErrors() {
    this.$v.field.$touch();
    if (this.$v.field.$error) {
      basketStore.addCustomFieldsError({
        field: this.field,
        errors: this.$v.field,
      });
    }
  }

  mounted() {
    EventBus.$on('assign-custom-fields', this.checkForErrors);
  }

  beforeDestroy() {
    EventBus.$off('assign-custom-fields', this.checkForErrors);
  }
}
