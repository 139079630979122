






































































































































































































































































































































































































































































































































import { Vue, Component, Watch} from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, decimal, integer, minValue, maxValue } from 'vuelidate/lib/validators';

import consts from '@/const/numbers.const';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import SettingsStore from '@/modules/settings/settings.store';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { CustomFieldDictionariesApi } from '@/api/custom-fields/custom-fields-dictionary.api';
import {
  UpdateFieldConfigurationMessage,
  TravelAssignmentLevel,
  TravelAssignmentModificationContext,
  CreateTravelAssignmentMessage,
} from '@/api/custom-fields/custom-fields.model';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { CustomFieldTravelAssignmentsApi } from '@/api/custom-fields/custom-fields-travel-assignments.api';

@Component({})
export default class TravelAssignment extends Vue {
  $v;
  travelAssignment: any = {
    id: null,
    field: null,
    defaultValue: null,
    level: this.levelOptions[0],
    order: 1,
    isActive: true,
    isAvailableForApprovalWorkflow: false,
    isAvailableForArrangerMatching: false,
    usageContext: 'Traveller',
    modificationContexts: [
      {
        mandatory: false,
        modificationContext: null,
        canBeModified: false,
        isForNonCompliantOnly: false,
        isVisibleOnlyForAgent: false,
      },
    ],
  };
  errorsOnPopup: any[] = [];
  dictionaryOptions: any[] = [];
  errors: any[] = [];
  loading: boolean = false;
  formHasErrors: boolean = false;
  removePopup: boolean = false;
  saving: boolean = false;



  get contextOptions() {
    return [
      {
        label: translate('settings-custom-fields.making-booking'),
        value: TravelAssignmentModificationContext.Booking,
      },
      {
        label: translate('settings-custom-fields.approving-trip'),
        value: TravelAssignmentModificationContext.Approval,
      },
    ];
  }

  isBookingContext(context) {
    return context.modificationContext != null && context.modificationContext.value === TravelAssignmentModificationContext.Booking;
  }

  get unusedContextOptions() {
    return this.contextOptions
      .filter(el => {
        return !this.travelAssignment.modificationContexts
          .find(context => context.modificationContext && context.modificationContext.value === el.value);
      });
  }

  get levelOptions() {
    return [
      {
        label: translate('settings-custom-fields.reservation'),
        value: TravelAssignmentLevel.Reservation,
      },
      {
        label: translate('settings-custom-fields.profile'),
        value: TravelAssignmentLevel.Profile,
      },
    ];
  }

  get maxInt32Value() {
    return consts.integer.max;
  }

  get minInt32Value() {
    return consts.integer.min;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get assignmentId() {
    return this.$route.params.assignmentId;
  }

  get hasAssignmentId() {
    return null != this.assignmentId;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/custom-fields/${this.configurationId !== undefined ? this.configurationId + '/field-configuration' : 'field-configuration'}`;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get customFields() {
    return CustomFieldsStore.customFields;
  }

  get otherTravelAssignments() {
    return CustomFieldsStore.travelAssignments
      .filter(item => {
        if (this.hasAssignmentId) {
          return item.id !== this.assignmentId;
        }
        return true;
      });
  }

  get customFieldsOptions() {
    return this.customFields.map(item => {
      const type = CustomFieldsStore.fieldTypes.find(el => el.id === item.typeId);
      let dictionary: any = undefined;

      if (type && type.type === 'Dictionary') {
        dictionary = CustomFieldsStore.dictionaries.find(el => el.id === item.customFieldDictionaryId);
      }
      return {
        ...item,
        type,
        dictionary,
      };
    }).filter(item => {
      const el = this.otherTravelAssignments.find(x => x.customField.id === item.id);

      return el == null && item.isActive;
    });
  }

  get fieldType() {
    const field: any = this.travelAssignment.field;

    if (!field || !field.type || !field.type.type) {
      return '';
    }

    return field.type.type;
  }

  get dictionaries() {
    return CustomFieldsStore.dictionaries;
  }

  get dictionaryOptionsFiltered() {
    const dictionaryItems  = this.dictionaryOptions
      .filter(item => item.isActive)
      .sort((a, b) => (a.name || '').localeCompare((b.name || '')));

    const defaultValue = {
      id: null,
      name: '',
      isActive: true,
      code: null,
      description: ''
    };
    return [defaultValue].concat(dictionaryItems);
  }
  
  get canAddRules() {
    return this.travelAssignment.modificationContexts.length < this.contextOptions.length;
  }

  get isArrangerMatchingPossible() {
    return this.travelAssignment.level && this.travelAssignment.level.value && this.travelAssignment.usageContext
      && this.travelAssignment.level.value === TravelAssignmentLevel.Profile
      && this.travelAssignment.usageContext === 'Traveller';
  }

  @Validation()
  validationObject() {
    const field = this.travelAssignment.field;
    const ifRegex = (value, vm) => {
      const field = vm.field;
      if (
        field && field.type &&
        field.validationRegExpRule !== '' &&
        (-1 === ['Dictionary', 'Bool'].indexOf(field.type.type))
      ) {
        const newValidationRegExpRule = new RegExp(field.validationRegExpRule);
        if (value) {
          return ( newValidationRegExpRule.test(value) );
        } else {
          return true;
        }
      } else {
        return true;
      }
    };
    const canBeAvailableForApprovalWorkflowOnlyIfDisplayWhenBooking = (value, vm) => {
      if (vm.modificationContexts != null && vm.modificationContexts.length > 0) {
        for (let i = 0; i < vm.modificationContexts.length; i++) {
          if (vm.modificationContexts[i].modificationContext !== null
            && vm.modificationContexts[i].modificationContext.value === TravelAssignmentModificationContext.Booking) {
              return true; // value can be either true or false
            }
        }
      }
      // if we're here then value must be false
      return !value;
    };

    let defaultValue: any = {
      ifRegex,
    };

    if (field && field.type) {
      if ('Double' === field.type.type) {
        defaultValue.decimal = decimal;
      } else if ('Integer' === field.type.type) {
        defaultValue.integer = integer;
        defaultValue.maxValue = maxValue(this.maxInt32Value);
        defaultValue.minValue = minValue(this.minInt32Value);
      }
    }
    return {
      travelAssignment: {
        field: {
          required,
        },
        level: {
          required,
        },
        order: {
          required,
          integer,
          minValue: minValue(1),
        },
        usageContext: {
          required,
        },
        defaultValue,
        modificationContexts: {
          $each: {
            modificationContext: {
              required,
            },
          },
        },
        isAvailableForApprovalWorkflow: {
          canBeAvailableForApprovalWorkflowOnlyIfDisplayWhenBooking
        }
      }
    };
  }



  @Watch('travelAssignment.canBeModified')
  onCanBeModifiedChange(value) {
    if (!value) {
      this.travelAssignment.mandatory = false;
    }
  }

  @Watch('travelAssignment.field.id', { immediate: true })
  onFieldChange() {
    this.travelAssignment.defaultValue = null;
    const type = this.travelAssignment.field && this.travelAssignment.field.type ? this.travelAssignment.field.type.type : '';

    if (-1 < ['Double', 'Integer'].indexOf(type)) {
      this.travelAssignment.defaultValue = 0;
    } else if ('Bool' === type) {
      this.travelAssignment.defaultValue = false;
    }
  }

  @Watch('travelAssignment.field.dictionary', { immediate: true })
  async onDictionaryChange(value) {
    this.dictionaryOptions = [];

    if (!value || !value.id) {
      return;
    }

    const response = await CustomFieldDictionariesApi.getCustomFieldDictionaryItems(value.id);
    this.dictionaryOptions = response.data.results;
    if (
      this.travelAssignment.defaultValue === null &&
      this.dictionaryOptionsFiltered.length
    ) {
      this.travelAssignment.defaultValue = this.dictionaryOptionsFiltered[0];
    }
  }

  @Watch('$route.params', { immediate: true, deep: true })
  async onParamsChange(params) {
    if (!params || !params.assignmentId) {
      return;
    }
    this.loading = true;
    try {
      const response = await CustomFieldTravelAssignmentsApi.getTravelAssignment(params.assignmentId);
      const data = response.data;
      const level = this.levelOptions.find(el => el.value === data.assignmentLevel);
      const field = this.customFieldsOptions.find(el => el.id === data.customField.id);
      if (!field) {
        return;
      }
      let dictValue = null;

      if ('Dictionary' === data.customField.type.type) {
        const result = await CustomFieldDictionariesApi.getCustomFieldDictionaryItems(field.customFieldDictionaryId);
        if (data.customFieldDictionaryItemId) {
          dictValue = result.data.results.find(el => el.id === data.customFieldDictionaryItemId);
        }
      }

      const defaultValue = 'Dictionary' === data.customField.type.type ? dictValue : data.defaultValue;

      this.travelAssignment = {
        id: data.id,
        configurationId: data.configurationId,
        field,
        defaultValue,
        level,
        order: data.order,
        isActive: data.isActive,
        isAvailableForApprovalWorkflow: data.isAvailableForApprovalWorkflow,
        isAvailableForArrangerMatching: data.isAvailableForArrangerMatching,
        modificationContexts: data.modificationContexts.map(context => {
          return {
            ...context,
            modificationContext: this.contextOptions
              .find(el => el.value === context.modificationContext),
          };
        }),
        usageContext: data.usageContext,
      };
      this.$nextTick(() => {
        this.travelAssignment.defaultValue = defaultValue;
      });
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  @Watch('travelAssignment.modificationContexts', { deep: true })
  onModificationContextsChange(newValue, oldValue) {
    newValue.forEach((context, index) => {
      if (!this.isBookingContext(context) && context.isVisibleOnlyForAgent) {
        this.$set(this.travelAssignment.modificationContexts[index], 'isVisibleOnlyForAgent', false);
      }
    });
  }

  addNextRule() {
    this.travelAssignment.modificationContexts.push({
      mandatory: false,
      modificationContext: null,
      canBeModified: false,
      isForNonCompliantOnly: false,
      isVisibleOnlyForAgent: false,
    });
  }

  focusOnSaveButton() {
    setTimeout(() => {
      if (!this.$refs.saveButton) {
        return;
      }
      ((this.$refs.saveButton as Vue).$el as HTMLElement).focus();
    }, 100);
  }

  removeModificationContext(index) {
    this.travelAssignment.modificationContexts.splice(index, 1);
  }

  checkForm() {
    this.formHasErrors = false;
    this.$v.travelAssignment.$touch();
    if (this.$v.travelAssignment.$pending || this.$v.travelAssignment.$error) {
      this.formHasErrors = true;
    }
  }

  resetForm() {
    this.formHasErrors = false;
    this.$v.travelAssignment.$reset();
  }

  goToConfiguration() {
    CustomFieldsStore.setActiveTab('travel-assignments');
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'field-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  removeAssignment() {
    this.removePopup = true;
  }

  async removeAssignmentNow() {
    this.removePopup = false;
    if (!this.travelAssignment.id) {
      return;
    }
    this.errors = [];
    this.loading = true;
    try {
      await CustomFieldTravelAssignmentsApi.deleteTravelAssignment(this.travelAssignment.id);

      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
      if (!this.errors.length) {
        this.goToConfiguration();
      }
    }
  }
  
  async createConfig(data: UpdateFieldConfigurationMessage) {
    try {
      CustomFieldsStore.setLoading(true);
      const response = await CustomFieldsApi.createCustomFieldConfiguration(data);
      if (response && response.data) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      CustomFieldsStore.setErrMessages(error);
      CustomFieldsStore.setShowError(true);
    } finally {
      CustomFieldsStore.setLoading(false);
    }
  }

  async saveAssignment() {
    this.errors = [];
    this.checkForm();
    if (this.formHasErrors) {
      return;
    }

    this.loading = true;

    let configId = this.configurationId;

    if (configId === undefined) {
      const message: UpdateFieldConfigurationMessage = {
        rootCompanyId: this.currentCompany!.rootCompanyId,
        name: this.configuration.name,
      };
      const newConfig = await this.createConfig(message);
      configId = newConfig.configurationId;
      CustomFieldsStore.setConfigurationId(configId);
    }

    const data = this.travelAssignment;
    const request: CreateTravelAssignmentMessage = {
      configurationId: this.configurationId,
      customFieldDefinitionId: data.field ? data.field.id : null,
      customFieldDictionaryItemId: data.field && data.field.dictionary && data.defaultValue ? data.defaultValue.id : null,
      order: data.order,
      isActive: data.isActive,
      assignmentLevel: data.level == null ? '' : data.level.value,
      usageContext: data.usageContext,
      defaultValue: data.field && data.field.dictionary && data.defaultValue ? 
        data.defaultValue.code : data.defaultValue,
      modificationContexts: data.modificationContexts.map(context => {
        return {
          modificationContext: context.modificationContext ? context.modificationContext.value : null,
          canBeModified: context.canBeModified,
          mandatory: context.mandatory,
          isForNonCompliantOnly: context.isForNonCompliantOnly,
          isVisibleOnlyForAgent: this.isBookingContext(context) ? context.isVisibleOnlyForAgent : false,
        };
      }),
      isAvailableForApprovalWorkflow: data.isAvailableForApprovalWorkflow,
      isAvailableForArrangerMatching: data.isAvailableForArrangerMatching && this.isArrangerMatchingPossible,
    };
    try {
      if (data.id) {
        await CustomFieldTravelAssignmentsApi.updateTravelAssignment(data.id, request);
      } else {
        await CustomFieldTravelAssignmentsApi.createTravelAssignment(request);
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
      if (!this.errors.length) {
        this.goToConfiguration();
      }
    }
  }

  created() {
    if (!CustomFieldsStore.configuration.name) {
      this.goToConfiguration();
    }
  }
}

