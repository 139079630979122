




















































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { VueContentLoading } from 'vue-content-loading';

import { userFullName } from '@/core/user-full-name';
import { Journey, Recommendation } from '@/api/train-engine/train-search.model';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';

@Component({
  components: {
    VueContentLoading
  },
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes) as any;
      if (rminutes && rminutes < 10) {
        rminutes = '0' + rminutes;
      }
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class TrainTimelineDetails extends Vue {
  @Prop() journey!: Journey;
  @Prop() offer!: Recommendation;
  @Prop({ default: false }) isShortView!: boolean;
  @Prop({ default: false }) isInSearch!: boolean;

  get travellers() {
    return SearchStore.getTravellersState;
  }

  get user() {
    return AccountStore.current;
  }


  userFullName(user) {
    return userFullName(user);
  }

  momentDate(date) {
    if (date && this.user && (this.user.profile.displayLanguage === 'it' || this.user.profile.displayLanguage === 'pl')) {
      return moment(date).format('dddd, D MMM');
    } else {
      return moment(date).format('dddd, Do MMM');
    }
  }

  getFareConditions(train) {
    return this.offer.proposals.forEach(proposal => {
      if (proposal.segmentKey === train.segmentKey) {
        return proposal.fares[0].shortFareCondidtion;
      }
      return [];
    });
  }

  isAnotherDepartureDay(train) {
    if (train) {
      let startOfDay = moment(this.journey.trains[0].departure).startOf('day');
      let endOfDay = moment(train.departure).endOf('day');

      let diff = endOfDay.diff(startOfDay, 'days');
      if (diff > 0) {
        return diff;
      }
      return 0;
    }
    return 0;
  }

  isAnotherArrivalDay(train) {
    if (train) {
      let startOfDay = moment(this.journey.trains[0].departure).startOf('day');
      let endOfDay = moment(train.arrival).endOf('day');

      let diff = endOfDay.diff(startOfDay, 'days');
      if (diff > 0) {
        return diff;
      }
      return 0;
    }
    return 0;
  }

  passenger(profileId) {
    return this.travellers.travellers.find(item => item.id === profileId) || {};
  }

  getRemarks(remark) {
    return remark.filter(r => r.image !== null);
  }

  flagClasses(train) {
    return {
      'flag__public': 'Public' === train.selectedProposal.isPublic,
      'flag__nego': 'Nego' === train.selectedProposal.isNego,
    };
  }
}
