
































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { LegFlight, Proposal } from '@/api/air-engine/air-search.model';
import AirSearchStore from './air-search.store';
import AccountStore from '@/store/account.store';
import moment from 'moment';

@Component({
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
    monthDate(date) {
      return moment(date).format('MMMM');
    },
    dayStringDate(date) {
      return moment(date).format('dddd');
    },
  }
})
export default class FlightTimeline extends Vue {
  @Prop() leg!: LegFlight;
  @Prop() value!: boolean;
  @Prop() proposal!: Proposal;
  @Prop({ default: false }) enableFilterByFlightNumber!: Boolean;
  @Prop({ default: false }) flightSelected!: Boolean;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: false }) isOneWay!: boolean;
  @Prop({ default: false }) modifyMode!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop({ default: false }) disabledFadedLeg!: boolean;

  internalFlightSelected = this.flightSelected;

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get carrierNames() {
    return this.leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.carrier.name)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
  }

  get carriers() {
    return this.leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.carrier)
      .filter((value, index, self) => self.findIndex((iv) => iv.name === value.name && iv.code === value.code) === index);
  }

  get isAnotherDay() {
    let startOfDay = moment(this.leg.flights[0].departure).startOf('day');
    let endOfDay = moment(this.leg.flights[this.leg.flights.length - 1].arrival).endOf('day');

    return endOfDay.diff(startOfDay, 'days');
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get user() {
    return AccountStore.current;
  }

  get numberOfTechStops() {
    return this.leg.flights.map(flight => {
      return flight.additionalStops && flight.additionalStops.length || 0;
    }).reduce((prev, cur) => prev + cur, 0);
  }

  toggleDetails() {
    this.$emit('input', !this.value);
  }

  flightChecked(e) {
    this.$emit('flightSelected', {
      legHash: this.leg.flightNumberHash,
      selected: e.target.checked
    });
  }

  setFlightSelectedState(state) {
    this.internalFlightSelected = state;
  }

  dayNumberDate(date) {
    if (!date) {
      return null;
    }
    if (this.user && this.user.profile.displayLanguage === 'en' || this.user && this.user.profile.displayLanguage === 'fr' || this.user && this.user.profile.displayLanguage === 'pl') {
      return moment(date).format('D');
    } else {
      return moment(date).format('Do');
    }
  }

  dayNumberLastDate(date) {
    if (date && this.user && this.user.profile.displayLanguage === 'en') {
      return moment(date).format('Do').slice(-2);
    } else {
      return null;
    }
  }
}
