import { router } from '@/router';

export const hasSomeParentTheClass = (element, classname) => {
  if (element.className && element.className.split && element.className.split(' ').indexOf(classname) >= 0) {
    return true;
  }
  return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
};

export const getParentByClass = (element, classname) => {
  if (element.className && element.className.split && element.className.split(' ').indexOf(classname) >= 0) {
    return element;
  }
  if (element.parentNode) {
    return getParentByClass(element.parentNode, classname);
  }
  return null;
};

export class ScrollManager {
  scrollableArea: HTMLElement | null = null;
  scrollStartPos: number = 0;
  scrollDestPos: number = 0;
  scrollAnimationRequest: number | null = null;
  scrollTimestamp: number = 0;
  animDuration: number = 300;
  fastAnimDuration: number = 10;
  fastAnimation: boolean = false;

  animate = () => {
    if (!this.scrollableArea) {
      return;
    }
    this.scrollAnimationRequest = requestAnimationFrame(this.animate);

    const ct = Date.now();
    const [ y, x ] = [ this.scrollableArea.offsetTop, this.scrollableArea.offsetLeft ];
    const animDuration = this.fastAnimation ? this.fastAnimDuration : this.animDuration;

    if (ct - this.scrollTimestamp > animDuration) {
      if (this.scrollableArea.scrollTo) {
        this.scrollableArea.scrollTo(0, this.scrollDestPos);
      } else { // edge fix
        this.scrollableArea.scrollTop = this.scrollDestPos;
        this.scrollableArea.scrollLeft = 0;
      }
      this.clear();

      return;
    }

    const t = (ct - this.scrollTimestamp) / animDuration;
    let current = 0;
    if (t < .5) {
      current = 2 * t * t;
    } else {
      current = 2 * (t - .5) * (1.5 - t) + .5;
    }

    const destX = x / 2;
    const destY = this.scrollStartPos + (this.scrollDestPos - this.scrollStartPos) * current;

    if (this.scrollableArea.scrollTo) {
      this.scrollableArea.scrollTo(destX, destY);
    } else { // edge fix
      this.scrollableArea.scrollTop = destY;
      this.scrollableArea.scrollLeft = destX;
    }
  }

  init(y, container: Element | null) {
    const elements = document.getElementsByClassName('scrollable-area');

    if (container) {
      this.scrollableArea = container as HTMLElement;
      this.scrollStartPos = this.scrollableArea.scrollTop;
      this.scrollDestPos = y;
    } else if (elements.length) {
      this.scrollableArea = elements[0] as HTMLElement;
      this.scrollStartPos = this.scrollableArea.scrollTop;
      this.scrollDestPos = y;
    }
    this.scrollTimestamp = Date.now();
  }

  setFast() {
    this.fastAnimation = true;
  }

  setSlow() {
    this.fastAnimation = false;
  }

  clear() {
    if (!this.scrollAnimationRequest) {
      return;
    }
    cancelAnimationFrame(this.scrollAnimationRequest);
    this.scrollAnimationRequest = null;
  }

  start(y = 0, checkMobile = false, container: Element | null = null) {
    if (checkMobile && window.innerWidth >= 800) {
      return;
    }
    this.clear();
    this.init(y, container);
    this.animate();
  }

  async checkForScrollMarkers(element) {
    let el = element;
    if (element && element.attributes['data-scroll-marker']) {
      el = document.getElementById(element.attributes['data-scroll-marker'].nodeValue);
      if (!el) {
        return null;
      }

      await new Promise(resolve => {
        setTimeout(resolve, 100);
      });
    }

    return el;
  }

  checkForOffset(element) {
    if (element.attributes['data-scroll-offset']) {
      return Number(element.attributes['data-scroll-offset'].nodeValue);
    }

    return 0;
  }

  async scrollTo(el: Element, checkForMobile: boolean = true) {
    let element: Element | null = el;
    element = await this.checkForScrollMarkers(element);
    if (!element) {
      return;
    }
    const offset = this.checkForOffset(element);
    
    const boundingRect = element.getBoundingClientRect();
    const isInPopup = hasSomeParentTheClass(element, 'modal-container') || false;
    let y = 0;

    if (isInPopup) {
      const area = getParentByClass(element, 'modal-container');
      if (area) {
        y = boundingRect.top + offset - 98 + area.scrollTop;
        this.start(y, checkForMobile, area);
      }
    } else {
      const elements = document.getElementsByClassName('scrollable-area');
      if (elements.length) {
        const area = elements[0];

        y = boundingRect.top + offset - 87 + area.scrollTop;

        if (
          router.currentRoute.matched[0].meta &&
          router.currentRoute.matched[0].meta.hasMobileSubMenu
        ) {
          y -= 63;
        }
        this.start(y, checkForMobile, null);
      }
    }
  }
}
