












































import { Vue, Component, Watch } from 'vue-property-decorator';

import HotelSort from './HotelSort.vue';
import layoutStore from '@/modules/layout/layout.store';

import { router } from '@/router';
import HotelSearchStore from './hotel-search.store';
import EventBus from '@/services/event-handler';


@Component({
  components: {
    HotelSort,
  }
})
export default class HotelSubintro extends Vue {
  toggled: boolean = true;

  get sidebarToggled() {
    return !layoutStore.sidebarHidden;
  }

  get filters() {
    return HotelSearchStore.filters;
  }

  get offers() {
    return HotelSearchStore.offers;
  }

  @Watch('sidebarToggled', { immediate: true })
    onToggled(value) {
      this.toggled = value;
  }

  @Watch('toggled')
    onToggledClick(value) {
      if (value !== this.sidebarToggled) {
        layoutStore.toggleSidebar();
      }
    
  }

  showMobileFilters() {
    router.push({
      name: 'hotelFilters',
    });
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}
