







































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketItem from './BasketItem.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketStore from './basket.store';
import { getLoyaltyName } from '@/modules/profile/loyalty-programs/loyalty-name.service';

@Component({
  components: {
    BasketItem,
    BasketExpirationCountdown,
  }
})
export default class BasketLoyaltyProgramsView extends Vue {
  @Prop() basketExpired!: boolean;

  loaded = false;
  selectedTraveller: any | null = null;
  selectedLoyalties = [];

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get basket() {
    return BasketStore.basket;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get loading() {
    return BasketStore.loadingLoyaltyPrograms;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get basketLoyaltyPrograms() {
    return BasketStore.basketLoyaltyPrograms;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get loadingLoyaltyPrograms() {
    return BasketStore.loadingLoyaltyPrograms;
  }

  get isAttachedInBooking() {
    return this.selectedLoyaltyProgram && this.selectedLoyaltyProgram.isAttachedInBooking;
  }

  get isSelectingLoyaltyDisabled() {
    return this.loadingLoyaltyPrograms || this.isAttachedInBooking;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'LOYALTY_PROGRAMS') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return null;
  }

  get availableLoyalties() {
    const loyalties = BasketStore.basketLoyaltyPrograms
      .filter(item => {
        return item.ownerId === this.selectedTraveller.id;
      });

    const defaultValue: any = {
      id: null,
      label: this.$t('basket.loyalty-none'),
    };
    return [defaultValue].concat(loyalties);
  }

  get selectedLoyaltyProgram() {
    if (!this.item) {
      return null;
    }

    const loyalty = BasketStore.selectedLoyalties.find(item => {
      return item.travellerProfileId === this.selectedTraveller.id &&
        item.tripItemId === this.item!.id;
    });

    if (loyalty) {
      const result = this.availableLoyalties.find(l => l.id === loyalty.loyaltyCardId); 
      return result;
    }

    return this.availableLoyalties[0];
  }

  set selectedLoyaltyProgram(value) {
    if (!this.item) {
      return;
    }

    if (!value || !value.id) {
      BasketStore.unsetSelectedLoyalty({
        tripItemId: this.item.id,
        travellerProfileId: this.selectedTraveller.id,
      });
      return;
    }
    
    BasketStore.setSelectedLoyalty({
      tripItemId: this.item.id,
      travellerProfileId: this.selectedTraveller.id,
      loyaltyCardId: value.id,
    });
  }

  get travellerUsesDiscountCard() {
    return this.selectedTraveller.useDiscountCard === true;
  }

  userFullName(user) {
    return userFullName(user);
  }

  getLoyaltyName = getLoyaltyName;

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.resetSelectedLoyalties({ tripItemId: this.itemId });
    this.selectedTraveller = this.travellers[0];

    await BasketStore.loadLoyaltyPrograms({
      tripItemId: this.itemId
    });

    this.initializeTravellersSelectedLoyalties();
    BasketStore.setBookingStepLoading(false);
  }

  initializeTravellersSelectedLoyalties() {
    for (const traveller of this.travellers) {
      const preferredLoyalty = BasketStore.basketLoyaltyPrograms.find(x => x.ownerId === traveller.id && x.isPreferred);

      if (preferredLoyalty) {
        BasketStore.setSelectedLoyalty({
          tripItemId: this.itemId,
          travellerProfileId: traveller.id,
          loyaltyCardId: preferredLoyalty.id,
        });
      }
    }
  }

  selectTraveller(traveller, index) {
    this.selectedTraveller = traveller;
    const element = (this.$refs.traveller as any[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }
}

