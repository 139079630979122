











































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { AvailableTimeFormats } from '@/api/profile/company.model';
import { ToTwoDigitStringValue } from '@/core/utils';

interface TimeModel {
  displayName: string;
  name: string;
  value: Array<number> | number;
  isSelected: boolean;
}

const timeUpdateEvent = 'update:time';

@Component({})
export default class UiTimePicker extends Vue {
  @Prop() title!: string;
  @Prop() mode!: string;
  @Prop() time!: TimeModel;
  @Prop() singularTimeModel!: boolean;
  @Prop() hideTimeWindows!: boolean;
  @Prop() sliderStep!: number;
  @Prop({ default: false }) ifReversedSliderColors!: boolean; 

  minValue: number = 0;
  minLimit: number = 0;
  maxLimit: number = 1439;
  isDisabled: boolean = false;
  timeOfDay: Array<TimeModel> = [
    {
      displayName: translate('search-air.all-day'),
      name: 'allday',
      value: [0, 1439],
      isSelected: false,
    },
    {
      displayName: translate('search-air.morning'),
      name: 'morning',
      value: [0, 719],
      isSelected: false,
    },
    {
      displayName: translate('search-air.afternoon'),
      name: 'afternoon',
      value: [720, 1079],
      isSelected: false,
    },
    {
      displayName: translate('search-air.evening'),
      name: 'evening',
      value: [1080, 1439],
      isSelected: false,
    },
  ];

  timeOfDaySingle: Array<TimeModel> = [
    {
      displayName: translate('search-air.all-day'),
      name: 'allday',
      value: 0,
      isSelected: false,
    },
    {
      displayName: translate('search-air.morning'),
      name: 'morning',
      value: 719,
      isSelected: false,
    },
    {
      displayName: translate('search-air.afternoon'),
      name: 'afternoon',
      value: 1079,
      isSelected: false,
    },
    {
      displayName: translate('search-air.evening'),
      name: 'evening',
      value: 1439,
      isSelected: false,
    },
  ];

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get timeStep(): number {
    return this.sliderStep ? this.sliderStep : 15;
  }

  get timeValue() {
    return this.singularTimeModel ?
    (this.time ? (this.time.value ? this.time.value : this.time) : 0) :
    this.time ? (this.time.value ? this.time.value : this.time) : [540, 1020];
  }

  set timeValue(value) {
    this[timeUpdateEvent](value);
  }

  @Emit()
  [timeUpdateEvent](val) {
    return val;
  }

  get displayValue() {
    if (this.timeValue) {
      const h1 = Math.floor(this.timeValue[0] / 60);
      const hours1 = ToTwoDigitStringValue(h1);
      const minutes1 = ToTwoDigitStringValue(this.timeValue[0] - (h1 * 60));
      const h2 = Math.floor(this.timeValue[1] / 60);
      const hours2 = ToTwoDigitStringValue(h2);
      const minutes2 = ToTwoDigitStringValue(this.timeValue[1] - (h2 * 60));
      const from = moment(`2000-01-01T${hours1}:${minutes1}:00.000Z`).utc(false).format(this.currentTimeFormat);
      const to = moment(`2000-01-01T${hours2}:${minutes2}:00.000Z`).utc(false).format(this.currentTimeFormat);

      return from + ' - ' + to;
    }

    return '';
  }

  get displayValueSingle() {
    if (this.timeValue || this.timeValue === 0) {
      const h = Math.floor(this.timeValue as number / 60);
      const hours = ToTwoDigitStringValue(h);
      const minutes = ToTwoDigitStringValue(this.timeValue as number - (h * 60));

      return moment(`2000-01-01T${hours}:${minutes}:00.000Z`).utc(false).format(this.currentTimeFormat);
    }
  }

  isSelected(time) {
    for (let i = 0; i < this.timeOfDay.length; i++) {
      if (
        time.value[0] === this.timeValue[0] &&
        time.value[1] === this.timeValue[1]
      ) {
        return true;
      }
    }
    return false;
  }

  selectedTimeOfDay(time) {
    this.timeOfDay.forEach(time => time.isSelected = false);
    time.isSelected = true;
    this[timeUpdateEvent](time.value);
  }

  manualChange(time) {
    this.timeOfDay.forEach(time => time.isSelected = false);
    this[timeUpdateEvent](time);
  }

  setActive(value) {
    (this.$parent as any).isVisible = value;
    (this.$parent as any).isFocused = value;
  }

  created() {
    if (this.time) {
      this.timeOfDay.forEach((list) => {
        if (list.name === this.time.name) {
          list.isSelected = true;
        }
      });
    }
  }
}
