import ProviderMapping from '@/modules/settings/custom-fields-configuration/ProviderMapping.vue';

export default {
    path: ':configurationId?/provider-mapping/:providerMappingId?',
    name: 'provider-mapping',
    component: ProviderMapping,
    displayName: 'Provider mapping',
    meta: {
        permission: 'ReadCustomFieldDefinitions',
        parentName: 'custom-fields',
        depthCategory: 'custom-fields',
        introClass: 'intro-settings',
        depth: 3,
    }
};