








































































import { Vue, Component } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import { router } from '@/router';
import { WonderSearchApi } from '@/api/wonder-search/wonder-search.api';

@Component({})
export default class JourneyAssistant extends Vue {
  messages: any[] = [];
  errors: any[] = [];
  message: string = '';
  conversationId: string = '';
  hasScrollbar: boolean = false;
  loading: boolean = false;
  imagesConst = '/assets/img/loader/1.gif';



  get currentUser() {
    if (!AccountStore.current) {
      return null;
    }
    return AccountStore.current.profile;
  }



  async sendMessage() {
    if (!this.message.length || this.loading) {
      return;
    }
    try {
      const message = this.message;
      this.message = '';
      this.messages.push({
        content: message,
        type: 'User',
      });
      this.messages.push({
        loading: true,
        type: 'System',
      });
      this.$nextTick(() => {
        this.scrollDown();
      });
      this.loading = true;
      const response = await WonderSearchApi.sendUserQuestion(this.conversationId, message);

      for (let i = 0; i < 100; i++) {
        const msgsResponse = await WonderSearchApi.getConversation(this.conversationId);
        this.messages = msgsResponse.data.messages;
        
        this.$nextTick(() => {
          this.scrollDown();
        });


        if (this.messages[this.messages.length - 1].fullContent) {
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 500));
      }

      /*const messages = response.data.messages;
      this.messages.splice(this.messages.length - 2, 2);
      messages.forEach(item => {
        this.messages.push(item);
      });*/
      this.loading = false;
      this.$nextTick(() => {
        this.scrollDown();
      });

      if (this.messages[this.messages.length - 1].type === 'Redirect') {
          let basketId = this.messages[this.messages.length - 1].redirectData;

          router.push({
            name: 'basket',
            params: { id: basketId }
          });
      }      
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  scrollDown() {
    const scrollableArea = this.$refs.chat as HTMLElement;

    this.hasScrollbar = scrollableArea.scrollHeight > scrollableArea.clientHeight;
    if (this.hasScrollbar) {
      if (scrollableArea.scrollTo) {
        scrollableArea.scrollTo(0, scrollableArea.scrollHeight - scrollableArea.clientHeight);
      } else { // edge fix
        scrollableArea.scrollTop = scrollableArea.scrollHeight - scrollableArea.clientHeight;
        scrollableArea.scrollLeft = 0;
      }
    }
  }

  async created() {
    try {
      this.conversationId = this.$route.params.conversationId;
      const msgsResponse = await WonderSearchApi.getConversation(this.conversationId);

      this.messages = msgsResponse.data.messages;

      if (this.messages[this.messages.length - 1].type === 'Redirect') {
          let basketId = this.messages[this.messages.length - 1].redirectData;

          router.push({
            name: 'basket',
            params: { id: basketId }
          });
      }  
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }
}

