


































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';

@Component({})
export default class BasketRailTravellerDiscountCodes extends Vue {
  @Prop() traveller!: any;
  @Prop() index!: any;

  get forwardDiscountCode(): string {
    return this.traveller.codes.forwardDiscountCode;
  }

  set forwardDiscountCode(code: string) {
    BasketStore.setTravellersForwardDiscountCode({
      code,
      travellerId: this.traveller.id,
    });
  }

  get returnDiscountCode(): string {
    return this.traveller.codes.returnDiscountCode;
  }

  set returnDiscountCode(code: string) {
    BasketStore.setTravellersReturnDiscountCode({
      code,
      travellerId: this.traveller.id,
    });
  }

  get isForwardCodeError() {
    return this.traveller.codes.forwardDiscountStatus === 'Error';
  }

  get isReturnCodeError() {
    return this.traveller.codes.returnDiscountCodeStatus === 'Error';
  }

  get forwardCodeError() {
    return [{
      message: this.traveller.codes.forwardDiscountCodeMessage,
    }];
  }

  get returnCodeError() {
    return [{
      message: this.traveller.codes.returnDiscountCodeMessage,
    }];
  }



  userFullName(user) {
    return userFullName(user);
  }

  onForwardCodeInput(id) {
    if (this.isForwardCodeError) {
      EventBus.$emit('clearRailForwardCodeError', id);
    }
  }

  onReturnCodeInput(id) {
    if (this.isReturnCodeError) {
      EventBus.$emit('clearRailReturnCodeError', id);
    }
  }
}
