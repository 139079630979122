import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  HotelFeesConfiguration,
} from './accommodation-fees-configuration.model';

class AccommodationFeesConfigurationApiClass {
  public getFeeConfiguration(configurationId: string): Promise<AxiosResponse<HotelFeesConfiguration>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/api/fees-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public createFeeConfiguration(request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/api/fees-configurations/{id}',
      method: RequestMethod.POST,
    }, {
      id: request.configurationId,
    }, request);
  }

  public updateFeeConfiguration(configurationId: string, request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/api/fees-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, request);
  }
}

export const AccommodationFeesConfigurationApi: AccommodationFeesConfigurationApiClass = new AccommodationFeesConfigurationApiClass();
