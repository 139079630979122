import AirRailResults from '@/modules/search/air-rail/AirRailResults.vue';
import AirRailSearchShortParams from '@/modules/search/air-rail/AirRailSearchShortParams.vue';
import AirRailSubintro from '@/modules/search/air-rail/AirRailSubintro.vue';
import AirRailResultsFilters from '@/modules/search/air-rail/AirRailResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/air-rail-results/:searchId',
  name: 'airRail',
  components: {
    default: AirRailResults,
    intro: AirRailSearchShortParams,
    sidebar: AirRailResultsFilters,
    subintro: AirRailSubintro,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    preserveSidebar: {
      airRailDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airRailDetails: AnimationName.DOWN,
        airRailTravellers: AnimationName.DOWN,
      },
    }
  }
};
