
































import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TTrainTile } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import AccountStore from '@/store/account.store';

@Component({})
export default class TrainsTile extends Vue {
  @Prop() trains?: TTrainTile[];
  @Prop({ default: false }) isIncluded!: boolean;
  @Prop() icon!: string;
  @Prop() title!: string;

  get sortedTrains() {
    return (this.trains || []).sort((a, b) => moment(a.departureDate).diff(moment(b.departureDate)));
  }

  getDateFormat(date: string) {
    const dateFormat = AccountStore.current!.profile.shortDateFormat;
    const timeFormat = AccountStore.current!.profile.timeFormat;
    return moment(date).format(`${dateFormat} ${timeFormat}`);
  }
}
