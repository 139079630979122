









import { Vue, Component, Prop } from 'vue-property-decorator';

import { BasketFlatItemModel } from '@/api/trip/basket.model';
import BasketItemAdditionalMessage from './BasketItemAdditionalMessage.vue';

@Component({
  components: {
    BasketItemAdditionalMessage,
  },
})
export default class BasketItemAdditionalMessages extends Vue {
  @Prop() item!: BasketFlatItemModel;

}

