






























import { Vue, Component, Prop } from 'vue-property-decorator';

import AirSearchStore from '@/modules/search/air/air-search.store';
import { FlightDetails } from '@/api/air-engine/air-search.model';


@Component({})
export default class AirResultGroupFlightError extends Vue {
  @Prop() offer!: FlightDetails;
  @Prop() searchModeType!: string;
  

  get isAnyAirportDifferent() {
    if (this.offer.legs && this.offer.legs.length === 2) {
      const firstLeg = this.offer.legs[0];
      const lastLeg = this.offer.legs[this.offer.legs.length - 1];
      const firstFlightOfFirstLeg = firstLeg.flights[0].from.airportCode;
      const lastFlightOfFirstLeg = firstLeg.flights[firstLeg.flights.length - 1].to.airportCode;
      const firstFlightOfLastLeg = lastLeg.flights[0].from.airportCode;
      const lastFlightOfLastLeg =  lastLeg.flights[lastLeg.flights.length - 1].to.airportCode;

      if (firstFlightOfFirstLeg !== lastFlightOfLastLeg) {
        return true;
      }
      if (lastFlightOfFirstLeg !== firstFlightOfLastLeg) {
        return true;
      }
    }

    return false;
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => expire.recommendationId === this.offer.id);
  }  
}
