








































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, email, numeric, minLength, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';

import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { HomeApi } from '@/api/home/home.api';
import { ContactsApi } from '@/api/profile/contacts.api';
import { translate } from '@/i18n';
import BasketStore from './basket.store';
import AccountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import DictionaryStore from '@/store/dictionary.store';
import { GuestTraveller } from '@/api/trip/basket.model';
import { PhoneCode } from '@/api/profile/contacts.model';
import EventBus from '@/services/event-handler';
import { BasketApi } from '@/api/trip/basket.api';
import { DateOfBirthObligation } from '@/api/home/home.model';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import { userFullName } from '@/core/user-full-name';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import { TravellerTypeMappings } from '@/const/traveller-types.const';


@Component({
  components: {
    BasketExpirationCountdown,
  }
})
export default class BasketEnterTravellers extends Vue {
  @Prop() basketExpired!: boolean;

  passengerTypes: string[] = [];
  options: any[] = [];
  isLoading: boolean = false;
  guestsLoading: boolean = true;
  serverErrors: any[] = [];
  showError: boolean = false;
  $v;
  guestExistingProfiles: any[] = [];
  guestNewProfiles: any[] = [];
  guestIsVirtual: any[] = [];
  guestsTravellersToSave: any[] = [];
  useTheSameContactData: boolean = true;
  isDataLoaded: boolean = false;

  travellerTypeMappingsForSearch = {
    'ADT': 'Adult',
    'YTH': 'Youth',
    'CHD': 'Child',
    'INF': 'InfantWithoutSeat',
    'SRC': 'Senior',
    'SEA': 'Seaman',
    'MLT': 'Military',
  };

  phoneCodeEmpty: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: translate('common.phone-code'),
    fullPhoneCodeDisplayName: translate('common.none'),
  };

  email: string = '';
  phoneCode: PhoneCode = this.phoneCodeEmpty;
  phoneNumber: string = '';
  isMobile: boolean = false;

  titleOptions = [
    {
      value: 'Mr',
      name: translate('common.mr'),
    },
    {
      value: 'Mrs',
      name: translate('common.mrs'),
    },
    {
      value: 'Miss',
      name: translate('common.miss'),
    },
  ];

  @Validation()
  validationObject() {
    let v: any = {
      guestNewProfiles: {
        $each: {
          title: {
            requiredIf: (item, model) => {
              return model.isGuest ? !!(model.title && model.title.value) : true;
            },
          },
          firstName: {
            requiredIf: requiredIf((item) => {
              return item.isGuest;
            }),
            isNameValid,
          },
          middleName: {
            isNameValid: (value) => (value ? isNameValid(value) : true),
          },
          lastName: {
            requiredIf: requiredIf((item) => {
              return item.isGuest;
            }),
            isNameValid,
          },
          email: {
            requiredIf: requiredIf((item) => {
              return item.isGuest && !(this.allowRefuseContactDetails && item.refusedProvidingContactDetails);
            }),
            email,
          },
          phoneCode: {
            requiredIf: requiredIf((item) => {
              return item.isGuest && !(this.allowRefuseContactDetails && item.refusedProvidingContactDetails);
            }),
            validPhoneCode: (codeObject, item) => {
              return (!!codeObject && !!codeObject.phoneCode) || (this.allowRefuseContactDetails && item.refusedProvidingContactDetails);
            }
          },
          phoneNumber: {
            requiredIf: requiredIf((item) => {
              return item.isGuest && !(this.allowRefuseContactDetails && item.refusedProvidingContactDetails);
            }),
            minLength: minLength(3),
            numeric,
          },
          dateOfBirth: {
            requiredIf: requiredIf((item) => {
              const trav = this.travellers.find(el => el.id === item.id);

              if (!trav) {
                return false;
              }
              return trav.dateOfBirthObligation === DateOfBirthObligation.Required;
            }),
          },
        }
      },
      guestExistingProfiles: {
        $each: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            requiredIf: requiredIf((item) => {
              return item.isVirtual;
            }),
            email,
          },
          phoneCode: {
            requiredIf: requiredIf((item) => {
              return item.isVirtual;
            }),
          },
          phoneNumber: {
            requiredIf: requiredIf((item) => {
              return item.isVirtual;
            }),
            minLength: minLength(3),
            numeric,
          }
        }
      },
    };

    if (this.useTheSameContactData) {
      v = {
        ...v,
        email: {
          required,
          email,
        },
        phoneCode: {
          required,
          validPhoneCode: (codeObject) => {
            return !!codeObject && !!codeObject.phoneCode;
          }
        },
        phoneNumber: {
          required,
          minLength: minLength(3),
          numeric,
        },
      };
    }
    return v;
  }

  get selectedExistingTraveller() {
    return BasketStore.selectedExistingTraveller;
  }

  get guestTravellers() {
    return BasketStore.guestBasketTravellers;
  }

  get allowRefuseContactDetails() {
    return BasketStore.allowRefuseContactDetails;
  }

  get basketEnterTravellersErrors() {
    return BasketStore.basketEnterTravellersErrors;
  }

  get shouldShowUiTitleErrors() {
    return BasketStore.shouldShowUiTitleErrors;
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get travellers() {
    return SearchStore.getTravellersState.travellers;
  }

  get profile() {
    return AccountStore.current!.profile;
  }

  get phoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    let allPhoneCodes = DictionaryStore.allCountries.filter((country) => { return country && country.phoneCode!.length > 0; })
    .map((country) => {
      return { 
        ...country,
        shortPhoneCodeDisplayName: '+' + country.phoneCode,
        fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
      };
    });

    return [this.phoneCodeEmpty].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get formError() {
    const errors = this.guestIsVirtual.map(({ isVirtual }, index) => {
      if (isVirtual) {
        return this.$v.guestNewProfiles.$each[index].$anyError;
      }
      return this.$v.guestExistingProfiles.$each[index].$anyError;
    });
    return !!errors.find(isError => isError);
  }

  get mainTravellerIndex() {
    return BasketStore.guestMainTravellerIndex;
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get hasSimilarTravellers() {
    return BasketStore.hasSimilarTravellers;
  }

  get travellersExistence() {
    return BasketStore.travellersExistence;
  }

  get preferredDateFormat() {
    return AccountStore.current!.profile.shortDateFormat;
  }

  get hasSomeGuestData() {
    return BasketStore.guestBasketTravellers.some(guest => {
      return guest.title ||
        guest.firstName ||
        guest.lastName ||
        guest.dateOfBirth ||
        guest.email ||
        guest.phoneCode ||
        guest.phoneNumber ||
        guest.isMobile;
    });
  }

  get noneOrOneGuestProfile() {
    return 1 >= this.guestIsVirtual.filter(guest => !!guest.isVirtual).length;
  }

  get onlyVirtualGuests() {
    return this.guestIsVirtual
      .map((guest, index) => ({
        index,
        isVirtual: guest.isVirtual,
      }))
      .filter(item => item.isVirtual)
      .map(item => this.guestNewProfiles[item.index]);
  }

  get emailSame() {
    return this.email;
  }

  set emailSame(value) {
    this.email = value;
    this.$v.email.$touch();

    this.onlyVirtualGuests
      .forEach(guest => guest.email = value);
  }

  get phoneCodeSame() {
    return this.phoneCode;
  }

  set phoneCodeSame(value) {
    this.phoneCode = value;
    this.$v.phoneCode.$touch();

    this.onlyVirtualGuests
      .forEach(guest => guest.phoneCode = value);
  }

  get phoneNumberSame() {
    return this.phoneNumber;
  }

  set phoneNumberSame(value) {
    this.phoneNumber = value;
    this.$v.phoneNumber.$touch();

    this.onlyVirtualGuests
      .forEach(guest => guest.phoneNumber = value);
  }

  get isMobileSame() {
    return this.isMobile;
  }

  set isMobileSame(value) {
    this.isMobile = value;

    this.onlyVirtualGuests
      .forEach(guest => guest.isMobile = value);
  }

  onRefusedProvidingContactDetailsChange(index: number) {
    if (this.guestNewProfiles[index].refusedProvidingContactDetails) {
      this.useTheSameContactData = false;
    }
    this.guestNewProfiles = this.guestNewProfiles.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          email: null,
          phoneCode: {},
          phoneNumber: null,
          isMobile: false,
        };
      }
      return item;
    });
  }

  userFullName(user) {
    return userFullName(user);
  }

  getPreferredDate(date) {
    if (this.preferredDateFormat) {
      return moment(date).format(this.preferredDateFormat);
    } else {
      return date;
    }
  }

  onPhoneNumberCodeChange(index) {
    let nextInput: HTMLElement | null = null;

    if (isNaN(index)) {
      nextInput = (this.$refs['travellerPhoneNumber_' + index] as Vue).$refs.input as HTMLElement;
    } else {
      nextInput = (this.$refs['travellerPhoneNumber_' + index] as Vue)[0].$refs.input as HTMLElement;
    }
    setTimeout(() => {
      if (nextInput) {
        nextInput.focus();
      }
    });
    this.$forceUpdate();
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async asyncFind(query: string, passengerTypeCode) {
    if (query && query.length > 0) {
      this.isLoading = true;
      this.showError = false;
      let response;
      try {
        if (this.travellers && this.travellers.length) {
          let travellerType = passengerTypeCode ? this.travellerTypeMappingsForSearch[passengerTypeCode] : '';
          let companyId = this.travellers[0].businessUnitId;
          response = await HomeApi.findTravellerInbusinessUnitId(
            query, companyId, travellerType
          );
        } else {
          response = await HomeApi.findTraveller({
            query
          });
        }

        if (response && response.data) {
          this.options = response.data.map(e => {
            return {
              ...e,
              isVirtual: false,
              isMainTraveller: false,
            };
          }).filter(traveller => {
            let travellerIds = this.guestExistingProfiles.map(profile => {
              return profile.id;
            });
            return -1 === travellerIds.indexOf(traveller.id);
          });
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.serverErrors = this.$handleErrors(error, true);
      }
    } else {
      this.options = [];
      this.isLoading = false;
    }
  }

  @Watch('guestTravellers')
  onGuestChange(data) {
    if (data.length) {
      this.guestsLoading = false;
    }
    data.forEach(async (trav, index) => {
      if (trav.profileId && !trav.passengerTypeCode) {
        try {
          const contactsResponse = await ContactsApi.getContacts(trav.profileId);
          trav.contacts = contactsResponse.data.primary;
          trav.guestId = this.travellers[index].id;
        } catch (error) {
          this.serverErrors = this.$handleErrors(error);
        }
      }
    });
  }

  @Watch('isDataLoaded', { immediate: true })
  onDataLoaded(value) {
    if (!value) {
      return;
    }
    if (this.noneOrOneGuestProfile) {
      this.useTheSameContactData = false;
    }
  }

  @Watch('noneOrOneGuestProfile', { immediate: true })
  onNoneOrOneGuestProfileChange(value) {
    if (!this.isDataLoaded) {
      return;
    }
    if (value) {
      this.useTheSameContactData = false;
    }
  }

  @Watch('formError')
  onFormErrorChange(value) {
    BasketStore.setGuestBasketTravellersError(value);
  }

  dateOfBirthRequired(index) {
    return this.travellers[index].dateOfBirthObligation === DateOfBirthObligation.Required;
  }

  dateOfBirthRecommended(index) {
    return this.travellers[index].dateOfBirthObligation === DateOfBirthObligation.Recommended;
  }

  resetExistingTraveller(index) {
    if (this.selectedExistingTraveller && this.selectedExistingTraveller.length > 1) {
      this.selectedExistingTraveller.splice(index, 1);
      BasketStore.setSelectedExistingTraveller(this.selectedExistingTraveller);
    } else {
      BasketStore.setSelectedExistingTraveller([]);
    }
  }

  onSelectTraveller(index) {
    this.options = [];
    this.guestTravellers[index].isVirtual = false;
    const selection = this.$refs['travellerSelect_' + index] as Vue;
    const selectionEl = selection[0].$el.getElementsByTagName('input')[0] as HTMLElement;
    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    }
  }

  onReset(index) {
    this.options = [];
    const resetedValue = {
      ...new GuestTraveller({
        ...this.travellers[index],
      }),
      isVirtual: false,
    };
    Object.keys(resetedValue).forEach(key => {
      this.guestExistingProfiles[index][key] = resetedValue[key];
    }
    );
    BasketStore.setGuestBasketTraveller({
      traveller: this.guestExistingProfiles[index],
      index
    });
  }

  travellersExistenceData(guestProfile) {
    return this.travellersExistence.filter(traveller => traveller.id === guestProfile.id).map(traveller => traveller.existingTravellers).flat();
  }

  onTitleSelect(index) {
    const el = (this.$refs['firstNameInput_' + index] as Vue);
    const selectionEl = el[0].$el.getElementsByTagName('input')[0] as HTMLElement;
    if (selectionEl) {
      this.$nextTick(() => {
        selectionEl.focus();
      });
    }
    this.$forceUpdate();
  }

  onRemoveTraveller(index) {
    this.guestTravellers[index] = new GuestTraveller();
  }

  findGuestDataFromTravellers(travId) {
    if (this.travellers && this.travellers.length) {
      let exist: any = this.travellers.find(trav => trav.id === travId);
      if (exist) {
        return exist.isGuest;
      }
    }
    return false;
  }

  getSuggestedPhoneCode() {
    const profileLang = this.profile ? this.profile.displayLanguage : null;
    switch (profileLang) {
      case 'en':
        return this.phoneCountryCodes.find(code => {
          return code.code === 'US';
        });
      case 'it':
        return this.phoneCountryCodes.find(code => {
          return code.code === 'IT';
        });
      case 'fr':
        return this.phoneCountryCodes.find(code => {
          return code.code === 'FR';
        });
      default:
        return null;
    }
  }

  onGuestTypeChange(value, index) {
    this.$v.$reset();
    BasketStore.setGuestBasketTraveller({
      traveller: new GuestTraveller({
        ...this.guestTravellers[index],
        isVirtual: value,
      }),
      index
    });
    this.resetExistingTraveller(index);
    if (value) {
      const newTraveller = {
        ...new GuestTraveller(this.guestTravellers[index]),
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: '',
        phoneCode: this.getSuggestedPhoneCode(),
        phoneNumber: '',
        contacts: null,
        middleName: '',
        isGuest: true,
        title: null,
        isMobile: false,
        isVirtual: value,
        refusedProvidingContactDetails: false,
      };

      Object.keys(newTraveller).forEach(key => {
        this.guestNewProfiles[index][key] = newTraveller[key];
      });
    }
  }

  

  disabledDefaultTravellers() {
    if (!this.guestExistingProfiles || !this.guestExistingProfiles.length ||
      !this.travellersExistence || !this.travellersExistence.length
    ) {
      return;
    }

    this.guestExistingProfiles
      .filter((guestData, index) => {
        return !this.guestIsVirtual[index].isVirtual;
      })
      .forEach(guestExist => {
        const id = guestExist.id;
        this.travellersExistence
          .filter(travellerExist => travellerExist && travellerExist.existingTravellers)
          .forEach(travellerExist => {
            const exist = travellerExist.existingTravellers.find(existTrav => existTrav.id === id);
            if (exist) {
              exist.disabled = true;
            }
          });
      });
  }

  validateAndProceed() {
    if (!this.hasSimilarTravellers) {
      this.guestTravellers.forEach((trav, index) => {
        if (this.guestIsVirtual[index].isVirtual) {
          this.$v.guestNewProfiles.$each[index].$touch();
        } else {
          this.$v.guestExistingProfiles.$each[index].$touch();
        }
        if (this.formError) {
          BasketStore.setGuestBasketTravellersError(true);
        }
      });
      if (this.useTheSameContactData) {
        this.$v.email.$touch();
        this.$v.phoneCode.$touch();
        this.$v.phoneNumber.$touch();
      }
    }
    BasketStore.setGuestBasketTravellersChecked(true);
  }

  async saveVirtualGuest(newProfile, index) {
    const oldId = this.guestTravellers[index].id;
    let traveller: any = {
      title: newProfile.title ? newProfile.title.value : '',
      profileId: null,
      id: newProfile.id,
      isMainTraveller: newProfile.isMainTraveller,
      firstName: newProfile.firstName,
      lastName: newProfile.lastName,
      middleName: newProfile.middleName || '',
      dateOfBirth: newProfile.dateOfBirth ? moment(newProfile.dateOfBirth).format('yyyy-MM-DD') : null,
      companyId: newProfile.companyId,
      companyName: newProfile.companyName,
      businessUnitId: newProfile.businessUnitId,
      businessUnitName: newProfile.businessUnitName,
      passengerTypeCode: newProfile.passengerTypeCode,
      isVirtual: newProfile.isVirtual,
      email: newProfile.email,
      isGuest: newProfile.isGuest,
    };
    if (this.allowRefuseContactDetails && newProfile.refusedProvidingContactDetails) {
      traveller = {
        ...traveller,
        refusedProvidingContactDetails: newProfile.refusedProvidingContactDetails
      };
    }
    if (traveller.profileId !== traveller.id) {
      const apiResponse = await BasketApi.saveTraveller(
        { 
          tripId: BasketStore.bookingStepDef.tripId,
          traveller: {
            ...traveller,
            contacts: {
              primary: {
                email: newProfile.email,
                phone: {
                  code: newProfile.phoneCode.phoneCode,
                  number: newProfile.phoneNumber,
                  isMobile: newProfile.isMobile,
                }
              }
            }
          }
        }
      );
      if (apiResponse && apiResponse.data) {
        const newId = apiResponse.data.profileId;
        this.guestsTravellersToSave.push({
          ...traveller,
          phoneCode: newProfile.phoneCode.phoneCode,
          phoneNumber: newProfile.phoneNumber,
          isMobile: newProfile.isMobile,
          email: newProfile.email,
          isVirtual: false,
          isGuest: newProfile.isGuest,
          oldId,
          id: newId,
          profileId: newId,
        });
      }
    } else {
      this.guestsTravellersToSave.push(traveller);
    }
  }

  async saveNonVirtualProfile(existingProfile, index) {
    const traveller: any = {
      ...existingProfile,
      id: this.travellers[index].id,
      profileId: existingProfile.id,
      isMainTraveller: this.travellers[index].isMainTraveller,
      passengerTypeCode: this.travellers[index].passengerTypeCode ? this.travellers[index].passengerTypeCode : null,
    };

    if (traveller.id !== traveller.profileId) {
      const apiResponse = await BasketApi.saveTraveller({
        tripId: BasketStore.bookingStepDef.tripId,
        traveller,
      });

      if (apiResponse && apiResponse.data) {
        this.guestsTravellersToSave.push({
          ...traveller,
          id: apiResponse.data.profileId,
          isVirtual: false,
        });
      }
    } else {
      this.guestsTravellersToSave.push(traveller);
    }
  }

  async saveSingleGuest(index) {
    if (index >= this.guestTravellers.length) {
      return;
    }

    const existingProfile = this.selectedExistingTraveller[index] ? this.selectedExistingTraveller[index] : this.guestExistingProfiles[index];
    const newProfile = this.guestNewProfiles[index];
    if (!this.guestTravellers[index].isVirtual || (this.selectedExistingTraveller && this.selectedExistingTraveller[index] && this.selectedExistingTraveller[index].id !== newProfile.id)) {
      await this.saveNonVirtualProfile(existingProfile, index);
    } else {
      await this.saveVirtualGuest(newProfile, index);
    }
    
    await this.saveSingleGuest(index + 1);
  }

  async checkGuestExist() {
    let newProfiles: any[] = [];
    this.guestNewProfiles.forEach(profile => {
      if (profile.firstName && profile.lastName && profile.email) {
        newProfiles.push({
          id: profile.id,
          firstName: profile.firstName,
          lastName: profile.lastName,
          primaryEmail: profile.email,
          middleName: profile.middleName || '',
          travellerType: profile.passengerTypeCode ? this.travellerTypeMappingsForSearch[profile.passengerTypeCode] : '',
          businessUnitId: this.travellers[0].businessUnitId,
        });
      }
    });
    let travellers = {
      rootCompanyId: this.travellers[0].businessUnitId,
      travellers: newProfiles
    };
    await BasketStore.checkTravellersExistence(travellers);
  }

  isDisabledTraveller(traveller) {
    let selectedIds = this.selectedExistingTraveller.map(trav => trav.id);
    return selectedIds.indexOf(traveller.id) >= 0;
  }

  async checkGuest() {
    if (this.anyVirtualTraveller()) {
      await this.checkGuestExist();
    }
  }

  anyVirtualTraveller() {
    return !!(this.guestIsVirtual && this.guestIsVirtual.length && this.guestIsVirtual.find(guest => guest.isVirtual));
  }

  anyVirtualGuest() {
    return !!(this.guestTravellers && this.guestTravellers.length && this.guestTravellers.find(guest => guest.isVirtual));
  }

  checkExistingTraveller() {
    return !!(this.selectedExistingTraveller && this.selectedExistingTraveller.length
      && (this.selectedExistingTraveller.filter(traveller => traveller !== null) || !this.selectedExistingTraveller.includes(undefined)));
  }

  async saveGuests() {
    BasketStore.setBasketEnterTravellersErrors([]);
    BasketStore.setShouldShowUiTitleErrors(false);
    try {
      await this.checkGuest();
      if (this.hasSimilarTravellers && (this.selectedExistingTraveller.length === 0 || this.checkExistingTraveller() === false)) {
        BasketStore.setBookingStepLoading(false);
        return;
      }

      await this.saveSingleGuest(0);
      this.serverErrors = [];
      BasketStore.setGuestBasketTravellers(this.guestsTravellersToSave);
      await BasketStore.updateBasket();
      await BasketStore.loadBasketItems(false);
      BasketStore.setErrors(this.serverErrors);
      BasketStore.updateTravellersIdsInWizardSteps();
      BasketStore.saveGuestsNextStep();
    } catch (error) {
      this.isLoading = false;
      this.showError = true; 
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'TRAVELLER_ALREADY_ADDED'
      ) {
        BasketStore.setShouldShowUiTitleErrors(true);
        const serverErrors = [{
          title: translate('basket.traveller-already-added-title'),
          message: translate('basket.traveller-already-added-info'),
        }];
        BasketStore.setBasketEnterTravellersErrors(serverErrors);
      } else {
        BasketStore.setShouldShowUiTitleErrors(false);
        BasketStore.setBasketEnterTravellersErrors(this.$handleErrors(error, true));
      }
      BasketStore.setBookingStepLoading(false);
    }
  }

  @Watch('selectedExistingTraveller', { deep: true })
  onSelectedExistingTravellerChange(value) {
    BasketStore.setSelectedExistingTraveller(value);
  }

  @Watch('travellersExistence', { deep: true })
  onTravellersExistenceChange() {
    this.disabledDefaultTravellers();
  }

  @Watch('useTheSameContactData', { immediate: true })
  onUseTheSameContactDataChange(value) {
    this.$v.$reset();

    if (!!value) {
      this.guestNewProfiles = this.guestNewProfiles.map((item) => {
        return {
          ...item,
          refusedProvidingContactDetails: false,
        };
      });
      this.onlyVirtualGuests
        .forEach(guest => {
          guest.email = this.email;
          guest.phoneCode = this.phoneCode;
          guest.phoneNumber = this.phoneNumber;
          guest.isMobile = this.isMobile;
        });
    }
  }

  prepareNewGuestData() {
    BasketStore.setGuestBasketTravellers(this.travellers.map(e => {
      return new GuestTraveller({
        ...e,
        dateOfBirth: null,
      });
    }));
    BasketStore.setGuestMainTravellerIndex(
      BasketStore.guestBasketTravellers.findIndex(trav => {
        return trav.isMainTraveller;
    }));
  }

  prepareExistingData() {
    BasketStore.setGuestBasketTravellers(this.guestTravellers.map((e, index) => {
      let result: any = {
        ...new GuestTraveller(e),
        dateOfBirth: moment(e.dateOfBirth).toDate(),
        phoneCode: this.phoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === (e.primaryPhoneNumberCode ? e.primaryPhoneNumberCode : e.phoneCode); })!,
        isMainTraveller: BasketStore.guestMainTravellerIndex === index,
        isVirtual: false,
      };
      if (e.primaryPhoneNumber) {
        result.phoneNumber = e.primaryPhoneNumber;
      }
      if (e.primaryEmail) {
        result.email = e.primaryEmail;
      }
      if (e.title) {
        result.title = this.titleOptions.find(title => { return title.value === e.title; });
      } else {
        result.title = '';
      }
      return result;
    }));
  }

  mapExistingProfiles() {
    this.guestExistingProfiles = BasketStore.guestBasketTravellers.map((traveller, index) => {
      if (this.guestTravellers[index].isVirtual) {
        return {
          ...new GuestTraveller(this.guestTravellers[index]),

          profileId: this.guestTravellers[index].id,
          isVirtual: false,
        };
      }

      return {
        ...traveller,
        profileId: traveller.id,
        isGuest: this.findGuestDataFromTravellers(traveller.id),
        isVirtual: false,
      };
    });
  }

  mapNewProfiles() {
    this.guestNewProfiles = BasketStore.guestBasketTravellers.map((traveller, index) => {
      if (!this.guestTravellers[index].isVirtual) {
        return {
          ...new GuestTraveller(),

          profileId: null,
          firstName: '',
          lastName: '',
          middleName: '',
          dateOfBirth: null,
          email: '',
          title: null,
          phoneCode: this.getSuggestedPhoneCode(),
          phoneNumber: '',
          contacts: null,
          passengerTypeCode: this.guestTravellers[index].passengerTypeCode ? this.guestTravellers[index].passengerTypeCode : 'ADT',
          isVirtual: false,
        };
      }
      return {
        ...traveller,
        profileId: traveller.id,
        isGuest: this.findGuestDataFromTravellers(traveller.id),
        isVirtual: false,
        title: null,
        email: '',
        phoneCode: this.getSuggestedPhoneCode(),
        phoneNumber: '',
      };
    });
  }

  prepareGuestIsVirtual() {
    this.guestIsVirtual = BasketStore.guestBasketTravellers.map(({ isVirtual }, index) => {
      return {
        isVirtual,
      };
    });
  }

  async getDateOfBirthObligation(id) {
    const dateOfBirthObligation = await BasketApi.getBasketTravellerDateOfBirth(id);
    if (dateOfBirthObligation && dateOfBirthObligation.data) {
      return dateOfBirthObligation.data;
    }
  }

  async created() {
    if (!this.anyVirtualGuest()) {
      BasketStore.resetHasSimilarTravellers();
    }
    let dateOfBirthObligationData = await this.getDateOfBirthObligation(this.basketId);
    BasketStore.setBasketEnterTravellersErrors([]);
    BasketStore.setShouldShowUiTitleErrors(false);
    BasketStore.setSelectedExistingTraveller([]);
    EventBus.$on('validate-guests', this.validateAndProceed);
    EventBus.$on('save-guests', this.saveGuests);

    this.travellers.forEach((trav: any) => {
      if (dateOfBirthObligationData && dateOfBirthObligationData.length) {
        let obligationData = dateOfBirthObligationData.find(data => data.travellerId === trav.id);
        if (obligationData) {
          trav.dateOfBirthObligation = obligationData.dateOfBirthObligation;
        }
      }
    });


    this.passengerTypes = this.travellers.map(e => {
      return e.passengerTypeCode ? translate(TravellerTypeMappings[e.passengerTypeCode]) : '';
    });

    if (this.hasSomeGuestData) {
      this.prepareExistingData();
    } else {
      this.prepareNewGuestData();
    }

    this.mapExistingProfiles();
    this.mapNewProfiles();
    this.prepareGuestIsVirtual();
    this.isDataLoaded = true;
  }

  beforeDestroy() {
    EventBus.$off('validate-guests', this.validateAndProceed);
    EventBus.$off('save-guests', this.saveGuests);
  }
}
