

























































































import { Component } from 'vue-property-decorator';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { ProfileApi } from '@/api/profile/profile.api';
import { translate } from '@/i18n';
import { TravellersImportSummary } from '@/api/profile/profile.model';
import axios from 'axios';
import settings from '@/settings';
import SettingsStore from '@/modules/settings/settings.store';

@Component({})
export default class ImportsList extends BaseTable<TravellersImportSummary> {
  
  errors: any[] = [];
  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    page: 1,
    usePageParam: true,
  });
  sortDesc = false;
  fields = {
    fileName: {
      label: translate('import-profiles.file-name'),
      sortable: false,
      tdClass: 'import-profiles__fileName',
    },
    uploadedBy: {
      label: translate('import-profiles.uploaded-by'),
      sortable: false,
    },
    dates: {
      label: translate('import-profiles.dates'),
      sortable: false,
    },
    status: {
      label: translate('import-profiles.status'),
      sortable: false,
    },
    statistics: {
      label: translate('import-profiles.statistics'),
      sortable: false,
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  canDownloadImportResult(status) {
    const nonDownloadableStatuses = ['uploaded', 'inprogress'];
    return !nonDownloadableStatuses.includes(status.toLowerCase());
  }

  async downloadImportResult(id) {

    axios({
      method: 'GET',
      url: settings.apiProfile + `/api/travellers/import-result/${id}`,
      responseType: 'blob'
    })
    .then((response) => {
      let filename;
      if (response && response.headers && response.headers['content-disposition']) {
        let disposition = response.headers['content-disposition'];
        if (!!disposition && disposition.indexOf('filename=') !== -1) {
          const fileNameIndex = disposition.indexOf('filename=');

          disposition = disposition.substring(fileNameIndex, disposition.length);
          const firstSemicolonIndex = disposition.indexOf(';');
          filename = disposition.substring(0, firstSemicolonIndex).replace('filename=', '').trim();
        }
      }

      if (!filename) {
        filename = `ImportResult_${id}.csv`;
      }
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url as string;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      this.errors = this.$handleErrors(error);
    });
  }

  async getItems(params: any) {

    try {
      const filter = {
        pagination: {
          pageNo: this.params.page,
          pageSize: this.params.size 
        },
        rootCompanyId: this.currentCompany!.rootCompanyId
      };

      const paginatedImportProfiles = await ProfileApi.getTravellersImportsSummary(filter);
      if (paginatedImportProfiles && paginatedImportProfiles.data) {
        this.params.size = paginatedImportProfiles.data.page.pageSize;
        return {
          page: paginatedImportProfiles.data.page,
          results: paginatedImportProfiles.data.results,
        };
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

}
