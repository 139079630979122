































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import { Debounce } from '@/core/decorators/debounce.decorator';
import { router } from '@/router';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import DebounceConst from '@/const/debounce.const';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { HotelLocationModel, LanguageCode } from '@/api/dictionary/dictionary.model';
import SettingsStore from '@/modules/settings/settings.store';
import DictionaryStore from '@/store/dictionary.store';
import DisplayPreferencesCarStore from '@/modules/settings/display-preferences/display-preferences-car.store';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';
import AccountStore from '@/store/account.store';

@Component({})
export default class DisplayPreferencesCarManageRule extends Vue {
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  isLoading: boolean = false;
  formPending: boolean = false;
  loadErrors: any[] = [];
  geoAreaErrors: any[] = [];
  rentalCompaniesOptions: { code: string; name: string }[] = [];
  locations:  HotelLocationModel[] = [];
  isRentalCompaniesEnabled: boolean = false;
  isPickUpDropOffLocationsEnabled: boolean = false;
  $v;

  get displayPreferences() {
    return DisplayPreferencesCarStore.displayPreferencesCar.displayPreferences;
  }

  get carRule() {
    return DisplayPreferencesCarStore.carRule;
  }

  get rentalCompanies() {
    return this.carRule.rentalCompanies;
  }
  set rentalCompanies(rentalCompanies: { code: string; name: string }[]) {
    DisplayPreferencesCarStore.setCarRule({
      ...this.carRule,
      rentalCompanies,
    });
  }

  get pickUpDropOffLocations() {
    return this.carRule.pickUpDropOffLocations;
  }
  set pickUpDropOffLocations(pickUpDropOffLocations: HotelLocationModel[]) {
    DisplayPreferencesCarStore.setCarRule({
      ...this.carRule,
      pickUpDropOffLocations,
    });
  }

  get isEditCarRule() {
    return this.displayPreferences.length > (this.carRule.$index as number);
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    return this.$route.params.configurationId;
  }

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get currentConfigName() {
    return DisplayPreferencesStore.currentConfigName;
  }

  get allCarVendors() {
    return DictionaryStore.allCarVendors || [];
  }

  get currentLanguage() {
    return AccountStore.currentLanguage;
  }

  get isLeastOneSelectedError() {
    return [
      this.isRentalCompaniesEnabled,
      this.isPickUpDropOffLocationsEnabled,
    ].every((item) => !item);
  }

  @Validation()
  validationObject() {
    return {
      rentalCompanies: {
        required: requiredIf(() => this.isRentalCompaniesEnabled),
      },
      pickUpDropOffLocations: {
        required: requiredIf(() => this.isPickUpDropOffLocationsEnabled),
      },
      carRule: {
        isLeastOneSelectedError: {
          required: requiredIf(() => this.isLeastOneSelectedError),
        },
      },
    };
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  async findRentalCompanies(query: string) {
    if (query && this.allCarVendors) {
      this.rentalCompaniesOptions = this.allCarVendors.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
    } else {
      this.rentalCompaniesOptions = this.allCarVendors;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async findLocations(query) {
    this.isLoading = true;
    if (query && query.length > 2) {
      const response = await DictionaryApi.findHotelToLocation(query, this.currentLanguage.toUpperCase() as LanguageCode);
      if (response && response.data) {
        this.locations = response.data;
      }
      this.isLoading = false;
    } else {
      this.locations = this.pickUpDropOffLocations;
      this.isLoading = false;
    }
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  async returnToConfiguration() {
    DisplayPreferencesStore.setIsFromManage(true);
    this.formPending = true;
    await router.push({
      name: 'display-preferences-configuration',
      params: {
        configurationId: this.configurationId,
      },
    });
  }

  addCarRuleToDisplayPreferences() {
    DisplayPreferencesCarStore.setDisplayPreferencesCar({
      ...DisplayPreferencesCarStore.displayPreferencesCar,
      displayPreferences: this.displayPreferences
        .filter((item, index) => index !== this.carRule.$index)
        .concat(this.carRule),
    });
  }

  confirmAndAddNext() {
    const isValid = this.isValid();
    if (isValid) {
      this.addCarRuleToDisplayPreferences();
      DisplayPreferencesCarStore.setCarRule();
      this.isRentalCompaniesEnabled = false;
      this.isPickUpDropOffLocationsEnabled = false;
      this.$v.$reset();
      EventBus.$emit('show-toast', {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      });
    }
  }

  confirmAndBack() {
    const isValid = this.isValid();
    if (isValid) {
      this.addCarRuleToDisplayPreferences();
      this.returnToConfiguration();
    }
  }

  async created() {
    await DictionaryStore.loadCarVendors();
    this.rentalCompaniesOptions = this.allCarVendors;
    if (this.carRule.$index || this.carRule.$index === 0) {
      this.isRentalCompaniesEnabled = this.carRule.rentalCompanies.length > 0;
      this.isPickUpDropOffLocationsEnabled = this.carRule.pickUpDropOffLocations.length > 0;
    } else {
      router.push('display-preferences-configuration');
    }
  }
}
