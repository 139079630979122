import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  TrainSystemMarkupConfiguration,
} from './train-system-markup-configuration.model';

class TrainSystemMarkupConfigurationApiClass {
  public getConfiguration(configurationId: string): Promise<AxiosResponse<TrainSystemMarkupConfiguration>> {
    return http.execute({
      path: settings.apiRailEngine + '/system-markup-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public createConfiguration(request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/system-markup-configurations/{id}',
      method: RequestMethod.POST,
    }, {
      id: request.configurationId,
    }, request.configuration);
  }

  public updateConfiguration(configurationId: string, request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/system-markup-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, request);
  }
}

export const TrainSystemMarkupConfigurationApi: TrainSystemMarkupConfigurationApiClass = new TrainSystemMarkupConfigurationApiClass();
