


































import { Component, Vue } from 'vue-property-decorator';
import AccountStore from '@/store/account.store';

@Component({})
export default class AFCancelledRebookedNotificationIssued extends Vue {
  get isAgency() {
    return AccountStore.current!.profile.isAgency;
  }
}
