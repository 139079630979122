
























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PnrRemarksStore from './pnr-remarks.store';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { translate } from '@/i18n';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';

const sabreRemarkRegex5QQ = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^(?!5QQ)/g.test(value);
};

const sabreRemarkRegexCharacters = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^([a-zA-Z0-9]|\s|\,|\.|\-|\/|\¥|\¤|\*|\+|\'|\:|\@)*$/g.test(value);
};

const onlyLettersRegex = (value, { type }, isAirAmadeus) => {
  if (typeof value === 'undefined' || value === null || value === '' || (type === 'RM' && value === '*' && isAirAmadeus)) {
    return true;
  }

  return /^([a-zA-Z])*$/g.test(value);
};

@Component({})
export default class PnrRemarksList extends Vue {
  @Prop({}) supplier!: string;
  @Prop({}) remarks!: any[];
  @Prop({}) service!: string;

  allAirline: any[] = [];
  airlines: any[] = [];

  $v;

  @Validation()
  validationObject() {
    const cantBeSame = (value, model) => {
      let entryCheck;
      if (this.isAccommodation) {
        entryCheck = entry => entry.remark === model.remark;
      } else {
        entryCheck = entry => entry.type === model.type &&
          (entry.category || null) === model.category &&
          entry.remark === model.remark;
      }
      
      const firstIndex = this.entries.findIndex(entryCheck);
      const lastIndex = this.entries.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };

    let entriesWithRQ = {};

    if (this.isAmadeus) {
      entriesWithRQ = {
        maxLength: maxLength(3),
      };
    }

    return {
      entriesWithRQ,
      entries: {
        $each: {
          remark: {
            required,
            maxLength: this.isSabre || this.isAmadeus ? maxLength(70) : maxLength(100),
            sabreRemarkRegex5QQ: (value) => {
              return (this.isSabre || this.isAmadeus) ? sabreRemarkRegex5QQ(value) : true;
            },
            sabreRemarkRegexCharacters: (value) => {
              return (this.isSabre || this.isAmadeus) ? sabreRemarkRegexCharacters(value) : true;
            },
            cantBeSame,
          },
          type: {
            required: requiredIf(() => {
              return (this.isSabre && this.isFlight) || this.isAmadeus;
            }),
            cantBeSame,
          },
          category: {
            onlyLettersRegex: (value, obj) => onlyLettersRegex(value, obj, this.isAmadeus && this.isFlight),
            maxLength: maxLength(2),
          },
        }
      }
    };
  }

  get amadeusRemarksTypes() {
    return [
      {
        value: 'RM',
        label: translate('settings-pnr-remarks-types.RM'),
      },
      {
        value: 'RI',
        label: translate('settings-pnr-remarks-types.RI'),
      },
      {
        value: 'RQ',
        label: translate('settings-pnr-remarks-types.RQ'),
      },
    ];
  }

  get sabreRemarksTypes() {
    return [
      {
        value: 'General',
        label: translate('settings-pnr-remarks-types.General'),
      },
      {
        value: 'AlphaCoded',
        label: translate('settings-pnr-remarks-types.AlphaCoded'),
      },
      {
        value: 'ClientAddress',
        label: translate('settings-pnr-remarks-types.ClientAddress'),
      },
      {
        value: 'Corporate',
        label: translate('settings-pnr-remarks-types.Corporate'),
      },
      {
        value: 'DeliveryAddress',
        label: translate('settings-pnr-remarks-types.DeliveryAddress'),
      },
      {
        value: 'GroupName',
        label: translate('settings-pnr-remarks-types.GroupName'),
      },
      {
        value: 'Hidden',
        label: translate('settings-pnr-remarks-types.Hidden'),
      },
      {
        value: 'Historical',
        label: translate('settings-pnr-remarks-types.Historical'),
      },
      {
        value: 'Invoice',
        label: translate('settings-pnr-remarks-types.Invoice'),
      },
      {
        value: 'Itinerary',
        label: translate('settings-pnr-remarks-types.Itinerary'),
      },
    ];
  }

  

  get remarksTypes() {
    if (this.isSabre) {
      return this.sabreRemarksTypes;
    }
    return this.amadeusRemarksTypes;
  }

  get isFlight() {
    return this.service === 'Flight';
  }

  get isAccommodation() {
    return this.service === 'Accommodation';
  }

  get isAmadeus() {
    return this.supplier === 'Amadeus' &&
        -1 < [
          'Flight',
          'Car',
        ].indexOf(this.service);
  }

  get entries() {
    const remarksFound = this.providerObject && this.providerObject.pnrRemarks ? this.providerObject.pnrRemarks : [];

    return remarksFound;
  }

  get providerObject() {
    return this.remarks.find(e => {
      return e.supplier === this.supplier;
    });
  }

  get hasProviderErrors() {
    return this.providerObject ?
        !this.providerObject.succeeded && this.providerObject.error :
        false;
  }

  get selectedTab() {
    return PnrRemarksStore.selectedTab;
  }

  get hasWritePermission() {
    return this.$hasAccess('WritePnrRemarks');
  }

  get isSabre() {
    return this.supplier === 'Sabre';
  }

  get entriesWithRQ() {
    return this.entries.filter(e => e.type === 'RQ');
  }



  airlinesMaper() {
    this.airlines = this.allAirline = DictionaryStore.allAirLinesSorted;
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  getItemType(item) {
    if (this.isSabre) {
      return this.sabreRemarksTypes.find(r => r.value === item.type);
    }
    return this.amadeusRemarksTypes.find(r => r.value === item.type);
  }

  setItemType(item, value) {
    item.type = value.value;
    if (item.type === 'RQ') {
      item.category = null;
    }
  }

  newRemarkDisabled() {
    return this.entries[this.entries.length - 1] && this.entries[this.entries.length - 1].remark ?
        !this.entries[this.entries.length - 1].remark.length :
        this.entries.length > 0;
  }

  newRemark() {
    if (!this.newRemarkDisabled()) {
      let pnrSsrCodes = this.providerObject && this.providerObject.pnrSsrCodes ? this.providerObject.pnrSsrCodes : [];
      PnrRemarksStore.addRemark({ service: this.service, supplier: this.supplier, pnrSsrCodes: pnrSsrCodes } );
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  deleteRemark(index) {
    PnrRemarksStore.removeRemark({ index: index, supplier: this.supplier });
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  created() {
    this.airlinesMaper();
  }

}
