



































































































































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';
import { translate } from '@/i18n';
import SearchStore from '@/modules/search/search.store';
import _ from 'lodash';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import UiTravellersTravellerRoom from './UiTravellersTravellerRoom.vue';

@Component({
  components: {
    UiTravellersTravellerRoom,
  },
})
export default class UiTravellersRoomsSelect extends Vue {
  @Model('change') modelValue!: any;
  @Prop({ default: () => {
    return [];
  } }) travellers!: any[];
  @Prop() disabled!: boolean;
  cleanOptions: any = null;
  travellersSum: number = 0;
  travellersSumTemp: number = 0;
  showFull: boolean = false;
  isInPopup: boolean = false;
  isActive: boolean = false;
  popupContainer: HTMLElement | null = null;
  blurTimeout: number = -1;
  touchedIndex: number = -1;
  rooms: any[] = [];

  get initialTravellersList() {
    return SearchStore.getTravellersState;
  }

  get wrapperClasses() {
    return {
      'ui-travellers-select--show-full': this.showFull,
      'disabled': this.disabled,
    };
  }

  get currentRoomNumber() {
    return this.modelValue
      .filter(item => !!item.profiles.length)
      .length;
  }

  get roomsFiltered() {
    return this.rooms
      .filter(item => !!item.profiles.length);
  }

  onRoomChanging(traveller, $event) {
    const oldRoom = this.rooms.find(item => item.profiles.includes(traveller.id));

    if (oldRoom) {
      const oldIndex = oldRoom.profiles.findIndex(item => item === traveller.id);
      oldRoom.profiles.splice(oldIndex, 1);
    }

    const newRoom = this.rooms[$event.value];
    if (!newRoom) {
      return;
    }

    newRoom.profiles.push(traveller.id);
  }

  mobileFocus() {
    if (this.disabled) {
      return;
    }
    clearTimeout(this.blurTimeout);
    this.isActive = true;
    this.cleanOptions = _.cloneDeep(this.rooms);

    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  mobileClick() {
    if (!this.isActive) {
      this.$nextTick(() => {
        return this.$refs.uiTravellerMobileInput &&
          (this.$refs.uiTravellerMobileInput as HTMLInputElement).focus();
      });
    }
  }

  onClick(e: Event) {
    if (this.disabled) {
      return;
    }
    const target = e.target as HTMLElement;
    if (target.className.includes('fake-input')) {
      this.showFull = true;
      this.cleanOptions = _.cloneDeep(this.rooms);
      return;
    }
    this.showFull = true;
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }


  get mobileClasses() {
    return {
      'ui-autocomplete__mobile--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile--has-values': 
        this.modelValue && this.modelValue.length,
      'ui-autocomplete__mobile--active': this.isActive,
    };
  }

  applyOptions() {
    this.$emit('change', _.cloneDeep(this.rooms));
    this.showFull = false;
  }

  applyOptionsMobile() {
    this.$emit('change', _.cloneDeep(this.rooms));
    this.isActive = false;
  }

  cancel() {
    this.rooms = _.cloneDeep(this.cleanOptions);
    this.showFull = false;
    this.isActive = false;
  }

  windowOnClick(e) {
    if (window.innerWidth < 800) {
      return;
    }
    let target = e.target as HTMLInputElement;

    if (
      this.showFull &&
      !this.$el.contains(target) &&
      (target && target.offsetParent && !target.offsetParent.className.includes('ui-travellers-select__class-box'))
    ) {
      setTimeout(() => {
        this.applyOptions();
      });
    }
  }

  created() {
    this.rooms = _.cloneDeep(this.modelValue);
    window.addEventListener('click', this.windowOnClick);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.windowOnClick);
  }

  @Emit('change')
  onChange(value) {
    return value;
  }

  onFocus() {
    if (this.disabled) {
      return;
    }
    this.showFull = true;
    this.cleanOptions = _.cloneDeep(this.rooms);
  }
}
