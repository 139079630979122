


















































import { Vue, Prop, Component } from 'vue-property-decorator';
import BasketStore from './basket.store';
import BasketExpedia3DSView from './BasketExpedia3DSView.vue';
import _ from 'lodash';
import moment from 'moment';

@Component({
  components: {
    BasketExpedia3DSView,
  },
  filters: {
    dateFilter(date) {
      return moment.parseZone(date).format('Do MMMM YYYY');
    },
  },
})
export default class BasketExpedia3DSPopup extends Vue {
  @Prop() title!: string;
  @Prop() show!: boolean;

  get loadErrors() {
    return BasketStore.errors;
  }

  get showBasketExpedia3DSPopup() {
    return BasketStore.showBasketExpedia3DSPopup;
  }

  get startBasketExpedia3DS() {
    return BasketStore.startBasketExpedia3DS;
  }

  get pendingAction() {
    return BasketStore.basketItemPendingActionCode;
  }

  get basketStatus() {
    return BasketStore.status;
  }

  get expedia3DSTripItemId() {
    return BasketStore.expedia3DSTripItemId;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get basketStatusItems() {
    if (!this.basketStatus) {
      return [];
    }
    return this.basketStatus.items.map(item => {
      const object = this.basketItems.find(i => i.id === item.id);

      if (object && object.bookingErrors && object.bookingErrors.length) {
        object.bookingErrors.forEach(element => {
          element.isOpen = false;
        });

        object.groupeErrors = _.groupBy(object.bookingErrors, 'errorLevel');
      }

      return {
        ...item,
        object,
      };
    });
  }

  get item() {
    return this.basketStatusItems.find(i => i.id === this.expedia3DSTripItemId);
  }

  stopPaymentProcess() {
    if (this.item) {
      const action = this.item.pendingActions.find(i => i.code === this.pendingAction);
      const availableResponseAnswer = {
        tripItemId: this.expedia3DSTripItemId,
        actionCode: this.pendingAction,
        answer: {
          selectedAnswer: action!.availableResponses[2],
        }
      };

      BasketStore.sendTripItemAvailableResponseAnswer(availableResponseAnswer);
      BasketStore.setShowBasketExpedia3DSPopup(false);
    }
  }

  async checkPaymentStatus() {
    await BasketStore.refreshStatus();
  }
}

