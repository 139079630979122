import SystemMarkupRuleAir from '@/modules/settings/system-markup/SystemMarkupRuleAir.vue';

export default {
  path: ':configurationId?/air-system-markup-rule',
  name: 'system-markup-rule-air',
  component: SystemMarkupRuleAir,
  displayName: 'Air system markup rule definition',
  meta: {
    breadCrumb: 'Air system markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    parentName: 'system-markup',
    depthCategory: 'system-markup',
    depth: 3,
  }
};