export const userFullName = (user) => {
  if (!user) {
    return '';
  }
  return [
    user.firstName,
    user.middleName,
    user.lastName,
  ].filter(item => !!item).join(' ');
};
