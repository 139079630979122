



























































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import settings from '@/settings';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { TripApi } from '@/api/trip/trip.api';
import { BookingsTableParams } from './bookings-table.params';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileFullNameModel, TravellerModel } from '@/api/profile/profile.model';
import {
  SearchTripPeriod,
  TripItemStatus,
  BasketItemType,
  SearchBooking,
  SearchTripsSortOnEnum,
  SearchTripsSortDirectionEnum
} from '@/api/trip/trip.model';
import { translate } from '@/i18n';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import searchConst from '@/const/search.const';
import { Permission } from '@/const/permission.enum';
import ExportConfirmationPopup from '@/modules/trips/ExportConfirmationPopup.vue';
import { SupplierName } from '@/services/supplier-name.service';
import AccountStore from '@/store/account.store';

@Component({
  components: {
    HeightTransition,
    ExportConfirmationPopup,
  },
})
export default class BookingsTable extends BaseTable<SearchBooking> {
  useQueryParams: boolean = true;
  showFilters: boolean = false;
  params: BookingsTableParams = new BookingsTableParams({});
  toggleSortOptions: boolean = false;
  showExportConfirmation: boolean = false;
  exportInProgress: boolean = false;

  get fields() {
    let bFields = {
      travellerName: {
        label: translate('trips-table.traveller-name'),
        class: 'trips-table__column--traveller-name',
        tdClass: 'mw-250',
      },
      arrangedBy: {
        label: translate('trips-table.arranged-by'),
        class: 'trips-table__column--arranged-by',
        tdClass: 'mw-200',
      },
      tripName: {
        label: translate('trips-table.departure-destination'),
        class: 'trips-table__column--trip-name',
        tdClass: 'mw-200',
      },
      createdDate: {
        label: translate('trips-table.created-date'),
        class: 'trips-table__column--created-date',
        tdClass: 'mw-200',
      },
      dates: {
        label: translate('trips-table.dates'),
        class: 'trips-table__column--dates',
        tdClass: 'mw-200',
      },
      status: {
        label: translate('trips-table.status'),
        class: 'trips-table__column--status',
        tdClass: 'w-200 mw-200',
      },
      totalPrice: {
        label: translate('trips-table.total-price'),
        class: 'trips-table__column--total-price',
        tdClass: 'mw-200',
      },
      actionsColumn: {
        label: '',
        class: 'trips-table__column--actions',
      },
    };

    if (!this.$canSeePrices()) {
      delete bFields.totalPrice;
    }

    return bFields;
  }
  periods = [
    {
      label: translate('trips-table.all'),
      value: SearchTripPeriod.All,
    },
    {
      label: translate('trips-table.past'),
      value: SearchTripPeriod.Past,
    },
    {
      label: translate('trips-table.current'),
      value: SearchTripPeriod.Current,
    },
    {
      label: translate('trips-table.upcoming'),
      value: SearchTripPeriod.Upcoming,
    }
  ];
  defaultPeriod = SearchTripPeriod.All;
  tripStatuses = [
    {
      label: translate('basket-item-status.Held'),
      value: TripItemStatus.Held,
    },
    {
      label: translate('basket-item-status.Confirmed'),
      value: TripItemStatus.Confirmed,
    },
    {
      label: translate('basket-item-status.Cancelled'),
      value: TripItemStatus.Cancelled,
    },
    {
      label: translate('basket-item-status.Desynchronized'),
      value: TripItemStatus.Desynchronized,
    },
    {
      label: translate('basket-item-status.PendingApproval'),
      value: TripItemStatus.PendingApproval,
    },
    {
      label: translate('basket-item-status.AgencySupportRequired'),
      value: TripItemStatus.AgencySupportRequired,
    },
    {
      label: translate('basket-item-status.UserActionRequired'),
      value: TripItemStatus.UserActionRequired,
    },
    {
      label: translate('basket-item-status.DelayedTicketing'),
      value: 'DelayedTicketing',
    },
  ];
  serviceTypes = [
    {
      label: translate('trips-filters.air'),
      value: BasketItemType.Air,
    },
    {
      label: translate('trips-filters.rail'),
      value: BasketItemType.Rail,
    },
    {
      label: translate('trips-filters.accommodation'),
      value: BasketItemType.Accommodation,
    },
    {
      label: translate('trips-filters.car'),
      value: BasketItemType.Car,
    },
  ];
  sortDirectionOptions = [
    {
      label: translate('trips-table.sort-direction-ascending'),
      value: SearchTripsSortDirectionEnum.Ascending,
    },
    {
      label: translate('trips-table.sort-direction-descending'),
      value: SearchTripsSortDirectionEnum.Descending,
    },
  ];
  sortOnOptions = [
    {
      label: translate('trips-table.sort-on-create-date'),
      value: SearchTripsSortOnEnum.CreateDate,
    },
    {
      label: translate('trips-table.sort-on-start-date'),
      value: SearchTripsSortOnEnum.StartDate,
    },
  ];

  arrangers: TravellerModel[] = [];
  arrangersLoading: boolean = false;
  travellers: TravellerModel[] = [];
  travellersLoading: boolean = false;
  companies: string = '';
  errors: any[] = [];
  usersErrors: any[] = [];
  shouldShowMoreFilters: boolean = false;
  locations: any[] = [];
  locationsLoading: boolean = false;
  locationsErrors: any[] = [];
  locationsQueryLength: number = 0;

  providersList = [
    {
      label: SupplierName('Sabre'),
      value: 'Sabre',
    },
    {
      label: SupplierName('TravelFusion'),
      value: 'TravelFusion',
    },
    {
      label: SupplierName('Amadeus'),
      value: 'Amadeus',
    },
    {
      label: SupplierName('Lufthansa'),
      value: 'Lufthansa',
    },
    {
      label: SupplierName('AmericanAirlines'),
      value: 'AmericanAirlines',
    },
    {
      label: SupplierName('Emirates'),
      value: 'Emirates',
    },
    {
      label: SupplierName('AirFranceKlm'),
      value: 'AirFranceKlm',
    },
    {
      label: SupplierName('Benerail'),
      value: 'Benerail',
    },
    {
      label: SupplierName('Ntv'),
      value: 'Ntv',
    },
    {
      label: SupplierName('Ouigo'),
      value: 'Ouigo',
    },
    {
      label: SupplierName('Sncf'),
      value: 'Sncf',
    },
    {
      label: SupplierName('Trenitalia'),
      value: 'Trenitalia',
    },
    {
      label: SupplierName('Expedia'),
      value: 'Expedia',
    },
    {
      label: SupplierName('HCorpo'),
      value: 'HCorpo',
    },
    {
      label: SupplierName('WonderHotel'),
      value: 'WonderHotel',
    },
    {
      label: SupplierName('Teldar'),
      value: 'Teldar',
    },
    {
      label: SupplierName('Koedia'),
      value: 'Koedia',
    },
    {
      label: SupplierName('BritishAirways'),
      value: 'BritishAirways',
    },
  ];
  providerStatusesList = [
    {
      label: translate('basket-item-provider-status.Booked'),
      value: 'Booked',
    },
    {
      label: translate('basket-item-provider-status.Cancelled'),
      value: 'Cancelled',
    },
    {
      label: translate('basket-item-provider-status.CancelledOffline'),
      value: 'CancelledOffline',
    },
    {
      label: translate('basket-item-provider-status.Desynchronized'),
      value: 'Desynchronized',
    },
    {
      label: translate('basket-item-provider-status.Preorder'),
      value: 'Preorder',
    },
    {
      label: translate('basket-item-provider-status.PendingCancellation'),
      value: 'PendingCancellation',
    },
    {
      label: translate('basket-item-provider-status.Support'),
      value: 'Support',
    },
    {
      label: translate('basket-item-provider-status.Refused'),
      value: 'Refused',
    },
    {
      label: translate('basket-item-provider-status.Refunded'),
      value: 'Refunded',
    },
    {
      label: translate('basket-item-provider-status.Void'),
      value: 'Void',
    },
    {
      label: translate('basket-item-provider-status.Ticketed'),
      value: 'Ticketed',
    },
    {
      label: translate('basket-item-provider-status.TicketedExchanged'),
      value: 'TicketedExchanged',
    },
    {
      label: translate('basket-item-provider-status.Vouchered'),
      value: 'Vouchered',
    },
    {
      label: translate('basket-item-provider-status.RefundDelayed'),
      value: 'RefundDelayed',
    },
    {
      label: translate('basket-item-provider-status.Unidentified'),
      value: 'Unidentified',
    },
    {
      label: translate('basket-item-provider-status.TicketingPending'),
      value: 'TicketingPending',
    },
    {
      label: translate('basket-item-provider-status.RefundViaBspNeeded'),
      value: 'RefundViaBspNeeded',
    }
  ];
  paymentMethodsList = [
    {
      label: translate('basket-payment-method-by-type.CentralPayment'),
      value: 'CentralPayment',
    },
    {
      label: translate('basket-payment-method-by-type.PaymentCard'),
      value: 'PaymentCard',
    },
    {
      label: translate('basket-payment-method-by-type.LodgeCard'),
      value: 'LodgeCard',
    },
    {
      label: translate('basket-payment-method-by-type.TfPay'),
      value: 'TfPay',
    },
    {
      label: translate('basket-payment-method-by-type.PreRegisteredCard'),
      value: 'PreRegisteredCard',
    },
    {
      label: translate('basket-payment-method-by-type.VirtualCard'),
      value: 'VirtualCard',
    },
  ];
  bookingChannelsList = [
    {
      label:  translate('settings-fees.uat'),
      value:  'UAT',
    },
    {
      label:  translate('settings-fees.sbt'),
      value: 'SBT',
    },
  ];
  bookingTypesList = [
    {
      label:  translate('settings-fees.offline'),
      value:  'Offline',
    },
    {
      label:  translate('settings-fees.online'),
      value: 'Online',
    },
  ];


  get isAgency() {
    return AccountStore.current!.profile.isAgency;
  }

  get providersOptions() {
    return this.providersList
      .sort(this.sorter);
  }

  get providerStatusesOptions() {
    return this.providerStatusesList
      .sort(this.sorter);
  }

  get paymentMethodsOptions() {
    return this.paymentMethodsList
      .sort(this.sorter);
  }

  get bookingChannelsOptions() {
    return this.bookingChannelsList
      .sort(this.sorter);
  }

  get bookingTypesOptions() {
    return this.bookingTypesList
      .sort(this.sorter);
  }

  get fromDateRange() {
    return {
      start: this.params.startDate.from ? moment(this.params.startDate.from).toDate() : null,
      end: this.params.startDate.to ? moment(this.params.startDate.to).toDate() : null,
    };
  }

  set fromDateRange(val) {
    if (val && val.start && val.end) {
      this.params.startDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.startDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.startDate.from = null;
      this.params.startDate.to = null;
    }
  }

  get toDateRange() {
    return {
      start: this.params.endDate.from ? moment(this.params.endDate.from).toDate() : null,
      end: this.params.endDate.to ? moment(this.params.endDate.to).toDate() : null,
    };
  }

  set toDateRange(val) {
    if (val && val.start && val.end) {
      this.params.endDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.endDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.endDate.from = null;
      this.params.endDate.to = null;
    }
  }

  get createDateRange() {
    return {
      start: this.params.createDate.from ? moment(this.params.createDate.from).toDate() : null,
      end: this.params.createDate.to ? moment(this.params.createDate.to).toDate() : null,
    };
  }

  set createDateRange(val) {
    if (val && val.start && val.end) {
      this.params.createDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.createDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.createDate.from = null;
      this.params.createDate.to = null;
    }
  }

  get deadlineDateRange() {
    return {
      start: this.params.deadline.from ? moment(this.params.deadline.from).toDate() : null,
      end: this.params.deadline.to ? moment(this.params.deadline.to).toDate() : null,
    };
  }

  set deadlineDateRange(val) {
    if (val && val.start && val.end) {
      this.params.deadline.from = moment(val.start).format('YYYY-MM-DD');
      this.params.deadline.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.deadline.from = null;
      this.params.deadline.to = null;
    }
  }

  get periodModel() {
    return this.periods.find(item => this.params.period === item.value);
  }

  set periodModel(data) {
    if (data) {
      this.params.period = data.value;
    }
  }

  get sortOnModel() {
    return this.sortOnOptions.find(item => this.params.sortOnField === item.value);
  }

  set sortOnModel(data) {
    if (data) {
      this.params.sortOnField = data.value;
    }
  }

  get sortDirectionModel() {
    return this.sortDirectionOptions.find(item => this.params.sortDirection === item.value);
  }

  set sortDirectionModel(data) {
    if (data) {
      this.params.sortDirection = data.value;
    }
  }

  get providersModel() {
    return this.providersList.filter(item => this.params.providers.includes(item.value));
  }

  set providersModel(value) {
    if (value) {
      this.params.providers = value.map(item => item.value);
    } else {
      this.params.providers = [];
    }
  }

  get providerStatusesModel() {
    return this.providerStatusesList.filter(item => this.params.providerStatuses.includes(item.value));
  }

  set providerStatusesModel(value) {
    if (value) {
      this.params.providerStatuses = value.map(item => item.value);
    } else {
      this.params.providerStatuses = [];
    }
  }

  get paymentMethodsModel() {
    return this.paymentMethodsList.filter(item => this.params.paymentMethods.includes(item.value));
  }

  set paymentMethodsModel(value) {
    if (value) {
      this.params.paymentMethods = value.map(item => item.value);
    } else {
      this.params.paymentMethods = [];
    }
  }

  get bookingChannelsModel() {
    return this.bookingChannelsList.filter(item => this.params.bookingChannels.includes(item.value));
  }

  set bookingChannelsModel(value) {
    if (value) {
      this.params.bookingChannels = value.map(item => item.value);
    } else {
      this.params.bookingChannels = [];
    }
  }

  get bookingTypeModel() {
    return this.bookingTypesList.filter(item => this.params.bookingTypes.includes(item.value));
  }

  set bookingTypeModel(value) {
    if (value) {
      this.params.bookingTypes = value.map(item => item.value);
    } else {
      this.params.bookingTypes = [];
    }
  }

  shouldDisplayProviderStatus(status) {
    return ['Desynchronized', 'UserActionRequired', 'AgencySupportRequired'].indexOf(status) === -1;
  }

  serviceIcon(serviceName) {
    switch (serviceName) {
      case 'Air':
        return 'airplanemode_active';
      case 'Rail':
        return 'train';
      case 'Accommodation':
        return 'hotel';
      case 'Car':
        return 'directions_car';
    }
  }

  journeyTypeIcon(journeyType) {
    switch (journeyType) {
      case 'OneWay':
        return 'arrow_right_alt';
      case 'RoundTrip':
        return 'sync_alt';
      case 'MultiLeg':
        return 'multiple_stop';
    }
  }

  journeyTypeTooltip(journeyType) {
    switch (journeyType) {
      case 'OneWay':
        return translate('common.one-way');
      case 'RoundTrip':
        return translate('common.roundtrip');
      case 'MultiLeg':
        return translate('common.multi-leg');
    }
  }

  serviceTooltip(serviceName) {
    switch (serviceName) {
      case 'Air':
        return translate('search.flight');
      case 'Rail':
        return translate('search.train');
      case 'Accommodation':
        return translate('search.hotel');
      case 'Car':
        return translate('search.accommodation');
    }
  }

  getTravLabel(guestCode) {
    let optionFound = searchConst.guestTravellerOptions.find(option => {
      return option.code === guestCode;
    });
    if (optionFound) {
      return `${translate('search.guest')} (${translate(optionFound.label)})`;
    } else {
      return translate('search.guest');
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  bookerTooltip(bookerData) {
    let tooltip = `
      <span>${userFullName(bookerData)}</span>
    `;
    if (bookerData.businessUnitName) {
      tooltip += `<span>${bookerData.businessUnitName}</span>`;
    }
    return tooltip;
  }

  sorter(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  @Watch('sortOnModel')
  onSortOnChanged() {
    this.resetList(this.currentPage);
    this.reload();
  }

  @Watch('sortDirectionModel')
  onSortDirectionChanged() {
    this.resetList(this.currentPage);
    this.reload();
  }

  mapDestinationObject(destinationObject) {
    if (destinationObject && destinationObject.cityCode) {
      return destinationObject.cityCode;
    } else if (destinationObject && destinationObject.iataCode) {
      return destinationObject.iataCode;
    } else if (destinationObject && !destinationObject.cityCode && !destinationObject.iataCode) {
      return destinationObject;
    } else {
      return undefined;
    }
  }

  async getDestinationParam(destination) {
    if (!destination) {
      return '';
    }
    const destinationResponse = await DictionaryApi.getCity(destination);
    if (destinationResponse && destinationResponse.data) {
      const d = destinationResponse.data;

      return {
        type: 'City',
        cityId: d.id,
        cityName: d.name,
        countryCode: d.countryCode,
        countryName: d.countryName,
        cityCode: d.iataCode,
      };
    }
  }

  getQueryParams(items, source) {
    if (!items) {
      return [];
    }
    let statuses = items;
    if (!(statuses instanceof Array)) {
      statuses = [statuses];
    }
    return statuses.map(value => source.find(item => item.value === value));
  }

  getQueryParamsRaw(items) {
    if (!items) {
      return [];
    }
    let statuses = items;
    if (!(statuses instanceof Array)) {
      statuses = [statuses];
    }

    return statuses;
  }

  async getBookersParam(bookers) {
    if (!bookers) {
      return [];
    }
    let a: string[] | string = bookers;
    if (!(a instanceof Array)) {
      a = [a];
    }
    const result: AxiosResponse<ProfileFullNameModel>[] = await Promise.all(
      a.map(id => ProfileApi.getFullNameById(id, Permission.ShowTripList))
    );

    return result.map(res => res.data);
  }

  async getTravellersParam(travellers) {
    if (!travellers) {
      return [];
    }
    let a: string[] | string = travellers;
    if (!(a instanceof Array)) {
      a = [a];
    }
    const result: AxiosResponse<ProfileFullNameModel>[] = await Promise.all(
      a.map(id => ProfileApi.getFullNameById(id, Permission.ShowTripList))
    );

    return result.map(res => res.data);
  }

  fillSimpleParameters(query) {
    this.params.period = query.period || this.defaultPeriod;
    this.params.destination = this.locations.find(location => {
      return location.cityCode === query.destination;
    });
    this.params.startDate = {
      from: query.startDateFrom,
      to: query.startDateTo,
    };
    this.params.endDate = {
      from: query.endDateFrom,
      to: query.endDateTo,
    };
    this.params.createDate = {
      from: query.createDateFrom,
      to: query.createDateTo,
    };
    this.params.wmCode = query.wmCode;
    this.params.bookingStatuses = this.getQueryParams(query.bookingStatuses, this.tripStatuses);
    this.params.serviceTypes = this.getQueryParams(query.serviceTypes, this.serviceTypes);

    if (this.$hasAccess('CanSeeProviderName')) {
      this.params.providers = this.getQueryParamsRaw(query.providers);
      this.params.providerStatuses = this.getQueryParamsRaw(query.providerStatuses);
    } else {
      this.params.providers = [];
      this.params.providerStatuses = [];
    }
    this.params.paymentMethods = this.getQueryParamsRaw(query.paymentMethods);
    this.params.bookingChannels = this.getQueryParamsRaw(query.bookingChannels);
    this.params.bookingTypes = this.getQueryParamsRaw(query.bookingTypes);

    if (query.bookingReference) {
      this.params.bookingReference = query.bookingReference;
    } else {
      this.params.bookingReference = '';
    }

    if (query.companyCode) {
      this.params.companyCode = query.companyCode;
    } else {
       this.params.companyCode = '';
    }
    this.params.companies = query.companies || [];
  }

  fillSortParams(query) {
    if (query.sortOn && query.sortOn !== SearchTripsSortOnEnum.CreateDate) {
      const item: any = this.sortOnOptions
        .map(entry => entry.value)
        .find(value => value === query.sortOn);
      
      this.params.sortOnField = item;
    }
    if (query.sortDirection && query.sortDirection !== SearchTripsSortDirectionEnum.Descending) {
      const item: any = this.sortDirectionOptions
        .map(entry => entry.value)
        .find(value => value === query.sortDirection);
      
      this.params.sortDirection = item;
    }
  }

  async fillParameters(query) {
    this.fillSimpleParameters(query);

    this.params.destination = await this.getDestinationParam(query.destination);
    this.params.bookers = await this.getBookersParam(query.bookers);
    this.params.travellers = await this.getTravellersParam(query.travellers);

    this.fillSortParams(query);
  }

  fillQuery(query) {
    query.period = this.params.period !== this.defaultPeriod ? this.params.period : '';
    query.destination = this.mapDestinationObject(this.params.destination);
    query.startDateFrom = this.params.startDate.from;
    query.startDateTo = this.params.startDate.to;
    query.createDateFrom = this.params.createDate.from;
    query.createDateTo = this.params.createDate.to;
    query.endDateFrom = this.params.endDate.from;
    query.endDateTo = this.params.endDate.to;
    query.deadlineFrom = this.params.deadline.from;
    query.deadlineTo = this.params.deadline.to;
    query.wmCode = this.params.wmCode;
    query.bookingStatuses = this.params.bookingStatuses.map(status => status.value);
    query.serviceTypes = this.params.serviceTypes.map(type => type.value);
    query.bookers = this.params.bookers.map(user => user.id);
    query.travellers = this.params.travellers.map(user => user.id);
    query.companies = this.params.companies.map(user => user.id);
    query.bookingReference = this.params.bookingReference;
    query.companyCode = this.params.companyCode;
    
    if (this.$hasAccess('CanSeeProviderName')) {
      query.providers = this.params.providers;
      query.providerStatuses = this.params.providerStatuses;
    } else {
      query.providers = [];
      query.providerStatuses = [];
    }
    query.paymentMethods = this.params.paymentMethods;
    query.bookingChannels = this.params.bookingChannels;
    query.bookingTypes = this.params.bookingTypes;
    query.sortOn = this.params.sortOnField !== SearchTripsSortOnEnum.CreateDate ? this.params.sortOnField : undefined;
    query.sortDirection = this.params.sortDirection !== SearchTripsSortDirectionEnum.Descending ? this.params.sortDirection : undefined;
    if (this.params.page && this.params.page > 1) {
      query.page = this.params.page;
    }
  }

  async getItems(params: BookingsTableParams): Promise<GetItemsResult<SearchBooking>> {
    let searchParams = {
      period: params.period,
      destination: this.mapDestinationObject(this.params.destination),
      destinationCityId: this.params.destination ? this.params.destination.cityId : '',
      destinationCityName: this.params.destination ? this.params.destination.cityName : '',
      startDate: {
        from: params.startDate.from ?  moment(params.startDate.from).format('YYYY-MM-DD') : null,
        to: params.startDate.to ?  moment(params.startDate.to).format('YYYY-MM-DD') : null,
      },
      endDate: {
        from: params.endDate.from ?  moment(params.endDate.from).format('YYYY-MM-DD') : null,
        to: params.endDate.to ?  moment(params.endDate.to).format('YYYY-MM-DD') : null,
      },
      createDate: {
        from: params.createDate.from ? moment(params.createDate.from).format('YYYY-MM-DD') : null,
        to: params.createDate.to ? moment(params.createDate.to).format('YYYY-MM-DD') : null,
      },
      deadline: {
        from: params.deadline.from ? moment(params.deadline.from).format('YYYY-MM-DD') : null,
        to: params.deadline.to ? moment(params.deadline.to).format('YYYY-MM-DD') : null,
      },
      wmCode: params.wmCode,
      bookingStatuses: params.bookingStatuses.map(status => status.value),
      serviceTypes: params.serviceTypes.map(type => type.value),
      bookers: params.bookers.map(user => user.id),
      travellers: params.travellers.map(user => user.id),
      companies: params.companies.map(user => user.id),
      bookingReference: params.bookingReference,
      providers: this.$hasAccess('CanSeeProviderName') ? params.providers : [],
      providerStatuses: this.$hasAccess('CanSeeProviderName') ? params.providerStatuses : [],
      paymentMethods: params.paymentMethods,
      bookingChannels: params.bookingChannels,
      bookingTypes: params.bookingTypes,
      companyCode: params.companyCode,
      sortOnField: params.sortOnField,
      sortDirection: params.sortDirection,
    };

    this.errors = [];
  
    try {
      const result: any = await TripApi.searchBookings({
        page: params.page,
        size: params.size,
      }, searchParams);
     
      return {
        results: result.data.results,
        page: result.data.page,
      };
    } catch (error) {
      this.errors = this.$handleErrors(error);
      throw(error);
    }
  }

  tryExportBookings() {
    this.showExportConfirmation = true;
  }

  exportBookings() {
    this.exportBookingsWithParams(this.params);
  }

  prepareParameters(params: BookingsTableParams) {
    return {
      period: params.period,
      destination: this.mapDestinationObject(this.params.destination),
      startDate: {
        from: params.startDate.from ?  moment(params.startDate.from).format('YYYY-MM-DD') : null,
        to: params.startDate.to ?  moment(params.startDate.to).format('YYYY-MM-DD') : null,
      },
      endDate: {
        from: params.endDate.from ?  moment(params.endDate.from).format('YYYY-MM-DD') : null,
        to: params.endDate.to ?  moment(params.endDate.to).format('YYYY-MM-DD') : null,
      },
      createDate: {
        from: params.createDate.from ? moment(params.createDate.from).format('YYYY-MM-DD') : null,
        to: params.createDate.to ? moment(params.createDate.to).format('YYYY-MM-DD') : null,
      },
      wmCode: params.wmCode,
      bookingStatuses: params.bookingStatuses.map(status => status.value),
      serviceTypes: params.serviceTypes.map(type => type.value),
      bookers: params.bookers.map(user => user.id),
      travellers: params.travellers.map(user => user.id),
      companies: params.companies.map(user => user.id),
      sortOnField: params.sortOnField,
      sortDirection: params.sortDirection,
      bookingReference: params.bookingReference,
      companyCode: params.companyCode,
      providers: this.$hasAccess('CanSeeProviderName') ? params.providers : [],
      providerStatuses: this.$hasAccess('CanSeeProviderName') ? params.providerStatuses : [],
      paymentMethods: params.paymentMethods,
      bookingChannels: params.bookingChannels,
      bookingTypes: params.bookingTypes,
    };
  }

  exportBookingsWithParams(params: BookingsTableParams) {
    this.exportInProgress = true;
    let searchParams = this.prepareParameters(params);
  
    let self = this;
    self.loading = true;
    axios({
      method: 'POST',
      url: settings.apiTrip + '/api/bookings/export-to-file',
      responseType: 'blob',
      data: searchParams
    })
    .then((response) => {
      let filename;
      if (response && response.headers && response.headers['content-disposition']) {
        let disposition = response.headers['content-disposition'];
        if (!!disposition && disposition.indexOf('filename=') !== -1) {
          const fileNameIndex = disposition.indexOf('filename=');

          disposition = disposition.substring(fileNameIndex, disposition.length);
          const firstSemicolonIndex = disposition.indexOf(';');
          filename = disposition.substring(0, firstSemicolonIndex).replace('filename=', '').trim();
        }
      }

      if (!filename) {
        filename = 'Trips.xlsx';
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      self.errors = self.$handleErrors(error);
    }).finally(function() {
      self.loading = false;
      self.exportInProgress = false;
      self.showExportConfirmation = false;
    });
  }

  onPaginationChange(value) {
    this.params.page = value;
  }

  showTripFilters() {
    this.showFilters = true;
  }

  hideTripFilters() {
    this.showFilters = false;
  }

  toggleSort(type, direction) {
    this.params.sortOnField = type;
    this.params.sortDirection = direction;
    this.toggleSortOptions = false;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'arrangersLoading',
  })
  async loadArrangers(query) {
    try {
      this.usersErrors = [];
      this.arrangersLoading = true;
      const result = await ProfileApi.searchTravellers(query, false, false);
      if (result && result.data) {
        this.arrangers = result.data;
      } else {
        this.arrangers = [];
      }
    } catch (error) {
      this.usersErrors = this.$handleErrors(error);
    } finally {
      this.arrangersLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'travellersLoading',
  })
  async loadTravellers(query) {
    try {
      this.usersErrors = [];
      this.travellersLoading = true;
      const result = await ProfileApi.searchTravellers(query, true, false);
      if (result && result.data) {
        this.travellers = result.data;
      } else {
        this.travellers = [];
      }
    } catch (error) {
      this.usersErrors = this.$handleErrors(error);
    } finally {
      this.travellersLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  async loadLocations(query) {
    this.locationsQueryLength = query.length;
    if (!query || query.length < 3) {
      return;
    }
    try {
      this.locations = [];
      this.locationsErrors = [];
      this.locationsLoading = true;
      const result = await DictionaryApi.findCityCountry(query);
      if (result && result.data) {
        this.locations = result.data.filter(entry => {
          return entry.type === 'City';
        });
      } else {
        this.locations = [];
      }
    } catch (error) {
      this.locationsErrors = this.$handleErrors(error);
    } finally {
      this.locationsLoading = false;
    }
  }

  action(item) {
    router.push({
      name: 'basket',
      params: { id: item.id }
    });
  }

  showMoreFilters() {
    this.shouldShowMoreFilters = true;
  }

  hideMoreFilters() {
    this.shouldShowMoreFilters = false;
  }

  handleEnterPress() {
    setTimeout(() => {
      const btn = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getResults() {
    this.resetList(); 
    this.reload();
  }
}
