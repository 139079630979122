












import { Vue, Component, Prop } from 'vue-property-decorator';
import settings from '@/settings';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class ReportsWidget extends Vue {
  @Prop({ default: 600 }) width!: number;
  @Prop({ default: 'small' }) type!: string;

  get reportsSmallIframeUrl() {
    return settings.hasOwnProperty('reportsSmallIframeUrl') ? sanitizeUrl(settings.reportsSmallIframeUrl) : null;
  }

  get reportsWideIframeUrl() {
    return settings.hasOwnProperty('reportsWideIframeUrl') ? sanitizeUrl(settings.reportsWideIframeUrl) : null;
  }

  get iframeUrl() {
    if (this.type === 'small') {
      return this.reportsSmallIframeUrl;
    } else if (this.type === 'wide') {  
      return this.reportsWideIframeUrl;
    }
    return null;
  }
  
}

