
















































































































import {Component, Prop} from 'vue-property-decorator';
import {router} from '@/router';

import {BaseTable} from '@/core/base-table.class';
import {BaseTableParams} from '@/core/base-table-params';
import {ProviderMappingModel} from '@/api/custom-fields/custom-fields.model';
import {CustomFieldsProviderMappingsApi} from '@/api/custom-fields/custom-fields-provider-mappings.api';
import EventHandler from '@/services/event-handler';
import {translate} from '@/i18n';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';

import UiTableWrapper from '@/controls/UiTableWrapper.vue';
import UiPopup from '@/controls/UiPopup.vue';
import UiIcon from '@/controls/UiIcon.vue';
import UiDropdown from '@/controls/UiDropdown.vue';
import UiForm from '@/controls/UiForm.vue';
import UiButton from '@/controls/UiButton.vue';
import UiErrors from '@/controls/UiErrors.vue';

@Component({
  components: {
    UiErrors,
    UiButton,
    UiForm,
    UiDropdown,
    UiIcon,
    UiPopup,
    UiTableWrapper,
  }
})
export default class ProviderMappings extends BaseTable<ProviderMappingModel> {
  @Prop({})
  readonly!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sort: 'provider',
    desc: false,
    size: 10,
    start: 0,
  });

  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;

  fields = {
    name: {
      sortable: false,
      label: translate('settings-custom-fields.field-name')
    },
    provider: {
      sortable: false,
      label: translate('settings-custom-fields.provider')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  loading: boolean = false;

  menuActive: boolean = false;
  removePopup: boolean = false;

  errors: any[] = [];
  errorsOnPopup: any[] = [];

  selected: ProviderMappingModel | null = null;
  selectedIndex: number = -1;

  get entries() {
    return CustomFieldsStore.providerMappings;
  }

  get loadingPage() {
    return CustomFieldsStore.loading;
  }

  get count() {
    return this.entries.length;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get perPage() {
    return this.params.size;
  }

  set perPage(value) {
    this.params.size = value;
  }

  compareByFieldNameAndProvider(f1, f2) {
    if (f1.customField.name.toLowerCase() > f2.customField.name.toLowerCase()) {
        return 1;
      }
    if (f1.customField.name.toLowerCase() < f2.customField.name.toLowerCase()) {
      return -1;
    }
    if (f1.provider.toLowerCase() > f2.provider.toLowerCase()) {
      return 1;
    }
    if (f1.provider.toLowerCase() < f2.provider.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  toggleMenu(row) {
    if (!this.menuActive || (this.selected !== row.item)) {
      this.selected = row.item;
      this.selectedIndex = row.index;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    let params: any = {
      mappingId: item.id,
    };

    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'provider-mapping',
      params
    });
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeProviderMapping() {
    try {
      CustomFieldsStore.setLoading(true);
      await CustomFieldsProviderMappingsApi.deleteMapping(this.selected!.id);

      const toast = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };

      EventHandler.$emit('show-toast', toast);
    } catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
    } finally {
      if (!this.errorsOnPopup.length) {
        if (this.configurationId) {
          CustomFieldsStore.getProviderMappings(this.configurationId);
        }

        this.removePopup = false;
      }

      CustomFieldsStore.setLoading(false);
    }
  }

  async submitForm() {
    CustomFieldsStore.setProviderMappings([]);
    this.reload();
  }
}
