import DisplayPreferencesConfigurations from '@/modules/settings/display-preferences/configurations/DisplayPreferencesConfigurations.vue';

export default {
  path: '',
  name: 'display-preferences-configurations',
  component: DisplayPreferencesConfigurations,
  displayName: 'Display Preferences Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDisplayPreferences',
    parentName: 'display-preferences-configurations',
    depthCategory: 'display-preferences',
    waitForDicts: true,
    depth: 1,
  }
};
