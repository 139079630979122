






































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { AmadeusPreEmdAnswer } from '@/api/trip/basket-item.model';
import { translate } from '@/i18n';
import BasketStore from './basket.store';
import { userFullName } from '@/core/user-full-name';

@Component({})
export default class BasketAmadeusTicketingView extends Vue {
  @Prop() item!: any;

  showDetails: boolean = false;
  showConfirmPopup: boolean = false;



  get basketStatus() {
    return BasketStore.status;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get amadeusOfferBooking() {
    return BasketStore.amadeusOfferBooking;
  }

  get amadeusPendingAction() {
    if (
      this.item.pendingActions &&
      !!this.item.pendingActions
        .find(action => action.code === 'AMADEUS_UNCONFIRMED_STATUS')
    ) {
      return true;
    }
    return false;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get passengers() {
    if (!this.amadeusOfferBooking || !this.amadeusOfferBooking.passengers) {
      return [];
    }
    return this.amadeusOfferBooking.passengers;
  }



  userFullName(user) {
    return userFullName(user);
  }

  travellerById(id) {
    return this.travellers.find(traveller => traveller.id === id);
  }

  ancillariesOfSegment(segment, ancillaries) {
    return ancillaries
      .filter(ancillary => {
        return ancillary.flightIds.some(flight => flight === segment.id);
      });
  }

  seatsOfSegment(segment, seats) {
    return seats
      .filter(seat => {
        return seat.segmentId === segment.id;
      });
  }

  notEmptySegments(passenger, segments) {
    return segments.filter(segment => {
      const hasSeats = passenger.seats.some(seat => seat.segmentId === segment.id);
      const hasAncillaries = passenger.ancillaries.some(ancillary => ancillary.flightIds.some(flight => flight === segment.id));

      return hasSeats || hasAncillaries;
    });
  }

  skipAmadeusTicketing() {
    this.showConfirmPopup = true;
  }

  skipAmadeusTicketingNow() {
    this.showConfirmPopup = false;
    BasketStore.answerAmadeusTicketing(AmadeusPreEmdAnswer.Skip);
  }

  @Watch('amadeusPendingAction', {immediate: true})
  onAmadeusPendingAction(value) {
    if (!value) {
      return;
    }

    if (!this.basketStatus) {
      return;
    }

    const object = this.basketItems.find(i => i.id === this.item.id);
    if (object && object.providerReferenceId) {
      this.unconfirmedStatusChange(object);
    }
  }

  async unconfirmedStatusChange(item) {
    await BasketStore.getAmadeusOfferBooking(item.providerReferenceId);
  }
}

