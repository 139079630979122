



















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';

@Component({
  filters: {
    priceFix(price) {
      return price ? Number.parseFloat(price).toFixed(2) : ' ';
    }
  }
})
export default class BoolFilter extends Vue {
  @Prop() label!: string;
  @Prop() elem!: any;

  visible = true;
  checked: boolean = false;

  get getChecked() {
    if (!this.elem) {
      return false;
    }

    if (this.elem && this.elem.data && !!this.elem.data.selected) {
      return this.elem.data.selected;
    } else {
      return false;
    }
  }

  set getChecked(value) {
    this.checked = value;
  }

  onChange() {
    this.$emit('change', {
      code: this.elem.code,
      data: this.checked
    });
  }

  @Watch('checked')
  checkedChangedHandler(checked) {
    this.elem.value = checked;
    this.onChange();
  }

  clearFilter() {
    this.checked = false;
  }

  mounted() {
    if (this.elem && this.elem.data && !!this.elem.data.selected) {
      this.checked = this.elem.data.selected;
    }
  }

  created() {
    EventBus.$on('clear-filters', this.clearFilter);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearFilter);
  }
}

