













































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { AirLocationModel } from '@/api/home/home.model';

@Component
export default class UiCarAutocompleteTemplate extends Vue {
  @Prop() props!: any;
  @Prop() options!: AirLocationModel[];
}

