import AirResults from '@/modules/search/air/AirResults.vue';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';
import AirSubIntro from '@/modules/search/air/AirSubintro.vue';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/air-modification-travellers/:searchId/basket/:basketId',
  name: 'airModificationTravellers',
  components: {
    default: AirResults,
    subintro: AirSubIntro,
    intro: SearchTravellersList,
    sidebar: AirResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        air: AnimationName.UP,
      },
    }
  }
};
