


































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { TFlight } from '@/api/trip/basket-air-seats-extras.model';
import AccountStore from '@/store/account.store';

@Component({})
export default class FlightsTile extends Vue {
  @Prop() flights!: TFlight[];
  @Prop() isIncluded!: boolean;
  @Prop() icon!: string;
  @Prop() title!: string;

  getDateFormat(date: string) {
    const dateFormat = AccountStore.current!.profile.shortDateFormat;
    const timeFormat = AccountStore.current!.profile.timeFormat;
    return moment(date).format(`${dateFormat} ${timeFormat}`);
  }
}
