import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { Permission } from '@/const/permission.enum';

export class ClientEmailNotificationConfiguration {
  rootCompanyId?: string;
  configurationId?: string;
  name: string = '';
  noPricesInEmail: boolean = false;
  includeInCc: string[] = [];
  sendEmailToManager: boolean = false;
  customContactDetails: {
    label: string;
    value: string;
  }[] = [];
  incidentEmailReceivers: string[] = [];
  incidentEmailLanguageCode: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.noPricesInEmail !== undefined) {
        this.noPricesInEmail = data.noPricesInEmail;
      }
      if (data.includeInCc !== undefined) {
        this.includeInCc = data.includeInCc;
      }
      if (data.sendEmailToManager !== undefined) {
        this.sendEmailToManager = data.sendEmailToManager;
      }
      if (data.customContactDetails !== undefined) {
        this.customContactDetails = data.customContactDetails;
      }
      if (data.incidentEmailReceivers !== undefined) {
        this.incidentEmailReceivers = data.incidentEmailReceivers;
      }
      if (data.incidentEmailLanguageCode !== undefined) {
        this.incidentEmailLanguageCode = data.incidentEmailLanguageCode;
      }
    }
  }
}

export class ClientEmailNotificationConfigurationApiClass {
  public createConfiguration(requestMessage: ClientEmailNotificationConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/client-email-notifications-configuration',
      method: RequestMethod.POST,
      permission: Permission.CanEditClientEmailNotifications,
    }, {}, requestMessage );
  }

  public getConfiguration(configurationId: string): Promise<AxiosResponse<ClientEmailNotificationConfiguration>> {
    return http.execute({
      path: settings.apiTrip + '/api/client-email-notifications-configuration/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateConfiguration(message: ClientEmailNotificationConfiguration): Promise<AxiosResponse<ClientEmailNotificationConfiguration>> {
    return http.execute({
      path: settings.apiTrip + '/api/client-email-notifications-configuration/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, {
      name: message.name,
      noPricesInEmail: message.noPricesInEmail,
      includeInCc: message.includeInCc,
      sendEmailToManager: message.sendEmailToManager,
      customContactDetails: message.customContactDetails,
      incidentEmailReceivers: message.incidentEmailReceivers,
      incidentEmailLanguageCode: message.incidentEmailLanguageCode,
    });
  }

}

export const ClientEmailNotificationApi: ClientEmailNotificationConfigurationApiClass = new ClientEmailNotificationConfigurationApiClass();