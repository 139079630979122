



























































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { hasSomeParentTheClass } from '@/modules/layout/scroll-manager';
import SearchStore from '../search.store';
import AccountStore from '@/store/account.store';

@Component({})
export default class AirOfferParametersSelect extends Vue {
  isInPopup: boolean = false;
  popupContainer: HTMLElement | null = null;
  isActive: boolean = false;
  blurTimeout: number = -1;
  touchedIndex: number = -1;
  selectedSum: number = 0;

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }

  get mobileClasses() {
    return {
      'ui-autocomplete__mobile--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile--active': this.isActive,
    };
  }

  get splitBookingPermission() {
    return AccountStore.HasPermission('CanDecideOnSplitSearch');
  }

  @Watch('airSearchParameters.nonStopsOnly', { immediate: true })
  onValueChange(value) {
    if (!value && this.selectedSum > 0) {
      this.selectedSum = this.selectedSum - 1;

    } else if (value) {
       this.selectedSum = this.selectedSum + 1;
    }
  }

  @Watch('airSearchParameters.additionalLuggageOnly', { immediate: true })
  additionalLuggageOnlyValueChange(value) {
    if (!value && this.selectedSum > 0) {
      this.selectedSum = this.selectedSum - 1;

    } else if (value) {
       this.selectedSum = this.selectedSum + 1;
    }
  }

  @Watch('airSearchParameters.flexOnly', { immediate: true })
  flexOnlyValueChange(value) {
    if (!value && this.selectedSum > 0) {
      this.selectedSum = this.selectedSum - 1;

    } else if (value) {
       this.selectedSum = this.selectedSum + 1;
    }
  }

  @Watch('airSearchParameters.isSplitSearchEnabled', { immediate: true })
  isSplitSearchEnabledChange(value) {
    if (!value && this.selectedSum > 0) {
      this.selectedSum = this.selectedSum - 1;

    } else if (value) {
       this.selectedSum = this.selectedSum + 1;
    }
  }

  deselectAll() {
    this.airSearchParameters.nonStopsOnly = false;
    this.airSearchParameters.additionalLuggageOnly = false;
    this.airSearchParameters.flexOnly = false;
  }

  mobileFocus() {
    clearTimeout(this.blurTimeout);
    this.isActive = true;
    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  mobileClick($event) {
    const el = $event.target;
    const isUiAutocomplete = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-field');
    const isUiAutocompleteInput = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-input');
    const isUiAutocompleteToggle = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-toggle');
    const isUiAutocompleteOption = hasSomeParentTheClass(el, 'option-item');
    const isUiAutocompleteOptions = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-options');
    
    if (
      this.isActive && ((isUiAutocomplete && isUiAutocompleteToggle) ||
      (!isUiAutocompleteOption && isUiAutocompleteOptions))
    ) {
      return this.$refs.extrasMobileInput && 
        (this.$refs.extrasMobileInput as HTMLInputElement).blur();
    }

    this.$nextTick(() => {
      return this.$refs.extrasMobileInput && 
        (this.$refs.extrasMobileInput as HTMLInputElement).focus();
    });
  }

  confirm() {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => {
      this.isActive = false;
      if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('unfreeze-popup', this.popupContainer);
      }
    }, 100);
  }
}
