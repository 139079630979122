








































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import TripsOnHoldWidget from '@/modules/trips/TripsOnHoldWidget.vue';
import TripsLastBookedWidget from '@/modules/trips/TripsLastBookedWidget.vue';
import TripsPendingApprovalWidget from '@/modules/trips/TripsPendingApprovalWidget.vue';
import JourneyAssistantWidget from '@/modules/journey-assistant/JourneyAssistantWidget.vue';
import MessagesWidget from './MessagesWidget.vue';
import OfflineRequestsWidget from '@/modules/offline/OfflineRequestsWidget.vue';
import AgentsPoolWidget from './AgentsPoolWidget.vue';
import LHNDCPartnerWigdet from './LHNDCPartnerWigdet.vue';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';
import settings from '@/settings';
import hasAccessFn from '@/core/permissions/has-access.service';
import LayoutStore from '@/modules/layout/layout.store';
import EventBus from '@/services/event-handler';
import CustomMessagesWidget from './CustomMessagesWidget.vue';
import DemoWelcomeWidget from './DemoWelcomeWidget.vue';
import ReportsWidget from './ReportsWidget.vue';

@Component({
  components: {
    JourneyAssistantWidget,
    TripsOnHoldWidget,
    TripsLastBookedWidget,
    TripsPendingApprovalWidget,
    OfflineRequestsWidget,
    AgentsPoolWidget,
    MessagesWidget,
    LHNDCPartnerWigdet,
    CustomMessagesWidget,
    DemoWelcomeWidget,
    ReportsWidget,
  },
})
export default class Home extends Vue {

  get user() {
    return AccountStore.current;
  }

  get canShowExternalAgentsWidget() {
    return 'true' === settings.enableExternalAgentsWidget;
  }

  get canShowExternalOffline() {
    return 'true' === settings.enableExternalOffline;
  }

  get canShowExternalMessagesWidget() {
    return 'true' === settings.enableExternalMessagesWidget;
  }

  get canShowExternalTripsWidgets() {
    return 'true' === settings.enableExternalTripsControls;
  }

  get canShowExternalSalesPictures() {
    return 'true' === settings.enableExternalSalesPictures;
  }

  get companyIdsNDCOfferWidget() {
    return settings.companyIdsNDCOfferWidget;
  }

  get canShowDemoWelcomeWidget() {
    return 'true' === settings.enableDemoWelcomeWidget;
  }

  get reportsSmallIframeUrl() {
    return settings.hasOwnProperty('reportsSmallIframeUrl') && '' !== settings.reportsSmallIframeUrl ? true : false;
  }

  get reportsWideIframeUrl() {
    return settings.hasOwnProperty('reportsWideIframeUrl') && '' !== settings.reportsWideIframeUrl ? true : false;
  }

  get canShowNDCPartnerWigdet() {
    if (this.user && this.user.profile && this.user.profile.rootCompanyId) {
      if (-1 !== this.companyIdsNDCOfferWidget.indexOf(this.user.profile.rootCompanyId) ||
          -1 !== this.companyIdsNDCOfferWidget.indexOf(this.user.profile.companyId)) {
        return true;
      }
    }

    return false;
  }

  scrollToWidget() {
    if (!this.$refs.LHNDCPartnerWigdet) {
      return;
    }
    LayoutStore.scrollToElementAlways((this.$refs.LHNDCPartnerWigdet as Vue).$el);
  }

  scrollToCustomMessages() {
    const widget = this.$refs.CustomMessagesWidget;
    
    if (!widget) {
      return;
    }
    LayoutStore.scrollToElementAlways((widget as Vue).$el);
  }

  @Watch('$route', { deep: true, immediate: true })
  onChildChanged(to, from, next) {
    if (to.name === 'home' && hasAccessFn('SelectTravellersForSearch')) {
      SearchStore.resetTravellerSearchState();
      SearchStore.resetSearchState();
    } else {
      SearchStore.updateBackFlag(true);
    }
  }

  mounted() {
    EventBus.$on('ndc-widget', this.scrollToWidget);
    EventBus.$on('custom-messages-widget', this.scrollToCustomMessages);
  }

  beforeDestroy() {
    EventBus.$off('ndc-widget', this.scrollToWidget);
    EventBus.$off('custom-messages-widget', this.scrollToCustomMessages);
  }
}
