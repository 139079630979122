










































import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import EventBus from '@/services/event-handler';
import { TBenerailSeatPreferencesModel } from '@/api/trip/basket.model';
import { TTrainTile } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import { Price } from '@/api/expense/expense.model';
import TrainsTile from '@/modules/basket/basket-rail-seats-extras/TrainsTile.vue';
import SeatsBenerailContent from '@/modules/basket/basket-rail-seats-extras/content/SeatsBenerailContent.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    SeatsBenerailContent,
    TrainsTile,
  },
})
export default class RailBenerailSeatSelection extends Vue {
  @Prop() basketItem!: any;

  get benerailSeatPreferencesModel() {
    return BasketStore.benerailSeatPreferencesModel;
  }
  set benerailSeatPreferencesModel(benerailSeatPreferences: TBenerailSeatPreferencesModel) {
    BasketStore.setBenerailSeatPreferencesModel(benerailSeatPreferences);
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: this.bookingStep,
      type: 'seats',
      prices: this.seatPrices,
    });
  }

  get isLoading() {
    return BasketStore.basketRailSeatPreferencesLoading;
  }

  get passengers() {
    return (BasketStore.basket && BasketStore.basket.travellers) || [];
  }

  get legs() {
    return BasketStore.benerailSeatPreferences.legs;
  }

  get trains() {
    return this.legs.reduce((prev, next) => {
      const trains = next.trains.map((item) => ({
        legNumber: next.legNumber,
        options: item.options,
        trainNumber: item.train.number,
        to: item.train.to.stationName,
        departureDate: item.train.departure,
        from: item.train.from.stationName,
        segmentId: item.segmentId,
        type: item.train.type,
      }));
      return prev.concat(trains);
    }, [] as TTrainTile[]);
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get seatPrices() {
    return this.benerailSeatPreferencesModel.options.reduce((prices, option) => {
      const leg = this.legs.find((item) => item.legNumber === option.legNumber);
      if (!leg) {
        return prices;
      }
      const train = leg.trains.find((item) => item.segmentId === option.segmentId);
      if (!train) {
        return prices;
      }
      const trainOption = train.options.find((item) => item.offerPartId === option.offerPartId);
      if (!trainOption) {
        return prices;
      }
      return option.isIncluded ? prices.concat(trainOption.price) : prices;
    }, [] as Price[]);
  }

  get isAvailableLeastOneSeatSelection() {
    return this.trains.some(({ options }) => (options || []).some(({ categories }) => categories.length > 0));
  }

  setInitModel() {
    this.passengers.forEach((passenger) => {
      this.trains.forEach((item) => {
        const option = (item.options || []).find((option) => option.passengers.some((item) => item.externalReference === passenger.id));
        if (option && option.offerPartId) {
          this.benerailSeatPreferencesModel = {
            options: this.benerailSeatPreferencesModel.options
              .filter((item) => item.offerPartId !== option.offerPartId)
              .concat({
                isIncluded: true,
                legNumber: item.legNumber,
                offerPartId: option.offerPartId,
                preferences: null,
                segmentId: item.segmentId,
              }),
          };
        }
      });
    });
  }

  isValid() {
    const seatsBenerailContentRef = this.$refs.seatsBenerailContentRef as SeatsBenerailContent[];
    if (seatsBenerailContentRef) {
      const errors = seatsBenerailContentRef.map((item) => !item.isValid());
      return !errors.some((item) => item);
    }
    return true;
  }

  validate() {
    const isValid = this.isValid();
    BasketStore.setBasketRailBenerailSeatPreferencesError(!isValid);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.setBenerailSeatPreferencesModel({ options: [] });
    await BasketStore.getBasketRailBenerailSeatPreferences({
      id: this.basketItem.providerReferenceId,
      supplier: this.basketItem.supplier,
    });
    this.setInitModel();
    BasketStore.setBookingStepLoading(false);
  }

  created() {
    EventBus.$on('validate-benerail-seats', this.validate);
  }

  beforeDestroy() {
    EventBus.$off('validate-benerail-seats', this.validate);
  }
}
