








































import { Vue, Component } from 'vue-property-decorator';
import ImportProfilesCsvFile from './ImportProfilesCsvFile.vue';
import ImportsList from './ImportsList.vue';
import EventHandler from '@/services/event-handler';
import { sanitizeUrl } from '@braintree/sanitize-url';
import settings from '@/settings';

@Component({ 
  components: {
   ImportProfilesCsvFile,
   ImportsList
  }
})

export default class ImportProfiles extends Vue {

  readonly TAB_IMPORT_CSV = 'TAB_IMPORT_CSV';
  readonly TAB_IMPORT_HISTORY = 'TAB_IMPORT_HISTORY';

  selectedTab: string | null = this.TAB_IMPORT_CSV;

  get guidelinesUrl() {
    return sanitizeUrl(settings.travellersImportGuidelinesUrl);
  }

  selectTab(tab) {
    this.selectedTab = tab;
  }

  created() {
    EventHandler.$on('show-import-profiles-list', this.showImporProfilesList);
  }

  showImporProfilesList(data) {
    this.selectTab(this.TAB_IMPORT_HISTORY);
  }

  beforeDestroy() {
    EventHandler.$off('show-import-profiles-list', this.showImporProfilesList);
  }

}
