










import { Vue, Component } from 'vue-property-decorator';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';

@Component({
  components: {
    SearchTravellersList,
  }
})
export default class SearchTravellersIntro extends Vue {
  
}
