import CompanyStructure from '@/modules/settings/company-structure/CompanyStructure.vue';
import companyStructureconfigurations from './company-structure.configurations.route';
import companyStructureManageConfiguration from './company-manage.route';
import companyStructureAddEdit from './company-structure-addedit.route';
import { translate } from '@/i18n';

export default {
  path: 'company-structure',
  component: CompanyStructure,
  displayName: 'settings-sidebar.company-structure',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyStructure',
    icon: 'account_tree',
    parentName: 'company-structure',
  },
  children: [
    companyStructureconfigurations,
    companyStructureManageConfiguration,
    companyStructureAddEdit,
  ],
};
