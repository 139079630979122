











































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { Debounce } from '@/core/decorators/debounce.decorator';
import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import filtersConst from '@/const/filter.const';
import RangeFilter from '../controls/RangeFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import CarSearchStore from './car-search.store';
import { translate } from '@/i18n';

@Component({
  components: {
    CategoriesFilter,
    RangeFilter,
    FiltersContentLoading,
  }
})
export default class CarResultsFilters extends Vue {

  get filtersMetadata() {
    return {
      rangeFilters: filtersConst.car.filters.rangeFilters.map((item) => {
        return {
          ...item,
          label: translate(item.label),
          type: 'range'
        };
      }),
      categoriesFilters: filtersConst.car.filters.categoriesFilters.map((item) => {
        return {
          ...item,
          label: translate(item.label),
          type: 'category'
        };
      })
    };
  }

  get currency() {
    return CarSearchStore.currency;
  }

  get offers() {
    return CarSearchStore.offers;
  }

  get filters() {
    return CarSearchStore.filters;
  }

  get loading() {
    return CarSearchStore.loading;
  }

  get isDisabled() {
    return CarSearchStore.selectingOffer;
  }

  get globalClass() {
    return {
      'md-hidden-mobile': !this.$route.meta.mobile,
      'filters-loading': this.isDisabled,
      'filters-container': this.$route.meta.mobile,
    };
  }

  get hasFiltersError() {
    return CarSearchStore.filtersError;
  }

  get rangeFilters() {
    return this.filtersMetadata.rangeFilters.map((m) => {
      const filterData = this.getFilterData(m.code);
      return {
        code: m.code,
        label: m.label,
        type: m.type,
        data: filterData
      };
    });
  }

  get topCategoriesFilters() {
    return this.categoriesFilters
      .filter(filter => !!filter.stickTop);
  }

  get regularCategoriesFilters() {
    return this.categoriesFilters
      .filter(filter => !filter.stickTop);
  }

  get categoriesFilters() {
    return this.filtersMetadata.categoriesFilters.map((m) => {
      const data = {
        code: m.code,
        label: m.label,
        type: m.type,
        stickTop: !!m.stickTop,
        maxCategoriesInCollapsedMode: m.maxCategoriesInCollapsedMode,
        data: this.getFilterData(m.code),
      };

      if (m && m.itemLabels) {
        data.data.forEach(d => {
          d.name = m.itemLabels![d.code] ? translate(m.itemLabels![d.code]) : d.name;
        });
      }

      return data;
    });
  }



  filterVisible(filterMetadata) {
    const supplierFilterInvisible = filterMetadata.code === 'SUPPLIER' && !this.$hasAccess('CanSeeProviderName');
    const isInFilters = this.filters.some(f => f.code === filterMetadata.code);

    if (supplierFilterInvisible) {
      return false;
    }

    return isInFilters;
  }

  getFilterData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.values : null;
  }

  convertRangeFilter(filter) {
    const minEnabled = filter.data && filter.data.minValue > filter.data.minLimit;
    const maxEnabled = filter.data && filter.data.maxValue < filter.data.maxLimit;

    const fData = {
      code: filter.code,
      min: minEnabled ? filter.data.minValue : null,
      max: maxEnabled ? filter.data.maxValue : null,
    };

    return fData;
  }

  convertCategoryFilter(filter) {
    const fData = {
      code: filter.code,
      requiredCategories: filter.data.filter(v => v.selected).map(v => v.code),
    };

    return fData;
  }

  buildFiltersDataPayload() {
    const filtersData = {
      numericRangeFilters: this.rangeFilters.map(f => this.convertRangeFilter(f)),
      categoriesFilters: this.categoriesFilters.map(f => this.convertCategoryFilter(f)),
    };
    return filtersData;
  }

  refreshFiltersComponents(refs) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      EventBus.$emit('refresh-filter-component', {
        serviceType: 'car',
        code: element.code,
        data: this.getFilterData(element.code),
      });
    });
  }

  @Watch('filters')
  onChangeFilters() {
    this.refreshFiltersComponents(this.$refs.topCategoriesFilters);
    this.refreshFiltersComponents(this.$refs.categoriesFilters);
    this.refreshFiltersComponents(this.$refs.rangeFilters);
  }

  callFiltersChanged() {
    const searchId = this.$route.params.searchId;

    CarSearchStore.setFiltersRequest({
      filtersData: this.buildFiltersDataPayload(),
      searchId,
    });
    CarSearchStore.setFiltersChanging(true);
    this.filtersChanged();
  }

  @Debounce({
    delay: 300,
  })
  filtersChanged() {
    this.filterOffersMethod();
  }

  async filterOffersMethod() {
    const searchId = this.$route.params.searchId;
    const requestId = CarSearchStore.filtersRequestId + 1;

    if (!searchId) {
      CarSearchStore.setFiltersChanging(false);
      return;
    }

    await CarSearchStore.updateFilterOffers({ searchId });
    CarSearchStore.setFiltersChanging(false);
    if (!this.hasFiltersError && requestId === CarSearchStore.filtersRequestId) {
      await CarSearchStore.getOffers(searchId);
    }
  }

  rangeFilterUpdated(value) {
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values.minValue = value.data.min;
    filter.values.maxValue = value.data.max;
    this.callFiltersChanged();
  }

  categoriesFilterUpdated(value) {
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values = value.data;
    this.callFiltersChanged();
  }

  showResults() {
    router.push({ name: 'car' });
  }
}
