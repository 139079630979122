




















































































































































































































































import { Vue, Component } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { CompanyConfigurationsApi } from '@/api/profile/company-configurations.api';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { Permission } from '@/const/permission.enum';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { ProfileCompanyApi } from '@/api/profile/company.api';

const NoConfigItem = {
  id: null,
  name: translate('settings-company.no-configuration'),
};

const NoAgencyConfigItem = {
  configurationId: null,
  name: translate('settings-company.no-configuration'),
};

const getLinkConfiguration = (companyId, configurationId) => {
  return [
    {
      configurationType: 'ThemeConfiguration',
      url: `/settings/${companyId}/theme/${configurationId}/theme-configuration`,
      permission: 'ReadCompanyTheme',
    },
    {
      configurationType: 'ProfileSyncConfiguration',
      url: `/settings/${companyId}/profile-sync-configurations/${configurationId}/profile-sync-configuration`,
      permission: 'CanEditProfilesSynchronization',
    },
    {
      configurationType: 'PluginsConfiguration',
      url: `/settings/${companyId}/plugins-configurations/${configurationId}/plugins-configuration`,
      permission: 'CanEditPlugins',
    },
    {
      configurationType: 'SecurityConfiguration',
      url: `/settings/${companyId}/security-configurations/${configurationId}/security-configuration`,
      permission: 'CanEditSecurityConfiguration',
    },
    {
      configurationType: 'AirProviderConfiguration',
      url: `/settings/${companyId}/air-provider-configurations/${configurationId}`,
      permission: 'ReadFlightProviders',
    },
    {
      configurationType: 'RailProviderConfiguration',
      url: `/settings/${companyId}/rail-provider-configurations/${configurationId}`,
      permission: 'ReadRailProviders',
    },
    {
      configurationType: 'AccommodationProviderConfiguration',
      url: `/settings/${companyId}/accommodation-provider-configurations/${configurationId}`,
      permission: 'ReadAccommodationProviders',
    },
    {
      configurationType: 'CarProviderConfiguration',
      url: `/settings/${companyId}/car-provider-configurations/${configurationId}`,
      permission: 'ReadCarProviders',
    },
    {
      configurationType: 'NegoFareConfiguration',
      url: `/settings/${companyId}/nego-fare-configurations/${configurationId}`,
      permission: 'ReadNegoFare',
    },
    {
      configurationType: 'FareMappingConfiguration',
      url: `/settings/${companyId}/fares-mapping-configurations/${configurationId}/fares-mapping-configuration`,
      permission: 'CanEditFareMapping',
    },
    {
      configurationType: 'NotificationConfiguration',
      url: `/settings/${companyId}/providers-notifications/${configurationId}`,
      permission: 'CanEditProvidersNotificationConfigurations',
    },
    {
      configurationType: 'TravelPolicyConfiguration',
      url: `/settings/${companyId}/travel-policy/${configurationId}/travel-policy-configuration`,
      permission: 'ReadTravelPolicy',
    },
    {
      configurationType: 'ApprovalWorkflowConfiguration',
      url: `/settings/${companyId}/approval-workflow/${configurationId}/configuration`,
      permission: 'ReadApprovalWorkflow',
    },
    {
      configurationType: 'DisplayPreferencesConfiguration',
      url: `/settings/${companyId}/display-preferences/${configurationId}/display-preferences-configuration`,
      permission: 'CanEditDisplayPreferences',
    },
    {
      configurationType: 'CustomFieldsConfiguration',
      url: `/settings/${companyId}/custom-fields/${configurationId}/field-configuration/false`,
      permission: 'ReadCustomFieldDefinitions',
    },
    {
      configurationType: 'BackOfficeConfiguration',
      url: `/settings/${companyId}/billing/${configurationId}/billing-configuration`,
      permission: 'ReadBackOfficeConfiguration',
    },
    {
      configurationType: 'BackOfficeCustomFieldsConfiguration',
      url: `/settings/${companyId}/billing-custom-fields/${configurationId}/billing-custom-fields-configuration`,
      permission: 'CanAssignBackOfficeCustomFields',
    },
    {
      configurationType: 'PnrRemarksConfiguration',
      url: `/settings/${companyId}/pnr-remarks/${configurationId}/pnr-remarks-configuration`,
      permission: 'ReadPnrRemarks',
    },
    {
      configurationType: 'FeesConfiguration',
      url: `/settings/${companyId}/fees/${configurationId}/fees-configuration`,
      permission: 'CanEditFeesConfiguration',
    },
    {
      configurationType: 'AgencyMarkupConfiguration',
      url: `/settings/${companyId}/markups/${configurationId}/markups-configuration`,
      permission: 'CanEditAgencyMarkupsConfiguration',
    },
    {
      configurationType: 'SystemMarkupConfiguration',
      url: `/settings/${companyId}/system-markup/${configurationId}/system-markup-configuration`,
      permission: 'CanEditSystemMarkupConfiguration',
    },
    {
      configurationType: 'ClientEmailNotification',
      url: `/settings/${companyId}/client-email-notifications/${configurationId}/configuration`,
      permission: 'CanEditClientEmailNotifications',
    },
    {
      configurationType: 'MissedSavingsConfiguration',
      url: `/settings/${companyId}/missed-savings/${configurationId}/configuration`,
      permission: 'CanEditMissedSavings',
    },
    {
      configurationType: 'DelayTicketingConfiguration',
      url: `/settings/${companyId}/delay-ticketing/${configurationId}/delay-ticketing-configuration`,
      permission: 'CanEditDelayTicketing',
    },
    {
      configurationType: 'PaymentGatewaysConfiguration',
      url: `/settings/${companyId}/payment-gateways-configurations/${configurationId}/configuration`,
      permission: 'CanEditPaymentGateways',
    },
    {
      configurationType: 'PaymentMethodConfiguration',
      url: `/settings/${companyId}/payment-methods-configurations/${configurationId}/payment-methods-configuration`,
      permission: 'ReadPaymentMethodConfiguration',
    },
    {
      configurationType: 'LodgeCardConfiguration',
      url: `/settings/${companyId}/lodge-cards/${configurationId}/configuration`,
      permission: 'ReadCompanyPaymentCards',
    },
    {
      configurationType: 'CurrencyConfiguration',
      url: `/settings/${companyId}/currency-exchange-configurations/${configurationId}/currency-exchange-configuration`,
      permission: 'CanAssignCurrencyConfiguration',
    },
    {
      configurationType: 'VirtualCardConfiguration',
      url: `/settings/${companyId}/virtual-cards-configurations/${configurationId}/configuration`,
      permission: 'CanEditVirtualCards',
    },
    {
      configurationType: 'ExpensePoliciesConfiguration',
      url: `/settings/${companyId}/expense-policies/${configurationId}/configuration/false`,
      permission: 'ReadExpensePolicyDefinition',
    },
    {
      configurationType: 'ProjectsConfiguration',
      url: `/settings/${companyId}/projects/${configurationId}/configuration/false`,
      permission: 'ReadProjects',
    },
  ];
};

@Component({})
export default class CompanyStructureManageConfiguration extends Vue {
  configurationTypes: string[] = [];
  currentValues: any = {};
  loadingMap: any = {};
  isFirstSearch: boolean = false;
  filteredItemsMap: any = {
    AirProviderConfiguration: [],
    TravelPolicyConfiguration: [],
    ApprovalWorkflowConfiguration: [],
  };
  typesItemsMap: any = {
    AirProviderConfiguration: [],
    TravelPolicyConfiguration: [],
    ApprovalWorkflowConfiguration: [],
  };
  loading: boolean = false;
  id: string = '';
  configTypesMap = {
    ThemeConfiguration: translate('settings-sidebar.tmc-environment'),
    ProfileSyncConfiguration: translate('settings-sidebar.profile-sync'),
    PluginsConfiguration: translate('settings-sidebar.plugins'),
    SecurityConfiguration: translate('settings-sidebar.security'),
    CurrencyConfiguration: translate('settings-sidebar.currency-exchange'),
    AirProviderConfiguration: translate('settings-sidebar.flight-providers'),
    RailProviderConfiguration: translate('settings-sidebar.rail-providers'),
    AccommodationProviderConfiguration: translate('settings-sidebar.accommodation-providers'),
    CarProviderConfiguration: translate('settings-sidebar.car-providers'),
    NegoFareConfiguration: translate('settings-sidebar.nego-fare'),
    NotificationConfiguration: translate('settings-sidebar.providers-notifications'),
    FareMappingConfiguration: translate('settings-sidebar.fares-mapping'),
    TravelPolicyConfiguration: translate('settings-sidebar.travel-policy'),
    ApprovalWorkflowConfiguration: translate('settings-sidebar.approval-workflow'),
    DisplayPreferencesConfiguration: translate('settings-sidebar.display-preferences'),
    CustomFieldsConfiguration: translate('settings-sidebar.custom-fields'),
    BackOfficeConfiguration: translate('settings-sidebar.back-office-configuration'),
    BackOfficeCustomFieldsConfiguration: translate('settings-sidebar.back-office-custom-fields-configuration'),
    FeesConfiguration: translate('settings-sidebar.fees'),
    AgencyMarkupConfiguration: translate('settings-sidebar.agency-markup'),
    SystemMarkupConfiguration: translate('settings-sidebar.system-markup'),
    PnrRemarksConfiguration: translate('settings-sidebar.pnr-remarks-configuration'),
    PaymentMethodConfiguration: translate('settings-sidebar.payment-methods'),
    PaymentGatewaysConfiguration: translate('settings-sidebar.payment-gateways-configuration'),
    LodgeCardConfiguration: translate('settings-sidebar.lodge-cards'),
    VirtualCardConfiguration: translate('settings-sidebar.virtual-cards'),
    ProjectsConfiguration: translate('settings-sidebar.projects'),
    ExpensePoliciesConfiguration: translate('settings-sidebar.expense-policies'),
    ClientEmailNotification: translate('settings-sidebar.client-email-notifications'),
    MissedSavingsConfiguration: translate('settings-sidebar.missed-savings'),
    DelayTicketingConfiguration: translate('settings-sidebar.delay-ticketing'),
  };
  errors: any[] = [];
  companyInfo: any = null;
  existAgencyCompanyId: any = null;
  companyStructureModel: any = [
    {
      group: 'company',
      permission: 'CanAssignCompanyTheme',
      configurationType: 'ThemeConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'company',
      permission: 'CanAssignProfileSynchronization',
      configurationType: 'ProfileSyncConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'company',
      permission: 'CanAssignPlugins',
      configurationType: 'PluginsConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'company',
      configurationType: 'SecurityConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignFlightProviders',
      configurationType: 'AirProviderConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignRailProviders',
      configurationType: 'RailProviderConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignAccommodationProviders',
      configurationType: 'AccommodationProviderConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignCarProviders',
      configurationType: 'CarProviderConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignNegotiatedFares',
      configurationType: 'NegoFareConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignFareMapping',
      configurationType: 'FareMappingConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'providers',
      permission: 'CanAssignProviderNotifications',
      configurationType: 'NotificationConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignTravelPolicy',
      configurationType: 'TravelPolicyConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignApprovalWorkflow',
      configurationType: 'ApprovalWorkflowConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignDisplayPreferences',
      configurationType: 'DisplayPreferencesConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignCustomFields',
      configurationType: 'CustomFieldsConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignBackOfficeSynchronization',
      configurationType: 'BackOfficeConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignBackOfficeCustomFields',
      configurationType: 'BackOfficeCustomFieldsConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignPnrRemarks',
      configurationType: 'PnrRemarksConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignBookingFee',
      configurationType: 'FeesConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignAgencyMarkup',
      configurationType: 'AgencyMarkupConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignSystemMarkup',
      configurationType: 'SystemMarkupConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignClientEmailNotifications',
      configurationType: 'ClientEmailNotification',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignMissedSavings',
      configurationType: 'MissedSavingsConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'business-rules',
      permission: 'CanAssignDelayTicketing',
      configurationType: 'DelayTicketingConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'payments',
      permission: 'CanAssignPaymentMethod',
      configurationType: 'PaymentMethodConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'payments',
      permission: 'CanAssignPaymentGatewaysConfiguration',
      configurationType: 'PaymentGatewaysConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'payments',
      permission: 'CanAssignLodgeCards',
      configurationType: 'LodgeCardConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'payments',
      permission: 'CanAssignCurrencyConfiguration',
      configurationType: 'CurrencyConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'payments',
      permission: 'CanAssignVirtualCardsConfiguration',
      configurationType: 'VirtualCardConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'expense',
      permission: 'CanAssignExpensePolicy',
      configurationType: 'ExpensePoliciesConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    },
    {
      group: 'expense',
      permission: 'CanAssignProjects',
      configurationType: 'ProjectsConfiguration',
      configurationSource: this.configurationSourceTypes[0],
      configurationCustom: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgency: {
        agencyCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationParent: {
        parentCompanyId: null,
        id: null,
        name: translate('settings-company.no-configuration'),
      },
      configurationAgencyPool: {
        configurationId: null,
        name: translate('settings-company.no-configuration'),
      }
    }
  ];
  saving: boolean = false;

  get companyStructureModelItems() {
    return this.companyStructureModel.filter(item => {​​​​​​​​​
        return !!this.configurationTypes.find(model => model === item.configurationType) && AccountStore.HasPermission(item.permission);
      }​​​​​​​​​).filter(item => {
      return (this.isExpenseEnabled || (-1 === [
        'ExpensePoliciesConfiguration',
        'ProjectsConfiguration',
      ].indexOf(item.configurationType)));
    });
  }

  get companyStructureModelItemsHeaders() {
    return this.companyStructureModelItems.map((item, index, array) => {
      if (index === 0 || array[index - 1].group !== item.group) {
        return item.group;
      }

      return '';
    });
  }

  get isProfileInAgency() {
    if (!AccountStore.current) {
      return false;
    }
    return AccountStore.current.profile.isAgency;
  }

  get isExpenseEnabled() {
    return AccountStore.isExpenseModuleEnabled;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRoot() {
    if (this.companyInfo) {
      return this.companyInfo.parentId === null;
    }
    return false;
  }

  get configurationSourceTypes() {
    if (this.isRoot) {
      if (this.existAgencyCompanyId) {
        return [
          {
            name: translate('settings-company.custom-configuration'),
            value: 'Custom'
          },
          {
            name: translate('settings-company.inherit-from-agency'),
            value: 'Agency'
          },
          {
            name: translate('settings-company.agency-pool'),
            value: 'AgencyPool'
          }
        ];
      } else {
        return [
          {
            name: translate('settings-company.custom-configuration'),
            value: 'Custom'
          }
        ];
      }
    } else if (!this.isRoot) {
      if (this.existAgencyCompanyId) {
        return [
          {
            name: translate('settings-company.custom-configuration'),
            value: 'Custom'
          },
          {
            name: translate('settings-company.inherit-from-parent'),
            value: 'Parent'
          },
          {
            name: translate('settings-company.inherit-from-agency'),
            value: 'Agency'
          },
          {
            name: translate('settings-company.agency-pool'),
            value: 'AgencyPool'
          }          
        ];
      } else {
        return [
          {
            name: translate('settings-company.custom-configuration'),
            value: 'Custom'
          },
          {
            name: translate('settings-company.inherit-from-parent'),
            value: 'Parent'
          }
        ];
      }
    } else {
      return [
        {
          name: translate('settings-company.custom-configuration'),
          value: 'Custom'
        }
      ];
    }
  }

  get configurationId() {
    return this.$route.params.configurationId;
  }

  sourceTypes(sourceTypeOptions, chosenValue) {
    if (
      !this.isProfileInAgency &&
      (!chosenValue || chosenValue.value !== 'AgencyPool')
    ) {
      return sourceTypeOptions.filter(item => item.value !== 'AgencyPool');
    }

    return sourceTypeOptions;
  }

  returnToConfigurations() {
    router.push({
      name: 'company-structure-configurations'
    });
  }

  mapCompanyStructureModel(results) {
    this.companyStructureModel.forEach(model => {
      if (!!results[model.configurationType] && model.configurationType === results[model.configurationType].configurationType) {
        if (results[model.configurationType].configurationSource === 'Custom') {
          model.configurationCustom.id = results[model.configurationType].id;
          model.configurationSource = this.configurationSourceTypes.find(type => type.value === results[model.configurationType].configurationSource);
          model.configurationCustom.name = results[model.configurationType].name
            ? results[model.configurationType].name
            : translate('settings-company.no-configuration');
          model.configurationCompanyId = this.currentCompany && this.currentCompany.rootCompanyId
            ? this.currentCompany.rootCompanyId
            : results[model.configurationType].parentCompanyId;
        } else if (results[model.configurationType].configurationSource === 'AgencyPool') {
          model.configurationAgencyPool = results[model.configurationType].agencyConfigurations.find(type => type.configurationId === results[model.configurationType].id);
          model.configurationCompanyId = results[model.configurationType].agencyCompanyId;
        } else if (results[model.configurationType].configurationSource === 'Parent') {
          model.configurationCompanyId = results[model.configurationType].parentConfigurationRootCompanyId;
        } else {
          model.configurationCustom.id =  null;
          model.configurationSource = this.configurationSourceTypes.find(type => type.value === results[model.configurationType].configurationSource);
          model.configurationCustom.name = results[model.configurationType].name
            ? results[model.configurationType].name
            : translate('settings-company.no-configuration');
          model.configurationCompanyId = results[model.configurationType].agencyCompanyId;
        }
        model.configurationCustom.id = results[model.configurationType].configurationSource === 'Custom' ? results[model.configurationType].id : null;
        model.configurationSource = this.configurationSourceTypes.find(type => type.value === results[model.configurationType].configurationSource);
        model.configurationCustom.name = results[model.configurationType].configurationSource === 'Custom' && results[model.configurationType].name
          ? results[model.configurationType].name
          : translate('settings-company.no-configuration');
        model.configurationParent.parentCompanyId = results[model.configurationType].parentCompanyId;
        model.configurationParent.id = results[model.configurationType].parentConfigurationId;
        model.configurationParent.name = results[model.configurationType].parentConfigurationName ? results[model.configurationType].parentConfigurationName : translate('settings-company.no-configuration');
        model.configurationAgency.agencyCompanyId = results[model.configurationType].agencyCompanyId;
        model.configurationAgency.id = results[model.configurationType].agencyConfigurationId;
        model.configurationAgency.name = results[model.configurationType].agencyConfigurationName ? results[model.configurationType].agencyConfigurationName : translate('settings-company.no-configuration');
        model.agencyConfigurations = [NoAgencyConfigItem, ...results[model.configurationType].agencyConfigurations];
        model.warningMessage = results[model.configurationType].warningMessage;
        model.agencyCompanyId = results[model.configurationType].agencyCompanyId;
        model.parentConfigurationRootCompanyId = results[model.configurationType].parentConfigurationRootCompanyId;
        model.link = results[model.configurationType].id ? getLinkConfiguration(model.configurationCompanyId, results[model.configurationType].id).find(link => link.configurationType === model.configurationType) : null;
      }
    });
  }

  selectItem(index) {
    setTimeout(() => {
      this.$forceUpdate();
      ((this.$refs.saveConfigButton as Vue).$refs.button as HTMLElement).focus({preventScroll: true});
    });
  }

  async saveConfigurationAssignment() {
    if (!this.currentCompany) {
      return;
    }
    this.saving = true;
    this.errors = [];
    try {
      await CompanyConfigurationsApi.setCompanyConfigurations(this.configurationId, {
        configurationsToUpdate: this.configurationTypes
          .filter(item => {​​​​​​​​​
            return !!this.companyStructureModel.find(model => model.configurationType === item);
          }​​​​​​​​​)
          .map(item => {
            let elem = this.companyStructureModel.find(model => model.configurationType === item);
            if (elem.configurationSource.value === 'Parent') {
              return {
                configurationId: elem.configurationParent.id,
                configurationType: item,
                companyId: elem.configurationParent.parentCompanyId,
                configurationSource: elem.configurationSource.value
              };
            } else if (elem.configurationSource.value === 'Agency') {
              return {
                configurationId: elem.configurationAgency.id,
                configurationType: item,
                companyId: elem.configurationAgency.agencyCompanyId,
                configurationSource: elem.configurationSource.value
              };
            } else if (elem.configurationSource.value === 'AgencyPool') {
              return {
                configurationId: elem.configurationAgencyPool.configurationId,
                configurationType: item,
                companyId: elem.configurationAgency.agencyCompanyId,
                configurationSource: elem.configurationSource.value
              };
            } else {
              return {
                configurationId: elem.configurationCustom.id,
                configurationType: item,
                companyId: null,
                configurationSource: elem.configurationSource.value
              };
            }

          }),
      });

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-company.configurations-saved')
      });
      this.returnToConfigurations();      
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  hasPermission(value) {
    return this.$hasAccess(value);
  }

  resetFlag() {
    this.isFirstSearch = false;
  }

  searchPhrase(query, configurationType) {
    query = query.toLowerCase();
    if (this.typesItemsMap[configurationType] && this.typesItemsMap[configurationType].length) {
      this.loadingMap[configurationType] = true;
      const response = this.typesItemsMap[configurationType].filter((configuration: any) => configuration.name.toLowerCase().includes(query));
      this.filteredItemsMap[configurationType] = [];

      if (response) {
        response.forEach((element) => {
          this.filteredItemsMap[configurationType].push(element);
        });
      } else {
         this.filteredItemsMap[configurationType] = this.typesItemsMap[configurationType];
      }
      this.$forceUpdate();
      this.loadingMap[configurationType] = false;
    }
  }

  createLinkForConfiguration(item, configId, configurationCompanyId) {
    this.companyStructureModel.forEach(model => {
      if (model.configurationType === item.configurationType) {
        model.link = configId
          ? getLinkConfiguration(configurationCompanyId, configId).find(link => link.configurationType === item.configurationType)
          : null;
      }
    });
  }

  createDataForConfig(item, config, configData) {
    let id;
    let configurationCompanyId;
    if (config.id) {
      id = config.id;
    } else {
      id = config.configurationId;
    }

    if (configData.configurationCompanyId) {
      configurationCompanyId = configData.configurationCompanyId;
    } else {
      configurationCompanyId = configData.rootCompanyId;
    }

    this.createLinkForConfiguration(item, id, configurationCompanyId);
  }

  createConfigurationSource(item, source) {
    let config;
    if (source && source.value === 'Parent') {
      config = item.configurationParent;
      config.configurationCompanyId = item.parentConfigurationRootCompanyId;
    } else if (source && source.value === 'AgencyPool') {
      config = item.configurationAgencyPool;
      config.configurationCompanyId = item.agencyCompanyId;
    } else if (source && source.value === 'Agency') {
      config = item.configurationAgency;
      config.configurationCompanyId = item.agencyCompanyId;
    } else {
      config = item.configurationCustom;
      config.configurationCompanyId = this.currentCompany && this.currentCompany.rootCompanyId;
    }

    return config;
  }

  onConfigurationSourceChange(item, source) {
    let configData = this.createConfigurationSource(item, source);
    this.createDataForConfig(item, configData, configData);
  }

  onConfigurationChange(item, config) {
    let configData = this.createConfigurationSource(item, item.configurationSource);
    this.createDataForConfig(item, config, configData);
  }

  async created() {
    try {
      this.loading = true;
      let companyResult = await ProfileCompanyApi.getById(this.configurationId, Permission.AccessSettings);
      if (companyResult && companyResult.data) {
        this.companyInfo = companyResult.data;
        SettingsStore.setIsAgency(companyResult.data.isAgency);
      }
      const cfgType = await ProfileConfigurationsApi.getConfigurationTypes(Permission.ReadCompanyStructure, this.configurationId);
      this.configurationTypes = cfgType.data;
      if (this.currentCompany && cfgType.data && cfgType.data.length > 0) {
        const response = await CompanyConfigurationsApi.getCompanyListConfigurations({companyId: this.configurationId, permission: Permission.ReadCompanyStructure});
        if (response.data && response.data.length > 0) {
          let results = response.data;
          this.existAgencyCompanyId = results[0].agencyCompanyId;

          this.configurationTypes.forEach(item => {
            const result = results.find(config => config.configurationType === item);
            this.isFirstSearch = true;
            this.loadingMap[item] = true;
            this.typesItemsMap[item] = [];
            this.filteredItemsMap[item] = [];

            this.typesItemsMap[item] = [NoConfigItem, ...result!.customConfigurations];
            this.filteredItemsMap[item] = [NoConfigItem, ...result!.customConfigurations];
            this.loadingMap[item] = false;

            if (!result) {
              this.currentValues[item] = NoConfigItem;
              return;
            }
            this.currentValues[item] = {
              id: result.configurationId,
              configurationType: result.configurationType,
              name: result.name,
              configurationSource: result.configurationSource,
              parentCompanyId: result.parentCompanyId,
              parentConfigurationName: result.parentConfigurationName,
              parentConfigurationId: result.parentConfigurationId,
              parentConfigurationRootCompanyId: result.parentConfigurationRootCompanyId,
              agencyCompanyId: result.agencyCompanyId,
              agencyConfigurationId: result.agencyConfigurationId,
              agencyConfigurationName: result.agencyConfigurationName,
              agencyConfigurations: result.agencyConfigurations,
              warningMessage: result.warningMessage
            };
          });
          this.mapCompanyStructureModel(this.currentValues);
          this.$forceUpdate();
        }
      }
      
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.errors = this.$handleErrors(error, true);
    }
  }
}
