


























































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import moment from 'moment';
import {
  AvailableDateFormats,
  AvailableTimeFormats,
} from '@/api/profile/company.model';

@Component({})
export default class NotificationSabreHistoryCompare extends Vue {
  @Prop() notificationData!: any;
  @Prop() title!: string;



  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }

  get headerInfo() {
    return this.notificationData.newBooking.headerInfo;
  }

  get shouldShowHeaderInfo() {
    return this.generalInfo &&
      (this.newBookingFlights.length ||
      this.previousBookingFlights.length);
  }

  get generalInfo() {
    return this.notificationData.generalInfo;
  }

  get newBookingFlights() {
    if (!this.notificationData.newBooking) {
      return [];
    }
    return this.notificationData.newBooking.flights;
  }

  get newFlightsMapped() {
    return this.newBookingFlights.map((flight, index) => {
      return {
        label: translate('basket-trip-history.item-x', {
          x: index + 1,
        }),
        items: this.itemsOfFlight(flight),
      };
    });
  }

  get previousBookingFlights() {
    if (!this.notificationData.previousBooking) {
      return [];
    }
    return this.notificationData.previousBooking.flights;
  }

  get previousFlightsMapped() {
    return this.previousBookingFlights.map((flight, index) => {
      return {
        label: translate('basket-trip-history.item-x', {
          x: index + 1,
        }),
        items: this.itemsOfFlight(flight),
      };
    });
  }



  fieldsOfFlight(flight) {
    return {
      itemLabel: {
        label: flight.label,
      },
      itemValue: {
        label: '',
      },
    };
  }
  
  itemsOfFlight(flight) {
    return [
      {
        label: translate('basket-trip-history.action'),
        value: flight.action,
      },
      {
        label: translate('basket-trip-history.arrival-date-time'),
        value: this.formatDate(flight.arrivalDateTime),
      },
      {
        label: translate('basket-trip-history.departure-date-time'),
        value: this.formatDate(flight.departureDateTime),
      },
      {
        label: translate('basket-trip-history.flight-number'),
        value: flight.flightNumber,
      },
      {
        label: translate('basket-trip-history.status'),
        value: flight.status,
      },
      {
        label: translate('basket-trip-history.destination-airport'),
        value: flight.destinationLocation,
      },
      {
        label: translate('basket-trip-history.marketing-airline-code'),
        value: flight.marketingAirlineCode,
      },
      {
        label: translate('basket-trip-history.origin-location'),
        value: flight.originLocation,
      },
      {
        label: translate('basket-trip-history.supplier-ref-id'),
        value: flight.supplierRefId,
      },
    ];
  }

  formatDate(date) {
    return moment(date).utc().format(this.dateTimeFormat);
  }
}

