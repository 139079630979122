




















































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import { userFullName } from '@/core/user-full-name';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import BasketStore from '@/modules/basket/basket.store';

const eventName = 'update:modificationSelected';

@Component({})
export default class RailTrenitaliaTravellerModification extends Vue {
  @Prop() travellers!: any;
  @Prop() modificationSelected!: boolean;
  @Prop() loading!: boolean;
  @Prop() item!: any;

  errors: any[] = [];
  loadingApply: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      travellers: {
        $each: {
          updateData: {
            firstName: {
              required,
              maxLength: maxLength(100),
            },
            middleName: {
              isNameValid: (value) => (value ? isNameValid(value) : true),
              maxLength: maxLength(100),
            },
            lastName: {
              required,
              maxLength: maxLength(100),
            },
            emailAddress: {
              maxLength: maxLength(255),
              required,
              email,
            },
          },
        },
      },
    };
  }



  userFullName(user) {
    return userFullName(user);
  }

  goBack() {
    this[eventName](false);
  }

  @Emit()
  [eventName](val) {
    return val;
  }

  loadData() {
    if (this.travellers) {
      this.travellers.forEach(traveller => {
        traveller.updateData = {
          id: traveller.id,
          firstName: traveller.firstName,
          middleName: traveller.middleName,
          lastName: traveller.lastName,
          emailAddress: traveller.emailAddress,
        };
      });
    } else {
      this.travellers.forEach(traveller => {
        traveller.updateData = {
          id: '',
          firstName: '',
          middleName: '',
          lastName: '',
          emailAddress: '',
        };
      });
    }
  }

  @Emit('close')
  hideForm() {
  }

  async applyChanges() {
    this.$v.travellers.$touch();
    if (this.$v.travellers.$pending || this.$v.travellers.$error) {
      return;
    }

    this.errors = [];
    this.loadingApply = true;
    let request: any = [];
    this.travellers.forEach(traveller => {
      request.push(traveller.updateData);
    });
    try {
      await BasketItemApi.updateTravellerData(this.item.id, request);
      BasketStore.updateBasket();
      this.hideForm();
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loadingApply = false;
    }
  }

  mounted() {
    this.errors = [];
    this.loadData();
  }
}

