



















































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import {maxLength, requiredIf, required} from 'vuelidate/lib/validators';

import { router } from '@/router';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import AccountStore from '@/store/account.store';
import { SupplierName } from '@/services/supplier-name.service';
import FeesConfigurationStore from './fees-configuration.store';
import EventBus from '@/services/event-handler';
import { SupplierEnum } from '@/api/accommodation-engine/accommodation-fees-configuration.model';
import { CurrencyApi } from '@/api/currency/currency.api';

const feeNumberRegex = (value) => {
  return /^(\d{1,10})(\.\d{1,2})?$/g.test(value);
};

@Component({})
export default class FeeRuleHotel extends Vue {
  $v;
  Form: any = {};
  allAirline: any[] = [];
  allBookingTypes: any[] = [];
  allPaymentMethods: any[] = [];
  allBookingChannels: any[] = [];
  allSupplier: any[] = [];
  allTripTypes: any[] = [];
  allFareTypes: any[] = [];
  hasErrors: boolean = false;
  formLoaded: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  supplierOptions: any[] = [
    {
      value: SupplierEnum.Expedia,
      label: SupplierName(SupplierEnum.Expedia),
    },
    {
      value: SupplierEnum.Sabre,
      label: SupplierName(SupplierEnum.Sabre)
    },
    {
      value: SupplierEnum.Koedia,
      label: SupplierName(SupplierEnum.Koedia),
    },
    {
      value: SupplierEnum.Amadeus,
      label: SupplierName(SupplierEnum.Amadeus),
    },
    {
      value: SupplierEnum.Teldar,
      label: SupplierName(SupplierEnum.Teldar),
    },
    {
      value: SupplierEnum.HCorpo,
      label: SupplierName(SupplierEnum.HCorpo),
    },
    {
      value: SupplierEnum.WonderHotel,
      label: SupplierName(SupplierEnum.WonderHotel),
    },
  ];
  bookingTypeOptions: any[] = [
    {
      value:  'Offline',
      label:  translate('settings-fees.offline')
    },
    {
      value: 'Online',
      label:  translate('settings-fees.online')
    },
  ];
  paymentMethodsOptions: any[] = [
    {
      value: 'CentralPayment',
      label: translate('settings-payment-methods.central-payment'),
    },
    {
      value: 'PaymentCard',
      label: translate('settings-payment-methods.user-payment-card'),
    },
    {
      value: 'LodgeCard',
      label: translate('settings-payment-methods.lodge-card'),
    },
    {
      value: 'VirtualCard',
      label: translate('settings-payment-methods.virtual-card-payment'),
    }
];
  bookingChannelsOptions: any[] = [
    {
      value:  'UAT',
      label:  translate('settings-fees.uat')
    },
    {
      value: 'SBT',
      label:  translate('settings-fees.sbt')
    },
  ];
  tripTypeOptions: any[] = [
    {
      value:  'Domestic',
      label:  translate('settings-travel-policy.domestic')
    },
    {
      value: 'International',
      label:  translate('settings-travel-policy.international')
    },
    {
      value: 'Intercontinental',
      label: translate('settings-travel-policy.intercontinental')
    }
  ];
  fareTypeOptions: any[] = [
    {
      value: 'Public',
      label: translate('search-air.public'),
    },
    {
      value: 'Nego',
      label: translate('search-air.nego'),
    }
  ];
  calculationMethodOptions: any[] = [
    {
      value: 'PerBooking',
      label: translate('settings-fees.per-booking'),
    },
    {
      value: 'PerTraveller',
      label: translate('settings-fees.per-traveller'),
    },
  ];
  whenToApplyOptions: any[] = [
    {
      value: 'MakingAReservation',
      label: translate('settings-fees.making-a-reservation'),
    },
  ];
  currencyCodes: any[] = [];
  currencyErrors: any[] = [];

  @Validation()
  validationObject() {
    return {
      Form: {
        fixedFee: {
          amount: {
            valid: (value) => {
              let val = Number(value);
              return this.Form.feeType === 'Fixed' ? value !== '' && feeNumberRegex(val) && val >= 0 && val <= 10000 : true;
            }
          },
          currency: {
            code: {
              requiredIf: requiredIf(() => {
                return this.Form.feeType === 'Fixed';
              }),
            }
          }
        },
        percentageFee: {
          valid: (value) => {
            let val = Number(value);
            return this.Form.feeType === 'Percentage' ? value !== '' && feeNumberRegex(val) && val <= 100 && val >= 0 : true;
          }
        },
        calculationMethod: {
          required,
        },
        whenToApply: {
          required,
        },
        paymentMethods: {
          required,
        },
        fareBasisCodes: {
          everyValid: (value) => {
            return this.Form.fareBasisCodesEnabled ? value.every(item => {
              return !!item;
            }) : true;
          },
          $each: {
            maxLength: maxLength(10),
          }
        }
      }
    };
  }

  get currentConfigurationName() {
    return FeesConfigurationStore.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get configIsEdit() {
    return !!((this.$route.params.configurationId && this.$route.params.configurationId !== 'new') || this.configurationId);
  }

  get rule() {
    return FeesConfigurationStore.selectedHotelRule;
  }

  get isFromManage() {
    return FeesConfigurationStore.isFromManage;
  }

  get isRuleEdited() {
    return !!(this.rule !== undefined && this.rule !== null && Object.keys(this.rule).length > 0);
  }

  get isLodgeCardSelected() {
    if (this.Form.paymentMethods) {
      if (Array.isArray(this.Form.paymentMethods) && this.Form.paymentMethods.length > 0) {
        return this.Form.paymentMethods.some(pm => pm.value === 'LodgeCard');
      }
    }
    return false;
  }

  get isCreditCardSelected() {
    if (this.Form.paymentMethods) {
      if (Array.isArray(this.Form.paymentMethods) && this.Form.paymentMethods.length > 0) {
        return this.Form.paymentMethods.some(pm => pm.value === 'PaymentCard');
      }
    }
    return false;
  }

  get user() {
    return AccountStore.current;
  }

  get classes() {
    return {
      'it-width': this.user && this.user.profile.displayLanguage === 'it',
    };
  }

  bookingTypeEnabled(value) {
    if (value === false) {
      this.Form.bookingType = [];
    }
  }

  bookingChannelsEnabled(value) {
    if (value === false) {
      this.Form.bookingChannels = [];
    }
  }

  supplierEnabled(value) {
    if (value === false) {
      this.Form.supplier = [];
    }
  }

  tripTypesEnabled(value) {
    if (value === false) {
      this.Form.tripTypes = [];
    }
  }

  fareTypesEnabled(value) {
    if (value === false) {
      this.Form.fareTypes = [];
    }
  }

  removeCode(index) {
    if (this.Form.fareBasisCodesEnabled) {
      this.Form.fareBasisCodes.splice(index, 1);
    }
  }

  checkForm() {
    this.hasErrors = false;
    this.$v.Form.$touch();
    if (this.$v.Form.$error) {
      this.hasErrors = true;
    }
  }

  returnToConfiguration() {
    FeesConfigurationStore.setIsFromManage(true);
    router.push({
      name: 'fees-configuration',
      params: {
        configurationId: this.configurationId,
      }
    });
  }

  confirmAndAddNext() {
    this.checkForm();
    if (this.hasErrors) {
      return;
    }
    if (this.isRuleEdited) {
      FeesConfigurationStore.editHotelRule(this.createRequest());
    } else {
      FeesConfigurationStore.addHotelRule(this.createRequest());
    }
    let obj = {
      type: translate('settings-travel-policy.success'),
      title: '',
      message: translate('settings-travel-policy.rule-added')
    };
    EventBus.$emit('show-toast', obj);
    FeesConfigurationStore.setHotelSelectedRule({});
    this.mapFormData();
    this.$v.$reset();
  }

  confirmAndBack() {
    this.checkForm();
    if (this.hasErrors) {
      return;
    }
    if (this.isRuleEdited) {
      FeesConfigurationStore.editHotelRule({
        ...this.createRequest(),
        status: FeesConfigurationStore.draftNewStatus,
      });
    } else {
      FeesConfigurationStore.addHotelRule({
        ...this.createRequest(),
        status: FeesConfigurationStore.draftNewStatus
      });
    }
    FeesConfigurationStore.setIsFromManage(true);
    router.push({
      name: 'fees-configuration',
      params: {
        configurationId: this.configurationId
      }
    });
  }

  findTripTypes(query) {
    query = query.toLowerCase();
    const response = this.tripTypeOptions.filter((type: any) => type.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if ( indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allTripTypes = [];

    if (response) {
      response.forEach((element) => {
        this.allTripTypes.push(element);
      });
    } else {
      this.allTripTypes = this.tripTypeOptions;
    }
  }

  findBookingType(query) {
    query = query.toLowerCase();
    const response = this.bookingTypeOptions.filter(option => option.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allBookingTypes = [];

    if (response) {
      response.forEach((element) => {
        this.allBookingTypes.push(element);
      });
    } else {
      this.allBookingTypes = this.bookingTypeOptions;
    }
  }

  findPaymentMethods(query) {
    query = query.toLowerCase();
    const response = this.paymentMethodsOptions.filter(option => option.label.toLowerCase().includes(query));
    const indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      const item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allPaymentMethods = [];

    if (response) {
      response.forEach((element) => {
        this.allPaymentMethods.push(element);
      });
    } else {
      this.allPaymentMethods = this.paymentMethodsOptions;
    }
  }

  findBookingChannels(query) {
    query = query.toLowerCase();
    const response = this.bookingChannelsOptions.filter(option => option.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allBookingChannels = [];

    if (response) {
      response.forEach((element) => {
        this.allBookingChannels.push(element);
      });
    } else {
      this.allBookingChannels = this.bookingChannelsOptions;
    }
  }

  findSupplier(query) {
    query = query.toLowerCase();
    const response = this.supplierOptions.filter((supplier: any) => supplier.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if ( indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allSupplier = [];

    if (response) {
      response.forEach((element) => {
        this.allSupplier.push(element);
      });
    } else {
      this.allSupplier = this.supplierOptions;
    }
  }

  findFareTypes(query) {
    query = query.toLowerCase();
    const response = this.fareTypeOptions.filter((supplier: any) => supplier.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if ( indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allFareTypes = [];

    if (response) {
      response.forEach((element) => {
        this.allFareTypes.push(element);
      });
    } else {
      this.allFareTypes = this.fareTypeOptions;
    }
  }

  mapArray(object, key) {
    if (object[key + 'Enabled']) {
      return object[key].map(item => item.value ? item.value : item);
    }

    return [];
  }

  createRequest() {
    return {
      feeType: this.Form.feeType,
      fixedFee: this.Form.feeType === 'Fixed' ? this.Form.fixedFee : null,
      percentageFee: this.Form.feeType === 'Percentage' ? this.Form.percentageFee : null,
      calculationMethod: this.Form.calculationMethod ? this.Form.calculationMethod.value : null,
      isLodgeCardInformationSelected: this.Form.isLodgeCardInformationSelected,
      bookingType: this.mapArray(this.Form, 'bookingType'),
      paymentMethods: this.Form.paymentMethods.map(item => item.value ? item.value : item),
      bookingChannels: this.mapArray(this.Form, 'bookingChannels'),
      supplier: this.mapArray(this.Form, 'supplier'),
      fareTypes: this.mapArray(this.Form, 'fareTypes'),
      fareBasisCodes: this.Form.fareBasisCodesEnabled ? this.Form.fareBasisCodes : [],
      status: FeesConfigurationStore.draftNewStatus,
    };
  }

  mapDefaultData() {
    this.Form = {
      feeType: 'Fixed',
      fixedFeeEnabled: true,
      fixedFee: {
        amount: 0,
        currency: {
          code: this.currencyCodes[0],
          symbol: null,
        }
      },
      percentageFeeEnabled: false,
      percentageFee: 0,
      calculationMethod: this.calculationMethodOptions[0],
      whenToApply: this.whenToApplyOptions[0],
      isLodgeCardInformationSelected: true,
      bookingTypeEnabled: false,
      bookingType: [],
      paymentMethods: [],
      bookingChannelsEnabled: false,
      bookingChannels: [],
      supplierEnabled: false,
      supplier: [],
      fareTypesEnabled: false,
      fareTypes: [],
      fareBasisCodesEnabled: false,
      fareBasisCodes: [''],
    };
  }

  getCalculationMethod(rule) {
    return rule.calculationMethod ?
      this.calculationMethodOptions
        .find(item => {
          return rule.calculationMethod.value ? item.value === rule.calculationMethod.value : item.value === rule.calculationMethod;
        }) :
      this.calculationMethodOptions[0];
  }

  getBookingType(rule) {
    return !rule.bookingType ?
      [] :
      rule.bookingType
        .map(item => {
          return this.bookingTypeOptions.find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
        });
  }

  getPaymentMethods(rule) {
    return !rule.paymentMethods ?
      [] :
      rule.paymentMethods
        .map(item => {
          return this.paymentMethodsOptions.find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
        });
  }

  getBookingChannels(rule) {
    return !rule.bookingChannels ?
      [] :
      rule.bookingChannels
        .map(item => {
          return this.bookingChannelsOptions.find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
        });
  }

  getSupplier(rule) {
    return !rule.supplier ?
      [] :
      rule.supplier
        .map(item => {
          return this.supplierOptions.find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
        });
  }

  fareBasisCodesEnabled(value) {
    if (value === false) {
      this.Form.fareBasisCodes = [''];
    }
  }

  getFareTypes(rule) {
    return !rule.fareTypes ?
      [] :
      rule.fareTypes
        .map(item => {
          return this.fareTypeOptions
            .find(type => {
              return item.value ? item.value === type.value : item === type.value;
            });
        });
  }

  getFareBasisCodes(rule) {
    return rule.fareBasisCodes && rule.fareBasisCodes.length ? rule.fareBasisCodes : [''];
  }

  mapFromRule(rule) {
    const fixedFeeEnabled = rule.fixedFee && rule.fixedFee.amount > 0;
    const amount = fixedFeeEnabled && rule.fixedFee.amount || 0;
    const percentageFeeEnabled = rule.percentageFee && rule.percentageFee > 0;
    const percentageFee = rule.percentageFee || 0;
    const calculationMethod = this.getCalculationMethod(rule);
    const isLodgeCardInformationSelected = rule.isLodgeCardInformationSelected;
    const bookingTypeEnabled = rule.bookingType && rule.bookingType.length > 0;
    const bookingType = this.getBookingType(rule);
    const paymentMethods = this.getPaymentMethods(rule);
    const bookingChannelsEnabled = rule.bookingChannels && rule.bookingChannels.length > 0;
    const bookingChannels = this.getBookingChannels(rule);
    const supplierEnabled = rule.supplier && rule.supplier.length > 0;
    const supplier = this.getSupplier(rule);
    const fareTypesEnabled = rule.fareTypes && rule.fareTypes.length > 0;
    const fareTypes = this.getFareTypes(rule);
    const fareBasisCodesEnabled = rule.fareBasisCodes.length > 0;
    const fareBasisCodes = this.getFareBasisCodes(rule);

    this.Form = {
      feeType: rule.feeType,
      fixedFeeEnabled,
      fixedFee: {
        amount,
        currency: rule.fixedFee ? {
          code: rule.fixedFee.currency.code,
          symbol: null,
        } : {
          amount: 0,
          currency: {
            code: this.currencyCodes[0],
            symbol: null,
          }
        },
      },
      percentageFeeEnabled,
      percentageFee,
      calculationMethod,
      whenToApply: this.whenToApplyOptions[0],
      isLodgeCardInformationSelected,
      bookingTypeEnabled,
      bookingType,
      paymentMethods,
      bookingChannelsEnabled,
      bookingChannels,
      supplierEnabled,
      supplier,
      fareTypesEnabled,
      fareTypes,
      fareBasisCodesEnabled,
      fareBasisCodes,
    };
  }

  mapFormData(rule?) {
    if (rule !== undefined && rule !== null && Object.keys(rule).length > 0) {
      this.mapFromRule(rule);
      return;
    }
    this.mapDefaultData();
  }

  async getCurrencies() {
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyCodes = response.data.map(item => item.code);
    } catch (error) {
      this.currencyErrors = this.$handleErrors(error);
    }
  }

  async created() {
    await this.getCurrencies();
    if (this.rule === null) {
      router.push({
        name: 'fees-configuration',
        params: {
          configurationId: this.configurationId,
        }
      });
    }
    if (this.rule) {
      this.mapFormData(this.rule);
    } else {
      this.mapFormData();
    }
    this.formLoaded = true;
  }
}
