import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  WonderModel,
  WonderResponseModel,
  ConversationIdentity,
  Conversation,
} from './wonder-search.model';

class WonderSearchApiClass {
  public wonderFind(query: WonderModel): Promise<AxiosResponse<WonderResponseModel>> {
    return http.execute({
      path: settings.apiWonderSearch + '/api/wondersearch',
      method: RequestMethod.POST,
    }, {}, query);
  }

  public getConversationId(tripId: string | undefined): Promise<AxiosResponse<ConversationIdentity>> {
    return http.execute({
      path: settings.apiWonderSearch + '/api/tripassistant',
      method: RequestMethod.POST,
    }, {}, { tripId });
  }

  public getConversation(conversationId: string): Promise<AxiosResponse<Conversation>> {
    return http.execute({
      path: settings.apiWonderSearch + '/api/tripassistant/{conversationId}',
      method: RequestMethod.GET,
    }, { conversationId }, {});
  }

  public sendUserQuestion(conversationId: string, userQuestion: string): Promise<AxiosResponse<Conversation>> {
    return http.execute({
      path: settings.apiWonderSearch + '/api/tripassistant/send-user-question',
      method: RequestMethod.PUT,
    }, {}, {
      conversationId,
      userQuestion,
    });
  }
}

export const WonderSearchApi: WonderSearchApiClass = new WonderSearchApiClass();
