
export const TravellerTypeMappings = {
  'ADT': 'search.adult',
  'YTH': 'search.youth',
  'CHD': 'search.child',
  'INF': 'search.infant',
  'SRC': 'search.senior',
  'SEA': 'search.seaman',
  'MLT': 'search.military',
};
