






































































































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { TripApi } from '@/api/trip/trip.api';
import { TrainExchangeApi } from '@/api/train-engine/train-exchange.api';
import BasketStore from '@/modules/basket/basket.store';
import BasketApproval from '@/modules/basket/BasketApproval.vue';
import TrainSearchStore from './train-search.store';
import TrainResultsBasketRow from './TrainResultsBasketRow.vue';
import ExchangePaymentMethods from '@/modules/search/air/ExchangePaymentMethods.vue';
import priceConst from '@/const/price.const';

const updateTitle = 'update:title';

@Component({
  components: {
    TrainResultsBasketRow,
    ExchangePaymentMethods,
    BasketApproval,
  },
})
export default class TrainExchangePricingForm extends Vue {
  @Prop() item!: any;
  @Prop() travellers!: any[];
  @Prop() title!: string;

  loading: boolean = true;
  modifyLoading: boolean = false;
  sendingRequest: boolean = false;
  errors: any[] = [];
  currentOffer: any = null;
  checkPrice: any = null;
  isAllowed: boolean = false;
  messages: any[] = [];
  exchangePaymentInfo: any = null;
  exchangePaymentMethods: any = null;
  travelPolicyResult: any = null;
  approvalResult: any = null;
  displayApproverEmpty: boolean = false;

  get isPreviousPriceTooltip() {
    const previousTotalValue = this.checkPrice && this.checkPrice.previousTotalValue;
    const totalPreviousPenalties = this.checkPrice && this.checkPrice.totalPreviousPenalties;
    return this.item.supplier === 'Sncf' && previousTotalValue && totalPreviousPenalties && totalPreviousPenalties.amount;
  }

  get isPriceDifferenceTooltip() {
    const missingAmount = this.checkPrice && this.checkPrice.missingAmount;
    const totalPenalties = this.checkPrice && this.checkPrice.totalPenalties;
    return this.item.supplier === 'Sncf' && missingAmount && totalPenalties && totalPenalties.amount;
  }

  get modifyItem() {
    return TrainSearchStore.exchangedOffer;
  }

  get tpCompliantUnchanged() {
    if (!this.travelPolicyResult) {
      return false;
    }

    return !this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'Compliant';
  }

  get tpNonCompliantUnchanged() {
    if (!this.travelPolicyResult) {
      return false;
    }

    return !this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'NonCompliant';
  }

  get tpTurnedIntoNotCompliant() {
    if (!this.travelPolicyResult) {
      return true;
    }

    return this.travelPolicyResult.travelPolicyResultChange.isChanged &&
      this.travelPolicyResult.travelPolicyResultChange.currentTravelPolicyResult === 'NonCompliant';
  }

  get tpIsBlocked() {
    if (!this.travelPolicyResult) {
      return false;
    }
    return this.travelPolicyResult.isNotBlockedByTravelPolicyChanges === false;
  }

  get isPostSales() {
    return this.$route.name === 'trainModification';
  }

  get isPostSalesApprovalVisible() {
    return this.isPostSales &&
      this.approvalResult &&
      this.approvalResult.isApprovalEnabled &&
      this.approvalResult.isApprovalPossible;
  }

  get hasMissingSelectedApprover() {
    return BasketStore.hasMissingSelectedApprover;
  }

  get selectedItem() {
    return TrainSearchStore.selectedForExchange;
  }



  userFullName(user) {
    return userFullName(user);
  }

  get shouldShowPriceDifference() {
    return 'Sncf' === this.item.supplier || 'Ouigo' === this.item.supplier;
  }

  get priceDifference() {
    if (!this.shouldShowPriceDifference || !this.checkPrice) {
      return priceConst.priceModel;
    }

    const penalties = this.checkPrice.totalPenalties;
    const missingAmount = this.checkPrice.missingAmount;

    return {
      ...penalties,
      amount: penalties.amount + missingAmount.amount,
    };
  }

  @Emit()
  [updateTitle](value) {
    return value;
  }

  hideForm() {
    this.$emit('close');
  }

  gotoBasket() {
    this.$emit('close');
    this.$router.push({
      name: 'basket',
      params: {
        id: this.$route.params.basketId,
      },
    });
  }

  async submitForm() {
    if (this.isPostSales && this.hasMissingSelectedApprover) {
      this.displayApproverEmpty = true;
      return;
    }
    this.errors = [];
    this.sendingRequest = true;
    let request = {};

    if (this.item && this.item.supplier === 'Ntv') {
      request = {
        paymentInfo: {
          paymentMethod: this.exchangePaymentInfo.paymentMethod,
          cardId: this.exchangePaymentInfo.cardId,
          transactionId: this.exchangePaymentInfo.transactionId,
        }
      };
    }

    try {
      if (this.isPostSalesApprovalVisible) {
        await TripApi.setExchangeDetailsApproval(
          this.$route.params.basketId,
          this.item.id,
          {
            approvers: BasketStore.selectedApprovers.map(approver => ({
              approverId: approver.approver.id,
              approvalLevel: approver.level,
            })),
          }
        );
      }
      await TrainExchangeApi.confirm(this.$route.params.searchId, request);
      this.gotoBasket();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  async loadData() {
    this.errors = [];
    this.loading = true;
    this.modifyLoading = true;
    
    const selectedFare = this.selectedItem.proposals[0].fares.find(fare => fare.isSelected);
    try {
      const response = await TrainExchangeApi.getPricing(this.$route.params.searchId);
      this.checkPrice = response.data;
      this.isAllowed = this.checkPrice.isAllowed;

      const item = this.checkPrice.newItem;
      let journeys: any[] = [];
      let proposals: any[] = [];
      let price = 0;
      item.rail.recommendations.forEach(recommendation => {
        recommendation.journeys.forEach(journey => {
          journeys.push(journey);
        });
        recommendation.proposals.forEach(proposal => {
          proposals.push(proposal);
        });
      });

      proposals.forEach(proposal => {
        proposal.fares.forEach(fare => {
          fare.isSelected = true;
          price += (fare as any).price.amount;
        });
      });

      const tripItem = {
        ...item,
        rail: {
          id: item.rail.id,
          orderId: item.rail.orderId,
          offerProvider: item.rail.recommendations[0].offerProvider,
          journeys: journeys,
          proposals: proposals,
          supplier: item.supplier,
          price: {
            amount: price,
            currency: {
              code: 'EUR',
              symbol: '€'
            }
          },
          travelPolicy: item.travelPolicy,
        }
      };

      this.currentOffer = tripItem;

      if (this.checkPrice.isAllowed) {
        this[updateTitle](this.$t('rail-exchange.confirm-modification'));
      } else {
        this[updateTitle](this.$t('rail-exchange.modification-not-allowed'));

        this.messages = Object.keys(this.checkPrice.validationMessages)
          .map(item => {
            return {
              title: item,
              message: this.checkPrice.validationMessages[item],
            };
          })
          .filter(item => !!item.message);
      }

      if (-1 < [
        'Ntv',
      ].indexOf(this.item.supplier)) {
        const basketId = this.$route.params.basketId;
        const { data } = await TripApi.getPaymentMethods(basketId);
        this.exchangePaymentMethods = data.find(element => {
          return element.tripItemId === this.modifyItem.id;
        });
        this.modifyItem.paymentMethods = this.exchangePaymentMethods;
      }

      const responseTP = await TripApi.getExchangeDetailsSummary(
        this.$route.params.basketId,
        this.item.id,
      );
      this.travelPolicyResult = responseTP.data;

      BasketStore.resetSelectedApprovers();

      const responseAW = await TripApi.getExchangeDetailsApproval(
        this.$route.params.basketId,
        this.item.id,
      );
      this.approvalResult = responseAW.data;
      BasketStore.setSelectApproverData(responseAW.data);
      BasketStore.setApprovalWorkflowForBasket(responseAW.data.tripApprovalWorkflowResult);
      responseAW.data.tripApprovalWorkflowResult.workflowLevels.forEach(level => {
        if (level.currentApprover) {
          BasketStore.setSelectedApprover({
            approver: {
              businessUnitName: level.currentApprover.businessUnitName,
              companyName: level.currentApprover.rootCompanyName,
              firstName: level.currentApprover.approverFirstName,
              middleName: level.currentApprover.approverMiddleName,
              lastName: level.currentApprover.approverLastName,
              profileId: level.currentApprover.approverId,
              id: level.currentApprover.approverId,
            },
            level: level.approvalLevel,
          });
        }
      });
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;

      setTimeout(() => {
        this.modifyLoading = false;
      }, 100);
      
    }
  }

  onPaymentValidationErrors() {
    this.loading = false;
  }

  onPaymentValidationSuccess() {
    this.submitForm();
  }

  created() {
    this.loadData();
  }
}

