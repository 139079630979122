import MarkupsConfigurations from '@/modules/settings/agency-markup/MarkupsConfigurations.vue';

export default {
  path: '',
  name: 'markups-configurations',
  component: MarkupsConfigurations,
  displayName: `Markups' configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    parentName: 'markups',
    depthCategory: 'markups',
    depth: 1,
  }
};