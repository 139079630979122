import { BaseTableParams } from '@/core/base-table-params';

export class CommunityTableParams extends BaseTableParams {
  phrase: string = '';
  isGuest: boolean | null = null;
  unitId: string = '';
  roleId: string = '';

  public constructor(params: any) {
    super(params);

    if (params.phrase) {
      this.phrase = params.phrase;
    }

    if (params.isGuest) {
      this.isGuest = params.isGuest;
    }

    if (params.unitId) {
      this.unitId = params.unitId;
    }

    if (params.roleId) {
      this.roleId = params.roleId;
    }
  }
}
