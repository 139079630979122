import { SelectedApproverModel } from '@/api/profile/profile.model';

export interface PagingQuery {
  page: number;
  size: number;
}

export interface Price {
  amount: number;
  currency: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface Traveller {
  id: string;
  profileId?: string;
  firstName: string;
  lastName: string;
}

export interface SearchTripBookInfo {
  flight: string[];
  train: string[];
  hotel: string[];
}

export interface SearchTrip {
  id: string;
  tripCreateDate: string;
  isMainTravellerTrip: boolean;
  traveller: Traveller;
  travellerUnit: string;
  bookers: Traveller;
  arrangerUnit: string;
  tripDestination: string;
  tripCode: string;
  tripName: string;
  startDate: string;
  holdDeadline: string;
  approvalDeadline: string;
  endDate: string;
  tripStatus: number;
  bookings: SearchTripBookInfo;
  totalPrice: Price;
}

export interface SearchBooking {
  id: string;
  tripId: string;
  tripItemCreateDate: string;
  targetDestination: string;
  tripItemType: string;
  tripItemCode: string;
  deadline: string;
  tripItemStatus: string;
  providerStatus: string;
  approvalDeadline: string;
  holdDeadline: string;
  booker: Traveller;
  travellers: Traveller[];
  startDate: string;
  endDate: string;
  totalPrice: Price;
}

export interface SearchApprovals {
  id: string;
  tripCreateDate: string;
  isMainTravellerTrip: boolean;
  traveller: Traveller;
  travellerUnit: string;
  arranger: Traveller;
  arrangerUnit: string;
  tripDestination: string;
  wmCode: string;
  tripName: string;
  startDate: string;
  endDate: string;
  approvalDeadline: string;
  tripStatus: number;
  bookings: SearchTripBookInfo;
  totalPrice: Price;
}

export interface SearchTripsResult {
  results: SearchTrip[];
  page: SearchPageModel;
}

export interface SearchBookingsResult {
  results: SearchBooking[];
  page: SearchPageModel;
}

export interface SearchApprovalsResult {
  results: SearchApprovals[];
  page: SearchPageModel;
}

export interface SearchPageModel {
  pageSize: number;
  moreResultsAvailable: boolean;
  pageNo: number;
}

export interface Range<T> {
  from: T;
  to: T;
}

export enum TripCommentType {
  Internal = 'Internal',
  External = 'External',
}

export enum TripCommentFlow {
  Booking = 'Booking',
  Holding = 'Holding',
  AfterBooking = 'AfterBooking',
}

export interface TripComment {
  tripId: string;
  type: TripCommentType;
  commentFlow: TripCommentFlow;
  comment: string;
  sendEmail: boolean;
}

export interface BasketTripCommentModel {
  id: string;
  tripId: string;
  commentType: TripCommentType;
  comment: string;
  createDate: string;
  profile: {
    profileId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    companyName: string;
  };
}

export interface ApprovalWorkflowLevelModel {
  approverSourceType: string;
  allowedApprovers: any[];
  approverSelectionType: string;
  approvalLevel: number;
  currentApprover: any;
  approvalWorkflowResult: string;
}

export interface ApprovalWorkflowModel {
  approvalLevelsCount: number;
  currentApprover: SelectedApproverModel;
  workflowLevels: ApprovalWorkflowLevelModel[];
  currentApprovalLevel: number;
  finalApprover: SelectedApproverModel;
  willBeApprovedAutomatically: boolean;
  autoApprovalWhenDeadlineReached: AutoApprovalWhenDeadlineReachedType;
}

export interface DelayTicketingDeadlineModel {
  state: string;
  maxDeadline: string;
  deadline: string;
}

export interface SearchTripsMessage {
  period: SearchTripPeriod;
  destination?: string;
  startDate: any;
  endDate: any;
  tripCode?: string;
  basketStatuses: TripStatus[];
  serviceTypes: BasketItemType[];
  bookers: string[];
  travellers: string[];
  companies: string[];
  sortOnField: string;
  sortDirection: string;
}

export interface SearchBookingsMessage {
  period: SearchTripPeriod;
  destination: string;
  startDate: any;
  endDate: any;
  createDate: any;
  deadline: any;
  wmCode: string;
  bookingStatuses: TripStatus[];
  serviceTypes: BasketItemType[];
  bookers: string[];
  travellers: string[];
  companies: string[];
  companyCode: string;
  bookingReference: string;
  providerStatuses: string[];
}

export interface SearchApprovalsMessage {
  period: SearchTripPeriod;
  destination?: string;
  startDate: any;
  endDate: any;
  tripCode?: string;
  basketStatuses: TripStatus[];
  serviceTypes: BasketItemType[];
  bookers: string[];
  travellers: string[];
  companies: string[];
  approvers: string[];
  sortOnField: string;
  sortDirection: string;
}

export enum SearchTripsSortOnEnum {
  StartDate = 'StartDate',
  CreateDate = 'CreateDate',
}

export enum SearchTripsSortDirectionEnum {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum SearchTripPeriod {
  All = 'All',
  Past = 'Past',
  Current = 'Current',
  Upcoming = 'Upcoming',
}

export enum TripStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Held = 'Held',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Desynchronized = 'Desynchronized',
  PendingApproval = 'PendingApproval',
  AgencySupportRequired = 'AgencySupportRequired',
  UserActionRequired = 'UserActionRequired',
  DelayedTicketing = 'DelayedTicketing',
}

export enum TripItemStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Held = 'Held',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Desynchronized = 'Desynchronized',
  Preorder = 'Preorder',
  PendingApproval = 'PendingApproval',
  AgencySupportRequired = 'AgencySupportRequired',
  UserActionRequired = 'UserActionRequired'
}

export enum BasketItemType {
  Unknown = 'Unknown',
  Air = 'Air',
  Rail = 'Rail',
  Accommodation = 'Accommodation',
  Car = 'Car',
}

export interface OnHoldWidgetTrip {
  tripId: string;
  traveller: Traveller;
  tripTargetCity: string;
  holdDeadline: string;
  totalPrice: Price;
}

export interface SearchNotificationProviderReference {
  key: string;
  value: string;
}

export interface SearchNotification {
  id: string;
  timestamp: string;
  type: string;
  source: string;
  readOn: string;
  hasBeenRead: boolean;
  tripCode: string;
  providerReference: SearchNotificationProviderReference[];
  serviceType: string;
  mainTravellerRootCompanyName: string;
  mainTravellerBusinessUnitName: string;
}

export enum NotificationsStatus {
  All = 'All',
  Read = 'Read',
  Unread = 'Unread',
}

export interface BasketTravellerShortProfile {
  id: string;
  isMainTraveller: boolean;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  passengerTypeCode: string;
}

export enum AutoApprovalWhenDeadlineReachedType {
  Reject = 'Reject',
  AutoApprove = 'AutoApprove',
}
