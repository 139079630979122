















































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';

import {
  ProfileRoleDetailsModel,
  NewProfileRoleModel,
  ProfileRoleModel,
  ScopeValue
} from '@/api/roles/roles.model';
import rolesProfileStore from './roles.store';
import accountStore from '@/store/account.store';
import profileStore from '@/modules/profile/profile.store';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { RolesApi } from '@/api/roles/roles.api';
import { CompanyModel } from '@/api/profile/company.model';
import { Permission } from '@/const/permission.enum';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class AssignProfileRole extends Vue {
  @Prop() selectedRole!: ProfileRoleModel;
  $v;
  scopeValue = ScopeValue;
  companyList: CompanyModel[] = [];
  Form: ProfileRoleDetailsModel | null = {
    roleId: '',
    name: null,
    scope: this.isAgency ? 'None' : 'UserProfileOnly',
    includeAgencyCustomers: false,
    usersCompany: false,
    selectedBusinessUnits: []
  };
  delayTimer: any | undefined = null;
  isLoading: boolean = false;
  canUpdate: boolean = false;
  serverErrors: any[] = [];
  checkboxError: boolean = false;

  get companyRoles() {
    return rolesProfileStore.ProfileCompanyRoles;
  }

  get availableScopes() {
    return rolesProfileStore.AvailableScopes;
  }

  get user() {
    return accountStore.current;
  }

  get profile() {
    return profileStore.userProfile;
  }

  get profileRoleDetails(): ProfileRoleDetailsModel | null {
    return rolesProfileStore.profileRoleDetails;
  }

  get showErrorPopup() {
    return rolesProfileStore.showErrorPopup;
  }

  get showCompany() {
    if (this.Form) {
      return this.Form.scope === this.scopeValue.selectedUnitsAccess ||
        this.Form.scope === this.scopeValue.selectedUnitsAndBelowAccess;
    }
  }

  get IsLoading() {
    return rolesProfileStore.IsLoading;
  }

  get classes() {
    return {
      'role-box': this.isAgency,
      'selected': this.Form && this.Form.usersCompany && this.isAgency,
    };
  }

  get isAgency() {
    return this.profile && this.profile.company && this.profile.company.isAgency;
  }

  async loadCompanies(phrase: string) {
    if (this.profile && this.profile.rootCompanyId && phrase.length) {
      rolesProfileStore.startLoading();
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        try {
          const result = await ProfileCompanyApi.getCompanyUnits(this.profile!.rootCompanyId, phrase, Permission.ReadProfilePermissions);
          
          this.companyList = this.sortByName(result.data);

          rolesProfileStore.finishLoading();
        } catch (error) {
          rolesProfileStore.setShowErrorPopup(true);
          rolesProfileStore.setErrMessages(error);
        }
      }, 200);
    }
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        name: { required },
        selectedBusinessUnits: {
          required: requiredIf(() => {
            return this.showCompany;
          })
        }
      },
    };
  }

  updateForm() {
    if (this.profileRoleDetails) {

      this.Form = {
        roleId: this.selectedRole.roleId,
        name: {
          name: this.selectedRole.roleName,
          roleId: this.selectedRole.roleId,
          isAgencyRole: this.selectedRole.isAgencyRole,
          usageScope: this.selectedRole.roleUsageScope,             
        },
        scope: this.profileRoleDetails.scope,
        includeAgencyCustomers: this.profileRoleDetails.includeAgencyCustomers,
        usersCompany: this.profileRoleDetails.scope !== 'None',
        selectedBusinessUnits: this.profileRoleDetails.selectedBusinessUnits,
      };
    }
  }

  assignUnitsToSend(units) {
    if (!units && units.length === 0) {
      return [];
    }

    let ids: string[] = [];
    units.map(unit => {
      ids.push(unit.id);
    });

    return ids;
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
  }

  async submit() {
    this.checkboxError = false;
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    if (this.Form && this.Form.includeAgencyCustomers === false && this.Form.usersCompany === false && this.isAgency) {
      this.checkboxError = true;
      return;
    }

    if (this.Form != null && this.profile) {
      rolesProfileStore.startLoading();
      try {
        let profileId = this.$router.currentRoute.params.id;
        let request = {
          roleId: this.Form.name!.roleId,
          scope: this.Form.scope,
          includeAgencyCustomers: this.Form.includeAgencyCustomers,
          selectedBusinessUnits: this.assignUnitsToSend(this.Form.selectedBusinessUnits)
        };

        if (this.selectedRole && this.selectedRole.roleId) {
          let roleId = this.selectedRole.roleId;
          await RolesApi.updateRoleToProfile(
            profileId,
            roleId,
            request
          );
          rolesProfileStore.init();
        } else {
          let newRole: NewProfileRoleModel = {
            rolesWithScopes: [request],
            ProfileCompanyId: this.profile.companyId,
            ProfileRootCompanyId: this.profile.rootCompanyId,
          };

          let currentUser = accountStore.CurrentUser;
          if (!profileId && currentUser) {
            profileId = currentUser.profile.id;
          }

          if (currentUser) {
            await RolesApi.addNewRoleToProfile(
              profileId,
              newRole
            );

            rolesProfileStore.init();
          }
          rolesProfileStore.loadingSuccess();
          rolesProfileStore.finishLoading();
        }
        this.close();
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        rolesProfileStore.finishLoading();
      }
    }
  }

  selectedUnitRadio() {
    this.canUpdate = false;
  }

  checkAvailableScope(scope: string) {
    return !this.availableScopes.some(s => s === scope);
  }

  handleEnterPress() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  getRoleOptionLabel(option) {
    const baseName = option.name;
    const suffix = !this.isAgency && option.isAgencyRole
      ? ` (${this.$t('settings-roles.defined-by-agency')})`
      : '';
    return `${baseName}${suffix}`;
  }

  @Watch('Form.scope')
  onScopeChange() {
    if (this.Form && this.Form.scope &&
      this.Form.scope !== ScopeValue.selectedUnitsAccess &&
      this.Form.scope !== ScopeValue.selectedUnitsAndBelowAccess
    ) {
      this.Form.selectedBusinessUnits = [];
    }
  }

  @Watch('Form.usersCompany')
  onUsersCompany(value) {
    if (value && this.Form && this.Form.scope === 'None') {
      this.Form.scope = this.profileRoleDetails && this.profileRoleDetails.scope ? this.profileRoleDetails.scope : 'UserProfileOnly';
    } else if (this.Form && !this.Form.usersCompany) {
      this.Form.scope = 'None';
    }
  }

  @Emit()
  close() {
  }

  async created() {
    rolesProfileStore.loadProfileCompanyRoles();
    if (this.selectedRole && this.selectedRole.roleId) {
      this.canUpdate = true;
      await rolesProfileStore.getProfileRoleDetails(this.selectedRole.roleId);
      this.updateForm();
    }
  }
}
