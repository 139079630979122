





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment/moment';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';

const TEXT = {
  get Air() {
    return translate('basket.flight-to');
  },
  get Accommodation() {
    return translate('basket.hotel-in');
  },
  get Rail() {
    return translate('basket.train-to');
  },
  get Car() {
    return translate('basket.car-in');
  },
};

@Component({
  filters: {
    dateFilter(date) {
      return moment.parseZone(date).format('Do MMMM YYYY');
    },
  },
})
export default class UiProcessingTile extends Vue {
  @Prop() type!: string;
  @Prop() targetDestination!: string;
  @Prop() startDate!: string;
  @Prop() status!: string;
  @Prop({ default: false }) isLoading!: boolean;

  imagesConst: string = '/assets/img/loader/1.gif';

  get user() {
    return AccountStore.current;
  }

  get isPlLang() {
    return this.user!.profile.displayLanguage === 'pl';
  }

  get prefixText() {
    return TEXT[this.type];
  }

  get shouldShowTime() {
    return this.type !== 'Accommodation';
  }

  get itemStatusName() {
    const status = this.status;

    if (status === 'Hold') {
      return 'On-hold';
    } else if (status === 'PendingApproval') {
      return 'PendingApproval';
    }

    return status;
  }

  get itemStatusClass() {
    return 'basket-processing-view__status-' + this.status.toLowerCase();
  }

  get isAgencySupportOrUserActionRequired() {
    return ['AgencySupportRequired', 'UserActionRequired'].indexOf(this.itemStatusName) > -1;
  }
}
