













































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { TripApi } from '@/api/trip/trip.api';
import { router } from '@/router';
import accountStore from '@/store/account.store';
import LayoutStore from './layout.store';
import AppProfileCard from './AppProfileCard.vue';
import SwitchProfile from '@/modules/profile/switch-profile/SwitchProfile.vue';
import BasketTooltip from '@/modules/basket/BasketTooltip.vue';
import TravelDoc from './travel-doc/TravelDoc.vue';
import AppBrand from './AppBrand.vue';
import wonderSearchConst from '@/const/wondersearch.const';
import navConst from '@/const/nav-links.const';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import EventBus from '@/services/event-handler';
import hasAccessFn from '@/core/permissions/has-access.service';
import settings from '@/settings';
import AccountStore from '@/store/account.store';

@Component({
  components: {
    AppProfileCard,
    SwitchProfile,
    TravelDoc,
    BasketTooltip,
    AppBrand,
  }
})
export default class AppNavbar extends Vue {
  active: boolean = false;
  showProfilePopup: boolean = false;
  wonderSearchHint = null;
  beginSearchHint = null;
  showTravelDocResults: boolean = false;
  shouldShowMobileMenu: boolean = false;
  showBasketTooltip: boolean = false;
  travelDocResults = null;
  unreadNotifications: number = 0;

  get links() {
    return navConst.links.filter(route => {
      return route.meta && route.meta.permission ? accountStore.HasPermission(route.meta.permission) : true;
    });
  }

  get user() {
    return accountStore.current;
  }

  get menusActive() {
    return this.shouldShowMobileMenu || this.active;
  }

  get currentBasket() {
    return BasketStore.basket;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get latestBasket() {
    return BasketStore.latestBasketData;
  }

  get latestBasketItems() {
    return this.latestBasket!.items;
  }

  get canShowLatestBasket() {
    if (!this.latestBasket || (this.latestBasket && this.latestBasket.id === '00000000-0000-0000-0000-000000000000')) {
      return false;
    }
    return true;
  }

  get agencySearchInProgress() {
    const currentUser = accountStore.CurrentUser;

    return -1 < ['search', 'home'].indexOf(this.$route.name || '') &&
      currentUser && currentUser.profile && currentUser.profile.isAgency;
  }

  get isAgency() {
    return AccountStore.current && AccountStore.current!.profile && AccountStore.current!.profile.isAgency;
  }

  get helpLink() {
    return sanitizeUrl(`${this.isAgency ? settings.helpLinkAgency : settings.helpLink}${accountStore.currentLanguage}`);
  }
  


  logoClick($event) {
    this.resetStore($event);
    this.hideBothMenus();
  }

  resetStore($event) {
    if ($event.ctrlKey || $event.shiftKey || $event.button !== 0) {
      return;
    }
    SearchStore.updateSkipTravellers(false);
    accountStore.setChooseTravellerMode(false);
    if (hasAccessFn('SelectTravellersForSearch')) {
      SearchStore.resetTravellerSearchState();
    }
    SearchStore.resetSearchState();
    SearchStore.selectBasket('');
  }

  triggerWonderSearchAction(actionDetails) {
    if (this.wonderSearchHint && (!actionDetails || !actionDetails.name)) {
      this.beginSearchHint = Object.assign({}, this.wonderSearchHint);
    }

    if (actionDetails) {
      if (actionDetails.name === wonderSearchConst.ACTION_FLIGHT) {
        let wonderSearchParam = JSON.parse(actionDetails.value);
        router.push({
          name: 'air',
          params: { searchId: wonderSearchParam.searchId }
        });

        if (wonderSearchParam.basketId) {
          setTimeout(() => {
            router.push({
              name: 'basket',
              params: { id: wonderSearchParam.basketId },
              query: { searchId: wonderSearchParam.searchId }
            });
          }, 100); // because of router guards -> maybe should be done better via store
        }
      }
      else if (actionDetails.name === wonderSearchConst.ACTION_PROFILE) {
        router.push({
          name: 'personal',
          params: { id: actionDetails.value }
        });
      }
      else if (actionDetails.name === wonderSearchConst.ACTION_TRIPS) {
        router.push({
          name: 'trips',
          query: JSON.parse(actionDetails.value)
        });
      }
      else if (actionDetails.name === wonderSearchConst.ACTION_TRAVELDOC) {
        this.travelDocResults = JSON.parse(actionDetails.value);
        this.showTravelDocResults = true;
      }
      this.wonderSearchHint = null;
    }
  }

  canShow(item) {
    if (!item.canShow) {
      return true;
    }
    if ('function' === typeof item.canShow) {
      return item.canShow();
    }
    return item.canShow;
  }

  allowUserToSearch(searchHint) {
    this.wonderSearchHint = searchHint;
  }

  toggle() {
    if (this.showBasketTooltip) {
      this.showBasketTooltip = false;
    }
    this.active = !this.active;
    this.shouldShowMobileMenu = false;
  }

  onTooltipClick() {
    if (this.active) {
      this.active = false;
    }
    this.showBasketTooltip = !this.showBasketTooltip;
  }

  goToYourLatestBasket() {
    router.push({
      name: 'yourLatestBasket',
    });
  }

  onTooltipCloseEvent(e) {
    this.showBasketTooltip = false;
  }

  showMobileMenu() {
    this.shouldShowMobileMenu = !this.shouldShowMobileMenu;
    this.active = false;
  }

  hideMobileMenu() {
    this.shouldShowMobileMenu = false;
  }

  hideBothMenus() {
    this.active = false;
    this.shouldShowMobileMenu = false;
  }

  close(e) {
    if (!this.$el.contains(e.target)) {
      this.active = false;
      this.showBasketTooltip = false;
    }
  }

  async getNotifications() {
    try {
      const result = await TripApi.getNotifications({
        page: 1,
        size: 10
      }, {});
      if (result && result.data) {
        this.unreadNotifications = result.data.unReadNotificationCount;
      }
    } catch (error) {

    }
  }

  async created() {
    window.addEventListener('click', this.close);
    EventBus.$on('close-basket-tooltip', this.onTooltipCloseEvent);
    EventBus.$on('refresh-notifications-count', this.getNotifications);
    await BasketStore.getLatestBasketData();
    if (accountStore.HasPermission('ReadEventNotification')) {
      await this.getNotifications();
    }
  }

  beforeDestroy() {
    window.removeEventListener('click', this.close);
    EventBus.$off('close-basket-tooltip', this.onTooltipCloseEvent);
    EventBus.$off('refresh-notifications-count', this.getNotifications);
  }

  @Watch('menusActive')
  onMenuActivate(value) {
    LayoutStore.menusActive(!!value);
  }
}
