import hasAccessPlugin from './hasAccess.plugin';
import handleErrorsPlugin from './handle-errors.plugin';
import animatedInPlugin from './animatedIn.plugin';
import Vue2TouchEvents from 'vue2-touch-events';
import VTooltip from 'v-tooltip';
import canSeePricesPlugin from './canSeePrices.plugin';

export default [
  hasAccessPlugin,
  handleErrorsPlugin,
  animatedInPlugin,
  Vue2TouchEvents,
  VTooltip,
  canSeePricesPlugin
];
