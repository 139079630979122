
















































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import { router } from '@/router';

import { TravelAssignmentModel } from '@/api/custom-fields/custom-fields.model';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CustomFieldTravelAssignmentsApi } from '@/api/custom-fields/custom-fields-travel-assignments.api';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';


@Component({})
export default class TravelAssignments extends BaseTable<TravelAssignmentModel> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sort: 'order',
    desc: false,
    size: 10,
    start: 0,
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  fields = {
    order: {
      sortable: false,
      label: translate('settings-custom-fields.order'),
    },
    name: {
      sortable: false,
      label: translate('settings-custom-fields.cf-definition-name'),
    },
    defaultValue: {
      sortable: false,
      label: translate('settings-custom-fields.default-value'),
    },
    assignmentLevel: {
      sortable: false,
      label: translate('settings-custom-fields.assignment-level'),
    },
    active: {
      sortable: false,
      label: translate('settings-custom-fields.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errors: any[] = [];
  errorsOnPopup: any[] = [];
  selected: TravelAssignmentModel | null = null;
  selectedIndex: number = -1;
  
  get entries() {
    return CustomFieldsStore.travelAssignments;
  }

  get loadingPage() {
    return CustomFieldsStore.loading;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.entries.length;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get perPage() {
    return this.params.size;
  }

  set perPage(value) {
    this.params.size = value;
  }

  compareByOrderAndFieldName(f1, f2) {
    if (f1.order > f2.order) {
        return 1;
      }
    if (f1.order < f2.order) {
      return -1;
    }
    if (f1.customField.name.toLowerCase() > f2.customField.name.toLowerCase()) {
        return 1;
      }
    if (f1.customField.name.toLowerCase() < f2.customField.name.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  toggleMenu(row) {
    if (!this.menuActive || (this.selected !== row.item)) {
      this.selected = row.item;
      this.selectedIndex = row.index;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    let params: any = {
      id: this.currentCompany!.rootCompanyId,
      assignmentId: item.id,
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'travel-assignment',
      params,
    });
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeTravelAssignment() {
    try {
      CustomFieldsStore.setLoading(true);
      await CustomFieldTravelAssignmentsApi.deleteTravelAssignment(this.selected!.id);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
    } finally {
      if (!this.errorsOnPopup.length) {
        if (this.configurationId) {
          CustomFieldsStore.getTravelAssignments(this.configurationId);
        }
        this.removePopup = false;
      }
      CustomFieldsStore.setLoading(false);
    }
  }

  resetData() {
    CustomFieldsStore.setTravelAssignments([]);
  }

  async submitForm() {
    this.resetData();
    this.reload();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }
  
  @Watch('currentCompany')
  companyChanged(value) {
    this.updateList();
  }

  async updateList() {
    await CustomFieldsStore.getTravelAssignments(this.configurationId);
  }
}
