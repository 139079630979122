













import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

@Component({})
export default class UiPerson extends Vue {
  @Prop({ default: false }) isChild!: boolean;

  get dimension() {
    if (this.isChild) {
      return 21;
    }

    return 28;
  }

  get classes() {
    return {
      'ui-person--adult': !this.isChild,
      'ui-person--child': this.isChild,
    };
  }
}
