import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { GetProfileSpecificCustomFieldsResult } from './custom-fields-profile.model';

export class CustomFieldsProfileApiClass {
  public getProfileCustomFields(profileId: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/custom-fields',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public getProfileApproverCustomFields(profileId: string): Promise<AxiosResponse<GetProfileSpecificCustomFieldsResult>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/approver-custom-fields',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public getProfileArrangerCustomFields(profileId: string): Promise<AxiosResponse<GetProfileSpecificCustomFieldsResult>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/arranger-custom-fields',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public updateProfileCustomField(
    profileId: string,
    request: any
  ): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/custom-fields',
      method: RequestMethod.PUT
    }, { profileId }, request);
  }

  public getCompanyCustomFields(companyId: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/custom-fields',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }

  public updateProfileApproverCustomField(
    profileId: string,
    request: any
  ): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/approver-custom-fields',
      method: RequestMethod.PUT
    }, { profileId }, request);
  }

  public updateProfileArrangerCustomField(
    profileId: string,
    request: any
  ): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/arranger-custom-fields',
      method: RequestMethod.PUT
    }, { profileId }, request);
  }
}

export const CustomFieldsProfileApi: CustomFieldsProfileApiClass
  = new CustomFieldsProfileApiClass();