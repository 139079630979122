






























































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { DocumentTypeList } from '@/const/document-type-list.const';
import {
  ProfileDocument,
  DocumentType,
  Gender,
} from '@/api/profile/documents.model';
import DictionaryStore from '@/store/dictionary.store';
import NationalIdDocumentData from './NationalIdDocumentData.vue';
import VisaDocumentData from './VisaDocumentData.vue';
import KnownTravellerDocumentData from './KnownTravellerDocumentData.vue';
import GreenCardData from './GreenCardData.vue';
import { translate } from '@/i18n';
import DrivingLicense from './DrivingLicense.vue';
import EventHandler from '@/services/event-handler';

@Component({
  components: {
    NationalIdDocumentData,
    VisaDocumentData,
    KnownTravellerDocumentData,
    DrivingLicense,
    GreenCardData,
  }
})
export default class DocumentEditPopup extends Vue {
  @Prop({ default: false }) documentPopupVisible!: boolean;
  @Prop({ default: null }) document: any;
  @Prop({ default: null }) profileId: any;
  @Prop({}) isGuestMode!: boolean;
  @Prop({ default: false }) withoutSaving!: boolean;
  @Prop({ default: () => ([]) }) allowedDocumentTypes!: any[];

  Form: ProfileDocument | null = null;
  serverErrors: any[] = [];
  errMessage: string = '';
  wasSubmitted: boolean = false;
  types = DocumentTypeList.map(item => {
    return {
      label: translate('profile-documents-by-type.' + item.value),
      name: item.name,
      value: item.value
    };
  });
  genders = [ 
    {
      label: translate('profile-document.male'),
      value: Gender.Male
    },
    {
      label: translate('profile-document.female'),
      value: Gender.Female
    }, 
    {
      label: translate('profile-document.unspecified'),
      value: Gender.Unspecified
    }];



  get allowedTypes() {
    if (this.allowedDocumentTypes.length) {
      return this.types.filter(type => this.allowedDocumentTypes.includes(type.value));
    }

    return this.types;
  }

  get popupTitle() {
    if (this.isEditing) {
      return translate('profile-document.edit-document');
    }
    return translate('profile-document.enter-document');
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isEditing() {
    return !!this.document;
  }

  get isGreenCard() {
    return this.Form && this.Form.type && this.Form.type.value === DocumentType.GreenCard;
  }

  isKnownTraveller(type) {
    return type === DocumentType.KnownTraveller || type === DocumentType.Redress;
  }

  isNationalId(type) {
    return type === DocumentType.NationalId || type === DocumentType.Passport;
  }

  isVisa(type) {
    return type === DocumentType.Visa || type === DocumentType.Eta || type === DocumentType.Esta || type === DocumentType.EVisitor;
  }

  isDrivingLicense(type) {
    return type === DocumentType.DrivingLicense;
  }

  async created() {
    this.Form = new ProfileDocument();
    if (this.isEditing) {
      this.Form.type = this.types.find(type => { return type.value === this.document.type; });
    }
  }

  notifyParent(reloadDocuments, doc) {
    this.$emit('document-changed', reloadDocuments, doc);
    EventHandler.$emit('profile-data-saved');
  }

  onSelectType() {
    setTimeout(() => {
      EventHandler.$emit('document-type-changed');
    }, 50);
  }
}
