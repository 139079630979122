import SystemMarkupRuleRail from '@/modules/settings/system-markup/SystemMarkupRuleRail.vue';

export default {
  path: ':configurationId?/rail-system-markup-rule',
  name: 'system-markup-rule-rail',
  component: SystemMarkupRuleRail,
  displayName: 'Rail system markup rule definition',
  meta: {
    breadCrumb: 'Rail system markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    parentName: 'system-markup',
    depthCategory: 'system-markup',
    depth: 3,
  }
};