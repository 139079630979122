import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  RefundConfirmationMessage,
  RefundDecisionMessage
} from '@/modules/search/air/air-refund.model';

class RefundBasketItemApiClass {
  public getItemCouponsStatus(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/coupons-status',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public getCancellationInfo(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/cancellation-info',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public sendCouponDecision(message: RefundDecisionMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + `/items/${message.itemId}/coupons-status/answer`,
      method: RequestMethod.PUT,
    }, message.itemId , { isConfirmed: message.isConfirmed });
  }

  public answerCouponDecisionError(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/coupons-status-error/answer',
      method: RequestMethod.PUT,
    }, { itemId }, {});
  }

  public startRefund(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/refund',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public startRefundViaBspLink(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/refund-via-bsp-link',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public startVoid(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/void',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public startSabreVoid(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/air-sabre-items/{itemId}/tickets-void',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public startAmadeusVoid(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/air-amadeus-items/{itemId}/tickets-void',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public getRefundData(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/refund/prompt',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public getRefundConditions(offerId: string, supplier: string) {
    return http.execute({
      path: settings.apiAirEngine + '/{supplier}/offers/{offerId}/ticket-cancelation-conditions',
      method: RequestMethod.GET,
    }, { offerId, supplier }, {});
  }

  public acceptRefundCancellationConditions(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/accept-refund-cancellation-conditions',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public confirmRefundData(params: RefundConfirmationMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + `/items/${params.itemId}/refund/confirm`,
      method: RequestMethod.POST,
    }, params.itemId , { cancellationFee: params.cancellationFee }, true);
  }

  public rejectRefund(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/refund',
      method: RequestMethod.DELETE,
    }, { itemId }, {});
  }

  public addItemErrors(itemId: string, params: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/errors',
      method: RequestMethod.POST,
    }, { itemId }, params);
  }

  public completeRefund(itemId: string, params: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/items/{itemId}/refund/complete',
      method: RequestMethod.POST,
    }, { itemId }, params);
  }

  public getOfferCancelationConditions(itemId: string) {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{itemId}/cancelation-conditions',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }
}

export const RefundBasketItemApi: RefundBasketItemApiClass = new RefundBasketItemApiClass();