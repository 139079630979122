import { CorporateCodeOwnerEnum, CorporateCodeTypeEnum } from '@/api/air-engine/nego-fares-configuration.model';

export class NegoFareConfiguration {
  rootCompanyId: string = '';
  name: string = '';
  negoFaresConfigurations: FareConfigurationModel[] = [];

  constructor(config?: any) {
    if (config) {
      if (config.rootCompanyId !== undefined) {
        this.rootCompanyId = config.rootCompanyId;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
      if (config.negoFaresConfigurations !== undefined) {
        this.negoFaresConfigurations = config.negoFaresConfigurations;
      }
    }
  }
}

export enum NegoFareConfigurationSupplierEnum {
  Unknown = 'Unknown',
  Fake = 'Fake',
  Sabre = 'Sabre',
  TravelFusion = 'TravelFusion',
  AirFranceKlm = 'AirFranceKlm',
  Lufthansa = 'Lufthansa',
  Amadeus = 'Amadeus',
  AmericanAirlines = 'AmericanAirlines',
  Emirates = 'Emirates',
  EasyJet = 'EasyJet',
  BritishAirways = 'BritishAirways',
  NDCx = 'NDCx',
}

export enum AirNegoFareConfigurationSupplierEnum {
  Unknown = 'Unknown',
  Sabre = 'Sabre',
  Lufthansa = 'Lufthansa',
  AirFranceKlm = 'AirFranceKlm',
  Amadeus = 'Amadeus',
  AmericanAirlines = 'AmericanAirlines',
  BritishAirways = 'BritishAirways',
  Emirates = 'Emirates',
}

export enum TrainNegoFareConfigurationSupplierEnum {
  Unknown = 'Unknown',
  Trenitalia = 'Trenitalia',
  Sncf = 'Sncf',
  Ntv = 'Ntv',
  Ouigo = 'Ouigo',
  Benerail = 'Benerail',
}

export enum AccommodationFareConfigurationSupplierEnum {
  Expedia = 'Expedia',
  BookingCom = 'BookingCom',
  Sabre = 'Sabre',
  Amadeus = 'Amadeus',
  Koedia = 'Koedia',
}

export interface CorporateCode {
  codeOwner: CorporateCodeOwnerEnum;
  code: string | null;
  codeType: CorporateCodeTypeEnum | null;
  description: string | null;
  supplier?: AirNegoFareConfigurationSupplierEnum;
  status?: string;
  tempId?: string;
}

export interface FareConfigurationModel {
  succeeded: boolean;
  error: any;
  supplier: NegoFareConfigurationSupplierEnum;
  corporateCodes: CorporateCode[];
}

export interface UpdateFareConfigurationSingle {
  supplier: NegoFareConfigurationSupplierEnum;
  corporateCodes: CorporateCode[] | null;
}

export interface UpdateNegoFareConfigurationParams {
  name: string;
  negoFaresConfigurations: UpdateFareConfigurationSingle[] | null;
}

export interface UpdateNegoFareConfigurationMessage {
  configurationId: string;
  params: UpdateNegoFareConfigurationParams;
}

export interface UpdateNegoFareConfigurationResponse {
  supplier: NegoFareConfigurationSupplierEnum;
  succeeded: boolean;
  error: any;
}