export const TravellerTypeOptions: {
  value: string;
  name: string;
  code: string;
  codes?: string[];
}[] = [
  {
    value: 'Adult',
    name: 'profile-personal.adult',
    code: 'ADT',
  },
  {
    value: 'InfantWithoutSeat',
    name: 'profile-personal.infant-without-seat',
    code: 'INF',
  },
  {
    value: 'Child',
    name: 'profile-personal.child',
    code: 'CHD',
    codes: ['CHD', 'CNN'],
  },
  {
    value: 'Youth',
    name: 'profile-personal.youth',
    code: 'YTH',
  },
  {
    value: 'Senior',
    name: 'profile-personal.senior',
    code: 'SNR',
    codes: ['SNR', 'SRC'],
  },
  {
    value: 'Seaman',
    name: 'profile-personal.seaman',
    code: 'SMN',
  },
  {
    value: 'Military',
    name: 'profile-personal.military',
    code: 'MIL',
  },
];

export const TravellerGroupOptions = [
  {
    value: 'A',
    color: '#66CC00',
  },
  {
    value: 'B',
    color: '#0080FF',
  },
  {
    value: 'C',
    color: '#FFCC00',
  },
  {
    value: 'D',
    color: '#7F00FF',
  },
  {
    value: 'E',
    color: '#990000',
  },
];