import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

export class CarRule {
  $index?: number;
  pickUpDropOffLocations: HotelLocationModel[];
  rentalCompanies: { code: string, name: string }[];

  constructor(payload?: Partial<CarRule>) {
    this.$index = payload && payload.$index;
    this.pickUpDropOffLocations = (payload && payload.pickUpDropOffLocations) || [];
    this.rentalCompanies = (payload && payload.rentalCompanies) || [];
  }
}

export class DisplayPreferencesCar {
  rootCompanyId?: string;
  name?: string;
  withCompliantTravelPolicy: boolean;
  displayPreferences: CarRule[];

  constructor(payload?: Partial<DisplayPreferencesCar>) {
    this.rootCompanyId = payload && payload.rootCompanyId;
    this.name = payload && payload.name;
    this.withCompliantTravelPolicy = (payload && payload.withCompliantTravelPolicy) || false;
    this.displayPreferences = ((payload && payload.displayPreferences) || []).map((item) => new CarRule(item));
  }
}


