
























































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import PaymentGatewayStore from './payment-gateway.store';

@Component({})
export default class PaymentGatewayConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selectedGateWay = 'Viva Wallet';
  Form: any = {
    name: '',
    vivaWalletSecretKey: '',
  };

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        vivaWalletSecretKey: {
          required,
          maxLength: maxLength(64),
        }
      }
    };
  }


  get currentConfiguration() {
    return PaymentGatewayStore.currentConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditPaymentGateways');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return PaymentGatewayStore.errMessages;
  }

  get showErrMessages() {
    return PaymentGatewayStore.showError;
  }

  get loading() {
    return PaymentGatewayStore.loading;
  }


  goToConfigurationList() {
    this.resetStoreErrors();
    this.$router.push({
      name: 'payment-gateways-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    PaymentGatewayStore.clearErrors();
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        vivaWalletSecretKey: this.Form.vivaWalletSecretKey,
      };
      await PaymentGatewayStore.createConfiguration(request);
      if (!this.errMessages.length) {
        const obj = {
          type: translate('common.success'),
          title: translate('settings-payment-gateway.config-saved'),
          message: translate('settings-payment-gateway.config-saved-message')
        };
        EventHandler.$emit('show-toast', obj);
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        configurationId: this.$route.params.configurationId,
        name: this.Form.name,
        vivaWalletSecretKey: this.Form.vivaWalletSecretKey,
      };
      await PaymentGatewayStore.updateConfiguration(request);

      if (!this.errMessages.length) {
        const obj = {
          type: translate('common.success'),
          title: translate('settings-payment-gateway.config-saved'),
          message: translate('settings-payment-gateway.config-saved-message')
        };
        EventHandler.$emit('show-toast', obj);

        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        rootCompanyId: data.rootCompanyId,
        vivaWalletSecretKey: data.vivaWalletSecretKey,
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        rootCompanyId: data.rootCompanyId,
        vivaWalletSecretKey: '',
      };
    }
  }

  async created() {
    if (this.inEditMode) {
      await PaymentGatewayStore.getConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    } else {
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    }
  }
}
