

















































































































































































































































































































































































































































































































































































































































import { Vue, Component, Model, Prop, Emit, Watch } from 'vue-property-decorator';
import { VDatePicker } from 'v-calendar';
import moment from 'moment';

import { translate, translationExists } from '@/i18n';
import { MOBILE_NAVIGATION_HEIGHT } from '@/modules/layout/layout.const';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import { DisplayTimeLabels } from '@/api/home/home.model';
import { ToTwoDigitStringValue } from '@/core/utils';
import _ from 'lodash';
import SearchConst from '@/const/search.const';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import LayoutStore from '@/modules/layout/layout.store';

interface DateValueModel {
  start?: Date;
  end?: Date;
}

const timeDepartureEventName = 'update:timeValueDeparture';
const timeReturnEventName = 'update:timeValueReturn';
const dateSeparator = ' - ';

const dropdownSizes = [
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode === 'range' && showTimeButtons && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 570,
      height: 440,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode !== 'range' && showTimeButtons && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 285,
      height: 440,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode === 'range' && showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 570,
      height: 373,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode !== 'range' && showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 285,
      height: 373,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode === 'range' && showTimePicker && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 570,
      height: 355,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode !== 'range' && showTimePicker && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 285,
      height: 355,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode === 'range' && !showTimePicker && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 570,
      height: 280,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode !== 'range' && !showTimePicker && !showAlternativeTimePicker && !singularTimeModel;
    },
    dimensions: {
      width: 285,
      height: 280,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode === 'range' && showTimePicker && !showAlternativeTimePicker && singularTimeModel;
    },
    dimensions: {
      width: 570,
      height: 340,
    },
  },
  {
    match: (mode: string, showTimePicker: boolean, showAlternativeTimePicker: boolean, singularTimeModel: boolean, showTimeButtons: boolean) => {
      return mode !== 'range' && showTimePicker && !showAlternativeTimePicker && singularTimeModel;
    },
    dimensions: {
      width: 285,
      height: 340,
    },
  },
];

const simpleDateFormat = 'Do MMMM';

@Component
export default class UiDatePicker extends Vue {
  @Model('change') readonly date!: any;
  @Prop({ default: 'single' }) mode!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) minDate!: Date | null;
  @Prop({ default: null }) maxDate!: Date | null;
  @Prop({ default: '' }) id!: string;
  @Prop({ default: '' }) seleniumId!: string;
  @Prop({ default: 1 }) columns!: number;
  @Prop({ default: false }) singularTimeModel!: boolean;
  @Prop() hideTimeWindows!: boolean;
  @Prop() timeValueDeparture!: Array<number|string> | number;
  @Prop() timeValueReturn!: Array<number|string> | number;
  @Prop({ default: false }) railHours!: boolean;
  @Prop({ default: false }) showTimePicker!: boolean;
  @Prop({ default: false }) showAlternativeTimePicker!: boolean;
  @Prop({ default: false }) showTimeButtons!: boolean;
  @Prop() alternativeTimePickerOptions!: any[];
  @Prop() timeSliderStep!: number;
  @Prop() timeLabels!: DisplayTimeLabels;
  @Prop({ default: false }) showOnHover!: boolean;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) titleButtonHighlighting!: boolean;
  @Prop({ default: false }) scrollIfNotFit!: boolean;
  @Prop({ default: null }) minTime!: number | null;
  @Prop({ default: null }) maxTime!: number | null;

  locale = {
    id: AccountStore.currentLanguage || 'en',
    masks: { 
      weekdays: 'WWW',
      L: this.currentDateFormat,
      title: 'MMMM YYYY',
    },
    popoverDirection: 'bottom',
    firstDayOfWeek: 2,
  };
  timeOfDay = [
    {
      displayName: translate('search-air.all-day'),
      name: 'allday',
      value: [0, 1439],
      isSelected: false,
    },
    {
      displayName: translate('search-air.morning'),
      name: 'morning',
      value: [0, 719],
      isSelected: false,
    },
    {
      displayName: translate('search-air.afternoon'),
      name: 'afternoon',
      value: [720, 1079],
      isSelected: false,
    },
    {
      displayName: translate('search-air.evening'),
      name: 'evening',
      value: [1080, 1439],
      isSelected: false,
    },
  ];
  canShowClasses: boolean = false;
  isInPopup: boolean = false;
  queryText: string = '';
  popupContainer: HTMLElement | null = null;
  popupTitle: string = '';
  foundLabel: string = '';
  inputFocused: boolean = false;
  iconFocused: boolean = false;
  selectFocused: boolean = false;
  isDropdownTouching: boolean = false;
  focusTimeout: number = -1;
  inputBlurTimeout: number = -1;
  iconBlurTimeout: number = -1;
  dropdownBlurTimeout: number = -1;
  foundTimeOptions: any[] = [];
  usingKeyboard: boolean = false;
  windowWidth: number = 0;
  windowHeight: number = 0;
  inputRect: any = null;
  scrollableArea: HTMLElement | null = null;
  currentDimensions: any = null;
  desktopInputFocused: boolean = false;
  mouseIsOverInput: boolean = false;
  mouseIsOverDropdown: boolean = false;
  mouseOverTimeoutId: any = null;
  mouseCheckEnabled: boolean = false;
  canShowDatePicker: boolean = false;
  dayFocused: boolean = false;
  timeFocused: boolean = false;
  isActive: boolean = false;
  expandedMode: boolean = false;
  mobileOptionsHeight: number = 0;
  selectMode: number = 0; // 0 - autocomplete, 1, 2 - alternative timepickers active
  touchedIndex: number = -1;
  chosenIndex: number = -1;



  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get placeholder() {
    if (this.mode === 'range') {
      return this.currentDateFormat + dateSeparator + this.currentDateFormat;
    }
    return this.currentDateFormat;
  }

  get departureDate() {
    if (this.mode === 'range') {
      return moment((this.dateValue as DateValueModel).start).format(simpleDateFormat);
    }

    return moment(this.dateValue).format(simpleDateFormat);
  }

  get returnDate() {
    if (this.mode === 'range') {
      return moment((this.dateValue as DateValueModel).end).format(simpleDateFormat);
    }

    return moment(this.dateValue).format(simpleDateFormat);
  }

  get timeButtons() {
    return (
        this.railHours ? SearchConst.railSearchTimeValues : SearchConst.searchTimeValues
      ).map(item => ({
        ...item,
        labelTranslated: translationExists(item.label) ? translate(item.label) : item.label,
      }));
  }

  get dateTimeDisplay() {
    if (this.mode === 'range') {
      if ((this.dateValue as DateValueModel).start === null) {
        return '';
      }
      return moment((this.dateValue as DateValueModel).start).format(this.currentDateFormat) +
        dateSeparator + moment((this.dateValue as DateValueModel).end).format(this.currentDateFormat);
    } else {
      if (!this.dateValue) {
        return '';
      }
      return moment(this.dateValue).format(this.currentDateFormat);
    }
  }

  get query() {
    return this.queryText;
  }

  timeWindowContentClick() {
    (this.$refs.input as HTMLElement).focus();
  }

  splittedQueryText() {
    const splitted = this.queryText.split(dateSeparator);

    if (2 === splitted.length) {
      const momentStart = moment(splitted[0], this.currentDateFormat);
      const momentEnd = moment(splitted[1], this.currentDateFormat);

      if (momentStart.isValid() && momentEnd.isValid()) {
        let valStart = this.normalizeDate(momentStart);
        let valEnd = this.normalizeDate(momentEnd);

        if (valStart.getTime() > valEnd.getTime()) {
          const temp = valEnd;
          valEnd = valStart;
          valStart = temp;
        }

        this.queryText = [
          moment(valStart).format(this.currentDateFormat),
          '-',
          moment(valEnd).format(this.currentDateFormat),
        ].join(' ');

        this.onChange({
          start: valStart,
          end: valEnd,
        });
        this.$nextTick(this.adjustDatePickerPageRange);
      }
    }
  }

  momentQueryValue(value) {
    const momentValue = moment(value, this.currentDateFormat);
    if (momentValue.isValid()) {
      let val = this.normalizeDate(momentValue);
      this.queryText = moment(val.toISOString()).format(this.currentDateFormat);
      this.onChange(val);
      this.$nextTick(this.adjustDatePickerPageRange);
    }
  }

  set query(value) {
    this.queryText = value;
    if ('range' === this.mode) {
      if ((this.inputFocused || this.desktopInputFocused) && 0 === this.queryText.length) {
        this.onChange({
          start: null,
          end: null,
        });
        return;
      }
      if ((this.inputFocused || this.desktopInputFocused) && 3 + 2 * this.currentDateFormat.length === this.queryText.length) {
        this.splittedQueryText();
      }
      return;
    }

    if ((this.inputFocused || this.desktopInputFocused) && 0 === this.queryText.length) {
      this.onChange(null);
    }
    if ((this.inputFocused || this.desktopInputFocused) && this.currentDateFormat.length === this.queryText.length) {
      this.momentQueryValue(value);
    }
  }

  get shouldShowDatePicker() {
    return this.desktopInputFocused || this.mouseIsOverInput || this.mouseIsOverDropdown || this.dayFocused || this.timeFocused;
  }

  get formatPlaceholder() {
    return 'range' === this.mode ? this.currentDateFormat + dateSeparator + this.currentDateFormat : this.currentDateFormat;
  }

  get currentId() {
    return this.id || ('UiDatePicker' + this._uid);
  }

  get dateValue() {
    if ('range' === this.mode) {
      if (!this.date || !this.date.start) {
        return {
          start: null,
          end: null, // new Date().setDate(new Date().getDate() + 1),
        };
      }
      return {
        start: this.date.start,
        end: this.date.end,
      };
    }
    return this.date;
  }

  set dateValue(value) {
    this.onChange(value);
  }

  get classes() {
    return {
      'ui-date-picker__mobile--disabled': this.disabled,
      'ui-date-picker__mobile--active': this.isActive,
      'ui-date-picker__mobile--has-popup-title': '' !== this.popupTitle,
    };
  }

  get timeValueDepartureObject() {
    if (typeof this.timeValueDeparture === 'object') {
      return {
        ...this.timeValueDeparture,
        labelTranslated: this.formatTimePicked(this.timeValueDeparture)
      };
    }
    return this.timeValueDeparture;
  }

  get timeValueReturnObject() {
    if (typeof this.timeValueReturn === 'object') {
      return {
        ...this.timeValueReturn,
        labelTranslated: this.formatTimePicked(this.timeValueReturn)
      };
    }
    return this.timeValueReturn;
  }

  get timeDeparture() {
    return this.timeValueDepartureObject;
  }

  set timeDeparture(value: any) {
    this[timeDepartureEventName](value);
  }

  get timeReturn() {
    return this.timeValueReturnObject;
  }

  set timeReturn(value: any) {
    this[timeReturnEventName](value);
  }

  get departureLabel() {
    return (this.timeLabels && this.timeLabels.start) ?
    this.timeLabels.start : translate('search-air.departure-at');
  }

  get returnLabel() {
    return (this.timeLabels && this.timeLabels.end) ?
    this.timeLabels.end : translate('search-air.return-at');
  }

  get mobileFieldClasses() {
    return {
      'ui-date-picker__2mobile-field--disabled': this.disabled,
      'ui-date-picker__2mobile-field--expanded-mode': this.expandedMode,
      'error-message': this.showErrorMessage || this.showEarlierTimeErrorMessage || this.showLaterTimeErrorMessage ,
    };
  }

  get dropdownWrapperClasses() {
    return {
      'ui-date-picker__2mobile-dropdown-wrapper--expanded-mode': this.expandedMode,
    };
  }
  
  get mobileOptionsStyles() {
    if (this.mobileOptionsHeight < 0) {
      return {};
    }

    return {
      height: this.mobileOptionsHeight + 'px',
      visibility: this.selectMode !== 0 ? 'hidden' : 'visible',
      position: this.selectMode !== 0 ? 'absolute' : 'static',
      top: this.selectMode !== 0 ? '-5000px' : '',
    };
  }

  get mobileAltPickerOptionsStyles() {
    if (this.mobileOptionsHeight < 0) {
      return {};
    }

    return {
      height: this.mobileOptionsHeight + 'px',
    };
  }

  get desktopDropdownClasses() {
    return {
      'ui-date-picker__desktop-dropdown--ready': this.canShowClasses,
    };
  }

  get shouldShowAnotherCaret() {
    return !this.showErrorMessage && this.dropdownRect.top < 0;
  }

  get dropdownRect() {
    const margin = 20;
    let width = 0;
    let height = 0;
    let top = 0;
    let left = 0;

    if (!this.currentDimensions || !this.inputRect) {
      return {
        top,
        left,
        width,
        height,
      };
    }


    width = this.currentDimensions.width;
    height = this.currentDimensions.height;

    if (this.showErrorMessage || this.showEarlierTimeErrorMessage || this.showLaterTimeErrorMessage) {
      height += 41;
    }

    if (this.inputRect.left + (this.inputRect.width + width) / 2 + margin > this.windowWidth) {
      left = this.windowWidth - (this.inputRect.left + width + margin);
    } else if (this.inputRect.left + (this.inputRect.width - width) / 2 - margin < 0) {
      left = margin - this.inputRect.left;
    } else {
      left = (this.inputRect.width - width) / 2;
    }

    if (
      (this.inputRect.top + this.inputRect.height + height + margin > this.windowHeight) &&
      (this.inputRect.top - height > 93)
    ) {
      top = -(height);
    } else {
      top = this.inputRect.height + 12;
    }

    return {
      top,
      left,
      width,
      height,
    };
  }

  get dropdownStyles() {
    return {
      top: this.dropdownRect.top + 'px',
      left: this.dropdownRect.left + 'px',
      width: this.dropdownRect.width + 'px',
    };
  }

  get dropdownCaretClasses() {
    return {
      'ui-date-picker__desktop-dropdown-caret--down': this.dropdownRect.top < this.inputRect.height,
    };
  }

  get dropdownCaretStyles() {
    return {
      left: Math.max(12, Math.min(this.dropdownRect.width - 24, -this.dropdownRect.left + this.inputRect.width / 2)) + 'px',
      top: (this.dropdownRect.top >= this.inputRect.height + 12 ? 0 : this.dropdownRect.height - 12) + 'px',
    };
  }

  get anotherCaretStyles() {
    return {
      left: (Math.max(12, Math.min(this.dropdownRect.width - 24, -this.dropdownRect.left + this.inputRect.width / 2)) - 8) + 'px',
    };
  }

  get timePickerOptions() {
    return this.alternativeTimePickerOptions.map(option => {
      return {
        ...option,
        labelTranslatedLowerCase: this.formatTimePicked(option).toLowerCase(),
        labelTranslated: this.formatTimePicked(option),
        labelLowerCase: option.label.toLowerCase()
      };
    });
  }

  get inputError() {
    return {
      'ui-date-picker-regular__input--error': this.showErrorMessage || this.showEarlierTimeErrorMessage || this.showLaterTimeErrorMessage,
      'ui-date-picker__2mobile-input--error': this.showErrorMessage || this.showEarlierTimeErrorMessage || this.showLaterTimeErrorMessage,
    };
  }

  get showErrorMessage() {
    if (!this.date) {
      return false;
    }

    if (this.mode === 'single') {
      return false;
    }

    if (!moment(this.date.start).isSame(this.date.end, 'date')) {
      return false;
    }

    if (this.showAlternativeTimePicker || this.showTimeButtons) {
      if (this.timeReturn.value === -1) {
        return false;
      }
      if (this.timeDeparture.value === -1) {
        return this.timeReturn.value === 0;
      }
      if (this.timeDeparture.value >= this.timeReturn.value ) {
        return true;
      }

      return false;
      
    } 

    return this.timeReturn <= this.timeDeparture;
  }

  get showEarlierTimeErrorMessage() {
    if (!this.date) {
      return false;
    }

    if (!moment(this.date).isSame(this.minDate, 'date')) {
      return false;
    }

    if (this.mode === 'single' && this.showTimePicker) {
      if (this.minTime) {
        if (this.timeValueDeparture < this.minTime) {
          return true;
        }
      }

      return false;
    }

    return false;
  }

  get showLaterTimeErrorMessage() {
    if (!this.date) {
      return false;
    }

    if (!moment(this.date).isSame(this.maxDate, 'date')) {
      return false;
    }

    if (this.mode === 'single' && this.showTimePicker) {
      if (this.maxTime) {

        if (this.timeValueDeparture > this.maxTime) {
          return true;
        }
      }

      return false;
    }

    return false;
  }

  get errorClass() {
    return {
      'time-picker-alt__wrapper--error-not-alt': !this.showTimeButtons && !this.showAlternativeTimePicker,
      'time-picker-alt__wrapper--error-time-buttons': this.showTimeButtons,
    };
  }

  get scrollOffset() {
    return this.scrollIfNotFit ? -60 : 0;
  }

  @Emit()
  [timeDepartureEventName](val) {
    return val;
  }

  @Emit()
  [timeReturnEventName](val) {
    return val;
  }

  @Emit('change')
  onChange(value) {
    return value;
  }

  isTimeButtonSelected(direction, button) {
    const item = this['time' + direction];

    return item.timeRange[0] === button.timeRange[0] &&
      item.timeRange[1] === button.timeRange[1];
  }

  isInViewport(elem) {
    if (elem) {
      let bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }

  normalizeDate(value) {
    const val = new Date(value.toISOString());
    if (this.minDate !== null && val.getTime() < this.minDate.getTime()) {
      return this.minDate;
    } else if (this.maxDate !== null && val.getTime() > this.maxDate.getTime()) {
      return this.maxDate;
    }
    return val;
  }

  adjustDatePickerPageRange() {
    if (this.inputFocused) {
      this.mobileAdjustPageRange();
    } else {
      this.adjustPageRange();
    }
  }

  mobileAdjustPageRange() {
    this.$nextTick(() => {
      if (!this.$refs.mobileVDatePickerComponent) {
        return;
      }
      (this.$refs.mobileVDatePickerComponent as VDatePicker).adjustPageRange();
    });
  }

  adjustPageRange() {
    this.$nextTick(() => {
      if (!this.$refs.vDatePickerComponent) {
        return;
      }
      (this.$refs.vDatePickerComponent as VDatePicker).adjustPageRange();
    });
  }

  formatTimePicked(valueObject) {
    if (valueObject.timeRange) {
      const hours = ToTwoDigitStringValue(valueObject.timeRange[0] / 60);
      const minutes = ToTwoDigitStringValue(valueObject.timeRange[0] % 60);

      return moment(`2000-01-01T${hours}:${minutes}:00.000Z`).utc(false).format(this.currentTimeFormat);
    } else if (valueObject.value === -1) {
      return translate('common.any-hour');
    }
    else if (valueObject.label === 0) {
      return valueObject.label;
    } else {
      const hours = ToTwoDigitStringValue(valueObject.value / 60);
      const minutes = ToTwoDigitStringValue(valueObject.value % 60);

      return moment(`2000-01-01T${hours}:${minutes}:00.000Z`).utc(false).format(this.currentTimeFormat);
    }
  }

  mobileFocus() {
    this.inputFocused = true;
  }

  assignQuery() {
    if ('range' === this.mode) {
      if (
        this.date !== null && typeof this.date === 'object' &&
        null != this.date.start && null != this.date.end
      ) {
        this.query = [
          moment(this.date.start).format(this.currentDateFormat),
          '-',
          moment(this.date.end).format(this.currentDateFormat),
        ].join(' ');
      } else {
        this.query = '';
      }

      return;
    }
    const momentDate = moment(this.date);
    if ((this.date !== null && typeof this.date !== 'string') || momentDate.isValid()) {
      this.query = moment(this.date).format(this.currentDateFormat);
    } else {
      this.query = '';
    }
  }

  mobileBlur() {
    this.inputFocused = false;
    this.assignQuery();
  }

  timeOfDisplay(obj) {
    let val = obj.value ? obj.value : obj;
    let day: string | null = null;
    this.timeOfDay.forEach((array) => {
      if (array.value[0] === val[0] && array.value[1] === val[1]) {
        array.isSelected = true;
        day = array.displayName;
      }
    });

    if (day) {
      return day;
    } else {
      const hours1 = ToTwoDigitStringValue(val[0] / 60);
      const minutes1 = ToTwoDigitStringValue(val[0] % 60);
      const hours2 = ToTwoDigitStringValue(val[1] / 60);
      const minutes2 = ToTwoDigitStringValue(val[1] % 60);

      const from = moment(`2000-01-01T${hours1}:${minutes1}:00.000Z`).utc(false).format(this.currentTimeFormat);
      const to = moment(`2000-01-01T${hours2}:${minutes2}:00.000Z`).utc(false).format(this.currentTimeFormat);

      return from + dateSeparator + to;
    }
  }

  formatTimeOfDisplay(obj) {
    const val = obj.value ? obj.value : obj;
    const hours = ToTwoDigitStringValue(val / 60);
    const minutes = ToTwoDigitStringValue(val % 60);

    return moment(`2000-01-01T${hours}:${minutes}:00.000Z`).utc(false).format(this.currentTimeFormat);
  }

  displayTime(obj) {
    if (this.showTimeButtons) {
      if (obj.value === -1) {
        return translate('common.any-hour');
      }
      return `${translate('common.after')} ${this.formatTimePicked(obj)}`;
    } else if ((obj || obj === 0) && !this.showAlternativeTimePicker) {
      if (!this.singularTimeModel) {
        return this.timeOfDisplay(obj);
      }
      return this.formatTimeOfDisplay(obj);
    } else if ((obj || obj === 0) && this.showAlternativeTimePicker) {
      let rangeObject = SearchConst.airSearchTimeWindows.find(wind => {
        return wind.value === obj.value;
      });
      if (!rangeObject) {
        return this.formatTimePicked(SearchConst.airSearchTimeWindows[0]);
      } else if (rangeObject.value > -1) {
        return `${translate('common.after')} ${this.formatTimePicked(rangeObject)}`;
      }
      return translate('common.any-hour');
    }
  }

  searchTimeRanges(query) {
    let searchTex = query.toLowerCase();
    this.foundTimeOptions  = [];
    let options = this.timePickerOptions.filter((opt: any) => {
      return opt.labelTranslatedLowerCase.includes(searchTex);
    });

    this.foundTimeOptions = _.sortBy(options, (opt) => {
      return opt.labelTranslatedLowerCase.indexOf(searchTex);
    });
  }

  openDatePicker() {
    this.usingKeyboard = true;
  }

  isDateValid(date) {
    let timestamp = Date.parse(date);
    return isNaN(timestamp) ? false : true;
  }

  onInputKeyDown($event) {
    if ($event.keyCode === 13) {
      this.$emit('enter-pressed', {
        uid: this._uid,
      });
    }
  }

  onInputFocus() {
    this.desktopInputFocused = true;
  }

  onInputBlur() {
    this.desktopInputFocused = false;
    this.assignQuery();
  }

  checkIsOverInput($event) {
    if (this.disabled) {
      return;
    }
    const inputRef = this.$refs.input as HTMLInputElement;

    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      
      if (
        $event.clientX >= rect.left && $event.clientX <= rect.left + rect.width &&
        $event.clientY >= rect.top && $event.clientY <= rect.top + rect.height
      ) {
        this.mouseIsOverInput = true;
      } else {
        this.mouseIsOverInput = false;
      }
    } 
  }

  checkIsOverDropdown($event) {
    const inputRef = this.$refs.input as HTMLInputElement;

    if (inputRef) {
      const rectInput = inputRef.getBoundingClientRect();
      const rect = {
        ...this.dropdownRect,
        left: rectInput.left + this.dropdownRect.left,
        top: rectInput.top + this.dropdownRect.top,
      };

      if (this.dropdownRect.top >= 0) {
        rect.top -= 12;
      }
      if (
        $event.clientX >= rect.left && $event.clientX <= rect.left + rect.width &&
        $event.clientY >= rect.top && $event.clientY <= rect.top + rect.height
      ) {
        this.mouseIsOverDropdown = true;
      } else {
        this.mouseIsOverDropdown = false;
      }
    }
  }

  onWindowMouseMove($event) {
    this.checkIsOverInput($event);
    this.checkIsOverDropdown($event);
  }

  mouseCheck() {
    if (this.mouseCheckEnabled) {
      return;
    }
    this.mouseCheckEnabled = true;
    window.removeEventListener('mousemove', this.onWindowMouseMove);
    window.addEventListener('mousemove', this.onWindowMouseMove);
  }

  onDropdownMouseDown($event) {
    this.onWindowMouseMove($event);
  }

  onDayFocus() {
    this.dayFocused = true;
  }

  onDayBlur() {
    this.dayFocused = false;
  }

  onTimeFocus() {
    this.timeFocused = true;
  }

  onTimeBlur() {
    const inputRef = this.$refs.input as HTMLInputElement;
    if (inputRef) {
      this.$nextTick(() => {
        inputRef.focus({
          preventScroll: true
        });
      });
    }

    this.timeFocused = false;
  }

  mobileFieldFocus() {
    if (this.disabled) {
      return;
    }
    this.isActive = true;
  }

  onWindowResizeOrScroll() {
    const inputRef = this.$refs.input as HTMLInputElement;
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      this.inputRect = {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      };
    }

    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  fitDateDropdownToWindowScroll() {
    const dropdownRef = this.$refs.dropdown as Element;
    const inputRef = this.$refs.input as HTMLInputElement;
    if (dropdownRef) {
      const rect = dropdownRef.getBoundingClientRect();
      const bottom = rect.bottom + this.dropdownRect.height;
      if (bottom > window.innerHeight || rect.top < 0) { 
        LayoutStore.scrollManager
        .scrollTo(inputRef, false);
      }
    }
  }

  switchDropdownWhenNeeded(value) {
    if (value && !this.canShowDatePicker) {
      clearTimeout(this.mouseOverTimeoutId);
      this.canShowDatePicker = true;
    }
  }

  @Watch('mouseIsOverInput')
  onMouseIsOverInput(value) {
    if (!this.showOnHover) {
      return;
    }
    this.switchDropdownWhenNeeded(value);
  }

  @Watch('desktopInputFocused')
  onDesktopInputFocused(value) {
    this.switchDropdownWhenNeeded(value);
  }

  @Watch('shouldShowDatePicker')
  onConditionsChange(value) {
    if (value) {
      clearTimeout(this.mouseOverTimeoutId);
      
      if (!this.showOnHover) {
        return;
      }

      this.canShowDatePicker = true;
    } else {
      let timeOutsec;
      if (!this.showOnHover) {
        timeOutsec = 200;
      } else {
        timeOutsec = 700;
      }

      clearTimeout(this.mouseOverTimeoutId);
      this.mouseOverTimeoutId = setTimeout(() => {
        this.canShowDatePicker = false;
      }, timeOutsec);
    }
  }

  @Watch('canShowDatePicker')
  onCanShowDatePickerChange(val) {
    if (!val) {
      this.mouseCheckEnabled = false;
      window.removeEventListener('mousemove', this.onWindowMouseMove);
      return;
    }

    EventBus.$emit('ui-date-picker-dropdown-toggled', this._uid);
    this.mouseCheck();
    const dimensions = dropdownSizes.find(item => {
      return item.match(this.mode, this.showTimePicker, this.showAlternativeTimePicker, this.singularTimeModel, this.showTimeButtons);
    });
    if (dimensions) {
      this.currentDimensions = dimensions.dimensions;
    }
    this.onWindowResizeOrScroll();

    this.canShowClasses = false;
    setTimeout(() => {
      this.canShowClasses = true;
      if (this.scrollIfNotFit) {
        this.fitDateDropdownToWindowScroll();
      }
    });
  }

  @Watch('date', { immediate: true })
  onDateChange(value) {
    if (this.inputFocused || this.desktopInputFocused) {
      return;
    }

    if (
      value && ('range' !== this.mode ||
      (value.start != null && value.end != null))
    ) {
      if ('range' === this.mode) {
        if (!value.start) {
          this.query = '';
          return;
        }

        this.query = [
          moment(value.start).format(this.currentDateFormat),
          '-',
          moment(value.end).format(this.currentDateFormat),
        ].join(' ');
        return;
      }
      this.query = moment(value).format(this.currentDateFormat);
    } else {
      this.query = '';
    }
  }

  onWindowClick($event) {
    const navHeight = MOBILE_NAVIGATION_HEIGHT;

    if ($event.pageY < navHeight) {
      this.confirm();
    }
  }

  confirm() {
    this.isActive = false;
  }

  recalculateOptionsHeight() {
    if (!this.isActive) {
      return;
    }

    const mobileHeader = this.$refs.mobileHeader as HTMLElement;
    const rect = mobileHeader.getBoundingClientRect();

    this.mobileOptionsHeight = window.innerHeight - rect.bottom;
  }

  @Watch('isActive')
  onActiveChange(value) {
    if (value) {
      setTimeout(() => {
        this.recalculateOptionsHeight();
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('resize', this.recalculateOptionsHeight);
      });
    } else {
      window.removeEventListener('click', this.onWindowClick);
      window.removeEventListener('resize', this.recalculateOptionsHeight);
    }
    if (this.isInPopup && window.innerWidth < 800) {
      if (value) {
        EventBus.$emit('freeze-popup', this.popupContainer);
      } else {
        EventBus.$emit('unfreeze-popup', this.popupContainer);
      }
    }
  }

  @Watch('showErrorMessage', { immediate: true})
  onInvalidDate(value) {
    this.$emit('invalid-date', value);
  }

  @Watch('showEarlierTimeErrorMessage', { immediate: true})
  onInvalidEarlierTime(value) {
    this.$emit('invalid-date', value);
  }

  @Watch('showLaterTimeErrorMessage', { immediate: true})
  onInvalidLaterTime(value) {
    this.$emit('invalid-date', value);
  }

  findLabel() {
    if (this.$slots['field-label']) {
      return;
    }

    const label = (this.$refs.uiDatePickerWrapper as Element).parentNode;

    if (
      label &&
      'label' === label.nodeName.toLowerCase() &&
      (label as HTMLElement).className &&
      -1 < (label as HTMLElement).className.indexOf('ui-label')
    ) {
      for (let child of label.children) {
        if ('span' === child.nodeName.toLowerCase()) {
          this.foundLabel = (child as HTMLElement).textContent || '';
        }
      }
    }
  }

  findOutIfInPopup() {
    if (!hasSomeParentTheClass(this.$refs.uiDatePickerWrapper, 'modal-container')) {
      return;
    }

    this.isInPopup = true;
    this.$nextTick(() => {
      this.popupContainer = getParentByClass(this.$refs.uiDatePickerWrapper, 'modal-container');
      if (this.popupContainer) {
        const titleElements = this.popupContainer.getElementsByClassName('popup-title');
        if (titleElements.length) {
          this.popupTitle = (titleElements[0] as HTMLElement).textContent || '';
        }
      }
    });
  }

  findOutIfInExpandedMode() {
    if (!hasSomeParentTheClass(this.$refs.uiDatePickerWrapper, 'datepicker-expanded')) {
      return;
    }

    this.expandedMode = true;
  }

  toggleAltTimePicker(mode: number) {
    this.selectMode = mode;
    this.touchedIndex = -1;
    this.chosenIndex = -1;
    this.searchTimeRanges('');
    if (mode === 1) {
      this.chosenIndex = this.foundTimeOptions.findIndex(option => option.value === this.timeDeparture.value);
    } else if (mode === 2) {
      this.chosenIndex = this.foundTimeOptions.findIndex(option => option.value === this.timeReturn.value);
    }
    setTimeout(() => {
      this.recalculateOptionsHeight();
    });
  }

  onAltTimePickerOptionTouchStart(index) {
    this.touchedIndex = index;
  }

  onAltTimePickerOptionTouchEnd(index) {
    if (index === this.touchedIndex) {
      this.touchedIndex = -1;
    }
  }

  classesForAltTimePickerIndex(item, index) {
    return {
      'ui-date-picker__2mobile-alt-time-picker-option--selected': item.$isDisabled !== true && this.chosenIndex === index,
      'ui-date-picker__2mobile-alt-time-picker-option--highlighted': item.$isDisabled !== true && this.touchedIndex === index,
    };
  }

  mobileAltTimePickerOptionsClick($event) {
    if (hasSomeParentTheClass($event.target, 'option-item')) {
      const parent = getParentByClass($event.target, 'option-item');
      const index = parent.attributes.data.nodeValue;

      this.chosenIndex = index;
      if (this.selectMode === 1) {
        this.timeDeparture = this.foundTimeOptions[index];
      } else if (this.selectMode === 2) {
        this.timeReturn = this.foundTimeOptions[index];
      }
    }
    this.toggleAltTimePicker(0);
  }

  attachToWindowAndScrollEvents() {
    window.addEventListener('resize', this.onWindowResizeOrScroll);

    this.scrollableArea = getParentByClass(this.$el, 'scrollable-area');
    if (!this.scrollableArea) {
      this.scrollableArea = getParentByClass(this.$el, 'modal-container');
    }

    if (!this.scrollableArea) {
      return;
    }

    this.scrollableArea.addEventListener('scroll', this.onWindowResizeOrScroll);
  }

  deattachFromWindowAndScrollEvents() {
    window.removeEventListener('resize', this.onWindowResizeOrScroll);

    if (!this.scrollableArea) {
      return;
    }

    this.scrollableArea.removeEventListener('scroll', this.onWindowResizeOrScroll);
  }

  onDropdownToggleEvent(id) {
    if (this._uid !== id && this.canShowDatePicker) {
      this.canShowDatePicker = false;
    }
  }

  mounted() {
    this.findLabel();
    this.findOutIfInPopup();
    this.findOutIfInExpandedMode();
    this.attachToWindowAndScrollEvents();

    EventBus.$on('ui-date-picker-dropdown-toggled', this.onDropdownToggleEvent);
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onWindowMouseMove);
    this.deattachFromWindowAndScrollEvents();
    EventBus.$off('ui-date-picker-dropdown-toggled', this.onDropdownToggleEvent);
  }
}

