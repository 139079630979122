export const getLoyaltyName = (serviceType, programName, carrierName, carrierCode) => { 
    if (serviceType === 'Air') {
        return getAirLoyaltyName(programName, carrierName, carrierCode);
    }
    return programName;
};


export const getAirLoyaltyName = (programName, carrierName, carrierCode) => { 
    if (carrierName) {
        return programName === carrierName ? programName : `${programName} | ${carrierName}`;
    } else if (carrierCode && programName !== carrierCode) {
        return programName === carrierCode ? programName : `${programName} | ${carrierCode}`;
    }
    return programName;
};
