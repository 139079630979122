































































































import { Vue, Component } from 'vue-property-decorator';

import { CustomMessagesApi } from '@/api/profile/custom-messages.api';
import CustomMessagesStore from '@/modules/global-settings/custom-messages/custom-messages.store';

@Component({})
export default class CustomMessagesWidget extends Vue {
  serverErrors: any[] = [];
  messages: any[] = [];
  preparing: boolean = false;
  animating: boolean = false;
  animatingFinishedID: any = null;
  listHeight: number = 0;
  isAnimationReversed: boolean = false;
  selectedMessage: any = null;
  lastSelectedMessage: any = null;

  

  get countNumber() {
    return this.messages.length;
  }

  get classes() {
    return {
      'train-timeline-details__proposals-list--ready-to-enter': this.preparing,
      'train-timeline-details__proposals-list--entering': this.animating,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get lastClasses() {
    return {
      'train-timeline-details__proposals-list--ready-to-leave': this.preparing,
      'train-timeline-details__proposals-list--leaving': this.animating,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get wrapperClasses() {
    return {
      'train-timeline-details__proposals-list-wrapper--animating': this.preparing || this.animating,
    };
  }

  get wrapperStyles() {
    if (this.preparing || this.animating) {
      return {
        height: this.listHeight + 'px',
      };
    }
    return {};
  }

  get currentMessageIndex() {
    return this.messages.findIndex(c => c.id === this.selectedMessage.id);
  }

  get isPrevDisabled() {
    return this.currentMessageIndex === 0;
  }

  get isNextDisabled() {
    return this.currentMessageIndex === this.messages.length - 1;
  }



  selectMessage(nextMessage, index) {
    if (index === this.currentMessageIndex) {
      return;
    }
    this.preparing = false;
    this.animating = false;
    this.isAnimationReversed = index < this.currentMessageIndex;
    clearTimeout(this.animatingFinishedID);

    this.$nextTick(() => {
      this.commitMessageChange(nextMessage);
    });
  }

  commitMessageChange(nextMessage) {
    this.lastSelectedMessage = this.selectedMessage;
    this.selectedMessage = nextMessage;

    this.preparing = true;

    const listWrapperRef = this.$refs.listWrapper as HTMLElement;
    this.listHeight = listWrapperRef.getBoundingClientRect().height;

    this.$nextTick(() => {
      const listRef = this.$refs.newList as HTMLElement;
      this.listHeight = listRef.getBoundingClientRect().height;

      this.animating = true;
      this.animatingFinishedID = setTimeout(this.finishAnimating, 600);
    });
  }

  showPrevMessage() {
    if (this.currentMessageIndex === 0) {
      return;
    }

    const newIndex = this.currentMessageIndex - 1;

    this.selectMessage(this.messages[newIndex], newIndex);
  }

  showNextMessage() {
    if (this.currentMessageIndex === this.messages.length - 1) {
      return;
    }

    const newIndex = this.currentMessageIndex + 1;

    this.selectMessage(this.messages[newIndex], newIndex);
  }

  async loadCustomMessages() {
    try {
      const result = await CustomMessagesApi.getActiveMessages();
      this.messages = result.data;

      if (this.messages.length) {
        this.selectedMessage = this.messages[0];
      }

      CustomMessagesStore.setHasMessages(0 < this.messages.length);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error);
    } finally {

    }
  }

  finishAnimating() {
    this.preparing = false;
    this.animating = false;
  }



  created() {
    this.loadCustomMessages();
  }

  beforeDestroy() {
    CustomMessagesStore.setHasMessages(false);
  }
}

