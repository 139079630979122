


































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

import { Journey, Fare, Proposal, Train } from '@/api/train-engine/train-search.model';
import TrainProposal from './TrainProposal.vue';
import TrainTimelineDetails from './TrainTimelineDetails.vue';
import EventBus from '@/services/event-handler';
import HeightTransition from '@/modules/layout/HeightTransition.vue';

@Component({
  components: {
    TrainProposal,
    TrainTimelineDetails,
    HeightTransition,
  },
})
export default class TrainProposals extends Vue {
  @Prop() provider!: string;
  @Prop() journeys!: Journey;
  @Prop() disabled!: boolean;
  @Prop() train!: Train[];
  @Prop() legNumber!: number;
  @Prop() index!: number;
  @Prop() proposal!: Proposal;
  @Prop() offer: any;
  @Prop({ default: false }) isInBasket!: boolean;

  selectedClass: any = null;
  lastSelectedClass: any = null;
  selectedProposal: Fare | undefined | null = null;
  proposalsNumber: number = 0;
  proposalsVisible: number = 3;
  lastProposalsVisible: number = 3;
  showLessButton: boolean = false;
  preparing: boolean = false;
  animating: boolean = false;
  animatingFinishedID: any = null;
  listHeight: number = 0;
  isAnimationReversed: boolean = false;

  get uniqueClasses() {
    if (this.proposal) {
      return _.sortBy(_.uniqBy(this.proposal.fares, 'class'), 'totalPrice.amount');
    }

    return [];
  }

  get currentClassIndex() {
    return this.uniqueClasses.findIndex(c => c.class === this.selectedClass.class);
  }

  get renderShowMoreProposals() {
    return this.proposalsNumber && this.proposals.length > this.proposalsVisible;
  }

  get proposals() {
    return this.proposal.fares
      .filter(proposal => proposal.class === this.selectedClass.class)
      .sort((a, b) => {
        return a.totalPrice.amount - b.totalPrice.amount;
      });
  }

  get visibleProposals() {
    return [...this.proposals].slice(0, this.proposalsVisible);
  }

  get lastProposals() {
    if (!this.lastSelectedClass) {
      return [];
    }
    return [...this.proposal.fares]
      .filter(proposal => proposal.class === this.lastSelectedClass.class)
      .sort((a, b) => {
        return a.totalPrice.amount - b.totalPrice.amount;
      })
      .slice(0, this.lastProposalsVisible);
  }

  get classes() {
    return {
      'train-timeline-details__proposals-list--ready-to-enter': this.preparing,
      'train-timeline-details__proposals-list--entering': this.animating,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get lastClasses() {
    return {
      'train-timeline-details__proposals-list--ready-to-leave': this.preparing,
      'train-timeline-details__proposals-list--leaving': this.animating,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get wrapperClasses() {
    return {
      'train-timeline-details__proposals-list-wrapper--animating': this.preparing || this.animating,
    };
  }

  get wrapperStyles() {
    if (this.preparing || this.animating) {
      return {
        height: this.listHeight + 'px',
      };
    }
    return {};
  }

  showMoreProposal() {
    this.showLessButton = true;
    this.proposalsVisible += 3;
  }

  showLessProposal() {
    this.proposalsVisible -= 3;
    if (this.proposalsVisible <= 3) {
      this.showLessButton = false;
    }
  }

  resetProposalsVisible() {
    this.proposalsVisible = 3;
  }

  setProposalNumber() {
    this.showLessButton = false;
    this.proposalsNumber = this.proposals.length;
  }

  finishAnimating() {
    this.preparing = false;
    this.animating = false;
  }

  ensureFareNameVisible(index) {
    const element = (this.$refs.fareName as any[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  commitClassChange(fareClass) {
    this.lastSelectedClass = this.selectedClass;
    this.lastProposalsVisible = this.proposalsVisible;
    this.selectedClass = fareClass;

    this.preparing = true;

    const listWrapperRef = this.$refs.listWrapper as HTMLElement;
    this.listHeight = listWrapperRef.getBoundingClientRect().height;

    this.$nextTick(() => {
      const listRef = this.$refs.newList as HTMLElement;
      this.listHeight = listRef.getBoundingClientRect().height;

      this.animating = true;
      this.animatingFinishedID = setTimeout(this.finishAnimating, 600);
    });
  }

  selectClass(fareClass, index) {
    if (index === this.currentClassIndex) {
      return;
    }
    this.ensureFareNameVisible(index);
    this.preparing = false;
    this.animating = false;
    this.isAnimationReversed = index < this.currentClassIndex;
    clearTimeout(this.animatingFinishedID);

    this.$nextTick(() => {
      this.commitClassChange(fareClass);
    });
  }

  @Watch('selectedClass')
  onClassChange() {
    this.resetProposalsVisible();
    this.setProposalNumber();
  }

  proposalSelected(newSelection: Fare) {
    this.proposal.fares.forEach(fare => {
      fare.isSelected = false;
    });
    this.selectedProposal = newSelection;
    this.selectedProposal.isSelected = true;
  }

  created() {
    if (this.proposal && this.proposal.fares) {
      this.selectedProposal = this.proposal.fares.find(p => p.isSelected);
      this.selectedClass = this.selectedProposal ? this.selectedProposal : this.uniqueClasses[0];
      this.lastSelectedClass = this.selectedClass;
    }
  }

  mounted() {
    if (this.currentClassIndex > -1) {
      this.ensureFareNameVisible(this.currentClassIndex);
    }
  }
}
