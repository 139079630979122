import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  TravellerCategory,
  TravellerCategoriesResponse,
  ProfileTravellerCategories,
} from './traveller-categories.model';

class TravellerCategoriesApiClass {
  public getCompanyTravellerCategories(companyId: string): Promise<AxiosResponse<TravellerCategory[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/traveller-categories/{companyId}',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }

  public saveCompanyTravellerCategories(companyId: string, categories: TravellerCategory[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/traveller-categories/{companyId}',
      method: RequestMethod.POST,
    }, { companyId }, categories, true);
  }

  public getAvailableTravellerCategoriesForProfile(profileId: string): Promise<AxiosResponse<TravellerCategoriesResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/traveller-categories/profile?profileId={profileId}',
      method: RequestMethod.GET,
    }, { profileId }, {});
  }

  public getAvailableTravellerCategoriesForCompany(companyId: string): Promise<AxiosResponse<TravellerCategoriesResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/traveller-categories/profile?companyId={companyId}',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }

  public getProfileTravellerCategories(profileId: string): Promise<AxiosResponse<ProfileTravellerCategories[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/traveller-categories?profileIds={profileId}',
      method: RequestMethod.GET,
    }, { profileId }, {});
  }
}

export const TravellerCategoriesApi: TravellerCategoriesApiClass = new TravellerCategoriesApiClass();
