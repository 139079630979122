














import { Component, Prop, Vue } from 'vue-property-decorator';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class UiSupplierLabel extends Vue {
  @Prop() supplier!: string;
  @Prop({ default: false }) basket!: boolean;
  @Prop({ default: false }) proposal!: boolean;

  get supplierName() {
    return SupplierName(this.supplier);
  }
}
