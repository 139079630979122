














































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import AirRailSort from './AirRailSort.vue';
import layoutStore from '@/modules/layout/layout.store';
import AirRailSearchStore from '@/modules/search/air-rail/air-rail-search.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    AirRailSort,
  }
})
export default class AirRailSubintro extends Vue {
  toggled: boolean = true;

  get sidebarToggled() {
    return !layoutStore.sidebarHidden;
  }

  get filters() {
    return AirRailSearchStore.filters;
  }

  get railFilters() {
    return AirRailSearchStore.railFilters;
  }

  @Watch('sidebarToggled', { immediate: true })
  onToggled(value) {
    this.toggled = value;
  }

  @Watch('toggled')
  onToggledClick(value) {
    if (value !== this.sidebarToggled) {
      layoutStore.toggleSidebar();
    }
  }

  showMobileFilters() {
    router.push({
      name: 'airRailFilters',
    });
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}
