import Fees from '@/modules/settings/agency-fees/Fees.vue';
import FeesConfigurations from './fees-configurations.route';
import FeesConfiguration from './fees-configuration.route';
import FeeRuleDefinitionAir from './fee-rule-definition-air.route';
import FeeRuleDefinitionRail from './fee-rule-definition-rail.route';
import FeeRuleDefinitionHotel from './fee-rule-definition-hotel.route';

export default {
  path: 'fees',
  component: Fees,
  displayName: 'settings-sidebar.fees',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    icon: 'price_change',
    breadCrumb: `Fees configurations' list`,
    parentName: 'fees',
  },
  children: [
    FeesConfigurations,
    FeesConfiguration,
    FeeRuleDefinitionAir,
    FeeRuleDefinitionRail,
    FeeRuleDefinitionHotel,
  ],
};