





















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import { router } from '@/router';
import TrainTimeline from './TrainTimeline.vue';
import TrainTimelineDetails from './TrainTimelineDetails.vue';
import TrainSearchStore from './train-search.store';
import { TrainOfferResult, Fare, Journey } from '@/api/train-engine/train-search.model';
import formatPrice from '@/filters/format-price.filter';

@Component({
  components: {
    TrainTimeline,
    TrainTimelineDetails,
  },
  filters: {
    timeFormat(date) {
      return moment(date).format('ddd Do MMM HH:mm');
    },
    dateFormat(date) {
      return moment(date).format('ddd Do MMM');
    },
  }
})
export default class TrainResultsShortRow extends Vue {
  @Prop() offer!: TrainOfferResult;
  @Prop() journey!: Journey;
  @Prop() legNumber!: number;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: false }) emitEvents!: boolean;

  showDetails: boolean = false;
  disabledInfo: string = 'Max. 3 offers can be selected';
  itemHeight: number = 0;
  detailsHeight: number = 0;
  isAnimating: boolean = false;
  animationRequest: number | null = 0;

  formatPrice(price) {
    return formatPrice(price);
  }

  get timelinesCollapsed() {
    return false;
  }

  get legOfferNumber() {
    return this.legNumber;
  }

  get numberOfLeg() {
    return this.$route.params.leg;
  }

  get offerPrice() {
    let selectedFares: Fare[] = [];
    let price = 0;
    let trains = _.uniqBy(this.journey.trains, 'segmentKey');
    trains.forEach(train => {
      this.offer.proposals.forEach(proposal => {
        if (train.segmentKey === proposal.segmentKey) {
          let fare = proposal.fares[0];
          selectedFares.push(fare);
        }
      });
    });
    if (selectedFares.length) {
      selectedFares.forEach(s => {
        if (s) {
          price += s.price.amount;
        }
      });
    }

    return price;
  }

  get uniqTrainById() {
    return _.uniqBy(this.journey.trains, 'segmentKey');
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  async editFirstLeg($event) {
    const searchId = this.$route.params.searchId;
    $event.stopPropagation();
    TrainSearchStore.stopSearch();
    TrainSearchStore.setSelectedOfferId('');
    if (this.modifyMode) {
      router.replace({
        name: 'trainModification',
        params: {
          leg: '1'
        }
      });
    } else {
      await TrainSearchStore.deleteSelectedLegAndGetSession({ searchId, legNumber: 1});
      
      if (TrainSearchStore.airRailSearchId !== null) {
        router.replace({
          name: 'airRail',
          params: {
            searchId: TrainSearchStore.airRailSearchId,
            leg: '1'
          },
        });
      } else {
        router.replace({
          name: 'train',
          params: {
            leg: '1'
          },
        });
      }
    }
    
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  updateItemHeight() {
    const el = this.$refs.item as HTMLElement;
    this.itemHeight = el.offsetHeight;
  }

  updateOnAnimation() {
    if (!this.isAnimating) {
      this.animationRequest = null;
      return;
    }

    this.animationRequest = requestAnimationFrame(this.updateOnAnimation);
    TrainSearchStore.setFiltersOffset((this.$el as HTMLElement).offsetHeight + 20);
  }

  startAnimation() {
    this.isAnimating = true;
    if (null !== this.animationRequest) {
      cancelAnimationFrame(this.animationRequest);
    }
    this.updateOnAnimation();
  }

  openingToggle(value) {
    this.detailsHeight = value - 34;
    this.startAnimation();
  }

  closingToggle(value) {
    this.detailsHeight = 0;
    this.isAnimating = true;
    this.startAnimation();
  }

  resizeToggle(value) {
    if (value) {
      this.detailsHeight = value - 34;
    } else {
      this.detailsHeight = 0;
    }
    TrainSearchStore.setFiltersOffset(this.itemHeight + this.detailsHeight + 20);
  }

  stopAnimation() {
    this.isAnimating = false;
    if (null !== this.animationRequest) {
      cancelAnimationFrame(this.animationRequest);
      this.animationRequest = null;
    }
    TrainSearchStore.setFiltersOffset(this.itemHeight + this.detailsHeight + 20);
  }

  mounted() {
    if (!this.emitEvents) {
      return;
    }
    this.updateItemHeight();
    TrainSearchStore.setFiltersOffset(this.itemHeight + this.detailsHeight + 20);
    TrainSearchStore.setFiltersShouldHaveOffset(true);
  }

  beforeDestroy() {
    TrainSearchStore.setFiltersShouldHaveOffset(false);
    TrainSearchStore.setFiltersOffset(0);
    if (null !== this.animationRequest) {
      cancelAnimationFrame(this.animationRequest);
    }
  }
}
