

















































































import { Vue, Prop, Component } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import { OverwriteTravellerNameMessage } from '@/api/air-engine/overwrite-traveller-name.model';

@Component({})
export default class BasketOverwriteTravellerNameForm extends Vue {
  @Prop({}) traveller: any;
  @Prop({}) index: any;
  Form: OverwriteTravellerNameMessage = {
    firstName: '',
    middleName: '',
    lastName: ''
  };

  userFullName(user) {
    return userFullName(user);
  }

  getCodeTranslation(code) {
    const value = searchConst.guestTravellerOptions.find(item => item.code === code);

    if (!value) {
      return '';
    }

    return translate(value.label);
  }

  onStoreChangedNames() {
    BasketStore.saveOverwrittenTravellersNames({
      id: this.traveller.id,
      ...this.Form
    });
  }

  created() {
    this.Form.firstName = this.traveller.firstName;
    this.Form.middleName = this.traveller.middleName;
    this.Form.lastName = this.traveller.lastName;
    EventBus.$on('basket-save-overwritten-travellers-names', this.onStoreChangedNames);
  }

  beforeDestroy() {
    EventBus.$off('basket-save-overwritten-travellers-names', this.onStoreChangedNames);
  }
}
