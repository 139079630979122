import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { Permission } from '@/const/permission.enum';
import { VirtualCardsConfiguration } from './virtual-cards.model';

export class VirtualCardsConfigurationApiClass {
  public createConfiguration(requestMessage: VirtualCardsConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/virtual-card-configurations',
      method: RequestMethod.POST,
      permission: Permission.CanEditVirtualCards,
    }, {}, requestMessage );
  }

  public getConfiguration(configurationId: string): Promise<AxiosResponse<VirtualCardsConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/virtual-card-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateConfiguration(message: VirtualCardsConfiguration): Promise<AxiosResponse<VirtualCardsConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/virtual-card-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, { configurationName: message.configurationName, airPlusSecretKey: message.airPlusSecretKey });
  }

}

export const VirtualCardsConfigurationApi: VirtualCardsConfigurationApiClass = new VirtualCardsConfigurationApiClass();