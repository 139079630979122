import moment from 'moment';

import formatTime from '@/filters/format-time.filter';

export const technicalStopTooltip = (stop) => {
  return [
    '<div>',
    '<strong>',
    formatTime(stop.arrival),
    '</strong> ',
    moment(stop.arrival).format('Do MMM'),
    ' - ',
    ' <strong>',
    formatTime(stop.departure),
    '</strong> ',
    moment(stop.departure).format('Do MMM'),
    '</div>',
    '<div>',
    stop.location.airportName,
    ' ',
    stop.location.airportCode,
    stop.location.cityName ? ', ' : '',
    stop.location.cityName,
    stop.location.country ? ', ' : '',
    stop.location.country,
    '</div>',
  ].join('');
};
