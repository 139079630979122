import roles from '@/router/routes/settings/roles.route';
import companyInfo from '@/router/routes/settings/company.route';
import companyStructure from '@/router/routes/settings/company-structure/company-structure.route';
import community from '@/router/routes/settings/community.route';
import airProviderConfiguration from '@/router/routes/settings/air-provider-configuration.route';
import railProviderConfiguration from '@/router/routes/settings/rail-provider-configuration.route';
import carProviderConfiguration from '@/router/routes/settings/car-provider-configuration.route';
import accommodationProviderConfiguration from '@/router/routes/settings/accommodation-provider-configuration.route';
import travelPolicy from '@/router/routes/settings/travel-policy.route';
import displayPreferences from '@/router/routes/settings/display-preferences.route';
import approvalWorkflow from '@/router/routes/settings/approval-workflow/approval-workflow.route';
import delayTicketing from '@/router/routes/settings/delay-ticketing/delay-ticketing.route';
import theme from '@/router/routes/settings/theme/theme.route';
import negoFareConfiguration from '@/router/routes/settings/nego-fare-configuration.route';
import providersNotifications from '@/router/routes/settings/providers-notifications/providers-notifications.route';
import billing from '@/router/routes/settings/billing/billing.route';
import billingCustomFields from '@/router/routes/settings/billing-custom-fields/billing-custom-fields.route';
import customFields from '@/router/routes/settings/custom-fields-configuration/custom-fields.route';
import projects from '@/router/routes/settings/projects-configuration/projects.route';
import expensePolicy from '@/router/routes/settings/expense-configuration/expense.route';
import customers from '@/router/routes/settings/customers.route';
import paymentMethodsConfiguration from '@/router/routes/settings/payment-methods-configuration.route';
import pnrRemarks from '@/router/routes/settings/pnr-remarks/pnr-remarks.route';
import lodgeCards from '@/router/routes/settings/lodge-cards/lodge-cards.route';
import profileSync from '@/router/routes/settings/profile-sync/profile-sync.route';
import fees from '@/router/routes/settings/agency-fees/fees.route';
import markups from '@/router/routes/settings/agency-markup/markups.route';
import systemMarkup from '@/router/routes/settings/system-markup/system-markup.route';
import plugins from '@/router/routes/settings/plugins/plugins.route';
import security from '@/router/routes/settings/security/security.route';
import clientEmailNotificationsRoute from '@/router/routes/settings/client-email-notifications/client-email-notifications.route';
import missedSavingsRoute from '@/router/routes/settings/missed-savings/missed-savings.route';
import faresMapping from '@/router/routes/settings/fares-mapping/fares-mapping.route';
import paymentGateway from '@/router/routes/settings/payment-gateway/payment-gateway.route';
import virtualCards from '@/router/routes/settings/virtual-cards/virtual-cards.route';
import currencyExchange from '@/router/routes/settings/currency-exchange/currency-exchange.route';
import travellerCategories from '@/router/routes/settings/traveller-categories/traveller-categories-wrapper.route';
import importProfiles from '@/router/routes/settings/import-profiles.route';

export default {
  menuGroups: [
    {
      name: 'company',
      displayName: 'settings-sidebar-groups.company',
      icon: 'home',
      items: [
        companyInfo,
        companyStructure,
        community,
        travellerCategories,
        roles,
        customers,
        theme,
        plugins,
        profileSync,
        security,
      ],
    },
    {
      name: 'providers',
      displayName: 'settings-sidebar-groups.providers',
      icon: 'device_hub',
      items: [
        airProviderConfiguration,
        railProviderConfiguration,
        accommodationProviderConfiguration,
        carProviderConfiguration,
        negoFareConfiguration,
        faresMapping,
        providersNotifications,
      ],
    },
    {
      name: 'business-rules',
      displayName: 'settings-sidebar-groups.business-rules',
      icon: 'rule_folder',
      items: [
        travelPolicy,
        approvalWorkflow,
        displayPreferences,
        customFields,
        billing,
        billingCustomFields,
        pnrRemarks,
        fees,
        markups,
        systemMarkup,
        clientEmailNotificationsRoute,
        missedSavingsRoute,
        delayTicketing,
      ],
    },
    {
      name: 'payments',
      displayName: 'settings-sidebar-groups.payments',
      icon: 'paid',
      items: [
        paymentMethodsConfiguration,
        lodgeCards,
        paymentGateway,
        currencyExchange,
        virtualCards
      ],
    },
    {
      name: 'expense',
      displayName: 'settings-sidebar-groups.expense',
      icon: 'account_balance_wallet',
      items: [
        projects,
        expensePolicy,
      ],
    },
    {
      name: 'imports',
      displayName: 'settings-sidebar-groups.imports',
      icon: 'upload',
      items: [
        importProfiles,
      ],
    },
  ],
};
