






































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import AirFeesStore from '@/modules/settings/agency-fees/fees-configuration.store';
import SettingsStore from '@/modules/settings/settings.store';
import { Permission } from '@/const/permission.enum';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class FeesConfigurations extends BaseTable<any> {
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  serverErrorsMessages = {
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  serverErrors: any = [];
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  get addNewConfigurationUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/fees/new/fees-configuration');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/fees/' + item.id + '/fees-configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;
      AirFeesStore.setConfigurationName(item.name);
      AirFeesStore.setConfigurationId(item.id);
      if (!preventRedirect) {
        router.push({
          name: 'fees-configuration',
          params: {
            configurationId: item.id
          }
        });  
      }
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  resetData() {
    AirFeesStore.clearData();
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  @Watch('currentCompany')
  async companyChange(value) {
    this.company = value;
    await this.getItems();
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id);
      await this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-approval.configuration-removed'),
        message: translate('settings-approval.info-removed')
      });
    }
    catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    }
    finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    this.serverErrors = [];
    let request = {
      searchName: this.Form.searchName,
    };

    try {
      this.loading = true;
      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.feesConfiguration,
        this.params.size,
        this.params.page,
        Permission.CanEditFeesConfiguration,
        request.searchName
      );
      if (result && result.data) {
        this.items = result.data.results;
        
        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.name < b.name) {
        result = -1;
      }
      else if (a.name > b.name) {
        result = 1;
      }
      return result;
    });
  }

  toggleFeesConfigurationMenu(item) {
    if (!this.configuration || this.configuration.id !== item.id) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.configuration = item;
      this.menuActive = !this.menuActive;
    }
  }

  created() {
    this.configuration = null;
  }
}

