



































































































import { Vue, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';
import AirRefundPopup from '@/modules/search/air/AirRefundPopup.vue';
import BasketAirMissedSavingsPopup from '@/modules/search/air/BasketAirMissedSavingsPopup.vue';
import BasketItemPriceChangePopup from './BasketItemPriceChangePopup.vue';
import BasketItemCarPriceChangePopup from './BasketItemCarPriceChangePopup.vue';
import BasketAirSabreRefundPopup from './BasketAirSabreRefundPopup.vue';
import BasketRetryInfoPopup from './BasketRetryInfoPopup.vue';
import BasketLufthansa3DSPopup from './BasketLufthansa3DSPopup.vue';
import BasketAirLufthansaRefundPopup from './BasketAirLufthansaRefundPopup.vue';
import BasketAirFranceKlmRefundPopup from './BasketAirFranceKlmRefundPopup.vue';
import BasketAirBritishAirwaysRefundPopup from './BasketAirBritishAirwaysRefundPopup.vue';
import BasketDiscountAppliedPopup from './BasketDiscountAppliedPopup.vue';
import BasketAirAmadeusRefundPopup from './BasketAirAmadeusRefundPopup.vue';
import TripActionErrorPopup from './TripActionErrorPopup.vue';
import BasketAirTravelFusionRefundPopup from './BasketAirTravelFusionRefundPopup.vue';
import BasketPaymentTerminatedPopup from './BasketPaymentTerminatedPopup.vue';
import BasketExpedia3DSPopup from './BasketExpedia3DSPopup.vue';
import BasketApprovalConfirmationPopup from './BasketApprovalConfirmationPopup.vue';
import BasketChangeApproverPopup from './BasketChangeApproverPopup.vue';
import BasketEditTrainItemFeePopup from './BasketEditTrainItemFeePopup.vue';
import BasketEditTrainItemAgencyMarkupPopup from './BasketEditTrainItemAgencyMarkupPopup.vue';
import BasketEditHotelFeePopup from './BasketEditHotelFeePopup.vue';
import BasketEditHotelAgencyMarkupPopup from './BasketEditHotelAgencyMarkupPopup.vue';
import BasketRejectionReasonPopup from './BasketRejectionReasonPopup.vue';
import BasketUnconfirmedNotificationsPopup from './BasketUnconfirmedNotificationsPopup.vue';

@Component({
  components: {
    AirRefundPopup,
    BasketItemPriceChangePopup,
    BasketItemCarPriceChangePopup,
    BasketRetryInfoPopup,
    BasketLufthansa3DSPopup,
    BasketAirSabreRefundPopup,
    BasketAirLufthansaRefundPopup,
    BasketAirFranceKlmRefundPopup,
    BasketAirBritishAirwaysRefundPopup,
    BasketDiscountAppliedPopup,
    BasketAirAmadeusRefundPopup,
    TripActionErrorPopup,
    BasketAirTravelFusionRefundPopup,
    BasketPaymentTerminatedPopup,
    BasketExpedia3DSPopup,
    BasketApprovalConfirmationPopup,
    BasketChangeApproverPopup,
    BasketEditTrainItemFeePopup,
    BasketEditTrainItemAgencyMarkupPopup,
    BasketEditHotelFeePopup,
    BasketEditHotelAgencyMarkupPopup,
    BasketRejectionReasonPopup,
    BasketUnconfirmedNotificationsPopup,
    BasketAirMissedSavingsPopup,
  },
})
export default class BasketPopups extends Vue {

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.refundedItem.id;
    });
  }

  get items() {
    return BasketStore.basketItemsMapped;
  }

  get isSabre() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'Sabre';
  }

  get isAmadeus() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'Amadeus';
  }

  get isLufthansa() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'Lufthansa';
  }

  get isAmericanAirlines() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'AmericanAirlines';
  }

  get isBritishAirways() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'BritishAirways';
  }

  get isEmirates() {
    return this.item && this.item.type === 'Air' &&
        this.item.supplier === 'Emirates';
  }

  get isTravelFusion() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'TravelFusion';
  }

  get isAirFranceKlm() {
    return this.item && this.item.type === 'Air' &&
      this.item.supplier === 'AirFranceKlm';
  }

  get refundedItem() {
    return BasketStore.refundedItem;
  }

  get showTripActionErrorPopup() {
    return BasketStore.showTripActionErrorPopup;
  }

  set showTripActionErrorPopup(value) {
    BasketStore.setShowTripActionErrorPopup(value);
  }

  get showing3DSInterruptedPopup() {
    return BasketStore.showing3DSInterruptedPopup;
  }

  get showRefundDataPopup() {
    return BasketStore.showRefundDataPopup;
  }

  get showPaymentTerminatedPopup() {
    return BasketStore.showPaymentTerminatedPopup && BasketStore.paymentTerminatedBasketId === this.$route.params.id;
  }

  set showRefundDataPopup(value) {
    BasketStore.setShowRefundDataPopup(value);
  }

  get showPriceChangePopup() {
    return BasketStore.showPriceChangePopup;
  }

  get showCarPriceChangePopup() {
    return BasketStore.showCarPriceChangePopup;
  }

  get showBasketExpedia3DSPopup() {
    return BasketStore.showBasketExpedia3DSPopup;
  }

  get showRetryPopup() {
    return BasketStore.showRetryPopup;
  }

  get showRejectionReasonPopup() {
    return BasketStore.showRejectionReasonPopup;
  }

  set showRejectionReasonPopup(value) {
    BasketStore.setShowRejectionReasonPopup(value);
  }

  get discountAppliedPopup(): boolean {
    return BasketStore.basketDicountAppliedPopupVisible;
  }

  set discountAppliedPopup(value) {
    BasketStore.updateBasketDicountAppliedPopupVisible(value);
  }

  get showApprovalConfirmationPopup() {
    return BasketStore.showApprovalConfirmationPopup && BasketStore.basketApprovalDecision && BasketStore.basketApprovalAction;
  }

  get showChangeApproverPopup() {
    return BasketStore.showChangeApproverPopup;
  }
  
  get showEditTrainFeePopup() {
    return BasketStore.showEditTrainFeePopup;
  }

  set showEditTrainFeePopup(value) {
    BasketStore.setShowEditTrainFeePopup(value);
  }

  get showEditTrainAgencyMarkupPopup() {
    return BasketStore.showEditTrainAgencyMarkupPopup;
  }

  set showEditTrainAgencyMarkupPopup(value) {
    BasketStore.setShowEditTrainAgencyMarkupPopup(value);
  }

  get showEditHotelFeePopup() {
    return BasketStore.showEditHotelFeePopup;
  }

  set showEditHotelFeePopup(value) {
    BasketStore.setShowEditHotelFeePopup(value);
  }

  get showEditHotelAgencyMarkupPopup() {
    return BasketStore.showEditHotelAgencyMarkupPopup;
  }

  set showEditHotelAgencyMarkupPopup(value) {
    BasketStore.setShowEditHotelAgencyMarkupPopup(value);
  }

  get showUnconfirmedNotificationsPopup() {
    return BasketStore.showUnconfirmedNotificationsPopup;
  }

  set showUnconfirmedNotificationsPopup(value) {
    BasketStore.setShowUnconfirmedNotificationsPopup(value);
  }
}

