






































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { CustomFieldsProfileApi } from '@/api/custom-fields/custom-fields-profile.api';
import { ProfileCustomFieldsAccessType, UpdateCustomFieldMethodType } from '@/api/custom-fields/custom-fields-profile.model';
import EditMultipleCustomFieldValue from '@/modules/profile/custom-fields/EditMultipleCustomFieldValue.vue';
import ProfileStore from '@/modules/profile/profile.store';

@Component({
  components: {
    EditMultipleCustomFieldValue,
  },
})
export default class ProfileApproverCustomFields extends Vue {
  loading: boolean = true;
  showEditField: boolean = false;
  isVisible: boolean = false;
  errors: any[] = [];
  profileFields: any[] = [];
  accessLevel: ProfileCustomFieldsAccessType = ProfileCustomFieldsAccessType.NoAccess;
  updateCustomFieldMethodType: UpdateCustomFieldMethodType = UpdateCustomFieldMethodType.ApproverCustomFields;
  selectedField: any = null;

  get canShowSection() {
    return this.isVisible &&
      this.$hasAccess('ReadProfileCustomFields');
  }

  get canEdit() {
    return this.$hasAccess('CanAssignApprovalCustomFieldsToProfile');
  }

  get fields() {
    return this.profileFields.map(field => {
      let profileValues: any = {};

      if (
        (field.profileValue === '' || field.profileValue === null) &&
        field.customField.typeName === 'Dictionary' &&
        field.profileDictionaryItemId === null
      ) {
        profileValues = {
          profileValue: '',
          profileDictionaryItemId: null,
        };
      } else if (field.customField.typeName !== 'Dictionary' && field.profileValue === null) {
        profileValues = {
          profileValue: '',
        };
      }

      return {
        ...field,
        ...profileValues,
      };
    }).sort(this.compareFieldByOrderAndName);
  }

  updateField(field) {
    this.selectedField = {
      ...field,
    };
    this.showEditField = true;
  }

  compareFieldByOrderAndName(f1, f2) {
    if (f1.order > f2.order) {
      return 1;
    }
    if (f1.order < f2.order) {
      return -1;
    }
    if (f1.customField.name.toLowerCase() > f2.customField.name.toLowerCase()) {
      return 1;
    }
    if (f1.customField.name.toLowerCase() < f2.customField.name.toLowerCase()) {
      return -1;
    }
    return 0;
  }

  async reloadItems() {
    ProfileStore.setHasAccessToApprovalWorkflow(false);
    this.errors = [];
    this.profileFields = [];
    try {
      const result = await CustomFieldsProfileApi
        .getProfileApproverCustomFields(this.$route.params.id);
      
      this.profileFields = result.data.getProfileCustomFieldValues;
      this.accessLevel = result.data.accessLevel;
      this.isVisible = result.data.accessLevel !== ProfileCustomFieldsAccessType.NoAccess;

      if (this.isVisible) {
        ProfileStore.setHasAccessToApprovalWorkflow(true);
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  fieldValues(field) {
    return field.values.map(value => value.value).join(', ');
  }

  @Watch('$route', { immediate: true , deep: true})
  onchangeRoute() {
    this.reloadItems();
  }
}
