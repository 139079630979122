



































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { translate } from '@/i18n';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';

@Component({})
export default class NegoFarePnrSsrCodes extends Vue {
  @Prop({}) codes!: any;
  @Prop({}) supplier!: string;
 

  $v;
  allAirline: any[] = [];
  airlines: any[] = [];
  sabreCodeTypes: any[] = ['CLID', 'OSI'];

  @Validation()
  validationObject() {
    const cantBeSame = (value, model) => {
      const entryCheck = entry => entry.type === model.type &&
          entry.value === model.value;
      const firstIndex = this.entries.findIndex(entryCheck);
      const lastIndex = this.entries.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };
    return {
      entries: {
        $each: {
          type: {
            required,
            maxLength: maxLength(10),
            cantBeSame,
          },
          value: {
            required,
            maxLength: maxLength(64),
            cantBeSame,
          },
        }
      }
    };
  }

  get entries() {
    const codes = this.codes ? this.codes : [];
    return codes;
  }

  get isAmadeus() {
    return this.supplier === 'Amadeus';
  }

  get isSabre() {
    return this.supplier === 'Sabre';
  }



  airlinesMaper() {
    this.airlines = this.allAirline = DictionaryStore.allAirLinesSorted;
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  newCodeDisabled() {
    return this.entries[this.entries.length - 1] && this.entries[this.entries.length - 1].type && this.entries[this.entries.length - 1].value ?
      !this.entries[this.entries.length - 1].type.length && !this.entries[this.entries.length - 1].value.length :
      this.entries.length > 0;
  }

  newCode() {
    if (!this.newCodeDisabled()) {
      this.entries.push({
        airlines: null,
        type: '',
        value: '',
      });
      this.$emit('change', this.entries);
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  deleteCode(index) {
     if (this.entries.length > 0) {
      if (-1 < index) {
        this.entries.splice(index, 1);
      }
      this.$emit('change', this.entries);
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  update() {
    this.$emit('change', this.entries);
  }

  created() {
    this.airlinesMaper();
  }
}
