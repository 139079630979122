














































































import _ from 'lodash';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { translate } from '@/i18n';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { IProfileLoyaltiesCard } from '@/api/profile/loyalties.model';
import EventHandler from '@/services/event-handler';
import LoyaltyPopup from './LoyaltyPopup.vue';
import LoyaltyRemovePopup from './LoyaltyRemovePopup.vue';
import { getLoyaltyName } from '@/modules/profile/loyalty-programs/loyalty-name.service';

@Component({
  components: {
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
export default class LoyaltyPrograms extends Vue {
  @Prop({ default: '' }) profileId!: string;
  @Prop({ default: '' }) serviceType!: string;
  @Prop({ default: '' }) loyaltyType!: string;
  showLoyaltyCardPopup: boolean = false;
  deleteLoyaltyCardPopup: boolean = false;
  editingCard: IProfileLoyaltiesCard | null = null;
  formPending: boolean = false;
  serverErrors: any[] = [];
  availableLoyalties: IProfileLoyaltiesCard[] = [];

  get availableDocumentByTypes() {
    return _.uniqBy(this.availableLoyalties, 'loyalty.serviceType');
  }

  getLoyaltyTypeName(name: string) {
    const type = name.toLowerCase();
    return translate('profile-loyalty.' + type);
  }

  showAddNewLoyaltyCardPopup() {
    this.editingCard = null;
    this.showLoyaltyCardPopup = true;
  }

  filterLoyaltiesByType(type: IProfileLoyaltiesCard) {
    return this.availableLoyalties.filter((doc) => doc.loyalty.serviceType === type.loyalty.serviceType);
  }

  async loadProfileLoyalties() {
    try {
      const profileId = this.$route.params.id;
      const result = await LoyaltiesApi.getProfileloyaltiesCard(profileId);
      if (result && result.data) {
        this.availableLoyalties = result.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  editLoyalties(card: IProfileLoyaltiesCard) {
    this.editingCard = card;
    this.showLoyaltyCardPopup = true;
  }

  removeLoyalties(card: IProfileLoyaltiesCard) {
    this.editingCard = card;
    this.deleteLoyaltyCardPopup = true;
  }

  getLoyaltyName = getLoyaltyName;

  onReceive() {
    this.loadProfileLoyalties();
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    this.serverErrors = [];
    this.loadProfileLoyalties();
  }

  created() {
    EventHandler.$on('reload-loyalty', this.onReceive);
  }

  beforeDestroy() {
    EventHandler.$off('reload-loyalty', this.onReceive);
  }
}

