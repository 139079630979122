










































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from './basket.store';
import {
  CancellationMethods,
  RefundDecisionMessage,
  RefundActionStatuses,
} from '@/modules/search/air/air-refund.model';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import BasketCancellationStore from './basket-cancellation.store';
import BasketAirLufthansaRefundView from './BasketAirLufthansaRefundView.vue';
import AirResultsRow from '@/modules/search/air/AirResultsRow.vue';
import BasketAirOffer from './BasketAirOffer.vue';
import settings from '@/settings';
import { userFullName } from '@/core/user-full-name';

const eventName = 'update:show';

@Component({
  components: {
    BasketAirLufthansaRefundView,
    AirResultsRow,
    BasketAirOffer,
  }
})
export default class BasketAirLufthansaRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;
  @Prop() recomedationId!: string;

  selectedOption: any = '';
  refundDetails: any[] = [];
  taxBreakdowns: any[] = [];
  isCommissionValid: boolean = true;
  shouldShowCommissionErrors: boolean = false;
  refundErrors: any[] = [];

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get isRefundAlwaysAvailable() {
    return 'true' === settings.isRefundAlwaysAvailable;
  }

  get couponsStatus() {
    return BasketCancellationStore.couponsStatus;
  }

  get loadingCoupons() {
    return BasketCancellationStore.loadingCoupons;
  }

  get couponsLoaded() {
    return BasketCancellationStore.couponsLoaded;
  }

  get processing() {
    return BasketCancellationStore.processing;
  }

  get loadingDetails() {
    return BasketCancellationStore.loadingDetails;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get isMobile() {
    return window.innerWidth <= 799.98;
  }

  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get processErrors() {
    return BasketCancellationStore.processErrors;
  }

  get statusCount() {
    return this.couponsStatus.length;
  }

  get canCancelTicket() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get canRefundFlightTicket() {
    return AccountStore.HasPermission('CanRefundFlightTicket');
  }

  get isVoidAvailable() {
    if ('Exchanged' === this.item.statusTag && ['Lufthansa', 'AmericanAirlines', 'Emirates'].indexOf(this.item.supplier) > -1) {
      return false;
    }

    if (!this.isValidLhCouponStatus) {
      return false;
    }

    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get isRefundAvailable() {
    if (this.isRefundAlwaysAvailable) {
      return true;
    }

    if (!this.isValidLhCouponStatus) {
      return false;
    }

    for (const status of this.couponsStatus) {
      if (!status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get isVoidAvailableWithoutCouponStatus() {
    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get lufthansaCancelationsDetails() {
    return BasketCancellationStore.lufthansaCancelationDetails;
  }

  get lufthansaRefundDetailsLoaded() {
    return BasketCancellationStore.lufthansaRefundDetailsLoaded;
  }

  get showError() {
    return BasketCancellationStore.showError;
  }

  get airDetailsInfo() {
    return BasketCancellationStore.airDetailsInfo;
  }

  get isValidLhCouponStatus() {
    const coupon = this.couponsStatus.find(item => item.isValidLhCouponStatus !== null);
    if (!coupon) {
      return true;
    }
    return coupon.isValidLhCouponStatus;
  }

  userFullName(user) {
    return userFullName(user);
  }

  toggleTaxBreakdown(index, name) {
    this.taxBreakdowns[index][name] = !this.taxBreakdowns[index][name];
    this.$forceUpdate();
  }

  issueDateTime(date) {
    return moment.parseZone(date).format('DD/MM/YYYY, ') + '23:59:59 (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  @Watch('actionType', { immediate: true })
  onChangeAction(val) {
    if (-1 < [
      RefundActionStatuses.LHGCheckConditions,
    ].indexOf(val.code)) {
      this.loadRefundDetails();
      BasketCancellationStore.setProcessing(false);
    } else if (val.code === RefundActionStatuses.VerifyStatus) {
      this.init();
      BasketCancellationStore.setProcessing(false);
    }
  }

  async beginProcess() {
    switch (this.selectedOption) {
      case CancellationMethods.void:
        BasketCancellationStore.setProcessErrors([]);
        BasketCancellationStore.setProcessWarning(null);
        BasketCancellationStore.setShowError(false);
        BasketCancellationStore.setProcessing(true);
        let voidResponse;
        try {
          voidResponse = await RefundBasketItemApi.startVoid(this.basketItemId);
        } catch (error) {
          BasketCancellationStore.setProcessErrors(error);
          BasketCancellationStore.setShowError(true);
        } finally {
          if (voidResponse && voidResponse.status === 204) {
            EventBus.$emit('refresh-basket-status');
            this.hidePopup();
          }
          BasketCancellationStore.setProcessing(false);
        }
      break;
      case CancellationMethods.refund:
        BasketCancellationStore.setProcessErrors([]);
        BasketCancellationStore.setProcessWarning(null);
        BasketCancellationStore.setShowError(false);
        BasketCancellationStore.setProcessing(true);
        let initResponse;
        BasketCancellationStore.setCouponsLoaded(false);
        try {
          initResponse  = await RefundBasketItemApi.startRefund(this.basketItemId);
        } catch (error) {
          BasketCancellationStore.setProcessErrors(error);
          BasketCancellationStore.setShowError(true);
          BasketCancellationStore.setProcessing(false);
        } finally {
          if (initResponse && initResponse.status === 204) {
            EventBus.$emit('refresh-basket-status');
          }
        }
      break;
    }
  }

  async loadRefundDetails() {
    await BasketCancellationStore.getOfferCancelationConditions(this.recomedationId);
  }

  async finalizeRefund() {
    this.refundErrors = [];
    BasketCancellationStore.setProcessing(true);
    let refundResponse;
    try {
      refundResponse = await RefundBasketItemApi
        .acceptRefundCancellationConditions(this.basketItemId);
    } catch (error) {
      this.refundErrors = this.$handleErrors(error);
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      BasketCancellationStore.setProcessing(false);
    }
  }

  async exitRefundPopup() {
    this.refundErrors = [];
    let refundResponse;
    BasketCancellationStore.setProcessing(true);
    try {
      refundResponse = await RefundBasketItemApi.rejectRefund(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');

      }
      BasketCancellationStore.setProcessing(false);
      this.hidePopup();
    }
  }

  async answerRefundConditions(decision: boolean) {
    const message: RefundDecisionMessage = {
      itemId: this.basketItemId,
      isConfirmed: decision
    };
    try {
      await RefundBasketItemApi.sendCouponDecision(message);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async answerRefundConditionsErrors() {
    try {
      await RefundBasketItemApi.answerCouponDecisionError(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async init() {
    BasketCancellationStore.setLufthansaCancelationDetails(null);
    BasketCancellationStore.setLufthansaRefundDetailsLoaded(false);
    await BasketCancellationStore.getAirDetails(this.item.providerReferenceId);
    await BasketCancellationStore.getItemCoupons(this.basketItemId);

    if (this.isVoidAvailable) {
      this.selectedOption = 'Void';
    } else if (this.isRefundAvailable) {
      this.selectedOption = 'Refund';
    } else {
      this.selectedOption = '';
    }
  }

  @Watch('commissionError')
  onCommissionErrorChange(val) {
    this.isCommissionValid = !val;
  }

  created() {
    BasketCancellationStore.setProcessing(false);
    this.init();
  }

  beforeDestroy() {
    BasketCancellationStore.setProcessErrors([]);
    BasketCancellationStore.setProcessWarning(null);
    BasketCancellationStore.setShowError(false);
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

