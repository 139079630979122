
















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { Recommendation } from '@/api/air-engine/air-search.model';

@Component({
})
export default class AirPriceDetails extends Vue {
  @Prop() offer!: Recommendation;
  @Prop() travellers!: any;
  @Prop() show!: boolean;

  get travellersMapped() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    return this.travellers.map(traveller => {
      const fare = this.offer.proposal.passengerPrices
        .find(p => p && p.profileId === traveller.id);

      return {
        ...traveller,
        fare,
      };
    });
  }



  userFullName(user) {
    return userFullName(user);
  }

  @Emit('update:show')
  hide() {
    return false;
  }
}

