












































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import CustomFieldDefinitions from './CustomFieldDefinitions.vue';
import CustomFieldDictionaries from './CustomFieldDictionaries.vue';
import TravelAssignments from './TravelAssignments.vue';
import ProviderMappings from './ProviderMappings.vue';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

@Component({
  components: {
    CustomFieldDefinitions,
    CustomFieldDictionaries,
    TravelAssignments,
    ProviderMappings,
  }
})
export default class CustomFieldConfiguration extends Vue {
  $v;
  tableFields = {
    name: {
      sortable: true,
      label: 'name'
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  currentPage: number = 1;
  selectedField: any | null = null;
  fieldMenuActive: boolean = false;
  formHasErrors: boolean = false;
  loadingGif: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      configuration: {
        name: {
          required,
          maxLength: maxLength(64),
        }
      }
    };
  }

  get errors() {
    return CustomFieldsStore.getErrMessages;
  }

  get activeTab() {
    return CustomFieldsStore.activeTab;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get fields() {
    return CustomFieldsStore.customFields;
  }

  get dictionaries() {
    return CustomFieldsStore.dictionaries;
  }

  get loading() {
    return CustomFieldsStore.loading;
  }

  get configIsEdit() {
    return !!this.configuration.id;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get canReadNotWrite() {
    return this.$hasAccess('ReadCustomFieldDefinitions') && !this.$hasAccess('WriteCustomFieldDefinitions');
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get canShowCustomError() {
    return CustomFieldsStore.canShowCustomError;
  }

  setFieldsTab() {
    CustomFieldsStore.setFieldsTab();
  }

  setDictionariesTab() {
    CustomFieldsStore.setDictionariesTab();
  }

  setTravelAssignmentsTab() {
    CustomFieldsStore.setTravelAssignmentsTab();
  }

  setProviderMappingsTab() {
    CustomFieldsStore.setProviderMappingsTab();
  }

  goToFieldDefinition() {
    this.checkForm();
    if (!this.formHasErrors) {
      let params: any = {
        id: this.$route.params.id
      };
      if (this.configurationId !== undefined) {
        params.configurationId = this.configurationId;
      }
      router.push({
        name: 'field-definition',
        params,
      });
    }
  }

  addCustomFieldDefinition() {
    CustomFieldsStore.clearFieldData();
  }

  goToTravelAssignment() {
    this.checkForm();
    if (this.formHasErrors) {
      return;
    }
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'travel-assignment',
      params,
    });
  }

  goToProviderMapping() {
    this.checkForm();

    if (this.formHasErrors) return;

    let params: any = {
      id: this.$route.params.id
    };

    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'provider-mapping',
      params
    });
  }

  addDictionaryData() {
    CustomFieldsStore.clearDictionaryData();
  }

  goToDictionary() {
    this.checkForm();
    if (!this.formHasErrors) {
      let params: any = {
        id: this.$route.params.id
      };
      if (this.configurationId !== undefined) {
        params.configurationId = this.configurationId;
      }
      router.push({
        name: 'dictionary-definition',
        params,
      });
    }
  }

  returnToConfigurations() {
    router.push({
      name: 'fields-configuration',
      params: {
        id: this.$route.params.id,
      }
    });
  }

  copyConfiguration() {
    this.checkForm();
    if (!this.formHasErrors) {
      CustomFieldsStore.copyCustomFieldConfiguration({ configurationId: this.configuration.id, configurationName: this.configuration.name });
      if (!this.errors.length) {
        if (!this.errors.length) {
         const obj = {
             type: translate('common.success'),
             title: translate('settings-custom-fields.configuration-copied'),
             message: translate('settings-custom-fields.configuration-copied-message')
           };
           EventHandler.$emit('show-toast', obj);

           this.returnToConfigurations();
        }
      }
    }
  }

  saveData() {
    this.checkForm();
    if (!this.formHasErrors) {
      if (this.configIsEdit) {
        CustomFieldsStore.updateCustomFieldConfiguration({ configurationId: this.configurationId, name: this.configuration.name });
        if (!this.errors.length) {
         const obj = {
             type: translate('common.success'),
             title: translate('settings-custom-fields.configuration-updated'),
             message: translate('settings-custom-fields.configuration-updated-message')
           };
           EventHandler.$emit('show-toast', obj);
        }
      } else {
        CustomFieldsStore.createCustomFieldConfiguration({ rootCompanyId: this.currentCompany!.rootCompanyId, name: this.configuration.name });
        if (!this.errors.length) {
          const obj = {
             type: translate('common.success'),
             title: translate('settings-custom-fields.configuration-saved'),
             message: translate('settings-custom-fields.configuration-saved-message')
           };
           EventHandler.$emit('show-toast', obj);
        }
      }
    }
  }

  checkForm() {
    this.formHasErrors = false;
    this.$v.configuration.$touch();
    if (this.$v.configuration.$pending || this.$v.configuration.$error) {
      this.formHasErrors = true;
    }
  }

  created() {
    CustomFieldsStore.setCustomError(false);
    CustomFieldsStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      CustomFieldsStore.clearData();
      if (!this.configuration.name) {
        CustomFieldsStore.getCustomFieldConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      CustomFieldsStore.getConfigurationCustomFields({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId});
      CustomFieldsStore.getConfigurationCustomFieldDictionaries({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId});
      CustomFieldsStore.getTravelAssignments(this.$route.params.configurationId);
      CustomFieldsStore.getProviderMappings(this.$route.params.configurationId);
    }
    CustomFieldsStore.getCustomFieldTypes();
    CustomFieldsStore.getCustomFieldCollectionModes();
  }
}
