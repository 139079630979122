





























































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import uniqueId from 'lodash/uniqueId';
import { IRestrictChoice, LoyaltyForm } from '@/api/profile/loyalties.model';
import { ServiceTypes } from '@/const/loyalty-const';
import { translate } from '@/i18n';
import { FormComponent, getComponentByType } from '@/modules/profile/loyalty-programs/forms';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import EventHandler from '@/services/event-handler';
import BasketStore from '@/modules/basket/basket.store';
import { BasketItemType } from '@/api/trip/trip.model';

@Component({})
export default class LoyaltyPopup extends Vue {
  @Prop() card!: any;
  @Prop() profileId!: string;
  @Prop() isGuestMode!: boolean;
  @Prop({ default: () => ([]) }) restrictChoices!: IRestrictChoice[];

  ServiceTypes = ServiceTypes;
  Form: LoyaltyForm = new LoyaltyForm();
  tempId: string = uniqueId();
  serviceType: string | null = null;
  formPending: boolean = false;
  serverErrors: any[] = [];
  $v;

  @Emit()
  close() {}

  @Validation()
  validationObject() {
    return {
      serviceType: { required },
    };
  }

  get hasRestrictedServiceType() {
    return !!this.restrictChoices.find(e => e.key === 'serviceType');
  }

  get airFieldValueError() {
    const error = this.serverErrors.find(({ code }) => code === 'FIELD_VALUE_ERROR');
    return this.serviceType === BasketItemType.Air && error;
  }

  getComponentByType = getComponentByType;

  serviceTypeSelect() {
    this.Form = new LoyaltyForm();
  }

  async save() {
    this.$v.$touch();
    if (this.$v.$error || !this.serviceType) {
      return;
    }
    const profileId = this.profileId ? this.profileId : this.$route.params.id;
    const formComponent = FormComponent[this.serviceType];
    const isValid = (this.$refs.formComponent as typeof formComponent).isValid();
    if (isValid && this.isGuestMode) {
      const loyalty = {
        ...this.Form,
        tempId: this.tempId,
      };
      BasketStore.updateGuestBasketTravellerLoyalty({ id: profileId, loyalty });
      this.close();
      return;
    }
    if (isValid && this.Form.loyaltyId) {
      try {
        this.formPending = true;
        if (this.card) {
          const { loyaltyId, ...form } = this.Form;
          await LoyaltiesApi.updateLoyaltyCard(this.card.id, form);
        } else {
          await LoyaltiesApi.createLoyaltyCard(profileId, this.Form);
        }
        EventHandler.$emit('show-toast', {
          type: 'success',
          title: translate('common.saved'),
          message: translate('profile-loyalty.loyalty-saved')
        });
        EventHandler.$emit('reload-loyalty');
        EventHandler.$emit('profile-data-saved');
        this.close();
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    }
  }

  created() {
    if (this.restrictChoices && this.restrictChoices.length > 0) {
      const serviceType = this.restrictChoices.find(e => e.key === 'serviceType');
      this.serviceType = (serviceType && serviceType.value) || null;
    }
    if (this.card) {
      this.serviceType = this.card.loyalty.serviceType || null;
      this.tempId = this.card.tempId || uniqueId();
      this.Form = new LoyaltyForm({
        loyaltyId: this.card.loyalty.id,
        cardNumber: this.card.cardNumber,
        expireDate: this.card.expireDate,
        issueDate: this.card.issueDate,
        validFromDate: this.card.validFromDate,
        validRoutes: this.card.validRoutes,
      });
    }
  }
}
