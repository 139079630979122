
























































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import AirSort from './AirSort.vue';
import layoutStore from '@/modules/layout/layout.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    AirSort,
  }
})
export default class AirSubintro extends Vue {
  toggled: boolean = true;

  get sidebarToggled() {
    return !layoutStore.sidebarHidden;
  }

  get filters() {
    return AirSearchStore.filters;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get switchNewDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  set switchNewDisplay(value) {
    AirSearchStore.updateFilters([]);
    AirSearchStore.updateOffers([]);
    AirSearchStore.setTemplateOffers([]);
    AirSearchStore.setErrMessages(null);
    AirSearchStore.setNewFlightDisplay(value);
    this.$cookies.set('NewFlightDisplay', value, '30d');
    let searchId = this.$route.params.searchId;
      AirSearchStore.loader(true);
    if (searchId && !this.searchModifyState) {
      if (this.searchModifyState) {
        AirSearchStore.getExchangeOffers(searchId);
      } else {
        AirSearchStore.getOffers(searchId);
      }
    } else {
      AirSearchStore.getExchangeOffers(searchId);
    }
  }

  @Watch('sidebarToggled', { immediate: true })
  onToggled(value) {
    this.toggled = value;
  }

  @Watch('toggled')
  onToggledClick(value) {
    if (value !== this.sidebarToggled) {
      layoutStore.toggleSidebar();
    }
  }

  showMobileFilters() {
    router.push({
      name: this.$route.params.basketId ? 'airModificationFilters' : 'airFilters',
    });
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}
