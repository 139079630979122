

















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { translate } from '@/i18n';
import { TAirAncillarySelectionsModel, TAncillaryOffer } from '@/api/trip/basket-air-seats-extras.model';
import AlertPopup from '@/modules/basket/basket-air-seats-extras/AlertPopup.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  methods: {
    sanitizeUrl,
  },
  components: {
    AlertPopup,
  },
})
export default class ExtrasContent extends Vue {
  @Prop() value!: TAirAncillarySelectionsModel;
  @Prop() ancillaryOffers!: TAncillaryOffer[];
  @Prop() profileId!: string;
  @Prop() flightId!: string;

  selectedAncillary: TAncillaryOffer | null = null;

  get ancillaryFlightOffers() {
    return this.ancillaryOffers.filter((item) => item.flights.find((flightId) => flightId === this.flightId));
  }

  get options() {
    return this.ancillaryFlightOffers.filter((item) => !item.purchaseUrl);
  }

  get purchaseUrls() {
    return this.ancillaryFlightOffers.filter((item) => !!item.purchaseUrl);
  }

  get selectedItems() {
    return this.getSelectedItemsByFlightId(this.flightId);
  }

  get basketAirSeatsAndExtras() {
    return BasketStore.basketAirSeatsAndExtras;
  }

  get ancillariesToOverwrite() {
    let ancillariesToOverwrite: TAncillaryOffer[] = [];
    if (!this.selectedAncillary) {
      return ancillariesToOverwrite;
    }
    const isMaxOneBagAncillaryPerSegment = this.basketAirSeatsAndExtras && this.basketAirSeatsAndExtras.maxOneBagAncillaryPerSegment;
    if (isMaxOneBagAncillaryPerSegment) {
      this.selectedAncillary.flights.forEach((item: string) => {
        const selectedItemsByFlight = this.getSelectedItemsByFlightId(item);
        const selectedItemsByBag = selectedItemsByFlight.filter(({ isBag }) => isBag);
        if (selectedItemsByBag.length >= 1) {
          const [ancillaryToOverwrite] = selectedItemsByBag;
          ancillariesToOverwrite = ancillariesToOverwrite.concat(ancillaryToOverwrite);
        }
      });
      return ancillariesToOverwrite;
    }
    if (this.selectedAncillary.group) {
      const { maxSelectedItemsPerFlight, code } = this.selectedAncillary.group;
      if (maxSelectedItemsPerFlight) {
        this.selectedAncillary.flights.forEach((item: string) => {
          const selectedItemsByFlight = this.getSelectedItemsByFlightId(item);
          const selectedItemsByGroup = this.getSelectedItemsByGroup(selectedItemsByFlight, code);
          if (selectedItemsByGroup.length >= maxSelectedItemsPerFlight) {
            const [ancillaryToOverwrite] = selectedItemsByGroup;
            ancillariesToOverwrite = ancillariesToOverwrite.concat(ancillaryToOverwrite);
          }
        });
      }
      return ancillariesToOverwrite;
    }
    return ancillariesToOverwrite;
  }

  get ancillariesToOverwriteText() {
    return this.ancillariesToOverwrite
      .map(({ commercialNameTranslated }) => commercialNameTranslated)
      .join(', ');
  }

  get selectedAncillaryText() {
    return this.selectedAncillary && this.selectedAncillary.commercialNameTranslated;
  }

  get isSelectedAncillaryAlreadySelected() {
    return this.selectedItems.find((item) => item.id === (this.selectedAncillary && this.selectedAncillary.id));
  }

  get isAncillaryToOverwriteAlreadyBooked() {
    return this.ancillariesToOverwrite.find((item) => item.isAlreadyBooked);
  }

  get isAncillaryToOverwriteMandatory() {
    return this.ancillariesToOverwrite.find((item) => item.isMandatory);
  }

  getSanitizeUrl(link: string) {
    return sanitizeUrl(link);
  }

  getSelectedItemsByFlightId(id: string) {
    const selections = this.value.ancillarySelections.filter(({ profileId }) => profileId === this.profileId);
    const ancillaryOffersByFlight = this.ancillaryOffers.filter((item) => item.flights.find((flightId) => flightId === id));
    return ancillaryOffersByFlight.filter((item) => {
      const selection = selections.find(({ ancillaryOfferId }) => ancillaryOfferId === item.id);
      return selection || item.isAlreadyBooked;
    });
  }

  getSelectedItemsByGroup(items: TAncillaryOffer[], code: string): TAncillaryOffer[] {
    const itemsByGroup = items.reduce((prev, next) => {
      if (next.group) {
        return {
          ...prev,
          [next.group.code]: (prev[next.group.code] || []).concat(next),
        };
      }
      return prev;
    }, {} as { [code: string]: TAncillaryOffer[] });
    return itemsByGroup[code] || [];
  }

  async onAddClick(selectedAncillary: TAncillaryOffer | null) {
    if (
      !selectedAncillary
      || this.isSelectedAncillaryAlreadySelected
      || this.isAncillaryToOverwriteAlreadyBooked
      || this.isAncillaryToOverwriteMandatory
    ) {
      return;
    }
    let result = true;
    if (selectedAncillary.flights.length > 1) {
      const alertPopup = this.$refs.alertPopup as AlertPopup;
      result = await alertPopup.open({
        title: translate('basket-seats-extras.alert'),
        content: translate('basket-seats-extras.added-to-other-flight'),
      });
    }
    if (result) {
      const ancillarySelections = this.value.ancillarySelections.filter(({ ancillaryOfferId }) => {
        const ancillaryToOverwrite = this.ancillariesToOverwrite.find((item) => item.id === ancillaryOfferId);
        return !ancillaryToOverwrite;
      });
      this.$emit('input', {
        ancillarySelections: ancillarySelections.concat({
          ancillaryOfferId: selectedAncillary.id,
          profileId: this.profileId,
          quantity: 1,
        }),
      });
    }
    this.selectedAncillary = null;
  }

  async onRemoveClick(id: string) {
    const offer = this.ancillaryOffers.find((item) => item.id === id);
    let result = true;
    if (offer && offer.flights.length > 1) {
      const alertPopup = this.$refs.alertPopup as AlertPopup;
      result = await alertPopup.open({
        title: translate('basket-seats-extras.alert'),
        content: translate('basket-seats-extras.removed-to-other-flight'),
      });
    }
    if (result) {
      this.$emit('input', {
        ancillarySelections: this.value.ancillarySelections.filter((item) => {
          const isAncillaryOfferId = item.ancillaryOfferId === id;
          const isProfileId = item.profileId === this.profileId;
          return !(isAncillaryOfferId && isProfileId);
        }),
      });
    }
  }
}
