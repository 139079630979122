













































































































import { Component } from 'vue-property-decorator';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { ApprovalTaskTableParams } from './approvaltask-table.params';
import accountStore from '@/store/account.store';
import ExpenseStore from '../expense.store';
import { translate } from '@/i18n';
import { Permission } from '@/const/permission.enum';
import { MissionResult, MissionState, ApprovalTasksStage } from '@/api/expense/expense.model';

@Component({})
export default class ExpenseApprovalTasks extends BaseTable<MissionResult> {
  useQueryParams: boolean = true;
  params: ApprovalTaskTableParams = new ApprovalTaskTableParams({});
  fields = {
    submitter: {
      label: translate('expense-list.submitter'),
      class: 'expense-table__column--mission',
    },
    stage: {
      label: translate('expense-list.stage'),
      class: 'expense-table__column--state',
    },
    name: {
      label: translate('expense-list.name'),
      class: 'expense-table__column--mission',
    },
    state: {
      label: translate('expense-list.state'),
      class: 'expense-table__column--state-long',
    },
    missionStart: {
      label: translate('my-missions.mission-start'),
      class: 'expense-table__column--created-date',
    },
    missionEnd: {
      label: translate('my-missions.mission-end'),
      class: 'expense-table__column--created-date',
    },
    missionBudget: {
      label: translate('my-missions.mission-budget'),
      class: 'expense-table__column--total-price',
    },
    created: {
      label: translate('expense-list.created-date'),
      class: 'expense-table__column--created-date',
    },
    actionsColumn: {
      label: '',
      class: 'expense-table__column--actions',
    },
  };
  mission: MissionResult | null = null;
  formPending: boolean = false;

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get manualApprovalTasks() {
    return ExpenseStore.manualApprovalTasks;
  }

  get errMessages() {
    return ExpenseStore.errMessages;
  }

  get companyId() {
    return accountStore.CurrentUser!.profile.companyId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }



  userFullName(user) {
    return userFullName(user);
  }

  async getItems(params: ApprovalTaskTableParams): Promise<GetItemsResult<MissionResult>> {
    ExpenseStore.clearErrMessages();
    await ExpenseStore.getDefaultExpensePolicy(Permission.ViewPendingMissionApprovalTask);
    let searchParams = {
      filters: {
        profileId: params.profileId,
        policyId: this.policyId,
        approverId: this.currentProfileId,
        companyId: params.companyId,
        businessUnitId: params.businessUnitId
      },
      paging: {
        offset: params.start,
        size: params.size,
      }
    };
    await ExpenseStore.getManualApprovalTasks(searchParams);
    return {
      page: {
        pageSize: 10,
        moreResultsAvailable: false,
        pageNo: 0,
      },
      results: this.manualApprovalTasks,
    };
  }

  refreshList() {
    this.resetList(); 
    this.reload();
  }

  stateClasses(state) {
    return {
     'counter--empty': MissionState.Draft === state,
      'counter--yellow': MissionState.MissionRequestSubmitted === state,
      'counter--orange': MissionState.MissionRequestInApproval === state,
      'counter--strong-grey': MissionState.MissionCancelled === state || MissionState.ExpenseReportCancelled === state,
      'counter--light-green': MissionState.MissionApproved === state,
      'counter--red-grey': MissionState.AmendmentNeeded === state || MissionState.ReportAmendmentNeeded === state,
      'counter--strong-red': MissionState.MissionRequestRejected === state || MissionState.ExpenseReportRejected === state,
      'counter--green': MissionState.ExpenseReportApproved === state,
      'counter--orange-green': MissionState.ExpenseReportInApproval === state,
      'counter--yellow-green': MissionState.ExpenseReportSubmitted === state,
    };
  }

  stageClasses(state) {
    return {
      'counter--green': ApprovalTasksStage.MissionPreapproval === state,
      'counter--red': ApprovalTasksStage.SingleExpenseValidation === state,
      'counter--purple': ApprovalTasksStage.ExpenseReportValidation === state,
    };
  }

  validate(item) {
    router.push({
      name: 'approval-task',
      params: {
        id: item.id,
        taskId: item.approverTaskIds[0]
      },
    });
  }
}
