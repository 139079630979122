




































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import HotelRoomName from './HotelRoomName.vue';

@Component({
  components: {
    HotelRoomName,
  },
})
export default class HotelRoomNameWithTenants extends Vue {
  @Prop() roomOffer!: any;
  @Prop() mealType!: any;

  get tenantsWidth() {
    return this.roomOffer.adultsCount * 8 + this.roomOffer.childrenCount * 7;
  }

  get styles() {
    return {
      'max-width': 'calc(100% - ' + (20 + this.tenantsWidth) + 'px)',
    };
  }

  get content() {
    let text = translate('search.adults') + ': ' + this.roomOffer.adultsCount;

    if (this.roomOffer.childrenCount) {
      text += ' | ' + translate('search.children') + ': ' + this.roomOffer.childrenCount;
    }

    return text;
  }
}
