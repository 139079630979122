import CustomMessagesList from '@/modules/global-settings/custom-messages/CustomMessagesList.vue';

export default {
  path: '',
  component: CustomMessagesList,
  displayName: 'global-settings.custom-messages',
  name: 'custom-messages',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanAccessGlobalSettings',
    icon: 'chat',
    breadCrumb: `Custom messages' list`,
    parentName: 'custom-messages',
    depth: 1,
  },
};