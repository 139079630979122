import ManagerAssignment from '@/modules/profile/manager-assignment/ManagerAssignment.vue';

export default {
  path: 'manager-assignment',
  name: 'manager-assignment',
  component: ManagerAssignment,
  displayName: 'profile-manager-assignment.title',
  meta: {
    permission: 'CanViewManagerToProfile',
    icon: 'assignment_ind',
  }
};