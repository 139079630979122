










































import { Vue, Component, Watch } from 'vue-property-decorator';

import TrainSort from './TrainSort.vue';
import layoutStore from '@/modules/layout/layout.store';
import TrainSearchStore from './train-search.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    TrainSort,
  }
})
export default class TrainSubintro extends Vue {
  toggled: boolean = true;

  get sidebarToggled() {
    return !layoutStore.sidebarHidden;
  }

  get filters() {
    return TrainSearchStore.filters;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  @Watch('sidebarToggled', { immediate: true })
  onToggled(value) {
    this.toggled = value;
  }

  @Watch('toggled')
  onToggledClick(value) {
    if (value !== this.sidebarToggled) {
      layoutStore.toggleSidebar();
    }
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}
