import FeesConfigurations from '@/modules/settings/agency-fees/FeesConfigurations.vue';

export default {
  path: '',
  name: 'fees-configurations',
  component: FeesConfigurations,
  displayName: `Fees' configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    parentName: 'fees',
    depthCategory: 'fees',
    depth: 1,
  }
};