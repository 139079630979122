

































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import { LanguageCode, AirLine } from '@/api/dictionary/dictionary.model';
import { Legs } from '@/api/air-engine/air-search.model';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventBus from '@/services/event-handler';
import SearchStore from '../search.store';
import AirSearchStore from './air-search.store';
import { HomeApi } from '@/api/home/home.api';
import { AirLocationModel, searchModes, serviceClassEnum } from '@/api/home/home.model';
import { translate } from '@/i18n';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import DictionaryStore from '@/store/dictionary.store';
import SearchConst from '@/const/search.const';
import AirOfferParametersSelect from './AirOfferParametersSelect.vue';
import AirSearchMultiLegForm from './AirSearchMultiLegForm.vue';
import SplitBookingParameter from './SplitBookingParameter.vue';

@Component({
  components: {
    HeightTransition,
    AirOfferParametersSelect,
    AirSearchMultiLegForm,
    SplitBookingParameter
  },
})
export default class AirSearch extends Vue {
  fromLocations: AirLocationModel[] = [];
  toLocations: AirLocationModel[] = [];
  connectionAirports: AirLocationModel[] = [];
  isFromLoading: boolean = false;
  isConnectionLoading: boolean = false;
  isAirlinesLoading: boolean = false;
  allAirline: any[] = [];
  isToLoading: boolean = false;
  shouldFocus: boolean = false;
  shouldShowAdvancedCriteria: boolean = false;
  isSwapLocationsActive: boolean = false;
  valueTimeDeparture = {
    value: -1,
    label: translate('common.any-hour'),
    timeRange: [0, 1439]
  };
  valueTimeReturn = {
    value: -1,
    label: translate('common.any-hour'),
    timeRange: [0, 1439]
  };
  searchModes = searchModes;
  searchModeOptions = [
    {
      value: searchModes.oneWay,
      label: translate('common.one-way'),
    },
    {
      value: searchModes.roundTrip,
      label: translate('common.roundtrip')
    },
    {
      value: searchModes.multiLeg,
      label: translate('common.multi-leg')
    }
  ];
  returnAvailable: boolean = true;
  classLabels: string[] = [
    translate('search-air.cabin-class-departure'),
    translate('search-air.cabin-class-return')
  ];
  alliances = SearchConst.alliances.map(item => {
    return {
      ...item,
      label: translate(item.label),
    };
  });



  get mobileSearchMode() {
    return this.searchModeOptions.find(item => item.value === this.airSearchParameters.searchMode);
  }

  set mobileSearchMode(searchMode) {
    if (!searchMode) {
      return;
    }
    this.airSearchParameters.searchMode = searchMode.value;
  }

  get allianceModel() {
    return this.airSearchParameters.alliance.length ? this.airSearchParameters.alliance[0] : {
      ...this.alliances[0],
    };
  }

  set allianceModel(value) {
    if (!value) {
      this.airSearchParameters.alliance = [];
    }
    this.airSearchParameters.alliance = [value];
  }

  get airlines() {
    return DictionaryStore.allAirLinesSorted;
  }

  get cabinClasses() {
    return SearchConst.cabinClasses;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get searchDate() {
    if (this.returnAvailable) {
      return {
        start: moment(this.airSearchParameters.departureDate).toDate(),
        end:  moment(this.airSearchParameters.returnDate).toDate(),
      };
    } else {
      return moment(this.airSearchParameters.departureDate).toDate();
    }
  }

  set searchDate(data: any) {
    if (this.returnAvailable && data && data.start) {
        this.airSearchParameters.departureDate = data.start;
        this.airSearchParameters.returnDate = data.end;
    } else if (!this.returnAvailable && data) {
      this.airSearchParameters.departureDate = data;
    }
  }

  get classesDate() {
    return this.returnAvailable ? 'date-picker-section__search-roundtrip' : 'date-picker-section__search-oneway';
  }

  get classesLocation() {
    return this.returnAvailable ? 'location-select__roundtrip' : 'location-select__one-way';
  }

  get modeType() {
    return this.returnAvailable ? 'range' : 'single';
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get minReturnDate() {
    return this.airSearchParameters.departureDate
      ? moment(this.airSearchParameters.departureDate, 'YYYY-MM-DD')
      : moment();
  }

  get toLocationsOptions() {
    return this.toLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get fromLocationsOptions() {
    return this.fromLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get connectionAirportsOptions() {
    return this.connectionAirports.map(location => ({
      ...location,
      uniqId: location.cityCode + location.airportCode,
      label: this.getAirportName(location),
    }));
  }

  get valueTimeOptions() {
    return SearchConst.airSearchTimeWindows;
  }

  get datePickerTimeLabels() {
    return {
      start: translate('search-air.departure-at'),
      end: translate('search-air.return-at'),
    };
  }

  get modifyMode() {
    return AirSearchStore.modifyMode;
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get multiLegPermission() {
    return AccountStore.HasPermission('CanSearchMultiLegAir');
  }

  onDateInvalid(value) {
    EventBus.$emit('date-invalid', {service: serviceClassEnum.Flight, value});
  }


  translateLabel(value) {
    return translate(value.label);
  }

  getAirportName(option) {
    let name = '';
    if (option.type === 'City') {
      name += option.cityName;
      name += ' (' + translate('search.all-airports') + ')';
    } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
      name += option.airportName;
      name += ' (' + option.cityName + ')';
    }
    return name;
  }

  isDateValid(date) {
    let timestamp = Date.parse(date);
    return isNaN(timestamp) ? false : true;
  }

  handleEnterPress() {
    EventBus.$emit('focus-on-search');
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isConnectionLoading',
  })
  async findConnectionAirports(query) {
    if (query && query.length > 2) {
      this.isConnectionLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode, true);

      if (response && response.data) {
        this.connectionAirports = response.data;
      }

      this.isConnectionLoading = false;
    } else {
      this.isConnectionLoading = false;
      this.connectionAirports = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isFromLoading',
  })
  async findFromAirLocation(query) {
    if (query && query.length > 2) {
      this.isFromLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocations = [];
        this.fromLocations = response.data;
      }

      this.isFromLoading = false;
    } else {
      this.isFromLoading = false;
      this.fromLocations = [];
      if (this.airSearchParameters.from) {
        this.fromLocations.push(this.airSearchParameters.from);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isToLoading',
  })
  async findToAirLocation(query) {
    if (query && query.length > 2) {
      this.isToLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);
      this.toLocations = [];
      if (response && response.data) {
        this.toLocations = [];
        this.toLocations = response.data;
      }

      this.isToLoading = false;
    } else {
      this.isToLoading = false;
      this.toLocations = [];
      if (this.airSearchParameters.to) {
        this.toLocations.push(this.airSearchParameters.to);
      }
    }
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  swapLocations() {
    this.isSwapLocationsActive = true;
    let from = this.airSearchParameters.from;
    this.airSearchParameters.from = this.airSearchParameters.to;
    this.airSearchParameters.to = from;

    this.prepareTo();
    this.prepareFrom();
    setTimeout(() => {
      this.isSwapLocationsActive = false;
    }, 450);
  }

  checkMode() {
    this.returnAvailable = this.airSearchParameters.searchMode === 'RoundTrip';
    if (this.airSearchParameters.searchMode === 'MultiLeg' && this.airSearchParameters.legs && this.airSearchParameters.legs.length < 1) {
      let date = moment(new Date())
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.airSearchParameters.legs.push(new Legs({legNumber: this.airSearchParameters.legs.length, departureDate: date}));
    }
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const vueEl = this.$refs[elementId] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl.$el as HTMLInputElement;
    inputValue.getElementsByTagName('input')[0].focus();
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs.fromSelect as Vue;
      const from: Multiselect = element.$refs.multiselect as Multiselect;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  prepareFrom() {
    this.fromLocations = [];
    if (this.airSearchParameters.from) {
      this.fromLocations.push(this.airSearchParameters.from);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.airSearchParameters.to) {
      this.toLocations.push(this.airSearchParameters.to);
    }
  }

  refreshTimeWindows() {
    if (this.airSearchParameters.outwardTimeWindows) {
      let window = this.airSearchParameters.outwardTimeWindows.departureWindow ? this.airSearchParameters.outwardTimeWindows.departureWindow : this.airSearchParameters.outwardTimeWindows.timeRange;
      this.valueTimeDeparture = this.findTimeRange(window) as any;
    }
    if (this.airSearchParameters.inwardTimeWindows) {
      let window = this.airSearchParameters.inwardTimeWindows.departureWindow ? this.airSearchParameters.inwardTimeWindows.departureWindow : this.airSearchParameters.inwardTimeWindows.timeRange;
      this.valueTimeReturn = this.findTimeRange(window) as any;
    }
  }

  unmodify() {
    AirSearchStore.setModifyState(false);
  }

  showAdvancedCriteria() {
    this.shouldShowAdvancedCriteria = true;
  }

  hideAdvancedCriteria() {
    this.shouldShowAdvancedCriteria = false;
  }

  findTimeRange(range) {
    const windowFound = !range ? false : SearchConst.searchTimeValues.find((window) => {
      return window.timeRange[0] === range[0] && window.timeRange[1] === range[1];
    });

    return windowFound ? windowFound : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];
  }

  @Watch('airSearchParameters.searchMode', { immediate: true, deep: true })
  onChangedSearchMode(val) {
    if (this.airSearchParameters.searchMode === this.searchModes.multiLeg) {
      EventBus.$emit('date-invalid', { service: serviceClassEnum.Flight, value: false });
    }
    if (this.airSearchParameters.searchMode === this.searchModes.oneWay) {
      this.returnAvailable = false;
      this.airSearchParameters.returnDate = '';
    } else {
      if (this.airSearchParameters.returnDate) {
        if (moment(this.airSearchParameters.returnDate).isAfter(
          moment(this.airSearchParameters.departureDate).add(1, 'days')
        )) {
          this.refreshTimeWindows();
          return;
        }
        this.airSearchParameters.returnDate = moment(this.airSearchParameters.returnDate).format('YYYY-MM-DD');
      } else {
        this.airSearchParameters.returnDate = moment(this.airSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.refreshTimeWindows();
  }

  @Watch('airSearchParameters.departureDate')
  onChangedDeparture(val) {
    if (val) {
      this.airSearchParameters.departureDate = moment(this.airSearchParameters.departureDate).format('YYYY-MM-DD');
    }
    if (moment(this.airSearchParameters.departureDate).isAfter(this.airSearchParameters.returnDate)) {
      this.airSearchParameters.returnDate = moment(this.airSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.airSearchParameters.departureDate = moment().format('YYYY-MM-DD');
    }
  }

  @Watch('airSearchParameters.returnDate')
  onChangedReturn(val) {
    if (this.airSearchParameters.searchMode === this.searchModes.oneWay) {
      this.airSearchParameters.returnDate = '';
    } else {
      if (this.airSearchParameters.returnDate) {
        this.airSearchParameters.returnDate = moment(this.airSearchParameters.returnDate).format('YYYY-MM-DD');
      }
    }
  }

  @Watch('valueTimeDeparture', { deep: true })
  onChangedDepartureTimeWindow(val) {
    if (val) {
      this.airSearchParameters.outwardTimeWindows = {
        departureWindow: val.timeRange,
        arrivalWindow: null
      };
    }
  }

  @Watch('valueTimeReturn', { deep: true })
  onChangedReturnTimeWindow(val) {
    if (this.airSearchParameters.searchMode === this.searchModes.oneWay) {
      this.airSearchParameters.inwardTimeWindows = null;
    } else {
      if (this.airSearchParameters.returnDate) {
        this.airSearchParameters.inwardTimeWindows = {
          departureWindow: val.timeRange,
          arrivalWindow: null
        };
      }
    }
  }
  
  created() {
    EventBus.$on('focus-from', this.onFocus);
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}
