






















































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import AccountStore from '@/store/account.store';
import FlightTimelineDetails from './FlightTimelineDetails.vue';
import FlightTimelineDetailsMobile from './FlightTimelineDetailsMobile.vue';
import AirSearchStore from './air-search.store';
import AirFareConditions from './AirFareConditions.vue';
import AirResultsRow from './AirResultsRow.vue';
import SearchStore from '@/modules/search/search.store';
import { OffersApi } from '@/api/air-engine/offers.api';
import { OffersModel } from '@/api/air-engine/offers.model';
import AirMissedSavingsPopup from './AirMissedSavingsPopup.vue';
import EventBus from '@/services/event-handler';

@Component({
   components: {
    FlightTimelineDetails,
    FlightTimelineDetailsMobile,
    AirFareConditions,
    AirResultsRow,
    AirMissedSavingsPopup,
  }
})
export default class AirResultDetails extends Vue {
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: true }) searchMode!: boolean;
  searchId: string | null = null;
  canShow = this.searchMode;
  showFullConditionsPopup: boolean = false;
  forcing: boolean = false;
  selectingOffer: boolean = false;
  selectingOfferForce: boolean = false;
  offerDetails: OffersModel | any = null;
  recomendationId: string | null = null;
  imagesErrorInfoConst = '/assets/img/ic_error.png';

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  get basketId() {
    return AirSearchStore.basketId;
  }

  get prevSelectedOffer() {
    return AirSearchStore.prevSelectedOffer;
  }

  get showModal() {
    return AirSearchStore.showModal;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get showErrorBE() {
    return AirSearchStore.showErrorBE;
  }
  
  get errorMessageBe() {
    return AirSearchStore.errorMessageBe;
  }

  get currentUser() {
    return AccountStore.CurrentUser;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get offers() {
    return AirSearchStore.offers;
  }

  get offer() {
    return this.offers.length ? this.offers.find(x => x.id === this.recomendationId) : null; 
  }

  get classes() {
    return {
      'disabled': AirSearchStore.isSelected && this.selectingOffer,
      'disabled-active': AirSearchStore.isSelected && 
      AirSearchStore.currentOfferId === this.offer!.id &&
      AirSearchStore.currentProposalId === this.offer!.proposal.id,
      'expired': this.isOfferExpired,
    };
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

   get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get canExchangeFlightBooking() {
    return AccountStore.HasPermission('ExchangeFlightBooking');
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => !!this.offer && expire.recommendationId === this.offer.id);
  }



  async initView() {
    this.searchId = this.$route.params.searchId;
    this.recomendationId = this.$route.params.recomendationId;
    if (this.searchId) {
       
      AirSearchStore.loader(true);
      await AirSearchStore.getSearchSession(this.searchId);
      AirSearchStore.getOffers(this.searchId);
    }
 }

  mounted() {
    if (this.offerDetails == null && this.offer) {
      this.loadOfferDetails();
    }
  }

  async loadOfferDetails() {

    if (this.offer) {
      if (this.offer.proposal.supplier !== 'AirFranceKlm' && this.offer.proposal.supplier !== 'BritishAirways') {
        return;
      }
      if (this.offer.proposal.supplier === 'AirFranceKlm' && AirSearchStore.modifyMode) {
        return;
      }

      try {
        let searchId = this.$route.params.searchId;
        const offerDetailsResponse = await OffersApi.getShortOfferDetails(this.offer.proposal.supplier, this.offer.id, this.offer.providerSearchId, searchId);
        if (offerDetailsResponse.data) {
          this.offerDetails = offerDetailsResponse.data;
        }
      } catch (error) {
        this.offerDetails = {
          error: error.response.data,
        };
      }
    }
  }

  legOfferDetails(index) {
    if (this.offerDetails) {
      return this.offerDetails.error ? this.offerDetails : this.offerDetails.legDetails[index];
    }

    return null;
  }

  showResults() {
    router.push({ name: 'air' });
  }

  showFullConditions() {
    if (this.selectingOffer) {
      return;
    }
    this.showFullConditionsPopup = true;
  }

  goToBasketWithoutChanges() {
    router.push({
      name: 'basket',
      params: { id: this.basketId }
    });
  }

  backToDetails() {
    this.selectingOffer = false;
    AirSearchStore.setShowModal(false);
  }

  onSelectingAborted() {
    this.selectingOffer = false;
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.selectingOfferForce = true;
    this.AddOfferToCart(true);
  }

  async AddOfferToCart(force = false) {
    let basketItemsRequest = {
      forceAdd: force,
      recommendationId: AirSearchStore.newFlightDisplay ? this.offer!.proposal.id : this.offer!.id,
    };
    this.selectingOffer = true;
    AirSearchStore.select({ offerId: this.offer!.id, proposalId: this.offer!.proposal.id });

    AirSearchStore.CheckIfCanAddOfferToCart(basketItemsRequest);
  }

  created() {
    EventBus.$on('selecting-aborted', this.onSelectingAborted);
  }

  beforeDestroy() {
    EventBus.$off('selecting-aborted', this.onSelectingAborted);
  }
}
