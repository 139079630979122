






































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import { getParentByClass } from '@/modules/layout/scroll-manager';

@Component({})
export default class UiHorizontalScrollSection extends Vue {
  @Prop({default: false}) hideYScroll!: boolean;
  @Prop({default: false}) grey!: boolean;

  scrollLeftVisible: boolean = false;
  scrollRightVisible: boolean = false;
  scrollLeftPressed: boolean = false;
  scrollRightPressed: boolean = false;
  expandIntervalId: number = -1;
  scrollLeftInterval: number = -1;
  scrollRightInterval: number = -1;
  scrollStep: number = 17;
  scrollSpeed: number = 17;
  scrollSpeedFall: number = 1.2;
  scrollToDestXInterval: number = -1;

  checkShowLeftRight() {
    const container = this.$refs.container as HTMLElement;
    const inner = this.$refs.content as HTMLElement;

    if (inner.clientWidth > container.clientWidth) {
      if (container.scrollLeft > 10) {
        this.scrollLeftVisible = true;
      } else {
        this.scrollLeftVisible = false;
      }

      if (container.scrollLeft < container.scrollWidth - container.clientWidth - 10) {
        this.scrollRightVisible = true;
      } else {
        this.scrollRightVisible = false;
      }
    } else {
      this.scrollLeftVisible = false;
      this.scrollRightVisible = false;
    }
  }
  
  startScrollLeft($event) {
    $event.preventDefault();
    this.scrollLeftPressed = true;
    this.scrollSpeed = this.scrollStep;
    clearInterval(this.scrollLeftInterval);
    clearInterval(this.scrollRightInterval);
    clearInterval(this.scrollToDestXInterval);
    this.scrollLeftInterval = setInterval(this.scrollLeft, 30);
  }

  stopScrollLeft() {
    this.scrollLeftPressed = false;
  }

  startScrollRight($event) {
    $event.preventDefault();
    this.scrollRightPressed = true;
    this.scrollSpeed = this.scrollStep;
    clearInterval(this.scrollLeftInterval);
    clearInterval(this.scrollRightInterval);
    clearInterval(this.scrollToDestXInterval);
    this.scrollRightInterval = setInterval(this.scrollRight, 30);
  }

  stopScrollRight() {
    this.scrollRightPressed = false;
  }
  
  scrollNow(value) {
    const container = this.$refs.container as HTMLDivElement;
    const destX = container.scrollLeft + value;

    if (container.scrollTo) {
      container.scrollTo(destX, 0);
    } else {
      container.scrollLeft = destX;
    }
  }

  scrollLeft() {
    if (!this.scrollLeftPressed) {
      this.scrollSpeed -= this.scrollSpeedFall;
      this.scrollSpeed = Math.max(this.scrollSpeed, 0);

      if (0 >= this.scrollSpeed) {
        clearInterval(this.scrollLeftInterval);
      }
    }
    this.scrollNow(-this.scrollSpeed);
  }

  scrollRight() {
    if (!this.scrollRightPressed) {
      this.scrollSpeed -= this.scrollSpeedFall;
      this.scrollSpeed = Math.max(this.scrollSpeed, 0);

      if (0 >= this.scrollSpeed) {
        clearInterval(this.scrollRightInterval);
      }
    }
    this.scrollNow(this.scrollSpeed);
  }

  scrollToDestinationX(x) {
    const container = this.$refs.container as HTMLElement;
    const currentScrollLeft = container.scrollLeft;
    let change = (x - currentScrollLeft) * 0.3;
    let destX = currentScrollLeft + change;

    if (Math.abs(change) < 1 || Math.abs(destX - x) < 1) {
      destX = x;
      clearInterval(this.scrollToDestXInterval);
    }

    if (container.scrollTo) {
      container.scrollTo(destX, 0);
    } else {
      container.scrollLeft = destX;
    }
  }

  ensureVisible(element: HTMLElement) {
    const parent = getParentByClass(element, 'ui-horizontal-scroll-section__content-wrapper');
    const container = this.$refs.container as HTMLElement;
    const maxScroll = container.scrollWidth - container.clientWidth - 1;

    if (!parent || parent !== container) {
      return;
    }

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    let destX = container.scrollLeft;

    if (elementRect.width <= containerRect.width) {
      if (elementRect.left + elementRect.width / 2 > containerRect.left + containerRect.width / 2) {
        destX += elementRect.left + elementRect.width / 2 - (containerRect.left + containerRect.width / 2);
      } else {
        destX -= containerRect.left + containerRect.width / 2 - (elementRect.left + elementRect.width / 2);
      }
    } else {
      if (elementRect.left > containerRect.left) {
        destX += elementRect.left - containerRect.left - 30;
      } else if (elementRect.left < containerRect.left) {
        destX -= containerRect.left - elementRect.left + 30;
      }
    }

    destX = Math.max(0, Math.min(destX, maxScroll));

    if (destX !== container.scrollLeft) {
      clearInterval(this.scrollLeftInterval);
      clearInterval(this.scrollRightInterval);
      clearInterval(this.scrollToDestXInterval);
      this.scrollToDestXInterval = setInterval(() => {
        this.scrollToDestinationX(destX);
      }, 30);
    }
  }

  mounted() {
    this.expandIntervalId = setInterval(this.checkShowLeftRight, 100);
    EventBus.$on('EnsureScrolledElementVisible', this.ensureVisible);
  }

  beforeDestroy() {
    clearInterval(this.expandIntervalId);
    clearInterval(this.scrollLeftInterval);
    clearInterval(this.scrollRightInterval);
    clearInterval(this.scrollToDestXInterval);
    EventBus.$off('EnsureScrolledElementVisible', this.ensureVisible);
  }

  @Watch('scrollLeftVisible')
  onScrollLeftVisibleChange(value) {
    if (!value) {
      this.stopScrollLeft();
    }
  }

  @Watch('scrollRightVisible')
  onScrollRightVisibleChange(value) {
    if (!value) {
      this.stopScrollRight();
    }
  }
}

