




























































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';

import HeightTransition from '@/modules/layout/HeightTransition.vue';
import AirOfferParametersSelect from './AirOfferParametersSelect.vue';
import SearchAutocomplete from './air-search-component/SearchAutocomplete.vue';
import SearchDatePicker from './air-search-component/SearchDatePicker.vue';
import SearchAdvancedCriteria from './air-search-component/SearchAdvancedCriteria.vue';
import SearchOption from './air-search-component/SearchOption.vue';
import SearchOptionCabinClass from './air-search-component/SearchOptionCabinClass.vue';
import SearchStore from '../search.store';

const updateAirSearchParameters: string = 'update:airSearchParameters';

@Component({
  components: {
    HeightTransition,
    AirOfferParametersSelect,
    SearchAutocomplete,
    SearchDatePicker,
    SearchAdvancedCriteria,
    SearchOption,
    SearchOptionCabinClass,
  },
})
export default class AirSearchMultiLegForm extends Vue {
  @Prop() airSearchParameters!: any;
  @Prop() searchModes!: any;
  @Prop() returnAvailable!: any;

  shouldShowAdvancedCriteria: boolean = false;

  get showRemoveIcon() {
    return this.airSearchParameters && this.airSearchParameters.legs.length > 1;
  }

  showAdvancedCriteria() {
    this.shouldShowAdvancedCriteria = true;
  }

  removeLeg(index) {
    this.airSearchParameters.legs.splice(index, 1);
  }

  @Emit()
  [updateAirSearchParameters](value) {
    return value;
  }

  isAnyHour(leg) {
    if (
      !leg.timeWindows ||
      (leg.timeWindows.departureWindow[0] === 0 &&
      leg.timeWindows.departureWindow[0] === 1439)
    ) {
      return true;
    }

    return false;
  }

  sortFunction(a, b) {
    if (moment(a.departureDate).isSame(b.departureDate, 'date')) {
      if (this.isAnyHour(a) && this.isAnyHour(b)) {
        return 0;
      }
      if (this.isAnyHour(a)) {
        return -1;
      }
      if (this.isAnyHour(b)) {
        return 1;
      }
      return a.timeWindows.departureWindow[0] < b.timeWindows.departureWindow[0] ? -1 : 1;
    }
    if (moment(a.departureDate).isBefore(b.departureDate, 'date')) {
      return -1;
    }
    return 1;
  }

  checkLegsOrder() {
    const newLegs = [...this.airSearchParameters.legs];

    newLegs.sort(this.sortFunction);
    SearchStore.updateAirSearchLegs(newLegs);
  }

  @Watch('airSearchParameters')
  onModelUpdate(val) {
    this[updateAirSearchParameters](val);
  }
}

