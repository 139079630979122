



















import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

import { PaymentCardsApi } from '@/api/profile/payment-cards.api';
import { PaymentCardProfileModel } from '@/api/profile/payment-cards.model';
import EventBus from '@/services/event-handler';

@Component({})
export default class PaymentCardRemovePopup extends Vue {
  @Prop() card!: PaymentCardProfileModel;
  @Prop() profileId!: string;
  cardRemovalErrorMessage: string | null = null;
  formPending: boolean = false;

  async removeDocument() {
    try {
      this.formPending = true;
      const documentsResult = await PaymentCardsApi.removeCard(this.profileId, this.card.id);
      this.$emit('updateCard');
      EventBus.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      if (!error.response.data.error.details) {
        this.cardRemovalErrorMessage = error.response.data.error.message;
      }
    } finally {
      this.formPending = false;
    }
  }

  @Emit()
  close() {
  }
}
