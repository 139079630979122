



















































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import { userFullName } from '@/core/user-full-name';
import BasketTravellerDocuments from './BasketTravellerDocuments.vue';
import DictionaryStore from '@/store/dictionary.store';
import { PhoneCode } from '@/api/profile/contacts.model';
import moment from 'moment';
import { ProfileApi } from '@/api/profile/profile.api';

@Component({
  components: {
    BasketTravellerDocuments,
  },
})
export default class BasketModifyAmadeusTraveller extends Vue {
  @Prop() traveller!: any;
  @Prop() index!: number;

  isToggled: boolean = false;
  Form: any = {
    dateOfBirth: '',
    email: '',
    selectedPrimaryPhoneCode: null,
    phone: {
      number: '',
      code: '',
    },
  };
  contactDataErrors: any[] = [];
  phoneCodeEmptyValue: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: translate('common.phone-code'),
    fullPhoneCodeDisplayName: translate('common.none'),
  };
  $v;



  get systemTraveller() {
    if (!BasketStore.basket) {
      return null;
    }
    return BasketStore.basket.travellers
      .find(traveller => traveller.id === this.traveller.id);
  }

  get systemTravellerIndex() {
    if (!BasketStore.basket) {
      return 0;
    }
    return BasketStore.basket.travellers
      .findIndex(traveller => traveller.id === this.traveller.id);
  }

  get travellerDocuments() {
    if (
      !this.traveller ||
      !this.traveller.passengerDocuments ||
      !this.traveller.passengerDocuments.documents
    ) {
        return [];
    }

    return this.traveller.passengerDocuments.documents;
  }

  get travellerSSR() {
    if (
      !this.traveller ||
      !this.traveller.passengerSSR ||
      !this.traveller.passengerSSR.ssr
    ) {
        return [];
    }

    return this.traveller.passengerSSR.ssr;
  }

  get emails() {
    if (
      !this.traveller ||
      !this.traveller.passengerContact ||
      !this.traveller.passengerContact.emailAddresses
    ) {
      return [];
    }
    return this.traveller.passengerContact.emailAddresses;
  }

  get phones() {
    if (
      !this.traveller ||
      !this.traveller.passengerContact ||
      !this.traveller.passengerContact.phoneNumbers
    ) {
      return [];
    }
    return this.traveller.passengerContact.phoneNumbers;
  }

  get canBeNameModified() {
    if (
      !this.traveller ||
      !this.traveller.passengerName
    ) {
      return false;
    }

    return this.traveller.passengerName.canBeModified;
  }

  get canBeContactModified() {
    if (
      !this.traveller ||
      !this.traveller.passengerContact
    ) {
      return false;
    }

    return this.traveller.passengerContact.canBeModified;
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }
  
  get allPhoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    const allPhoneCodes = this.allCountries
      .filter(country => country && country.phoneCode!.length > 0)
      .map((country) => {
        return { 
          ...country,
          shortPhoneCodeDisplayName: '+' + country.phoneCode,
          fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
        };
      });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get currentModelData() {
    if (this.Form.$invalid) {
      return {};
    }
    return {
      id: this.traveller.id,
      dateOfBirth: this.Form.dateOfBirth,
      passengerContact: {
        emailAddress: this.Form.email,
        phoneNumber: this.Form.phone.number,
        phoneNumberCode: this.Form.selectedPrimaryPhoneCode.phoneCode,
      },
    };
  }



  @Validation()
  validationObject() {
    return {
      Form: {
        email: {
          required,
          maxLength: maxLength(320),
          email,
        },
        selectedPrimaryPhoneCode: {
          required,
          validPhoneCode: (codeObject) => {
            return !!codeObject && !!codeObject.phoneCode;
          },
        },
        dateOfBirth: {
          required,
        },
        phone: {
          number: {
            required,
            minLength: minLength(3),
            numeric,
          }
        },
      }
    };
  }

  toggleNow() {
    this.isToggled = !this.isToggled;
  }

  userFullName(user) {
    return userFullName(user);
  }

  onPrimaryPhoneCodeSelect(selected, index) {
    if (selected && !selected.phoneCode) {
      this.Form.phone.number = '';
    } else if (selected && selected.phoneCode) {
      this.Form.phone.code = selected.phoneCode;
    }
    setTimeout(() => {
      const el = (this.$refs.primaryNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  async loadData() {
    try {
      const result = await ProfileApi.getById(this.traveller.id);
      this.Form.dateOfBirth = result.data.dateOfBirth;
      this.Form.email = result.data.primaryEmail;
      this.Form.phone = result.data.primaryPhone;
      this.Form.selectedPrimaryPhoneCode = result.data.primaryPhone ? 
        this.allPhoneCountryCodes
          .find(item => {
            return item.phoneCode === result.data.primaryPhone!.code;
          }) : null;
    } catch (error) {
      this.contactDataErrors = this.$handleErrors(error);
    }
  }

  updateData() {
    this.currentModelData.dateOfBirth = moment(this.currentModelData.dateOfBirth).utcOffset(0, true).format();
    BasketStore.setModifiedTraveller({
      data: this.currentModelData,
      index: this.index,
    });
  }

  @Watch('$v.$invalid')
  onFormInvalid(invalid) {
    BasketStore.setModifyTravellersDetailsFormStatus({
      travellerId: this.traveller.id,
      form: invalid,
      loyalty: null,
      noSection: null,
    });
  }



  created() {
    this.loadData();
    EventBus.$on('update-modified-details', this.updateData);
  }

  beforeDestroy() {
    EventBus.$off('update-modified-details', this.updateData);
  }
}
