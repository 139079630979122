


























































































































































































































































































import { Vue, Component } from 'vue-property-decorator';

import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import SettingsStore from '@/modules/settings/settings.store';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import {
  CustomFieldDictionaryItemEntry,
  UpdateFieldMessage,
  UpdateFieldConfigurationMessage,
} from '@/api/custom-fields/custom-fields.model';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import CustomFieldDictionaryItems from './CustomFieldDictionaryItems.vue';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

@Component({
  components: {
    CustomFieldDictionaryItems,
  }
})
export default class CustomFieldDefinition extends Vue {
  $v;
  selectedModes: boolean[] = [];
  formHasErrors: boolean = false;
  previewPopup: boolean = false;
  previewDictionary!: any;
  previewDictionaryItems: CustomFieldDictionaryItemEntry[] = [];
  valuesLoading: boolean = false;
  saving: boolean = false;

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/custom-fields/${this.configurationId !== undefined ? this.configurationId + '/field-configuration' : 'field-configuration'}`;
  }

  get loading() {
    return CustomFieldsStore.loading;
  }

  get customField() {
    return CustomFieldsStore.customFieldDefinition;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get dictionaries() {
    if (CustomFieldsStore.dictionaries && CustomFieldsStore.dictionaries.length) {
      return CustomFieldsStore.dictionaries.filter(item => { return item.isActive; });
    } else {
      return CustomFieldsStore.dictionaries;
    }
  }

  get collectionModes() {
    return CustomFieldsStore.collectionModes;
  }

  get customFieldType() {
    return this.customField.valueType;
  }

  set customFieldType(val) {
    this.customField.valueType = val;
  }

  get valueTypes() {
    return CustomFieldsStore.fieldTypes;
  }

  get fieldType() {
    if (this.customField.valueType) {
      if (this.customField.valueType.type === 'String' || this.customField.valueType.type === 'Double' || this.customField.valueType.type === 'Integer') {
      return 'Text';
      } else if (this.customField.valueType.type === 'Dictionary' || this.customField.valueType.type === 'Bool') {
        return this.customField.valueType.type;
      }
    } else {
      return '';
    }
  }

  get errors() {
    return CustomFieldsStore.getErrMessages;
  }

  @Validation()
  validationObject() {
    return {
      customField: {
        name: {
          required,
          maxLength: maxLength(64)
        },
        description: {
          maxLength: maxLength(1024)
        },
        code: {
          required,
          maxLength: maxLength(64)
        },
        valueType: {
          required
        },
        validationRegExpRule: {
          maxLength: maxLength(128),
          validRegex: this.isRegexValid,
        },
        regExpValidationMessage: {
          maxLength: maxLength(1024),
        },
        dictionary: {
          requiredIf: requiredIf(() => {
            return this.fieldType === 'Dictionary';
          })
        },
        formatGuidanceText: {
          maxLength: maxLength(1024)
        },
      },
    };
  }

  goToConfiguration() {
    CustomFieldsStore.setActiveTab('fields');
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'field-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  isRegexValid(val) {
    let valid = true;
    try {
      new RegExp(val);
    } catch (e) {
      valid = false;
    }
    return valid;
  }

  async createConfig(data: UpdateFieldConfigurationMessage) {
    try {
      CustomFieldsStore.setLoading(true);
      const response = await CustomFieldsApi.createCustomFieldConfiguration(data);
      if (response && response.data) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      CustomFieldsStore.setErrMessages(error);
      CustomFieldsStore.setShowError(true);
    } finally {
      CustomFieldsStore.setLoading(false);
    }
  }

  prepareRequest() {
    const isRegexCompliant = this.customField.valueType &&
      -1 === ['Dictionary', 'Bool'].indexOf(this.customField.valueType.type || '');
    let request = {
      rootCompanyId: this.currentCompany!.rootCompanyId,
      configurationId: this.configurationId,
      name: this.customField.name,
      description: this.customField.description,
      typeId: this.customField.valueType!.id,
      validationRegExpRule: isRegexCompliant ? this.customField.validationRegExpRule : '',
      regExpValidationMessage: this.customField.regExpValidationMessage,
      formatGuidanceText: this.customField.formatGuidanceText,
      code: this.customField.code,
      isActive: this.customField.isActive,
      collectionModes: CustomFieldsStore.collectionModes.map((mode, index) => {
        if (Object.keys(this.customField.collectionModes).indexOf(mode) !== -1) {
          return index;
        }
      }).filter(Boolean)
    };
    if (this.customField.valueType!.type === 'Dictionary') {
      request['customFieldDictionaryId'] = this.customField.dictionary ? this.customField.dictionary.id : '';
    }

    return request;
  }

  async prepareDefinition() {
    let configId = this.configurationId;

    if (configId === undefined) {
      const message: UpdateFieldConfigurationMessage = {
        rootCompanyId: this.currentCompany!.rootCompanyId,
        name: this.configuration.name,
      };
      const newConfig = await this.createConfig(message);
      configId = newConfig.configurationId;
      CustomFieldsStore.setConfigurationId(configId);
    }
    if (!this.errors.length && this.customField) {
      const request = this.prepareRequest();
      const isEdit = !!this.customField.id;
      if (isEdit && this.customField.id) {
        const message: UpdateFieldMessage = {
          customFieldId: this.customField.id,
          field: request,
        };
        await CustomFieldsStore.updateCustomFieldDefinition(message);
      } else {
        await CustomFieldsStore.addCustomFieldDefinition(request);
      }
    }
  }

  async saveDefinition() {
    this.checkForm();
    if (!this.formHasErrors) {
      CustomFieldsStore.clearErrMessages();
      this.saving = true;
      await this.prepareDefinition();
      if (!this.errors.length) {
        this.goToConfiguration();
      }
      this.saving = false;
    }
  }

  async removeDefinition() {
    try {
      if (this.customField && this.customField.id) {
        CustomFieldsStore.setLoading(true);
        await CustomFieldsApi.deleteCustomField(this.customField.id);
        const obj = {
          type: translate('common.success'),
          title: translate('settings-custom-fields.configuration-removed'),
          message: translate('settings-gds.info-removed')
        };
        EventHandler.$emit('show-toast', obj);
      }
    } catch (error) {
      CustomFieldsStore.setErrMessages(error);
    } finally {
      if (!this.errors.length) {
        this.goToConfiguration();
      }
      CustomFieldsStore.setLoading(false);
    }
  }

  checkForm() {
    this.formHasErrors = false;
    this.$v.customField.$touch();
    if (this.$v.customField.$pending || this.$v.customField.$error) {
      this.formHasErrors = true;
    }
  }

  mapFormData() {
    if (this.customField.id) { // field exists, look for value to bind
      const result = this.valueTypes.find(type => {
        return type.id === this.customField.typeId;
      });
      if (result) {
        this.customField.valueType = result;
      }
      const modesMapped = {};
      this.customField.collectionModes.forEach(e => {
        modesMapped[e] = true;
      });
      this.customField.collectionModes = modesMapped;
      if (this.customField.customFieldDictionaryId) {
        const dictionaryFound = this.dictionaries.find(e => {
          return e.id === this.customField.customFieldDictionaryId;
        });
        this.customField.dictionary = dictionaryFound ? dictionaryFound : null;
      }
    }
  }

  created() {
    CustomFieldsStore.clearErrMessages();
    if (!this.configuration.name) { // no configuration data, return to config route
      if (this.$route.params.configurationId === undefined) {
        router.push({
          name: 'fields-configuration',
          params: {
            id: this.$route.params.id,
          }
        });
      } else {
        router.push({
          name: 'field-configuration',
          params: {
            id: this.$route.params.id,
            configurationId: this.$route.params.configurationId,
          }
        });
      }
    } else {
      this.mapFormData();
    }
  }
}
