















































import { Vue, Component, Watch } from 'vue-property-decorator';
import CarSort from './CarSort.vue';
import LayoutStore from '@/modules/layout/layout.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import CarSearchStore from './car-search.store';

@Component({
  components: {
    CarSort,
  }
})
export default class CarSubintro extends Vue {
  toggled: boolean = true;

  get sidebarToggled() {
    return !LayoutStore.sidebarHidden;
  }

  get filters() {
    return CarSearchStore.filters;
  }

  @Watch('sidebarToggled', { immediate: true })
  onToggled(value) {
    this.toggled = value;
  }

  @Watch('toggled')
  onToggledClick(value) {
    if (value !== this.sidebarToggled) {
      LayoutStore.toggleSidebar();
    }
  }

  showMobileFilters() {
    router.push({
      name: 'carFilters',
    });
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}
