import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { ProfileSyncApi } from '@/api/profile/profile-sync.api';
import {
  ProfileSyncConfiguration,
  ProfileSyncConfigurationEntry
} from '@/api/profile/profile-sync.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'profile-sync'
})
class ProfileSyncStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  profileSyncConfiguration: ProfileSyncConfiguration = new ProfileSyncConfiguration();
  profileSyncConfigurationItems: ProfileSyncConfigurationEntry[] = [];
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get ProfileSyncConfiguration() {
    return this.profileSyncConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setProfileSyncConfiguration(payload) {
    this.profileSyncConfiguration = payload;
  }

  @Mutation
  setProfileSyncConfigurationItems(payload) {
    this.profileSyncConfigurationItems = payload;
  }

  @Mutation
  setName(value) {
    this.profileSyncConfiguration.configurationData.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'profile-sync-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  clearData() {
    this.setProfileSyncConfiguration(new ProfileSyncConfiguration());
    this.setCurrentConfigurationName('');
  }

  @Action
  async getProfileSyncConfiguration(configurationId) {
    try {
      this.setCustomError(false);
      this.setLoading(true);
      this.setShowError(false);
      if (configurationId) {
        const result = await ProfileSyncApi.getConfiguration(configurationId);
        if (result && result.data) {
          this.setProfileSyncConfiguration(result.data);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getEmptyConfiguration() {
    try {
      this.setLoading(true);
      this.setShowError(false);
      const result = await ProfileSyncApi.getEmpty();
      if (result && result.data) {
        this.setProfileSyncConfiguration(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addProfileSyncConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result: any = await ProfileSyncApi.createConfiguration(request);
      if (result && result.data) {
        this.setConfigurationId(result.data.configurationId);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-profile-sync.config-saved')
        });

        this.getProfileSyncConfiguration(result.data.configurationId);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateProfileSyncConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await ProfileSyncApi.updateConfiguration(data.configurationId, data.params);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-profile-sync.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(ProfileSyncStore);
