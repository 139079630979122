
































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import { router } from '@/router';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ProfileUpdateMessage, ProfileAssignCompanyMessage, ProfileResponseActionStatusType } from '@/api/profile/profile.model';
import { CompanyModel } from '@/api/profile/company.model';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import { TravellerTypeOptions } from '@/const/profile-personal.const';
import EventHandler from '@/services/event-handler';
import settings from '@/settings';
import settingsStore from '@/modules/settings/settings.store';
import profileStore from '@/modules/profile/profile.store';
import formatDateMoment from '@/filters/format-data-moment.filter';
import ChangeAvatarPopup from '../avatar-change/ChangeAvatarPopup.vue';
import CreateAccountPopup from './CreateAccountPopup.vue';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';


@Component({
  components: {
    ChangeAvatarPopup,
    CreateAccountPopup,
  }
})
export default class PersonalInformation extends Vue {
  Form: any = {
    title: {
      value: '',
      name: '',
    },
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    primaryEmail: '',
    companyId: '',
    creationDate: null,
    company: null,
    rootCompanyName: '',
    displayLanguage: '',
    travellerType: null,
    externalId: '',
    invoicingId: null,
    gdsRecordLocator: '',
    travellerCategories: [],
    isGuest: false,
  };
  titleOptions = [
    {
      value: 'Mr',
      name: translate('common.mr'),
    },
    {
      value: 'Mrs',
      name: translate('common.mrs'),
    },
    {
      value: 'Miss',
      name: translate('common.miss'),
    },
  ];
  stateOptions: any = [
    {
      value: 'Active',
      name: translate('profile-personal.active'),
    },
    {
      value: 'Inactive',
      name: translate('profile-personal.inactive'),
    },
  ];
  travellerGroupOptions: any = [
    {
      value: null,
      color: '',
    },
    {
      value: 'A',
      color: '#66CC00',
    },
    {
      value: 'B',
      color: '#0080FF',
    },
    {
      value: 'C',
      color: '#ffcc00',
    },
    {
      value: 'D',
      color: '#7F00FF',
    },
    {
      value: 'E',
      color: '#990000',
    },
  ];
  guestOptions: any[] = [
    {
      value: true,
      name: translate('profile-personal.guest'),
    },
    {
      value: false,
      name: translate('profile-personal.regular'),
    },
  ];
  currentTitle: string = '';
  currentFirstName: string = '';
  currentMiddleName: string = '';
  currentLastName: string = '';
  existAccountId: boolean = false;
  companiesLoading: boolean = false;
  profileId: string | null = null;
  companiesFromQuery: CompanyModel[] = [];
  languageOptions: LanguagesModel[] = [];
  delayTimer: any | undefined = null;
  serverErrors: any[] = [];
  avatarErrors: any[] = [];
  accountName: string = '';
  $v;
  formPending: boolean = false;
  showChangeAvatarPopup: boolean = false;
  removeAvatarPopup: boolean = false;
  showCreateAccountPopup: boolean = false;
  rootCompanyId: string = '';
  isEmailConfirmed: boolean = true;
  invitationEmailSending: boolean = false;
  travellerCategoriesOptions: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  canEditTravellerCategories: boolean = false;

  get user() {
    return AccountStore.current;
  }

  get userData() {
    return profileStore.userData;
  }

  get currentProfile() {
    return profileStore.userProfile;
  }

  get companies() {
    return this.companiesFromQuery;
  }

  get currentLanguage() {
    return AccountStore.currentLanguage;
  }

  get currentCompany() {
    return settingsStore.currentCompany;
  }

  get companyIdForAutocomplete() {
    return settingsStore.currentCompany ? settingsStore.currentCompany.companyId : AccountStore.current!.profile.companyId;
  }

  get language() {
    if (this.Form.displayLanguage && this.languageOptions) {
      return this.languageOptions.find((option) => {
        return option.code === this.Form.displayLanguage;
      });
    }
  }

  get profile() {
    return profileStore.userProfile;
  }

  get currentUser() {
    if (this.currentFirstName && this.currentLastName) {
      return { 
        firstName: this.currentFirstName,
        lastName: this.currentLastName,
        id: this.profileId,
      };
    } else {
      return {
        firstName: this.user!.profile.firstName,
        lastName: this.user!.profile.lastName,
        id: this.profileId,
      };
    }
  }

  get userHasAvatar() {
    let avatarObject = AccountStore.avatarCollection.find(el => {
      return el.id === this.profileId;
    });
    return avatarObject && avatarObject.avatar;
  }

  set language(option) {
    if (option) {
      this.Form.displayLanguage = option.code;
    }
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get travellerTypeOptions() {
    return TravellerTypeOptions.map(traveller => {
      return {
        ...traveller,
        name: translate(traveller.name)
      };
    });
  }

  get userBasicData() {
    return AccountStore.userBasicData;
  }

  get titleRequired() {
    return this.Form.travellerType && -1 === [
      'InfantWithoutSeat',
      'Child',
    ].indexOf(this.Form.travellerType.value);
  }

  get communityViewUrl() {
    return sanitizeUrl(`/settings/${this.Form.companyId}/community?unitId=${this.Form.companyId}`);
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        title: {
          value: {
            required: requiredIf(() => this.titleRequired),
          }
        },
        firstName: {
          required,
          isNameValid,
          maxLength: maxLength(100),
        },
        middleName: {
          isNameValid: (value) => (value ? isNameValid(value) : true),
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          isNameValid,
          maxLength: maxLength(255),
        },
        travellerType: {},
        externalId: {
          maxLength: maxLength(100),
        },
        invoicingId: {
          maxLength: maxLength(128)
        },
        isGuest: {},
      },
    };
  }

  goToCommunityView() {
    const id = this.Form.companyId || '';

    this.$router.push({
      name: 'community',
      params: {
        id,
      },
      query: {
        unitId: id,
      },
    });
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  createAccount() {
    this.showCreateAccountPopup = true;
  }

  accountRedirect(active) {
    if (active) {
      return;
    }
    window.open(settings.manageAccount, '_self');
  }

  updateCurrentData(profile) {
    if (profile.title) {
      this.currentTitle = profile.title.name ? profile.title.name : '';
    } else {
      this.currentTitle = '';
    }
    this.currentFirstName = profile.firstName;
    this.currentMiddleName = profile.middleName;
    this.currentLastName = profile.lastName;
    this.Form.dateOfBirth = profile.dateOfBirth ? moment(profile.dateOfBirth).toDate() : null;
    this.Form.creationDate = profile.creationDate ? moment(profile.creationDate).toDate() : null;
  }

  async loadCompanies(phrase: string) {
    if (this.Form.company && this.$hasAccess('ReadProfilePersonal')) {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        try {
          const result = await ProfileCompanyApi.getCompanyUnits(this.Form.company!.rootId, phrase, Permission.ReadProfilePersonal);

          let sortedHierarchy: CompanyModel[] = [];
          sortedHierarchy = result.data;

          this.companiesFromQuery = sortedHierarchy;
        } catch (error) {
          this.serverErrors = this.$handleErrors(error, true);
        }
      }, 200);
    }
  }

  async loadLanguages() {
    if (this.$hasAccess('ReadProfilePersonal')) {
      try {
        const result = await LanguagesApi.getLanguages();
        if (result && result.data) {
          this.languageOptions = result.data;
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('$route', { immediate: true , deep: true})
  async onchangeRoute() {
    this.serverErrors = [];
    this.avatarErrors = [];
    await this.fetchData();
  }

  async resendInvitationEmail() {
    if (!this.profileId || this.invitationEmailSending) {
      return;
    }
    try {
      this.invitationEmailSending = true;
      const response = await ProfileApi.resendInvitationEmail(this.profileId);
      if (response && response.data) {
        if (response.data.actionStatus === ProfileResponseActionStatusType.Errors) {
          EventHandler.$emit('show-toast', {
            type: 'error',
            title: translate('common-error.error'),
            message: translate('profile-personal.resend-invitation-email-error'),
          });
        } else if (response.data.actionStatus === ProfileResponseActionStatusType.Successfull) {
          EventHandler.$emit('show-toast', {
            type: translate('common.success'),
            title: translate('common.success'),
            message: translate('profile-personal.resend-invitation-email-success'),
          });
          this.isEmailConfirmed = true;
        }
      }
    } catch (error) {
      EventHandler.$emit('show-toast', {
        type: 'error',
        title: translate('common-error.error'),
        message: translate('profile-personal.resend-invitation-email-error'),
      });
    } finally {
      this.invitationEmailSending = false;
    }
  }

  createForm() {
    this.rootCompanyId = this.profile ? this.profile.rootCompanyId || '' : '';

    if (!this.$hasAccess('CanAssignTravellerCategories')) {
      this.travellerCategoriesOptions = this.profile!.travellerCategories.map(category => {
        return {
          value: category.name,
          label: category.name,
          id: category.id,
        };
      });
    }
    this.Form = {
      title: this.titleOptions.find(opt => {
        return opt.value === (this.profile!.title ? this.profile!.title.name : '');
      }),
      firstName:  this.profile!.firstName ? this.profile!.firstName.trim() : '',
      lastName: this.profile!.lastName ? this.profile!.lastName.trim() : '',
      middleName: this.profile!.middleName ? this.profile!.middleName.trim() : '',
      dateOfBirth: this.profile!.dateOfBirth ? moment(this.profile!.dateOfBirth).toDate() : null,
      creationDate: this.profile!.creationDate ? moment(this.profile!.creationDate).toDate() : null,
      rootCompanyName: this.profile!.rootCompanyName,
      displayLanguage: this.profile!.displayLanguage,
      primaryEmail: this.profile!.primaryEmail ? this.profile!.primaryEmail : '',
      externalId: this.profile!.externalId,
      invoicingId: this.profile!.invoicingId,
      gdsRecordLocator: this.profile!.company.gdsRecordLocator,
      company: null,
      companyId: null,
      travellerType: this.travellerTypeOptions.find(type => type.value === this.profile!.travellerType),
      state: this.stateOptions.find(opt => {
        return opt.value === this.profile!.state;
      }),
      travellerCategories: this.travellerCategoriesOptions.filter(category => !!this.profile!.travellerCategories.find(c => c.id === category.id)),
      isGuest: this.guestOptions.find(guest => guest.value ===  this.profile!.isGuest),
    };
    this.updateData();
  }

  updateData() {
    this.updateCurrentData(this.profile);
    if (this.userData && this.userData.company) {
      this.companiesFromQuery.push(this.userData.company);
      this.Form.company = this.userData.company;
      this.Form.companyId = this.userData.company.id;
    }
  }

  setUserDate(email) {
    AccountStore.setUserBasicData({
      ...email.data,
      accountId: this.profile!.accountId,
    });
    this.accountName = email.data.userName;
    this.isEmailConfirmed = email.data.isConfirmed;
  }

  async getTravellerCategories() {
    try {
      const response = await TravellerCategoriesApi.getAvailableTravellerCategoriesForProfile(this.$route.params.id);

      this.canEditTravellerCategories = response.data.canEditTravellerCategories;
      this.travellerCategoriesOptions = response.data.travellerCategories.map(category => {
        return {
          value: category.name,
          label: category.name,
          id: category.id,
        };
      });
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async getProfileData() {
    try {
      this.profileId = router.currentRoute.params.id;
      this.accountName = this.user ? this.user.accountName : '';
      
      if (this.profile && this.userBasicData && this.userBasicData.accountId === this.profile.accountId) {
        this.accountName = this.userBasicData.userName;
        this.isEmailConfirmed = this.userBasicData.isConfirmed;
      } else if (this.profile!.accountId && this.user && this.user.profile.accountId !== this.profile!.accountId) {
        const email = await ProfileApi.getActiveInfo(this.profile!.id, this.profile!.accountId);
        if (email && email.data) {
          this.setUserDate(email);
        }
      } else if (this.user!.isSwitched && this.user!.profile && this.user!.profile.accountId) {
        const email = await ProfileApi.getActiveInfo(this.user!.profile.id, this.user!.profile.accountId);
        if (email && email.data) {
          this.accountName = email.data.userName;
        }
      }
      this.existAccountId = !!this.profile!.accountId;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  async fetchData() {
    this.loadLanguages();
    if (this.$hasAccess('CanAssignTravellerCategories')) {
      await this.getTravellerCategories();
    }
    await this.getProfileData();

    this.createForm();

    if (this.$hasAccess('WriteProfileUnitChange')) {
      this.loadCompanies('');
    }
  }

  catchError(error) {
    if (
      error && error.response && error.response.data && error.response.data.error &&
      'DISABLING_OWN_PROFILE' === error.response.data.error.code
    ) {
      this.serverErrors = [{
        message: translate('settings-community.unable-to-delete-or-disable'),
      }];
    } else {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  requestProfile() {
    this.Form.firstName = this.Form.firstName.trim();
    this.Form.lastName = this.Form.lastName.trim();

    if (this.Form.middleName) {
      this.Form.middleName =  this.Form.middleName.trim();
    }
    
    return {
      ...this.Form,
      dateOfBirth: formatDateMoment(this.Form.dateOfBirth),
      title: this.Form && this.Form.title && this.Form.title.value ? this.Form.title.value : '',
      externalId: this.Form.externalId ? this.Form.externalId : '',
      invoicingId: this.Form.invoicingId ? this.Form.invoicingId : null,
      gdsRecordLocator: undefined,
      state: this.Form && this.Form.state && this.Form.state.value,
      travellerType: this.Form.travellerType ? this.Form.travellerType.value : null,
      travellerCategories: this.Form.travellerCategories.map(category => ({
        id: category.id,
        name: category.value,
      })),
      isGuest: this.Form.isGuest ? this.Form.isGuest.value : false,
    };
  }

  async submit() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }
    this.formPending = true;

    try {
      const profileId = router.currentRoute.params.id;

      if (this.$hasAccess('WriteProfilePersonal')) {
        let request = this.requestProfile();
        await ProfileApi.updateProfile(
          profileId,
          request,
        );
        const obj = {
          type: 'success',
          title: translate('profile-personal.data-saved'),
          message: translate('profile-personal.general-saved')
        };

        this.updateCurrentData(this.Form);
        EventHandler.$emit('show-toast', obj);
        EventHandler.$emit('profile-data-saved');
      }

      if (this.$hasAccess('WriteProfileUnitChange')) {
        await this.assignCompanyToUser();
      }

      if (this.user!.profile.id === profileId) {
        if (this.currentLanguage !== this.Form.displayLanguage) {
          location.reload();
        } else {
          AccountStore.init();  // refresh current user info if we were changing current user data
        }
      }
    } catch (error) {
      this.catchError(error);
    } finally {
      this.formPending = false;
    }
  }

  async assignCompanyToUser() {
    const profileId = router.currentRoute.params.id;
    let assignCompanyMessage: ProfileAssignCompanyMessage = {
      companyId: this.Form && this.Form.company ? this.Form.company.id : ''
    };

    try {
      await ProfileApi.assignCompanyToProfile(
        profileId,
        assignCompanyMessage,
      );
      const obj = {
        type: this.$t('profile-personal.success'),
        title: this.$t('profile-personal.data-saved'),
        message: this.$t('profile-personal.unit-saved')
      };
      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  focusOnGeneralSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.generalSaveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  onSelectedUnit() {
    const selection = this.$refs.ProfileUnit as Vue;
    const selectionEl = selection.$el.getElementsByTagName('input')[0] as HTMLElement;

    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    } 
  }

  async removeAvatar() {
    let avatarErrors = [];
    try {
      await ProfileApi.removeAvatars(router.currentRoute.params.id);
    } catch (error) {
      this.avatarErrors = this.$handleErrors(error);
    } finally {
      if (!avatarErrors.length) {
        AccountStore.setProfileAvatar({
          id: router.currentRoute.params.id,
          avatar: '',
          usageTimestamp: new Date().getTime(),
        });
      this.removeAvatarPopup = false;
      }
    }
  }

  refreshProfile() {
    this.fetchData();
  }

  avatarUploaded() {
    this.showChangeAvatarPopup = false;
    EventHandler.$emit('show-toast', {
      type: 'success',
      title: this.$t('profile-personal.avatar-uploaded'),
      message: this.$t('profile-personal.uploaded-successfully'),
    });
  }

  onPopupClose() {
    this.showChangeAvatarPopup = false;
  }

  closeAvatarPopup() {
    this.showChangeAvatarPopup = false;
  }

  created() {
    EventHandler.$on('close-avatar-popup', this.closeAvatarPopup);
    EventHandler.$on('avatar-uploaded', this.avatarUploaded);
    EventHandler.$on('refresh-profile', this.refreshProfile);
  }

  beforeDestroy() {
    EventHandler.$off('close-avatar-popup', this.closeAvatarPopup);
    EventHandler.$off('avatar-uploaded', this.avatarUploaded);
    EventHandler.$off('refresh-profile', this.refreshProfile);
  }
}

