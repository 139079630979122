

























































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { ExpenseCategoriesApi } from '@/api/expense/expense-categories.api';
import { ExpenseCategoryModel } from '@/api/expense/expense-categories.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class ExpenseCategory extends BaseTable<ExpenseCategoryModel> {
  @Prop({}) readonly!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  currentPage: number = 1;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: any | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-custom-fields.name'),
    },
    description: {
      sortable: false,
      label: translate('settings-custom-fields.description'),
    },
    code: {
      sortable: false,
      label: translate('settings-custom-fields.code'),
    },
    active: {
      sortable: false,
      label: translate('settings-custom-fields.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get entries() {
    return ExpensePolicyStore.expenseCategories;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.entries.length || 0;
  }

  toggleMenu(item) {
    if (this.selected !== item) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    router.push({
      name: 'expense-category',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: this.$route.params.itemId,
        configurationId: this.$route.params.configurationId,
        categoryId: item.id
      }
    });
  }

  async removeField() {
    try {
      await ExpenseCategoriesApi.removeExpenseCategory(this.selected.id);
      this.removePopup = false;
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-removed'),
        message: translate('settings-expense.configuration-removed-category')
      };
      EventHandler.$emit('show-toast', obj);
      ExpensePolicyStore.getExpenseCategories(this.selected.policyId);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }
}
