


























































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';

import settings from '@/settings';
import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AccountStore from '@/store/account.store';
import BasketAirOffer from './BasketAirOffer.vue';
import BasketAirAmadeusRefundView from './BasketAirAmadeusRefundView.vue';
import BasketCancellationStore from './basket-cancellation.store';
import { userFullName } from '@/core/user-full-name';
import { RefundActionStatuses } from '../search/air/air-refund.model';
import LayoutStore from '../layout/layout.store';

const eventName = 'update:show';

@Component({
  components: {
    BasketAirOffer,
    BasketAirAmadeusRefundView,
  },
})
export default class BasketAirAmadeusRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;
  @Prop() recomedationId!: string;

  selectedOption: any = '';
  refundMode: string = '';
  $v;
  shouldShowCommissionErrors: boolean = false;
  commissionMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
  });
  refundErrors: any[] = [];

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };



  get isRefundAlwaysAvailable() {
    return 'true' === settings.isRefundAlwaysAvailable;
  }

  get couponsStatus() {
    return BasketCancellationStore.couponsStatus;
  }

  get loadingCoupons() {
    return BasketCancellationStore.loadingCoupons;
  }

  get couponsLoaded() {
    return BasketCancellationStore.couponsLoaded;
  }

  get processing() {
    return BasketCancellationStore.processing;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get loadingDetails() {
    return BasketCancellationStore.loadingDetails;
  }

  get isMobile() {
    return window.innerWidth <= 799.98;
  }

  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get processErrors() {
    return BasketCancellationStore.processErrors;
  }

  get showError() {
    return BasketCancellationStore.showError;
  }

  get statusCount() {
    return this.couponsStatus.length;
  }

  get canCancelTicket() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get canRefundFlightTicket() {
    return AccountStore.HasPermission('CanRefundAmadeusFlightTicket');
  }

  get isVoidAvailableWithoutCouponStatus() {
    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get lufthansaCancelationsDetails() {
    return BasketCancellationStore.lufthansaCancelationDetails;
  }

  get lufthansaRefundDetailsLoaded() {
    return BasketCancellationStore.lufthansaRefundDetailsLoaded;
  }

  get airDetailsInfo() {
    return BasketCancellationStore.airDetailsInfo;
  }

  get isVoidAvailable() {
    if ('Exchanged' === this.item.statusTag) {
      return false;
    }
    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  get isRefundAvailable() {
    if (this.isRefundAlwaysAvailable) {
      return true;
    }

    if (!this.isValidLhCouponStatus) {
      return false;
    }

    for (const status of this.couponsStatus) {
      if (!status.isVoidAvailable) {
        return true;
      }
    }
  }

  get isValidLhCouponStatus() {
    const coupon = this.couponsStatus.find(item => item.isValidLhCouponStatus !== null);
    if (!coupon) {
      return true;
    }
    return coupon.isValidLhCouponStatus;
  }



  userFullName(user) {
    return userFullName(user);
  }

  issueDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  @Watch('actionType', { immediate: true })
  onChangeAction(val) {
    if (-1 < [
      RefundActionStatuses.AmadeusCheckConditions,
    ].indexOf(val.code)) {
      this.loadRefundDetails();
      BasketCancellationStore.setProcessing(false);
    } else if (val.code === RefundActionStatuses.VerifyStatus) {
      this.init();
      BasketCancellationStore.setProcessing(false);
    }
  }

  async loadRefundDetails() {
    await BasketCancellationStore.getOfferCancelationConditions(this.recomedationId);
  }

  async beginVoid() {
    BasketCancellationStore.setProcessErrors([]);
    BasketCancellationStore.setProcessWarning(null);
    BasketCancellationStore.setShowError(false);
    BasketCancellationStore.setProcessing(true);
    let voidResponse;
    try {
      voidResponse = await RefundBasketItemApi.startAmadeusVoid(this.basketItemId);
    } catch (error) {
      BasketCancellationStore.setProcessErrors(error);
      BasketCancellationStore.setShowError(true);
    } finally {
      if (voidResponse && voidResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      BasketCancellationStore.setProcessing(false);
    }
  }

  async beginRefund() {
    BasketCancellationStore.setProcessErrors([]);
    BasketCancellationStore.setProcessWarning(null);
    BasketCancellationStore.setShowError(false);
    BasketCancellationStore.setProcessing(true);
    let initResponse;
    BasketCancellationStore.setCouponsLoaded(false);
    try {
      initResponse  = await RefundBasketItemApi.startRefund(this.basketItemId);
    } catch (error) {
      BasketCancellationStore.setProcessErrors(error);
      BasketCancellationStore.setShowError(true);
      BasketCancellationStore.setProcessing(false);
    } finally {
      if (initResponse && initResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
    }
  }

  async finalizeRefund() {
    this.refundErrors = [];
    BasketCancellationStore.setProcessing(true);
    let refundResponse;
    try {
      refundResponse = await RefundBasketItemApi
        .acceptRefundCancellationConditions(this.basketItemId);
    } catch (error) {
      this.refundErrors = this.$handleErrors(error);
      setTimeout(() => {
        LayoutStore.scrollManager.scrollTo((this.$refs.refundErrors as Vue).$el, false);
      }, 300);
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      BasketCancellationStore.setProcessing(false);
    }
  }

  async exitRefundPopup() {
    this.refundErrors = [];
    let refundResponse;
    BasketCancellationStore.setProcessing(true);
    try {
      refundResponse = await RefundBasketItemApi.rejectRefund(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');

      }
      BasketCancellationStore.setProcessing(false);
      this.hidePopup();
    }
  }

  continueNow() {
    const actionsByOption = {
      Void: this.beginVoid,
      Refund: this.beginRefund,
    };
    const action = actionsByOption[this.selectedOption];
    if (action !== undefined) {
      action();
    }
  }

  async init() {
    BasketCancellationStore.setLufthansaRefundDetailsLoaded(false);
    await BasketCancellationStore.getAirDetails(this.item.providerReferenceId);
    await BasketCancellationStore.getItemCoupons(this.basketItemId);

    if (this.isVoidAvailable) {
      this.selectedOption = 'Void';
    } else if (this.isRefundAvailable) {
      this.selectedOption = 'Refund';
    } else {
      this.selectedOption = '';
    }
  }

  created() {
    BasketCancellationStore.setProcessing(false);
    this.init();
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

