import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import TravelPolicyFlightStore from './travel-policy-flight.store';
import TravelPolicyRailStore from './travel-policy-rail.store';
import TravelPolicyAccomodationStore from './travel-policy-accomodation.store';
import TravelPolicyCarStore from './travel-policy-car.store';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'travelPolicy'
})
class TravelPolicystore extends VuexModule {
  currentSelectedItem: string = 'Flight';
  currentConfigName: string = '';
  loading: boolean = false;
  errors: any[] = [];
  isFromManage: boolean = false;
  customError: boolean = false;
  isPostSalesEnabledInNonCompliantBooking: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get getCurrentSelectedItem() {
    return this.currentSelectedItem;
  }

  get getErrors() {
    return this.errors;
  }

  @Mutation
  setCurrentConfigName(payload) {
    this.currentConfigName = payload;
  }

  @Mutation
  setIsPostSalesEnabledInNonCompliantBookingMutation(value) {
    this.isPostSalesEnabledInNonCompliantBooking = value;
  }

  @Action
  setIsPostSalesEnabledInNonCompliantBooking(value) {
    this.setIsPostSalesEnabledInNonCompliantBookingMutation(value);
    TravelPolicyFlightStore.setIsPostSalesEnabledInNonCompliantBooking(value);
    TravelPolicyRailStore.setIsPostSalesEnabledInNonCompliantBooking(value);
    TravelPolicyAccomodationStore.setIsPostSalesEnabledInNonCompliantBooking(value);
    TravelPolicyCarStore.setIsPostSalesEnabledInNonCompliantBooking(value);
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setIsFromManage(payload) {
    this.isFromManage = payload;
  }

  @Mutation
  clearErrors() {
    this.errors = [];
  }

  @Mutation
  setErrors(payload) {
    this.errors = $handleErrors(payload, true);
  }

  @Mutation
  setCurrentSelectedItem(payload) {
    this.currentSelectedItem = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }
}

export default getModule(TravelPolicystore);