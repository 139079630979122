import NegoFareConfigurations from '@/modules/settings/nego-fare/NegoFareConfigurations.vue';

export default {
  path: '',
  name: 'nego-fare-configurations',
  component: NegoFareConfigurations,
  displayName: 'Nego fare configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadNegoFare',
    depthCategory: 'nego-fare',
    depth: 1,
    waitForDicts: true,
    parentName: 'nego-fare-configurations',
  },
};