













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IOfferChange } from '@/api/air-engine/air-search.model';
import { userFullName } from '@/core/user-full-name';

type TTraveller = {
  firstName: string;
  isMainTraveller: boolean;
  lastName: string;
  middleName: string;
  profileId: string;
  sortOrder: number;
};
type TPrice = {
  Amount: string;
  Currency: {
    Code: string,
    Symbol: string,
  };
};

const PRICE_REGEXP = /[Pp]rice/;

@Component({})
export default class NotificationAFHistoryItem extends Vue {
  @Prop() offerChange!: IOfferChange;
  @Prop() travellers!: TTraveller[];

  get passenger() {
    return this.travellers.find((item) => item.profileId === this.offerChange.additionalTag);
  }

  get userFullName() {
    return userFullName(this.passenger);
  }

  get isInlineTitle() {
    return ['CancelledFlight', 'NewFlight'].indexOf(this.parsedOffer.changeType) > -1;
  }

  get isPrice() {
    return PRICE_REGEXP.test(this.offerChange.changeType);
  }

  get valueFrom(): Array<string> | string | TPrice {
    let parsedValueFrom = '';
    try {
      parsedValueFrom = JSON.parse(this.offerChange.valueFrom);
    } catch (error) {
      parsedValueFrom = this.offerChange.valueFrom;
    }
    return parsedValueFrom;
  }

  get valueTo(): Array<string> | string | TPrice {
    let parsedValueTo = '';
    try {
      parsedValueTo = JSON.parse(this.offerChange.valueTo);
    } catch (error) {
      parsedValueTo = this.offerChange.valueTo;
    }
    return parsedValueTo;
  }

  get parsedOffer() {
    return {
      ...this.offerChange,
      valueFrom: this.valueFrom,
      valueTo: this.valueTo,
    };
  }

  get priceFrom() {
    if (this.isPrice) {
      const valueFrom = this.parsedOffer.valueFrom as TPrice;
      return {
        amount: valueFrom.Amount,
        currency: {
          code: valueFrom.Currency.Code,
          symbol: valueFrom.Currency.Symbol,
        }
      };
    }
  }

  get priceTo() {
    if (this.isPrice) {
      const valueTo = this.parsedOffer.valueTo as TPrice;
      return {
        amount: valueTo.Amount,
        currency: {
          code: valueTo.Currency.Code,
          symbol: valueTo.Currency.Symbol,
        }
      };
    }
  }
}
