












import { Vue, Component } from 'vue-property-decorator';

import { BasketItemApi } from '@/api/trip/basket-item.api';
import { router } from '@/router';
import BasketStore from './basket.store';

@Component({})
export default class BasketPayment extends Vue {
  serverErrors: any[] = [];
  loading: boolean = false;

  get paymentMethodId() {
    return this.$route.query.paymentMethodId as string;
  }

  async loadBasketPayment() {
    this.serverErrors = [];
    let tripItemId = this.$router.currentRoute.query.tripItemId as string;

    try {
      const response = await BasketItemApi.basketPaymentVerificationV2(tripItemId, this.paymentMethodId);

      if (response && response.data) {
        const basketId = response.data.basketId;

        if (response.data.isPaymentTerminated) {
          BasketStore.setShowPaymentTerminatedPopup(true);
          BasketStore.setPaymentTerminatedBasketId(basketId);
        }

        router.push({
          name: 'basket',
          params: { id: basketId }
        });
        this.loading = false;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.loading = true;
    this.loadBasketPayment();
  }

}
