import CustomFields from '@/modules/settings/custom-fields-configuration/CustomFields.vue';
import CustomFieldsConfigurations from './custom-fields-configurations.route';
import CustomFieldConfiguration from './field-configuration.route';
import CustomFieldDefinition from './custom-field-definition.route';
import CustomFieldDictionaryDefinition from './custom-field-dictionary-definition.route';
import CustomFieldTravelAssignment from './custom-field-travel-assignment.route';
import CustomFieldProviderMapping from './custom-field-provider-mapping.route';

export default {
  path: 'custom-fields',
  component: CustomFields,
  displayName: 'settings-sidebar.custom-fields',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCustomFieldDefinitions',
    icon: 'input',
    breadCrumb: `Custom fields' configurations' list`,
    parentName: 'custom-fields',
  },
  children: [
    CustomFieldsConfigurations,
    CustomFieldConfiguration,
    CustomFieldDefinition,
    CustomFieldDictionaryDefinition,
    CustomFieldTravelAssignment,
    CustomFieldProviderMapping,
  ],
};