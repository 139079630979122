










































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VueContentLoading from 'vue-content-loading';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { translate, translationExists } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import TrainSearchStore from '@/modules/search/train/train-search.store';
import { Train } from '@/api/train-engine/train-search.model';
import { TrainApi } from '@/api/train-engine/train-search.api';
import accountStore from '@/store/account.store';

@Component({
  components: {
    VueContentLoading,
  }
})
export default class TrainFareConditions extends Vue {
  @Prop() train!: Train;
  @Prop() fareCondition!: string;
  @Prop() fareId!: string;
  @Prop() legNumber!: number;
  @Prop() offer!: any;
  @Prop() offerId!: string;
  @Prop() searchId!: string;
  @Prop() provider!: string;
  @Prop() searchMode!: boolean;
  @Prop() proposal!: any;

  loading: boolean = true;
  errors: any[] = [];
  data: any = null;

  get orderId() {
    return TrainSearchStore.exchangedRecommendationId;
  }

  get trains() {
    const segmentKey = this.proposal.segmentKey;
    const p = [];

    return p.concat.apply(p, this.offer.journeys.map(journey => {
      return journey.trains.filter(train => train.segmentKey === segmentKey);
    }));
  }

  get generalConditionsUrl() {
    if (!this.data || !this.data.generalConditionsUrl) {
      return '';
    }

    return sanitizeUrl(this.data.generalConditionsUrl);
  }

  get rulesOfSecondContactUrl() {
    if (!this.data || !this.data.rulesOfSecondContactUrl) {
      return '';
    }

    return sanitizeUrl(this.data.rulesOfSecondContactUrl);
  }

  get conditionsUrls() {
    return {
      en: 'https://www.sncf.com/en/customer-service/delays',
      fr: 'https://www.sncf.com/fr/service-client/en-cas-de-retard',
      it: 'https://www.sncf.com/en/customer-service/delays',
      pl: 'https://www.sncf.com/en/customer-service/delays',
    };
  }

  get currentLanguageUrl() {
    const LanguageUrl = this.conditionsUrls;

    return sanitizeUrl(LanguageUrl[accountStore.currentLanguage]);
  }
  

  userFullName(user) {
    return userFullName(user);
  }

  typeTranslations(label) {
    const key: string = 'typedConditions.' + label;

    if (translationExists(key)) {
      return translate(key);
    }

    return label;
  }

  async getData() {
    this.loading = true;
    try {
      let response;
      if (this.searchMode) {
        response = await TrainApi.getFareConditions(
          this.offerId,
          this.fareId,
          this.provider,
          this.searchId,
        );
      }
      else {
        response = await TrainApi.getFareConditionsForOrder(
          this.offerId ? this.offerId : this.orderId,
          this.legNumber,
          this.fareId,
          this.provider,
          this.searchId,
        );
      }

      this.data = response.data;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    if (-1 < [
      'Sncf',
      'Ouigo',
      'TrenItalia',
      'Benerail',
    ].indexOf(this.provider)) {
      this.getData();
    } else {
      this.loading = false;
    }
  }
}
