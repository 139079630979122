

































































import { Vue, Component, Prop } from 'vue-property-decorator';

import HotelSearchStore from './hotel-search.store';
import HotelOfferConditions from './HotelOfferConditions.vue';
import { Offer } from '@/api/accommodation-engine/accommodation-search.model';
import HotelRoom from './HotelRoom.vue';
import HeightTransition from '@/modules/layout/HeightTransition.vue';

@Component({
  components: {
    HotelOfferConditions,
    HotelRoom,
    HeightTransition,
  },
})
export default class HotelRooms extends Vue {
  @Prop() offers!: Offer[];
  @Prop() searchId!: string;
  @Prop() property!: any;
  @Prop() hasNoOffers!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: () => {
    return {};
  }, }) basket!: any;

  offerConditionsVisible: boolean = false;
  selectedOffer?: any = null;
  currentOffersVisible: number = 4;

  get minimalSet() {
    return this.offers.slice(0, 4);
  }

  get restOfOffers() {
    return this.offers.slice(4);
  }

  get globalClass() {
    return this.$route.meta.mobile ? 'be-booking-promo' : '';
  }

  get showMoreNumber(): number {
    return this.offers.length - this.offersCurrent.length;
  }

  get offersCurrent(): Offer[] {
    return this.offers.slice(0, this.currentOffersVisible);
  }
  
  selectOffer(offer) {
    if (this.disabled) {
      return;
    }
    HotelSearchStore.selectOffer({
      offer,
      property: this.property,
    });
  }

  showConditions(offer) {
    if (this.disabled) {
      return;
    }
    this.selectedOffer = offer;
    this.offerConditionsVisible = true;
  }

  showMore() {
    if (this.disabled) {
      return;
    }
    this.currentOffersVisible = this.offers.length;
  }

  showLess() {
    if (this.disabled) {
      return;
    }
    this.currentOffersVisible = 4;
  }
}
