
















































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, requiredIf, required } from 'vuelidate/lib/validators';

import { router } from '@/router';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import AccountStore from '@/store/account.store';
import { SupplierName } from '@/services/supplier-name.service';
import SystemMarkupConfigurationStore from './system-markup-configuration.store';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';
import EventBus from '@/services/event-handler';
import { CurrencyApi } from '@/api/currency/currency.api';

const feeNumberRegex = (value) => {
  return /^(\d{1,10})(\.\d{1,2})?$/g.test(value);
};

@Component({})
export default class SystemMarkupRuleAir extends Vue {
  $v;
  Form: any = {};
  allAirline: any[] = [];
  allBookingTypes: any[] = [];
  allPaymentMethods: any[] = [];
  allBookingChannels: any[] = [];
  allSupplier: any[] = [];
  allTripTypes: any[] = [];
  allFareTypes: any[] = [];
  hasErrors: boolean = false;
  formLoaded: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  supplierOptions: any[] = [
    {
      value: 'Sabre',
      label: SupplierName('Sabre'),
    },
    {
      value: 'TravelFusion',
      label: SupplierName('TravelFusion')
    },
    {
      value: 'AirFranceKlm',
      label: SupplierName('AirFranceKlm'),
    },
    {
      value: 'Lufthansa',
      label: SupplierName('Lufthansa'),
    },
    {
      value: 'Amadeus',
      label: SupplierName('Amadeus'),
    },
    {
      value: 'BritishAirways',
      label: SupplierName('BritishAirways'),
    },
    {
      value: 'AmericanAirlines',
      label: SupplierName('AmericanAirlines'),
    },
    {
      value: 'Emirates',
      label: SupplierName('Emirates'),
    }
  ];
  bookingTypeOptions: any[] = [
    {
      value:  'Offline',
      label:  translate('settings-fees.offline')
    },
    {
      value: 'Online',
      label:  translate('settings-fees.online')
    },
  ];
  paymentMethodsOptions: any[] = [
    {
      value: 'CentralPayment',
      label: translate('settings-payment-methods.central-payment'),
    },
    {
      value: 'PaymentCard',
      label: translate('settings-payment-methods.user-payment-card'),
    },
    {
      value: 'LodgeCard',
      label: translate('settings-payment-methods.lodge-card'),
    },
    {
      value: 'PreRegisteredCard',
      label: translate('settings-payment-methods.preregistered-card'),
    },
    {
      value: 'VirtualCard',
      label: translate('settings-payment-methods.virtual-card-payment'),
    }
  ];
  bookingChannelsOptions: any[] = [
    {
      value:  'UAT',
      label:  translate('settings-fees.uat')
    },
    {
      value: 'SBT',
      label:  translate('settings-fees.sbt')
    },
  ];
  tripTypeOptions: any[] = [
    {
      value:  'Domestic',
      label:  translate('settings-travel-policy.domestic')
    },
    {
      value: 'International',
      label:  translate('settings-travel-policy.international')
    },
    {
      value: 'Intercontinental',
      label: translate('settings-travel-policy.intercontinental')
    }
  ];
  fareTypeOptions: any[] = [
    {
      value: 'Public',
      label: translate('settings-fees.public'),
    },
    {
      value: 'Private',
      label: translate('profile-contacts.private'),
    },
    {
      value: 'Nego',
      label: translate('settings-fees.nego'),
    }
  ];
  calculationMethodOptions: any[] = [
    {
      value: 'PerBooking',
      label: translate('settings-fees.per-booking'),
    },
    {
      value: 'PerPassengerInBooking',
      label: translate('settings-fees.per-passenger-in-booking'),
    },
    {
      value: 'PerLeg',
      label: translate('settings-fees.per-leg'),
    },
    {
      value: 'PerPassengerInLeg',
      label: translate('settings-fees.per-passenger-in-leg'),
    },
  ];
  whenToApplyOptions: any[] = [
    {
      value: 'MakingAReservation',
      label: translate('settings-fees.making-a-reservation'),
    },
  ];
  currencyCodes: any[] = [];
  currencyErrors: any[] = [];

  @Validation()
  validationObject() {
    return {
      Form: {
        fixedFee: {
          amount: {
            valid: (value) => {
              let val = Number(value);
              return this.Form.feeType === 'Fixed' ? value !== '' && feeNumberRegex(val) && val >= 0 && val <= 10000 : true;
            }
          },
          currency: {
            code: {
              requiredIf: requiredIf(() => {
                return this.Form.feeType === 'Fixed';
              }),
            }
          }
        },
        percentageFee: {
          valid: (value) => {
            let val = Number(value);
            return this.Form.feeType === 'Percentage' ? value !== '' && feeNumberRegex(val) && val <= 100 && val >= 0 : true;
          }
        },
        calculationMethod: {
          required,
        },
        whenToApply: {
          required,
        },
        paymentMethods: {
          required,
        },
        fareBasisCodes: {
          everyValid: (value) => {
            return this.Form.fareBasisCodesEnabled ? value.every(item => {
              return !!item;
            }) : true;
          },
          $each: {
            maxLength: maxLength(10),
          }
        }
      }
    };
  }

  get configuration() {
    return SystemMarkupConfigurationStore.CurrentConfiguration;
  }

  get currentConfigurationName() {
    return SystemMarkupConfigurationStore.configurationName;
  }

  get currentRules() {
    return SystemMarkupConfigurationStore.rulesList;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get configIsEdit() {
    return !!((this.$route.params.configurationId && this.$route.params.configurationId !== 'new') || this.configurationId);
  }

  get rule() {
    return SystemMarkupConfigurationStore.selectedRule;
  }

  get airlines() {
    return DictionaryStore.allAirLinesSorted;
  }

  get isFromManage() {
    return SystemMarkupConfigurationStore.isFromManage;
  }

  get isRuleEdited() {
    return !!(this.rule !== undefined && this.rule !== null && Object.keys(this.rule).length > 0);
  }

  get isLodgeCardSelected() {
    if (this.Form.paymentMethods) {
      if (Array.isArray(this.Form.paymentMethods) && this.Form.paymentMethods.length > 0) {
        return this.Form.paymentMethods.some(pm => pm.value === 'LodgeCard');
      }
    }
    return false;
  }

  get isCreditCardSelected() {
    if (this.Form.paymentMethods) {
      if (Array.isArray(this.Form.paymentMethods) && this.Form.paymentMethods.length > 0) {
        return this.Form.paymentMethods.some(pm => pm.value === 'PaymentCard');
      }
    }
    return false;
  }

  get user() {
    return AccountStore.current;
  }

  get classes() {
    return {
      'it-width': this.user && this.user.profile.displayLanguage === 'it',
    };
  }

  get formFixedFee() {
    return this.Form.feeType === 'Fixed' ? this.Form.fixedFee : null;
  }

  get formPercentageFee() {
    return this.Form.feeType === 'Percentage' ? this.Form.percentageFee : null;
  }

  get formCalculationMethod() {
    return this.Form.calculationMethod ? this.Form.calculationMethod.value : null;
  }

  get formPaymentMethods() {
    return this.Form.paymentMethods ? this.Form.paymentMethods.map(item => item.value ? item.value : item) : [];
  }

  get formSupplier() {
    return this.Form.supplierEnabled ? this.Form.supplier.map(item => item.value ? item.value : item) : [];
  }

  get formBookingType() {
    return this.Form.bookingTypeEnabled ? this.Form.bookingType.map(item => item.value ? item.value : item) : [];
  }

  get formBookingChannels() {
    return this.Form.bookingChannelsEnabled ? this.Form.bookingChannels.map(item => item.value ? item.value : item) : [];
  }

  get formAirlines() {
    return this.Form.airlineEnabled ? this.Form.airlines.map(item => item.code ? item.code : item) : [];
  }

  get formTripTypes() {
    return this.Form.tripTypesEnabled ? this.Form.tripTypes.map(item => item.value ? item.value : item) : [];
  }

  get formFareTypes() {
    return this.Form.fareTypesEnabled ? this.Form.fareTypes.map(item => item.value ? item.value : item) : [];
  }

  get formFareBasisCodes() {
    return this.Form.fareBasisCodesEnabled ? this.Form.fareBasisCodes : [];
  }



  bookingTypeEnabled(value) {
    if (value === false) {
      this.Form.bookingType = [];
    }
  }

  bookingChannelsEnabled(value) {
    if (value === false) {
      this.Form.bookingChannels = [];
    }
  }

  supplierEnabled(value) {
    if (value === false) {
      this.Form.supplier = [];
    }
  }

  airlineEnabled(value) {
    if (value === false) {
      this.Form.airlines = [];
    }
  }

  tripTypesEnabled(value) {
    if (value === false) {
      this.Form.tripTypes = [];
    }
  }

  fareTypesEnabled(value) {
    if (value === false) {
      this.Form.fareTypes = [];
    }
  }

  fareBasisCodesEnabled(value) {
    if (value === false) {
      this.Form.fareBasisCodes = [''];
    }
  }

  newCodeDisabled() {
    return !this.Form.fareBasisCodes.every(item => { return !!item && item.length <= 10; });
  }

  addCode() {
    if (this.Form.fareBasisCodesEnabled && !this.newCodeDisabled()) {
      this.Form.fareBasisCodes.push('');
    }
  }

  removeCode(index) {
    if (this.Form.fareBasisCodesEnabled) {
      this.Form.fareBasisCodes.splice(index, 1);
    }
  }

  checkForm() {
    this.hasErrors = false;
    this.$v.Form.$touch();
    if (this.$v.Form.$error) {
      this.hasErrors = true;
    }
  }

  returnToConfiguration() {
    SystemMarkupConfigurationStore.setIsFromManage(true);
    router.push({
      name: 'system-markup-configuration',
      params: {
        configurationId: this.configurationId,
      }
    });
  }

  confirmAndAddNext() {
    this.checkForm();
    if (this.hasErrors) {
      return;
    }
    if (this.isRuleEdited) {
      SystemMarkupConfigurationStore.editRule(this.createRequest());
    } else {
      SystemMarkupConfigurationStore.addRule(this.createRequest());
    }
    let obj = {
      type: translate('settings-travel-policy.success'),
      title: '',
      message: translate('settings-travel-policy.rule-added')
    };
    EventBus.$emit('show-toast', obj);
    SystemMarkupConfigurationStore.setSelectedRule({});
    this.mapFormData();
    this.$v.$reset();
  }

  confirmAndBack() {
    this.checkForm();
    if (this.hasErrors) {
      return;
    }
    if (this.isRuleEdited) {
      SystemMarkupConfigurationStore.editRule({
        ...this.createRequest(),
        status: SystemMarkupConfigurationStore.draftNewStatus,
      });
    } else {
      SystemMarkupConfigurationStore.addRule({
        ...this.createRequest(),
        status: SystemMarkupConfigurationStore.draftNewStatus
      });
    }
    SystemMarkupConfigurationStore.setIsFromManage(true);
    router.push({
      name: 'system-markup-configuration',
      params: {
        configurationId: this.configurationId
      }
    });
  }

  findTripTypes(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.tripTypeOptions.filter((type: any) => type.label.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.label.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allTripTypes = [];

      if (response) {
        response.forEach((element) => {
          this.allTripTypes.push(element);
        });
      } else {
        this.allTripTypes = this.tripTypeOptions;
      }
    }
  }

  findBookingType(query) {
    query = query.toLowerCase();
    const response = this.bookingTypeOptions.filter(option => option.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allBookingTypes = [];

    if (response) {
      response.forEach((element) => {
        this.allBookingTypes.push(element);
      });
    } else {
      this.allBookingTypes = this.bookingTypeOptions;
    }
  }

  findPaymentMethods(query) {
    query = query.toLowerCase();
    const response = this.paymentMethodsOptions.filter(option => option.label.toLowerCase().includes(query));
    const indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      const item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allPaymentMethods = [];

    if (response) {
      response.forEach((element) => {
        this.allPaymentMethods.push(element);
      });
    } else {
      this.allPaymentMethods = this.paymentMethodsOptions;
    }
  }

  findBookingChannels(query) {
    query = query.toLowerCase();
    const response = this.bookingChannelsOptions.filter(option => option.label.toLowerCase().includes(query));
    let indexCode = response.findIndex(item => query === item.label.toLowerCase());
    if (indexCode > -1) {
      let item = response.splice(indexCode, 1);
      response.unshift(...item);
    }

    this.allBookingChannels = [];

    if (response) {
      response.forEach((element) => {
        this.allBookingChannels.push(element);
      });
    } else {
      this.allBookingChannels = this.bookingChannelsOptions;
    }
  }

  findSupplier(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.supplierOptions.filter((supplier: any) => supplier.label.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.label.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allSupplier = [];

      if (response) {
        response.forEach((element) => {
          this.allSupplier.push(element);
        });
      } else {
        this.allSupplier = this.supplierOptions;
      }
    }
  }

  findFareTypes(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.fareTypeOptions.filter((supplier: any) => supplier.label.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.label.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allFareTypes = [];

      if (response) {
        response.forEach((element) => {
          this.allFareTypes.push(element);
        });
      } else {
        this.allFareTypes = this.fareTypeOptions;
      }
    }
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  createRequest() {
    return {
      feeType: this.Form.feeType,
      fixedFee: this.formFixedFee,
      percentageFee: this.formPercentageFee,
      calculationMethod: this.formCalculationMethod,
      isLodgeCardInformationSelected: this.Form.isLodgeCardInformationSelected,
      supplier: this.formSupplier,
      bookingType: this.formBookingType,
      paymentMethods: this.formPaymentMethods,
      bookingChannels: this.formBookingChannels,
      airlines: this.formAirlines,
      tripTypes: this.formTripTypes,
      fareTypes: this.formFareTypes,
      fareBasisCodes: this.formFareBasisCodes,
      status: SystemMarkupConfigurationStore.draftNewStatus,
    };
  }

  setDefaultFormData() {
    this.Form = {
      feeType: 'Fixed',
      fixedFeeEnabled: true,
      fixedFee: {
        amount: 0,
        currency: {
          code: this.currencyCodes[0],
          symbol: null,
        }
      },
      percentageFeeEnabled: false,
      percentageFee: 0,
      calculationMethod: this.calculationMethodOptions[0],
      whenToApply: this.whenToApplyOptions[0],
      isLodgeCardInformationSelected: true,
      bookingTypeEnabled: false,
      bookingType: [],
      paymentMethods: [],
      bookingChannelsEnabled: false,
      bookingChannels: [],
      supplierEnabled: false,
      supplier: [],
      airlineEnabled: false,
      airlines: [],
      tripTypesEnabled: false,
      tripTypes: [],
      fareTypesEnabled: false,
      fareTypes: [],
      fareBasisCodesEnabled: false,
      fareBasisCodes: [
        ''
      ],
    };
  }

  mapFormFeeType(rule) {
    return rule.feeType;
  }

  mapFormFixedFeeEnabled(rule) {
    return rule.fixedFee && rule.fixedFee.amount > 0;
  }

  mapFormFixedFee(rule) {
    return {
      amount: rule.fixedFee && rule.fixedFee.amount !== undefined ? rule.fixedFee.amount : 0,
      currency: rule.fixedFee ? {
        code: rule.fixedFee.currency.code,
        symbol: this.currencyCodes
          .find(item => item === rule.fixedFee.currency.symbol),
      } : {
        amount: 0,
        currency: {
          code: this.currencyCodes[0],
          symbol: null,
        }
      },
    };
  }

  mapFormPercentageFeeEnabled(rule) {
    return rule.percentageFee && rule.percentageFee > 0;
  }

  mapFormPercentageFee(rule) {
    return rule.percentageFee || 0;
  }

  mapFormCalculationMethod(rule) {
    return rule.calculationMethod ? this.calculationMethodOptions
      .find(item => {
        return rule.calculationMethod.value ?
          item.value === rule.calculationMethod.value :
          item.value === rule.calculationMethod;
        }) : this.calculationMethodOptions[0];
  }

  mapFormWhenToApply(rule) {
    return this.whenToApplyOptions[0];
  }

  mapFormPaymentMethods(rule) {
    return (rule.paymentMethods || [])
        .map(item => {
          return this.paymentMethodsOptions
              .find(type => {
                return item.value ? item.value === type.value : item === type.value;
              });
        });
  }

  mapFormIsLodgeCardInformationSelected(rule) {
    return rule.isLodgeCardInformationSelected;
  }

  mapFormBookingTypeEnabled(rule) {
    return rule.bookingType && rule.bookingType.length > 0;
  }

  mapFormBookingType(rule) {
    return (rule.bookingType || [])
      .map(item => {
        return this.bookingTypeOptions
          .find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
      });
  }

  mapFormBookingChannelsEnabled(rule) {
    return rule.bookingChannels && rule.bookingChannels.length > 0;
  }

  mapFormBookingChannels(rule) {
    return (rule.bookingChannels || [])
      .map(item => {
        return this.bookingChannelsOptions
          .find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
      });
  }

  mapFormSupplierEnabled(rule) {
    return rule.supplier.length > 0;
  }

  mapFormSupplier(rule) {
    return rule.supplier
      .map(item => {
        return this.supplierOptions
          .find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
      });
  }

  mapFormAirlineEnabled(rule) {
    return rule.airlines.length > 0;
  }

  mapFormAirlines(rule) {
    return rule.airlines
      .map(item => {
        return this.airlines
          .find((type: any) => {
            return item.value ? item.value === type.code : item === type.code;
          });
      });
  }

  mapFormTripTypesEnabled(rule) {
    return rule.tripTypes.length > 0;
  }

  mapFormTripTypes(rule) {
    return rule.tripTypes
      .map(item => {
        return this.tripTypeOptions
          .find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
      });
  }

  mapFormFareTypesEnabled(rule) {
    return rule.fareTypes.length > 0;
  }

  mapFormFareTypes(rule) {
    return rule.fareTypes
      .map(item => {
        return this.fareTypeOptions
          .find(type => {
            return item.value ? item.value === type.value : item === type.value;
          });
      });
  }

  mapFormFareBasisCodesEnabled(rule) {
    return rule.fareBasisCodes.length > 0;
  }

  mapFormFareBasisCodes(rule) {
    return rule.fareBasisCodes && rule.fareBasisCodes.length ? rule.fareBasisCodes : [''];
  }

  mapFormData(rule?) {
    if (rule !== undefined && rule !== null && Object.keys(rule).length > 0) {
      this.Form = {
        feeType: this.mapFormFeeType(rule),
        fixedFeeEnabled: this.mapFormFixedFeeEnabled(rule),
        fixedFee: this.mapFormFixedFee(rule),
        percentageFeeEnabled: this.mapFormPercentageFeeEnabled(rule),
        percentageFee: this.mapFormPercentageFee(rule),
        calculationMethod: this.mapFormCalculationMethod(rule),
        whenToApply: this.mapFormWhenToApply(rule),
        isLodgeCardInformationSelected: this.mapFormIsLodgeCardInformationSelected(rule),
        bookingTypeEnabled: this.mapFormBookingTypeEnabled(rule),
        bookingType: this.mapFormBookingType(rule),
        paymentMethods: this.mapFormPaymentMethods(rule),
        bookingChannelsEnabled: this.mapFormBookingChannelsEnabled(rule),
        bookingChannels: this.mapFormBookingChannels(rule),
        supplierEnabled: this.mapFormSupplierEnabled(rule),
        supplier: this.mapFormSupplier(rule),
        airlineEnabled: this.mapFormAirlineEnabled(rule),
        airlines: this.mapFormAirlines(rule),
        tripTypesEnabled: this.mapFormTripTypesEnabled(rule),
        tripTypes: this.mapFormTripTypes(rule),
        fareTypesEnabled: this.mapFormFareTypesEnabled(rule),
        fareTypes: this.mapFormFareTypes(rule),
        fareBasisCodesEnabled: this.mapFormFareBasisCodesEnabled(rule),
        fareBasisCodes: this.mapFormFareBasisCodes(rule),
      };

      return;
    }
    this.setDefaultFormData();
  }

  async getCurrencies() {
    try {
      const response = await CurrencyApi.getCurrencies();
      this.currencyCodes = response.data.map(item => item.code);
    } catch (error) {
      this.currencyErrors = this.$handleErrors(error);
    }
  }

  async created() {
    await this.getCurrencies();
    if (this.rule === null) {
      router.push({
        name: 'system-markup-configuration',
        params: {
          configurationId: this.configurationId,
        }
      });
    }
    if (this.rule) {
      this.mapFormData(this.rule);
    } else {
      this.mapFormData();
    }
    this.formLoaded = true;
  }
}
