













































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import { Price } from '@/api/expense/expense.model';
import { TPassenger, TAncillaryOffer } from '@/api/trip/basket-air-seats-extras.model';
import { getSeatPrice } from '@/modules/basket/basket-air-seats-extras/BasketAirSeatsExtras.helper';
import EventBus from '@/services/event-handler';
import BasketExpirationCountdown from '@/modules/basket/BasketExpirationCountdown.vue';
import BasketItem from '@/modules/basket/BasketItem.vue';
import FlightsTile from '@/modules/basket/basket-air-seats-extras/FlightsTile.vue';
import TravelfusionExtrasTile from '@/modules/basket/basket-air-seats-extras/TravelfusionExtrasTile.vue';
import SeatsContent from '@/modules/basket/basket-air-seats-extras/SeatsContent.vue';
import ExtrasContent from '@/modules/basket/basket-air-seats-extras/ExtrasContent.vue';
import SeatMapPopup from '@/modules/basket/basket-air-seats-extras/SeatMapPopup.vue';
import BasketItemBusinessErrors from '@/modules/basket/BasketItemBusinessErrors.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    TravelfusionExtrasTile,
    BasketItemBusinessErrors,
    BasketExpirationCountdown,
    BasketItem,
    FlightsTile,
    ExtrasContent,
    SeatsContent,
    SeatMapPopup,
  },
})
export default class BasketAirSeatsExtrasView extends Vue {
  @Prop() basketExpired!: boolean;

  userFullName = userFullName;

  get airSeatSelectionsModel() {
    return BasketStore.airSeatSelectionsModel;
  }
  set airSeatSelectionsModel(value) {
    BasketStore.setAirSeatSelectionsModel(value);
  }

  get airAncillarySelectionsModel() {
    return BasketStore.airAncillarySelectionsModel;
  }
  set airAncillarySelectionsModel(value) {
    BasketStore.setAirAncillarySelectionsModel(value);
  }

  get travelfusionItemsModel() {
    return BasketStore.travelfusionItemsModel;
  }
  set travelfusionItemsModel(value) {
    BasketStore.setTravelfusionItemsModel(value);
  }

  get isTravelfusion() {
    return BasketStore.bookingStepTripItem && BasketStore.bookingStepTripItem.supplier === 'TravelFusion';
  }

  get basket() {
    return BasketStore.basket;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get businessErrors() {
    return BasketStore.businessErrors;
  }

  get item() {
    const stepDef = BasketStore.wizardSteps[BasketStore.bookingStep - 1];
    if (stepDef && stepDef.code === 'AIR_ANCILLARIES_AND_SEAT_SELECTION') {
      return BasketStore.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return BasketStore.basketItemsMapped;
  }

  get loadingAirSeatsAndExtras() {
    return BasketStore.loadingAirSeatsAndExtras;
  }

  get basketAirSeatsAndExtras() {
    return BasketStore.basketAirSeatsAndExtras;
  }

  get passengerAncillaries() {
    if (this.basketAirSeatsAndExtras) {
      return this.basketAirSeatsAndExtras.passengerAncillaries;
    }
    return [];
  }

  get parameters() {
    if (this.basketAirSeatsAndExtras) {
      return this.basketAirSeatsAndExtras.parameters || [];
    }
    return [];
  }

  get seatMaps() {
    if (this.basketAirSeatsAndExtras) {
      return this.basketAirSeatsAndExtras.seatMaps;
    }
    return [];
  }

  get flights() {
    return this.seatMaps.map((item) => item.flight);
  }

  get passengers() {
    const passengers: TPassenger[] = this.passengerAncillaries.map((item) => {
      const profile = BasketStore.basketTravellers.find((traveller) => traveller.id === item.profileId);
      return { ...item, ...profile };
    });
    return passengers;
  }

  get seatPrices() {
    return this.airSeatSelectionsModel.seatSelections.reduce((prices, seatSelection) => {
      const seatSelectionPrices = seatSelection.profileSeatSelections.reduce((prev, item) => {
        const seatPrice = getSeatPrice({
          seatMaps: this.seatMaps,
          profileId: item.profileId,
          flightId: seatSelection.flightId,
          row: item.seatRow,
          column: item.seatColumn,
        });
        return seatPrice ? prev.concat(seatPrice) : prev;
      }, [] as Price[]);
      return prices.concat(seatSelectionPrices);
    }, [] as Price[]);
  }

  get ancillaries() {
    return this.airAncillarySelectionsModel.ancillarySelections.reduce((ancillary, ancillarySelection) => {
      const passengerAncillary = this.passengerAncillaries.find((item) => item.profileId === ancillarySelection.profileId);
      if (!passengerAncillary) {
        return ancillary;
      }
      const ancillaryOffer = passengerAncillary.ancillaryOffers.find((item) => item.id === ancillarySelection.ancillaryOfferId);
      if (!ancillaryOffer) {
        return ancillary;
      }
      return ancillary.concat({ ...ancillaryOffer, profileId: passengerAncillary.profileId });
    }, [] as Array<TAncillaryOffer & { profileId: string }>);
  }

  get ancillariesPrices() {
    return this.ancillaries.map((item) => item.totalPrice);
  }

  get parametersPrices() {
    return this.travelfusionItemsModel.items.reduce((prev, next) => {
      const parameter = this.parameters.find((item) => item.key === next.key);
      if (parameter && parameter.options && parameter.options.length > 0) {
        const option = parameter.options.find((item) => item.key === next.value);
        if (option) {
          return prev.concat(option.price);
        }
      }
      return prev;
    }, [] as Price[]);
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get countFlights() {
    const flights: { [key: string]: number } = this.ancillaries
      .flatMap((item) => {
        return item.flights.map(flightId => ({
          flightId,
          profileId: item.profileId,
        }));
      })
      .reduce((acc, { flightId, profileId }) => {
        const key = `${flightId}-${profileId}`;
        return {
          ...acc,
          [key]: (acc[key] || 0) + 1,
        };
      }, {} as { [key: string]: number });
    return Object.values(flights);
  }

  get hasMaxOneAncillaryError() {
    const hasError = this.countFlights.some((item) => item > 1);
    BasketStore.setHasMaxOneAncillaryError(hasError);
    return hasError;
  }

  get hasExactOneAncillaryError() {
    const travellersToConsider = this.passengerAncillaries.filter((item) => item.ancillaryOffers.length > 0);
    const numberOfTravellersToConsider = travellersToConsider.length;
    const flightsToConsider = travellersToConsider.flatMap(({ ancillaryOffers }) => ancillaryOffers.flatMap(({ flights }) => flights));
    const uniqueFlightsToConsider = [...new Set(flightsToConsider)];
    const numberOfFlightsToConsider = uniqueFlightsToConsider.length;
    const hasError = this.countFlights.length !== numberOfFlightsToConsider * numberOfTravellersToConsider || this.countFlights.some((item) => item > 1);
    BasketStore.setHasExactOneAncillaryError(hasError);
    return hasError;
  }

  get hasRestrictToSameTypeForNewAncillariesError() {
    const leastOneNonBookedBag = this.ancillaries.some((item) => !!item.isBag && !item.isAlreadyBooked);
    const oneNonBookedNonBag = this.ancillaries.some((item) => !item.isBag && !item.isAlreadyBooked);
    const hasError = leastOneNonBookedBag && oneNonBookedNonBag;
    BasketStore.setHasRestrictToSameTypeForNewAncillariesError(hasError);
    return hasError;
  }

  get maxOneAncillary() {
    return BasketStore.maxOneAncillaryPerSegment;
  }

  get minimumOneAncillary() {
    return BasketStore.minimumAncillaryCount === 1;
  }

  get restrictToSameTypeForNewAncillaries() {
    return BasketStore.restrictToSameTypeForNewAncillaries;
  }

  get maxOneAncillaryErrors() {
    if ((this.hasMaxOneAncillaryError || this.hasExactOneAncillaryError) && BasketStore.showMaxOneAncillaryError) {
      return [{ message: translate('basket-ancillaries.please-select-one-ancillary-in-segment') }];
    }
    return [];
  }

  get restrictToSameTypeForNewAncillariesError() {
    if (this.hasRestrictToSameTypeForNewAncillariesError && BasketStore.showRestrictToSameTypeForNewAncillariesError) {
      return [{ message: translate('basket-ancillaries.please-select-one-ancillary-type') }];
    }
    return [];
  }

  getSeatMapForFlight(flightId: string) {
    return this.seatMaps.find((item) => item.flight.id === flightId);
  }

  isAvailableSeatMapForTraveller(profileId: string) {
    const isAvailableSeatMapForTraveller = this.seatMaps.some((item) => {
      if (item.availableSeatsForTravellers) {
        return item.availableSeatsForTravellers.includes(profileId);
      }
      return false;
    });
    const hasDecks = this.seatMaps.some((item) => item.decks && item.decks.length > 0);
    return isAvailableSeatMapForTraveller && hasDecks;
  }

  isAvailableAncillaryOffersForTraveller(profileId: string) {
    const passengerAncillary = this.passengerAncillaries.find((item) => item.profileId === profileId);
    if (passengerAncillary) {
      return passengerAncillary.ancillaryOffers.length > 0;
    }
    return false;
  }

  setPriceChanges(type: string) {
    const prices = type === 'seats' ? [...this.seatPrices] : [...this.ancillariesPrices, ...this.parametersPrices];
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: BasketStore.bookingStep,
      type,
      prices,
    });
  }

  isEasyJetOffer(passenger: TPassenger) {
    const ancillaryOffers = passenger && passenger.ancillaryOffers;
    return ancillaryOffers.some((item) => item.airlineCode === 'U2');
  }

  checkSeatsMandatory() {
    let isBasketSeatsMapError;
    if (this.isTravelfusion) {
      isBasketSeatsMapError = !BasketStore.canContinueOnSeatsStep;
    } else {
      isBasketSeatsMapError = this.seatMaps.some(({ isMandatory, flight }) => {
        const { id } = flight;
        const seatSelection = this.airSeatSelectionsModel.seatSelections.find(({ flightId }) => flightId === id);
        return isMandatory && !seatSelection;
      });
    }
    BasketStore.setBasketSeatsMapError(isBasketSeatsMapError);
  }

  created() {
    EventBus.$on('validate-basket-seats-map-mandatory', this.checkSeatsMandatory);
  }

  beforeDestroy() {
    EventBus.$off('validate-basket-seats-map-mandatory', this.checkSeatsMandatory);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.resetPriceChangesForStep({ tripItemId: this.itemId, step: BasketStore.bookingStep });
    BasketStore.setAirSeatSelectionsModel({ seatSelections: [] });
    BasketStore.setAirAncillarySelectionsModel({ ancillarySelections: [] });
    await BasketStore.loadBasketAirSeatsAndExtras(this.itemId);
    BasketStore.setBookingStepLoading(false);
  }
}
