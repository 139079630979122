








import { Component, Vue, Watch } from 'vue-property-decorator';

import ProfileStore from '@/modules/profile/profile.store';
import ProfileApproverCustomFields from '@/modules/profile/approval-workflow/ProfileApproverCustomFields.vue';
import DesignatedTravellers from '@/modules/profile/approval-workflow/DesignatedTravellers.vue';
import DesignatedApprovers from '@/modules/profile/approval-workflow/DesignatedApprovers.vue';

@Component({
  components: {
    ProfileApproverCustomFields,
    DesignatedTravellers,
    DesignatedApprovers,
  },
})
export default class ApprovalWorkflow extends Vue {
  
  @Watch('$route', { immediate: true , deep: true})
  onchangeRoute() {
    ProfileStore.setHasAccessToApprovalWorkflow(false);
  }

}
