


























import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

import BasketStore from './basket.store';

@Component
export default class BasketRetryInfoPopup extends Vue {
  @Prop() show!: boolean;

  closeRetryInfoPopup() {
    BasketStore.setShowRetryPopup(false);
    BasketStore.processBasket();
  }
}

