













































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import DictionaryStore from '@/store/dictionary.store';
import SearchConst from '@/const/search.const';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { translate } from '@/i18n';
import { HomeApi } from '@/api/home/home.api';
import AccountStore from '@/store/account.store';
import { LanguageCode, AirLine } from '@/api/dictionary/dictionary.model';
import { AirLocationModel } from '@/api/home/home.model';

const updateAirSearchParameters: string = 'update:airSearchParameters';
const updateShowAdvancedCriteria: string = 'update:shouldShowAdvancedCriteria';

@Component({})
export default class SearchAdvancedCriteria extends Vue {
  @Prop() shouldShowAdvancedCriteria!: boolean;
  @Prop() airSearchParameters!: any;

  isAirlinesLoading: boolean = false;
  allAirline: any[] = [];
  isConnectionLoading: boolean = false;
  connectionAirports: AirLocationModel[] = [];
  alliances = SearchConst.alliances.map(item => {
    return {
      ...item,
      label: translate(item.label),
    };
  });

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get connectionAirportsOptions() {
    return this.connectionAirports.map(location => ({
      ...location,
      uniqId: location.cityCode + location.airportCode,
      label: this.getAirportName(location),
    }));
  }

  get airlines() {
    return DictionaryStore.allAirLinesSorted;
  }

  get allianceModel() {
    return this.airSearchParameters.alliance.length ? this.airSearchParameters.alliance[0] : {
      ...this.alliances[0],
    };
  }

  set allianceModel(value) {
    if (!value) {
      this.airSearchParameters.alliance = [];
      this[updateAirSearchParameters](this.airSearchParameters);
    }
    this.airSearchParameters.alliance = [value];
    this[updateAirSearchParameters](this.airSearchParameters);
  }

  getAirportName(option) {
    let name = '';
    if (option.type === 'City') {
      name += option.cityName;
      name += ' (' + translate('search.all-airports') + ')';
    } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
      name += option.airportName;
      name += ' (' + option.cityName + ')';
    }
    return name;
  }

  hideAdvancedCriteria() {
    this[updateShowAdvancedCriteria](false);
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isConnectionLoading',
  })
  async findConnectionAirports(query) {
    if (query && query.length > 2) {
      this.isConnectionLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode, true);

      if (response && response.data) {
        this.connectionAirports = response.data;
      }

      this.isConnectionLoading = false;
    } else {
      this.isConnectionLoading = false;
      this.connectionAirports = [];
    }
  }

  @Emit()
  [updateAirSearchParameters](value) {
    return value;
  }

  @Emit()
  [updateShowAdvancedCriteria](value) {
    return value;
  }
}

