import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import { store } from '@/store';
import { router } from '@/router';
import { ToTwoDigitStringValue } from '@/core/utils';
import axios, { CancelTokenSource } from 'axios';
import {
  Recommendation,
  SortOptions,
  SearchState,
  InitAirSearchMessage,
  LegTimeWindow,
  TimeWindow,
  InitExchangeAirSearchMessage,
  AirSearchProviderError,
  AirResultsErrorPayload,
  Legs,
} from '@/api/air-engine/air-search.model';
import AccountStore from '@/store/account.store';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import SearchStore from '@/modules/search/search.store';
import { AirApi } from '@/api/air-engine/air-search.api';
import { CompareOffersApi } from '@/api/air-engine/compare-offers.api';
import { CompareOfferDetails } from '@/api/air-engine/compare-offers.model';
import { BasketApi } from '@/api/trip/basket.api';
import filtersList from '@/const/filter.const';
import LayoutStore from '@/modules/layout/layout.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import moment from 'moment';
import {
  serviceClassEnum,
  cabinClassEnum,
  searchModes,
} from '@/api/home/home.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import SearchConst from '@/const/search.const';
import { translate } from '@/i18n';
import DictionaryStore from '@/store/dictionary.store';
import { HomeApi } from '@/api/home/home.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import messages from '@/const/error-messages.const';
import BasketStore from '@/modules/basket/basket.store';
import { isCancelError } from '@/core/utils';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'airSearch'
})
class AirSearchStore extends VuexModule {
  filters: any[] = [];
  requestFilterData = [];
  offers: Recommendation[] = [];
  offersForExchange: Recommendation[] = [];
  cancelToken: CancelTokenSource | null = null;
  filtersChanging: boolean = false;
  filtersRequest: any = null;
  filtersError: boolean = false;
  filtersRequestId: number = 0;
  searchFreezed: boolean = false;
  sortOptions: SortOptions = {
    BEST_FARE_DEPARTURE_TIME: 'Descending',
  };
  offersVisible: number = 10;
  totalOffers: number = 0;
  averageCo2Emission: number | null = null;
  agencyMarkup: number = 0;
  convertetFilters: any[] = [];
  loading: boolean = false;
  templateOffers = [];
  stateId: string = '';
  searchId: string = '';
  searchIdChanged: boolean = false;
  searchLoaded: boolean = false;
  currentOfferId: string = '';
  currentProposalId: string = '';
  searchCompleted: boolean = false;
  serverErrors: any[] = [];
  exchangeErrors: any[] = [];
  initExchangeErrors: any[] = [];
  searchTimeout: number = -1;
  modifyMode: boolean = false;
  exchangeParameters: any = {};
  exchangeDates: any[] = [];
  exchangedOffer: any = null;
  exchangedOfferItem: any = null;
  exchangeLocations: any = null;
  exchangeTravellers: any = [];
  basketId: string = '';
  prevSelectedOffer = null;
  showModal: boolean = false;
  errorMessageBe: string = '';
  showErrorBE: boolean = false;
  searchInProgress: boolean = false;
  scrollTimeout: number = 0;
  modificationBasketId: string = '';
  modificationBasketItemStatus: string = '';
  errMessages: any[] = [];
  errCompareMessages: any[] = [];
  errSplitProvidersForCompanyMessages: any[] = [];
  permissionErrSplitProvidersForCompanyMessages: boolean = false;
  showError: boolean = false;
  recommendationsAirCount: number = 0;
  providersErrors: AirSearchProviderError[] = [];
  retrievesExchangeOffer: any = null;
  currentSearchIdFromApi: string = '';
  fareUnavailableErrorParams = {
    codes: {
      [404]: 'common-error.fare-unavailable'
    }
  };
  newFlightDisplay: boolean = false;
  loadingProposals: boolean = false;
  filterLoadingView: boolean = false;
  shouldShowUpsell: boolean = false;
  upsellOffers: any[] = [];
  upsellCurrentOffer: any = null;
  upsellChosen: any = null;
  loadingRestProposals: boolean = false;
  offersProposalsList: any[] = [];
  shouldReloadProposals: boolean = false;
  startSearchingMoreProposals: boolean = false;
  openOffers: any[] = [];
  feeConfigurationId: string = '';
  priceDetailedComponentsVisible: boolean = false;
  compareOfferDetailsData: CompareOfferDetails[] = [];
  loaderCompare: boolean = false;
  splitProvidersForCompany: any[] = [];
  loaderSplitProvidersForCompany: boolean = false;
  showTemplateOffersModal: boolean = false;
  currency: any = null;
  offerExpired: any[] = [];
  legs: any[] = [];
  showCustomExchangeLufthansaPopup: boolean = false;
  customExchangeLufthansaError: any = null;
  showingMissedSavingsModal: boolean = false;
  missedSavingsInfo: any = null;
  basketItemsRequest: any = null;
  exchangeInitErrors: any[] = [];
  legCabinClasses: any;
  preferredCabinClass: string = 'Economy';

  get isSelected() {
    return this.currentOfferId !== '' && this.currentProposalId !== '';
  }

  get offersProposalsListRefresh() {
    return this.offersProposalsList;
  }

  get getExchangedOffer() {
    return this.exchangedOffer;
  }

  get getExchangeTravellers() {
    return this.exchangeTravellers;
  }

  get getExchangeLocations() {
    return this.exchangeLocations;
  }

  get getExchangeDates() {
    return this.exchangeDates;
  }

  get exchangeLegsSelectedForEdit() {
    if (!this.exchangedOffer || !this.exchangedOffer.legFlights) {
      return [];
    }
    return this.exchangedOffer.legFlights.map(item => item.selectedForEdit);
  }

  get currentSelectedOffer() {
    return this.offers.find(offer => offer.id === this.basketItemsRequest.recommendationId);
  }

  get currentSelectedGroupedOffer() {
    const group = this.offersProposalsList
      .find(g => g.proposals.some(p => p.id === this.basketItemsRequest.recommendationId));
    
    if (!group) {
      return null;
    }

    return group.proposals.find(p => p.id === this.basketItemsRequest.recommendationId);
  }

  @Mutation
  setOffersProposalsList(payload) {
    payload.proposals.forEach(proposal => {
      proposal.prepareOfferCheck = false;
      if (this.templateOffers.length > 0) {
        this.templateOffers.forEach((template: any) => {
          if (template.proposalId === proposal.id) {
            proposal.prepareOfferCheck = true;
          }
        });
      }
    });

    const offer = this.offers.find(o => o.id === payload.id);

    if (offer) {
      offer.travelPolicy = payload.travelPolicy;
    }

    let itemExist = this.offersProposalsList.findIndex(proposal => proposal.id === payload.id);
    if (itemExist < 0) {
      this.offersProposalsList.push(payload);
    } else {
      this.offersProposalsList[itemExist] = payload;
    }
  }

  @Mutation
  setMissedSavingsInfo(value) {
    this.missedSavingsInfo = value;
  }

  @Mutation
  showMissedSavingsModal(value) {
    this.missedSavingsInfo = value;
    this.showingMissedSavingsModal = !!value;
  }

  @Mutation
  toggleMissedSavingsModal(value) {
    this.showingMissedSavingsModal = value;
  }

  @Mutation
  resetOffersProposalsList() {
    this.offersProposalsList = [];
  }
  @Mutation
  setFilterLoadingView(value) {
    this.filterLoadingView = value;
  }

  @Mutation
  setNewFlightDisplay(value) {
    this.newFlightDisplay = value;
  }

  @Mutation
  setShouldReloadProposals(value) {
    this.shouldReloadProposals = value;
  }

  @Mutation
  setOpenOffers(value) {
    let itemExist = this.openOffers.findIndex(offer => offer.id === value.id);
    if (itemExist < 0) {
      this.openOffers.push(value);
    }
  }

  @Mutation
  removeOffers(value) {
    this.openOffers = this.openOffers.filter(offer => offer.id !== value.id);
  }

  @Mutation
  clearOpenOffers() {
    this.openOffers = [];
  }

  @Mutation
  setStartSearchingMoreProposals(value) {
    this.startSearchingMoreProposals = value;
  }

  @Mutation
  setLoadingRestProposals(value) {
    this.loadingRestProposals = value;
  }

  @Mutation
  setErrMessages(message: AirResultsErrorPayload | null) {
    if (message) {
      this.errMessages = $handleErrors(message.error, true, message.customParams , message.addMessageToError);
    } else {
      this.errMessages = [];
    }
  }

  @Mutation
  setCompareErrMessages(error) {
    if (error) {
      this.errCompareMessages = $handleErrors(error);
    } else {
      this.errCompareMessages = [];
    }
  }

  @Mutation
  setErrSplitProvidersForCompanyMessages(error) {
    if (error) {
      this.errSplitProvidersForCompanyMessages = $handleErrors(error);
    } else {
      this.errSplitProvidersForCompanyMessages = [];
    }
  }

  @Mutation
  setPermissionErrSplitProvidersForCompanyMessages(vaule) {
    this.permissionErrSplitProvidersForCompanyMessages = vaule;
  }

  @Mutation
  setInitExchangeErrors(error, addMessageToError?) {
    this.initExchangeErrors = $handleErrors(error, true, undefined, addMessageToError || false);
  }

  @Mutation
  clearInitExchangeErrors() {
    this.initExchangeErrors = [];
  }

  @Mutation
  setCustomError(error) {
    this.errMessages = error;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setCurrency(value) {
    this.currency = value;
  }

  @Mutation
  setExchangeInitErrors(value) {
    this.exchangeInitErrors = value;
  }

  @Mutation
  setCurrentSearchIdFromApi(value) {
    this.currentSearchIdFromApi =  value;
  }

  @Mutation
  setSearchInProgress(state: boolean) {
    this.searchInProgress = state;
  }

  @Mutation
  setRecommendationsAirCount(state: number) {
    this.recommendationsAirCount = state;
  }

  @Mutation
  setProvidersErrors(errors: AirSearchProviderError[]) {
    this.providersErrors = errors;
  }

  @Mutation
  setTimeoutInterval(state: number) {
    this.scrollTimeout = state;
  }

  @Mutation
  setSearchCompleted(state: boolean) {
    this.searchCompleted = state;
  }

  @Mutation
  clear() {
    this.currentOfferId = '';
    this.currentProposalId = '';
  }

  @Mutation
  select({offerId, proposalId }) {
    this.currentOfferId = offerId;
    this.currentProposalId = proposalId;
  }

  @Mutation
  setTotalOffers(value) {
    this.totalOffers = value;
  }

  @Mutation
  setAverageCo2Emission(value) {
    this.averageCo2Emission = value;
  }

  @Mutation
  setupSearchLoaded(searchId) {
    if (this.searchId !== searchId) {
      this.searchIdChanged = true;
      this.searchId = searchId;
      this.searchLoaded = false;
    } else {
      this.searchIdChanged = false;
    }
  }

  @Mutation
  finishLoading() {
    if (this.searchIdChanged) {
      this.searchLoaded = true;
      this.searchIdChanged = false;
    }
  }

  @Mutation
  loader(data) {
    this.loading = data;
  }

  @Mutation
  setTemplateOffers(data) {
    this.templateOffers = data;
  }

  @Mutation
  setNewAgencyMarkup(value) {
    this.agencyMarkup = value;
  }

  @Mutation
  setNewSort({ sorterCode, isAscending = true }) {
    this.sortOptions = {
      [sorterCode]: isAscending ? 'Ascending' : 'Descending',
    };
  }

  @Mutation
  updateOffers(data) {
    this.offers = data;
  }

  @Mutation
  updateOffer(data) {
    let index = this.offers.findIndex(offer => {
      return offer.id === data.id;
    });
    if (-1 < index) {
      this.offers.splice(index, 1, data);
    }
  }

  @Mutation
  updateFilters(data) {
    this.filters = data;
  }

  @Mutation
  updateRequestFilterData(data) {
    this.requestFilterData = data;
  }

  @Mutation
  getDefaultOffersVisible() {
    this.offersVisible = 10;
  }

  @Mutation
  setOffersVisible(num) {
    this.offersVisible = num;
  }

  @Mutation
  setShowCustomExchangeLufthansaPopup(value) {
    this.showCustomExchangeLufthansaPopup = value;
  }

  @Mutation
  setCustomExchangeLufthansaError(value) {
    this.customExchangeLufthansaError = value;
  }

  @Mutation
  updateSearchStateId(value: string) {
    this.stateId = value;
  }

  @Mutation
  updateErrors(error: any[]) {
    this.serverErrors = error;
  }

  @Mutation
  clearErrors() {
    this.serverErrors = [];
  }

  @Mutation
  updateExchangeErrors(error: any[]) {
    this.exchangeErrors = error;
  }

  @Mutation
  clearExchangeErrors() {
    this.exchangeErrors = [];
  }

  @Mutation
  setExchangeParameters(params: any) {
    this.exchangeParameters = params;
  }

  @Mutation
  setExchangeCabinClass(value) {
    this.preferredCabinClass = value;
  }

  @Mutation
  setExchangeDate(date: any) {
    this.exchangeDates = date;
  }

  @Mutation
  setExchangedOffer(offer: any) {
    this.exchangedOffer = offer;
  }

  @Mutation
  setExchangedOfferItem(item: any) {
    this.exchangedOfferItem = item;
  }

  @Mutation
  setExchangeLocations(data: any) {
    this.exchangeLocations = data;
  }

  @Mutation
  setExchangeTravellers(data: any[]) {
    this.exchangeTravellers = data;
  }

  @Mutation
  setShouldShowUpsell(value) {
    this.shouldShowUpsell = value;
  }

  @Mutation
  setUpsellOffers(value) {
    this.upsellOffers = value.map(item => {
      return {
        ...item,
        proposal: {
          ...item.proposal,
          isNewFullPrice: true,
        },
      };
    });
  }

  @Mutation
  setUpsellCurrentOffer(value) {
    this.upsellCurrentOffer = value;
  }

  @Mutation
  setUpsellChosen(value) {
    this.upsellChosen = value;
  }

  @Mutation
  setBasketId(data) {
    this.basketId = data;
  }
  
  @Mutation
  setPrevSelectedOffer(data) {
    this.prevSelectedOffer = data;
  }

  @Mutation
  setShowModal(data) {
    this.showModal = data;
  }

  @Mutation
  setErrorMessageBe(data) {
    this.errorMessageBe = data;
  }

  @Mutation
  setShowErrorBE(data) {
    this.showErrorBE = data;
  }

  @Mutation
  setRetrievesExchangeOffer(data) {
    this.retrievesExchangeOffer = data;
  }

  @Mutation
  setLoadingProposals(data) {
    this.loadingProposals = data;
  }

  @Mutation
  newFiltersMut(data) {
    this.convertetFilters = data;
  }

  @Mutation
  convertetFiltersMut(data) {
    this.convertetFilters.push(data);
  }

  @Mutation
  setOffersForExchange(data) {
    this.offersForExchange = data;
  }
  
  @Mutation
  setModifyState(data: boolean) {
    this.modifyMode = data;
  }

  @Mutation
  updateModificationBasketId(basketId) {
    this.modificationBasketId = basketId;
  }

  @Mutation
  setModificationBasketItemStatus(status) {
    this.modificationBasketItemStatus = status;
  }

  @Mutation
  setSearchTimeout(timeout) {
    this.searchTimeout = setTimeout(timeout, 1000);
  }

  @Mutation
  clearSearchTimeout() {
    clearTimeout(this.searchTimeout);
  }

  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  createCancelTokenIfNeeded() {
    if (this.cancelToken !== null) {
      return;
    }
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    this.cancelToken = cancelTokenSource;
  }

  @Mutation
  setFiltersError(value) {
    this.filtersError = value;
  }

  @Mutation
  setSearchFreezed(value: boolean) {
    this.searchFreezed = value;
  }

  @Mutation
  updateFiltersRequestId() {
    this.filtersRequestId++;
  }

  @Mutation
  setFiltersChanging(value) {
    this.filtersChanging = value;
  }

  @Mutation
  setFiltersRequest(value) {
    this.filtersRequest = value;
  }

  @Mutation
  setFeeConfigurationId(value) {
    this.feeConfigurationId = value;
  }

  @Mutation
  setPriceDetailedComponentsVisible(value) {
    this.priceDetailedComponentsVisible = value;
  }

  @Mutation
  setCompareOfferDetails(value) {
    this.compareOfferDetailsData = value;
  }

  @Mutation
  setLoaderCompare(value) {
    this.loaderCompare = value;
  }

  @Mutation
  setSplitProvidersForCompany​(value) {
    this.splitProvidersForCompany = value;
  }

  @Mutation
  setLoaderSplitProvidersForCompany(value) {
    this.loaderSplitProvidersForCompany = value;
  }

  @Mutation
  setShowTemplateOffersModal(value) {
    this.showTemplateOffersModal = value;
  }

  @Mutation
  setOfferExpired(value) {
    this.offerExpired.push(value);
  }

  @Mutation
  clearOfferExpired() {
    this.offerExpired = [];
  }

  @Mutation
  requestAirSearchMultiLeg() {
    const params = SearchStore.getAirDefaultState;
    this.legs = params.legs.map((leg, index) => {
      return {
        legNumber: index,
        from: {
          type: leg.from.type === 'CityAirport'
              ? 'Airport'
              : leg.from.type,
          code: leg.from.type === 'City' ? leg.from.cityCode : leg.from.airportCode
        },
        to: {
          type: leg.to.type === 'CityAirport'
            ? 'Airport'
            : leg.to.type,
          code: leg.to.type === 'City' ? leg.to.cityCode : leg.to.airportCode
        },
        departureDate: leg.departureDate,
        cabinClass: leg.cabinClasses[0].value,
        timeWindows: leg.timeWindows && leg.timeWindows.departureWindow ? {
          departureWindow: {
            start: '' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[0] / 60) + ':' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[0] % 60) + ':00',
            end: '' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[1] / 60) + ':' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[1] % 60) + ':00',
          },
          arrivalWindow: null,
        } : null,
      };
    });
  }

  @Mutation
  requestAirSearchClear() {
    this.legs = [new Legs()];
  }

  @Mutation
  setBasketItemRequest(data) {
    this.basketItemsRequest = data;
  }



  @Action({ rawError: true })
  convertRangeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        }
      };
      this.convertetFiltersMut(converted);
    }
  }

  @Action({ rawError: true })
  convertRangeTimeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        },
        data: stats.rangesStatItems.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
            maxLimit: i.maxLimit,
            minLimit: i.minLimit,
          };
        }),
      };
      this.convertetFiltersMut(converted);
    }
  }

  @Action({ rawError: true })
  convertCategoriesFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: stats.items.map(i => {
          return {
            ...i,
          };
        }),
      };
      this.convertetFiltersMut(converted);
    }
  }

  @Action
  convertHashFilterStats(stats) {
    const converted = {
      code: stats.code,
      values: stats.selectedHashes
    };

    this.convertetFiltersMut(converted);
  }

  @Action
  buildSortQuery() {
    const data: string[] = [];
    const paramName = 'sortOptions';
    let i = 0;
    for (let key in this.sortOptions) {
      if (this.sortOptions.hasOwnProperty(key)) {
        let urlParam = `${paramName}[${i}].Code=${key}`;
        data.push(urlParam);
        urlParam = `${paramName}[${i}].Direction=${this.sortOptions[key]}`;
        data.push(urlParam);

        i++;
      }
    }

    return data.join('&');
  }

  @Action
  convertFiltersStats(stats) {
    this.newFiltersMut([]);
    filtersList.air.filtersdata.rangeFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      let multiLegExist = -1 !== [...SearchConst.legNumberData].indexOf(f.code);
      if (f.code === 'OUTBOUND_DEPART_TIME' || f.code === 'INBOUND_DEPART_TIME' || multiLegExist) {
        this.convertRangeTimeFilterStats(filterStats);
      } else {
        this.convertRangeFilterStats(filterStats);
      }
    });

    filtersList.air.filtersdata.categoriesFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertCategoriesFilterStats(filterStats);
    });

    filtersList.air.filtersdata.hashFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      if (filterStats !== undefined) {
        this.convertHashFilterStats(filterStats);
      }
    });

    this.updateFilters(this.convertetFilters);
  }

  @Action
  hashFilterUpdated(value) {
    let filter: any = this.filters.find((f: any) => f.code === value.code);
    const filterExists = filter && filter.values && filter.values.length > 0;

    if (!filterExists) {
      filter = {
        code: value.code,
        values: []
      };
    }

    if (value.data.selected && !filter.values.includes(value.data.legHash)) {
      filter.values.push(value.data.legHash);
    }
    if (!value.data.selected && filter.values.includes(value.data.legHash)) {
      filter.values = filter.values.filter(v => {
        return v !== value.data.legHash;
      });
    }

    this.filters.splice(this.filters.length - 1, 0, filter);
    this.updateFilters(this.filters);
  }

  @Action
  clearHashFilters() {
    const filterData = this.filters.find(item => item.code === 'FLIGHT_NUMBERS');

    filterData.values = [];
  }

  @Action
  async loadOffers(searchId) {
    if (this.searchId !== searchId) {
      return;
    }
    const sortQuery = await this.buildSortQuery();
    let response;

    this.createCancelTokenIfNeeded();
    try {
      if (this.newFlightDisplay) {
        response = await AirApi.getAirSearchFlights({
          searchId, sortQuery
        }, this.cancelToken || undefined);
      } else {
        response = await AirApi.getAirOffers({
          searchId, sortQuery
        }, this.cancelToken || undefined);
      }
      if (this.searchId !== searchId) {
        return;
      }

      if (response.data.searchResults.recommendations) {
        let offers = response.data.searchResults.recommendations;
        let agencyMarkup = response.data.searchResults.agencyMarkup ? response.data.searchResults.agencyMarkup.amount : null;
        offers.map((offer) => {
          offer.prepareOfferCheck = false;
          offer.ssid = '';
          if (this.templateOffers.length > 0) {
            this.templateOffers.forEach((template: any) => {
              if (template.proposal && template.proposal.id === offer.proposal.id) {
                offer.prepareOfferCheck = true;
              }
            });
          }
        });

        this.setTotalOffers(response.data.searchResults.recommendationsTotalCount);
        this.setAverageCo2Emission(response.data.searchResults.averageCo2Emission);
        this.setNewAgencyMarkup(agencyMarkup);
        this.updateOffers(offers);
        this.setSearchFreezed(false);
        this.setFilterLoadingView(false);
        EventBus.$emit('hash-refresh');
      } else if (response.data.searchResults.flights) {
        let flights = response.data.searchResults.flights;
        let agencyMarkup = response.data.searchResults.agencyMarkup ? response.data.searchResults.agencyMarkup.amount : null;
        flights.map((flight) => {
          flight.prepareOfferCheck = false;
          flight.loadingProposals = true;
          flight.ssid = '';
        });

        this.setTotalOffers(response.data.searchResults.recommendationsTotalCount);
        this.setAverageCo2Emission(response.data.searchResults.averageCo2Emission);
        this.setNewAgencyMarkup(agencyMarkup);
        this.updateOffers(flights);
        this.setFilterLoadingView(false);
        EventBus.$emit('hash-refresh');
      }

      if (response.data.statistics && !this.filtersChanging) {
        this.convertFiltersStats(response.data.statistics);
        EventBus.$emit('hash-refresh');
      }

      if (response.data && response.data.currency) {
        this.setCurrency(response.data.currency);
      }
    } catch (error) {
      if (!isCancelError(error)) {
        this.updateErrors($handleErrors(error, true, await this.translateErrorParams(this.fareUnavailableErrorParams)));
        this.setShowError(true);
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  async loadExchangeOffers(searchId) {
    this.updateExchangeErrors([]);
    try {
      const sortQuery = await this.buildSortQuery();
      let response;
      if (this.newFlightDisplay) {
        response = await AirApi.getExchangeFlights({
          searchId, sortQuery
        });
      } else {
        response = await AirApi.getExchangeOffers({
          searchId, sortQuery
        });
      }

      if (response.data.searchResults.searchState === SearchState.CompletedWithErrors) {
        let errors: any[] = [];
        if (response.data.searchResults.errors) {
          response.data.searchResults.errors.forEach(e => {
            if (e.params) {
              errors.push({
                message: e.params.Message || e.params.MESSAGE,
              });
            } else {
              errors.push({
                message: e.message,
              });
            }
          });
        }
        this.updateExchangeErrors(errors);
      }
      if (response.data.searchResults.solutions && response.data.searchResults.solutions.length > 0) {
        const offers = response.data.searchResults.solutions;

        this.setTotalOffers(response.data.searchResults.solutionsTotalCount);
        this.setAverageCo2Emission(response.data.searchResults.averageCo2Emission);
        // this.setNewAgencyMarkup(response.data.searchResults.agencyMarkup.amount);
        this.updateOffers(offers);
        EventBus.$emit('hash-refresh');
      } else if (response.data.searchResults.flights && response.data.searchResults.flights.length > 0) {
        const flights = response.data.searchResults.flights;

        this.setTotalOffers(response.data.searchResults.recommendationsTotalCount);
        this.setAverageCo2Emission(response.data.searchResults.averageCo2Emission);
        // this.setNewAgencyMarkup(response.data.searchResults.agencyMarkup.amount);
        this.updateOffers(flights);
        EventBus.$emit('hash-refresh');
      }

      if (response.data.statistics && !this.filtersChanging) {
        this.convertFiltersStats(response.data.statistics);
        EventBus.$emit('hash-refresh');
      }
      this.setFilterLoadingView(false);

      if (this.searchId !== searchId) {
        return;
      }
    } catch (error) {
      this.setErrMessages({ error });
      this.setShowError(true);
    }
  }

  @Action
  public stopSearch() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.setSearchInProgress(false);
    clearInterval(this.scrollTimeout);
    this.finishLoading();
    this.clearSearchTimeout();
    this.loader(false);
  }

  @Action
  public async stepLoading(searchId) {
    if (this.searchId !== searchId) {
      return;
    }

    this.createCancelTokenIfNeeded();
    this.setShouldReloadProposals(false);

    try {
      let searchCompleted = this.searchCompleted;
      const response = await AirApi.getAirSearchState(searchId, this.stateId, this.cancelToken || undefined);

      if (this.searchId !== searchId) {
        return;
      }

      searchCompleted = response.data.searchState === SearchState.Completed;

      this.setProvidersErrors(response.data.providersErrors);

      if (response && response.data) {
        this.updateSearchStateId(response.data.stateId);
      }

      if (response.data.newResultsAvailable) {
        await this.loadOffers(searchId);

        if (this.newFlightDisplay) {
          this.setShouldReloadProposals(true);
          if (this.openOffers && this.openOffers.length) {
            this.setLoadingProposals(true);
            this.openOffers.forEach(async offer => {
              let flightNumberHash: any = [];
              if (offer && offer.legs) {
                offer.legs.forEach(leg => {
                  flightNumberHash.push(leg.flightNumberHash);
                });
              }
              await this.loadProposals({searchId, id: offer.id, flightNumberHash});
            });
          }
        }

        if (this.searchId !== searchId) {
          return;
        }
      }
      this.setRecommendationsAirCount(response.data.recommendationsTotalCount);
      if (searchCompleted) {
        this.finishLoading();
        this.setSearchCompleted(true);
        this.setShouldReloadProposals(false);
        this.loader(false);
        return;
      }

      if (this.searchId !== searchId) {
        return;
      }

      this.clearSearchTimeout();
      this.setSearchTimeout(() => {
        this.stepLoading(searchId);
      });
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.setErrMessages({ error });
        this.setShowError(true);
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  public async stepExchangeLoading(searchId) {
    if (this.searchId !== searchId) {
      return;
    }
    const response = await AirApi.getExchangeAirSearchState({searchId});

    this.setRecommendationsAirCount(response.data.recommendationsTotalCount);
    if (response.data.searchState ===  SearchState.CompletedWithErrors) {
      await this.loadExchangeOffers(searchId);
      this.finishLoading();
      this.loader(false);
      return;
    }

    let searchCompleted = response.data.searchState === SearchState.Completed;

    if (response.data.newResultsAvailable) {
      this.updateSearchStateId(response.data.stateId);
      await this.loadExchangeOffers(searchId);

      if (this.searchId !== searchId) {
        return;
      }
    }

    if (searchCompleted) {
      await this.loadExchangeOffers(searchId);

      if (this.searchId !== searchId) {
        return;
      }

      this.finishLoading();
      this.setSearchCompleted(true);
      this.loader(false);
      return;
    }
    
    if (this.searchId !== searchId) {
      return;
    }

    this.clearSearchTimeout();
    this.setSearchTimeout( () => {
      this.stepExchangeLoading(searchId);
    });
  }

  @Action
  public getOffers(searchId) {
    this.clearSearchTimeout();
    this.setupSearchLoaded(searchId);
    this.loader(true);
    this.updateSearchStateId('');
    this.stepLoading(searchId);
  }

  @Action
  public getExchangeOffers(searchId) {
    this.clearSearchTimeout();
    this.setupSearchLoaded(searchId);
    this.loader(true);
    this.updateSearchStateId('');
    this.stepExchangeLoading(searchId);
  }

  @Action
  async updateFilterOffers(data) {
    this.updateFiltersRequestId();
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.loader(true);
    this.clearSearchTimeout();
    this.setSearchFreezed(true);
    this.createCancelTokenIfNeeded();

    this.setFiltersError(false);

    try {
      const mode = this.modifyMode ? 'exchange' : 'search';
      const response = await AirApi.updateFilterOffers(mode, data.searchId, data.filtersData, this.cancelToken || undefined);
      if (response.data.statistics) {
        this.convertFiltersStats(response.data.statistics);
      }
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.setFiltersError(true);
        this.setErrMessages({ error, addMessageToError: false, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        this.setShowError(true);
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  updateLoadingProposalOffer(data) {
    if (data) {
      let id = data.offer && data.offer.offerId ? data.offer.offerId : data.id;
      let selectedOffer = this.offers.find(offer => offer.id === id);
      if (selectedOffer) {
        selectedOffer.loadingProposals = data.value;
        this.updateOffers(this.offers);
      }
    }
  }

  @Action
  updateOfferPrice(data) {
    if (data) {
      let selectedOffer = this.offers.find(offer => offer.id === data.offer.offerId);
      if (selectedOffer) {
        let selectedProposalList: any = this.offersProposalsList.find(proposal => proposal.id === data.offer.offerId);
        if (this.newFlightDisplay && selectedProposalList) {
          selectedOffer.offerProposals = selectedProposalList;
          if (selectedOffer.offerProposals) {
            let selectedProposal = selectedOffer.offerProposals.proposals.find(proposal => proposal.id === data.proposal.id);
            selectedOffer.offerProposals.priceChanged = true;
            if (selectedProposal) {
              selectedProposal.price = data.offer.totalPrice;
              selectedProposal.pricePlusMarkup = data.offer.totalPrice;
            }
          }
        } else {
          selectedOffer.proposal.priceChanged = true;
          selectedOffer.proposal.price = data.offer.totalPrice;
          selectedOffer.proposal.pricePlusMarkup = data.offer.totalPrice;
        }
        this.updateOffers(this.offers);
      }
    }
  }

  @Action
  filterExchangeData() {
    let exOffer = this.exchangedOffer;
    let dates = this.exchangeDates;
    let locations = {
      ...this.exchangeLocations,
    };

    this.setExchangeDate(dates.filter((value, index) => {
      return exOffer.legFlights[index] && exOffer.legFlights[index].selectedForEdit;
    }));

    this.setExchangeLocations({
      from: locations.from.filter((value, index) => {
        return exOffer.legFlights[index] && exOffer.legFlights[index].selectedForEdit;
      }),
      to: locations.to.filter((value, index) => {
        return exOffer.legFlights[index] && exOffer.legFlights[index].selectedForEdit;
      }),
    });
  }

  @Action
  async initExchangeAirSearch(basketId) {
    this.loader(true);
    this.updateOffers([]);
    this.updateFilters([]);
    this.setProvidersErrors([]);
    this.updateExchangeErrors([]);
    this.setTotalOffers(0);
    this.setAverageCo2Emission(null);
    this.setRecommendationsAirCount(0);
    this.setFeeConfigurationId('');
    this.setPriceDetailedComponentsVisible(false);
    let travellersList = this.exchangeTravellers;
    let exOffer = this.exchangedOffer;
    let dates = this.exchangeDates;
    let flightLegs = exOffer.legFlights.map( (element, index) => {
      if (element.selectedForEdit) {
        return {
          flightLegNumber: element.legNumber,
          isForExchange: element.selectedForEdit,
          from: {
            type: this.getExchangeLocations.from[index].type === 'CityAirport'
              ? 'Airport'
              : this.getExchangeLocations.from[index].type,
            code: this.getExchangeLocations.from[index].type === 'City'
              ? this.getExchangeLocations.from[index].cityCode
              : this.getExchangeLocations.from[index].airportCode
          },
          to: {
            type: this.getExchangeLocations.to[index].type === 'CityAirport'
              ? 'Airport'
              : this.getExchangeLocations.to[index].type,
            code: this.getExchangeLocations.to[index].type === 'City'
              ? this.getExchangeLocations.to[index].cityCode
              : this.getExchangeLocations.to[index].airportCode
          },
          date: moment(dates[index]).format('YYYY-MM-DD')
        };
      } else {
        return {
          flightLegNumber: element.legNumber,
          isForExchange: element.selectedForEdit,
          from: {
            type: this.getExchangeLocations.from[index].type === 'CityAirport'
              ? 'Airport'
              : this.getExchangeLocations.from[index].type,
            code: this.getExchangeLocations.from[index].type === 'City'
              ? this.getExchangeLocations.from[index].cityCode
              : this.getExchangeLocations.from[index].airportCode
          },
          to: {
            type: this.getExchangeLocations.to[index].type === 'CityAirport'
              ? 'Airport'
              : this.getExchangeLocations.to[index].type,
            code: this.getExchangeLocations.to[index].type === 'City'
              ? this.getExchangeLocations.to[index].cityCode
              : this.getExchangeLocations.to[index].airportCode
          },
          date: moment(dates[index]).format('YYYY-MM-DD'),
        };
      }
    });
    let message: InitExchangeAirSearchMessage = {
      parentRecommendationId: exOffer.id,
      airSupplier: exOffer.proposal ? exOffer.proposal.supplier : exOffer.supplier,
      travellers: travellersList,
      flightLegs: flightLegs,
      preferredCabinClass: this.preferredCabinClass,
    };

    this.clearInitExchangeErrors();

    try {
      const initResponse = await AirApi.initExchangeAirSearch(message, basketId);
      if (initResponse && initResponse.data) {
        this.clearExchangeErrors();
        this.clearInitExchangeErrors();
        this.filterExchangeData();
        router.push({
          name: 'airModification',
          params: {
            searchId: initResponse.data.searchId,
            basketId,
          }
        });
      }
    } catch (error) {
      this.loader(false);
      this.setInitExchangeErrors(error);
      this.setShowError(true);
    }
  }

  @Action
  async initAirSearch(basketId?: string) {
    this.setCurrentSearchIdFromApi('');
    this.loader(true);
    this.setShowErrorBE(false);
    this.setShowError(false);
    this.updateOffers([]);
    this.setProvidersErrors([]);
    this.updateExchangeErrors([]);
    this.setTotalOffers(0);
    this.setAverageCo2Emission(null);
    this.setFeeConfigurationId('');
    this.setPriceDetailedComponentsVisible(false);
    this.setNewSort({ sorterCode: 'BEST_FARE_DEPARTURE_TIME', isAscending: false });
    this.setRecommendationsAirCount(0);
    this.setSearchInProgress(true);
    const params = SearchStore.getAirDefaultState;
    const travellersList = SearchStore.getTravellersState;
    this.setSearchCompleted(false);

    const hasVirtualTravellers = travellersList.travellers
      .some(traveller => !!traveller.isVirtual);
    const searchCompanyId = hasVirtualTravellers ? SearchStore.skipTravellersCompany.id : null;

    let request: InitAirSearchMessage | any = {
      searchMode: params.searchMode,
      from: null,
      to: null,
      departureDate: null,
      returnDate: null,
      travellers: travellersList.travellers,
      cabinClass: params.cabinClasses[0],
      cabinClasses: params.cabinClasses,
      offlineRequestId: params.offlineRequestId,
      nonStopsOnly: params.nonStopsOnly,
      flexOnly: params.flexOnly,
      isSplitSearchEnabled: params.isSplitSearchEnabled,
      additionalLuggageOnly: params.additionalLuggageOnly,
      inwardCabinClass: params.searchMode === searchModes.oneWay ? null : params.cabinClasses[1].value,
      outwardCabinClass: params.cabinClasses[0].value,
      outwardTimeWindows: null,
      inwardTimeWindows: null,
      alliances: params.alliance.length && null !== params.alliance[0].value ? [params.alliance[0].value] : [],
      carriers: params.airlines.map(item => item.code),
      connectingLocations: params.connectionAirports
        .map(item => item.type === 'City' ? item.cityCode : item.airportCode),
      searchCompanyId,
      advancedSearchParametersId: params.advancedSearchParametersId,
    };

    if (params.searchMode === 'MultiLeg') {
      this.requestAirSearchClear();
      this.requestAirSearchMultiLeg();

      request = {
        ...request,
        legs: this.legs
      };

    } else {
      request = {
        ...request,
        legs: [],
        from: {
          type: params.from!.type === 'CityAirport'
              ? 'Airport'
              : params.from!.type,
          code: params.from!.type === 'City' ? params.from!.cityCode : params.from!.airportCode
        },
        to: {
          type: params.to!.type === 'CityAirport'
            ? 'Airport'
            : params.to!.type,
          code: params.to!.type === 'City' ? params.to!.cityCode : params.to!.airportCode
        },
        departureDate: params.departureDate,
        returnDate: params.returnDate,
      };
    }

    if (params.outwardTimeWindows && params.outwardTimeWindows.departureWindow) {
      request.outwardTimeWindows = {
        departureWindow: {
          start: '' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.departureWindow[0] / 60) + ':' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.departureWindow[0] % 60) + ':00',
          end: '' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.departureWindow[1] / 60) + ':' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.departureWindow[1] % 60) + ':00',
        } as TimeWindow
      } as LegTimeWindow;
    }

    if (params.inwardTimeWindows && params.inwardTimeWindows.departureWindow) {
      request.inwardTimeWindows = {
        departureWindow: {
          start: '' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.departureWindow[0] / 60) + ':' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.departureWindow[0] % 60) + ':00',
          end: '' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.departureWindow[1] / 60) + ':' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.departureWindow[1] % 60) + ':00',
        } as TimeWindow
      } as LegTimeWindow;
    }

    if (params.outwardTimeWindows && params.outwardTimeWindows.timeRange) {
      request.outwardTimeWindows = {
        departureWindow: {
          start: '' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.timeRange[0] / 60) + ':' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.timeRange[0] % 60) + ':00',
          end: '' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.timeRange[1] / 60) + ':' + ToTwoDigitStringValue(<number>params.outwardTimeWindows.timeRange[1] % 60) + ':00',
        } as TimeWindow
      } as LegTimeWindow;
    }

    if (params.inwardTimeWindows && params.inwardTimeWindows.timeRange) {
      request.inwardTimeWindows = {
        departureWindow: {
          start: '' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.timeRange[0] / 60) + ':' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.timeRange[0] % 60) + ':00',
          end: '' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.timeRange[1] / 60) + ':' + ToTwoDigitStringValue(<number>params.inwardTimeWindows.timeRange[1] % 60) + ':00',
        } as TimeWindow
      } as LegTimeWindow;
    }

    if (router.currentRoute.query.offlineRequestId && !router.currentRoute.params.searchId) {
      request.offlineRequestId = router.currentRoute.query.offlineRequestId as string;
    }

    setTimeout(() => {
      SearchStore.select(serviceClassEnum.Flight);
    });

    router.push({
      name: 'air',
      params: { searchId: '-' }
    });

    try {
      const missionId = SearchStore.missionId;
      let initResponse;
      if (basketId && !missionId) {
        initResponse = await AirApi.addSegment(request, basketId);
        await BasketStore.getBasketMetadata(basketId);
      } else if (missionId && !basketId) {
        initResponse = await AirApi.initAirSearchForMission(request, missionId);
      } else if (basketId && missionId) {
        initResponse = await AirApi.addSegmentForMission(request, basketId, missionId);
        await BasketStore.getBasketMetadata(basketId);
      } else {
        initResponse = await AirApi.initAirSearch(request);
      }
      
      if (initResponse && initResponse.data && this.searchInProgress) {
        this.setCurrentSearchIdFromApi(initResponse.data.searchId);
        this.setSearchInProgress(false);
        clearInterval(this.scrollTimeout);
        this.setTimeoutInterval(setInterval(() => {
          if (!LayoutStore.slots.intro.transitioning) {
            clearInterval(this.scrollTimeout);
            router.replace({
              name: 'air',
              params: { searchId: initResponse.data.searchId }
            });
          }
        }, 30));
      }
    } catch (error) {
      this.setSearchInProgress(false);
      this.loader(false);
      this.setErrMessages({ error });
      this.setShowError(true);
      router.push({
        name: 'airDetails',
        params: router.currentRoute.params
      });
    }
  }

  @Action
  async getSearchSession(searchId) {
    try {
      const response = await AirApi.getAirSearchSession({
        searchId
      });
      const sorterCode = response.data.metadata.defaultSortingMethod;
      this.setNewSort({
        sorterCode,
        isAscending: sorterCode !== 'BEST_FARE_DEPARTURE_TIME',
      });
      const request = response.data.request as any;
      const getAirportName = (option) => {
        let name = '';
        if (option.type === 'City') {
          name += option.cityName;
          name += ' (' + translate('search.all-airports') + ')';
        } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
          name += option.airportName;
          name += ' (' + option.cityName + ')';
        }
        return name;
      };
      const outwardWindow = SearchConst.searchTimeValues.find(elem => {
        if (request.outwardTimeWindows && request.outwardTimeWindows.departureWindow) {
          return _.isEqual(elem.timeRange, request.outwardTimeWindows.departureWindow);
        }
      }) || SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];
      const inwardWindow = SearchConst.searchTimeValues.find(elem => {
        if (request.inwardTimeWindows && request.inwardTimeWindows.departureWindow) {
          return _.isEqual(elem.timeRange, request.inwardTimeWindows.departureWindow);
        }
      }) || SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];

      if (!request.departureDate) {
        request.departureDate = moment()
        .add(1, 'week')
        .format('YYYY-MM-DD');
      }

      if (!request.returnDate) {
        request.returnDate = moment()
        .add(1, 'week').add(1, 'days')
        .format('YYYY-MM-DD');
      }

      let legsData =
        request.legs && request.legs.length
          ? request.legs.map(leg => {
              const timeWindows = SearchConst.searchTimeValues.find(elem => {
                if (leg.timeWindows && leg.timeWindows.departureWindow) {
                  return _.isEqual(elem.timeRange, leg.timeWindows.departureWindow);
                }
              });

              return {
                ...leg,
                cabinClasses: [
                  SearchConst.cabinClasses.find(e => {
                    return e.value === leg.cabinClass;
                  })
                ],
                timeWindows: timeWindows ? timeWindows : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
              };
            })
          : {};
          
      const economyClass = SearchConst.cabinClasses
        .find(e => e.value === cabinClassEnum.Economy);

      const params = {
        ...request,
        legs: request.legs && request.legs.length ? [ ...legsData] : [new Legs()],
        cabinClasses: [
          SearchConst.cabinClasses.find(e => {
            return e.value === request.outwardCabinClass;
          }) || economyClass,
          SearchConst.cabinClasses.find(e => {
            return e.value === request.inwardCabinClass;
          }) || economyClass,
        ],
        alliance: (request.alliances || [])
          .map(alliance => {
            return SearchConst.alliances
              .find(item => item.value === alliance);
          }).map(alliance => {
            return {
              ...alliance,
              label: translate(alliance.label),
            };
          }),
        airlines: (request.carriers || [])
          .map(carrier => {
            return DictionaryStore.allAirLinesSorted
              .find(item => item.code === carrier);
          }),
        connectionAirports: [],
        outwardTimeWindows: outwardWindow ? outwardWindow : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
        inwardTimeWindows: inwardWindow ? inwardWindow : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
      };
      const languageCode = AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
      const locations: any[] = await Promise.all(
        (request.connectingLocations || []).map(async code => {
          const locationResponse = await HomeApi.findAirLocation(code, languageCode);
          return {
            data: locationResponse.data,
            code,
          };
        })
      );
  
      locations.forEach(({ data, code }) => {
        const elem = data.find(item => {
          return item.cityCode === code || item.airportCode === code;
        });
        if (elem) {
          params.connectionAirports.push({
            ...elem,
            uniqId: elem.cityCode + elem.airportCode,
            label: getAirportName(elem),
          });
        }
      });

      if (router.currentRoute.params.searchId !== searchId) {
        return;
      }
  
      SearchStore.updateAirCurrentState(params);
      SearchStore.updateAirDefaultState(params);
      SearchStore.updateSearchModeStatus(response.data.request.searchMode);
      let travellers = {
        travellers: response.data.request.travellers
      };
      SearchStore.updateTravellersDefaultState(travellers);
      SearchStore.updateEditedTravellers(travellers);
      SearchStore.updateSkipTravellers(travellers.travellers.some(trav => {
        return !!trav.isVirtual;
      }));

      let isVirtualTraveller = travellers.travellers.find(trav => trav.isVirtual === true);

      if (isVirtualTraveller) {
        let companyModel =  {
          name: isVirtualTraveller.businessUnitName,
          code: isVirtualTraveller.companyCode,
          id: isVirtualTraveller.businessUnitId,
          isDisabled: false,
          parentId: null,
          rootId: isVirtualTraveller.companyId,
          isSelected: false,
        };

        SearchStore.setSkipTravellersCompany(companyModel);
        EventBus.$emit('update-skip-travellers-company');
      }

      this.updateSearchStateId(response.data.metadata.stateId);
      SearchStore.selectBasket(response.data.metadata.basketId);
      SearchStore.setMissionId(response.data.metadata.missionId);
      this.setPriceDetailedComponentsVisible(response.data.metadata.hasDetailedPriceComponentAccess);
      if (response.data.metadata.missionId !== null) {
        await SearchStore.getMissionDetails();
      }
      if (!BasketStore.basketMetadata! || BasketStore.basketMetadata!.basketId !== response.data.metadata.basketId) {
        await BasketStore.getBasketMetadata(response.data.metadata.basketId);
      }

      SearchStore.selectNavigated(serviceClassEnum.Flight);
      SearchStore.select(serviceClassEnum.Flight);
    } catch (error) {
      this.loader(false);
      this.setErrMessages({ error });
      this.setShowError(true);
      if (error.response.status === 404) {
        EventBus.$emit('show-toast', {
          type: translate('common.error'),
          title: translate('common-error.session-unavailable-header'),
          message: translate('common-error.session-unavailable')
        });
        router.push({
          name: 'home'
        });
      }
    }
  }

  @Action
  async checkForUpsellOffers() {
    if (!AccountStore.HasPermission('CanSeeUpsellOffers')) {
      return;
    }
    if (
      (this.newFlightDisplay && !this.currentSelectedGroupedOffer) ||
      (!this.newFlightDisplay && !this.currentSelectedOffer)
    ) {
      return;
    }

    if (
      (!this.newFlightDisplay &&
      this.currentSelectedOffer &&
      this.currentSelectedOffer.proposal &&
      this.currentSelectedOffer.proposal.supplier !== 'AirFranceKlm') ||
      (this.newFlightDisplay && (!this.currentSelectedGroupedOffer ||
      this.currentSelectedGroupedOffer.supplier !== 'AirFranceKlm'))
    ) {
      return;
    }

    let providerSearchId = '';

    if (this.newFlightDisplay) {
      providerSearchId = this.currentSelectedGroupedOffer.providerSearchId;
    } else if (this.currentSelectedOffer) {
      providerSearchId = this.currentSelectedOffer.providerSearchId;
    }

    try {
      const response = await AirApi.getUpsellData(this.searchId, this.basketItemsRequest.recommendationId, providerSearchId);

      this.setUpsellOffers(response.data.offers);
      this.setUpsellCurrentOffer(response.data.selectedOffer);

      if (response.data.offers && response.data.offers.length > 0) {
        this.setUpsellChosen(null);
        router.push({
          name: 'airUpsell',
          params: {
            searchId: this.searchId,
            providerSearchId,
            recommendationId: this.basketItemsRequest.recommendationId,
            force: !!this.basketItemsRequest.force ? '1' : '0',
          },
        });
        this.setShouldShowUpsell(true);
      }
    } catch (error) {
      this.setErrMessages({ error });
    }
  }

  @Action
  async AddOfferToCartWithReason(reason) {
    this.setShouldShowUpsell(false);
    await this.checkForUpsellOffers();

    if (this.shouldShowUpsell) {
      return;
    }

    try {
      const response = await AirApi.createBasket(this.searchId, {
        ...this.basketItemsRequest,
        missedSavingReasonCode: reason,
      });

      if (response && response.data) {
        this.stopSearch();
        this.setBasketId(response.data.tripId);
        SearchStore.selectBasket(response.data.tripId);
        if (response.data.confirmationNeeded) {
          this.setPrevSelectedOffer(response.data.previousRecommendation);
          this.setLoadingRestProposals(false);
          this.setShowModal(true);
        } else {
          this.setShowModal(false);
          this.setLoadingRestProposals(false);
          this.toggleMissedSavingsModal(false);
          this.clear();
          router.push({
            name: 'basket',
            params: { id: this.basketId }
          });
        }
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: this.basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async CheckIfCanAddOfferToCart(basketItemsRequest) {
    try {
      this.setBasketItemRequest(basketItemsRequest);
      const response = await AirApi.checkIfCanCreateBasket(this.searchId, basketItemsRequest.recommendationId);

      if (
        response.data &&
        response.data.isMissedSavingsCalculationInProgress === false &&
        (!response.data.missedSaving || (
          response.data.missedSaving &&
          !response.data.missedSaving.missedSavingValue
        ))

      ) {
        if (this.basketItemsRequest.recommendationId === response.data.recommendationId) {
          this.AddOfferToCart(basketItemsRequest);
        }
      } else if (response.data) {
        this.showMissedSavingsModal(response.data);
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async AddOfferToCartNow(basketItemsRequest) {
    try {
      const response = await AirApi.createBasket(this.searchId, basketItemsRequest);
      
      if (response.data && !response.data.missedSaving) {
        this.stopSearch();
        this.setBasketId(response.data.tripId);
        SearchStore.selectBasket(response.data.tripId);
        if (response.data.confirmationNeeded) {
          this.setPrevSelectedOffer(response.data.previousRecommendation);
          this.setLoadingRestProposals(false);
          this.setShowModal(true);
        } else {
          this.setShowModal(false);
          this.setLoadingRestProposals(false);
          this.clear();
          if (router.currentRoute.name === 'airUpsell') {
            router.replace({
              name: 'basket',
              params: { id: this.basketId }
            });
          } else {
            router.push({
              name: 'basket',
              params: { id: this.basketId }
            });
          }
        }
      } else if (response.data) {
        this.showMissedSavingsModal(response.data);
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });

        if (router.currentRoute.name === 'airUpsell') {
          LayoutStore.scrollUp();
          return;
        }
        router.push({
          name: 'airDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async AddOfferToCart(basketItemsRequest) {
    this.setBasketItemRequest(basketItemsRequest);
    this.setShouldShowUpsell(false);
    await this.checkForUpsellOffers();

    if (this.shouldShowUpsell) {
      return;
    }
    await this.AddOfferToCartNow(basketItemsRequest);
  }

  @Action
  async retrievesExchangeOfferPrice(data) {
    this.setRetrievesExchangeOffer(null);
    let exOffer = this.exchangedOffer;
    let params = {
      parentRecommendationId: exOffer.id,
      supplier: data.supplier,
      providerSearchId: data.providerSearchId,
      searchId: this.searchId
    };

    try {
      const initResponse = await OffersApi.retrievesExchangeOfferPrice(data.id, params);
      if (initResponse && initResponse.data) {
        this.setRetrievesExchangeOffer(initResponse.data);
      }
    } catch (error) {
      if (data.supplier === 'Lufthansa' || data.supplier === 'AmericanAirlines' || data.supplier === 'Emirates') {
        this.setShowCustomExchangeLufthansaPopup(true);
        if (error.response && error.response.data) {
          this.setCustomExchangeLufthansaError(error.response.data);
        }
      } else if (error.response && error.response.status === 418 && error.response.data.error.code === 'LHG_Prov_Error') {
        let customError = error.response.data.error.details;
        customError[0].message += [
          translate(messages.serviceTagPrefix),
          error.response.data.error.serviceTag,
          messages.serviceTagSufix
        ].join('');
        this.setCustomError(customError);
      } else {
        this.setErrMessages({ error });
      }
      this.loader(false);
      this.setShowError(true);
    }
  }

  @Action
  async exchangeCandidate({ recommendationId, sessionId }) {
    this.setExchangeInitErrors([]);
    try {
      await OffersApi.exchangeCandidate(recommendationId, sessionId);
    } catch (error) {
      this.setExchangeInitErrors($handleErrors(error));
    }
  }

  @Action
  async loadProposals(data) {
    this.updateLoadingProposalOffer({id: data.id, value: true});
    try {
      const sortQuery = await this.buildSortQuery();
      let flightsHashes = '';
      data.flightNumberHash.forEach((hash, index) => {
        flightsHashes += `&flightsHashes[${index}]=${hash}`;
      });
      let response;
      if (data.isExchange) {
        response = await AirApi.getExchangeProposals({searchId: data.searchId, sortQuery, flightsHashes: flightsHashes});
      } else {
        response = await AirApi.getSearchFlightProposals({searchId: data.searchId, sortQuery, flightsHashes: flightsHashes});
      }

      if (response && response.data) {
        if (response.data.moreProposalsAvailable) {
          this.setShouldReloadProposals(true);
        }

        this.setOffersProposalsList({
          ...response.data,
          id: data.id
        });
      }
      this.updateLoadingProposalOffer({id: data.id, value: false});
    } catch (error) {
      this.loader(false);
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.updateLoadingProposalOffer({id: data.id, value: false});
    }
  }

  @Action
  translateErrorParams(params) {
    if (params && params.codes) {
      Object.keys(params.codes).forEach(code => {
        params.codes[code] = translate(params.codes[code]);
      });
    }
    return params;
  }

  @Action
  async compareOfferDetails(params) {
    this.setCompareErrMessages(null);
    this.setLoaderCompare(true);
    this.setCompareOfferDetails([]);
    try {
      const initResponse = await CompareOffersApi.offerDetails(params);
      if (initResponse && initResponse.data) {
        this.setCompareOfferDetails(initResponse.data);
        this.setLoaderCompare(false);
      }
    } catch (error) {
      this.setCompareErrMessages(error);
      this.setLoaderCompare(false);
      this.setShowError(true);
    }
  }

  @Action
  async getSplitProvidersForCompany(companyId: string) {
    this.setPermissionErrSplitProvidersForCompanyMessages(false);
    this.setErrSplitProvidersForCompanyMessages(null);
    this.setLoaderSplitProvidersForCompany(true);
    this.setSplitProvidersForCompany([]);
    try {
      const response = await AirApi.getSplitProvidersForCompany(companyId);
      if (response && response.data) {
        this.setSplitProvidersForCompany(response.data);
        this.setLoaderSplitProvidersForCompany(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) { 
        this.setPermissionErrSplitProvidersForCompanyMessages(true);
      } else {
        this.setErrSplitProvidersForCompanyMessages(error);
        this.setShowError(true);
      }
      this.setLoaderSplitProvidersForCompany(false);
    }
  }

}

export default getModule(AirSearchStore);
