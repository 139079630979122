
































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';

import SettingsStore from '@/modules/settings/settings.store';
import SystemMarkupConfigurationStore from './system-markup-configuration.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { required, maxLength } from 'vuelidate/lib/validators';
import SystemMarkupListAir from './SystemMarkupListAir.vue';
import SystemMarkupListRail from './SystemMarkupListRail.vue';
import SystemMarkupListHotel from './SystemMarkupListHotel.vue';

const normalizeRulesList = (rule, index) => {
  let entry = { ...rule };
  entry.order = index;
  delete entry.id;
  delete entry.status;

  return entry;
};

@Component({
  components: {
    SystemMarkupListAir,
    SystemMarkupListRail,
    SystemMarkupListHotel,
  }
})
export default class SystemMarkupConfiguration extends Vue {
  $v;
  imagesConst: string = '/assets/img/loader/1.gif';
  reallyNewPath: boolean = false;
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
      disabled: false,
    },
    {
      value: 'Rail',
      name: translate('settings-travel-policy.rail'),
      disabled: false,
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation'),
      disabled: false,
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
      disabled: true,
    }
  ];

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      }
    };
  }

  get loading() {
    return SystemMarkupConfigurationStore.Loading;
  }

  get trainErrors() {
    return SystemMarkupConfigurationStore.trainErrors;
  }

  get errMessages() {
    return SystemMarkupConfigurationStore.errMessages;
  }

  get saveErrors() {
    return SystemMarkupConfigurationStore.saveErrors.map(item => {
      return {
        message: translate('settings-fees.save-failure') + item.message,
      };
    });
  }

  get trainSaveErrors() {
    return SystemMarkupConfigurationStore.trainSaveErrors.map(item => {
      return {
        message: translate('settings-fees.train-save-failure') + item.message,
      };
    });
  }

  get hotelSaveErrors() {
    return SystemMarkupConfigurationStore.hotelSaveErrors.map(item => {
      return {
        message: translate('settings-fees.hotel-save-failure') + item.message,
      };
    });
  }

  get showMessages() {
    return SystemMarkupConfigurationStore.showError;
  }

  get links() {
    return this.navLinks;
  }

  get configurationId() {
    return this.$route.params.configurationId || '';
  }

  get storeConfigurationId() {
    return SystemMarkupConfigurationStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isFromManage() {
    return SystemMarkupConfigurationStore.isFromManage;
  }

  get currentConfiguration() {
    return SystemMarkupConfigurationStore.CurrentConfiguration;
  }

  get currentConfigurationName() {
    return SystemMarkupConfigurationStore.configurationName;
  }

  set currentConfigurationName(value) {
    SystemMarkupConfigurationStore.setConfigurationName(value);
  }

  get configIsEdit() {
    return this.storeConfigurationId && this.storeConfigurationId !== 'new' ? !!this.storeConfigurationId : false;
  }

  get selectedTab() {
    return SystemMarkupConfigurationStore.selectedTab;
  }

  get canShowCustomError() {
    return SystemMarkupConfigurationStore.canShowCustomError;
  }

  changeSelectedTab(item) {
    if (item.disabled) {
      return;
    }
    SystemMarkupConfigurationStore.setSelectedTab(item.value);
  }

  backToConfigurationsList() {
    SystemMarkupConfigurationStore.setIsFromManage(false);
    router.push({
      name: 'system-markup-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  async saveFeesConfigurations() {
    this.$v.currentConfigurationName.$touch();
    if (this.$v.$error) {
      return;
    }
    this.reallyNewPath = false;
    SystemMarkupConfigurationStore.setLoading(true);
    SystemMarkupConfigurationStore.setTrainLoading(true);
    SystemMarkupConfigurationStore.setHotelLoading(true);
    await this.saveFeesAirConfiguration();
    await this.saveFeesTrainConfiguration();
    await this.saveFeesHotelConfiguration();

    if (this.reallyNewPath) {
      router.push({
        name: 'system-markup-configuration',
        params: {
          id: this.$route.params.id,
          configurationId: this.storeConfigurationId
        }
      });
    }
    
    SystemMarkupConfigurationStore.setLoading(false);
    SystemMarkupConfigurationStore.setTrainLoading(false);
    SystemMarkupConfigurationStore.setHotelLoading(false);

    if (
      SystemMarkupConfigurationStore.errors.length ||
      SystemMarkupConfigurationStore.trainErrors.length ||
      SystemMarkupConfigurationStore.hotelErrors.length
    ) {
      return;
    }

    EventBus.$emit('show-toast', {
      type: translate('common.success'),
      title: translate('settings-fees.configuration-saved'),
      message: translate('settings-fees.configuration-saved-fees')
    });
  }

  async saveFeesAirConfiguration() {
    SystemMarkupConfigurationStore.setErrors([]);

    let request;
    let flightRules = SystemMarkupConfigurationStore.rulesList.map(normalizeRulesList);
    const isEnabled = SystemMarkupConfigurationStore.isAirConfigurationEnabled;

    if (this.currentCompany && (!this.storeConfigurationId || this.storeConfigurationId === 'new')) {
      try {
        request = {
          rootCompanyId: this.currentCompany.rootCompanyId,
          data: {
            name: this.currentConfigurationName,
            isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
            isEnabled: isEnabled,
            feesRules: flightRules,
          },
        };
        await SystemMarkupConfigurationStore.createAirConfiguration(request);
        if (this.storeConfigurationId !== '00000000-0000-0000-0000-000000000000') {
          this.reallyNewPath = true;
        }
      } catch (error) {
        SystemMarkupConfigurationStore.setErrors(this.$handleErrors(error, true));
        SystemMarkupConfigurationStore.setShowError(true);
      }
    } else {
      try {
        request = {
          name: this.currentConfigurationName,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules: flightRules,
        };

        await SystemMarkupConfigurationStore.updateAirConfiguration({ configurationId: this.storeConfigurationId, params: request });
      } catch (error) {
        SystemMarkupConfigurationStore.setErrors(this.$handleErrors(error, true));
        SystemMarkupConfigurationStore.setShowError(true);
      }
    }
  }

  async saveFeesTrainConfiguration() {
    SystemMarkupConfigurationStore.setTrainErrors([]);
    const feesRules = SystemMarkupConfigurationStore.trainRulesList.map(normalizeRulesList);
    const isEnabled = SystemMarkupConfigurationStore.isTrainConfigurationEnabled;

    if (this.currentCompany && (this.reallyNewPath || SystemMarkupConfigurationStore.trainCustomError)) {
      await SystemMarkupConfigurationStore.createTrainConfiguration({
        configurationId: this.storeConfigurationId,
        configuration: {
          rootCompanyId: this.currentCompany.rootCompanyId,
          data: {
            name: this.currentConfigurationName,
            isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
            isEnabled: isEnabled,
            feesRules,
          },
        },
      });
    } else {
      await SystemMarkupConfigurationStore.updateTrainConfiguration({
        configurationId: this.storeConfigurationId,
        data: {
          name: this.currentConfigurationName,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules,
        },
      });
    }
  }

  async saveFeesHotelConfiguration() {
    SystemMarkupConfigurationStore.setHotelErrors([]);
    const feesRules = SystemMarkupConfigurationStore.hotelRulesList.map(normalizeRulesList);
    const isEnabled = SystemMarkupConfigurationStore.isHotelConfigurationEnabled;

    if (this.currentCompany && (this.reallyNewPath || SystemMarkupConfigurationStore.hotelCustomError)) {
      await SystemMarkupConfigurationStore.createHotelConfiguration({
        configurationId: this.storeConfigurationId,
        configuration: {
          rootCompanyId: this.currentCompany.rootCompanyId,
          data: {
            name: this.currentConfigurationName,
            isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
            isEnabled: isEnabled,
            feesRules,
          },
        },
      });
    } else {
      await SystemMarkupConfigurationStore.updateHotelConfiguration({
        configurationId: this.storeConfigurationId,
        data: {
          name: this.currentConfigurationName,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules,
        },
      });
    }
  }

  @Watch('$route.params', { deep: true })
  onParamsChange() {
    this.loadAll();
  }

  async loadAll() {
    await Promise.all([
      SystemMarkupConfigurationStore.getAirConfiguration(this.configurationId),
      SystemMarkupConfigurationStore.getTrainConfiguration(this.configurationId),
      SystemMarkupConfigurationStore.getHotelConfiguration(this.configurationId),
      SystemMarkupConfigurationStore.getConfigurationName({ companyId: this.$route.params.id, configurationId: this.configurationId }),
    ]);
  }

  async created() {
    SystemMarkupConfigurationStore.clearErrors();
    if ((this.configurationId && this.configurationId !== 'new' && this.configurationId !== '00000000-0000-0000-0000-000000000000') && !this.isFromManage) {
      this.loadAll();
      SystemMarkupConfigurationStore.setConfigurationId(this.configurationId);
    }
  }
}

