

















































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import ExpensePolicyStore from './expense-policy.store';
import { ExpenseApi } from '@/api/expense/expense.api';
import { ExpenseDefinitionModel } from '@/api/expense/expense.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class ExpensePolicyDefinitions extends BaseTable<ExpenseDefinitionModel[]> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: ExpenseDefinitionModel | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  changeDefaultPopup: boolean = false;
  fields = {
    default: {
      sortable: false,
      label: translate('settings-expense.default'),
    },
    name: {
      sortable: false,
      label: translate('settings-expense.name'),
    },
    description: {
      sortable: false,
      label: translate('settings-expense.description'),
    },
    currency: {
      sortable: false,
      label: translate('settings-expense.currency'),
    },
    active: {
      sortable: false,
      label: translate('settings-expense.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get expensesList() {
    return ExpensePolicyStore.configurations;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (this.$route.params.configurationId) {
      return this.$route.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.expensesList.length || 0;
  }

  get loadingPage() {
    return ExpensePolicyStore.loading;
  }

  toggleMenu(item) {
    if (this.selected !== item) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item, name) {
    router.push({
      name: name,
      params: {
        configurationId: this.$route.params.configurationId,
        itemId: item.id
      }
    });
  }

  async removeField() {
    try {
      await ExpenseApi.removeExpensePolicy(this.selected!.id);
      this.removePopup = false;
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-removed'),
        message: translate('settings-expense.policy-removed-success')
      };
      EventHandler.$emit('show-toast', obj);
      ExpensePolicyStore.getExpensePoliciesConfiguration(this.$route.params.configurationId);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    }
  }

  selectDefault(item) {
    this.expensesList.forEach(list => list.isDefault = false);
    item.isDefault = true;
    this.selected = item;
    this.changeDefaultPopup = true;
  }

  closeDefaultPopup() {
    ExpensePolicyStore.setConfigurations([]);
    this.changeDefaultPopup = false;
    ExpensePolicyStore.getExpensePoliciesConfiguration(this.$route.params.configurationId);
  }

  async changeDefault() {
    let param = {
      expensePolicyId: this.selected!.id
    };
    await ExpensePolicyStore.updateDefaultExpensePolicy(param);
    this.changeDefaultPopup = false;
  }

  remove() {
    this.errorsOnPopup = [];
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }
}
