






























































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';

@Component({
})
export default class BasketPriceDetails extends Vue {
  @Prop() offer!: any;
  @Prop() item!: any;
  @Prop() travellers!: any;
  @Prop() show!: boolean;


  get agencyClasses() {
    return {
      'mb-3': this.showExtrasTravellerMapper,
      'my-3': !this.showExtrasTravellerMapper,
    };
  }

  get summaryClasses() {
    return {
      'mb-3': this.showExtrasTravellerMapper && !this.item.price.agencyFee && !this.showResidualValueTravellerMapper,
      'my-3': !this.showExtrasTravellerMapper || this.item.price.agencyFee || this.showResidualValueTravellerMapper,
    };
  }

  get showFaresTravellerMapper() {
    return !!this.offer.passengers
      .find(passenger => {
        return passenger.fares && passenger.fares.filter(fare => fare.totalPrice.amount > 0).length;
      });
  }

  get faresTravellerMapper() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    let trav: any[] = [];

    this.travellers.map(traveller => {
      const passenger = this.offer.passengers
        .find(p => p.profileId === traveller.id);

      if (passenger && passenger.fares && passenger.fares.length) {
        trav.push({
          ...traveller,
          fares: passenger.fares.filter(fare => fare.totalPrice.amount > 0),
          passengerTypeCode: passenger.passengerTypeCode,
        });
      }
    });

    return trav;
  }

  get showExtrasTravellerMapper() {
    return !!this.offer.passengers.find(passenger => passenger.ancillaries && passenger.ancillaries.length);
  }

  get extrasTravellerMapper() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    let trav: any[] = [];

    this.travellers.forEach(traveller => {
      const passenger = this.offer.passengers
        .find(p => p.profileId === traveller.id);

      if (passenger && passenger.ancillaries && passenger.ancillaries.length) {
        trav.push({
          ...traveller,
          ancillaries: passenger.ancillaries,
        });
      }
    });

    return trav;
  }

  get showResidualValueTravellerMapper() {
    return !!this.offer.passengers.find(passenger => passenger.residualValues && passenger.residualValues.length);
  }

  get residualValuesTravellerMapper() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    let trav: any[] = [];

    this.travellers.forEach(traveller => {
      const passenger = this.offer.passengers
        .find(p => p.profileId === traveller.id);

      if (passenger && passenger.residualValues && passenger.residualValues.length) {
        trav.push({
          ...traveller,
          residualValues: passenger.residualValues,
        });
      }
    });

    return trav;
  }



  userFullName(user) {
    return userFullName(user);
  }

  @Emit('update:show')
  hide() {
    return false;
  }
}

