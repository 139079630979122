













































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { OffersApi } from '@/api/air-engine/offers.api';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { Journey, Proposal } from '@/api/train-engine/train-search.model';
import HotelResultsRow from '@/modules/search/hotel/HotelResultsRow.vue';
import BasketAirOffer from './BasketAirOffer.vue';
import TrainResultsBasketRow from '@/modules/search/train/TrainResultsBasketRow.vue';
import CarResultsRow from '@/modules/search/car/CarResultsRow.vue';
import BasketStore from './basket.store';
import { TravelPolicyResult } from '@/api/trip/basket.model';

@Component({
  components: {
    BasketAirOffer,
    TrainResultsBasketRow,
    HotelResultsRow,
    CarResultsRow,
  },
})
export default class BasketItemWithTravelPolicyResult extends Vue {
  @Prop() item!: any;

  itemDetails: any = null;
  accomodationItem: any = null;
  loading: boolean = true;
  loadDetailsErrors: any[] = [];



  get basketItemStatusName() {
    return this.item.status;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get bookingStepDef() {
    return BasketStore.bookingStepDef;
  }

  get isStepTripItemSpecific() {
    return this.bookingStepDef && this.bookingStepDef.tripItemId;
  }

  get basketExpired() {
    return this.$route.name === 'basket-expired';
  }

  get itemDetailsMapped() {
    return {
      ...this.itemDetails,
      travelPolicy: this.item.travelPolicyCompliance.travelPolicyResultChange.currentTravelPolicyResult,
    };
  }

  get itemMapped() {
    return {
      ...this.item,
      travelPolicy: this.item.travelPolicyCompliance.travelPolicyResultChange.currentTravelPolicyResult,
    };
  }

  get currentChange() {
    return this.item.travelPolicyCompliance.travelPolicyResultChange;
  }

  get isBlocked() {
    return this.item.travelPolicyCompliance.isBlockedByTravelPolicyChange;
  }

  get compliantUnchanged() {
    return this.currentChange.currentTravelPolicyResult === TravelPolicyResult.Compliant &&
      this.currentChange.previousTravelPolicyResult === TravelPolicyResult.Compliant;
  }

  get notCompliantUnchanged() {
    return this.currentChange.currentTravelPolicyResult === TravelPolicyResult.NonCompliant &&
      this.currentChange.previousTravelPolicyResult === TravelPolicyResult.NonCompliant;
  }

  get turnedIntoNotCompliant() {
    return this.currentChange.currentTravelPolicyResult === TravelPolicyResult.NonCompliant &&
      this.currentChange.previousTravelPolicyResult === TravelPolicyResult.Compliant;
  }

  get turnedIntoCompliant() {
    return this.currentChange.currentTravelPolicyResult === TravelPolicyResult.Compliant &&
      this.currentChange.previousTravelPolicyResult === TravelPolicyResult.NonCompliant;
  }

  get priceChangesForItem() {
    return BasketStore.priceChanges
      .filter(item => item.tripItemId === this.item.id)
      .reduce((prev, cur) => {
        const sum = cur.prices.reduce((p, c) => {
          return p + c.amount;
        }, 0);

        return prev + sum;
      }, 0);
  }

  get railTotalPrice() {
    return {
      ...this.item.price.total,
      amount: this.item.price.total.amount + this.priceChangesForItem,
    };
  }



  async getAirDetails() {
    try {
      const response = await OffersApi.getOffer(this.item.providerReferenceId);

      this.itemDetails = {
        ...response.data,
        pricePlusMarkup: this.item.price.total,
        travelPolicy: this.item.travelPolicy,
      };
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async getBasketItem() {
    try {
      const response = await BasketItemApi.getBasketItem(this.item.id);
      let details = response.data;

      if (details.rail && details.rail.recommendations) {
        let journeys: Journey[] = [];
        let proposals: Proposal[] = [];
        let price = 0;

        details.rail.recommendations.forEach(recommendation => {
          recommendation.journeys.forEach(journey => {
            journeys.push(journey);
          });

          recommendation.proposals.forEach(proposal => {
            proposals.push(proposal);
          });
        });

        proposals.forEach(proposal => {
          proposal.fares.forEach(fare => {
            fare.isSelected = true;
            price += (fare as any).price.amount;
          });
        });

        details.rail = {
          ...details.rail,
          id: details.rail.id,
          orderId: details.rail.orderId,
          offerProvider: details.rail.recommendations[0].offerProvider,
          supplier: details.rail.recommendations[0].supplier,
          recommendationId: details.recommendationId,
          journeys: journeys,
          proposals: proposals,
          price: {
            amount: price,
            currency: {
              code: 'EUR',
              symbol: '€'
            }
          },
          travelPolicy: details.travelPolicy,
        };
        this.itemDetails = details.rail;
      } else if (details.accommodation) {

        this.itemDetails = {
          ...details.accommodation,
          agencyFee: details.agencyFee,
          supplier: details.supplier,
          travelPolicy: this.itemMapped.travelPolicy,
        };

        this.accomodationItem = {
          ...this.itemMapped,
          agencyMarkup: details.accommodation.selectedOffer.agencyMarkup,
          totalPrice: this.item.price.total,
        };
      } else if (details.car) {
        this.itemDetails = {
          ...details.car.recommendation,
          totalPrice: this.item.price.total,
          supplier: details.supplier,
          travelPolicy: this.itemMapped.travelPolicy,
        };
      }
            
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async loadData() {
    if (this.item.type === 'Air') {
      await this.getAirDetails(); 
    } else {
      await this.getBasketItem();
    }
  }

  created() {
    this.loadData();
  }
}

