









































































import { Vue, Component, Watch } from 'vue-property-decorator';
import OfflineRequestsPopup from '@/modules/offline/OfflineRequestsPopup.vue';
import { router } from '@/router';
import AccountStore from '@/store/account.store';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import BasketItineraryEmailPopup from './BasketItineraryEmailPopup.vue';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';
import LayoutStore from '@/modules/layout/layout.store';
import BasketHeader from './BasketHeader.vue';
import axios from 'axios';
import settings from '@/settings';
import handleErrors from '@/core/errors/handle-errors.service';
import { translate } from '@/i18n';

@Component({
  components: {
    OfflineRequestsPopup,
    SearchTripTravellers,
    BasketHeader,
    BasketItineraryEmailPopup,
  },
})
export default class BasketIntro extends Vue {
  showOfflineRequestPopup: boolean = false;
  showItineraryEmailPopup: boolean = false;
  imageCalendarExport = '/assets/img/CalendarExportPrimary.png';
  imageLoader = '/assets/img/loader/1.gif';
  exportingToInternetCalendarFile: boolean = false;

  get basket() {
    return BasketStore.basket;
  }

  get initialising() {
    return BasketStore.initialising;
  }

  get loading() {
    return BasketStore.loading;
  }

  get isInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get processing() {
    return BasketStore.processing;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get status() {
    return BasketStore.status ? BasketStore.status.status : null;
  }

  get basketExpired() {
    return router.currentRoute.name === 'basket-expired';
  }

  get canSendTripItineraryEmail() {
    return AccountStore.HasPermission('CanSendTripItineraryEmail');
  }

  get canShowItineraryShareIcon() {
    return this.status !== 'Cancelled' && !this.basketExpired && this.canSendTripItineraryEmail && !this.loading && !this.isInProgress;
  }

  get canShowExportToInternetCalendarFile() {
    return this.status === 'Confirmed';
  }

  showItineraryEmail() {
    this.showItineraryEmailPopup = true;
  }

  closeItineraryEmailPopup() {
    this.showItineraryEmailPopup = false;
  }

  showTravellers() {
    router.push({
      name: 'basketTravellers',
      params: { id: this.$route.params.id }
    });
  }

  exportToInternetCalendarFile() {
    this.exportingToInternetCalendarFile = true;
    
    axios({
      method: 'POST',
      url: settings.apiTrip + `/api/internet-calendar/${this.$route.params.id}/export-to-file`,
      responseType: 'blob'
    })
    .then((response) => {
      let filename;
      if (response && response.headers && response.headers['content-disposition']) {
        let disposition = response.headers['content-disposition'];
        if (!!disposition && disposition.indexOf('filename=') !== -1) {
          const fileNameIndex = disposition.indexOf('filename=');

          disposition = disposition.substring(fileNameIndex, disposition.length);
          const firstSemicolonIndex = disposition.indexOf(';');
          filename = disposition.substring(0, firstSemicolonIndex).replace('filename=', '').trim();
        }
      }

      if (!filename) {
        filename = 'Trip.ics';
      }
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url as string;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      BasketStore.setErrors(handleErrors(error, true));
    }).finally(() => {
      this.exportingToInternetCalendarFile = false;
    });
  }

  tooltipExportToInternetCalendarFile() {
    return `<div>${translate('basket.export-to-internet-calendar-file-first-line')}</div><div>${translate('basket.export-to-internet-calendar-file-second-line')}</div>`;
  }
  
  @Watch('processing')
  onProcessingChange(value) {
    if (!value) {
      return;
    }
    this.$nextTick(() => {
      LayoutStore.scrollManager
        .scrollTo(this.$refs.bookingProgress as Element, false);
    });
  }
}
