















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AirSearchParamOnResults from './AirSearchParamOnResults.vue';

@Component({
  components: {
    AirSearchParamOnResults,
  }
})
export default class AirSearchShortParams extends Vue {
  searchId: string | null = null;

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get currentAirSearchIdFromApi() {
    return AirSearchStore.currentSearchIdFromApi;
  }

  showCriteria() {
    let params = {
      ...this.$route.params
    };

    if (this.$route.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
      params.searchId = this.currentAirSearchIdFromApi;
    }

    router.push({
      name: 'airDetails',
      params,
    });
  }
  showTravellers() {
    let params = {
      ...this.$route.params
    };

    if (this.$route.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
      params.searchId = this.currentAirSearchIdFromApi;
    }

    router.push({
      name: 'airTravellers',
      params,
    });
  }
}
