















































































import { Vue, Component } from 'vue-property-decorator';
import { ProfileApi } from '@/api/profile/profile.api';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import settings from '@/settings';
import { Debounce } from '@/core/decorators/debounce.decorator';

const errorCodesWithParams = [
  'TRAVELLERS_IMPORT_UNKNOWN_HEADER_COLUMNS',
  'TRAVELLERS_IMPORT_IMPORTER_PROFILE_NO_PERMISSION',
  'TRAVELLERS_IMPORT_SIZE_OF_UPLOADED_FILE_EXCEEDED',
  'TRAVELLERS_IMPORT_COMPANY_DOES_NOT_BELONG_TO_ROOT_COMPANY'
];

@Component({})
export default class ImportProfilesCsvFile extends Vue {
  errors: any[] = [];
  showLoader: boolean = false;
  file: Blob | null = null;
  isDragover = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  get profileId() {
    return router.currentRoute.params.id;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get maxSizeOfTravellersImportFile() {
    return settings.maxSizeOfTravellersImportFile;
  }

  bytesToMegabytes(bytes) {
    const megabytes = bytes / (1024 * 1024);
    if (Number.isInteger(megabytes)) {
      return megabytes;
    }
    if (megabytes % 1 === 0.1) {
      return megabytes.toFixed(1);
    }
    return megabytes.toFixed(2);
  }

  async setFile(event) {
    const [file] = event.target.files || event.dataTransfer.files;
    if (file) {
      this.file = file;
    }
  }

  dragover(event) {
    event.preventDefault();
    this.isDragover = true;
  }

  dragleave() {
    this.isDragover = false;
  }

  drop(event) {
    event.preventDefault();
    this.isDragover = false;
    this.setFile(event);
  }

  onCancelClick() {
    this.file = null;
  }

  getErrorMessageWithReplacedPlaceholders(message, params) {
    const processedParams = {
      ...params,
      maxSizeOfTravellersImportFile: params.maxSizeOfTravellersImportFile 
        ? (params.maxSizeOfTravellersImportFile / (1024 * 1024)).toFixed(2)
        : undefined
    };

    const result = message.replace(/{(\w+)}/g, (match, key) => {
      return key in processedParams ? processedParams[key] : match;
    });

    return result;
  }

  @Debounce({ delay: 3000 })
  async showImportProfilesList() {
    this.showLoader = false;
    this.file = null;
    EventHandler.$emit('show-import-profiles-list');
  }

  async onFileUpload() {
    if (!this.file) {
      return;
    }
    try {
      this.showLoader = true;
      let formData = new FormData();
      formData.append('file', this.file);
      await ProfileApi.importProfiles(this.currentCompany!.rootCompanyId, formData);
      this.errors = [];
      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: '',
        message: translate('import-profiles.uploading-started'),
        hideIcon: true
      });
      await this.showImportProfilesList();
    } catch (error) {
      this.showLoader = false;
      this.file = null;
      if (errorCodesWithParams.includes(error.response.data.error.code)
        && error.response.data.error.details
        && error.response.data.error.details[0]
        && error.response.data.error.details[0].params
      ) {
        this.errors = [{ message: this.getErrorMessageWithReplacedPlaceholders(error.response.data.error.message, error.response.data.error.details[0].params) }];
      } else {
        this.errors = [{ message: error.response.data.error.message }];
      }
    }
  }
}
