import CustomMessages from '@/modules/global-settings/custom-messages/CustomMessages.vue';
import CustomMessagesList from './custom-messages-list.route';
import CustomMessagesMessage from './custom-messages-message.route';

export default {
  path: 'custom-messages',
  component: CustomMessages,
  displayName: 'global-settings.custom-messages',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanAccessGlobalSettings',
    icon: 'chat',
    breadCrumb: `Custom messages configurations' list`,
    parentName: 'custom-messages',
  },
  children: [
    CustomMessagesList,
    CustomMessagesMessage,
  ],
};