























































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';

import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { translate } from '@/i18n';
import NegoFaresStore from '@/modules/settings/nego-fare/nego-fares.store';
import { TrainNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import { CorporateCodeOwnerEnum } from '@/api/air-engine/nego-fares-configuration.model';
import NegoFaresTrainStore from '@/modules/settings/nego-fare/nego-fares-train.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({
  components: {
  }
})
export default class NegoFareRailCodeForm extends Vue {
  $v;
  validated: boolean = false;
  formMapped: boolean = false;

  get providerOptions() { 
    return [
    {
      label: SupplierName(TrainNegoFareConfigurationSupplierEnum.Trenitalia),
      value: TrainNegoFareConfigurationSupplierEnum.Trenitalia,
      $isDisabled: false,
    },
    {
      label: SupplierName(TrainNegoFareConfigurationSupplierEnum.Sncf),
      value: TrainNegoFareConfigurationSupplierEnum.Sncf,
      $isDisabled: false,
    },
    {
      label: SupplierName(TrainNegoFareConfigurationSupplierEnum.Ouigo),
      value: TrainNegoFareConfigurationSupplierEnum.Ouigo,
      $isDisabled: false,
    },
    {
      label: SupplierName(TrainNegoFareConfigurationSupplierEnum.Ntv),
      value: TrainNegoFareConfigurationSupplierEnum.Ntv,
      $isDisabled: !this.ntvConfigurationAvailable,
    },
    {
      label: SupplierName(TrainNegoFareConfigurationSupplierEnum.Benerail),
      value: TrainNegoFareConfigurationSupplierEnum.Benerail,
      $isDisabled: false,
    }
    ];
  }

  codeOwnerOptions = [
    {
      label: translate('settings-nego-fare.uppercase-tmc-type'),
      value:  CorporateCodeOwnerEnum.Tmc,
    },
    {
      label: translate('settings-nego-fare.client-type'),
      value: CorporateCodeOwnerEnum.Client,
    },
  ];

  Form: any = {
    supplier: null,
    code: '',
    providerCode: null,
    description: '',
    codeOwner: null,
  };

  @Validation()
  validationObject() {
    return {
      Form: {
        supplier: {
          required,
        },
        code: {
          required,
          maxLength: maxLength(40),
        },
        providerCode: {
          required: requiredIf(() => {
            return (this.Form.supplier === TrainNegoFareConfigurationSupplierEnum.Sncf || this.Form.supplier === TrainNegoFareConfigurationSupplierEnum.Ouigo || this.Form.supplier === TrainNegoFareConfigurationSupplierEnum.Benerail);
          }),
        },
        codeOwner: {
          required,
        },
        description: {
          maxLength: maxLength(246),
        }
      }
    };
  }

  get supplier() {
    return this.providerOptions.find(provider => provider.value === this.Form.supplier) || null;
  }

  set supplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }
    this.Form.supplier = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return NegoFaresStore.configurationName;
  }

  get ntvConfigurationAvailable() {
    const ntvEntry = NegoFaresTrainStore.corporateCodesList.find((item: any) => { return item.supplier === 'Ntv'; });
    return ntvEntry ? false : true;
  }

  get service() {
    return this.$route.params.service;
  }

  get selectedCode() {
    return NegoFaresTrainStore.currentTrainCode;
  }

  get codeExists() {
    return this.selectedCode && Object.keys(this.selectedCode).length > 0;
  }

  getProviderCodeOptions(supplier) {
    return supplier === 'Benerail' ? [{
      label: 'ICE',
      value: 'urn:itti:supplier:ICE',
    }, {
      label: 'THALYS',
      value: 'urn:itti:supplier:THA',
    }, {
      label: 'EUROSTAR',
      value: 'urn:itti:supplier:EUR',
    }
    ] : [{
      label: 'SNCF',
      value: 'SNCF',
    }, {
      label: 'THALYS',
      value: 'THALYS',
    }, {
      label: 'EUROSTAR',
      value: 'EUROSTAR',
    }];
  }

  getAccountCodeLabel() {
    if (this.Form.supplier === 'Trenitalia') {
      return translate('settings-nego-fare.account-code-pit');
    } else if (this.Form.supplier === 'Ntv') {
      return translate('settings-nego-fare.account-code-idpartner');
    } else {
      return translate('settings-nego-fare.contract-code');
    }
  }

  goToConfiguration() {
    NegoFaresStore.setIsFromManage(true);
    router.push({
      name: 'nego-fare-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      const providerCodeOptions = this.getProviderCodeOptions(data.supplier);
      this.Form = {
        supplier: data.supplier,
        codeOwner: this.codeOwnerOptions.find(opt => {
          return opt.value === data.codeOwner;
        }),
        description: data.description,
        code: data.code,
        providerCode: data.supplier === 'Trenitalia' ?
          'PIT' : providerCodeOptions.find(opt => opt.value === data.providerCode),
      } as any;
    } else {
      this.Form = {
        supplier: null,
        code: '',
        description: '',
        codeOwner: null,
        providerCode: null,
      } as any;
    }
    this.formMapped = true;
  }

  createCode() {
    const providerCodeValue = {
      Trenitalia: () => {
        return 'PIT';
      },
      Sncf: () => {
        return this.Form.providerCode.value;
      },
      Benerail: () => {
        return this.Form.providerCode.value;
      },
      Ouigo: () => {
        return this.Form.providerCode.value;
      },
      Ntv: () => {
        return null;
      }
    };
    let item = {
      code: this.Form.code.trim(),
      codeOwner: this.Form.codeOwner.value,
      description: this.Form.description ? this.Form.description.trim() : this.Form.description,
      providerCode: providerCodeValue[this.Form.supplier]() || null,
      supplier: this.Form.supplier,
      status: NegoFaresTrainStore.draftNewStatus,
      id: _.uniqueId(),
    };

    if (this.codeExists) {
      NegoFaresTrainStore.editCorporateCode(item);
    } else {
      NegoFaresTrainStore.addCorporateCode(item);
    }
  }

  confirmAndAddNext() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.createCode();
      NegoFaresTrainStore.selectCorporateCode({});
      this.validated = false;
      this.$v.Form.$reset();
      this.mapFormData();
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.createCode();
      this.goToConfiguration();
    }
  }

  @Watch('selectedCode', { deep: true, immediate: true })
  onCodeChange(code) {
    if (!code) {
      router.go(-1);
    }
  }

  onSupplierChange(supplier) {
    if (this.formMapped) {
      this.Form.codeType = null;
      this.Form.airlineId = null;
      this.Form.providerCode = null;
    }
  }

  created() {
    if (this.selectedCode) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedCode)));
    } else {
      this.mapFormData();
    }
  }
}
