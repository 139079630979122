





























import { Vue, Component, Watch } from 'vue-property-decorator';

import accountStore from '@/store/account.store';
import profileStore from '@/modules/profile/profile.store';
import { ProfileResponseActionStatusType } from '@/api/profile/profile.model';

import General from '@/modules/profile/personal/PersonalInformation.vue';
import Roles from '@/modules/profile/roles/Roles.vue';
import Contacts from '@/modules/profile/contacts/Contacts.vue';
import Documents from '@/modules/profile/documents/Documents.vue';
import PaymentCards from '@/modules/profile/payment-cards/PaymentCards.vue';
import LoyaltyPrograms from '@/modules/profile/loyalty-programs/LoyaltyPrograms.vue';
import ApprovalWorkflow from '@/modules/profile/approval-workflow/ApprovalWorkflow.vue';
import Preferences from '@/modules/profile/preferences/Preferences.vue';
import ProfileCustomFields from '@/modules/profile/custom-fields/ProfileCustomFields.vue';
import ProfileArrangerCustomFields from '@/modules/profile/custom-fields/ProfileArrangerCustomFields.vue';
import ManagerAssignment from '@/modules/profile/manager-assignment/ManagerAssignment.vue';
import ProfileSyncInformation from './ProfileSyncInformation.vue';
import EventBus from '@/services/event-handler';

const syncInterval = 30000;

@Component({
  components: {
    General,
    Roles,
    Contacts,
    Documents,
    PaymentCards,
    LoyaltyPrograms,
    ApprovalWorkflow,
    Preferences,
    ProfileCustomFields,
    ProfileArrangerCustomFields,
    ManagerAssignment,
    ProfileSyncInformation,
  },
})
export default class Profile extends Vue {
  loading: boolean = true;
  errors: any[] = [];
  personalLoaded: boolean = false;
  syncDataIntervalID: any = null;
  localSyncInfo: any = null;



  get user() {
    return accountStore.user;
  }

  get serverErrors() {
    return [...profileStore.serverErrors, ...profileStore.profileSyncErrors];
  }

  get userData() {
    return profileStore.userData;
  }

  get currentProfile() {
    return profileStore.userProfile;
  }

  get profileResponse() {
    return accountStore.createProfileResponse;
  }

  get shouldShowProfileResponse() {
    return this.profileResponse ? this.$route.params.id === this.profileResponse.id : false;
  }

  get failedProfileCreateActions() {
    return this.profileResponse ? Object.keys(this.profileResponse).map(e => {
      if (this.profileResponse[e] === ProfileResponseActionStatusType.Errors) {
        return e;
      }
    }).filter(Boolean) : [];
  }

  get profileSyncStatus() {
    return profileStore.profileSyncInfo;
  }



  async onProfileDataSaved() {
    await this.loadProfileSyncStatusNow();
    clearInterval(this.syncDataIntervalID);
    this.syncDataIntervalID = setInterval(this.loadProfileSyncStatusNow, syncInterval);
  }

  async loadProfileSyncStatusNow() {
    await profileStore.loadUserProfileSyncStatus(this.$route.params.id);
  }



  @Watch('$route', { immediate: true, deep: true })
  onRouteChange() {
    this.init();
  }

  async init() {
    this.localSyncInfo = null;
    this.loading = true;
    await Promise.all([
      profileStore.loadUserData(this.$route.params.id),
      this.loadProfileSyncStatusNow(),
    ]);
    clearInterval(this.syncDataIntervalID);
    this.syncDataIntervalID = setInterval(this.loadProfileSyncStatusNow, syncInterval);
    if (this.userData) {
      profileStore.setUserProfile(this.userData);
    }
    this.personalLoaded = true;
    this.loading = false;
  }

  @Watch('profileSyncStatus')
  onProfileSyncStatusChange(value) {
    if (!value) {
      return;
    }
    this.localSyncInfo = value;
  }



  mounted() {
    EventBus.$on('profile-data-saved', this.onProfileDataSaved);
  }

  beforeDestroy() {
    EventBus.$off('profile-data-saved', this.onProfileDataSaved);
    clearInterval(this.syncDataIntervalID);
  }
}
