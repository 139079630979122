
















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import {
  ProfileDocument,
  CreateDrivingLicenses,
} from '@/api/profile/documents.model';
import AccountStore from '@/store/account.store';
import DictionaryStore from '@/store/dictionary.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import moment from 'moment';
import _ from 'lodash';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import EventBus from '@/services/event-handler';


@Component({})
export default class DrivingLicense extends Vue {
  @Prop({ default: false }) documentType: any;
  @Prop({ default: null }) document: any;
  @Prop({ default: null }) profileId: any;
  @Prop({}) isGuestMode!: boolean;
  @Prop({ default: false }) withoutSaving!: boolean;

  Form: ProfileDocument | null = null;
  serverErrors: any[] = [];
  errMessage: string = '';
  wasSubmitted: boolean = false;
  formPending: boolean = false;
  $v;

  @Validation()
  validationObject() {
    return {
      Form: {
        number: {
          required,
          maxLength: maxLength(14),
        },
        issueDate: {
          required,
        },
        issueCountry: {
          required,
        },
        firstName: {
          required,
          maxLength: maxLength(100),
          isNameValid,
        },
        middleName: {
          isNameValid: (value) => (value ? isNameValid(value) : true),
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          isNameValid,
          maxLength: maxLength(255),
        },
      },
    };
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isEditing() {
    return !!this.document;
  }

  async created() {
    if (this.isEditing) {
      this.Form = new ProfileDocument();
      this.Form.type = this.documentType.value;
      this.Form.number = this.document.number;
      this.Form.firstName = this.document.firstName;
      this.Form.middleName = this.document.middleName;
      this.Form.lastName = this.document.lastName;
      this.Form.issueDate = new Date(this.document.issueDate);
      this.Form.expireDate = this.document.expireDate ? moment(this.document.expireDate).toDate() : '';
      this.Form.issueCountryCode = this.document.issueCountryCode;
      this.Form.issueCountry = this.allCountries!.find(country => { return country.code === this.document.issueCountryCode; })!;
      this.Form.id = this.document.id;
      this.Form.tempId = this.document.tempId;
    } else {
      const currentUser = AccountStore.CurrentUser;
      this.Form = new ProfileDocument();

      if (currentUser && currentUser.originalProfileId === this.profileId && !SearchStore.skipTravellers) {
        this.Form.firstName = currentUser.profile.firstName;
        this.Form.middleName = currentUser.profile.middleName;
        this.Form.lastName = currentUser.profile.lastName;
      }
    }
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.backButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  validDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  validDateIssueDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();
    if (date && dayOne.diff(dayTwo, 'days', true) > 0) {
      return true;
    }

    return false;
  }

  maxIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  notifyParent(reloadDocuments, document) {
    this.$emit('document-changed', reloadDocuments, document);
  }

  async saveDetails() {
    this.serverErrors = [];
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error || this.validDateIssueDate(this.Form!.issueDate) || this.validDate(this.Form!.expireDate) || this.maxIssueDate(this.Form!.issueDate, this.Form!.expireDate)) {
      return;
    }

    let request: CreateDrivingLicenses = {
      type: this.documentType.value,
      number: this.Form!.number,
      issueDate: moment(this.Form!.issueDate).format('YYYY-MM-DD'),
      expireDate: this.Form!.expireDate ? moment(this.Form!.expireDate).format('YYYY-MM-DD') : '',
      issueCountryCode: this.Form!.issueCountry!.code,
      firstName: this.Form!.firstName,
      middleName: this.Form!.middleName,
      lastName: this.Form!.lastName,
    };

    if (this.withoutSaving) {
      this.notifyParent(true, request);
      return;
    }

    if (this.isEditing) {

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document = {
            ...request,
            tempId: this.Form!.tempId,
          };
          BasketStore.updateGuestBasketTravellerDocument({ id: this.profileId, document: document });
          this.notifyParent(false, null);
        } else {
          await DocumentsApi.updateDocumentsDrivingLicenses(this.profileId, this.document.id, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    } else {
      this.wasSubmitted = true;

      try {
        this.formPending = true;
        if (this.isGuestMode) {
          let document: any = request;
          document.tempId = _.uniqueId();
          BasketStore.setGuestBasketTravellerDocument({ id: this.profileId, document: document });
          this.notifyParent(false, null);
        } else {
          await DocumentsApi.createDocumentsDrivingLicenses(this.profileId, request);
          this.notifyParent(true, null);
        }
      } catch (error) {
        this.serverErrors = $handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    }
  }

  onTypeChanged() {
    ((this.$refs.firstInput as Vue).$refs.input as HTMLElement).focus();
  }

  mounted() {
    EventBus.$on('document-type-changed', this.onTypeChanged);
  }

  beforeDestroy() {
    EventBus.$off('document-type-changed', this.onTypeChanged);
  }
}
