import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  Country,
  City,
  LocationModel,
  LanguageCode,
  AirLine,
  CityCountryModel,
  HotelLocationModel,
  CarExtra,
  CitiesNamesModel,
  CountryContinentModel,
  GeoPointDescription,
  GeoAreaModel,
  RegionModel,
} from './dictionary.model';

class DictionaryApiClass {
  public getCountries(languageCode: LanguageCode): Promise<AxiosResponse<Array<Country>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/countries?languageCode={languageCode}',
      method: RequestMethod.GET
    }, { languageCode }, {});
  }

  public getCountry(code: string, languageCode: LanguageCode): Promise<AxiosResponse<Country>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/countries/{code}?languageCode={languageCode}',
      method: RequestMethod.GET
    }, { code, languageCode }, {});
  }

  public getCity(code: string, languageCode?: LanguageCode): Promise<AxiosResponse<City>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/cities/{code}?languageCode={languageCode}',
      method: RequestMethod.GET
    }, { code, languageCode }, {});
  }

  public getAirlines(): Promise<AxiosResponse<Array<AirLine>>> {
    return http.execute({
      path: settings.apiDictionary + '/api/airlines',
      method: RequestMethod.GET
    }, {}, {});
  }

  public findLocation(query: string, languageCode: LanguageCode): Promise<AxiosResponse<Array<LocationModel>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/v2/autocomplete/airports?query={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, {query, languageCode}, {});
  }

  public findCityCountry(query: string, languageCode?: LanguageCode): Promise<AxiosResponse<Array<CityCountryModel>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/countries-cities?searchPhrase={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, {query, languageCode}, {});
  }

  public findHotelToLocation(query: string, languageCode: LanguageCode): Promise<AxiosResponse<Array<HotelLocationModel>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/locations/search?query={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, {query, languageCode}, {});
  }

  public getCitiesNames(params: any[], languageCode: LanguageCode): Promise<AxiosResponse<Array<CitiesNamesModel>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/cities/get-names?languageCode={languageCode}',
      method: RequestMethod.POST,
    }, {languageCode}, params, true);
  }

  public getContinentsNames(params: string[]): Promise<AxiosResponse<string[]>> {
    return http.execute({
      path: settings.apiDictionary + '/api/locations/continents',
      method: RequestMethod.POST,
    }, {}, { params });
  }

  public getCountriesContinentsNames(query: string, languageCode?: LanguageCode): Promise<AxiosResponse<CountryContinentModel[]>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/countries-continents?searchPhrase={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }

  public getCarExtras(languageCode: string): Promise<AxiosResponse<Array<CarExtra>>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/car-extras?languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { languageCode }, {});
  }

  public findGeoAreas(query: string, languageCode?: LanguageCode): Promise<AxiosResponse<GeoAreaModel[]>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/geo-areas?searchPhrase={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }

  public findRegions(query: string, languageCode?: LanguageCode): Promise<AxiosResponse<RegionModel[]>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/regions?phrase={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }

  public getErrorCodeTranslation(errorCode: string, languageCode: string) {
    return http.execute({
      path: settings.apiDictionary + '/api/error-code-translations/{errorCode}?languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { errorCode, languageCode }, {});
  }

  public getTrainTicketsClasses(): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/train-tickets-classes/display',
      method: RequestMethod.GET,
    }, {  }, {});
  }

  public getRailwayStations(query: string, languageCode?: LanguageCode): Promise<AxiosResponse<GeoPointDescription[]>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiDictionary + '/api/autocomplete/railway-stations?searchPhrase={query}&languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { query, languageCode }, {});
  }
}

export const DictionaryApi: DictionaryApiClass = new DictionaryApiClass();
