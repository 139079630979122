export interface FareMappingConfiguration {
  supplier: string;
  fareMapping: FareMapping[];
}

export class FareMappingConfigurations {
  name: string = '';
  rootCompanyId: string = '';
  fareMappingConfigurations: FareMappingConfiguration[] = [];

  constructor(config?: any) {
    if (config) {
      if (config.rootCompanyId !== undefined) {
        this.rootCompanyId = config.rootCompanyId;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
      if (config.fareMappingConfigurations !== undefined) {
        this.fareMappingConfigurations = config.fareMappingConfigurations;
      }
    }
  }
}

export class FareMappingConfigurationModel {
  id: string = '';
  rootCompanyId: string = '';
  configurationType: string = '';
  name: string = '';

  constructor(config?: any) {
    if (config) {
      if (config.id !== undefined) {
        this.id = config.id;
      }
      if (config.rootCompanyId !== undefined) {
        this.rootCompanyId = config.rootCompanyId;
      }
      if (config.configurationType !== undefined) {
        this.configurationType = config.configurationType;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
    }
  }
}

export interface Airlines {
  code: string;
  businessName: string;
}

export class FareMapping {
  id?: string = '';
  airlines: Airlines[] | [] = [];
  fareBasisCode: string = '';
  label: string = '';

  constructor(config?: any) {
    if (config) {
      if (config.id !== undefined) {
        this.id = config.id;
      }
      if (config.airlines !== undefined) {
        this.airlines = config.airlines;
      }
      if (config.fareBasisCode !== undefined) {
        this.fareBasisCode = config.fareBasisCode;
      }
      if (config.label !== undefined) {
        this.label = config.label;
      }
    }
  }
}

export enum FareMappingConfigurationSupplierEnum {
  Unknown = 'Unknown',
  Fake = 'Fake',
  Sabre = 'Sabre',
  TravelFusion = 'TravelFusion',
  AirFranceKlm = 'AirFranceKlm',
  Lufthansa = 'Lufthansa',
  Amadeus = 'Amadeus',
  EasyJet = 'EasyJet',
  BritishAirways = 'BritishAirways',
  AmericanAirlines = 'AmericanAirlines',
  NDCx = 'NDCx',
  Emirates = 'Emirates',
}

export enum AirFareMappingConfigurationSupplierEnum {
  Unknown = 'Unknown',
  Sabre = 'Sabre',
  Lufthansa = 'Lufthansa',
  AirFranceKlm = 'AirFranceKlm',
  Amadeus = 'Amadeus',
  TravelFusion = 'TravelFusion',
  AmericanAirlines = 'AmericanAirlines',
  BritishAirways = 'BritishAirways',
  NDCx = 'NDCx',
  Emirates = 'Emirates',
}

export interface FareMappingConfigurationModel {
  succeeded: boolean;
  error: any;
  supplier: FareMappingConfigurationSupplierEnum;
  fareMapping: FareMapping[];
}

export interface UpdateFareMappingConfigurationSingle {
  supplier: FareMappingConfigurationSupplierEnum;
  fareMapping: FareMapping[] | null;
}

export interface UpdateFareMappingConfigurationParams {
  name: string;
  fareMappingConfigurations: UpdateFareMappingConfigurationSingle[] | null;
}

export interface UpdateFareMappingConfigurationMessage {
  configurationId: string;
  params: UpdateFareMappingConfigurationParams;
}

export interface UpdateFareMappingConfigurationResponse {
  supplier: FareMappingConfigurationSupplierEnum;
  succeeded: boolean;
  error: any;
}
