  export class GetProfileSpecificCustomFieldsResult {
    getProfileCustomFieldValues: any[] = [];
    accessLevel: ProfileCustomFieldsAccessType = ProfileCustomFieldsAccessType.NoAccess;
  
    public constructor(params?: any) {
      if (!params) {
        return;
      }
      if (params.getProfileCustomFieldValues !== undefined) {
        this.getProfileCustomFieldValues = params.getProfileCustomFieldValues;
      }
      if (params.accessLevel !== undefined) {
        this.accessLevel = params.accessLevel;
      }
    }
  }

  export enum ProfileCustomFieldsAccessType {
    NoAccess = 'NoAccess',
    CanView = 'CanView',
    CanEdit = 'CanEdit',
  }

  export enum UpdateCustomFieldMethodType {
    Default = 'Default',
    ApproverCustomFields = 'ApproverCustomFields',
    ArrangerCustomFields = 'ArrangerCustomFields'
  }
  