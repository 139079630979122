import TravelPolicyFlightManageRule from '@/modules/settings/travel-policy/configurations/configuration/TravelPolicyFlightManageRule.vue';

export default {
  path: ':configurationId?/flight-rule:readOnly?',
  name: 'flight-rule',
  component: TravelPolicyFlightManageRule,
  displayName: 'Flight Rule',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadTravelPolicy',
    parentName: 'travel-policy-configurations',
    depthCategory: 'travel-policy',
    waitForDicts: true,
    depth: 3,
  }
};