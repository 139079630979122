import AccountStore from '@/store/account.store';

export default (to, from, next) => {
  // only applies to approvals section
  // and only when entering from outside of approvals section
  // and only when there are no query params
  if (
    !to.matched.length ||
    'approvals' !== to.matched[0].name ||
    (0 !== Object.keys(to.query).length)
  ) {
    next();
    return;
  }

  if (!AccountStore.HasPermission('CanValidateTrips')) {
    next({
      ...to,
      query: {
        basketStatuses: 'PendingApproval',
      },
    });

    return;
  }

  next({
    ...to,
    query: {
      basketStatuses: 'PendingApproval',
      approvers: AccountStore.CurrentUser!.profile.id,
    },
  });
};
