


















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';

@Component({
})
export default class BasketAirLufthansaRefundView extends Vue {
  @Prop() offer!: any;
  @Prop() travellers!: any;
  @Prop() refundDetails!: any;

  get travellersMapped() {
    return this.travellers
      .map(traveller => {
        if (!(this.refundDetails as any).passengerPriceDifferences) {
          return traveller;
        }
        const priceInfo = (this.refundDetails as any).passengerPriceDifferences
          .find(p => p.profileId === traveller.id);

        for (let key in priceInfo) {
          if (typeof priceInfo[key] === 'object' && priceInfo[key] !== null) {
            priceInfo[key].isToggled = false;
          }
        }

        return {
          ...traveller,
          priceInfo,
        };
      })
      .filter(traveller => {
        return traveller.priceInfo != null;
      });
  }

  userFullName(user) {
    return userFullName(user);
  }

  clickTax(key, index) {
    this.travellersMapped[index].priceInfo[key].isToggled = !this.travellersMapped[index].priceInfo[key].isToggled;
    this.$forceUpdate();
  }
}

