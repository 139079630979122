



































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import SettingsStore from '@/modules/settings/settings.store';
import { ServerError } from '@/api/home/home.model';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { TravelPolicyApi } from '@/api/travel-policy/travel-policy.api';
import TravelPolicyFlightStore from '@/modules/settings/travel-policy/travel-policy-flight.store';
import TravelPolicyAccomodationStore from '@/modules/settings/travel-policy/travel-policy-accomodation.store';
import TravelPolicyCarStore from '@/modules/settings/travel-policy/travel-policy-car.store';
import TravelPolicyRailStore from '@/modules/settings/travel-policy/travel-policy-rail.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import TravelPolicyFlight from './TravelPolicyFlight.vue';
import TravelPolicyCar from './TravelPolicyCar.vue';
import TravelPolicyAccomodation from './TravelPolicyAccomodation.vue';
import TravelPolicyRail from './TravelPolicyRail.vue';
import DictionaryStore from '@/store/dictionary.store';
import EventBus from '@/services/event-handler';
import {
  AirportLocationTypes,
  GeographicalAreaTypes,
  RailGeoPointType
} from '@/api/dictionary/dictionary.model';
import { 
  SpeedTrainType,
  RailProviderType 
} from '@/api/travel-policy/travel-policy.model';
import { translate } from '@/i18n';

@Component({
  components: {
    TravelPolicyFlight,
    TravelPolicyCar,
    TravelPolicyAccomodation,
    TravelPolicyRail,
  }
})
export default class TravelPolicyConfiguration extends Vue {
  isReadOnly: boolean = false;
  configurationData: ConfigurationRow | null = null;
  formHasError: boolean = false;
  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
    },
    {
      value: 'Accomodation',
      name: translate('settings-travel-policy.accomodation'),
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
    },
    {
      value: 'Train',
      name: translate('settings-travel-policy.rail'),
    }
  ];

  serverErrors: ServerError[] = [];
  $v;

  @Validation()
  validationObject() {
    return {
      configurationName: {
        required,
        maxLength: maxLength(64)
      }
    };
  }

  get loadErrors() {
    return TravelPolicyStore.getErrors;
  }

  get loadingList() {
    return TravelPolicyStore.loading;
  }

  get isFromManage() {
    return TravelPolicyStore.isFromManage;
  }

  get travelPolicyFlight() {
    return TravelPolicyFlightStore.travelPolicy;
  }

  get travelPolicyAccomodation() {
    return TravelPolicyAccomodationStore.travelPolicy;
  }

  get travelPolicyCar() {
    return TravelPolicyCarStore.travelPolicy;
  }

  get travelPolicyRail() {
    return TravelPolicyRailStore.travelPolicy;
  }

  get configurationName() {
    return TravelPolicyStore.currentConfigName;
  }

  set configurationName(value) {
    TravelPolicyStore.setCurrentConfigName(value);
  }

  get isPostSalesEnabledInNonCompliantBooking() {
    return TravelPolicyStore.isPostSalesEnabledInNonCompliantBooking;
  }

  set isPostSalesEnabledInNonCompliantBooking(value) {
    TravelPolicyStore.setIsPostSalesEnabledInNonCompliantBooking(value);
  }

  get currentConfigName() {
    return TravelPolicyStore.currentConfigName;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get links() {
    return this.navLinks;
  }

  get currentSelectedItem() {
    return TravelPolicyStore.currentSelectedItem;
  }
  
  get canReadNotWrite() {
    return this.$hasAccess('ReadTravelPolicy') && !this.$hasAccess('WriteTravelPolicy');
  }

  get canShowCustomError() {
    return TravelPolicyStore.canShowCustomError;
  }

  rowClicked(path) {
    if (path) {
      router.push({
        name: path,
        params: {
          configurationId: this.currentConfigurationId,
        },
      });
    }
  }

  linkClicked(e) {
    e.preventDefault();
  }

  changeConfiguration(name) {
    TravelPolicyStore.setCurrentSelectedItem(name);
  }

  returnToConfigurations() {
    router.push({
      name: 'travel-policy-configurations'
    });
  }

  prepareRailRoutings(routings: any[] | null) {
    if (!routings) {
      return;
    }
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from : {
          type: RailGeoPointType[e.from.type],
          id: e.from.id,
        },
        to: {
          type: RailGeoPointType[e.to.type],
          id: e.to.id,
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }

  prepareAirRoutings(routings: any[] | null) {
    if (!routings) {
      return;
    }
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from: {
          code: e.from.type === 'CityAirport' ? (e.from.airportCode || e.from.code) : (e.from.cityCode || e.from.code),
          type: AirportLocationTypes[e.from.type]
        },
        to: {
          code: e.to.type === 'CityAirport' ? (e.to.airportCode || e.to.code) : (e.to.cityCode || e.to.code),
          type: AirportLocationTypes[e.to.type]
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }

  prepareGeographicalAreas(areas: any[] | null) {
    if (!areas) {
      return;
    }
    const areasMapped = areas.map(e => {
      return (e.from && e.to) ?
      { 
        From: {
          code: e.from.code,
          type: GeographicalAreaTypes[e.from.type]
        },
        to: {
          code: e.to.code,
          type: GeographicalAreaTypes[e.to.type]
        }
      } : null;
    });
    const areasFiltered = areasMapped.filter(Boolean);
    return areasFiltered.length ? areasFiltered : null;
  }

  fligthTpRequest() {
    let rules = [] as  any;
    this.travelPolicyFlight.flightRules.forEach(element => {
      let airlines: string[] = [];

      if (element.ruleAirlines) {
        element.ruleAirlines.forEach((air: any) => {
          airlines.push(air.code);
        });
      } else {
        airlines = element.ruleAirlines;
      }

      let rule = {
        rulePriority: element.rulePriority,
        maxPrice: element.maxPrice,
        priceCurrency: element.maxPrice ? element.priceCurrency : null,
        ruleLegTime: element.ruleLegTime === 'NaN' ? null : element.ruleLegTime,
        ruleLegTimeOperator: element.ruleLegTimeOperator === '' ? null : element.ruleLegTimeOperator,
        ruleAirLines: airlines,
        ruleCabinClasses: element.ruleCabinClasses,
        distributionChannelTypes: element.distributionChannelTypes,
        tripTypes: element.tripTypes,
        airlineTypes: element.airlineTypes,
        routings: this.prepareAirRoutings(element.routings),
        geographicalAreas: this.prepareGeographicalAreas(element.geographicalAreas),
        travellerCategories: element.travellerCategories,
      };

      if (this.currentConfigurationId) {
        let id;
        if (element.status) {
          id = element.status === TravelPolicyFlightStore.draftEditStatus ? element.id : this.emptyGuid;
        } else {
          id = element.id;
        }

        rules.push({id: id, ...rule});
      } else {
        rules.push(rule);
      }
    });

    return {
      configurationName: this.configurationName,
      rootCompanyId: this.currentCompany!.rootCompanyId,
      flightRules: rules,
      hideNonCompliantRates: this.travelPolicyFlight.hideNonCompliantRates,
      isPostSalesEnabledInNonCompliantBooking: this.travelPolicyFlight.isPostSalesEnabledInNonCompliantBooking,
    };
  }

  accomodationTpRequest() {
    let rules = [] as  any;
    this.travelPolicyAccomodation.accomodationRules.forEach(element => {
      let mealTypes: string[] = [];
      let categories: string[] = [];

      if (element.categories && element.categories.length) {
        element.categories.forEach((c: any) => {
          categories.push(c.value ? c.value : c);
        });
      } else {
        categories = [];
      }
      if (element.mealTypes && element.mealTypes.length) {
        element.mealTypes.forEach((c: any) => {
          mealTypes.push(c.value ? c.value : c);
        });
      } else {
        mealTypes = [];
      }

      let id;
      if (element.status) {
        id = element.status === TravelPolicyAccomodationStore.draftEditStatus ? element.id : this.emptyGuid;
      } else {
        id = element.id;
      }

      let rule = {
        id: id,
        rulePriority: element.rulePriority,
        priceAmount: element.priceAmount,
        priceCurrency: element.priceAmount ? element.priceCurrency : null,
        isRefundable: element.isRefundable,
        mealTypes: mealTypes,
        categories: categories,
        numberOfPeopleInRoom: element.numberOfPeopleInRoom,
        geoAreas: element.geoAreas.length ? element.geoAreas : [],
        providers: element.providers,
        travellerCategories: element.travellerCategories,
      };

      rules.push(rule);
    });

    return {
      configurationName: this.configurationName,
      rootCompanyId: this.currentCompany!.rootCompanyId,
      accomodationRules: rules,
      hideNonCompliantRates: this.travelPolicyAccomodation.hideNonCompliantRates,
      isPostSalesEnabledInNonCompliantBooking: this.travelPolicyAccomodation.isPostSalesEnabledInNonCompliantBooking,
    };
  }

  carTpRequest() {
    let rules = [] as  any;
    this.travelPolicyCar.carRules.forEach(element => {
      let rentalCompanies: string[] = [];
      let geographicalAreas: any[] = [];

      if (element.rentalCompanies && element.rentalCompanies.length) {
        element.rentalCompanies.forEach((company: any) => {
          rentalCompanies.push(company.code);
        });
      } else {
        rentalCompanies = [];
      }

      if (element.geographicalAreas && element.geographicalAreas.length) {
        element.geographicalAreas.forEach((area: any) => {
          geographicalAreas.push({
            type: area.type,
            code: area.code,
          });
        });
      } else {
        geographicalAreas = [];
      }

      let id;
      if (element.status) {
        id = element.status === TravelPolicyCarStore.draftEditStatus ? element.id : this.emptyGuid;
      } else {
        id = element.id;
      }

      let rule = {
        id: id,
        rulePriority: element.rulePriority,
        rentalCompanies: rentalCompanies,
        carCategories: element.carCategories,
        geographicalAreas: geographicalAreas,
        travellerCategories: element.travellerCategories,
      };

      rules.push(rule);
    });

    return {
      configurationName: this.configurationName,
      rootCompanyId: this.currentCompany!.rootCompanyId,
      carRules: rules,
      hideNonCompliantRates: this.travelPolicyCar.hideNonCompliantRates,
      isPostSalesEnabledInNonCompliantBooking: this.travelPolicyCar.isPostSalesEnabledInNonCompliantBooking,
    };
  }

  railTpRequest() {
    let railRules = [] as any;
    this.travelPolicyRail.railRules.forEach(element => {
      let id;
      if (element.status) {
        id = element.status === TravelPolicyRailStore.draftEditStatus ? element.id : this.emptyGuid;
      } else {
        id = element.id;
      }

      let rule = {
        id: id,
        rulePriority: element.rulePriority,
        maxPrice: element.maxPrice,
        maxPriceCurrency: element.maxPrice ? element.maxPriceCurrency : null,
        ruleLegTime: element.ruleLegTime === 'NaN' ? null : element.ruleLegTime,
        ruleLegTimeOperator: element.ruleLegTimeOperator === '' ? null : element.ruleLegTimeOperator,
        trainTypes: element.trainTypes ? element.trainTypes.map(e => typeof(e) === 'string' ? SpeedTrainType[e] : e) : null,
        tripTypes: element.tripTypes,
        routings: this.prepareRailRoutings(element.routings),
        providers: element.providers ? element.providers.map(e => typeof(e) === 'string' ? RailProviderType[e] : e) : null,
        travellerCategories: element.travellerCategories,
        ticketsClasses: element.ticketsClasses,
      };
      railRules.push(rule);
    });

    return {
      configurationName: this.configurationName,
      rootCompanyId: this.currentCompany!.rootCompanyId,
      railRules,
      hideNonCompliantRates: this.travelPolicyRail.hideNonCompliantRates,
      isPostSalesEnabledInNonCompliantBooking: this.travelPolicyRail.isPostSalesEnabledInNonCompliantBooking,
    };
  }

  setAndGetData() {
    TravelPolicyAccomodationStore.setAccomodationRules([]);
    TravelPolicyAccomodationStore.getAccomodationRulesConfiguration(this.currentConfigurationId);
    TravelPolicyCarStore.setCarRules([]);
    TravelPolicyCarStore.getCarRulesConfiguration(this.currentConfigurationId);
    TravelPolicyRailStore.setRailRules([]);
    TravelPolicyRailStore.getRailRulesConfiguration(this.currentConfigurationId);
  }

  toastShow() {
    let obj = {
      type: translate('settings-travel-policy.success'),
      title: translate('common.saved'),
      message: translate('settings-travel-policy.travel-policy-saved')
    };
    EventBus.$emit('show-toast', obj);
  }

  errorToast() {
    let obj = {
      type: 'error',
      title: translate('common-error.error'),
      message: translate('settings-travel-policy.save-error')
    };
    EventBus.$emit('show-toast', obj);
  }

  async saveTravelPolicy() {
    this.checkForm();
    if (this.travelPolicyFlight.flightRules && !this.formHasError) {
      let flightRequest = this.fligthTpRequest();
      let accomodationRequest = this.accomodationTpRequest();
      let carRequest = this.carTpRequest();
      let railRequest = this.railTpRequest();

      if (this.currentConfigurationId) {
        try {
          this.formPending = true;
          let result = await TravelPolicyApi.editFlightRules(this.currentConfigurationId, flightRequest);
          TravelPolicyFlightStore.setFlightRules([]);
          TravelPolicyFlightStore.getFlightRulesConfiguration(this.currentConfigurationId);

          if (result && result.status === 204) {
            await TravelPolicyApi.editAccomodationRules(this.currentConfigurationId, accomodationRequest);
            await TravelPolicyApi.editCarRules(this.currentConfigurationId, carRequest);
            await TravelPolicyApi.editRailRules(this.currentConfigurationId, railRequest);

            this.setAndGetData();
          }

          this.toastShow();
        } catch (error) {
          TravelPolicyStore.setErrors(error);
        } finally {
          this.formPending = false;
        }
      } else {
        try {
          this.formPending = true;
          let result = await TravelPolicyApi.addFlightRules(flightRequest);

          if (result && result.data) {
            router.push({
              name: 'travel-policy-configuration',
              params: {
                configurationId: result.data.configurationId
              },
            });
            TravelPolicyFlightStore.setFlightRules([]);
            TravelPolicyFlightStore.getFlightRulesConfiguration(result.data.configurationId);
          
            this.toastShow();

            await TravelPolicyApi.editAccomodationRules(result.data.configurationId, accomodationRequest);
            await TravelPolicyApi.editCarRules(result.data.configurationId, carRequest);
            await TravelPolicyApi.editRailRules(result.data.configurationId, railRequest);

            this.setAndGetData();
          } else {
            this.errorToast();
          }
        } catch (error) {
          TravelPolicyStore.setErrors(error);
        } finally {
          this.formPending = false;
        }
      }
    }
  }

  checkForm() {
    this.formHasError = false;
    this.$v.configurationName.$touch();
    if (this.$v.configurationName.$pending || this.$v.configurationName.$error) {
      this.formHasError = true;
    }
  }

  @Watch('$route', {deep: true,  immediate: true})
  onRouteChange() {
    EventBus.$emit('refresh-data');
  }

  created() {
    TravelPolicyStore.setCustomError(false);
    DictionaryStore.loadRentalCompany();
    this.isReadOnly = !!this.$route.query.readOnly;
    if (!this.isFromManage) {
      TravelPolicyFlightStore.getFlightRulesConfiguration(this.currentConfigurationId);
      TravelPolicyAccomodationStore.getAccomodationRulesConfiguration(this.currentConfigurationId);
      TravelPolicyCarStore.getCarRulesConfiguration(this.currentConfigurationId);
      TravelPolicyRailStore.getRailRulesConfiguration(this.currentConfigurationId);
    }
  }
}
