

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import moment from 'moment';

import AirResultsRow from '@/modules/search/air/AirResultsRow.vue';
import TrainResultsBasketRow from '@/modules/search/train/TrainResultsBasketRow.vue';
import CustomParameters from '@/modules/basket/CustomParameters.vue';
import {
  BasketModel,
  BasketFlatItemModel,
  } from '@/api/trip/basket.model';
import { BasketApi } from '@/api/trip/basket.api';
import { router } from '@/router';
import AccountStore from '@/store/account.store';
import settings from '@/settings';
import HotelResultsRow from '@/modules/search/hotel/HotelResultsRow.vue';
import TrainResultsRow from '@/modules/search/train/TrainResultsRow.vue';
import CarResultsRow from '@/modules/search/car/CarResultsRow.vue';
import { translate } from '@/i18n';
import HotelCancellationPolicy from '@/modules/search/hotel/HotelCancellationPolicy.vue';
import CarCancellationPolicy from '@/modules/search/car/CarCancellationPolicy.vue';
import TrainCancellationPolicy from '@/modules/search/train/TrainCancellationPolicy.vue';
import TrainTrenitaliaCancellationPolicy from '@/modules/search/train/TrainTrenitaliaCancellationPolicy.vue';
import TrainBenerailCancellation from '@/modules/search/train/TrainBenerailCancellation.vue';
import TrainSncfCancellationPolicy from '@/modules/search/train/TrainSncfCancellationPolicy.vue';
import RailTrenitaliaExchangeModifyForm from './rail/RailTrenitaliaExchangeModifyForm.vue';
import RailSncfExchangeModifyForm from './rail/RailSncfExchangeModifyForm.vue';
import RailNtvExchangeModifyForm from './rail/RailNtvExchangeModifyForm.vue';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import BasketItemIdentifiers from '@/modules/basket/BasketItemIdentifiers.vue';
import BasketAirOffer from './BasketAirOffer.vue';
import ExpenseStore from '@/modules/expense/expense.store';
import { OffersApi } from '@/api/air-engine/offers.api';
import { Permission } from '@/const/permission.enum';
import AirExchangeModifyPopup from '@/modules/search/air/AirExchangeModifyPopup.vue';
import SearchStore from '@/modules/search/search.store';
import BasketErrorGroups from './BasketErrorGroups.vue';
import formatTime from '@/filters/format-time.filter';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { Journey, Proposal } from '@/api/train-engine/train-search.model';
import SearchShortTripTravellers from '@/modules/search/SearchShortTripTravellers.vue';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import { sanitizeUrl } from '@braintree/sanitize-url';
import BasketProviderBookingPopup from './BasketProviderBookingPopup.vue';
import BasketRawProviderBookingPopup from './BasketRawProviderBookingPopup.vue';
import TripDescriptionText from './TripDescriptionText.vue';
import BasketItemAdditionalMessages from './BasketItemAdditionalMessages.vue';
import BasketSyncAirBookingSuccessPopup from './BasketSyncAirBookingSuccessPopup.vue';
import BasketSyncAirBookingErrorPopup from './BasketSyncAirBookingErrorPopup.vue';

@Component({
  components: {
    BasketAirOffer,
    AirResultsRow,
    TrainResultsBasketRow,
    HotelResultsRow,
    CustomParameters,
    TrainResultsRow,
    HotelCancellationPolicy,
    CarCancellationPolicy,
    CarResultsRow,
    TrainCancellationPolicy,
    TrainTrenitaliaCancellationPolicy,
    TrainBenerailCancellation,
    BasketItemIdentifiers,
    AirExchangeModifyPopup,
    TrainSncfCancellationPolicy,
    RailTrenitaliaExchangeModifyForm,
    RailSncfExchangeModifyForm,
    RailNtvExchangeModifyForm,
    BasketErrorGroups,
    SearchShortTripTravellers,
    BasketProviderBookingPopup,
    BasketRawProviderBookingPopup,
    TripDescriptionText,
    BasketItemAdditionalMessages,
    BasketSyncAirBookingSuccessPopup,
    BasketSyncAirBookingErrorPopup,
  },
  filters: {
    dateFormat(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  }
})
export default class BasketItem extends Vue {
  @Prop() item!: BasketFlatItemModel;
  @Prop() basket!: BasketModel;
  @Prop() bookingInProgress!: boolean;
  @Prop({ default: true }) showActions!: boolean;
  @Prop({ default: false }) hideErrors!: boolean;
  @Prop({ default: false }) bookingProcessing!: boolean;
  @Prop({ default: false }) isProcessing!: boolean;

  showingItemDetails: boolean = false;
  showModifyTrenitaliaPopup: boolean = false;
  showModifySncfPopup: boolean = false;
  showModifyNtvPopup: boolean = false;
  displayValidationMessages: boolean = false;
  basketId: string | null = null;
  accommodationCancelPopupVisible: boolean = false;
  imagesConst = '/assets/img/loader/1.gif';
  carCancelPopupVisible: boolean = false;
  railCancelPopupVisible: boolean = false;
  railRefundPopupVisible: boolean = false;
  showDesynchronizeConfirmModal: boolean = false;
  bookingIdentifiersPopupVisible: boolean = false;
  desyncInProgress: boolean = false;
  desyncErrors: any[] = [];
  cancellation: any | null = null;
  cancellationErrors: any[] = [];
  showEditSegmentPopup: boolean = false;
  showingConfirmRemoveItem: boolean = false;
  loading: boolean = false;
  ticketingDeadlineValue: string = '';
  loadDetailsErrors: any[] = [];
  airExchangePopupErrors: any[] = [];
  itemDetails: any | null = null;
  itemDetailsToModify: any | null = null;
  accomodationItem: any | null = null;
  modificationNotAllowed: boolean = false;
  bookingSyncPopupVisible: boolean = false;
  menuActive: boolean = false;
  moreMenuActive: boolean = false;
  showProviderBookingPopup: boolean = false; 
  showRawProviderBookingPopup: boolean = false; 
  areCancellationConditionsLoaded: boolean = false;
  carCancellationInProgress: boolean = false;
  cancelHoldPopupVisible: boolean = false;

  get syncAirBookingId() {
    return BasketStore.syncAirBookingId;
  }

  get showSyncAirBookingSuccessPopup() {
    return BasketStore.showSyncAirBookingSuccessPopup;
  }
  set showSyncAirBookingSuccessPopup(value) {
    BasketStore.setShowSyncAirBookingSuccessPopup(value);
  }  
  get isDisableSendingBackOfficeSyncAirBookingSuccessPopup() {
    return BasketStore.isDisableSendingBackOfficeSyncAirBookingSuccessPopup;
  }
  get showSyncAirBookingErrorPopup() {
    return BasketStore.showSyncAirBookingErrorPopup;
  }
  set showSyncAirBookingErrorPopup(value) {
    BasketStore.setShowSyncAirBookingErrorPopup(value);
  }

  get railTotalPrice() {
    const changes = BasketStore.priceChanges
      .filter(change => change.type === 'discounts' && change.tripItemId === this.item.id);

    if (changes.length) {
      let price = {
        ...this.item.price.total,
      };

      changes.forEach(change => {
        change.prices.forEach(p => price.amount += p.amount);
      });

      return price;
    }
    
    return this.item.price.total;
  }

  get workflowError() {
    return BasketStore.workflowError;
  }

  get notificationsInfo() {
    return BasketStore.notificationsCount.find(item => item.tripItemId === this.item.id);
  }

  get unread() {
    if (!this.notificationsInfo) {
      return 0;
    }

    return this.notificationsInfo.unreadNotificationsCount;
  }

  get totalNotifications() {
    if (!this.notificationsInfo) {
      return 0;
    }

    return this.notificationsInfo.totalNotificationsCount;
  }

  get notificationsLink() {
    if (this.item.wmCode) {
      return sanitizeUrl('/notifications?affectedBooking=' + this.item.wmCode);
    }
    return '///';
  }

  get travellersOfItem() {
    return this.travellers.filter(traveller => {
      return (this.item.travellers as any[]).find(t => t.profileId === traveller.id);
    });
  }

  get basketStatus() {
    return BasketStore.status;
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get preorderTooltip() {
    return {
      classes: 'basket-status-info__preorder-tooltip',
      content: translate('basket.preorder-tooltip-content'),
    };
  }

  get bookingStepCode() {
    return BasketStore.bookingStepDef && BasketStore.bookingStepDef.code ? BasketStore.bookingStepDef.code : '';
  }

  get canShowExternalHopper() {
    return 'true' === settings.enableExternalHopper;
  }

  get basketItemsStatusSuccessful() {
    return this.basketItemStatus.confirmed || this.basketItemStatus.held || this.basketItemStatus.cancelled || this.basketItemStatus.ticketed;
  }

  get canSeeContextMenu() {
    return AccountStore.HasPermission('CanManageSystem') && this.item.status !== 'Draft';
  }

  get canSeeProviderBusinessWarnings() {
    return AccountStore.HasPermission('CanSeeProviderBusinessWarnings');
  }

  get correctSupplierForModify() {
    return this.item.supplier === 'Sabre' ||
      this.item.supplier === 'Lufthansa' ||
      this.item.supplier === 'AmericanAirlines' ||
      this.item.supplier === 'Emirates' ||
      this.item.supplier === 'AirFranceKlm';
  }

  get correctProviderStatusForExchange() {
    return this.item.providerStatus === 'Ticketed' || this.item.providerStatus === 'TicketedExchanged';
  }

  get basketItemStatus() {
    return {
      draft: this.item.status === 'Draft',
      held: this.item.status === 'Held',
      pendingApproval: this.item.status === 'PendingApproval',
      confirmed: this.item.status === 'Confirmed',
      agencySupportRequired: this.item.status === 'AgencySupportRequired',
      userActionRequired: this.item.status === 'UserActionRequired',
      cancelled: this.item.status === 'Cancelled',
      desynchronized: this.item.status === 'Desynchronized',
      refused: this.item.status === 'Refused',
      ticketed: this.item.status === 'Ticketed',
      delayed: this.item.status === 'DelayedTicketing',
    };
  }

  get basketItemStatusName() {
    return this.item.status;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get missionDetails() {
    return SearchStore.mission;
  }

  get user() {
    return AccountStore.current;
  }

  get classes() {
    return {
      'identifiers-link': this.user!.profile.displayLanguage !== 'it',
      'identifiers-link-it': this.user!.profile.displayLanguage === 'it',
    };
  }

  get ticketingDeadlineTimezoneOffset() {
    if (this.item.type === 'Air' && this.item.deadline) {
      return moment.parseZone(this.item.deadline).format('Z');
    }
    if (this.item.type === 'Accomodation' && this.itemDetails) {
      let offer = this.itemDetails.selectedOffer;
      if (offer && offer.refundableUntil) {
        return moment.parseZone(offer.refundableUntil).format('Z');
      }
    }
    return null;
  }

  get ticketingDeadline() {
    if (this.item.type === 'Air' && this.itemDetails) {
      let formatDate = 'Do MMMM YYYY ';
      if (this.user && this.user.profile.displayLanguage === 'pl') {
        formatDate = 'D MMMM YYYY ';
      }

      if (this.item.deadline) {
        let dateTimeNoOffset = this.item.deadline.slice(0, this.item.deadline.length - 6);
        return `${ moment(dateTimeNoOffset, 'YYYY-MM-DDTHH:mm').format(formatDate) } ${ formatTime(dateTimeNoOffset) } (GMT${ this.ticketingDeadlineTimezoneOffset })`;
      } else if (this.itemDetails.ticketingDeadline) {
        return moment(this.itemDetails.ticketingDeadline, 'YYYY-MM-DD HH:mm').format(formatDate) + formatTime(this.itemDetails.ticketingDeadline);
      } else {
        return null;
      }
    }

    return null;
  }

  get shouldShowTicketingDeadline() {
    if (this.item.type === 'Air') {
      return this.item.status === 'Draft' || this.item.status === 'Held' || this.item.status === 'PendingApproval';
    } else {
      return false;
    }
  }

  get carCancellationDeadline() {
    if (!this.itemDetails || !this.itemDetails.rate.cancellationRefund.length || !this.itemDetails.rate.cancellationRefund[0].refundableUntil) {
      return '';
    }
    return moment.parseZone(this.itemDetails.rate.cancellationRefund[0].refundableUntil).format(this.currentDateTimeFormat);
  }

  get shouldShowItemIdentifiersLink() {
    if (this.basketItemStatus.draft) {                                                      
      return false;
    }
    return true;
  }
  
  get expenseIdForBasket() {
    return BasketStore.expenseIdForBasket;
  }

  get shouldAddPadding() {
    return !this.basketItemsStatusSuccessful ||
      this.ticketingDeadline ||
      this.ticketingDeadlineValue ||
      (this.itemDetails && this.item.type === 'Accomodation' && this.itemDetails.selectedOffer.refundableUntil) ||
      (this.itemDetails && this.item.type === 'Accomodation' && !this.itemDetails.selectedOffer.refundableUntil) ||
      (this.item.type === 'Car' && this.carCancellationDeadline) ||
      this.item.isInProgress;
  }

  get canSynchProviderBookingData() {
    return AccountStore.HasPermission('CanSynchProviderBookingData');
  }

  get airExchangeTravellers() {
    if (this.item.type === 'Air' && !this.itemDetailsToModify ) {
      return this.travellers;
    } 

    if (this.item.type === 'Air' && this.itemDetailsToModify && this.itemDetailsToModify.passengers) {
      return this.travellers.filter(traveller => {
        return -1 < this.itemDetailsToModify.passengers
          .map(passenger => passenger.profileId)
          .indexOf(traveller.id);
      });
    }
    
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get propertyErrors() {
    let groupeErrors = {};
    let groups: any[] = [];
    if (this.item && this.item.bookingErrors && this.item.bookingErrors.length) {
      groupeErrors = _.groupBy(this.item.bookingErrors, 'errorLevel');
    }

    for (let item in groupeErrors) {
      groups.push({
        type: item,
        items: groupeErrors[item],
      });
    }

    return groups;
  }

  get basketLoading() {
    return BasketStore.loading;
  }

  get ticketsExist() {
    return this.item.type === 'Rail' && this.item.providerReferenceId ? BasketStore.ticketsExist : false;
  }

  get ticketsExistLoading() {
    return BasketStore.ticketsExistLoading;
  }
  
  get disabledAF() {
    if (this.item.statusTag === 'Exchanged' && this.item.type === 'Air' && this.item.supplier === 'AirFranceKlm') {
      return true;
    }
    return false;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get showTripTravellers() {
    if (this.basketItems && this.basketItems.length) {
      return !!this.basketItems.find(item => item.travellers && item.travellers.length !== this.travellers.length);
    }

    return true;
  }

  get disabledShowProviderBooking() {
    if (this.item.providerStatus === null || this.item.providerStatus === 'TicketingPending' || this.item.type === 'Car') {
      return true;
    }
    return false;
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get currentDateTimeFormat() {
    return `${this.currentDateFormat} ${this.currentTimeFormat}`;
  }

  get basketExpired() {
    return this.$route.name === 'basket-expired';
  }

  get wmCode() {
    return this.item.wmCode.trim();
  }

  get shouldShowBasketPaymentMethodsView() {
    return BasketStore.shouldShowBasketPaymentMethods;
  }

  get shouldShowExtrasLink() {
    return this.item && this.item.actions && this.item.actions.extras && this.item.actions.extras.status !== null;
  }

  get shouldShowModifyLink() {
    return this.item && this.item.actions && this.item.actions.exchange && this.item.actions.exchange.status !== null;
  }

  get shouldShowModifyBookingLink() {
    return this.item && this.item.actions && this.item.actions.modifyFlights && this.item.actions.modifyFlights.status !== null;
  }

  get shouldShowCancelLink() {
    return this.item && this.item.actions && this.item.actions.cancel && this.item.actions.cancel.status !== null;
  }

  get shouldShowModifyTravellersDetails() {
    return this.item && this.item.actions && this.item.actions.modifyTravellersData && this.item.actions.modifyTravellersData.status !== null;
  }

  get shouldShowRefundLink() {
    return this.item && this.item.actions && this.item.actions.refund && this.item.actions.refund.status !== null;
  }

  get shouldShowDesynchronizeLink() {
    return AccountStore.HasPermission('CanMarkAsDesynchronized') && this.item && this.item.actions && this.item.actions.desynchronize && this.item.actions.desynchronize.status !== null;
  }

  get shouldShowSynchronizeLink() {
    return this.item && this.item.actions && this.item.actions.synchronize && this.item.actions.synchronize.status !== null;
  }

  get shouldShowChangePaymentMethodLink() {
    return this.item && this.item.actions && this.item.actions.changePaymentMethod && this.item.actions.changePaymentMethod.status !== null;
  }

  get shouldShowAirExtras() {
    return this.item.type === 'Air' && this.shouldShowExtrasLink;
  }

  get shouldShowAirExchange() {
    return this.item.type === 'Air' && this.shouldShowModifyLink;
  }

  get shouldShowAirModifyBooking() {
    return this.item.type === 'Air' && this.shouldShowModifyBookingLink;
  }

  get shouldShowRailModifyTicket() {
    return this.item.type === 'Rail' && this.shouldShowModifyLink;
  }

  get shouldShowAirCancel() {
    return this.areCancellationConditionsLoaded && this.item.type === 'Air' && this.shouldShowCancelLink;
  }

  get shouldShowRegisterAsExpense() {
    return this.item.type === 'Air' && this.basket.missionId &&
      !this.missionDetails.externalId && !this.item.expenseId &&
      this.item.status === 'Confirmed';
  }

  get shouldShowDisplayExpense() {
    return this.item.type === 'Air' && this.basket.missionId && this.item.expenseId;
  }

  get shouldShowRailCancel() {
    return this.item.type === 'Rail' && this.shouldShowCancelLink;
  }

  get shouldShowRailRefund() {
    return this.item.type === 'Rail' && 
      (-1 < [
        'Trenitalia',
        'Benerail',
      ].indexOf(this.item.supplier)) &&
      this.shouldShowRefundLink;
  }

  get shouldShowAccommodationCancel() {
    return this.item.type === 'Accommodation' && this.shouldShowCancelLink;
  }

  get shouldShowCarCancel() {
    return this.item.type === 'Car' && this.shouldShowCancelLink;
  }

  get shouldShowSynchronize() {
    return this.canSynchProviderBookingData && this.item.type === 'Air' && this.shouldShowSynchronizeLink;
  }

  get shouldShowCancelHoldLink() {
    return this.item && this.item.actions && this.item.actions.cancelHold && this.item.actions.cancelHold.status !== null;
  }

  get shouldShowRemoveItem() {
    return this.item.status === 'Draft' &&
      !this.bookingInProgress &&
      this.$hasAccess('AddOfferToTrip') &&
      !this.isInWizard;
  }

  get shouldShowMoreLink() {
    const conditions = [
      this.shouldShowChangePaymentMethodLink,
      this.shouldShowAirExtras,
      this.shouldShowAirExchange,
      this.shouldShowAirModifyBooking,
      this.shouldShowRailModifyTicket,
      this.shouldShowAirCancel,
      this.shouldShowModifyTravellersDetails,
      this.shouldShowRegisterAsExpense,
      this.shouldShowDisplayExpense,
      this.shouldShowRailCancel,
      this.shouldShowRailRefund,
      this.shouldShowAccommodationCancel,
      this.shouldShowCarCancel,
      this.shouldShowDesynchronizeLink,
      this.shouldShowSynchronize,
      this.shouldShowCancelHoldLink,
    ];

    return conditions
      .find(item => !!item);
  }

  get tourOperatorWarning() {
    if (this.itemDetails && this.itemDetails.fareLabel === 'TO' && (this.basketItemStatus.draft || this.basketItemStatus.held || this.basketItemStatus.pendingApproval)) {
      return true;
    }

    return false;
  }

  get isAmadeusSupplier() {
    return this.item.supplier === 'Amadeus';
  }

  get isAmadeusSupplierDraft() {
    if (this.isAmadeusSupplier && this.basketItemStatus.draft) {
      return true;
    }

    return false;
  }

  get isAgencySupportOrUserActionRequired() {
    return ['AgencySupportRequired', 'UserActionRequired'].indexOf(this.basketItemStatusName) > -1;
  }

  get shouldHideProviderErrors() {
    return this.basketItemStatusName === 'AgencySupportRequired' && !this.user!.profile.isAgency;
  }

  checkIfActionStatusEquals(actionType: string, statuses: string[]) {
    let result: boolean = false;
    if (this.item && this.item.actions && this.item.actions[actionType]) {
      statuses.forEach(status => {
        if (this.item.actions[actionType].status === status) {
          result = true;
        }
      });
    }

    return result;
  }

  openMissedSavingsModal() {
    BasketStore.showMissedSavingsModal(this.item.id);
  }

  getDateTimeFormatted(date) {
    return moment(date).format(this.currentDateTimeFormat);
  }

  tooltipExchangeAvailable() {
    if (this.item.type === 'Air' && !this.item.isExchangeAvailable) {
      return translate('search-air.modify-ticket-disabled');
    } else if (this.disabledAF && !this.item.isExchangeAvailable) {
      return translate('search-air.disabled-modify-ancillaries');
    } else if (this.disabledAF) {
      return translate('search-air.modify-not-supported');
    }

    return '';
  }

  tooltipExtrasExchangeAvailable() {
    if (this.checkIfActionStatusEquals('extras', ['Disabled'])) {
      return translate('basket-item-actions-by-code.' + this.item.actions.extras);
    }

    return '';
  }

  basketActionTooltip(actionType) {
    if (this.checkIfActionStatusEquals(actionType, ['Disabled'])) {
      return this.item.actions[actionType].disabledReason;
    }

    if (actionType === 'desynchronize') {
      return [
        translate('basket.desynchronize-tooltip1'),
        translate('basket.desynchronize-tooltip2'),
      ].join('<br/><br/>');
    }

    return '';
  }

  tooltipCancelAFAvailable() {
    if (this.disabledAF) {
      return translate('search-air.cancel-not-supported');
    }

    return '';
  }

  tooltipTripItemPaymentInfo() {
    if (this.item.tripItemPaymentInfo && this.item.tripItemPaymentInfo.length) {
      if (this.item.tripItemPaymentInfo[0].maskedNumber) {
        return translate('basket.paid-by') + ': ' + translate('basket-payment-method-by-type.' + this.item.tripItemPaymentInfo[0].paymentMethod) + ' (' + this.item.tripItemPaymentInfo[0].maskedNumber + ')';
      } else {
        return translate('basket.paid-by') + ': ' + translate('basket-payment-method-by-type.' + this.item.tripItemPaymentInfo[0].paymentMethod);
      }
    }

    return '';
  }

  notificationsCountClick() {
    router.push({
      name: 'notifications',
      query: {
        affectedBooking: this.item.wmCode,
      }
    });
  }

  errorClasses(error) {
    return {
      'error': error.errorLevel === 'Unknown' || error.errorLevel === 'Error',
      'warning': error.errorLevel === 'Warning',
      'info-level': error.errorLevel === 'Info',
    };
  }

  async getItemCancellationData() {
    try {
      const response = await BasketApi.getItemCancellationPolicy(this.item.id);
      if (response && response.data) {
        this.cancellation = response.data;
        this.areCancellationConditionsLoaded = true;
      }
    } catch (error) {
      this.cancellationErrors = this.$handleErrors(error);
    }
  }

  async itemSynchronizeBooking() {
    BasketStore.setSynchronizationCompleted(false);
    await BasketStore.initItemBookingDataSync(this.item);
  }

  removeItem() {
    SearchStore.updateSkipTravellers(false);
    BasketStore.removeBasketItem(this.item.id);
  }

  confirmRemoveItem() {
    this.showingConfirmRemoveItem = true;
  }

  displayExpenseForItem(id) {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: id,
      }
    });
  }

  async registerAsExpense() {
    await ExpenseStore.getDefaultExpensePolicy(Permission.BookTrip);

    if (!this.policyId) {
      return;
    }
    let name = '';
    if (this.item && this.item.type === 'Air') {
      name = translate('basket.flight-to') + this.item.location;
    } else if (this.item && this.item.type === 'Accommodation') {
      name = translate('basket.hotel-stay') + this.item.location;
    } else if (this.item && this.item.type === 'Car') {
      name = translate('basket.car-in') + this.item.location;
    } else if (this.item && this.item.type === 'Rail') {
      name = translate('basket.train-to') + this.item.location;
    }

    let request = {
      reporterId: this.user!.profile.id,
      policyId: this.policyId,
      companyId: this.user!.profile.rootCompanyId,
      businessUnitId: this.user!.profile.companyId,
      missionId: this.basket.missionId,
      tripItem: {
        id: this.item.id,
        tripId: this.basketId,
        type: this.item.type === 'Air' ? 'Flight' : this.item.type,
        creationDate: this.item.createDate ? moment(this.item.createDate).format('YYYY-MM-DD') : null,
        name: name,
        price: this.item.price.total,
        supplier: this.item.supplier
      }
    };
    await BasketStore.registerAsExpense(request);

    if (this.expenseIdForBasket) {
      await BasketStore.saveConnectionTripExpense({itemId: this.item.id, expenseId: this.expenseIdForBasket});
      router.push({
        name: 'modify-expense',
        params: {
          expenseId: this.expenseIdForBasket,
        }
      });
    }
  }

  toggleContextMenu() {
    if (!this.menuActive) {
      this.menuActive = true;
    } else {
      this.menuActive = false;
    }
  }

  toggleMoreActionMenu() {
    if (!this.moreMenuActive) {
      this.moreMenuActive = true;
    } else {
      this.moreMenuActive = false;
    }
  }

  showProviderBooking() {
    this.menuActive = false;
    this.showProviderBookingPopup = true;
  }

  showRawProviderBooking() {
    this.menuActive = false;
    this.showRawProviderBookingPopup = true;
  }

  @Watch('item.bookingErrors', {deep: true})
  onErrorShow() {
    if (!this.shouldShowBasketPaymentMethodsView) {
      this.scrollToError();
    }
  }

  async toggleShowingItemDetails() {
    if (!this.showingItemDetails) {
      this.loading = true;
    }
    
    this.$nextTick(async () => {
      this.showingItemDetails = !this.showingItemDetails;
      
      if (this.showingItemDetails) {
        this.itemDetails = null;
        this.loadDetailsErrors = [];
        
        if (this.item.type === 'Air') {
          await this.getAirDetails(); 
        } else {
          await this.getBasketItem();
        }
      }
    });
  }

  hideItemDetails() {
    if (!this.showingItemDetails) {
      return;
    }

    this.toggleShowingItemDetails();
  }

  async getAirDetails() {
    try {
      const response = await OffersApi.getOffer(this.item.providerReferenceId);

      this.itemDetails = {
        ...response.data,
        pricePlusMarkup: this.item.price.total,
        travelPolicy: this.item.travelPolicy,
      };
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async getBasketItem() {
    try {
      const response = await BasketItemApi.getBasketItem(this.item.id);
      let details = response.data;

      if (details.rail && details.rail.recommendations) {
        let journeys: Journey[] = [];
        let proposals: Proposal[] = [];
        let price = 0;

        details.rail.recommendations.forEach(recommendation => {
          recommendation.journeys.forEach(journey => {
            journeys.push(journey);
          });

          recommendation.proposals.forEach(proposal => {
            proposals.push(proposal);
          });
        });

        proposals.forEach(proposal => {
          proposal.fares.forEach(fare => {
            fare.isSelected = true;
            price += (fare as any).price.amount;
          });
        });

        details.rail = {
          ...details.rail,
          id: details.rail.id,
          orderId: details.rail.orderId,
          offerProvider: details.rail.recommendations[0].offerProvider,
          supplier: details.rail.recommendations[0].supplier,
          recommendationId: details.recommendationId,
          journeys: journeys,
          proposals: proposals,
          price: {
            amount: price,
            currency: {
              code: 'EUR',
              symbol: '€'
            }
          },
          travelPolicy: details.travelPolicy,
        };
        this.itemDetails = details.rail;
      } else if (details.accommodation) {

        this.itemDetails = {
          ...details.accommodation,
          agencyFee: details.agencyFee,
          supplier: details.supplier,
          travelPolicy: this.item.travelPolicy,
        };

        this.accomodationItem = {
          ...this.item,
          agencyMarkup: details.accommodation.selectedOffer.agencyMarkup,
          totalPrice: this.item.price.total,
        };
      } else if (details.car) {
        this.itemDetails = {
          ...details.car.recommendation,
          totalPrice: this.item.price.total,
          supplier: details.supplier,
          travelPolicy: this.item.travelPolicy,
        };
      }
            
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  openCancelAccommodationModal() {
    if (this.checkIfActionStatusEquals('cancel', ['Disabled'])) {
      return;
    }
    this.accommodationCancelPopupVisible = true;
  }

  openCancelCarModal() {
    if (this.checkIfActionStatusEquals('cancel', ['Disabled'])) {
      return;
    }
    this.carCancelPopupVisible = true;
  }

  openDesynchronizeModal() {
    if (this.checkIfActionStatusEquals('desynchronize', ['Disabled'])) {
      return;
    }
    this.showDesynchronizeConfirmModal = true;
  }

  openSyncModal() {
    if (this.checkIfActionStatusEquals('synchronize', ['Disabled'])) {
      return;
    }
    this.bookingSyncPopupVisible = true;
  }

  openCancelRailModal() {
    if (this.checkIfActionStatusEquals('cancel', ['Disabled'])) {
      return;
    }
    this.railCancelPopupVisible = true;
  }

  openRefundRailModal() {
    if (this.checkIfActionStatusEquals('refund', ['Disabled'])) {
      return;
    }
    this.railRefundPopupVisible = true;
  }

  openCancelHoldModal() {
    if (this.checkIfActionStatusEquals('cancelHold', ['Disabled'])) {
      return;
    }
    this.cancelHoldPopupVisible = true;
  }

  async cancelHoldBooking() {
    try {
      await BasketItemApi.cancelHoldItem(this.item.id);

      this.cancelHoldPopupVisible = false;
      BasketStore.processBasket();
    } catch (error) {
      this.cancelHoldPopupVisible = false;
      BasketStore.setErrors(this.$handleErrors(error, true));
    }
  }

  async desynchronizeItem() {
    this.desyncErrors = [];
    this.desyncInProgress = true;
    try {
      const response = await BasketItemApi.desynchronizeItem(this.item.id);

      this.showDesynchronizeConfirmModal = false;
      BasketStore.processBasket();
    } catch (error) {
      this.desyncErrors = this.$handleErrors(error, true);
    } finally {
      this.desyncInProgress = false;
    }
  }

  confirmCancelItem() {
    this.accommodationCancelPopupVisible = false;
    EventBus.$emit('refresh-basket-status');
  }

  carCancelInProgress(value) {
    this.carCancellationInProgress = value;
  }

  closeCarCancelPopup () {
    if (this.carCancellationInProgress) {
      return;
    }
    this.carCancelPopupVisible = false;
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  scrollToPaymentError() {
    this.$nextTick(() => {
      if (!this.$refs.paymentSection) {
        return;
      }
      (this.$refs.paymentSection as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  onValidationMessages(value) {
    this.displayValidationMessages = value;
  }

  startChangePaymentWizard() {
    if (this.checkIfActionStatusEquals('changePaymentMethod', ['Disabled'])) {
      return;
    }
    BasketStore.startChangePaymentWizard(this.item.id);
  }

  startNdcExtrasWizard() {
    if (this.checkIfActionStatusEquals('extras', ['Disabled'])) {
      return;
    }
    BasketStore.startNdcExtrasWizard({
      basketItemId: this.item.id,
      supplier: this.item.supplier,
    });
  }

  async openModifyModal(actionType) {
    if (this.checkIfActionStatusEquals(actionType, ['Disabled'])) {
      return;
    }
    this.airExchangePopupErrors = [];
    this.itemDetailsToModify = null;
    this.showEditSegmentPopup = true;
    BasketStore.setLoadingExchangePopup(true);
    try {
      const response = await OffersApi.getOffer(this.item.providerReferenceId);
      if (response && response.data) {
        this.itemDetailsToModify = {
          ...response.data,
          proposal: {
            ...response.data,
            price: response.data.price.totalPrice,
            pricePlusMarkup: response.data.price.totalPrice,
            supplier: this.item.supplier,
            },
          travelPolicy: this.item.travelPolicy,
        };
        BasketStore.setLoadingExchangePopup(false);
      }
    } catch (error) {
      this.airExchangePopupErrors = this.$handleErrors(error, true);
    } finally {
      BasketStore.setLoadingExchangePopup(false);
    }
  }

  openRailModifyModal() {
    if (this.checkIfActionStatusEquals('exchange', ['Disabled'])) {
      return;
    }
    if (this.item.supplier === 'Trenitalia') {
      this.showModifyTrenitaliaPopup = true;
    } else if (this.item.supplier === 'Sncf' || this.item.supplier === 'Ouigo') {
      this.showModifySncfPopup = true;
    } else if (this.item.supplier === 'Ntv') {
      this.showModifyNtvPopup = true;
    }
  }

  hideRailModifyModal() {
    if (this.item.supplier === 'Trenitalia') {
      this.showModifyTrenitaliaPopup = false;
      this.modificationNotAllowed = false;
    } else if (this.item.supplier === 'Sncf' || this.item.supplier === 'Ouigo') {
      this.showModifySncfPopup = false;
    } else if (this.item.supplier === 'Ntv') {
      this.showModifyNtvPopup = false;
    }
  }
  
  openRefundModal() {
    if (this.checkIfActionStatusEquals('cancel', ['Disabled'])) {
      return;
    }
    EventBus.$emit('open-refund-popup', {
      id: this.item.id,
      conditions: this.cancellation,
    });
  }

  startModifyTravellersDetails() {
    if (this.checkIfActionStatusEquals('modifyTravellersData', ['Disabled'])) {
      return;
    }
    BasketStore.startModifyTravellersDetails(this.item.id);
  }

  async getItemDetailsWhenNeeded() {
    if (!this.showingItemDetails) {
      return;
    }

    if (this.item.type === 'Air') {
      await this.getAirDetails(); 
    } else {
      await this.getBasketItem();
    }
  }

  async mounted() {
    this.basketId = router.currentRoute.params.id;
    await this.getItemCancellationData();
    if (this.item.type === 'Rail' && this.item.providerReferenceId) {
      await BasketStore.getTicketsExist({orderId: this.item.providerReferenceId, railSupplier: this.item.supplier});
    }
    this.handleFollowUpAction();
  }

  handleFollowUpAction() {
    const followUpAction = BasketStore.basketItemFollowUpAction;
    if (!followUpAction || !followUpAction.tripItemId || followUpAction.tripItemId !== this.item.id) {
      return;
    }
    BasketStore.setBasketItemFollowUpAction(null);
    if (followUpAction.name === 'OpenRefundPopup') {
      this.openRefundModal();
    }
    if (followUpAction.name === 'OpenModifyModalExchange') {
      this.openModifyModal('exchange');
    }
  }


  created() {
    EventBus.$on('basket-item-hide-details', this.hideItemDetails);
    EventBus.$on('reload-item-details-after-price-recalculation', this.getItemDetailsWhenNeeded);
  }

  beforeDestroy() {
    EventBus.$off('basket-item-hide-details', this.hideItemDetails);
    EventBus.$off('reload-item-details-after-price-recalculation', this.getItemDetailsWhenNeeded);
  }
}
