import SystemMarkupConfigurations from '@/modules/settings/system-markup/SystemMarkupConfigurations.vue';

export default {
  path: '',
  name: 'system-markup-configurations',
  component: SystemMarkupConfigurations,
  displayName: `System markup's configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    parentName: 'system-markup',
    depthCategory: 'system-markup',
    depth: 1,
  }
};