



































































import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import BasketStepCheckbox from './BasketStepCheckbox.vue';

@Component({
  components: {
    BasketStepCheckbox,
  },
  filters: {
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
    dateFilter(date) {
      return moment.parseZone(date).format('Do MMMM YYYY');
    },
  },
})
export default class BasketStepsCheckboxes extends Vue {

  get wizardGroups() {
    return BasketStore.wizardStepsGroups;
  }

  get typeCodeOptions() {
    return searchConst.guestTravellerOptions.map(option => {
      return {
        ...option,
        name: translate(option.label)
      };
    });
  }

  get travellers() {
    if (!BasketStore.basket) {
      return [];
    }
    return BasketStore.basket.travellers;
  }

  isSplit(group) {
    if (group && group.tripItem && group.tripItem.travellers) {
      return group.tripItem.travellers.length !== this.travellers.length;
    }
    return false;
  }

  tooltipAddon(trav) {
    const option = this.typeCodeOptions.find(opt => opt.code === trav.passengerTypeCode);
    if (!option) {
      return [];
    }
    return [option.name];
  }

  groupTravellers(group) {
    if (!group || !group.tripItem) {
      return [];
    }

    return this.travellers.filter(traveller => {
      return group.tripItem.travellers.find(t => t.profileId === traveller.id);
    });
  }

  isGroupUnique(group) {
    const groupType = group.tripItem.type;

    const sameItems = this.wizardGroups
      .filter(item => {
        const tripItem = item.tripItem || {type: ''};
        return tripItem.type === groupType;
      });

    if (1 === sameItems.length) {
      return true;
    }
    return false;
  }

  sameDate(item) {
    return moment(item.startDate).isSame(item.endDate, 'date');
  }

  sameMonth(item) {
    return moment(item.startDate).isSame(item.endDate, 'month');
  }

  startDate(item) {
    return item.startDate;
  }

  endDate(item) {
    return item.endDate;
  }
  
  selectAll() {
    this.wizardGroups.forEach(group => {
      group.steps.forEach(step => {
        BasketStore.setWizardStepSelected({
          index: step.index,
          value: true,
        });
      });
    });
  }

  deselectAll() {
    this.wizardGroups.forEach(group => {
      group.steps
        .filter(step => !step.isMandatory)
        .forEach(step => {
          BasketStore.setWizardStepSelected({
            index: step.index,
            value: false,
          });
        });
    });
  }
}
