import { AxiosResponse } from 'axios';
import { http,
  RequestMethod 
} from '@/services/http';
import settings from '@/settings';
import {
  CarVendorsModel,
} from './car-vendors.model';

class CarVendorsApiClass {
  public getCarVendors(): Promise<AxiosResponse<CarVendorsModel[]>> {
    return http.execute({
      path: settings.apiCarEngine + '/car-vendors',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public getPriceChangeDetails(recommendationId: string, supplier: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/offers/{recommendationId}/price-change?supplier={supplier}',
      method: RequestMethod.GET,
    }, { recommendationId, supplier }, {});
  }
}

export const CarVendorsApi: CarVendorsApiClass = new CarVendorsApiClass();