











































































































import { Vue, Prop, Component } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import { TravellerAddressAtDestination } from '@/api/air-engine/traveller-address-at-destination.model';
import DictionaryStore from '@/store/dictionary.store';

@Component({})
export default class BasketProvideTravellerAddressAtDestinationForm extends Vue {
  @Prop({}) traveller: any;
  @Prop({}) index: any;
  @Prop({}) flightHasDestinationInUsa!: any;
    Form: TravellerAddressAtDestination = {
    street: '',
    cityName: '',
    postalCode: '',
    country: null
  };

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get postalCode() {
    return this.Form.postalCode;
  }
  set postalCode(postalCode: string) {
    this.Form = {
      ...this.Form,
      postalCode: postalCode.split('-').join(''),
    };
  }

  userFullName(user) {
    return userFullName(user);
  }

  getCodeTranslation(code) {
    const value = searchConst.guestTravellerOptions.find(item => item.code === code);

    if (!value) {
      return '';
    }

    return translate(value.label);
  }

  onStoreChangedAddresses() {
    BasketStore.saveTravellersAddressAtDestination({
      travellerId: this.traveller.id,
      street: this.Form.street,
      cityName: this.Form.cityName,
      postalCode: this.Form.postalCode,
      countryCode: this.Form.country.code
    });
  }

  close() {
    const autocomplete = (this.$refs.countryAutocomplete as Vue).$el as HTMLElement;
    const el = autocomplete.getElementsByTagName('input')[0];

    setTimeout(() => {
      el.blur();
    }, 100);
  }

  created() { 
    EventBus.$on('basket-save-travellers-address-at-destination', this.onStoreChangedAddresses);

    if (this.flightHasDestinationInUsa) {
      this.Form.country = this.allCountries.find(country => country.code === 'US');
    }
  }

  beforeDestroy() {
    EventBus.$off('basket-save-travellers-address-at-destination', this.onStoreChangedAddresses);
  }
}
