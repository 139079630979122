



































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import BasketSeatsMap from './BasketSeatsMap.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    BasketItem,
    BasketSeatsMap,
  }
})
export default class BasketProcessSeatsView extends Vue {
  @Prop() tripItemId!: string;
  @Prop() action!: any;

  loaded = false;
  canShow = false;

  get loading() {
    return this.seatsLoading || this.loadingItems || !this.item;
  }

  get loadingItems() {
    return BasketStore.loadingItems;
  }

  get processingSeats() {
    return BasketStore.basketProcessingStoreSeats;
  }

  get basket() {
    return BasketStore.basket;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get seatsLoading() {
    return BasketStore.loadingSeatsMap;
  }

  get basketSeatsMap() {
    return BasketStore.basketSeatsMap;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get item() {
    return this.basketItemsMapped.find(item => item.id === this.tripItemId) || null;
  }

  get bookingError() {
    if (!this.action) {
      return null;
    }
    const original = this.action.additionalData;
    return {
      text: original.Params.etext,
      details: original.Params.edetail,
    };
  }

  async created() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.resetPriceChangesForStep({ tripItemId: this.tripItemId, step: this.bookingStep });
    BasketStore.loadBasketItems();
    await BasketStore.loadBasketSeats(this.tripItemId);
    BasketStore.setBookingStepLoading(false);
  }

  beforeDestroy() {
    BasketStore.resetSelectedSeats();
  }
}

