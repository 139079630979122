import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { LodgeCardConfiguration, LodgeCard } from '@/api/lodge-cards/lodge-cards.model';
import { LodgeCardsApi } from '@/api/lodge-cards/lodge-cards.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'lodgeCards'
})
class LodgeCardsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  lodgeCardConfiguration: LodgeCardConfiguration = new LodgeCardConfiguration();
  selectedCard: LodgeCard = new LodgeCard();
  isCopy: boolean = false;
  isFromManage: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get LodgeCardConfiguration() {
    return this.lodgeCardConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setLodgeCardConfiguration(payload) {
    this.lodgeCardConfiguration = payload;
  }

  @Mutation
  setSelectedLodgeCard(payload) {
    this.selectedCard = new LodgeCard(payload);
  }

  @Mutation
  addLodgeCard(payload) {
    this.lodgeCardConfiguration.lodgeCards.push(new LodgeCard(payload));
  }

  @Mutation
  editLodgeCard(payload) {
    let index = this.lodgeCardConfiguration.lodgeCards.findIndex(card => {
      return payload.id === card.id;
    });
    if (-1 < index) {
      this.lodgeCardConfiguration.lodgeCards.splice(index, 1, payload);
    }
  }

  @Mutation
  removeLodgeCard(payload) {
    let index = this.lodgeCardConfiguration.lodgeCards.findIndex(card => {
      return payload.id === card.id;
    });
    if (-1 < index) {
      this.lodgeCardConfiguration.lodgeCards.splice(index, 1);
    }
  }

  @Mutation
  setName(value) {
    this.lodgeCardConfiguration.configurationName = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  setIsFromManage(payload) {
    this.isFromManage = payload;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'lodge-card-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }
  
  @Action
  clearData() {
    this.setLodgeCardConfiguration(new LodgeCardConfiguration());
    this.setCurrentConfigurationName('');
  }

  @Action
  async getLodgeCardsConfiguration(currentSelectedId) {
    this.setLoading(true);
    this.setLodgeCardConfiguration(new LodgeCardConfiguration());
    this.setShowError(false);
    if (currentSelectedId) {
      const result = await LodgeCardsApi.getLodgeCardsConfiguration(currentSelectedId);
      if (result && result.data) {
        this.setLodgeCardConfiguration(result.data);
      }
      this.setLoading(false);
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async addLodgeCardsConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    this.setCustomError(false);
    try {
      const result = await LodgeCardsApi.addLodgeCardsConfiguration(request);
      if (result && result.data) {
        this.setConfigurationId(result.data.configurationId);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-lodge-cards.config-saved')
        });

        this.getLodgeCardsConfiguration(result.data.configurationId);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateLodgeCardsConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await LodgeCardsApi.updateLodgeCardsConfiguration(data.configurationId, data.params);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-lodge-cards.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(LodgeCardsStore);
