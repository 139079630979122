









import { Vue, Component } from 'vue-property-decorator';

import DepthTransition from '@/modules/layout/DepthTransition.vue';

@Component({
    components: {
    DepthTransition,
    },
})
export default class ImportProfilesWrapper extends Vue {

}

