




















import { Vue, Component } from 'vue-property-decorator';

import ScrollMenu from './ScrollMenu.vue';
import profileRoute from '@/router/routes/profile/profile-by-id.route';
import { translate } from '@/i18n';
import ProfileStore from './profile.store';

@Component({
  components: {
    ScrollMenu,
  },
})
export default class ProfileSidebar extends Vue {

  get tabsStyles() {
    return {
      width: '100%',
    };
  }

  get links() {
    const specialLinks = {
      'approval-workflow': () => {
        return ProfileStore.hasAccessToApprovalWorkflow;
      },
      'manager-assignment': () => {
        return ProfileStore.hasAccessToManagerAssignment;
      },
      'profile-arranger-custom-fields': () => {
        return ProfileStore.hasAccessToProfileArrangerCustomFields;
      },
    };
    if (profileRoute != null && profileRoute.children != null) {
      return profileRoute.children
        .filter((route: any) => {
          return route.meta && route.meta.permission ? this.$hasAccess(route.meta.permission) : true;
        })
        .filter((route: any) => {
          if (specialLinks[route.name] != null) {
            return specialLinks[route.name]();
          }
          return true;
        })
        .map(route => {
          return {
            ...route,
            displayName: translate(route.displayName),
            icon: route.meta.icon,
          };
        });
    }

    return [];
  }
}
