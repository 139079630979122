import ProfileArrangerCustomFields from '@/modules/profile/custom-fields/ProfileArrangerCustomFields.vue';

export default {
  path: 'arranger-custom-fields',
  name: 'profile-arranger-custom-fields',
  component: ProfileArrangerCustomFields,
  displayName: 'profile-bookings-arranging.travel-arranging',
  meta: {
    permission: 'CanViewArrangerCustomFieldsInProfile',
    icon: 'groups',
  }
};
