








































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { translate } from '@/i18n';
import { CarRule } from '@/api/profile/display-preferences-car.model';
import DisplayPreferencesCarStore from '@/modules/settings/display-preferences/display-preferences-car.store';

@Component({})
export default class DisplayPreferencesCar extends BaseTable<any> {
  @Prop({ default: false }) formPending!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    rentalCompanies: {
      sortable: false,
      label: translate('settings-display-preferences.rental-company'),
    },
    pickUpDropOffLocations: {
      sortable: false,
      label: translate('settings-display-preferences.pick-up-drop-off-location'),
    },
    status: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  maxSize: number = Number.MAX_SAFE_INTEGER;
  menuActive: { [key: string]: boolean } = {};

  get displayPreferences() {
    return DisplayPreferencesCarStore.displayPreferencesCar.displayPreferences;
  }

  get withCompliantTravelPolicy() {
    return DisplayPreferencesCarStore.displayPreferencesCar.withCompliantTravelPolicy;
  }
  set withCompliantTravelPolicy(withCompliantTravelPolicy: boolean) {
    DisplayPreferencesCarStore.setDisplayPreferencesCar({
      ...DisplayPreferencesCarStore.displayPreferencesCar,
      withCompliantTravelPolicy,
    });
  }

  get count() {
    if (!this.results) {
      return 0;
    }
    return this.results.length;
  }

  get configurationId() {
    return this.$route.params.configurationId;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.displayPreferences,
    };
  }

  toggleRuleMenu(index: number) {
    this.menuActive = {
      ...this.menuActive,
      [`${index}`]: !this.menuActive[index],
    };
  }

  addRule() {
    DisplayPreferencesCarStore.setCarRule({ $index: this.displayPreferences.length });
    if (this.configurationId) {
      router.push({
        name: 'display-preferences-car-rule',
        params: {
          configurationId: this.configurationId
        },
      });
    } else {
      router.push({
        name: 'display-preferences-car-rule',
      });
    }
  }

  modifyRule(rule: CarRule, index: number) {
    DisplayPreferencesCarStore.setCarRule({
      ...rule,
      $index: index,
    });
    router.push({
      name: 'display-preferences-car-rule',
      params: {
        configurationId: this.configurationId,
      },
    });
  }

  removeRule(ruleIndex: number) {
    DisplayPreferencesCarStore.setDisplayPreferencesCar({
      ...DisplayPreferencesCarStore.displayPreferencesCar,
      displayPreferences: this.displayPreferences.filter((item, index) => index !== ruleIndex),
    });
  }
}
