


































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';
import { router } from '@/router';
import settings from '@/settings';
import TrainResultsRow from '../train/TrainResultsRow.vue';
import { AirApi } from '@/api/air-engine/air-search.api';
import accountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import LayoutStore from '@/modules/layout/layout.store';
import EventBus from '@/services/event-handler';
import AirRailSearchStore from './air-rail-search.store';
import AirTemplateOffers from '../air/AirTemplateOffers.vue';
import AirResultsRow from '../air/AirResultsRow.vue';
import AirResultsRowView from '../air/AirResultsRowView.vue';
import BasketAirOffer from '@/modules/basket/BasketAirOffer.vue';
import BasketApproval from '@/modules/basket/BasketApproval.vue';

@Component({
  components: {
    AirResultsRow,
    AirResultsRowView,
    TrainResultsRow,
    VclCode,
    AirTemplateOffers,
    BasketAirOffer,
    BasketApproval,
  }
})
export default class AirRailResults extends Vue {
  searchId: string | null = null;
  selectedOffer: { offer: any; proposal: any } | null = null;
  loadingOffers: boolean = true;
  forcing: boolean = false;
  enableFilterByFlightNumber: boolean = true;
  sortOptions = {
    PRICE: 'Ascending'
  };
  showTemplateOffersModal: boolean = false;
  showHopperModal: boolean = false;
  selectingOffer: boolean = false;
  serverErrors: any[] = [];
  airSearchId: string = '';



  get proposal() {
    return this.selectedOffer && this.selectedOffer.offer && this.selectedOffer.offer.proposal;
  }

  get searchMode() {
    if (!this.airRailSearchParameters) {
      return 'OneWay';
    }
    return this.airRailSearchParameters.searchMode;
  }

  get recommendationsCount() {
    return AirRailSearchStore.recommendationsCount;
  }

  get visibleOffers() {
    return this.offers.slice(0, this.offersVisible);
  }

  get basketId() {
    return AirRailSearchStore.basketId;
  }

  get prevSelectedOffer() {
    return AirRailSearchStore.prevSelectedOffer;
  }

  get showModal() {
    return AirRailSearchStore.showModal;
  }

  get showErrorBE() {
    return AirRailSearchStore.showErrorBE;
  }

  get errorMessageBe() {
    return AirRailSearchStore.errorMessageBe;
  }

  get showError() {
    return AirRailSearchStore.showError;
  }

  get errMessages() {
    return AirRailSearchStore.errMessages;
  }

  get canShowExternalHopper() {
    return 'true' === settings.enableExternalHopper;
  }

  get loading() {
    return AirRailSearchStore.loading;
  }

  get templateOffers() {
    return AirRailSearchStore.templateOffers;
  }

  get airRailSearchParameters() {
    return SearchStore.airRailSearchCurrentState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get offers() {
    return AirRailSearchStore.offers;
  }

  get filters() {
    return AirRailSearchStore.filters;
  }

  get railFilters() {
    return AirRailSearchStore.railFilters;
  }

  get offersVisible() {
    return AirRailSearchStore.offersVisible;
  }

  get renderShowMoreOffers() {
    return this.offers && this.offers.length > this.offersVisible;
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

  get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get filterLoadingView() {
    return AirRailSearchStore.filterLoadingView;
  }

  showHopperPopup() {
    this.showHopperModal = true;
  }

  get searchErrors() {
    return AirRailSearchStore.serverErrors;
  }

  get fareUnavailableCode() {
    return AirRailSearchStore.fareUnavailableErrorParams;
  }

  get offersProposalsList() {
    return AirRailSearchStore.offersProposalsList;
  }

  showMoreOffer() {
    let curr = this.offersVisible;
    curr += 10;
    AirRailSearchStore.setOffersVisible(curr);
    this.$nextTick(this.refreshFlightNumberHashFilter);
  }

  async initView() {
    AirRailSearchStore.loader(true);
    AirRailSearchStore.clear();
    this.searchId = this.$route.params.searchId;
    
    if (this.searchId && this.searchId !== '-') {
      this.checkTemplate();
      if (this.$route.params.searchId !== this.searchId) {
        return;
      }
      AirRailSearchStore.loader(true);
      await AirRailSearchStore.getSearchSession(this.searchId);
      if (this.$route.params.searchId !== this.searchId) {
        return;
      }
      AirRailSearchStore.getOffers(this.searchId);
    }
  }

  async getSearchSession(searchId) {
    try {
      const response = await AirApi.getAirRailSearchSession({
        searchId
      });
      SearchStore.updateAirDefaultState(response.data.request);
      SearchStore.updateAirCurrentState(response.data.request);
      SearchStore.updateSearchModeStatus(response.data.request.searchMode);
      let travellers = {
        travellers: response.data.request.travellers
      };
      SearchStore.updateTravellersDefaultState(travellers);
      AirRailSearchStore.updateSearchStateId(response.data.metadata.stateId);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true, await AirRailSearchStore.translateErrorParams(this.fareUnavailableCode));
    }
  }

  showConfirmationModal(item) {
    AirRailSearchStore.setPrevSelectedOffer(item);
    AirRailSearchStore.setShowModal(true);
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.AddOfferToCart(true);
    window.scrollTo(0, 0);
  }

  GoToBasketWithoutChanges() {
    AirRailSearchStore.stopSearch();
    AirRailSearchStore.setLoadingRestProposals(false);
    router.push({
      name: 'basket',
      params: { id: this.basketId }
    });
  }

  BackToResults() {
    AirRailSearchStore.loader(false);
    AirRailSearchStore.setShowModal(false);
    AirRailSearchStore.setLoadingRestProposals(false);
    AirRailSearchStore.clear();
  }

  BackToCriteria() {
    AirRailSearchStore.clearErrors();
    router.push({
      name: 'search'
    });
  }

  checkTemplate() {
    if (this.templateOffers.length) {
      let templates = this.templateOffers.filter((temp: any) => {
        return temp.ssid === this.searchId;
      });
      AirRailSearchStore.setTemplateOffers(templates);
    }
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    AirRailSearchStore.setFiltersChanging(false);
    AirRailSearchStore.updateFilters([]);
    AirRailSearchStore.updateRailFilters([]);
    AirRailSearchStore.setProvidersErrors([]);
    AirRailSearchStore.updateOffers([]);
    AirRailSearchStore.setCurrentSearchIdFromApi('');
    AirRailSearchStore.updateFilters([]);
    AirRailSearchStore.updateRailFilters([]);
    AirRailSearchStore.setShowErrorBE(false);
    AirRailSearchStore.setShowError(false);
    AirRailSearchStore.setTotalOffers(0);
    AirRailSearchStore.setRecommendationsCount(0);

    this.selectedOffer = null;
    AirRailSearchStore.getDefaultOffersVisible();
    this.initView();
  }

  refreshFlightNumberHashFilter() {
    const currentLegFilters = this.filters;
    if (currentLegFilters) {
      const filterData: any = currentLegFilters.find((f: any) => f.code === 'FLIGHT_NUMBERS');
      if (filterData && this.$refs && this.$refs.airResultRows) {
        (this.$refs.airResultRows as any[]).forEach(element => {
          element.refreshFlightNumberHashState(filterData.values);
        });
      }
      if (filterData && this.$refs && this.$refs.airResultsRowViews) {
        (this.$refs.airResultsRowViews as any[]).forEach(element => {
          element.refreshFlightNumberHashState(filterData.values);
        });
      }
    }
  }

  flightSelected({ legHash, selected }) {
    this.scrollToElem();
    AirRailSearchStore.hashFilterUpdated(
      {
        code: 'FLIGHT_NUMBERS',
        data: {
          legHash,
          selected
        },
        searchId: this.searchId,
      },
    );
    EventBus.$emit('update-hashfilter', this.$refs);
  }

  clearHashFilters() {
    AirRailSearchStore.clearHashFilters();
  }

  async SelectProposal(offer, proposal) {
    this.selectingOffer = true;
    AirRailSearchStore.setShowError(false);
    if (AirRailSearchStore.isSelected) {
      return;
    }
    AirRailSearchStore.select({ offerId: offer.id, proposalId: proposal.id });

    let filteredProposals = offer.offerProposals.proposals.filter(offerProposal => offerProposal.id === proposal.id);
    let offerData = Object.assign({}, offer);
    offerData.offerProposals.selectedProposals = [];
    offerData.offerProposals.selectedProposals = filteredProposals;
    offerData.legs.forEach(leg => {
      leg.flights.map(flight => {
        flight.fareInfo = {
          brandName: filteredProposals[0].brandNames[0],
          cabinClasses: filteredProposals[0].cabinClasses[0],
          fareType: filteredProposals[0].fareType
        };
      });
    });
    this.selectedOffer = {
      offer: {
        id: offerData.id,
        legFlights: offerData.legs,
        proposal: offerData.offerProposals.selectedProposals[0]
      },
      proposal,
    };

    this.airSearchId = offer.sourceSearchId;
    
    this.AddOfferToCart();
    window.scrollTo(0, 0);
  }

  async AddOfferToCart(force = false) {
    this.loadingOffers = true;

    let basketItemsRequest = {
      forceAdd: force,
      recommendationId: this.selectedOffer!.proposal.id,
    };

    AirRailSearchStore.CheckIfCanAddOfferToCart({
      searchId: this.airSearchId,
      basketItemsRequest,
    });
  }

  selectedOfferProposal(data) {
    AirRailSearchStore.setLoadingRestProposals(true);
    this.SelectProposal(data.offer, data.proposal);
  }

  onReceive(data) {
    AirRailSearchStore.setShowTemplateOffersModal(true);
    this.showTemplateOffersModal = data;
  }

  closeTemplatePopup() {
    AirRailSearchStore.setShowTemplateOffersModal(false);
    this.showTemplateOffersModal = false;
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  @Watch('errMessages', { deep: true, immediate: true })
  onErrorShow(val) {
    this.scrollToError();
    if (val && val.length) {
      this.$nextTick(() => {
        AirRailSearchStore.loader(false);
      });
    }
  }

  templateInfo(data) {
    if (data) {
      this.offersProposalsList.forEach(offer => {
        offer.proposals.forEach(proposal => {
          proposal.prepareOfferCheck = false;
        });
      });
    }
  }

  scrollToElem() {
    this.$nextTick(() => {
      LayoutStore.scrollToElementAlways(this.$refs.top as HTMLElement);
    });
  }

  hashRefresh() {
    this.$nextTick(this.refreshFlightNumberHashFilter);
  }

  created() {
    EventBus.$on('prepare-offer', this.onReceive);
    EventBus.$on('template-offer', this.templateInfo);
    EventBus.$on('hash-refresh', this.hashRefresh);
    EventBus.$on('clear-filters', this.clearHashFilters);
    EventBus.$on('selected-offer-proposal', this.selectedOfferProposal);
  }

  beforeDestroy() {
    AirRailSearchStore.stopSearch();
    EventBus.$off('prepare-offer', this.onReceive);
    EventBus.$off('template-offer', this.templateInfo);
    EventBus.$off('hash-refresh', this.hashRefresh);
    EventBus.$off('clear-filters', this.clearHashFilters);
    EventBus.$off('selected-offer-proposal', this.selectedOfferProposal);
  }

}
