import { TSeatMap } from '@/api/trip/basket-air-seats-extras.model';
import { Price } from '@/api/expense/expense.model';

type TPayload = {
  seatMaps: TSeatMap[];
  profileId: string;
  flightId: string;
  row: number;
  column: string;
};

export function getSeatPrice(payload: TPayload): Price | null {
  let price: Price | null = null;
  const seatMap = payload.seatMaps.find((item) => item.flight.id === payload.flightId);
  if (seatMap) {
    seatMap.decks.some((deck) => {
      return deck.cabins.some((item) => {
        const deckRow = item.rows.find((item) => item.rowNumber === payload.row);
        if (!deckRow) {
          return false;
        }
        const deckColumn = deckRow.seats.find((item) => item.column === payload.column);
        if (!deckColumn) {
          return false;
        }
        const offer = deckColumn.offers.find((item) => item.profileId === payload.profileId);
        if (!offer) {
          return false;
        }
        price = offer.price;
        return true;
      });
    });
  }
  return price;
}
