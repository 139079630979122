export enum CancellationMethods {
  void = 'Void',
  refund = 'Refund',
  refundViaBspLink = 'RefundViaBspLink',
}

export enum RefundActionStatuses {
  VerifyRefund = 'AIR_SABRE_VERIFY_REFUND',
  VerifyStatus = 'AIR_SABRE_COUPONS_VERIFY_STATUS',
  VerifyError = 'AIR_SABRE_COUPONS_STATUS_ERROR',
  VerifyConditions = 'AFKLM_CHECK_CANCELLATION_CONDITIONS',
  VerifyScreen = 'AIR_SABRE_GET_REFUND_SCREEN',
  LHGCheckConditions = 'LHG_CHECK_CANCELATION_CONDITIONS',
  AmadeusCheckConditions = 'AMADEUS_CHECK_CANCELLATION_CONDITIONS',
  BACheckConditions = 'BA_CHECK_CANCELLATION_CONDITIONS',
}

export interface RefundConfirmationMessage {
  itemId: string;
  cancellationFee: number;
}

export interface RefundDecisionMessage {
  itemId: string;
  isConfirmed: boolean;
}