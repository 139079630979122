import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  ProfileSyncConfiguration,
  ProfileSyncConfigurationData,
  ProfileSyncStatus,
} from './profile-sync.model';


class ProfileSyncApiClass {
  public getConfiguration(configurationId: string): Promise<AxiosResponse<ProfileSyncConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/profile-sync-configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public createConfiguration(params: ProfileSyncConfigurationData): Promise<AxiosResponse<{ configurationId: string }>> {
    return http.execute({
      path: settings.apiProfile + '/api/profile-sync-configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateConfiguration(configurationId: string, params: ProfileSyncConfigurationData): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profile-sync-configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getEmpty(): Promise<AxiosResponse<ProfileSyncConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/profile-sync-configurations/empty',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public getProfileSyncStatus(profileId: string): Promise<AxiosResponse<ProfileSyncStatus[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles-to-sync/last?profileId={profileId}',
      method: RequestMethod.GET,
    }, { profileId }, {});
  }
}

export const ProfileSyncApi: ProfileSyncApiClass = new ProfileSyncApiClass();