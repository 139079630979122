


















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';

@Component({})
export default class AirFiltersSubintro extends Vue {

  hideMobileFilters() {
    router.push({
      name: this.$route.params.basketId ? 'airModification' : 'air',
    });
  }

}
