































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { CustomFieldModel } from '@/api/custom-fields/custom-fields.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class CustomFieldDefinitions extends BaseTable<CustomFieldModel> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
    sortDesc: false,
    sortCompareOptions: '{sensitivity: \'base\'}'
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: CustomFieldModel | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-custom-fields.cf-definition-name'),
    },
    description: {
      sortable: false,
      label: translate('settings-custom-fields.description'),
    },
    code: {
      sortable: false,
      label: translate('settings-custom-fields.code'),
    },
    type: {
      sortable: false,
      label: translate('settings-custom-fields.type'),
    },
    active: {
      sortable: false,
      label: translate('settings-custom-fields.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get entries() {
    return CustomFieldsStore.customFields;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.entries.length || 0;
  }

  get loadingPage() {
    return CustomFieldsStore.loading;
  }

  displayType(id) {
    const typeFound = CustomFieldsStore.fieldTypes.find(e => {
      return e.id === id;
    });
    return typeFound ? typeFound.label : translate('settings-custom-fields.type-unknown');
  }

  toggleMenu(item) {
    if (item !== this.selected) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    CustomFieldsStore.setFieldData(item);
    let params: any = {
      id: this.currentCompany!.rootCompanyId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'field-definition',
      ...params
    });
  }

  addCustomFieldDefinition() {
    CustomFieldsStore.clearFieldData();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeField() {
    try {
      CustomFieldsStore.setLoading(true);
      await CustomFieldsApi.deleteCustomField(this.selected!.id);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    } finally {
      if (!this.errorsOnPopup.length) {
        CustomFieldsStore.getConfigurationCustomFields({ companyId: router.currentRoute.params.id, configurationId: router.currentRoute.params.configurationId });
        this.removePopup = false;
      }
      CustomFieldsStore.setLoading(false);
    }
  }
}
