























































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import PluginsStore from './plugins.store';
import settings from '@/settings';

@Component({})
export default class PluginsConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  Form: any = {
    name: '',
    providerConfigurations: [
      {
        provider: '',
        isEnabled: false,
        hasErrors: false,
        configurationSettings: [
          {
            settingKey: 'test',
            settingValue: '',
          }
        ]
      }
    ]
  };
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        providerConfigurations: {
          $each: {
            configurationSettings: {
              $each: {
                required: function(this: any, value, vm) {
                  let configurationFound = this.Form.providerConfigurations.find(cfg => {
                    return _.isEqual(cfg.configurationSettings, vm);
                  });
                  return configurationFound && configurationFound.isEnabled ?
                    value.settingValue.length > 0 : true;
                },
                settingValue: {
                  maxLength: maxLength(10),
                }
              }
            }
          }
        }
      }
    };
  }

  get currentConfiguration() {
    return PluginsStore.PluginsConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditPlugins');
  }

  get pluginProviderEnableIntercom() {
    return 'true' === settings.pluginProviderEnableIntercom;
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return PluginsStore.errMessages;
  }

  get showErrMessages() {
    return PluginsStore.showError;
  }

  get loading() {
    return PluginsStore.loading;
  }

  get errors() {
    return PluginsStore.errMessages;
  }
  
  get showError() {
    return PluginsStore.showError;
  }

  get canShowCustomError() {
    return PluginsStore.canShowCustomError;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'plugins-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    PluginsStore.setErrMessages([]);
    PluginsStore.setShowError(false);
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await PluginsStore.addPluginsConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await PluginsStore.updatePluginsConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        configurationId: this.Form.configurationId,
        params: {
          name: this.Form.name,
          providerConfigurations: this.Form.providerConfigurations,
        }
      };
    } else {
      request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        providerConfigurations: this.Form.providerConfigurations,
      };
    }
    
    return request;
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        rootCompanyId: data.rootCompanyId,
        providerConfigurations: data.providerConfigurations,
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        rootCompanyId: data.rootCompanyId,
        providerConfigurations: [],
      };
    }
  }

  async created() {
    if (this.inEditMode) {
      await PluginsStore.getPluginsConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
      this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        providerConfigurations: this.Form.providerConfigurations.filter(group => 'Intercom' !== group.provider || this.pluginProviderEnableIntercom)
      };
    } else {
      await PluginsStore.getEmptyConfiguration();
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
       this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        providerConfigurations: this.Form.providerConfigurations.filter(group => 'Intercom' !== group.provider || this.pluginProviderEnableIntercom)
      };
    }
  }
}
