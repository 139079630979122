
export interface MissedSavingsItem {
  id?: string;
  reasonCode: string;
  displayValue: string;
}

export class MissedSavingsConfiguration {
  rootCompanyId?: string;
  configurationId?: string;
  name: string = '';
  missedSavings: MissedSavingsItem[] = [];

  constructor(data?: any) {
    if (data) {
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.missedSavings !== undefined) {
        this.missedSavings = data.missedSavings;
      }
    }
  }
}