


























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import { HotelSearchStateParams } from './hotel-search.params';
import { TravellersStateParams } from '../travellers.params';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import LayoutStore from '@/modules/layout/layout.store';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';

@Component({
  components: {
    SearchTripTravellers,
  }
})
export default class HotelSearchParamsOnResults extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  @Prop() hotelSearchParameters!: HotelSearchStateParams;

  get templateOffers() {
    return [];
  }

  get checkInDate() {
    return moment(this.hotelSearchParameters.checkInDate, 'YYYY-MM-DD').format(
      'D MMM'
    );
  }

  get checkInDay() {
    return moment(this.hotelSearchParameters.checkInDate, 'YYYY-MM-DD').format(
      'dddd'
    );
  }


  get checkOutDate() {
    return moment(this.hotelSearchParameters.checkOutDate, 'YYYY-MM-DD').format(
      'D MMM'
    );
  }

  get checkOutDay() {
    return moment(this.hotelSearchParameters.checkOutDate, 'YYYY-MM-DD').format(
      'dddd'
    );
  }

  get place() {
    return this.hotelSearchParameters.to
      ? this.hotelSearchParameters.to.displayText
      : '';
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  toggleSidebar() {
    LayoutStore.toggleSidebar();
  }

  @Emit()
  criteria() {}

  @Emit()
  travellers() {}
}
