import moment from 'moment';
import settings from '@/settings';
import { RailLocationModel } from '@/api/home/home.model';

export class TrainSearchStateParams {
  searchMode: string = 'RoundTrip';
  from: RailLocationModel | null = null;
  to: RailLocationModel | null = null;
  departureDate: string = moment()
    .add(1, 'week')
    .format('YYYY-MM-DD');
  returnDate: string = moment()
    .add(1, 'week').add(1, 'days')
    .format('YYYY-MM-DD');
  departureTimeSpan: any = {
    value: [840, 960],
    isSelected: true,
  };
  returnTimeSpan: any = {
    value: [840, 960],
    isSelected: true,
  };
  isForExchange?: boolean[] = [];
  legs: any[] = [];
  enablePackageRates: boolean = false;
  advancedSearchParametersId: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.searchMode !== undefined) {
      this.searchMode = params.searchMode;
    } else {
      this.searchMode = settings.trainSearchMode;
    }
    if (params.from !== undefined) {
      this.from = params.from;
    }
    if (params.to !== undefined) {
      this.to = params.to;
    }
    if (params.departureDate !== undefined) {
      this.departureDate = params.departureDate;
    }
    if (params.returnDate !== undefined) {
      this.returnDate = params.returnDate;
    }
    if (params.departureTimeSpan !== undefined) {
      this.departureTimeSpan.value[0] = params.departureTimeSpan.from;
      this.departureTimeSpan.value[1] = params.departureTimeSpan.to;
    }
    if (params.returnTimeSpan !== undefined) {
      this.returnTimeSpan.value[0] = params.returnTimeSpan.from;
      this.returnTimeSpan.value[1] = params.returnTimeSpan.to;
    }
    if (params.isForExchange !== undefined) {
      this.isForExchange = params.isForExchange;
    }
    if (params.legs !== undefined) {
      this.legs = params.legs;
    }
    if (params.enablePackageRates !== undefined) {
      this.enablePackageRates = params.enablePackageRates;
    }
    if (params.advancedSearchParametersId !== undefined) {
      this.advancedSearchParametersId = params.advancedSearchParametersId;
    }
  }
}