













































































import { Vue, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';

@Component({})
export default class BasketHotelPlaceholder extends Vue {
  overHotelSuggestion: boolean = false;

  get styles() {
    return {
      opacity: this.overHotelSuggestion ? 1 : .5,
    };
  }

  goToHotelSearchResult() {
    BasketStore.getBasketMetadata(this.$route.params.id);
    this.$router.push( {
      name: 'hotel',
      params: { searchId: BasketStore.basket!.hotelSearchId! }
    });
  }

}
