import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import SettingsStore from '@/modules/settings/settings.store';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { CustomFieldDictionariesApi } from '@/api/custom-fields/custom-fields-dictionary.api';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import {
  CustomFieldModel,
  DictionaryModel,
  FieldRequestMessage,
  CustomFieldConfigurationModel,
  CustomFieldDefinitionModel,
  CustomFieldValueType,
  CustomFieldValueTypeTranslation,
  CustomFieldDictionaryDefinitionModel,
  CustomFieldDictionaryItemEntry,
  UpdateFieldMessage,
  CopyFieldConfigurationMessage,
  TravelAssignmentModel,
  ProviderMappingModel,
} from '@/api/custom-fields/custom-fields.model';
import {
  CompanyConfiguration,
} from '@/api/profile/company.model';
import { Permission } from '@/const/permission.enum';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import { CustomFieldTravelAssignmentsApi } from '@/api/custom-fields/custom-fields-travel-assignments.api';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import {CustomFieldsProviderMappingsApi} from '@/api/custom-fields/custom-fields-provider-mappings.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'customFields'
})
class CustomFieldsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  fieldTypes: any[] = [];
  configuration: CustomFieldConfigurationModel = new CustomFieldConfigurationModel();
  fieldDefinition: CustomFieldDefinitionModel = new CustomFieldDefinitionModel();
  dictionaryDefinition: CustomFieldDictionaryDefinitionModel = new CustomFieldDictionaryDefinitionModel();
  dictionaryEntry: CustomFieldDictionaryItemEntry | null = null;
  collectionModes: any = {};
  customFields: CustomFieldModel[] = [];
  dictionaries: DictionaryModel[] = [];
  travelAssignments: TravelAssignmentModel[] = [];
  providerMappings: ProviderMappingModel[] = [];
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  activeTab: string = 'fields';
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get customFieldDefinition() {
    return this.fieldDefinition;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = $handleErrors(error, true);
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setFieldsTab() {
    this.activeTab = 'fields';
  }

  @Mutation
  setDictionariesTab() {
    this.activeTab = 'dictionaries';
  }

  @Mutation
  setTravelAssignmentsTab() {
    this.activeTab = 'travel-assignments';
  }

  @Mutation
  setProviderMappingsTab() {
    this.activeTab = 'provider-mappings';
  }

  @Mutation
  setActiveTab(value) {
    this.activeTab = value;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  setName(value) {
    this.configuration.name = value;
  }

  @Mutation
  setConfiguration(value) {
    this.configuration = new CustomFieldConfigurationModel(value);
  }
  
  @Mutation
  setConfigurationId(value) {
    this.configuration.id = value;
  }

  @Mutation
  setFields(value) {
    this.customFields = value;
  }

  @Mutation
  setDictionaries(value) {
    this.dictionaries = value;
  }

  @Mutation
  setCollectionModes(value) {
    this.collectionModes = value;
  }

  @Mutation
  setFieldData(value) {
    this.fieldDefinition = new CustomFieldDefinitionModel(value);
  }

  @Mutation
  setFieldValueType(value) {
    this.fieldDefinition.valueType = value;
  }

  @Mutation
  clearFieldData() {
    this.fieldDefinition = new CustomFieldDefinitionModel();
  }

  @Mutation
  setDictionaryData(value) {
    this.dictionaryDefinition = new CustomFieldDictionaryDefinitionModel(value);
  }

  @Mutation
  clearDictionaryData() {
    this.dictionaryDefinition = new CustomFieldDictionaryDefinitionModel();
  }

  @Mutation
  setDictionaryItems(value) {
    this.dictionaryDefinition.items = value;
  }

  @Mutation
  addDictionaryItem(value) {
    if (value.id) {
      const index = this.dictionaryDefinition.items.map(e => {
        return e.id;
      }).indexOf(value.id);
      this.dictionaryDefinition.items.splice(index, 1, value);
    } else {
      this.dictionaryDefinition.items.push(value);
    }
  }

  @Mutation
  modifyDictionaryItem(data) {
    this.dictionaryDefinition.items.splice(data.index, 1, data.item);
  }

  @Mutation
  removeDictionaryItem(position) {
    this.dictionaryDefinition.items.splice(position, 1);
  }

  @Mutation
  clearDictionaryItems() {
    this.dictionaryDefinition.items = [];
  }

  @Mutation
  selectDictionaryItem(value) {
    this.dictionaryEntry = value;
  }

  @Mutation
  clearDictionaryItem() {
    this.dictionaryEntry = null;
  }

  @Mutation
  setFieldTypes(data) {
    this.fieldTypes = data;
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setTravelAssignments(items: TravelAssignmentModel[]) {
    this.travelAssignments = items;
  }

  @Mutation
  setProviderMappings(items: ProviderMappingModel[]) {
    this.providerMappings = items;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  clearData() {
    this.setConfiguration(new CustomFieldConfigurationModel());
    this.setFields([]);
    this.setDictionaries([]);
    this.setTravelAssignments([]);
    this.setProviderMappings([]);
  }

  @Action
  async getCustomFieldConfiguration(data: FieldRequestMessage) {
    this.setLoading(true);
    if (data.configurationId) {
      const result = await ProfileConfigurationsApi.getByRootCompanyId(data.companyId, companyConfigurationType.customFieldDefinitionConfiguration, 0, 1, Permission.ReadCustomFieldDefinitionsConfiguration, '');
      if (result && result.data) {
        const configuration = result.data.results.find((e) => {
          return e.id === data.configurationId;
        });
        if (configuration) {
          this.setConfiguration(configuration);
        }
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async getCustomFieldTypes() {
    this.setFields([]);
    try {
      const result = await CustomFieldsApi.getCustomFieldTypes();
      if (result && result.data) {
        const types = result.data.map(e => {
          return {
            ...e,
            value: CustomFieldValueType[e.type],
            label: translate(CustomFieldValueTypeTranslation[e.type])
          };
        }).filter(e => {
          return e.value !== undefined;
        });
        this.setFieldTypes(types);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async getCustomFieldCollectionModes() {
    try {
      const result = await CustomFieldsApi.getCustomFieldCollectionModes();
      if (result && result.data) {
        this.setCollectionModes(result.data);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    }
  }

  @Action
  async createCustomFieldConfiguration(data) {
    try {
      this.setLoading(true);
      const response = await CustomFieldsApi.createCustomFieldConfiguration(data);
      if (response && response.data) {
        const newConfiguration: CompanyConfiguration = {
          id: response.data.configurationId,
          name: this.configuration.name,
          rootCompanyId: this.currentCompany!.rootCompanyId,
          configurationType: companyConfigurationType.customFieldDefinitionConfiguration,
        };
        this.setConfiguration(newConfiguration);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCustomFieldConfiguration(data) {
    try {
      this.setLoading(true);
      await CustomFieldsApi.updateCustomFieldConfiguration(data.configurationId, data.name);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async copyCustomFieldConfiguration(data: CopyFieldConfigurationMessage) {
    try {
      this.setLoading(true);
      await CustomFieldsApi.copyCustomFieldConfiguration(data);
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addCustomFieldDefinition(data) {
    try {
      this.setLoading(true);
      await CustomFieldsApi.createCustomField(data);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-saved'),
        message: translate(
          'settings-custom-fields.definition-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCustomFieldDefinition(data: UpdateFieldMessage) {
    try {
      this.setLoading(true);
      await CustomFieldsApi.updateCustomField({ customFieldId: data.customFieldId, field: data.field });
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-updated'),
        message: translate(
          'settings-custom-fields.definition-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getConfigurationCustomFields(data: FieldRequestMessage) {
    this.setLoading(true);
    this.setCustomError(false);
    try {
      const result = await CustomFieldsApi.getCustomFields(data.companyId, data.configurationId);
      if (result && result.data) {
        this.setFields(result.data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages(error);
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getTravelAssignments(configurationId) {
    this.setLoading(true);
    try {
      const result = await CustomFieldTravelAssignmentsApi.getTravelAssignments(configurationId);
      if (result && result.data) {
        this.setTravelAssignments(result.data);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getProviderMappings(configurationId) {
    this.setLoading(true);

    try {
      const response = await CustomFieldsProviderMappingsApi.getMappings(configurationId);

      if (response && response.data) {
        this.setProviderMappings(response.data);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getConfigurationCustomFieldDictionaries(data: FieldRequestMessage) {
    this.setLoading(true);
    try {
      const result = await CustomFieldDictionariesApi.getCustomFieldDictionaries(data.companyId, data.configurationId);
      if (result && result.data) {
        this.setDictionaries(result.data);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getCustomFieldDictionaryEntries(customFieldDictionaryId: string) {
    this.setLoading(true);
    try {
      const response = await CustomFieldDictionariesApi.getCustomFieldDictionaryItems(customFieldDictionaryId);
      if (response && response.data && response.data.results) {
        this.setDictionaryItems(response.data.results);
      }
    } catch (error) {
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(CustomFieldsStore);
