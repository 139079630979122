import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  TrainAgencyMarkupConfiguration,
} from './train-agency-markup-configuration.model';

class TrainAgencyMarkupConfigurationApiClass {
  public getAgencyMarkupConfiguration(configurationId: string): Promise<AxiosResponse<TrainAgencyMarkupConfiguration>> {
    return http.execute({
      path: settings.apiRailEngine + '/agency-markup-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public createAgencyMarkupConfiguration(request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/agency-markup-configurations/{id}',
      method: RequestMethod.POST,
    }, {
      id: request.configurationId,
    }, request.configuration);
  }

  public updateAgencyMarkupConfiguration(configurationId: string, request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/agency-markup-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, request);
  }
}

export const TrainAgencyMarkupConfigurationApi: TrainAgencyMarkupConfigurationApiClass = new TrainAgencyMarkupConfigurationApiClass();
