import { LoyaltyType } from '@/api/profile/loyalties.model';
import { BasketItemType } from '@/api/trip/trip.model';

export const AccommodationSubscriber = {
  club: 'ACCOR_MODE_CLUB_ACCOR_HOTELS',
  ibis: 'ACCOR_MODE_IBIS_BUSINESS',
  favorite: 'ACCOR_MODE_FAVORITE_GUEST_BUSINESS',
};

export const ValidRoute = {
  AllFrance: 'AllFrance',
  SpecificRoute: 'SpecificRoute',
  SpecificRouteVia: 'SpecificRouteVia',
};

export const ServiceTypes = [
  BasketItemType.Air,
  BasketItemType.Rail,
  BasketItemType.Accommodation,
  BasketItemType.Car,
];

export const LoyaltyTypes = [
  LoyaltyType.Discount,
  LoyaltyType.Loyalty,
];

export const DiscountAirPassList = [
  'France - Europe - North Africa - Israel',
  'West Indies - French Guiana - Reunion',
  'Combined',
];

export const ValidRoutes = [{
  type: ValidRoute.AllFrance,
}, {
  type: ValidRoute.SpecificRoute,
}, {
  type: ValidRoute.SpecificRouteVia,
}];
