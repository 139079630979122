






































































































import { Prop, Component, Watch } from 'vue-property-decorator';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CarRuleConfigurationUpdateModel, CarRuleUpdateModel } from '@/api/travel-policy/travel-policy.model';
import SettingsStore from '@/modules/settings/settings.store';
import TravelPolicyCarStore from '@/modules/settings/travel-policy/travel-policy-car.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import EventBus from '@/services/event-handler';
import { router } from '@/router';
import { translate } from '@/i18n';
import _ from 'lodash';

@Component({})
export default class TravelPolicyCar extends BaseTable<CarRuleConfigurationUpdateModel> {
  @Prop({ default: false }) formPending: boolean = false;
  @Prop({ default: false }) readOnly?;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    rentalCompany: {
      sortable: false,
      label: translate('settings-travel-policy.rental-company'),
    },
    carCategory: {
      sortable: false,
      label: translate('settings-travel-policy.car-category'),
    },
    geographicalAreas: {
      sortable: false,
      label: translate('settings-travel-policy.pick-drop-country-continent'),
    },
    seeAll: {
      sortable: false,
      label: '',
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  $v;
  menuActive: boolean = false;
  rule: CarRuleConfigurationUpdateModel | null = null;
  modifyPopup: boolean = false;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  itemList: CarRuleUpdateModel[] = [];

  get ruleList() {
    return TravelPolicyCarStore.getCarRules;
  }

  get hideNonCompliantRates() {
    return TravelPolicyCarStore.travelPolicy.hideNonCompliantRates;
  }

  set hideNonCompliantRates(value) {
    TravelPolicyCarStore.setHideNonCompliantRates(value);
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  get count() {
    if (!this.results) {
      return 0;
    }

    return this.results.length;
  }

  get loadingList() {
    return TravelPolicyStore.loading;
  }

  rentalCompaniesString(items) {
    if (items.length === 1) {
      return `1 ${translate('settings-travel-policy.rental-company-lowercase')}`;
    } else {
      return `${items.length} ${translate('settings-travel-policy.rental-companies-lowercase')}`;
    }
  }

  categoryString(items) {
    if (items.length === 1) {
      return `1 ${translate('settings-travel-policy.category-lowercase')}`;
    } else {
      return `${items.length} ${translate('settings-travel-policy.categories-lowercase')}`;
    }
  }

  geographicalAreasString(items) {
    if (items.length === 1) {
      return `1 ${translate('settings-travel-policy.lowercase-area')}`;
    } else {
      return `${items.length} ${translate('settings-travel-policy.lowercase-areas')}`;
    }
  }

  modifyRule(item) {
    TravelPolicyCarStore.selectedCarRule(item);
    router.push({
      name: 'car-rule',
      params: {
        configurationId: this.currentConfigurationId,
      },
      query: {
        readOnly: this.readOnly.toString()
      }
    });
  }

  removeRule(selected) {
    if (selected) {
      let filterList = _.filter(this.ruleList, (item) => {
        return item.id !== selected.id;
      });
      TravelPolicyCarStore.setCarRules(filterList);
      this.reload();
    }
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.ruleList,
    };
  }

  toggleRuleMenu(item) {
    if (this.rule !== item) {
      this.rule = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  addRule() {
    TravelPolicyCarStore.selectedCarRule({});
    if (this.currentConfigurationId) {
      router.push({
        name: 'car-rule',
        params: {
          configurationId: this.currentConfigurationId
        },
      });
    } else {
      router.push({
        name: 'car-rule',
      });
    }
  }

  returnToConfigurations() {
    router.push({
      name: 'travel-policy-configurations'
    });
  }

  onReceive() {
    this.reload();
  }

  created() {
    EventBus.$on('refresh-data', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-data', this.onReceive);
  }

  @Watch('ruleList')
  onRuleListChange() {
    this.reload();
  }
}
