










































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { SabrePreEmdAnswer } from '@/api/trip/basket-item.model';
import BasketStore from './basket.store';

@Component({})
export default class BasketSabreTicketingView extends Vue {
  @Prop() item!: any;

  showDetails: boolean = false;
  showConfirmPopup: boolean = false;



  get basketStatus() {
    return BasketStore.status;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get sabreOfferBooking() {
    return BasketStore.sabreOfferBooking;
  }

  get sabrePendingAction() {
    if (
      !!this.item.pendingActions
        .find(action => action.code === 'SABRE_UNCONFIRMED_STATUS')
    ) {
      return true;
    }
    return false;
  }

  get itemHasSabrePendingAction() {
    if (
      this.item.pendingActions &&
      this.item.pendingActions
        .find(obj => obj.code ===  'SABRE_UNCONFIRMED_STATUS') !== undefined
    ) {
      return true;
    }
    return false;
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get passengers() {
    if (!this.sabreOfferBooking || !this.sabreOfferBooking.passengers) {
      return [];
    }
    return this.sabreOfferBooking.passengers;
  }



  userFullName(user) {
    return userFullName(user);
  }

  travellerById(id) {
    return this.travellers.find(traveller => traveller.id === id);
  }

  ancillariesOfSegment(segment, ancillaries) {
    return ancillaries
      .filter(ancillary => {
        return ancillary.flightIds.some(flight => flight === segment.id);
      });
  }

  seatsOfSegment(segment, seats) {
    return seats
      .filter(seat => {
        return seat.segmentId === segment.id;
      });
  }

  notEmptySegments(passenger, segments) {
    return segments.filter(segment => {
      const hasSeats = passenger.seats.some(seat => seat.segmentId === segment.id);
      const hasAncillaries = passenger.ancillaries.some(ancillary => ancillary.flightIds.some(flight => flight === segment.id));

      return hasSeats || hasAncillaries;
    });
  }

  skipSabreTicketing() {
    this.showConfirmPopup = true;
  }

  skipSabreTicketingNow() {
    this.showConfirmPopup = false;
    BasketStore.answerSabreTicketing(SabrePreEmdAnswer.Skip);
  }

  @Watch('sabrePendingAction', {immediate: true})
  onSabrePendingAction(value) {
    if (!value) {
      return;
    }

    if (!this.basketStatus) {
      return;
    }

    const object = this.basketItems.find(i => i.id === this.item.id);
    this.unconfirmedStatusChange(object);
  }

  async unconfirmedStatusChange(item) {
    await BasketStore.getSabreOfferBooking(item.providerReferenceId);
  }
}

