


















































import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

import settings from '@/settings';
import { loadLanguageAsync } from '@/i18n';
import AccountStore from '@/store/account.store';

@Component({})
export default class PreloadError extends Vue {

  get language() {
    if (/^fr\b/.test(navigator.language)) {
      return 'fr';
    } else if (/^it\b/.test(navigator.language)) {
      return 'it';
    } else if (/^en\b/.test(navigator.language)) {
      return 'en';
    } else if (/^pl\b/.test(navigator.language)) {
      return 'pl';
    }

    return navigator.language.substring(0, 2);
  }

  get languageCode() {
    if (-1 < [
      'en',
      'fr',
      'it',
      'pl',
    ].indexOf(this.language)) {
      return this.language;
    }

    return 'en';
  }

  get twoFactorError() {
    return AccountStore.twoFactorError;
  }

  get ipVerificationError() {
    return AccountStore.ipVerificationError;
  }

  get logoutUrl() {
    return settings.logout;
  }

  @Watch('twoFactorError', { immediate: true })
  onTwoFactorError(value) {
    if (!value) {
      return;
    }
    this.updateLanguage();
  }

  @Watch('ipVerificationError', { immediate: true })
  onIPVerificationError(value) {
    if (!value) {
      return;
    }
    this.updateLanguage();
  }

  updateLanguage() {
    AccountStore.setCurrentLanguage(this.languageCode);
    loadLanguageAsync(this.languageCode);
    moment.locale(this.languageCode);
  }

  logoutClick() {
    window.location.href = this.logoutUrl;
  }

  created() {
    this.updateLanguage();
  }

}
