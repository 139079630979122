








































import _ from 'lodash';
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import BasketStore from '@/modules/basket/basket.store';
import { OfferParameter, SupplierParameterOption } from '@/api/trip/basket-item.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import EventBus from '@/services/event-handler';

const updateValue: string = 'update:parameter.value';

@Component({
  name: 'parameter-multiselect'
})
export default class ParameterMultiselect extends Vue {
  @Prop() parameter!: OfferParameter;

  @Validation()
  validationObject() {
    return {
      paramValue: {
        required: requiredIf(() => {
          return this.parameter.isRequired;
        }),
      }
    };
  }

  get isEditable() {
    return BasketStore.status && BasketStore.status.status !== 'Draft';
  }

  get displayText() {
      return this.parameter.displayText ? this.parameter.displayText : this.parameter.key;
  }

  get paramValue() {
    let values = this.parameter.value ? this.parameter.value.split(';') : [];
    return this.parameter.options.filter(opt => values.indexOf(opt.key) >= 0) as SupplierParameterOption[] | null;
  }

  @Emit()
  [updateValue](value) {
    return value;
  }

  set paramValue(value: SupplierParameterOption[] | null) {

    let values = _.map(value, (val) => val.key).join(';');

    this.parameter.value = value ? values : null;
    this[updateValue](this.parameter.value);
    this.$emit('update', this.parameter.value);

    BasketStore.updateItemParameterValue(this.parameter);
  }

  onReceive() {
    this.$v.paramValue!.$touch();
    if (this.$v.paramValue!.$pending || this.$v.paramValue!.$error) {
      BasketStore.setValidCustomParameters(false);
    }
  }

  created() {
    EventBus.$on('custom-parameter-form', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('custom-parameter-form', this.onReceive);
  }
}
