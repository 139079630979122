import { Page } from '../roles/roles.model';
export class ConfigurationRow {
  name!: string;
  id!: string;

  public constructor() {
      this.name = '';
      this.id = '';
  }
}

export interface ConfigurationListResponse {
  page: Page;
  results: ConfigurationRow[];
}

export enum companyConfigurationType {
  airProviderConfiguration =  'AirProviderConfiguration',
  travelPolicyConfiguration = 'TravelPolicyConfiguration',
  sabreQueuesConfiguration = 'SabreQueuesConfiguration',
  approvalWorkflowConfiguration = 'ApprovalWorkflowConfiguration',
  NegoFareConfiguration = 'NegoFareConfiguration',
  customFieldDefinitionConfiguration = 'CustomFieldsConfiguration',
  carProviderConfiguration = 'CarProviderConfiguration',
  projectsConfiguration = 'ProjectsConfiguration',
  expensePoliciesConfiguration = 'ExpensePoliciesConfiguration',
  accommodationProviderConfiguration = 'AccommodationProviderConfiguration',
  railProviderConfiguration = 'RailProviderConfiguration',
  backOfficeConfiguration = 'BackOfficeConfiguration',
  pnrRemarksConfiguation = 'PnrRemarksConfiguration',
  lodgeCardConfiguration = 'lodgeCardConfiguration',
  paymentMethodConfiguration = 'PaymentMethodConfiguration',
  themeConfiguration = 'ThemeConfiguration',
  profileSyncConfiguration = 'ProfileSyncConfiguration',
  feesConfiguration = 'FeesConfiguration',
  agencyMarkupConfiguration = 'AgencyMarkupConfiguration',
  systemMarkupConfiguration = 'SystemMarkupConfiguration',
  pluginsConfiguration = 'PluginsConfiguration',
  securityConfiguration = 'SecurityConfiguration',
  paymentGatewaysConfiguration = 'PaymentGatewaysConfiguration',
  notificationConfiguration = 'NotificationConfiguration',
  clientEmailNotification = 'ClientEmailNotification',
  fareMappingConfiguration = 'FareMappingConfiguration',
  currencyConfiguration = 'CurrencyConfiguration',
  displayPreferencesConfiguration = 'DisplayPreferencesConfiguration',
  missedSavingsConfiguration = 'MissedSavingsConfiguration',
  virtualCardConfiguration = 'VirtualCardConfiguration',
  delayTicketingConfiguration = 'DelayTicketingConfiguration',
  backOfficeCustomFieldsConfiguration = 'BackOfficeCustomFieldsConfiguration',
}