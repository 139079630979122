import DelayTicketing from '@/modules/settings/delay-ticketing/DelayTicketing.vue';
import DelayTicketingConfigurations from './delay-ticketing-configurations.route';
import DelayTicketingConfiguration from './delay-ticketing-configuration.route';

export default {
  path: 'delay-ticketing',
  component: DelayTicketing,
  displayName: 'settings-sidebar.delay-ticketing',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDelayTicketing',
    icon: 'more_time',
    breadCrumb: 'Delay ticketing configuration',
    parentName: 'delay-ticketing-configurations',
  },
  children: [
    DelayTicketingConfigurations,
    DelayTicketingConfiguration,
  ],
};