






























import { Vue, Component } from 'vue-property-decorator';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { WonderSearchApi } from '@/api/wonder-search/wonder-search.api';

@Component({})
export default class JourneyAssistantWidget extends Vue {
  conversationId: string = '';
  
  get redirectUrl() {
    return sanitizeUrl('/journey-assistant/' + this.conversationId);
  }

  async created() {
    try {
      const response = await WonderSearchApi.getConversationId(undefined);

      this.conversationId = response.data.conversationId;
    } catch (error) {
      
    }
  }
}

