




















































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import EventBus from '@/services/event-handler';

import BasketStore from './basket.store';
import BasketItem from './BasketItem.vue';
import BasketRailTravellerDiscountCodes from './BasketRailTravellerDiscountCodes.vue';

@Component({
  components: {
    BasketItem,
    BasketRailTravellerDiscountCodes,
  }
})
export default class BasketRailDiscountCodes extends Vue {
  @Prop() item!: any;

  selectedJourneyId: string = '';
  selectedCoach: any = null;
  travellerPicked: any = null;
  availableItems = [
    'Table',
    'Luggage',
    'Exit',
    'Window',
    'Bulkhead',
    'Lavatory',
    'LargeSeat',
    'NormalSeat',
  ];
  showTooltip: boolean = false;
  tooltipInfo: any = {};

  get isBasketRailDiscountCodesLoading() {
    return BasketStore.railDiscountCodesLoading;
  }

  get railDiscountCodes() {
    return BasketStore.railDiscountCodes;
  }

  get basket() {
    return BasketStore.basket;
  }

  get travellers() {
    return this.basket!.travellers
      .filter(traveller => {
        return this.railDiscountCodes.find(item => item.id === traveller.id);
      })
      .map(traveller => {
        const discountCodes = this.railDiscountCodes.find(item => item.id === traveller.id);
        let codes: any = {
          codes: {},
        };

        if (discountCodes) {
          codes.codes = discountCodes;
        }
        return {
          ...traveller,
          ...codes,
        };
      });
  }

  get currentWizardStep() {
    return BasketStore.wizardSteps[BasketStore.bookingStep - 1];
  }

  get errors() {
    return BasketStore.railDiscountCodesLoadingErrors;
  }

  get saveErrors() {
    return BasketStore.railDiscountCodesSaveErrors;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  onClearRailForwardCodeError(travId) {
    let travFound = BasketStore.railDiscountCodes.find(trav => {
      return trav.id === travId;
    });
    if (travFound) {
      let codeObject = {
        ...travFound,
        forwardDiscountCodeMessage: null,
        forwardDiscountStatus: null,
      };
      
      BasketStore.setTravellersRailDiscountCodeObject({ codeObject, travellerId: travId });
    }
  }

  onClearRailReturnCodeError(travId) {
    let travFound = BasketStore.railDiscountCodes.find(trav => {
      return trav.id === travId;
    });
    if (travFound) {
      let codeObject = {
        ...travFound,
        returnDiscountCodeMessage: null,
        returnDiscountCodeStatus: null,
      };
      
      BasketStore.setTravellersRailDiscountCodeObject({ codeObject, travellerId: travId });
    }
  }

  @Watch('bookingStep', { immediate: true })
  onBookingStepChange() {
    BasketStore.loadRailDiscountCodes();
  }

  created() {
    EventBus.$on('clearRailForwardCodeError', this.onClearRailForwardCodeError);
    EventBus.$on('clearRailReturnCodeError', this.onClearRailReturnCodeError);
  }

  beforeDestroy() {
    BasketStore.setRailDiscountCodes([]);
    EventBus.$off('clearRailForwardCodeError', this.onClearRailForwardCodeError);
    EventBus.$off('clearRailReturnCodeError', this.onClearRailReturnCodeError);
  }
}
