import { render, staticRenderFns } from "./GeneralPreferencesPopup.vue?vue&type=template&id=8dc57c20"
import script from "./GeneralPreferencesPopup.vue?vue&type=script&lang=ts"
export * from "./GeneralPreferencesPopup.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports