

























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import formatTime from '@/filters/format-time.filter';

import { BasketApi } from '@/api/trip/basket.api';

@Component({})
export default class HotelCancellationPolicy extends Vue {
  @Prop() itemId!: string;

  errors: any[] = [];
  message: any = {};
  loaded: boolean = false;

  hideForm() {
    this.$emit('close');
  }

  dateFilter(date) {
    return moment.parseZone(date).format('Do MMMM YYYY ') + formatTime(date) +   ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  async confirm() {
    try {
      const response = await BasketApi.confirmItemCancel(this.itemId);
      this.$emit('confirm');
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  async loadData() {
    this.message = '';
    try {
      const response = await BasketApi.getItemCancellationPolicy(this.itemId);
      this.message = response.data;
      this.loaded = true;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  mounted() {
    this.loadData();
  }
}
