import { AirLine, CountryContinentModel, GeoAreaModel } from '@/api/dictionary/dictionary.model';

export interface ConfigurationName {
  name: string;
}

export interface City {
  id: string;
  name: string;
}

export interface Country {
  code: string;
  name: string;
}

export interface RentalCompany {
  code: string;
  name: string;
}

export interface FlightRuleRoutingModel {
  from: FlightRuleRoutingSingleModel;
  to: FlightRuleRoutingSingleModel;
}

export interface FlightRuleRoutingSingleModel {
  type: string | number;
  code: string;
}

export interface FlightRuleCreateModel {
  id: string;
  maxPrice: number;
  priceCurrency: string;
  rulePriority: number;
  ruleLegTime: number | string;
  ruleLegTimeOperator: string;
  ruleAirlines: string[];
  ruleCabinClasses: CabinClass[];
  status?: string;
  distributionChannelTypes?: number[];
  distributionChannels?: any[];
  tripTypes: number[] | null;
  airlineTypes: number[] | null;
  routings: FlightRuleRoutingModel[] | null;
  geographicalAreas: FlightRuleRoutingModel[] | null;
  travellerCategories: string[];
}

export interface CarRuleCreateModel {
  id: string;
  rulePriority: number;
  rentalCompanies: string[];
  carCategories: string[];
  geographicalAreas: CountryContinentModel[];
  travellerCategories: string[];
  status?: string;
}

export interface AccomodationRuleCreateModel {
  id: string;
  countries: Country[];
  cities: City[];
  geoAreas: GeoAreaModel[];
  rulePriority: number;
  priceAmount: number;
  priceCurrency: string;
  isRefundable: boolean | null;
  mealTypes: string[];
  categories: string[];
  numberOfPeopleInRoom: number[];
  travellerCategories: string[];
  status?: string;
  providers: string[];
}

export interface FlightRuleConfigurationCreateModel {
  configurationName: string;
  rootCompanyId: string;
  flightRules: FlightRuleCreateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface CarRuleConfigurationCreateModel {
  configurationName: string;
  rootCompanyId: string;
  carRules: CarRuleCreateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface AccomodationRuleConfigurationCreateModel {
  configurationName: string;
  rootCompanyId: string;
  accomodationRules: AccomodationRuleCreateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface FlightRuleUpdateModel {
  status: string;
  id: string;
  rulePriority: number;
  ruleLegTime: number;
  ruleLegTimeOperator: string;
  ruleLegTimeComputed: string;
  ruleAirLines: string[];
  ruleCabinClasses: CabinClass[];
}
export interface FlightRuleConfigurationUpdateModel {
  configurationName: string;
  rootCompanyId: string;
  flightRules: FlightRuleUpdateModel[];
}

export interface CarRuleUpdateModel {
  id: string;
  rulePriority: number;
  rentalCompany: string[];
  carCategories: string[];
  geographicalAreas: CountryContinentModel[];
}

export interface CarRuleConfigurationUpdateModel {
  configurationName: string;
  rootCompanyId: string;
  carRules: CarRuleUpdateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface AccomodationRuleModel {
  id: string;
  rulePriority: number;
  priceAmount: number | null;
  priceCurrency: string;
  isRefundable: boolean | null;
  categories: string[];
  countries: Country[];
  cities: City[];
  providers: string[];
}

export interface AccomodationRuleConfigurationUpdateModel {
  configurationName: string;
  rootCompanyId: string;
  accomodationRules: AccomodationRuleModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface FlightRuleFromDbModel {
  id: string;
  rulePriority: number;
  ruleLegTime: number;
  ruleLegTimeOperator: string;
  ruleLegTimeComputed: string;
  ruleAirLines: AirLine[];
  ruleCabinClasses: CabinClass[];
}
export interface FlightRuleConfigurationFromDbModel {
  configurationId: string;
  configurationName: string;
  rootCompanyId: string;
  flightRules: FlightRuleFromDbModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface CarRuleModel {
  id: string;
  rulePriority: number;
  rentalCompany: RentalCompany[];
  carCategories: string[];
  geographicalAreas: CountryContinentModel[];
}
export interface CarRuleConfigurationModel {
  configurationId: string;
  configurationName: string;
  rootCompanyId: string;
  carRules: CarRuleModel[];
}

export interface AccomodationRuleConfigurationModel {
  configurationId: string;
  configurationName: string;
  rootCompanyId: string;
  accomodationRules: AccomodationRuleModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface TravelPolicySimplyConfigutationIdModel {
  configurationId: string;
}
export interface FlightRuleCreateConfigurationMessage extends FlightRuleConfigurationCreateModel {
}

export interface FlightRuleUpdateConfigurationMessage extends FlightRuleConfigurationUpdateModel {
}

export enum CabinClass {
  Economy = 'Economy',
  EconomyPremium = 'EconomyPremium',
  BusinessClass = 'BusinessClass',
  FirstClass = 'FirstClass',
}

export interface ICabinClassOption {
  label: string;
  name?: CabinClass;
  value: CabinClass;
}

export enum SpeedTrainType {
  Normal = 0,
  HighSpeed = 1
}

export enum RailGeoPointType {
  Station = 0,
  City = 1,
  Country = 2,
}

export enum RailProviderType {
  Unknown = 0,
  Fake = 1,
  Ntv = 2,
  Trenitalia = 3,
  Sncf = 4,
  Ouigo = 5,
  Benerail = 6,
}

export interface RailRoutingPoint {
  type: RailGeoPointType;
  id: string;
}

export interface RailRoutingPointUi {
  type: RailGeoPointType;
  id: string;
  
}

export interface RailRouting {
  from: RailRoutingPoint | null;
  to: RailRoutingPoint | null;
}

export interface RailRoutingUi {
  from: RailRoutingPointUi;
  to: RailRoutingPointUi;
}

export interface RailTicketClass {
  class: string;
  provider: string;
}

export interface RailRuleCreateModel {
  id: string;
  maxPrice: number;
  maxPriceCurrency: string;
  ruleLegTime: number | string;
  ruleLegTimeOperator: string;
  status?: string;
  rulePriority: number;
  trainTypes: SpeedTrainType[];
  tripTypes: string[];
  routings: RailRouting[] | null;
  travellerCategories: string[];
  providers: RailProviderType[];
  ticketsClasses: RailTicketClass[];
}

export interface RailRuleConfigurationCreateModel {
  configurationName: string;
  rootCompanyId: string;
  railRules: RailRuleCreateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

export interface RailRuleConfigurationUpdateModel {
  configurationName: string;
  rootCompanyId: string;
  railRules: RailRuleCreateModel[];
  hideNonCompliantRates: boolean;
  isPostSalesEnabledInNonCompliantBooking: boolean;
}

