
































import { Vue, Prop, Component } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';

import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';

export default class BasketTravellersSecurityDataMissing extends Vue {

  get travellers() {
    return SearchStore.travellersSearch.travellers;
  }

  async mounted() {
    await BasketStore.updateBasket();
  }
  
  userFullName(user) {
    return userFullName(user);
  }

  getCodeTranslation(code) {
    const value = searchConst.guestTravellerOptions.find(item => item.code === code);

    if (!value) {
      return '';
    }

    return translate(value.label);
  }
}
