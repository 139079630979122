import { Traveller } from '@/api/trip/basket.model';
import { RailLocationModel } from '@/api/home/home.model';
import { Metadata, Price, Recommendation, SelectedLeg } from './train-search.model';

export interface PostSalesOptionsParams {
  railTripItemId: string;
  supplier: string;
  travellers: Traveller[];
}

export enum PostSalesOptions {
  ChangeBooking = 'ChangeBooking',
  EconomyUpgrade = 'EconomyUpgrade',
  ModifyTravel = 'ModifyTravel',
  ModifyTraveller = 'ModifyTraveller',
}

export enum RailExchangeType {
  Date = 'Date',
  Fare = 'Fare',
}

export interface PostSaleLegInformation {
  legNumber: number;
  availablePostSales?: PostSalesOptions[];
  isExchangable?: boolean;
  from: RailLocationModel;
  to: RailLocationModel;
  date: string;
  departureTimeSpan?: PostSaleTimeSpan;
}

export interface PostSaleTimeSpan {
  from: number;
  to: number;
}

export interface ExchangeTrainLeg {
  legNumber: number;
  isForExchange: boolean;
  from: RailLocationModel;
  to: RailLocationModel;
  date: string;
}

export interface PostSalesOptionsResponse {
  parentOrderId: string;
  railTripItemId: string;
  travellers: Traveller[];
  supplier: string;
  availablePostSales: PostSalesOptions[];
  legs: PostSaleLegInformation[];
}

export interface SncfPostSaleServiceItemJourneySegment {
  segmentId: string;
  exchangeAvailable: boolean;
  departureDate: string;
  departureDateDisplay: string;
  origin: any;
  destination: any;
}

export interface SncfPostSaleServiceItemJourney {
  legNumber: number;
  segments: SncfPostSaleServiceItemJourneySegment[];
}

export interface SncfPostSaleServiceItem {
  serviceItemId: string;
  marketingCarrierRef: string;
  partialExchangeAvailable: boolean;
  totalExchangeAvailable: boolean;
  journeys: SncfPostSaleServiceItemJourney[];
}

export interface SncfPostSaleOptionsResponse {
  id: string;
  parentOrderId: string;
  railTripItemId: string;
  travellers: Traveller[];
  supplier: string;
  serviceItems: SncfPostSaleServiceItem[];
  errorCode: string;
  canContinue: boolean;
}

export interface InitRailExchangeSearchMessage {
  type: RailExchangeType;
  parentOrderId: string;
  railTripItemId: string;
  travellers: Traveller[];
  supplier: string;
  legs: ExchangeTrainLeg[];
}

export interface RailExchangeSessionForm {
  metadata: Metadata;
  request: InitRailExchangeSearchMessage;
  selectedLegs: SelectedLeg[];
}

export interface SelectedFare {
  segmentKey: string;
  fareId: string;
}

export interface RailSelectedLegMessage {
  legNumber: number;
  recommendationId: string;
  fares: SelectedFare[];
}

export interface RailSelectedLegResponse {
  legNumber: number;
}

export interface RailPriceDetail {
  message: string;
  type: string;
  price: Price;
}

export interface RailModificationPricing {
  isAllowed: boolean;
  message: string;
  validationMessages: string[];
  recommendations: Recommendation[];
  totalValue: Price;
  missingAmount: Price;
  totalPenalties: Price;
  previousTotalValue: Price;
  previousTotalNetValue: Price;
  priceBreakdown: RailPriceDetail;
}
