

































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import FeesConfigurationStore from './fees-configuration.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import FeesListAir from './FeesListAir.vue';
import FeesListRail from './FeesListRail.vue';
import FeesListHotel from './FeesListHotel.vue';

const normalizeRulesList = (rule, index) => {
  let entry = { ...rule };
  entry.order = index;
  delete entry.id;
  delete entry.status;

  return entry;
};

@Component({
  components: {
    FeesListAir,
    FeesListRail,
    FeesListHotel,
  }
})
export default class FeesConfiguration extends Vue {
  $v;
  imagesConst: string = '/assets/img/loader/1.gif';
  reallyNewPath: boolean = false;
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
      disabled: false,
    },
    {
      value: 'Rail',
      name: translate('settings-travel-policy.rail'),
      disabled: false,
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation'),
      disabled: false,
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
      disabled: true,
    }
  ];

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      }
    };
  }

  get loading() {
    return FeesConfigurationStore.Loading;
  }

  get trainErrors() {
    return FeesConfigurationStore.trainErrors;
  }

  get errMessages() {
    return FeesConfigurationStore.errMessages;
  }

  get saveErrors() {
    return FeesConfigurationStore.saveErrors.map(item => {
      return {
        message: translate('settings-fees.save-failure') + item.message,
      };
    });
  }

  get trainSaveErrors() {
    return FeesConfigurationStore.trainSaveErrors.map(item => {
      return {
        message: translate('settings-fees.train-save-failure') + item.message,
      };
    });
  }

  get hotelSaveErrors() {
    return FeesConfigurationStore.hotelSaveErrors.map(item => {
      return {
        message: translate('settings-fees.hotel-save-failure') + item.message,
      };
    });
  }

  get showMessages() {
    return FeesConfigurationStore.showError;
  }

  get links() {
    return this.navLinks;
  }

  get configurationId() {
    return this.$route.params.configurationId || '';
  }

  get storeConfigurationId() {
    return FeesConfigurationStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isFromManage() {
    return FeesConfigurationStore.isFromManage;
  }

  get currentConfiguration() {
    return FeesConfigurationStore.CurrentConfiguration;
  }

  get currentConfigurationName() {
    return FeesConfigurationStore.configurationName;
  }

  get configIsEdit() {
    return this.storeConfigurationId && this.storeConfigurationId !== 'new' ? !!this.storeConfigurationId : false;
  }

  set currentConfigurationName(value) {
    FeesConfigurationStore.setConfigurationName(value);
  }

  get selectedTab() {
    return FeesConfigurationStore.selectedTab;
  }

  get canShowCustomError() {
    return FeesConfigurationStore.canShowCustomError;
  }

  changeSelectedTab(item) {
    if (item.disabled) {
      return;
    }
    FeesConfigurationStore.setSelectedTab(item.value);
  }

  backToConfigurationsList() {
    FeesConfigurationStore.setIsFromManage(false);
    router.push({
      name: 'fees-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  async saveFeesConfigurations() {
    this.$v.currentConfigurationName.$touch();
    if (this.$v.$error) {
      return;
    }
    this.reallyNewPath = false;
    FeesConfigurationStore.setLoading(true);
    FeesConfigurationStore.setTrainLoading(true);
    FeesConfigurationStore.setHotelLoading(true);
    await this.saveFeesAirConfiguration();
    await this.saveFeesTrainConfiguration();
    await this.saveFeesHotelConfiguration();

    if (this.reallyNewPath) {
      router.push({
        name: 'fees-configuration',
        params: {
          id: this.$route.params.id,
          configurationId: this.storeConfigurationId
        }
      });
    }
    
    FeesConfigurationStore.setLoading(false);
    FeesConfigurationStore.setTrainLoading(false);
    FeesConfigurationStore.setHotelLoading(false);

    if (FeesConfigurationStore.errors.length || FeesConfigurationStore.trainErrors.length || FeesConfigurationStore.hotelErrors.length) {
      return;
    }

    EventBus.$emit('show-toast', {
      type: translate('common.success'),
      title: translate('settings-fees.configuration-saved'),
      message: translate('settings-fees.configuration-saved-fees')
    });
  }

  async saveFeesAirConfiguration() {
    FeesConfigurationStore.setErrors([]);

    let request;
    let flightRules = FeesConfigurationStore.rulesList.map(normalizeRulesList);
    const isEnabled = FeesConfigurationStore.isAirConfigurationEnabled;

    if (this.currentCompany && (!this.storeConfigurationId || this.storeConfigurationId === 'new')) {
      try {
        request = {
          rootCompanyId: this.currentCompany.rootCompanyId,
          name: this.currentConfigurationName,
          isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules: flightRules,
        };
        await FeesConfigurationStore.createAirConfiguration(request);
        if (this.storeConfigurationId !== '00000000-0000-0000-0000-000000000000') {
          this.reallyNewPath = true;
        }
      } catch (error) {
        FeesConfigurationStore.setErrors(this.$handleErrors(error, true));
        FeesConfigurationStore.setShowError(true);
      }
    } else {
      try {
        request = {
          name: this.currentConfigurationName,
          isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules: flightRules,
        };

        await FeesConfigurationStore.updateAirConfiguration({ configurationId: this.storeConfigurationId, params: request });
      } catch (error) {
        FeesConfigurationStore.setErrors(this.$handleErrors(error, true));
        FeesConfigurationStore.setShowError(true);
      }
    }
  }

  async saveFeesTrainConfiguration() {
    FeesConfigurationStore.setTrainErrors([]);
    const feesRules = FeesConfigurationStore.trainRulesList.map(normalizeRulesList);
    const isEnabled = FeesConfigurationStore.isTrainConfigurationEnabled;

    if (this.currentCompany && (this.reallyNewPath || FeesConfigurationStore.trainCustomError)) {
      await FeesConfigurationStore.createTrainConfiguration({
        configurationId: this.storeConfigurationId,
        rootCompanyId: this.currentCompany.rootCompanyId,
        name: this.currentConfigurationName,
        isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
        isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
        isEnabled: isEnabled,
        feesRules,
      });
    } else {
      await FeesConfigurationStore.updateTrainConfiguration({
        configurationId: this.storeConfigurationId,
        data: {
          name: this.currentConfigurationName,
          isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules,
        },
      });
    }
  }

  async saveFeesHotelConfiguration() {
    FeesConfigurationStore.setHotelErrors([]);
    const feesRules = FeesConfigurationStore.hotelRulesList.map(normalizeRulesList);
    const isEnabled = FeesConfigurationStore.isHotelConfigurationEnabled;

    if (this.currentCompany && (this.reallyNewPath || FeesConfigurationStore.hotelCustomError)) {
      await FeesConfigurationStore.createHotelConfiguration({
        configurationId: this.storeConfigurationId,
        rootCompanyId: this.currentCompany.rootCompanyId,
        name: this.currentConfigurationName,
        isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
        isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
        isEnabled: isEnabled,
        feesRules,
      });
    } else {
      await FeesConfigurationStore.updateHotelConfiguration({
        configurationId: this.storeConfigurationId,
        data: {
          name: this.currentConfigurationName,
          isVisibleToCustomer: this.currentConfiguration.isVisibleToCustomer,
          isFeeAdjustableToTravelPolicy: this.currentConfiguration.isFeeAdjustableToTravelPolicy,
          isEnabled: isEnabled,
          feesRules,
        },
      });
    }
  }

  @Watch('$route.params', { deep: true })
  onParamsChange() {
    this.loadAll();
  }

  async loadAll() {
    await Promise.all([
      FeesConfigurationStore.getAirConfiguration(this.configurationId),
      FeesConfigurationStore.getTrainConfiguration(this.configurationId),
      FeesConfigurationStore.getHotelConfiguration(this.configurationId),
      FeesConfigurationStore.getConfigurationName({ companyId: this.$route.params.id, configurationId: this.configurationId }),
    ]);
  }

  async created() {
    FeesConfigurationStore.clearErrors();
    if ((this.configurationId && this.configurationId !== 'new' && this.configurationId !== '00000000-0000-0000-0000-000000000000') && !this.isFromManage) {
      this.loadAll();
      FeesConfigurationStore.setConfigurationId(this.configurationId);
    }
  }
}

