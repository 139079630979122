









































































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { translate } from '@/i18n';
import { AirNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import { PaymentMethodConfiguration } from '@/api/air-engine/payment-methods-configuration.model';
import SettingsStore from '@/modules/settings/settings.store';
import PaymentMethodsStore from './payment-methods.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class PaymentMethodsAir extends BaseTable<PaymentMethodConfiguration> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: PaymentMethodConfiguration | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    provider: {
      sortable: false,
      label: translate('settings-payment-methods.provider'),
    },
    paymentMethods: {
      sortable: false,
      label: translate('settings-payment-methods.provider-payments'),
      tdClass: 'mw-200',
    },
    paymentGateway: {
      sortable: false,
      label: translate('settings-payment-methods.payment-gateway'),
    },
    status: {
      sortable: false,
      label: '',
      tdClass: 'w-60',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    details: {
      sortable: false,
      label: ''
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  providerOptions = [
    AirNegoFareConfigurationSupplierEnum.Unknown,
    AirNegoFareConfigurationSupplierEnum.Sabre,
    AirNegoFareConfigurationSupplierEnum.Lufthansa,
    AirNegoFareConfigurationSupplierEnum.AmericanAirlines,
    AirNegoFareConfigurationSupplierEnum.BritishAirways,
    AirNegoFareConfigurationSupplierEnum.Emirates,
  ];

  get activeTab() {
    return PaymentMethodsStore.selectedTab;
  }

  get entries() {
    return PaymentMethodsStore.paymentList.filter(item => {
      return item.succeeded !== false;
    });
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return PaymentMethodsStore.currentConfiguration;
  }

  get configurationId() {
    return router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : undefined;
  }

  get count() {
    return this.entries ? this.entries.length : 0;
  }

  get errors() {
    return PaymentMethodsStore.paymentErrMessages;
  }
  
  getSupplierString(supplier) {
    return SupplierName(supplier);
  }

  getMethodsString(item) {
    let result: any = [];
    if (item.isTfPayEnabled) {
      let entry: any = `${translate('settings-payment-methods.tfpay-central')}${item.defaultPaymentMethod === 'TfPay' ? ' (' + translate('common.default') + ')' : ''}`;
      result.push(entry);
    }
    if (item.isCardPaymentEnabled) {
      let entry: any = `${translate('settings-payment-methods.user-card')}${item.defaultPaymentMethod === 'PaymentCard' ? ' (' + translate('common.default') + ')' : ''}`;
      if (item.defaultPaymentMethod === 'PaymentCard') {
        result.unshift(entry);
      } else {
        result.push(entry);
      }
    }
    if (item.isCentralPaymentEnabled) {
      let entry: any = `${translate('settings-payment-methods.central-payment') }${item.defaultPaymentMethod === 'CentralPayment' ? ' (' + translate('common.default') + ')' : ''}`;
      if (item.defaultPaymentMethod === 'CentralPayment') {
        result.unshift(entry); 
      } else {
        result.push(entry);
      }
    }
    if (item.isLodgeCardPaymentEnabled) {
      let entry: any = `${translate('settings-payment-methods.lodge-card')}${item.defaultPaymentMethod === 'LodgeCard' ? ' (' + translate('common.default') + ')' : ''}`;
      if (item.defaultPaymentMethod === 'LodgeCard') {
        result.unshift(entry);
      } else {
        result.push(entry);
      }
    }
    if (item.isPreRegisteredCardEnabled) {
      let entry: any = `${translate('settings-payment-methods.preregistered-card')}${item.defaultPaymentMethod === 'PreRegisteredCard' ? ' (' + translate('common.default') + ')' : ''}`;
      if (item.defaultPaymentMethod === 'PreRegisteredCard') {
        result.unshift(entry);
      } else {
        result.push(entry);
      }
    }
    if (item.isVirtualCardPaymentEnabled) {
      let entry: any = `${translate('settings-payment-methods.virtual-card-payment')}${item.defaultPaymentMethod === 'VirtualCard' ? ' (' + translate('common.default') + ')' : ''}`;
      if (item.defaultPaymentMethod === 'VirtualCard') {
        result.unshift(entry);
      } else {
        result.push(entry);
      }
    }

    return result.join(', ');
  }

  getPaymentGatewayString(item) {
    let result: any = '';
    if (item && item.paymentMethodConfiguration.regular.paymentGatewayOption) {
      result = `${translate('settings-payment-methods.enabled')}${item.paymentMethodConfiguration.regular.paymentGatewayOption === 'TotalBookingAmount' ? ' (' + translate('settings-payment-methods.total-amount') + ')' : ' (' + translate('settings-payment-methods.only-fee') + ')'}`;
    } else {
      result = translate('settings-payment-methods.disabled');
    }

    return result;
  }

  toggleMenu(item) {
    if (!this.selected || this.selected.id !== item.id) {
      this.selected = item;
      this.menuActive = true;
    } else if (this.selected && this.selected.id === item.id) {
      this.menuActive = false;
      this.selected = null;
    } else {
      this.selected = item;
      this.menuActive = !this.menuActive;
    }
  }

  addPayment() {
    PaymentMethodsStore.setSelectedPayment({});
    let readOnly = 'false';
    if (!this.$hasAccess('WritePaymentMethodConfiguration')) {
      readOnly = 'true';
    }
    if (this.configurationId) {
      router.push({
        name: 'air-configuration',
        params: {
          configurationId: this.configurationId,
        },
        query: {
          readOnly: readOnly
        }
      });
    } else {
      router.push({
        name: 'air-configuration',
        params: {
          configurationId: 'new',
        },
        query: {
          readOnly: readOnly
        }
      });
    }
  }

  editPayment(data) {
    PaymentMethodsStore.setSelectedPayment(data);
    let readOnly = 'false';
    if (!this.$hasAccess('WritePaymentMethodConfiguration')) {
      readOnly = 'true';
    }
    if (this.configurationId) {
      router.push({
        name: 'air-configuration',
        params: {
          configurationId: this.configurationId,
        },
        query: {
          readOnly: readOnly
        }
      });
    } else {
      router.push({
        name: 'air-configuration',
        params: {
          configurationId: 'new',
        },
        query: {
          readOnly: readOnly
        }
      });
    }
  }

  onRemovePopupClose() {
    this.removePopup = false;
    this.selected = null;
    this.menuActive = false;
  }

  removeCode() {
    PaymentMethodsStore.removeSelectedPayment(this.selected);
    this.closeRemovePopup();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.onRemovePopupClose();
  }
}

