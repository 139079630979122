



































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import { Legs } from '@/api/air-engine/air-search.model';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventBus from '@/services/event-handler';
import SearchStore from '../search.store';
import { HomeApi } from '@/api/home/home.api';
import { AirLocationModel, searchModes, serviceClassEnum } from '@/api/home/home.model';
import { translate } from '@/i18n';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import DictionaryStore from '@/store/dictionary.store';
import SearchConst from '@/const/search.const';

@Component({
  components: {
    HeightTransition,
  },
})
export default class AirRailSearch extends Vue {
  fromLocations: AirLocationModel[] = [];
  toLocations: AirLocationModel[] = [];
  connectionAirports: AirLocationModel[] = [];
  isFromLoading: boolean = false;
  allAirline: any[] = [];
  isToLoading: boolean = false;
  shouldFocus: boolean = false;
  shouldShowAdvancedCriteria: boolean = false;
  isSwapLocationsActive: boolean = false;
  valueTimeDeparture = {
    value: -1,
    label: translate('common.any-hour'),
    timeRange: [0, 1439]
  };
  valueTimeReturn = {
    value: -1,
    label: translate('common.any-hour'),
    timeRange: [0, 1439]
  };
  searchModes = searchModes;
  searchModeOptions = [
    {
      value: searchModes.oneWay,
      label: translate('common.one-way'),
    },
    {
      value: searchModes.roundTrip,
      label: translate('common.roundtrip')
    },
  ];
  returnAvailable: boolean = true;
  alliances = SearchConst.alliances.map(item => {
    return {
      ...item,
      label: translate(item.label),
    };
  });



  get mobileSearchMode() {
    return this.searchModeOptions.find(item => item.value === this.airRailSearchParameters.searchMode);
  }

  set mobileSearchMode(searchMode) {
    if (!searchMode) {
      return;
    }
    this.airRailSearchParameters.searchMode = searchMode.value;
  }

  get airlines() {
    return DictionaryStore.allAirLinesSorted;
  }

  get cabinClasses() {
    return SearchConst.cabinClasses;
  }

  get airRailSearchParameters() {
    return SearchStore.getAirRailDefaultState;
  }

  get searchDate() {
    if (this.returnAvailable) {
      return {
        start: moment(this.airRailSearchParameters.departureDate).toDate(),
        end:  moment(this.airRailSearchParameters.returnDate).toDate(),
      };
    } else {
      return moment(this.airRailSearchParameters.departureDate).toDate();
    }
  }

  set searchDate(data: any) {
    if (this.returnAvailable && data && data.start) {
        this.airRailSearchParameters.departureDate = data.start;
        this.airRailSearchParameters.returnDate = data.end;
    } else if (!this.returnAvailable && data) {
      this.airRailSearchParameters.departureDate = data;
    }
  }

  get classesDate() {
    return this.returnAvailable ? 'date-picker-section__search-roundtrip' : 'date-picker-section__search-oneway';
  }

  get classesLocation() {
    return this.returnAvailable ? 'location-select__roundtrip' : 'location-select__one-way';
  }

  get modeType() {
    return this.returnAvailable ? 'range' : 'single';
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get toLocationsOptions() {
    return this.toLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get fromLocationsOptions() {
    return this.fromLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get valueTimeOptions() {
    return SearchConst.airSearchTimeWindows;
  }

  get datePickerTimeLabels() {
    return {
      start: translate('search-air.departure-at'),
      end: translate('search-air.return-at'),
    };
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  onDateInvalid(value) {
    EventBus.$emit('date-invalid', {service: serviceClassEnum.FlightTrain, value});
  }

  getAirportName(option) {
    let name = '';
    if (option.type === 'City') {
      name += option.cityName;
      name += ' (' + translate('search.all-airports') + ')';
    } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
      name += option.airportName;
      name += ' (' + option.cityName + ')';
    }
    return name;
  }

  handleEnterPress() {
    EventBus.$emit('focus-on-search');
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isFromLoading',
  })
  async findFromAirLocation(query) {
    if (query && query.length > 0) {
      this.isFromLoading = true;
      const response = await HomeApi.findAirRailLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocations = [];
        this.fromLocations = response.data;
      }

      this.isFromLoading = false;
    } else {
      this.isFromLoading = false;
      this.fromLocations = [];
      if (this.airRailSearchParameters.from) {
        this.fromLocations.push(this.airRailSearchParameters.from);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isToLoading',
  })
  async findToAirLocation(query) {
    if (query && query.length > 0) {
      this.isToLoading = true;
      const response = await HomeApi.findAirRailLocation(query, this.languageCode);
      this.toLocations = [];
      if (response && response.data) {
        this.toLocations = [];
        this.toLocations = response.data;
      }

      this.isToLoading = false;
    } else {
      this.isToLoading = false;
      this.toLocations = [];
      if (this.airRailSearchParameters.to) {
        this.toLocations.push(this.airRailSearchParameters.to);
      }
    }
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  swapLocations() {
    this.isSwapLocationsActive = true;
    let from = this.airRailSearchParameters.from;
    this.airRailSearchParameters.from = this.airRailSearchParameters.to;
    this.airRailSearchParameters.to = from;

    this.prepareTo();
    this.prepareFrom();
    setTimeout(() => {
      this.isSwapLocationsActive = false;
    }, 450);
  }

  checkMode() {
    this.returnAvailable = this.airRailSearchParameters.searchMode === 'RoundTrip';
    if (this.airRailSearchParameters.searchMode === 'MultiLeg' && this.airRailSearchParameters.legs && this.airRailSearchParameters.legs.length < 1) {
      let date = moment(new Date())
        .add(1, 'week')
        .format('YYYY-MM-DD');
      this.airRailSearchParameters.legs.push(new Legs({legNumber: this.airRailSearchParameters.legs.length, departureDate: date}));
    }
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const vueEl = this.$refs[elementId] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl.$el as HTMLInputElement;
    inputValue.getElementsByTagName('input')[0].focus();
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs.fromSelect as Vue;
      const from: Multiselect = element.$refs.multiselect as Multiselect;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  prepareFrom() {
    this.fromLocations = [];
    if (this.airRailSearchParameters.from) {
      this.fromLocations.push(this.airRailSearchParameters.from);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.airRailSearchParameters.to) {
      this.toLocations.push(this.airRailSearchParameters.to);
    }
  }

  refreshTimeWindows() {
    if (this.airRailSearchParameters.outwardTimeWindows) {
      let window = this.airRailSearchParameters.outwardTimeWindows.departureWindow ? this.airRailSearchParameters.outwardTimeWindows.departureWindow : this.airRailSearchParameters.outwardTimeWindows.timeRange;
      this.valueTimeDeparture = this.findTimeRange(window) as any;
    }
    if (this.airRailSearchParameters.inwardTimeWindows) {
      let window = this.airRailSearchParameters.inwardTimeWindows.departureWindow ? this.airRailSearchParameters.inwardTimeWindows.departureWindow : this.airRailSearchParameters.inwardTimeWindows.timeRange;
      this.valueTimeReturn = this.findTimeRange(window) as any;
    }
  }

  findTimeRange(range) {
    const windowFound = !range ? false : SearchConst.searchTimeValues.find((window) => {
      return window.timeRange[0] === range[0] && window.timeRange[1] === range[1];
    });

    return windowFound ? windowFound : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];
  }

  @Watch('airRailSearchParameters.searchMode', { immediate: true, deep: true })
  onChangedSearchMode(val) {
    if (this.airRailSearchParameters.searchMode === this.searchModes.multiLeg) {
      EventBus.$emit('date-invalid', { service: serviceClassEnum.FlightTrain, value: false });
    }
    if (this.airRailSearchParameters.searchMode === this.searchModes.oneWay) {
      this.returnAvailable = false;
      this.airRailSearchParameters.returnDate = '';
    } else {
      if (this.airRailSearchParameters.returnDate) {
        if (moment(this.airRailSearchParameters.returnDate).isAfter(
          moment(this.airRailSearchParameters.departureDate).add(1, 'days')
        )) {
          this.refreshTimeWindows();
          return;
        }
        this.airRailSearchParameters.returnDate = moment(this.airRailSearchParameters.returnDate).format('YYYY-MM-DD');
      } else {
        this.airRailSearchParameters.returnDate = moment(this.airRailSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.refreshTimeWindows();
  }

  @Watch('airRailSearchParameters.departureDate')
  onChangedDeparture(val) {
    if (val) {
      this.airRailSearchParameters.departureDate = moment(this.airRailSearchParameters.departureDate).format('YYYY-MM-DD');
    }
    if (moment(this.airRailSearchParameters.departureDate).isAfter(this.airRailSearchParameters.returnDate)) {
      this.airRailSearchParameters.returnDate = moment(this.airRailSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.airRailSearchParameters.departureDate = moment().format('YYYY-MM-DD');
    }
  }

  @Watch('airRailSearchParameters.returnDate')
  onChangedReturn(val) {
    if (this.airRailSearchParameters.searchMode === this.searchModes.oneWay) {
      this.airRailSearchParameters.returnDate = '';
    } else {
      if (this.airRailSearchParameters.returnDate) {
        this.airRailSearchParameters.returnDate = moment(this.airRailSearchParameters.returnDate).format('YYYY-MM-DD');
      }
    }
  }

  @Watch('valueTimeDeparture', { deep: true })
  onChangedDepartureTimeWindow(val) {
    if (val) {
      this.airRailSearchParameters.outwardTimeWindows = {
        departureWindow: val.timeRange,
        arrivalWindow: null
      };
    }
  }

  @Watch('valueTimeReturn', { deep: true })
  onChangedReturnTimeWindow(val) {
    if (this.airRailSearchParameters.searchMode === this.searchModes.oneWay) {
      this.airRailSearchParameters.inwardTimeWindows = null;
    } else {
      if (this.airRailSearchParameters.returnDate) {
        this.airRailSearchParameters.inwardTimeWindows = {
          departureWindow: val.timeRange,
          arrivalWindow: null
        };
      }
    }
  }

  created() {
    EventBus.$on('focus-from', this.onFocus);
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}
