



















































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import { TravellerTypeMappings } from '@/const/traveller-types.const';

@Component({
})
export default class BasketTrainPriceDetails extends Vue {
  @Prop() item!: any;
  @Prop() offer!: any;
  @Prop() travellers!: any;


  get travellersMapped() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    return this.travellers.map(traveller => {
      const fare = this.offer.prices.passengerPriceSums
        .find(p => p && p.profileId === traveller.id);
      const ancillaries = (this.offer.prices.passengerAncillaries || [])
        .filter(p => p && p.profileId === traveller.id);

      return {
        ...traveller,
        fare,
        ancillaries,
      };
    });
  }



  userFullName(user) {
    if (user.isVirtual) {
      return translate(TravellerTypeMappings[user.passengerTypeCode]);
    }
    return userFullName(user);
  }
}

