import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import { MissedSavingsApi } from '@/api/profile/missed-savings-configuration.api';
import { MissedSavingsConfiguration } from '@/api/profile/missed-savings-configuration.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'MissedSavingsConfiguration'
})
class MissedSavingsConfigurationStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: MissedSavingsConfiguration = new MissedSavingsConfiguration();
  configurationName: string = '';
  configurationId: string = '';
  errMessages: any[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get errors() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get Loading() {
    return this.loading;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.currentConfiguration = new MissedSavingsConfiguration(value);
  }

  @Mutation
  clearConfiguration() {
    this.currentConfiguration = new MissedSavingsConfiguration();
  }

  @Mutation
  setConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setErrors(value) {
    this.errMessages = $handleErrors(value, true);
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  clearErrors() {
    this.showError = false;
    this.errMessages = [];
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }


  @Action
  clearData() {
    this.setConfiguration(new MissedSavingsConfiguration());
    this.setConfigurationId('');
    this.setConfigurationName('');
  }

  @Action
  async createConfiguration(request: MissedSavingsConfiguration) {
    try {
      this.setLoading(true);
      const response = await MissedSavingsApi.createConfiguration(request);
      if (response && response.data) {
        this.setConfigurationId(response.data.configurationId);
      }
    } catch (error) {
      this.setErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateConfiguration(data) {
    try {
      this.setLoading(true);
      const response = await MissedSavingsApi.updateConfiguration(data);
      if (response && response.data) {
        this.getConfiguration(data.configurationId);
      }
    } catch (error) {
      this.setErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getConfiguration(configurationId: string) {
    try {
      this.setErrors([]);
      this.setCustomError(false);
      this.setShowError(false);
      this.setLoading(true);
      const result = await MissedSavingsApi.getConfiguration(configurationId);
      if (result && result.data) {
        this.setConfiguration(result.data);
        this.setConfigurationId(configurationId);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrors(error);
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(MissedSavingsConfigurationStore);