











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiBusyIndicator extends Vue {
  @Prop({ default: 207 }) size!: [String, Number];
}
