








































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { IProfileLoyaltiesCard } from '@/api/profile/loyalties.model';
import LoyaltyPopup from '@/modules/profile/loyalty-programs/LoyaltyPopup.vue';
import LoyaltyRemovePopup from '@/modules/profile/loyalty-programs/LoyaltyRemovePopup.vue';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import { getLoyaltyName } from '@/modules/profile/loyalty-programs/loyalty-name.service';

@Component({
  components: {
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
export default class BasketTravellerLoyaltyProgramsModifyTravellers extends Vue {
  @Prop({ default: '' }) profileId!: string;
  @Prop({ default: '' }) loyalty!: string;
  @Prop({ default: false }) isShouldChangeLoyaltyProgram!: boolean;
  $v;
  showLoyaltyCardPopup: boolean = false;
  deleteLoyaltyCardPopup: boolean = false;
  editingCard: any = null;
  formPending: boolean = false;
  serverErrors: any[] = [];
  availableLoyalties: IProfileLoyaltiesCard[] = [];
  selectedOption: any = null;



  @Validation()
  validationObject() {
    if (!this.isShouldChangeLoyaltyProgram) {
      return {
        selectedOption: {
        },
      };
    }
    return {
      selectedOption: {
        required,
      },
    };
  }


  get availableDocumentByTypes() {
    return _.uniqBy(this.availableLoyalties, 'loyalty.serviceType');
  }

  get someAvailableLoyaltyPrograms() {
    return this.availableLoyalties.some((item: any) => {
      return item.loyalty && item.loyalty.loyaltyType === 'Loyalty';
    });
  }

  get shouldShowBasketModifyTravellersDetailsView() {
    return BasketStore.shouldShowBasketModifyTravellersDetailsView;
  }

  showAddNewLoyaltyCardPopup() {
    this.editingCard = null;
    this.showLoyaltyCardPopup = true;
  }

  filterLoyaltiesByType(type) {
    return this.availableLoyalties.filter((doc: any) => doc.loyalty.serviceType === type.loyalty.serviceType);
  }

  async loadProfileLoyalties() {
    try {
      let result = await LoyaltiesApi.getProfileloyaltiesCardModify(this.profileId, 'Air', 'Loyalty');
      if (result && result.data) {
        this.availableLoyalties = result.data;
      }
      if (this.availableLoyalties.length) {
        setTimeout(() => {
          EventBus.$emit('open-traveller-loyalties', this.profileId);
        });
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      EventBus.$emit('travellers-loyalty-programs-loaded', this.profileId);
    }
  }

  editLoyalties(card) {
    this.editingCard = card;
    this.showLoyaltyCardPopup = true;
  }
  
  getLoyaltyName = getLoyaltyName;

  async onReceive() {
    await this.loadProfileLoyalties();
  }

  onAssignData() {
    this.$v.selectedOption.$touch();
    BasketStore.setModifyTravellersDetailsLoyaltiesTouched(true);
    BasketStore.setChangesInLoyaltyCards({
      profileId: this.profileId,
      selectedOption: this.selectedOption === false ? null : this.selectedOption,
    });
  }

  @Watch('$v.$invalid', { immediate: true })
  onInvalid(value) {
    BasketStore.setModifyTravellersDetailsFormStatus({
      travellerId: this.profileId,
      form: null,
      loyalty: value,
      noSection: null,
    });
  }

  @Watch('isShouldChangeLoyaltyProgram')
  onLoyaltyProgramsChange(value) {
    if (value) {
      this.selectedOption = null;
      if (this.loyalty) {
        const loyalty = this.availableLoyalties
          .find((item: any) => item.cardNumber === this.loyalty);

        if (loyalty) {
          this.selectedOption = { cardId: loyalty.id };
        }
      }
      this.$v.$reset();
    } else {
      this.selectedOption = null;
    }
  }

  @Watch('someAvailableLoyaltyPrograms')
  onAvailableLoyaltiesChange(value) {
    if (!value) {
      EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: true });
    } else {
      EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: false });
    }
  }

  async created() {
    await this.onReceive();
    EventBus.$on('assign-modify-travellers', this.onAssignData);
    EventBus.$on('reload-loyalty', this.onReceive);
    EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: !this.someAvailableLoyaltyPrograms });
  }

  beforeDestroy() {
    EventBus.$off('assign-modify-travellers', this.onAssignData);
    EventBus.$off('reload-loyalty', this.onReceive);
  }
}

