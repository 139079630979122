



































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { router } from '@/router';
import { AirSearchStateParams } from './air-search.params';
import { TravellersStateParams } from '../travellers.params';
import AirSearchStore from '@/modules/search/air/air-search.store';
import EventBus from '@/services/event-handler';
import layoutStore from '@/modules/layout/layout.store';
import AirExchangeModifyPopup from './AirExchangeModifyPopup.vue';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';

@Component({
  components: {
    AirExchangeModifyPopup,
    SearchTripTravellers,
  },
  filters: {
    date(val) {
      return moment(
        val,
        'YYYY-MM-DD'
      ).format('Do MMMM');
    }
  },
})
export default class AirSearchExchangeParamOnResults extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  @Prop() airSearchParameters!: any;

  showEditSegmentPopup: boolean = false;

  get searchTravellersCount() {
    return this.travellersSearchList && this.travellersSearchList.travellers
      ? this.travellersSearchList.travellers.length
      : 0;
  }

  get legs() {
    if (!this.airSearchParameters || !this.airSearchParameters.dates) {
      return [];
    }
    return this.airSearchParameters.dates.map((date, index) => {
      return {
        text: this.airSearchParameters.locations.from[index].cityName + ' - ' +
          this.airSearchParameters.locations.to[index].cityName,
        date,
      };
    });
  }

  get basketId() {
    return AirSearchStore.modificationBasketId;
  }

  get itemStatus() {
    return AirSearchStore.modificationBasketItemStatus;
  }

  get offer() {
    return {
      ...AirSearchStore.getExchangedOffer,
      legFlights: AirSearchStore.getExchangedOffer && AirSearchStore.getExchangedOffer.legFlights.map(leg => {
        return { ...leg };
      }),
    };
  }

  dayName(leg) {
    if (leg) {
      return moment(
        leg.date,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  toggleSidebar() {
    layoutStore.toggleSidebar();
  }

  criteria() {
    this.showEditSegmentPopup = true;
  }

  travellers() {
    router.push({
      name: 'airModificationTravellers',
      params: router.currentRoute.params,
    });
  }

  mounted() {
    EventBus.$on('show-air-exchange-popup', this.criteria);
  }

  beforeDestroy() {
    EventBus.$off('show-air-exchange-popup', this.criteria);
  }
}
