















import { Vue, Component, Prop } from 'vue-property-decorator';
import { TRailSeatSelection } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import BasketStore from '@/modules/basket/basket.store';

const SETTINGS = {
  offset: 4,
  width: 30,
  height: 30,
};

@Component
export default class SeatAvatar extends Vue {
  @Prop({ default: 0 }) x!: number;
  @Prop({ default: 0 }) y!: number;
  @Prop() seatSelection!: TRailSeatSelection;

  settings = SETTINGS;

  get profile() {
    return BasketStore.basketTravellers.find((item) => item.id === this.seatSelection.profileId);
  }
}
