export class LodgeCardConfiguration {
  rootCompanyId: string = '';
  configurationName: string = '';
  lodgeCards: LodgeCard[] = [];

  constructor(item?: any) {
    if (item) {
      if (item.rootCompanyId !== undefined) {
        this.rootCompanyId = item.rootCompanyId;
      }
      if (item.configurationName !== undefined) {
        this.configurationName = item.configurationName;
      }
      if (item.lodgeCards !== undefined) {
        this.lodgeCards = item.lodgeCards;
      }
    }
  }
}

export class LodgeCard {
  id?: string = '';
  displayName: string | null = '';
  transactionId?: string | null = '';
  maskedCardNumber?: string | null = '';
  expireDate: string | null = '';
  holder: string | null = '';
  holderFirstName: string | null = '';
  holderLastName: string | null = '';
  type: CompanyPaymentCardTypeEnum | null = null;
  provider: CompanyPaymentCardProvider | null = null;
  email: string | null = '';
  phoneNumber: string | null = '';
  companyName: string | null = '';
  addressLine1: string | null = '';
  addressLine2: string | null = '';
  cityName: string | null = '';
  stateRegion: string | null = '';
  postalCode: string | null = '';
  countryCode: string | null = '';
  cardToken: string = '';
  status?: string = '';

  constructor(item?: any) {
    if (item) {
      if (item.id !== undefined) {
        this.id = item.id;
      }
      if (item.displayName !== undefined) {
        this.displayName = item.displayName;
      }
      if (item.transactionId !== undefined) {
        this.transactionId = item.transactionId;
      }
      if (item.maskedCardNumber !== undefined) {
        this.maskedCardNumber = item.maskedCardNumber;
      }
      if (item.expireDate !== undefined) {
        this.expireDate = item.expireDate;
      }
      if (item.holder !== undefined) {
        this.holder = item.holder;
      }
      if (item.holderFirstName !== undefined) {
        this.holderFirstName = item.holderFirstName;
      }
      if (item.holderLastName !== undefined) {
        this.holderLastName = item.holderLastName;
      }
      if (item.type !== undefined) {
        this.type = item.type;
      }
      if (item.provider !== undefined) {
        this.provider = item.provider;
      }
      if (item.email !== undefined) {
        this.email = item.email;
      }
      if (item.phoneNumber !== undefined) {
        this.phoneNumber = item.phoneNumber;
      }
      if (item.companyName !== undefined) {
        this.companyName = item.companyName;
      }
      if (item.addressLine1 !== undefined) {
        this.addressLine1 = item.addressLine1;
      }
      if (item.addressLine2 !== undefined) {
        this.addressLine2 = item.addressLine2;
      }
      if (item.cityName !== undefined) {
        this.cityName = item.cityName;
      }
      if (item.stateRegion !== undefined) {
        this.stateRegion = item.stateRegion;
      }
      if (item.postalCode !== undefined) {
        this.postalCode = item.postalCode;
      }
      if (item.countryCode !== undefined) {
        this.countryCode = item.countryCode;
      }
      if (item.status !== undefined) {
        this.status = item.status;
      }
      if (item.cardToken !== undefined) {
        this.cardToken = item.cardToken;
      }
    }
  }
}

export interface UpdateLodgeCardMessage {
  configurationName: string;
  lodgeCards: LodgeCard[];
}

export enum CompanyPaymentCardTypeEnum {
  Unknown = 'Unknown',
  VisaCredit = 'VisaCredit',
  VisaElectron = 'VisaElectron',
  VisaDebit = 'VisaDebit',
  MasterCardCredit = 'MasterCardCredit',
  MasterCardDebit = 'MasterCardDebit',
  AmericanExpress = 'AmericanExpress',
  Maestro = 'Maestro',
  DinersClub = 'DinersClub',
  Discover = 'Discover',
}

export enum CompanyPaymentCardProvider {
  None = 'None',
  AirPlus = 'AirPlus',
  Bta = 'BTA',
}