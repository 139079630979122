


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import SearchStore from './search.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({
  created: () => {
    router.currentRoute.path.includes('ResultsTravellers') ||
    router.currentRoute.path.includes('basket') ||
    router.currentRoute.path.includes('DetailsTravellers') ?
      SearchStore.updateHideShowLess(false) :
      SearchStore.updateHideShowLess(true);
  }
})
export default class SearchShortTripTravellers extends Vue {
  @Prop() travellersList!: any;

  get travellersSearchList() {
    return this.travellers;
  }

  get travellers() {
    return this.travellersList;
  }

  get typeCodeOptions() {
    return searchConst.guestTravellerOptions.map(option => {
      return {
        ...option,
        name: translate(option.label)
      };
    });
  }

  tooltipAddon(trav) {
    const option = this.typeCodeOptions.find(opt => opt.code === trav.passengerTypeCode);
    if (!option) {
      return [];
    }
    return [option.name];
  }

  getTravLabel(guestCode) {
    let option = searchConst.guestTravellerOptions.find(trav => {
      return trav.code === guestCode;
    });
    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    }
  }

  travellerUrl(trav) {
    return sanitizeUrl('/profile/' + trav.profileId);
  }
}
