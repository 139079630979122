import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { BillingConfiguration, BillingConfigurationItem, BillingConfigurationModeEnum, UpdateBillingConfigurationParams } from './billing.model';

class BillingApiClass {
  public getBillingConfiguration(configurationId: string): Promise<AxiosResponse<BillingConfiguration>> {
    return http.execute({
      path: settings.apiBilling + '/api/configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public getEmptyBillingConfiguration(mode: BillingConfigurationModeEnum): Promise<AxiosResponse<Array<BillingConfigurationItem>>> {
    return http.execute({
      path: settings.apiBilling + '/api/configurations/empty?mode={mode}',
      method: RequestMethod.GET,
    }, { mode }, {});
  }

  public addBillingConfiguration(params: BillingConfiguration): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiBilling + '/api/configurations',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateBillingConfiguration(configurationId: string, params: UpdateBillingConfigurationParams): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiBilling + '/api/configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getCustomFieldsConfiguration(id: string): Promise<AxiosResponse<BillingConfiguration>> {
    return http.execute({
      path: settings.apiBilling + '/api/custom-fields-configuration/{id}',
      method: RequestMethod.GET
    }, { id }, {});
  }

  public addCustomFieldsConfiguration(configuration: any): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiBilling + '/api/custom-fields-configuration',
      method: RequestMethod.POST
    }, {}, configuration);
  }

  public updateCustomFieldsConfiguration(id: string, configuration: any): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiBilling + '/api/custom-fields-configuration/{id}',
      method: RequestMethod.PUT
    }, { id }, configuration);
  }
}

export const BillingApi: BillingApiClass = new BillingApiClass();