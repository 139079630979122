import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import { store } from '@/store';
import { router } from '@/router';
import { ToTwoDigitStringValue } from '@/core/utils';
import axios, { CancelTokenSource } from 'axios';
import {
  Recommendation,
  SortOptions,
  SearchState,
  InitAirSearchMessage,
  AirSearchProviderError,
  AirResultsErrorPayload,
  Legs,
} from '@/api/air-engine/air-search.model';
import AccountStore from '@/store/account.store';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import SearchStore from '@/modules/search/search.store';
import { AirApi } from '@/api/air-engine/air-search.api';
import { CompareOffersApi } from '@/api/air-engine/compare-offers.api';
import { CompareOfferDetails } from '@/api/air-engine/compare-offers.model';
import filtersList from '@/const/filter.const';
import layoutStore from '@/modules/layout/layout.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import moment from 'moment';
import {
  serviceClassEnum,
  cabinClassEnum,
} from '@/api/home/home.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import SearchConst from '@/const/search.const';
import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import { isCancelError } from '@/core/utils';
import TrainSearchStore from '../train/train-search.store';
import { TrainApi } from '@/api/train-engine/train-search.api';
import { BasketItemApi } from '@/api/trip/basket-item.api';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'airRailSearch'
})
class AirRailSearchStore extends VuexModule {
  filters: any[] = [];
  railFilters: any[] = [];
  filtersChanged: boolean = false;
  requestFilterData = [];
  offers: Recommendation[] = [];
  cancelToken: CancelTokenSource | null = null;
  filtersChanging: boolean = false;
  filtersRequest: any = null;
  filtersError: boolean = false;
  filtersRequestId: number = 0;
  searchFreezed: boolean = false;
  sortOptions: SortOptions = {
    PRICE: 'Ascending'
  };
  offersVisible: number = 10;
  totalOffers: number = 0;
  averageCo2Emission: number | null = null;
  agencyMarkup: number = 0;
  convertedFilters: any[] = [];
  convertedRailFilters: any[] = [];
  loading: boolean = false;
  templateOffers = [];
  stateId: string = '';
  searchId: string = '';
  searchIdChanged: boolean = false;
  searchLoaded: boolean = false;
  currentOfferId: string = '';
  currentProposalId: string = '';
  searchCompleted: boolean = false;
  serverErrors: any[] = [];
  searchTimeout: number = -1;
  basketId: string = '';
  prevSelectedOffer = null;
  showModal: boolean = false;
  errorMessageBe: string = '';
  showErrorBE: boolean = false;
  searchInProgress: boolean = false;
  scrollTimeout: number = 0;
  modificationBasketId: string = '';
  errMessages: any[] = [];
  errCompareMessages: any[] = [];
  showError: boolean = false;
  recommendationsCount: number = 0;
  providersErrors: AirSearchProviderError[] = [];
  currentSearchIdFromApi: string = '';
  fareUnavailableErrorParams = {
    codes: {
      [404]: 'common-error.fare-unavailable'
    }
  };
  newFlightDisplay: boolean = true;
  loadingProposals: boolean = false;
  filterLoadingView: boolean = false;
  loadingRestProposals: boolean = false;
  offersProposalsList: any[] = [];
  shouldReloadProposals: boolean = false;
  startSearchingMoreProposals: boolean = false;
  openOffers: any[] = [];
  feeConfigurationId: string = '';
  priceDetailedComponentsVisible: boolean = false;
  compareOfferDetailsData: CompareOfferDetails[] = [];
  loaderCompare: boolean = false;
  showTemplateOffersModal: boolean = false;
  currency: any = null;
  offerExpired: any[] = [];
  legs: any[] = [];
  showingMissedSavingsModal: boolean = false;
  sortChanged: boolean = false;
  missedSavingsInfo: any = null;
  basketItemsRequest: any = null;
  legCabinClasses: any;
  preferredCabinClass: string = 'Economy';

  get isSelected() {
    return this.currentOfferId !== '' && this.currentProposalId !== '';
  }

  get offersProposalsListRefresh() {
    return this.offersProposalsList;
  }
  
  get currentUser() {
    return AccountStore.CurrentUser;
  }

  @Mutation
  setOffersProposalsList(payload) {
    payload.proposals.forEach(proposal => {
      proposal.prepareOfferCheck = false;
      if (this.templateOffers.length > 0) {
        this.templateOffers.forEach((template: any) => {
          if (template.proposalId === proposal.id) {
            proposal.prepareOfferCheck = true;
          }
        });
      }
    });

    const offer = this.offers.find(o => o.id === payload.id);

    if (offer) {
      offer.travelPolicy = payload.travelPolicy;
    }

    let itemExist = this.offersProposalsList.findIndex(proposal => proposal.id === payload.id);
    if (itemExist < 0) {
      this.offersProposalsList.push(payload);
    } else {
      this.offersProposalsList[itemExist] = payload;
    }
  }

  @Mutation
  setMissedSavingsInfo(value) {
    this.missedSavingsInfo = value;
  }

  @Mutation
  showMissedSavingsModal(value) {
    this.missedSavingsInfo = value;
    this.showingMissedSavingsModal = !!value;
  }

  @Mutation
  toggleMissedSavingsModal(value) {
    this.showingMissedSavingsModal = value;
  }

  @Mutation
  resetOffersProposalsList() {
    this.offersProposalsList = [];
  }
  @Mutation
  setFilterLoadingView(value) {
    this.filterLoadingView = value;
  }

  @Mutation
  setNewFlightDisplay(value) {
    this.newFlightDisplay = value;
  }

  @Mutation
  setShouldReloadProposals(value) {
    this.shouldReloadProposals = value;
  }

  @Mutation
  setOpenOffers(value) {
    let itemExist = this.openOffers.findIndex(offer => offer.id === value.id);
    if (itemExist < 0) {
      this.openOffers.push(value);
    }
  }

  @Mutation
  removeOffers(value) {
    this.openOffers = this.openOffers.filter(offer => offer.id !== value.id);
  }

  @Mutation
  clearOpenOffers() {
    this.openOffers = [];
  }

  @Mutation
  setStartSearchingMoreProposals(value) {
    this.startSearchingMoreProposals = value;
  }

  @Mutation
  setLoadingRestProposals(value) {
    this.loadingRestProposals = value;
  }

  @Mutation
  setErrMessages(message: AirResultsErrorPayload | null) {
    if (message) {
      this.errMessages = $handleErrors(message.error, true, message.customParams , message.addMessageToError);
    } else {
      this.errMessages = [];
    }
  }

  @Mutation
  setCompareErrMessages(error) {
    if (error) {
      this.errCompareMessages = $handleErrors(error);
    } else {
      this.errCompareMessages = [];
    }

  }

  @Mutation
  setCustomError(error) {
    this.errMessages = error;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setCurrency(value) {
    this.currency = value;
  }

  @Mutation
  setCurrentSearchIdFromApi(value) {
    this.currentSearchIdFromApi =  value;
  }

  @Mutation
  setSearchInProgress(state: boolean) {
    this.searchInProgress = state;
  }

  @Mutation
  setRecommendationsCount(state: number) {
    this.recommendationsCount = state;
  }

  @Mutation
  setProvidersErrors(errors: AirSearchProviderError[]) {
    this.providersErrors = errors;
  }

  @Mutation
  setTimeoutInterval(state: number) {
    this.scrollTimeout = state;
  }

  @Mutation
  setSearchCompleted(state: boolean) {
    this.searchCompleted = state;
  }

  @Mutation
  clear() {
    this.currentOfferId = '';
    this.currentProposalId = '';
  }

  @Mutation
  select({offerId, proposalId }) {
    this.currentOfferId = offerId;
    this.currentProposalId = proposalId;
  }

  @Mutation
  setTotalOffers(value) {
    this.totalOffers = value;
  }

  @Mutation
  setAverageCo2Emission(value) {
    this.averageCo2Emission = value;
  }

  @Mutation
  setupSearchLoaded(searchId) {
    if (this.searchId !== searchId) {
      this.searchIdChanged = true;
      this.searchId = searchId;
      this.searchLoaded = false;
    } else {
      this.searchIdChanged = false;
    }
  }

  @Mutation
  setSearchId(value) {
    this.searchId = value;
  }

  @Mutation
  finishLoading() {
    if (this.searchIdChanged) {
      this.searchLoaded = true;
      this.searchIdChanged = false;
    }
  }

  @Mutation
  loader(data) {
    this.loading = data;
  }

  @Mutation
  setTemplateOffers(data) {
    this.templateOffers = data;
  }

  @Mutation
  setNewAgencyMarkup(value) {
    this.agencyMarkup = value;
  }

  @Mutation
  setNewSort({ sorterCode, isAscending = true }) {
    this.sortOptions = {
      [sorterCode]: isAscending ? 'Ascending' : 'Descending',
    };
    this.sortChanged = true;
  }

  @Mutation
  updateOffers(data) {
    this.offers = data;
  }

  @Mutation
  updateOffer(data) {
    let index = this.offers.findIndex(offer => {
      return offer.id === data.id;
    });
    if (-1 < index) {
      this.offers.splice(index, 1, data);
    }
  }

  @Mutation
  updateFilters(data) {
    this.filters = data;
  }

  @Mutation
  updateRailFilters(data) {
    this.railFilters = data;
  }

  @Mutation
  setFiltersChanged(value) {
    this.filtersChanged = value;
  }

  @Mutation
  setSortChanged(value) {
    this.sortChanged = value;
  }

  @Mutation
  updateRequestFilterData(data) {
    this.requestFilterData = data;
  }

  @Mutation
  getDefaultOffersVisible() {
    this.offersVisible = 10;
  }

  @Mutation
  setOffersVisible(num) {
    this.offersVisible = num;
  }

  @Mutation
  updateSearchStateId(value: string) {
    this.stateId = value;
  }

  @Mutation
  updateErrors(error: any[]) {
    this.serverErrors = error;
  }

  @Mutation
  clearErrors() {
    this.serverErrors = [];
  }

  @Mutation
  setExchangeCabinClass(value) {
    this.preferredCabinClass = value;
  }

  @Mutation
  setBasketId(data) {
    this.basketId = data;
  }
  
  @Mutation
  setPrevSelectedOffer(data) {
    this.prevSelectedOffer = data;
  }

  @Mutation
  setShowModal(data) {
    this.showModal = data;
  }

  @Mutation
  setErrorMessageBe(data) {
    this.errorMessageBe = data;
  }

  @Mutation
  setShowErrorBE(data) {
    this.showErrorBE = data;
  }

  @Mutation
  setLoadingProposals(data) {
    this.loadingProposals = data;
  }

  @Mutation
  newFiltersMut(data) {
    this.convertedFilters = data;
  }

  @Mutation
  convertedFiltersMut(data) {
    this.convertedFilters.push(data);
  }

  @Mutation
  updateConvertedRailFilters(data) {
    this.convertedRailFilters = data;
  }

  @Mutation
  addConvertedRailFilter(data) {
    this.convertedRailFilters.push(data);
  }

  @Mutation
  updateModificationBasketId(basketId) {
    this.modificationBasketId = basketId;
  }

  @Mutation
  setSearchTimeout(timeout) {
    this.searchTimeout = setTimeout(timeout, 1000);
  }

  @Mutation
  clearSearchTimeout() {
    clearTimeout(this.searchTimeout);
  }

  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  createCancelTokenIfNeeded() {
    if (this.cancelToken !== null) {
      return;
    }

    this.cancelToken = axios.CancelToken.source();
  }

  @Mutation
  setFiltersError(value) {
    this.filtersError = value;
  }

  @Mutation
  setSearchFreezed(value: boolean) {
    this.searchFreezed = value;
  }

  @Mutation
  updateFiltersRequestId() {
    this.filtersRequestId++;
  }

  @Mutation
  setFiltersChanging(value) {
    this.filtersChanging = value;
  }

  @Mutation
  setFiltersRequest(value) {
    this.filtersRequest = value;
  }

  @Mutation
  setFeeConfigurationId(value) {
    this.feeConfigurationId = value;
  }

  @Mutation
  setPriceDetailedComponentsVisible(value) {
    this.priceDetailedComponentsVisible = value;
  }

  @Mutation
  setCompareOfferDetails(value) {
    this.compareOfferDetailsData = value;
  }

  @Mutation
  setLoaderCompare(value) {
    this.loaderCompare = value;
  }

  @Mutation
  setShowTemplateOffersModal(value) {
    this.showTemplateOffersModal = value;
  }

  @Mutation
  setOfferExpired(value) {
    this.offerExpired.push(value);
  }

  @Mutation
  clearOfferExpired() {
    this.offerExpired = [];
  }

  @Mutation
  requestAirSearchMultiLeg() {
    const params = SearchStore.getAirDefaultState;
    this.legs = params.legs.map((leg, index) => {
      return {
        legNumber: index,
        from: {
          type: leg.from.type === 'CityAirport'
              ? 'Airport'
              : leg.from.type,
          code: leg.from.type === 'City' ? leg.from.cityCode : leg.from.airportCode
        },
        to: {
          type: leg.to.type === 'CityAirport'
            ? 'Airport'
            : leg.to.type,
          code: leg.to.type === 'City' ? leg.to.cityCode : leg.to.airportCode
        },
        departureDate: leg.departureDate,
        cabinClass: leg.cabinClasses[0].value,
        timeWindows: leg.timeWindows && leg.timeWindows.departureWindow ? {
          departureWindow: {
            start: '' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[0] / 60) + ':' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[0] % 60) + ':00',
            end: '' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[1] / 60) + ':' + ToTwoDigitStringValue(<number>leg.timeWindows.departureWindow[1] % 60) + ':00',
          },
          arrivalWindow: null,
        } : null,
      };
    });
  }

  @Mutation
  requestAirSearchClear() {
    this.legs = [new Legs()];
  }

  @Mutation
  setBasketItemRequest(data) {
    this.basketItemsRequest = data;
  }



  @Action({ rawError: true })
  convertRangeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        }
      };
      this.convertedFiltersMut(converted);
    }
  }

  @Action({ rawError: true })
  convertRangeTimeFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: {
          minValue: stats.min,
          minLimit: stats.minLimit,
          maxValue: stats.max,
          maxLimit: stats.maxLimit
        },
        data: stats.rangesStatItems.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
            maxLimit: i.maxLimit,
            minLimit: i.minLimit,
          };
        }),
      };
      this.convertedFiltersMut(converted);
    }
  }

  @Action({ rawError: true })
  convertCategoriesFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: stats.items.map(i => {
          return {
            ...i,
          };
        }),
      };
      this.convertedFiltersMut(converted);
    }
  }

  @Action
  convertHashFilterStats(stats) {
    const converted = {
      code: stats.code,
      values: stats.selectedHashes
    };

    this.convertedFiltersMut(converted);
  }

  @Action
  buildSortQuery() {
    const data: string[] = [];
    const paramName = 'sortOptions';
    let i = 0;
    for (let key in this.sortOptions) {
      if (this.sortOptions.hasOwnProperty(key)) {
        let urlParam = `${paramName}[${i}].Code=${key}`;
        data.push(urlParam);
        urlParam = `${paramName}[${i}].Direction=${this.sortOptions[key]}`;
        data.push(urlParam);

        i++;
      }
    }

    return data.join('&');
  }

  @Action({ rawError: true })
  convertCategoriesRailFilterStats(stats) {
    if (stats) {
      const converted = {
        code: stats.code,
        values: stats.items.map(i => {
          return {
            code: i.code,
            name: i.name,
            selected: i.selected,
            matches: i.matches,
            minPrice: i.minPrice,
          };
        }),
      };
      this.addConvertedRailFilter(converted);
    }
  }

  @Action
  convertFiltersStats(stats) {
    this.newFiltersMut([]);
    filtersList.air.filtersdata.rangeFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      let multiLegExist = -1 !== [...SearchConst.legNumberData].indexOf(f.code);
      if (f.code === 'OUTBOUND_DEPART_TIME' || f.code === 'INBOUND_DEPART_TIME' || multiLegExist) {
        this.convertRangeTimeFilterStats(filterStats);
      } else {
        this.convertRangeFilterStats(filterStats);
      }
    });

    filtersList.air.filtersdata.categoriesFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertCategoriesFilterStats(filterStats);
    });

    filtersList.air.filtersdata.hashFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      if (filterStats !== undefined) {
        this.convertHashFilterStats(filterStats);
      }
    });

    this.updateFilters(this.convertedFilters);
  }

  @Action
  hashFilterUpdated(value) {
    let filter: any = this.filters.find((f: any) => f.code === value.code);
    const filterExists = filter && filter.values && filter.values.length > 0;

    if (!filterExists) {
      filter = {
        code: value.code,
        values: []
      };
    }

    if (value.data.selected && !filter.values.includes(value.data.legHash)) {
      filter.values.push(value.data.legHash);
    }
    if (!value.data.selected && filter.values.includes(value.data.legHash)) {
      filter.values = filter.values.filter(v => {
        return v !== value.data.legHash;
      });
    }

    this.filters.splice(this.filters.length - 1, 0, filter);
    this.updateFilters(this.filters);
  }

  @Action
  clearHashFilters() {
    const filterData = this.filters.find(item => item.code === 'FLIGHT_NUMBERS');

    filterData.values = [];
  }

  @Action
  async loadOffers(searchId) {
    if (this.searchId !== searchId) {
      return;
    }
    const sortQuery = await this.buildSortQuery();
    let response;
    this.createCancelTokenIfNeeded();
    try {
      
      response = await AirApi.getSearchAirRailOffers({
        searchId, sortQuery
      }, this.cancelToken || undefined);
      
      if (this.searchId !== searchId) {
        return;
      }

      if (response.data.searchResults) {
        let offers = response.data.searchResults
          .map(offer => {
            if (offer.serviceType === 'Air') {
              return {
                ...offer,
                ...offer.flights,
              };
            }

            let selectedProposal: any[] = [];
            offer.recommendation.proposals.forEach(proposal => {
              if (proposal.fares.length) {
                proposal.fares.forEach(fare => fare.isSelected = false);
      
                const selectedFare = _.minBy(proposal.fares, (f) => f.totalPrice.amount);
      
                if (selectedFare) {
                  selectedFare.isSelected = true;
                  selectedProposal.push(selectedFare);
                }
              }
            });

            return {
              ...offer,
              ...offer.recommendation,
              selectedProposal,
            };
          });

        offers.forEach((offer) => {
          offer.prepareOfferCheck = false;
          offer.ssid = '';
          if (this.templateOffers.length > 0) {
            this.templateOffers.forEach((template: any) => {
              if (template.proposal && template.proposal.id === offer.proposal.id) {
                offer.prepareOfferCheck = true;
              }
            });
          }
        });
        this.setTotalOffers(response.data.recommendationsTotalCount);
        this.setAverageCo2Emission(response.data.averageCo2Emission);
        this.updateOffers(offers);
        this.setSearchFreezed(false);
        this.setFilterLoadingView(false);
        EventBus.$emit('hash-refresh');
      }

      if (response.data.airStatistics && !this.filtersChanging) {
        this.convertFiltersStats(response.data.airStatistics);
        this.convertRailFiltersStats(response.data.railStatistics);
        EventBus.$emit('hash-refresh');
      }

      if (response.data && response.data.currency) {
        this.setCurrency(response.data.currency);
      }
    } catch (error) {
      if (!isCancelError(error)) {
        this.updateErrors($handleErrors(error, true, await this.translateErrorParams(this.fareUnavailableErrorParams)));
        this.setShowError(true);
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  convertRailFiltersStats(stats) {
    this.updateConvertedRailFilters([]);

    filtersList.train.filters.categoriesFilters.forEach(f => {
      const filterStats = stats.find(s => s.code === f.code);
      this.convertCategoriesRailFilterStats(filterStats);
    });

    this.updateRailFilters(this.convertedRailFilters);
  }

  @Action
  public stopSearch() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.setSearchInProgress(false);
    clearInterval(this.scrollTimeout);
    this.finishLoading();
    this.clearSearchTimeout();
    this.loader(false);
  }

  @Action
  public async stepLoading(searchId) {
    if (this.searchId !== searchId) {
      return;
    }

    this.createCancelTokenIfNeeded();
    this.setShouldReloadProposals(false);

    try {
      let searchCompleted = this.searchCompleted;
      const response = await AirApi.getAirRailSearchState(searchId, this.stateId, this.cancelToken || undefined);

      if (this.searchId !== searchId) {
        return;
      }

      searchCompleted = response.data.searchState === SearchState.Completed;

      this.setProvidersErrors(response.data.providersErrors);

      if (response && response.data) {
        this.updateSearchStateId(response.data.stateId);
      }

      const offers = response.data.recommendationsTotalCount;

      if (offers !== this.recommendationsCount || this.filtersChanged || this.sortChanged) {
        if (this.filtersChanged) {
          this.setFiltersChanged(false);
        }
        if (this.sortChanged) {
          this.setSortChanged(false);
        }
        await this.loadOffers(searchId);

        this.setShouldReloadProposals(true);
        if (this.openOffers && this.openOffers.length) {
          this.setLoadingProposals(true);
          this.openOffers.forEach(async offer => {
            let flightNumberHash: any = [];
            if (offer && offer.legs) {
              offer.legs.forEach(leg => {
                flightNumberHash.push(leg.flightNumberHash);
              });
            }
            await this.loadProposals({searchId, id: offer.id, flightNumberHash});
          });
        }

        if (this.searchId !== searchId) {
          return;
        }
      }
      this.setRecommendationsCount(response.data.recommendationsTotalCount);
      if (searchCompleted) {
        this.finishLoading();
        this.setSearchCompleted(true);
        this.setShouldReloadProposals(false);
        this.loader(false);
        return;
      }

      if (this.searchId !== searchId) {
        return;
      }

      this.clearSearchTimeout();
      this.setSearchTimeout(() => {
        this.stepLoading(searchId);
      });
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.setErrMessages({ error });
        this.setShowError(true);
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  public getOffers(searchId) {
    this.clearSearchTimeout();
    this.setupSearchLoaded(searchId);
    this.loader(true);
    this.updateSearchStateId('');
    this.stepLoading(searchId);
  }

  @Action
  async updateFilterOffers(data) {
    this.updateFiltersRequestId();
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.loader(true);
    this.clearSearchTimeout();
    this.setSearchFreezed(true);
    this.createCancelTokenIfNeeded();

    this.setFiltersError(false);

    try {
      const response = await AirApi.updateAirRailFilters(data.searchId, {
        airFilter: data.filtersData,
        railFilter: data.railFiltersData,
      }, this.cancelToken || undefined);
      if (response.data.statistics) {
        this.convertFiltersStats(response.data.statistics);
      }
      this.setFiltersChanged(true);
    } catch (error) {
      this.loader(false);
      if (!isCancelError(error)) {
        this.setFiltersError(true);
        this.setErrMessages({ error, addMessageToError: false, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        this.setShowError(true);
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      }
    }
  }

  @Action
  updateLoadingProposalOffer(data) {
    if (data) {
      let id = data.offer && data.offer.offerId ? data.offer.offerId : data.id;
      let selectedOffer = this.offers.find(offer => offer.id === id);
      if (selectedOffer) {
        selectedOffer.loadingProposals = data.value;
        this.updateOffers(this.offers);
      }
    }
  }

  @Action
  updateOfferPrice(data) {
    if (data) {
      let selectedOffer = this.offers.find(offer => offer.id === data.offer.offerId);
      if (selectedOffer) {
        let selectedProposalList: any = this.offersProposalsList.find(proposal => proposal.id === data.offer.offerId);
        if (this.newFlightDisplay && selectedProposalList) {
          selectedOffer.offerProposals = selectedProposalList;
          if (selectedOffer.offerProposals) {
            let selectedProposal = selectedOffer.offerProposals.proposals.find(proposal => proposal.id === data.proposal.id);
            selectedOffer.offerProposals.priceChanged = true;
            if (selectedProposal) {
              selectedProposal.price = data.offer.totalPrice;
              selectedProposal.pricePlusMarkup = data.offer.totalPrice;
            }
          }
        } else {
          selectedOffer.proposal.priceChanged = true;
          selectedOffer.proposal.price = data.offer.totalPrice;
          selectedOffer.proposal.pricePlusMarkup = data.offer.totalPrice;
        }
        this.updateOffers(this.offers);
      }
    }
  }

  @Action
  async initAirRailSearch(basketId?: string) {
    this.setCurrentSearchIdFromApi('');
    this.loader(true);
    this.updateFilters([]);
    this.updateRailFilters([]);
    this.setShowErrorBE(false);
    this.setShowError(false);
    this.updateOffers([]);
    this.setProvidersErrors([]);
    this.setTotalOffers(0);
    this.setAverageCo2Emission(null);
    this.setFeeConfigurationId('');
    this.setPriceDetailedComponentsVisible(false);
    this.setNewSort({ sorterCode: 'PRICE', isAscending: true });
    this.setRecommendationsCount(0);
    this.setSearchInProgress(true);
    const params = SearchStore.getAirRailDefaultState;
    const travellersList = SearchStore.getTravellersState;
    this.setSearchCompleted(false);

    const hasVirtualTravellers = travellersList.travellers
      .some(traveller => !!traveller.isVirtual);
    const searchCompanyId = hasVirtualTravellers ? SearchStore.skipTravellersCompany.id : null;

    let request: InitAirSearchMessage | any = {
      searchMode: params.searchMode,
      basketId: null,
      missionId: null,
      travellers: travellersList.travellers,
      searchCompanyId,
      from: params.from,
      to: params.to,
      departureDate: params.departureDate,
      returnDate: params.returnDate || null,
    };

    request.departureTimeSpan = {
      from: params.outwardTimeWindows && params.outwardTimeWindows.departureWindow ? params.outwardTimeWindows.departureWindow[0] : 0,
      to: params.outwardTimeWindows && params.outwardTimeWindows.departureWindow ? params.outwardTimeWindows.departureWindow[1] : 1439,
    };

    request.returnTimeSpan = {
      from: params.inwardTimeWindows && params.inwardTimeWindows.departureWindow ? params.inwardTimeWindows.departureWindow[0] : 0,
      to: params.inwardTimeWindows && params.inwardTimeWindows.departureWindow ? params.inwardTimeWindows.departureWindow[1] : 1439,
    };

    setTimeout(() => {
      SearchStore.select(serviceClassEnum.FlightTrain);
    });

    try {
      let initResponse;

      if (basketId) {
        initResponse = await AirApi.addSegment(request, basketId);
        await BasketStore.getBasketMetadata(basketId);
      } else {
        initResponse = await AirApi.initAirRailSearch(request);
      }

      router.push({
        name: 'airRail',
        params: { searchId: '-' }
      });

      if (initResponse && initResponse.data && this.searchInProgress) {
        this.setCurrentSearchIdFromApi(initResponse.data.searchId);
        this.setSearchInProgress(false);
        clearInterval(this.scrollTimeout);
        this.setTimeoutInterval(setInterval(() => {
          if (!layoutStore.slots.intro.transitioning) {
            clearInterval(this.scrollTimeout);
            router.replace({
              name: 'airRail',
              params: { searchId: initResponse.data.searchId }
            });
          }
        }, 30));
      }
    } catch (error) {
      this.setSearchInProgress(false);
      this.loader(false);
        
      this.setErrMessages({ error });
      this.setShowError(true);
      throw error;
    }
  }

  @Action
  async selectRailOffer(data) {
    try {
      TrainSearchStore.setSelectingOffer(true);
      TrainSearchStore.setSelectedOfferId(data.offer.id);
      let searchId = data.searchId;

      let selectedFare: any = [];
      data.offer.proposals.forEach(proposal => {
        proposal.fares.filter(fare => {
          if (fare.isSelected === true) {
            selectedFare.push({
              segmentKey: proposal.segmentKey,
              fareId: fare.id
            });
          }
        });
      });

      let request = {
        legNumber: 1,
        recommendationId: data.offer.id,
        fares: selectedFare
      };

      let response;

      response = await TrainApi.setTrainProposal({searchId}, request);

      if (response && response.data) {
        const response = await BasketItemApi.addItemAndCreateTrip({
          bookerId: this.currentUser!.profile.id,
          type: 'Rail',
          searchId: searchId
        });
        if (response && response.data) {
          this.setBasketId(response.data.tripId);
          SearchStore.selectBasket(response.data.tripId);
          
          this.stopSearch();
          TrainSearchStore.setSelectedFirstLeg(false);
          TrainSearchStore.setSelectedOffer([]);

          router.push({
            name: 'basket',
            params: { id: this.basketId }
          });
        }


        window.scrollTo(0, 0);
      }

      TrainSearchStore.setSelectingOffer(false);
    } catch (error) {
      this.setErrMessages(error as any);
      this.setShowError(true);
      TrainSearchStore.setSelectingOffer(false);
      TrainSearchStore.setSelectedOfferId('');
    }
  }

  @Action
  async getSearchSession(searchId) {
    try {
      const response = await AirApi.getAirRailSearchSession({
        searchId
      });
      const request = response.data.request as any;
      const getAirportName = (option) => {
        let name = '';
        if (option.type === 'City') {
          name += option.cityName;
          name += ' (' + translate('search.all-airports') + ')';
        } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
          name += option.airportName;
          name += ' (' + option.cityName + ')';
        }
        return name;
      };
      const outwardWindow = SearchConst.searchTimeValues.find(elem => {
        if (request.outwardTimeWindows && request.outwardTimeWindows.departureWindow) {
          return _.isEqual(elem.timeRange, request.outwardTimeWindows.departureWindow);
        }
      }) || SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];
      const inwardWindow = SearchConst.searchTimeValues.find(elem => {
        if (request.inwardTimeWindows && request.inwardTimeWindows.departureWindow) {
          return _.isEqual(elem.timeRange, request.inwardTimeWindows.departureWindow);
        }
      }) || SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1];

      if (!request.departureDate) {
        request.departureDate = moment()
        .add(1, 'week')
        .format('YYYY-MM-DD');
      }

      if (!request.returnDate) {
        request.returnDate = moment()
        .add(1, 'week').add(1, 'days')
        .format('YYYY-MM-DD');
      }

      let legsData =
        request.legs && request.legs.length
          ? request.legs.map(leg => {
              const timeWindows = SearchConst.searchTimeValues.find(elem => {
                if (leg.timeWindows && leg.timeWindows.departureWindow) {
                  return _.isEqual(elem.timeRange, leg.timeWindows.departureWindow);
                }
              });

              return {
                ...leg,
                cabinClasses: [
                  SearchConst.cabinClasses.find(e => {
                    return e.value === leg.cabinClass;
                  })
                ],
                timeWindows: timeWindows ? timeWindows : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
              };
            })
          : {};
          
      const economyClass = SearchConst.cabinClasses
        .find(e => e.value === cabinClassEnum.Economy);

      const params = {
        ...request,
        legs: request.legs && request.legs.length ? [ ...legsData] : [new Legs()],
        cabinClasses: [
          SearchConst.cabinClasses.find(e => {
            return e.value === request.outwardCabinClass;
          }) || economyClass,
          SearchConst.cabinClasses.find(e => {
            return e.value === request.inwardCabinClass;
          }) || economyClass,
        ],
        outwardTimeWindows: outwardWindow ? outwardWindow : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
        inwardTimeWindows: inwardWindow ? inwardWindow : SearchConst.searchTimeValues[SearchConst.searchTimeValues.length - 1],
      };
      const languageCode = AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;

      if (router.currentRoute.params.searchId !== searchId) {
        return;
      }
  
      SearchStore.updateAirRailCurrentState(params);
      SearchStore.updateAirRailDefaultState(params);
      SearchStore.updateSearchModeStatus(response.data.request.searchMode);
      let travellers = {
        travellers: response.data.request.travellers
      };
      SearchStore.updateTravellersDefaultState(travellers);
      SearchStore.updateEditedTravellers(travellers);
      SearchStore.updateSkipTravellers(travellers.travellers.some(trav => {
        return !!trav.isVirtual;
      }));

      let isVirtualTraveller = travellers.travellers.find(trav => trav.isVirtual === true);

      if (isVirtualTraveller) {
        let companyModel =  {
          name: isVirtualTraveller.businessUnitName,
          code: isVirtualTraveller.companyCode,
          id: isVirtualTraveller.businessUnitId,
          isDisabled: false,
          parentId: null,
          rootId: isVirtualTraveller.companyId,
          isSelected: false,
        };

        SearchStore.setSkipTravellersCompany(companyModel);
        EventBus.$emit('update-skip-travellers-company');
      }

      this.updateSearchStateId(response.data.metadata.stateId);
      SearchStore.selectBasket(response.data.metadata.basketId);
      SearchStore.setMissionId(response.data.metadata.missionId);
      this.setPriceDetailedComponentsVisible(response.data.metadata.hasDetailedPriceComponentAccess);
      if (response.data.metadata.missionId !== null) {
        await SearchStore.getMissionDetails();
      }
      if (response.data.metadata.basketId && (!BasketStore.basketMetadata! || BasketStore.basketMetadata!.basketId !== response.data.metadata.basketId)) {
        await BasketStore.getBasketMetadata(response.data.metadata.basketId);
      }

      SearchStore.selectNavigated(serviceClassEnum.FlightTrain);
      SearchStore.select(serviceClassEnum.FlightTrain);
    } catch (error) {
      this.loader(false);
      this.setErrMessages({ error });
      this.setShowError(true);
      if (error.response.status === 404) {
        EventBus.$emit('show-toast', {
          type: translate('common.error'),
          title: translate('common-error.session-unavailable-header'),
          message: translate('common-error.session-unavailable')
        });
        router.push({
          name: 'home'
        });
      }
    }
  }

  @Action
  async AddOfferToCartWithReason(reason) {
    try {
      const response = await AirApi.createBasket(this.searchId, {
        ...this.basketItemsRequest,
        missedSavingReasonCode: reason,
      });

      if (response && response.data) {
        this.stopSearch();
        this.setBasketId(response.data.tripId);
        SearchStore.selectBasket(response.data.tripId);
        if (response.data.confirmationNeeded) {
          this.setPrevSelectedOffer(response.data.previousRecommendation);
          this.setLoadingRestProposals(false);
          this.setShowModal(true);
        } else {
          this.setShowModal(false);
          this.setLoadingRestProposals(false);
          this.toggleMissedSavingsModal(false);
          this.clear();
          router.push({
            name: 'basket',
            params: { id: this.basketId }
          });
        }
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: this.basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async CheckIfCanAddOfferToCart({ searchId, basketItemsRequest }) {
    this.setSearchId(searchId);
    try {
      this.setBasketItemRequest(basketItemsRequest);
      const response = await AirApi.checkIfCanCreateBasket(searchId, basketItemsRequest.recommendationId);

      if (
        response.data &&
        response.data.isMissedSavingsCalculationInProgress === false &&
        (!response.data.missedSaving || (
          response.data.missedSaving &&
          !response.data.missedSaving.missedSavingValue
        ))

      ) {
        if (this.basketItemsRequest.recommendationId === response.data.recommendationId) {
          this.AddOfferToCart(basketItemsRequest);
        }
      } else if (response.data) {
        this.showMissedSavingsModal(response.data);
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async AddOfferToCart(basketItemsRequest) {
    try {
      this.setBasketItemRequest(basketItemsRequest);
      const response = await AirApi.createBasket(this.searchId, basketItemsRequest);
      
      if (response.data && !response.data.missedSaving) {
        this.stopSearch();
        this.setBasketId(response.data.tripId);
        SearchStore.selectBasket(response.data.tripId);
        if (response.data.confirmationNeeded) {
          this.setPrevSelectedOffer(response.data.previousRecommendation);
          this.setLoadingRestProposals(false);
          this.setShowModal(true);
        } else {
          this.setShowModal(false);
          this.setLoadingRestProposals(false);
          this.clear();
          router.push({
            name: 'basket',
            params: { id: this.basketId }
          });
        }
      } else if (response.data) {
        this.showMissedSavingsModal(response.data);
      }
    } catch (error) {
      this.clear();
      this.loader(false);
      this.setShowError(true);

      if (error && error.response && error.response.status === 418 && error.response.data.error.code === 'OFFER_EXPIRED') {
        this.setOfferExpired({
          recommendationId: basketItemsRequest.recommendationId
        });
      } else if (error && error.response && error.response.status === 404) {
        this.setErrMessages({ error, addMessageToError: true, customParams: await this.translateErrorParams(this.fareUnavailableErrorParams) });
        router.push({
          name: 'airRailDetails',
          params: router.currentRoute.params
        });
      } else {
        this.setErrMessages({ error });
      }
    }
  }

  @Action
  async loadProposals(data) {
    this.updateLoadingProposalOffer({id: data.id, value: true});
    try {
      const sortQuery = await this.buildSortQuery();
      let flightsHashes = '';
      data.flightNumberHash.forEach((hash, index) => {
        flightsHashes += `&flightsHashes[${index}]=${hash}`;
      });
      let response = await AirApi.getSearchFlightProposals({searchId: data.searchId, sortQuery, flightsHashes: flightsHashes});

      if (response && response.data) {
        if (response.data.moreProposalsAvailable) {
          this.setShouldReloadProposals(true);
        }

        this.setOffersProposalsList({
          ...response.data,
          id: data.id
        });
      }
      this.updateLoadingProposalOffer({id: data.id, value: false});
    } catch (error) {
      this.loader(false);
      this.setErrMessages(error);
      this.setShowError(true);
    } finally {
      this.updateLoadingProposalOffer({id: data.id, value: false});
    }
  }

  @Action
  translateErrorParams(params) {
    if (params && params.codes) {
      Object.keys(params.codes).forEach(code => {
        params.codes[code] = translate(params.codes[code]);
      });
    }
    return params;
  }

  @Action
  async compareOfferDetails(params) {
    this.setCompareErrMessages(null);
    this.setLoaderCompare(true);
    this.setCompareOfferDetails([]);
    try {
      const initResponse = await CompareOffersApi.offerDetails(params);
      if (initResponse && initResponse.data) {
        this.setCompareOfferDetails(initResponse.data);
        this.setLoaderCompare(false);
      }
    } catch (error) {
      this.setCompareErrMessages(error);
      this.setLoaderCompare(false);
      this.setShowError(true);
    }
  }

}

export default getModule(AirRailSearchStore);
