















































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { userFullName } from '@/core/user-full-name';
import { Traveller } from '@/api/trip/basket.model';
import {
  SeatType,
  TCoach,
  TRailSeatSelection,
  TRailSeatSelectionsModel,
  TSeatMap,
  TSeatMapItem,
} from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';
import EventBus from '@/services/event-handler';
import priceConst from '@/const/price.const';
import consts from '@/const/rail-seat-map.const';
import TrainWagon from '@/modules/basket/railseatmap/TrainWagon.vue';
import TrainLoco from '@/modules/basket/railseatmap/TrainLoco.vue';
import TrainWagonCabin from '@/modules/basket/railseatmap/TrainWagonCabin.vue';
import RailSeatMapLegend from '@/modules/basket/railseatmap/RailSeatMapLegend.vue';
import TrainCabinNormalSeat from '@/modules/basket/railseatmap/TrainCabinNormalSeat.vue';
import TrainCabinBulkhead from '@/modules/basket/railseatmap/TrainCabinBulkhead.vue';
import TrainCabinExit from '@/modules/basket/railseatmap/TrainCabinExit.vue';
import TrainCabinLavatory from '@/modules/basket/railseatmap/TrainCabinLavatory.vue';
import TrainCabinLuggage from '@/modules/basket/railseatmap/TrainCabinLuggage.vue';
import TrainCabinTable from '@/modules/basket/railseatmap/TrainCabinTable.vue';
import TrainCabinWindow from '@/modules/basket/railseatmap/TrainCabinWindow.vue';

const AvailableItems = [
  SeatType.table,
  SeatType.luggage,
  SeatType.exit,
  SeatType.window,
  SeatType.bulkhead,
  SeatType.lavatory,
  SeatType.largeSeat,
  SeatType.normalSeat,
];
const HooverOffset = {
  x: 10,
  y: 30,
};

@Component({
  components: {
    TrainWagonCabin,
    TrainLoco,
    TrainWagon,
    RailSeatMapLegend,
    TrainCabinNormalSeat,
    TrainCabinBulkhead,
    TrainCabinExit,
    TrainCabinLavatory,
    TrainCabinLuggage,
    TrainCabinTable,
    TrainCabinWindow,
  }
})
export default class SeatMapNtvPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() value!: TRailSeatSelectionsModel;
  @Prop() traveller!: Traveller;
  @Prop() segmentId!: string;
  @Prop() seatMap!: TSeatMap;
  @Prop() coaches!: TCoach[];

  userFullName = userFullName;
  selectedCoach: TCoach | null = null;
  selectedSeat: TRailSeatSelection | null = null;
  hoverSeat: TSeatMapItem | null = null;

  get selectedCoachNumber() {
    return this.selectedCoach && this.selectedCoach.number;
  }

  get selectedSeatNumber() {
    return this.selectedSeat && this.selectedSeat.seatNumber;
  }

  get selectedAlternateNumber() {
    return this.selectedSeat && this.selectedSeat.alternateNumber;
  }

  get hooverSeatPrice() {
    const hooverSeatPrice = (this.hoverSeat && this.hoverSeat.prices || []).find((item) => item.travellerId === this.traveller.id);
    return (hooverSeatPrice && hooverSeatPrice.price) || priceConst.priceModel;
  }

  get seatSelectionsModel() {
    if (this.selectedSeat) {
      return this.value.seatSelections
        .filter((item) => !(item.profileId === this.traveller.id && item.segmentId === this.segmentId))
        .concat(this.selectedSeat);
    }
    return [...this.value.seatSelections];
  }

  get mapWidth() {
    const cols = this.selectedCoach && this.selectedCoach.width || 0;
    return consts.margin * 2 + cols * consts.colWidth;
  }

  get mapHeight() {
    const rows = this.selectedCoach && this.selectedCoach.length || - 2 * consts.margin / consts.rowHeight;
    return consts.margin * 2 + rows * consts.rowHeight;
  }

  get seatMapItems() {
    if (this.selectedCoach) {
      const { seatMapItems, width, length } = this.selectedCoach;
      return seatMapItems
        .filter((item) => AvailableItems.indexOf(item.seatType) > -1)
        .map((item) => {
          if (item.seatType === SeatType.largeSeat) {
            return {
              ...item,
              comp: 'TrainCabinNormalSeat',
            };
          }
          return {
            ...item,
            allColumns: width,
            allRows: length,
            comp: 'TrainCabin' + item.seatType,
          };
        });
    }
    return [];
  }

  @Emit('update:show')
  closePopup() {
    return false;
  }

  onContinueClick() {
    if (this.selectedSeatNumber && this.selectedCoachNumber) {
      const seatSelections = this.value.seatSelections
        .filter((item) => !(item.profileId === this.traveller.id && item.segmentId === this.segmentId))
        .concat({
          alternateNumber: this.selectedAlternateNumber,
          seatNumber: this.selectedSeatNumber,
          profileId: this.traveller.id,
          segmentId: this.segmentId,
          coachNumber: this.selectedCoachNumber,
        });
      this.$emit('input', { seatSelections });
      this.closePopup();
    }
  }

  onCoachClick(coach: TCoach) {
    if (coach.available) {
      this.selectedCoach = coach;
    }
  }

  onSelectSeat(seatMapItem: TSeatMapItem) {
    if (!this.selectedCoachNumber) {
      return;
    }
    const seat = this.seatSelectionsModel.find((item) => {
      const isSegment = item.segmentId === this.segmentId;
      const isTraveller = item.profileId === this.traveller.id;
      return isSegment && isTraveller;
    });
    const isTheSameSeat = seat && (seat.seatNumber === seatMapItem.seatNumber && seat && seat.coachNumber === this.selectedCoachNumber);
    if (!isTheSameSeat) {
      this.selectedSeat = {
        alternateNumber: seatMapItem.alternateNumber,
        seatNumber: seatMapItem.seatNumber,
        profileId: this.traveller.id,
        segmentId: this.segmentId,
        coachNumber: this.selectedCoachNumber,
      };
      this.hoverSeat = null;
    }
  }

  onMouseEnterSeat({ item, e }: { item: TSeatMapItem, e: { layerX: number, layerY: number } }) {
    const selectedSeats = this.seatSelectionsModel.filter(({ coachNumber }) => coachNumber === this.selectedCoachNumber);
    const isSelected = selectedSeats.some(({ seatNumber }) => seatNumber === item.seatNumber);
    if (item.seatAvailability === SeatType.open && !isSelected) {
      this.hoverSeat = {
        ...item,
        x: e.layerX + HooverOffset.x,
        y: e.layerY + HooverOffset.y,
      };
    }
  }

  onMouseLeaveSeat() {
    this.hoverSeat = null;
  }

  selectCoach() {
    this.selectedSeat = null;
    const seatSelected = this.value.seatSelections.find((item) => item.profileId === this.traveller.id && item.segmentId === this.segmentId);
    if (seatSelected) {
      this.selectedCoach = this.seatMap.coaches.find((item) => item.number === seatSelected.coachNumber) || null;
      return;
    }
    this.selectedCoach = this.coaches.find((item) => item.available) || null;
  }

  created() {
    EventBus.$on('select-seat', this.onSelectSeat);
    EventBus.$on('mouseenter-seat', this.onMouseEnterSeat);
    EventBus.$on('mouseleave-seat', this.onMouseLeaveSeat);
  }

  beforeDestroy() {
    EventBus.$off('select-seat', this.onSelectSeat);
    EventBus.$off('mouseenter-seat', this.onMouseEnterSeat);
    EventBus.$off('mouseleave-seat', this.onMouseLeaveSeat);
  }

  @Watch('show', { immediate: true })
  onShow(isShow: boolean) {
    if (isShow) {
      this.selectCoach();
    }
  }
}
