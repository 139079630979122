























































import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import SearchStore from './search.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({
  created: () => {
    router.currentRoute.path.includes('ResultsTravellers') ||
    router.currentRoute.path.includes('DetailsTravellers') ?
      SearchStore.updateHideShowLess(false) :
      SearchStore.updateHideShowLess(true);
  }
})
export default class SearchShortTravellers extends Vue {

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get editedTravellers() {
    return SearchStore.editedTravellers;
  }

  get isSearchView() {
    return this.$route.name === 'search';
  }

  get isSearchDetails() {
    return this.$route.name === 'airDetails' ||
      this.$route.name === 'carDetails' ||
      this.$route.name === 'hotelResultsSearch' ||
      this.$route.name === 'trainDetails';
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get basketMetadata() {
    return BasketStore.basketMetadata ? BasketStore.basketMetadata.metadata : null;
  }

  get isEditEnabled() {
    return (this.isSearchView || this.isSearchDetails) && !this.basketMetadata;
  }

  get travellers() {
    if (this.isEditEnabled) {
      return this.editedTravellers.travellers;
    }
    return this.travellersSearchList.travellers;
  }



  userFullName(user) {
    return userFullName(user);
  }

  travellerUrl(trav) {
    return sanitizeUrl('/profile/' + trav.id);
  }

  getTravLabel(guestCode) {
    let option = searchConst.guestTravellerOptions.find(guest => {
      return guest.code === guestCode;
    });
    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    }
  }

  selectMain(trav) {
    if (!this.isEditEnabled || this.skipTravellers) {
      return;
    }
    SearchStore.setMainTraveller(trav);
  }

}
