import moment from 'moment';

import { AirLocationModel, cabinClassEnum } from '@/api/home/home.model';
import SearchConst from '@/const/search.const';
import { LegTimeWindows, Legs } from '@/api/air-engine/air-search.model';
import { Traveller } from '@/api/trip/basket.model';

export class AirSearchStateParams {
  legs: Legs[] = [
    new Legs({legNumber: 0, departureDate: moment()
      .add(1, 'week')
      .format('YYYY-MM-DD')}),
  ];
  searchMode: string = 'RoundTrip';
  from: AirLocationModel | null = null;
  to: AirLocationModel | null = null;
  cabinClass: string = 'Any';
  withNego: boolean = false;
  cabinClasses: any[] = [SearchConst.cabinClasses[0], SearchConst.cabinClasses[0]];
  nonStopsOnly: boolean = false;
  flexOnly: boolean = false;
  isSplitSearchEnabled: boolean = false;
  additionalLuggageOnly: boolean = false;
  outwardCabinClass: string | null = cabinClassEnum.AnyClass;
  outwardTimeWindows: LegTimeWindows | null = null;
  inwardCabinClass: string | null = cabinClassEnum.AnyClass;
  inwardTimeWindows: LegTimeWindows | null = null;
  departureDate: string = moment()
    .add(1, 'week')
    .format('YYYY-MM-DD');
  returnDate: string = moment()
    .add(1, 'week').add(1, 'days')
    .format('YYYY-MM-DD');
  offlineRequestId?: string | null = null;
  alliance: any[] = [];
  airlines: any[] = [];
  connectionAirports: any[] = [];
  searchCompanyId: string | null = null;
  travellers: Traveller[] = [];
  advancedSearchParametersId: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.legs !== undefined) {
      this.legs = params.legs;
    }
    if (params.searchMode !== undefined) {
      this.searchMode = params.searchMode;
    }
    if (params.from !== undefined) {
      this.from = params.from;
    }
    if (params.to !== undefined) {
      this.to = params.to;
    }
    if (params.cabinClass !== undefined) {
      this.cabinClass = params.cabinClass;
    }
    if (params.cabinClasses !== undefined) {
      this.cabinClasses = params.cabinClasses;
    }
    if (params.departureDate !== undefined) {
      this.departureDate = params.departureDate;
    }
    if (params.returnDate !== undefined) {
      this.returnDate = params.returnDate;
    }
    if (params.flexOnly !== undefined) {
      this.flexOnly = params.flexOnly;
    }
    if (params.nonStopsOnly !== undefined) {
      this.nonStopsOnly = params.nonStopsOnly;
    }
    if (params.isSplitSearchEnabled !== undefined) {
      this.isSplitSearchEnabled = params.isSplitSearchEnabled;
    }
    if (params.additionalLuggageOnly !== undefined) {
      this.additionalLuggageOnly = params.additionalLuggageOnly;
    }
    if (params.outwardCabinClass !== undefined) {
      this.outwardCabinClass = params.outwardCabinClass;
    }
    if (params.inwardCabinClass !== undefined) {
      this.inwardCabinClass = params.inwardCabinClass;
    }
    if (params.outwardTimeWindows !== undefined) {
      this.outwardTimeWindows = params.outwardTimeWindows;
    }
    if (params.inwardTimeWindows !== undefined) {
      this.inwardTimeWindows = params.inwardTimeWindows;
    }
    if (params.travellers !== undefined) {
      this.travellers = params.travellers;
    }

    if (params.advancedSearchParametersId !== undefined) {
      this.advancedSearchParametersId = params.advancedSearchParametersId;
    }
  }
}