import { Price } from '@/api/air-engine/air-search.model';

export interface OfferParameter {
  itemId: string;
  key: string;
  displayText: string;
  type: string;
  isRequired: boolean;
  isPerTraveler: boolean;
  options: SupplierParameterOption[];
  value: string | null;
  profileId: string | null;
  isDateFormat: boolean;
  format: string | null;
  momentJsDateFormat: string | null;
}

export interface CreateBasketModel {
  addedToTrip: boolean;
  confirmationNeeded: boolean;
  prevRailSelection: any[];
  tripId: string;
}

export interface SupplierParameterOption {
  key: string;
  displayText: string;
  price: Price;
}

export class BasketParameter {
  itemId: string = '';
  key: string = '';
  value: string | null = '';
  profileId: string | null = null;
}

export interface RailSeatPreferenceRequestModel {
  profileId: string;
  segmentId: string;
  seatPrefrerence?: number;
  coachNumber?: string;
  seatNumber?: string;
}

export enum RailSeatPreferences {
  Aisle = 1,
  Cinema = 2,
  SmartXl = 3,
  Window = 4
}

export enum AirLuftHansaTicketingMessage {
  Wait = 'WaitForConfirmation',
  Skip = 'SkipUnconfirmedElements'
}

export interface RailDiscountCodes {
  isReturnAvailable: boolean;
  forwardDiscountStatus: string;
  returnDiscountCodeStatus: string;
  returnDiscountCodeMessage: string | null;
  forwardDiscountCodeMessage: string | null;
  id: string;
  forwardDiscountCode: string | null;
  returnDiscountCode: string | null;
}

export interface RailDiscountCodesResponse {
  data: RailDiscountCodes[];
  discount: Price;
  priceAfterDiscount: Price;
  priceBeforeDiscount: Price;
}
export enum SabrePreEmdAnswer {
  Wait = 'WaitForConfirmation',
  Skip = 'SkipUnconfirmedElements'
}

export enum AmadeusPreEmdAnswer {
  Wait = 'WaitForConfirmation',
  Skip = 'SkipUnconfirmedElements'
}

export interface PriceChangeDetailsModel {
  from: FlightLocationModel;
  to: FlightLocationModel;
  carrier: FlightCarrierModel;
  departureDate: string | null;
  fromPrice: Price;
  toPrice: Price;
}

export interface FlightLocationModel {
  airportCode: string;
  airportName: string;
  city: string;
  cityName: string;
  state: string;
  country: string;
  terminalId: string;
}

export interface FlightCarrierModel {
  code: string;
  name: string;
  alliance: string;
}

export interface TripItemPaymentInfoRedirectUrl {
  id: string;
  redirectUrl: string;
  isUsed?: boolean;
}

export interface TripItemFeePaymentModel {
  shouldShowFeePaymentInfo: boolean;
  paymentPrice: Price;
  allFees: Price;
}

