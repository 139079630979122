import JourneyAssistant from '@/modules/journey-assistant/JourneyAssistant.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/journey-assistant/:conversationId',
  name: 'journey-assistant',
  components: {
    default: JourneyAssistant,
  },
  meta: {
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    },
    noPaddingBottom: true,
  },
};
