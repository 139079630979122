import Company from '@/modules/settings/company-info/Company.vue';
import companyInfo from './company-info/company-info.route';

export default {
  path: '',
  component: Company,
  displayName: 'settings-sidebar.company-info',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyInfo',
    icon: 'info',
    parentName: 'company-info',
  },
  children: [
    companyInfo,
  ],
};
