import {
  CompanyConfiguration,
} from '@/api/profile/company.model';

export class NegoFaresConfigurationSetting {
  type!: string;
  code!: string;
  parentIndex?: number;
}

export class NegoFaresConfiguration {
  supplier!: string;
  corporateCodes!: NegoFaresConfigurationSetting[];
}

export class NegoFaresConfigurations {
  id!: string;
  name!: string;
  rootCompanyId!: string;
  negoFaresConfigurations!: NegoFaresConfiguration[];
}

export class NegoFareConfigurationModel {
  id: string = '';
  rootCompanyId: string = '';
  configurationType: string = '';
  name: string = '';

  constructor(config?: CompanyConfiguration) {
    if (config) {
      if (config.id !== undefined) {
        this.id = config.id;
      }
      if (config.rootCompanyId !== undefined) {
        this.rootCompanyId = config.rootCompanyId;
      }
      if (config.configurationType !== undefined) {
        this.configurationType = config.configurationType;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
    }
  }
}

export enum CorporateCodeOwnerEnum {
  Unknown = 'Unknown',
  Tmc = 'Tmc',
  Client = 'Client'
}

export enum LeasureFareTypeEnum {
  Tod = 'Tour Operator Deferred',
  Tosd = 'Tour Operator Semi-Deferred',
  Cb = 'Contract Bulk',
  Pfa = 'Private Fare Adult',
  Ht = 'Humanitarian'
}

export enum CorporateCodeTypeEnum {
  Unknown = 'Unknown',
  CorporateId = 'CorporateId',
  Seaman = 'Seaman',
  AccountCode = 'AccountCode',
  Clid = 'Clid',
  PartnerPlusBenefits = 'PartnerPlusBenefits',
  TourOperator = 'TourOperator',
  Vfr = 'Vfr',
  OsiMessage = 'OsiMessage',
  Nam = 'Nam',
  DubbedAAdvantageBusiness = 'DubbedAAdvantageBusiness',
  Negotiated = 'Negotiated',
  Corporate = 'Corporate',
  Leasure = 'Leasure',
  ContractId = 'ContractId',
}

export enum AirlineIdEnum {
  LH = 'LH',
  LX = 'LX',
  OS = 'OS',
  SN = 'SN',
  AF = 'AF',
  AA = 'AA',
  BA = 'BA',
  EK = 'EK',
}

export class CorporateCodesModel {
  codeOwner: CorporateCodeOwnerEnum = CorporateCodeOwnerEnum.Unknown;
  code: string | null = null;
  codeType: CorporateCodeTypeEnum = CorporateCodeTypeEnum.Unknown;
  description: string | null = null;
  airlineId: AirlineIdEnum | null = null;
  status?: string;
  useSpecialTypesOfPax: boolean = false;
  osiMessage: string = '';

  constructor(config?: any) {
    const props: string[] = [
        'codeOwner',
        'code',
        'codeType',
        'description',
        'airlineId',
        'status',
        'useSpecialTypesOfPax',
        'osiMessage',
    ];

    if (!config) {
      return;
    }

    props.forEach(prop => {
      if (config[prop] !== undefined) {
        this[prop] = config[prop];
      }
    });
  }
}
