

































































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { DisplayPreferencesConfigurationsDataRow } from './displayPreferencesConfigurations.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import DisplayPreferencesFlightStore from '@/modules/settings/display-preferences/display-preferences-flight.store';
import DisplayPreferencesCarStore from '@/modules/settings/display-preferences/display-preferences-car.store';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';
import SettingsStore from '@/modules/settings/settings.store';
import EventHandler from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class DisplayPreferencesConfigurations extends BaseTable<DisplayPreferencesConfigurationsDataRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  errors: any[] = [];
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get currentSelectedItem() {
    return DisplayPreferencesStore.currentSelectedItem;
  }

  get count() {
    return this.items.length;
  }

  get addNewConfigurationUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/display-preferences/display-preferences-configuration');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/display-preferences/' + item.id + '/display-preferences-configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;

      if (!preventRedirect) {
        if ( this.$hasAccess('CanEditDisplayPreferences')) {
          router.push({
            name: 'display-preferences-configuration',
            params: {
              configurationId: item.id
            }
          });
        } else {
          router.push({
            name: 'display-preferences-configuration',
            params: {
              configurationId: item.id
            },
            query: {
              readOnly: 'true'
            }
          });
        }
      }
    }
  }

  addNewConfiguration() {
    this.resetData();
  }

  modifyConfiguration(item) {
    this.resetData();
    router.push({
      name: 'display-preferences-configuration',
      params: {
        configurationId: item.id
      },
    });
  }

  resetData() {
    DisplayPreferencesFlightStore.clearFlightRules();
    DisplayPreferencesFlightStore.selectedFlightRule({});
    DisplayPreferencesFlightStore.setFlightRules([]);
    DisplayPreferencesCarStore.setDisplayPreferencesCar();

    DisplayPreferencesStore.clearErrors();
    DisplayPreferencesStore.setCurrentConfigName('');
    DisplayPreferencesStore.setCurrentSelectedItem(this.currentSelectedItem);
    DisplayPreferencesStore.setIsFromManage(false);
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.CanEditDisplayPreferences);
      this.reload();

      const obj = {
        type: 'success',
        title: translate('settings-travel-policy.configuration-removed'),
        message: translate('settings-travel-policy.info-removed')
      };

      EventHandler.$emit('show-toast', obj);
    }
    catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    }
    finally {      
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    try {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.displayPreferencesConfiguration,
        this.params.size,
        this.params.page,
        Permission.CanEditDisplayPreferences,
        request.searchName
      );

      if (result && result.data) {
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.name < b.name) {
        result = -1;
      }
      else if (a.name > b.name) {
        result = 1;
      }
      return result;
    });
  }

  toggleConfigurationMenu(item) {
    if (this.configuration !== item) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  created() {
    let self = this;
    this.configuration = null;
    window.addEventListener('click', function(e) {
      if (self.isRootCompany && self.errors.length === 0) {
        let target = e.target as HTMLInputElement;
        if (!self.$el.children[0].children[2].contains(target)) {
          self.menuActive = false;
        }
      }
    });
  }
}

