import MarkupRuleHotel from '@/modules/settings/agency-markup/MarkupRuleHotel.vue';

export default {
  path: ':configurationId?/hotel-markup-rule',
  name: 'markup-rule-hotel',
  component: MarkupRuleHotel,
  displayName: 'Hotel markup rule definition',
  meta: {
    breadCrumb: 'Hotel markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    parentName: 'markups',
    depthCategory: 'markups',
    depth: 3,
  }
};