export interface CreateConfiguration {
  rootCompanyId?: string;
  name: string;
  configurations: Configuration[];
}

export class PnrRemarksConfiguration {
  rootCompanyId: string = '';
  name: string = '';
  pnrRemarks: Configuration[] = [];

  constructor(item?: any) {
    if (item) {
      if (item.rootCompanyId !== undefined) {
        this.rootCompanyId = item.companyId;
      }
      if (item.name !== undefined) {
        this.name = item.name;
      }
      if (item.pnrRemarks !== undefined) {
        this.pnrRemarks = item.pnrRemarks;
      }
    }
  }
}

export interface UpdateConfigurationResult {
  configurationId: string;
  result: Configuration[];
}

export interface Configuration {
  succeeded?: boolean;
  error?: Error;
  supplier: string;
  pnrRemarks: Remark[];
  pnrSsrCodes?: SsrCodes[];
}

export interface Remark {
  id?: string;
  seqNo: number;
  remark: string;
  type?: string;
  category?: string | null;
  airlines?: any[] | null;
}

export interface SsrCodes {
  airlines?: any[] | null;
  type?: string;
  value: string;
}

export interface Error {
  code: string;
  message: string;
  messageEng: string;
  serviceTag: string;
  details: Detail[];
  devDetails: DevDetails;
  isTranslated: boolean;
  translationLanguage: string;
}


export interface DevDetails {
  extendedInfo: any;
  message: string;
  stacktrace: string;
  serverErrorSource: string;
  innerException: string;
}


export interface Detail {
  code: string;
  message: string;
  messageEng: string;
  field: string;
  params: Params;
  resourceId: string;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface Params {
  additionalProp1: any;
  additionalProp2: any;
  additionalProp3: any;
}

export enum RemarkType {
  AlphaCoded = 'AlphaCoded',
  ClientAddress = 'ClientAddress',
  Corporate = 'Corporate',
  DeliveryAddress = 'DeliveryAddress',
  General = 'General',
  GroupName = 'GroupName',
  Hidden = 'Hidden',
  Historical = 'Historical',
  Invoice = 'Invoice',
  Itinerary = 'Itinerary',
}

export enum AirSupplier {
  Unknown = 'Unknown',
  Fake = 'Fake',
  Sabre = 'Sabre',
  TravelFusion = 'TravelFusion',
  AirFranceKlm = 'AirFranceKlm',
  Lufthansa = 'Lufthansa',
  Amadeus = 'Amadeus',
  EasyJet = 'EasyJet',
  BritishAirways = 'BritishAirways',
  AmericanAirlines = 'AmericanAirlines',
  NDCx = 'NDCx',
  Emirates = 'Emirates'
}

export enum CarSupplier {
  Unknown = 'Unknown',
  Fake = 'Fake',
  Sabre = 'Sabre',
}

export enum AccommodationSupplier {
  Expedia = 'Expedia',
  BookingCom = 'BookingCom',
  Sabre = 'Sabre',
  Amadeus = 'Amadeus'
}