import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  ApprovalWorkflowConfigurationModel,
  DesignatedTravellersResponse,
  DesignatedApproversResponse,
} from './approval-workflow.model';

class ApprovalWorkflowApiClass {
  public getApprovalWorkflowConfiguration(configurationId: string): Promise<AxiosResponse<ApprovalWorkflowConfigurationModel>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/v2/configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public addApprovalWorkflowConfiguration(params: ApprovalWorkflowConfigurationModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/v2/configurations',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateApprovalWorkflowConfiguration(configurationId: string, params: ApprovalWorkflowConfigurationModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/v2/configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getDeisignatedApprovers(profileId: string): Promise<AxiosResponse<DesignatedApproversResponse>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-approvers',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public addDeisignatedApprover(profileId: string, approverId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-approvers',
      method: RequestMethod.PUT
    }, { profileId }, { approverId });
  }

  public removeDeisignatedApprovers(profileId: string, approvers: string[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-approvers',
      method: RequestMethod.DELETE
    }, { profileId }, approvers, true);
  }

  public getDeisignatedTravellers(profileId: string): Promise<AxiosResponse<DesignatedTravellersResponse>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-travellers',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public addDeisignatedTraveller(profileId: string, travellerId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-travellers',
      method: RequestMethod.PUT
    }, { profileId }, { travellerId });
  }

  public removeDeisignatedTravellers(profileId: string, travellers: string[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiApprovalWorkflow + '/api/profiles/{profileId}/designated-travellers',
      method: RequestMethod.DELETE
    }, { profileId }, travellers, true);
  }
}

export const ApprovalWorkflowApi: ApprovalWorkflowApiClass = new ApprovalWorkflowApiClass();