
export class Country {
  code: string;
  countryName: string;
  threeLetterCode: string;
  phoneCode: string | null;

  public constructor() {
    this.code = '';
    this.countryName = '';
    this.threeLetterCode = '';
    this.phoneCode = null;
  }
}

export class City {
  id: string;
  iataCode: string;
  name: string;
  countryCode: string;
  countryName: string;
  isMultipleAirportCity: boolean;

  public constructor() {
    this.id = '';
    this.iataCode = '';
    this.name = '';
    this.countryCode = '';
    this.countryName = '';
    this.isMultipleAirportCity = false;
  }
}

export interface AirLine {
  code: string;
  businessName: string;
}

export interface CarExtra {
  code: string;
  comment: string;
  name: string;
  priority: number;
}

export interface LocationModel {
  code: string | null;
  type: LocationType;
  airportName: string;
  cityName: string;
  countryCode: string;
}

export interface HotelLocationModel {
  identifier: string | null;
  type: string;
  countryCode: string;
  name: string;
  address: string | null;
  displayText: string;
  location: LatLong | null;
  cityName: string;
}

export interface GeoAreaModel {
  type: string;
  id: string;
  code: string;
  name: string;
}

export interface RegionModel {
  adm1Name: string;
  adm2Name: string;
  adm3Name: string;
  adm4Name: string;
  code: string;
  countryCode: string;
  countryName: string;
  id: string;
  name: string;
  type: string;
}

export interface CityCountryModel {
  type: string;
  cityId: string;
  cityName: string;
  cityCode: string;
  countryCode: string;
  countryName: string;
}

export interface CountryContinentModel {
  type: string;
  code: string;
  name: string;
}

export interface RailwayLocationModel {
  latitude: number | null;
  longitude: number | null;
  type: RailGeoPointType;
  stationName: string | null;
  stationCodes: string[];
  cityName: string | null;
  cityCodes: string[];
  countryCode: string | null;
  countryName: string | null;
  stationId: string;
  cityId: string;
  countryId: string;
}

export interface GeoPointDescription {
  code: string | null;
  name: string | null;
  type: RailGeoPointType;
  id: string;
}

export interface LatLong {
  lat: number;
  lon: number;
}

export interface Query {
  query: string;
}

export interface CitiesNamesModel {
  id: string;
  cityName: string;
  countryName: string;
}

export enum LocationType {
  Airport = 'Airport',
  City = 'City',
}

export enum DistanceUnit {
  Unknown = 'Unknown',
  Kilometers = 'Kilometers',
  Miles = 'Miles',
}

export enum LanguageCode {
  EN = 'EN',
  FR = 'FR',
  IT = 'IT',
  PL = 'PL',
}

export enum AirlineType {
  LowCost = 1,
  Regular = 2
}

export enum RoutingType {
  Domestic = 1,
  International = 2,
  Intercontinental = 3
}

export enum DistributionChannelTypes {
  Direct = 1,
  Gds = 2,
  Aggregator = 3,
}

export enum GeographicalAreaTypes {
  Country = 'Country',
  Continent = 'Continent'
}

export enum AirportLocationTypes {
  Airport = 1,
  City = 2,
  CityAirport = 3
}

export enum GeoAreaTypes {
  City = 0,
  Country = 1,
  Continent = 2,
}

export enum RailGeoPointType {
  Station = 0,
  City = 1,
  Country = 2
}
