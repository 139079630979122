














































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import { translate, translationExists } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import { TrainApi } from '@/api/train-engine/train-search.api';
import searchConst from '@/const/search.const';
import SearchStore from '../search.store';

const eventName = 'update:show';

@Component({})
export default class DiscountsAndVouchersPopup extends Vue {
  @Prop() show!: boolean;

  errors: any[] = [];
  loading: boolean = true;
  loaded: boolean = false;
  suppliers: string[] = [];
  data: any = null;
  selectedTravellerIndex: number = -1;
  saving: boolean = false;
  showNotAvailableInfo: boolean = false;
  get title() {
    return translate('rail-discount-codes.travel-preferences-title');
  }

  get travellers() {
    return SearchStore.editedTravellersState.travellers;
  }

  get trainSearchState() {
    return SearchStore.getTrainDefaultState;
  }

  get searchCompanyId() {
    if (!this.travellers.length) {
      return '';
    }

    const mainTraveller = this.travellers.find((item) => !!item.isMainTraveller);
    if (mainTraveller && !mainTraveller.isVirtual) {
      return mainTraveller.businessUnitId;
    }
    return SearchStore.skipTravellersCompany.id;
  }

  get options() {
    if (!this.data || this.selectedTravellerIndex < 0 || this.selectedTravellerIndex >= this.data.length) {
      return null;
    }
    return this.data[this.selectedTravellerIndex];
  }

  get disabilityKind() {
    if (!this.options) {
      return null;
    }
    return this.disabilityKindOptions.find(item => item.code === this.options.disabilityKind) || null;
  }

  set disabilityKind(value) {
    if (!this.options) {
      return;
    }
    this.options.disabilityKind = value.code;
  }

  get disabilityKindOptions() {
    const defaultEmpty = {
      label: '',
      code: '',
    };

    if (!this.options) {
      return [defaultEmpty];
    }

    return [defaultEmpty, ...this.options.disabilityKinds];
  }

  get hasSncf() {
    return -1 < this.suppliers.indexOf('Sncf');
  }

  get hasOuigo() {
    return -1 < this.suppliers.indexOf('Ouigo');
  }

  get currentCompanyId() {
    if (SearchStore.skipTravellers) {
      return SearchStore.skipTravellersCompany.id;
    }
    const mainTraveller = SearchStore.editedTravellers.travellers.find((item) => !!item.isMainTraveller);
    if (mainTraveller && !mainTraveller.isVirtual) {
      return mainTraveller.businessUnitId;
    }
    return SearchStore.editedTravellers.travellers[0].businessUnitId;
  }


  codeTranslations(label) {
    const key: string = 'discountCardAccompanyingPersonFareProfiles.' + label;

    if (translationExists(key)) {
      return translate(key);
    }

    return label;
  }


  userFullName(user) {
    return userFullName(user);
  }

  sortValues(item) {
    const sortByCode = (a, b) => {
      if (a.code < b.code) {
        return -1;
      } else if (a.code > b.code) {
        return 1;
      }

      return 0;
    };

    return {
      discountCardAccompanyingPersonFareProfiles: item.discountCardAccompanyingPersonFareProfiles
        .sort(sortByCode),
      disabledPassengerAccompanyingPersonFareProfiles: item.disabledPassengerAccompanyingPersonFareProfiles
        .sort(sortByCode),
    };
  }

  async loadSupplier(supplier) {
    let data: any = null;
    let available = false;
    let errors: any[] = [];

    try {
      const response = await TrainApi.getAdvancedOptions(supplier, {
        searchCompanyId: this.searchCompanyId,
        travellers: this.travellers,
      });

      data = response.data;
      available = true;
    } catch (error) {
      if (
        !(
          error.response && error.response.data &&
          error.response.data.error &&
          error.response.data.error.code &&
          -1 < [
            'NO_ACTIVE_CONFIGURATION',
            'NO_RAIL_PROVIDER_CONFIGURATION_FOUND',
          ].indexOf(error.response.data.error.code)
        )
      ) {
        errors = this.$handleErrors(error);
      }
    }

    return {
      supplier,
      data,
      available,
      errors,
    };
  }

  async applyData(data) {
    const defaultItems = {
      discounts: [],
      disabilityKind: '',
      disabilityDiscounts: [],
    };

    if (this.trainSearchState.advancedSearchParametersId) {
      const { data: currentOptions } = await TrainApi.getSavedAdvancedOptions(this.suppliers[0], this.trainSearchState.advancedSearchParametersId);

      this.data = data.map((item, index) => {
        const sortedValues = this.sortValues(item);
        let savedData = currentOptions.find(el => el.travellerId === item.traveller.id);
        if ((this.travellers[0] as any).isVirtual === true) {
          savedData = currentOptions[index];
        }
      
        if (!savedData) {
         
          return {
            ...item,
            ...defaultItems,
            ...sortedValues,
          };
        }

        return {
          ...item,
          traveller: {
            ...item.traveller,
            voucherCode: savedData.voucherCode,
            infantSeatPlace: savedData.selectedInfantSeat
          },
          ...sortedValues,
          disabilityKind: savedData.disabilityKindCode,
          discounts: savedData.discountCardAccompanyingPassengerFareProfiles,
          disabilityDiscounts: savedData.disabledPassengerAccompanyingPassengerFareProfiles,
        };
      });
    } else {
      this.data = data.map(item => {
        this.setDefaultsForTraveller(item);
        const sortedValues = this.sortValues(item);
        return {
          ...item,
          ...defaultItems,
          ...sortedValues,
        };
      });
    }
  }

  setDefaultsForTraveller(item: any) {
    if (item.traveller.isInfantTraveller) {
          item.traveller.infantSeatPlace = 'lap';
        }
       
  }
  async loadData() {
    let result: any = null;

    try {
      const response = await TrainApi.getAdvancedOptionsAvailability(this.currentCompanyId);
      this.suppliers = response.data;

      if (this.hasSncf) {
        result = await this.loadSupplier('Sncf');
      }
      if ((!result || !result.available) && this.hasOuigo) {
        result = await this.loadSupplier('Ouigo');
      }

      if (result && result.available) {
        await this.applyData(result.data);
        this.selectedTravellerIndex = 0;
        this.loaded = true;
      }

      if (result && !result.available && !result.errors.length) {
        this.showNotAvailableInfo = true;
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  async confirmNow() {
    const request = this.data.map(options => {
      return {
        travellerId: options.traveller.id,
        voucherCode: options.traveller.voucherCode,
        disabilityKindCode: options.disabilityKind,
        discountCardAccompanyingPassengerFareProfiles: options.discounts,
        disabledPassengerAccompanyingPassengerFareProfiles: options.disabilityDiscounts,
        passengerTypeCode: options.traveller.passengerTypeCode,
        selectedInfantSeat: options.traveller.infantSeatPlace,
      };
    });

    this.saving = true;

    try {
      if (this.trainSearchState.advancedSearchParametersId) {
        await TrainApi.updateAdvancedOptions(this.suppliers[0], this.trainSearchState.advancedSearchParametersId, request);
      } else {
        const response = await TrainApi.createAdvancedOptions(this.suppliers[0], request);

        this.trainSearchState.advancedSearchParametersId = response.data;
      }

      this.hidePopup();
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.saving = false;
    }
  }

  selectTraveller(index) {
    this.selectedTravellerIndex = index;

    const travellerRef = (this.$refs.traveller as HTMLElement[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', travellerRef);
  }

  getTravellerLabel(guestCode) {
    const option = searchConst.guestTravellerOptions.find(guest => {
      return guest.code === guestCode;
    });
    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    }

    return guestCode;
  }

  resetPopup() {
    this.suppliers = [];
    this.showNotAvailableInfo = false;
    this.data = null;
    this.loading = true;
    this.loaded = false;
    this.saving = false;
    this.errors = [];
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }

  @Watch('show', { immediate: true })
  onShow(value) {
    if (!value) {
      return;
    }

    this.resetPopup();
    this.loadData();
  }

}

