


























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { LegFlight, Recommendation } from '@/api/air-engine/air-search.model';
import moment from 'moment';

import SearchStore from '@/modules/search/search.store';
import { technicalStopTooltip } from './technical-tooltip.service';

@Component({
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('dddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class FlightTimelineDetailsView extends Vue {
  @Prop() leg!: LegFlight;
  @Prop() offer!: Recommendation;
  @Prop({default: false}) isInBasket!: boolean;
  
  get travellers() {
    return SearchStore.getTravellersState;
  }

  get offerLegs() {
    if (!this.offer || !this.offer.legs) {
      return [];
    }

    return this.offer.legs.map(leg => {
      return {
        ...leg,
        numberOfTechStops: leg.flights.map(flight => {
          return flight.additionalStops && flight.additionalStops.length || 0;
        }).reduce((prev, cur) => prev + cur, 0),
        flights: leg.flights.map(flight => {
          return {
            ...flight,
            additionalStops: (flight.additionalStops || []).map(stop => {
              return {
                ...stop,
                tooltip: this.technicalStopTooltip(stop),
              };
            }),
          };
        }),
      };
    });
  }


  technicalStopTooltip(stop) {
    return technicalStopTooltip(stop);
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  legFlights(leg) {
    return leg.flights.map(flight => {
      if (flight.selectedSeats && flight.selectedAncillaries) {
        if (flight.selectedSeats.length || flight.selectedAncillaries.length) {
          return {
            ...flight,
            travellers: this.travellers.travellers.map(traveller => {
              return {
                ...traveller,
                seat: flight.selectedSeats.length ? flight.selectedSeats.find(seat => traveller.id === seat.profileId) : null,
                ancillaries: flight.selectedAncillaries.length ? flight.selectedAncillaries.filter(ancillary => traveller.id === ancillary.profileId && flight.id === ancillary.flightId) : null,
              };
            }),
          };
        } else {
          return {
            ...flight,
          };
        } 
      } else {
        return {
          ...flight,
        };
      }
    });
  }
}
