






































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import _ from 'lodash';

import { userFullName } from '@/core/user-full-name';

@Component({})
export default class TripsLastBookedTripsWidgetItem extends Vue {
  @Prop() trip!: any;

  serverErrors: any[] = [];
  cancelPopupVisible: boolean = false;
  confirmPopupVisible: boolean = false;

  get mainTraveller() {
    return this.trip.travellers.find(trav => {
      return trav.isMainTraveller;
    });
  }

  get travellers() {
    return _.sortBy(this.trip.travellers, (trav) => {
      return trav.isMainTraveller ? 1 : 0;
    });
  }

  get travellerName() {
    if (this.mainTraveller) {
      return userFullName(this.mainTraveller);
    }
    return userFullName(this.trip.travellers[0]);
  }

  get travellerBusinessUnit() {
    return this.mainTraveller ?
      this.mainTraveller.businessUnitName :
      this.trip.travellers[0].businessUnitName; 
  }

  get travellerCompany() {
    return this.mainTraveller ? 
      this.mainTraveller.companyName :
      this.trip.travellers[0].companyName;
  }

  get travellerCompanyString() {
    if (this.travellerBusinessUnit && this.travellerCompany) {
      return `${this.travellerBusinessUnit} | ${this.travellerCompany}`;
    } else if (!this.travellerBusinessUnit && this.travellerCompany) {
      return this.travellerCompany;
    } else if (this.travellerBusinessUnit && !this.travellerCompany) {
      return this.travellerBusinessUnit;
    } else {
      return false;
    }
  }

  get tripTargetCity() {
    return this.trip.destinations[0];
  }

  @Emit()
  reload() {}
}

