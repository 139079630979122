import accountStore from '@/store/account.store';
import _ from 'lodash';

export default (value: string | string[], allRequired?: boolean): boolean => {
  let hasAccess: boolean = false;

  if (Array.isArray(value)) {
    const permissionsArray = value as string[];
    if (allRequired) {
      hasAccess = _.every(permissionsArray, x => accountStore.HasPermission(x));
    } else {
      hasAccess = _.some(permissionsArray, x => accountStore.HasPermission(x));
    }
  } else {
    const permissionCode = value as string;
    hasAccess = accountStore.HasPermission(permissionCode);
  }

  return hasAccess;
};
