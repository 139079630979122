import {
  getModule,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';

import { store } from '@/store';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'customMessages'
})
class CustomMessagesStore extends VuexModule {
  selected: any = null;
  hasMessages: boolean = false;

  @Mutation
  setSelectedMessage(message) {
    this.selected = message;
  }

  @Mutation
  setHasMessages(value) {
    this.hasMessages = value;
  }

}

export default getModule(CustomMessagesStore);
