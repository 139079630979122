









































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import EventBus from '@/services/event-handler';
import filtersConst from '@/const/filter.const';
import SearchConst from '@/const/search.const';
import { Debounce } from '@/core/decorators/debounce.decorator';
import SearchStore from '@/modules/search/search.store';
import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import RangeFilter from '../controls/RangeFilter.vue';
import RangeTimeSlider from '../controls/RangeTimeFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import AirRailSearchStore from './air-rail-search.store';
import { translate } from '@/i18n';
import price from '@/const/price.const';
import RangeCo2EmissionFilter from '@/modules/search/controls/RangeCo2EmissionFilter.vue';

@Component({
  components: {
    RangeCo2EmissionFilter,
    CategoriesFilter,
    RangeFilter,
    FiltersContentLoading,
    RangeTimeSlider,
  }
})
export default class AirRailResultsFilters extends Vue {

  get filtersMetadata() {
    return {
      rangeFilters: filtersConst.air.filtersdata.rangeFilters.map((item) => {
        return {
          ...item,
          label: translate(item.label),
          type: item.type,
        };
      }),
      categoriesFilters: filtersConst.air.filtersdata.categoriesFilters
        .filter(item => {
          return item.code !== 'WITHOUT_AIRPORT_CHANGE' || this.roundTrip;
        })
        .map((item) => {
          return {
            ...item,
            label: translate(item.label)
          };
        }),
      hashFilters: filtersConst.air.filtersdata.hashFilters,
    };
  }

  get filtersOrder() {
    return [
      {
        type: 'air',
        code: 'SUPPLIER',
        filterType: 'category',
      },
      {
        type: 'rail',
        code: 'SUPPLIER',
        filterType: 'category',
      },
      {
        type: 'air',
        code: '',
        filterType: 'range',
      },
      {
        type: 'air',
        code: 'AIRLINE',
        filterType: 'category',
      },
      {
        type: 'air',
        code: 'CABINCLASS',
        filterType: 'category',
      },
      {
        type: 'rail',
        code: 'CABINCLASS',
        filterType: 'category',
      },
      {
        type: 'air',
        code: 'STOPS',
        filterType: 'category',
      },
      {
        type: 'rail',
        code: 'CHANGES',
        filterType: 'category',
      },
      {
        type: 'air',
        code: '',
        filterType: 'category',
      },
      {
        type: 'rail',
        code: '',
        filterType: 'category',
      },
    ];
  }

  get allFiltersByOrder() {
    const airOtherFilters = this.categoriesFilters
      .filter(filter => !this.filtersOrder
        .find(f => f.code !== '' && f.code === filter.code && f.type === 'air')
      )
      .map(item => ({
        ...item,
        airRailType: 'air',
      }));
    const railOtherFilters = this.railCategoriesFilters
      .filter(filter => !this.filtersOrder
        .find(f => f.code !== '' && f.code === filter.code && f.type === 'rail')
      )
      .map(item => ({
        ...item,
        airRailType: 'rail',
      }));
    
    return this.filtersOrder
      .reduce((prev: any, cur: any) => {
        if (cur.type === 'air') {
          if (cur.code === '' && cur.filterType === 'category') {
            return [...prev, ...airOtherFilters];
          } else if (cur.code !== '' && cur.filterType === 'category') {
            const filter = this.categoriesFilters.find(f => f.code === cur.code);
            return [...prev, {
              ...filter,
              airRailType: 'air',
            }];
          }
          
          return [...prev, ...this.rangeFilters.map(f => ({ ...f, airRailType: 'air' }))];
        }
        
        if (cur.code === '' && cur.filterType === 'category') {
          return [...prev, ...railOtherFilters];
        }

        const filter = this.railCategoriesFilters.find(f => f.code === cur.code);

        if (!filter) {
          return prev;
        }

        return [...prev, {
          ...filter,
          airRailType: 'rail',
        }];
      }, []);
  }

  get roundTrip() {
    return this.searchModeStatus === 'RoundTrip';
  }

  get filters() {
    return AirRailSearchStore.filters;
  }

  get railFilters() {
    return AirRailSearchStore.railFilters;
  }

  get offers() {
    return AirRailSearchStore.offers;
  }

  get loadingFilter() {
    return AirRailSearchStore.loading;
  }

  get searchModeStatus() {
    return SearchStore.airRailSearchParameters.searchMode;
  }

  get currency() {
    return AirRailSearchStore.currency || price.priceModel.currency;
  }

  get globalClass() {
    return {
      'md-hidden-mobile': !this.$route.meta.mobile,
      'filters-loading': this.isDisabled,
      'filters-container': this.$route.meta.mobile,
    };
  }

  get isDisabled() {
    return AirRailSearchStore.isSelected;
  }

  get hasFiltersError() {
    return AirRailSearchStore.filtersError;
  }

  get fareUnavailableCode() {
    return AirRailSearchStore.fareUnavailableErrorParams;
  }

  get rangeFilters() {
    return this.filtersMetadata.rangeFilters.map((m) => {
      if (m.code === 'OUTBOUND_DEPART_TIME' || m.code === 'INBOUND_DEPART_TIME' || this.legRangeFilter(m)) {
        const filterData = this.getFilterData(m.code);
        const filterValue = this.getFilterTimeData(m.code);

        if (m.translate && filterValue) {
          filterValue.forEach(f => {
            if (f.name) {
              f.name = translate('search-air-filters.' + f.code);
            }
          });
        }

        return {
          code: m.code,
          label: m.label,
          type: m.type,
          data: filterData,
          values: filterValue,
        };
      } else {
        const filterData = this.getFilterData(m.code);
        return {
          code: m.code,
          label: m.label,
          type: m.type,
          data: filterData
        };
      }
    });
  }

  get railFiltersMetadata() {
    const mapper = (item) => {
      return {
        ...item,
        label: translate(item.label),
        type: item.type,
      };
    };
    return {
      categoriesFilters: filtersConst.train.filters.categoriesFilters.map(mapper),
    };
  }

  get categoriesFilters() {
    return this.filtersMetadata.categoriesFilters.map((category) => {
      const data = {
        type: category.type,
        code: category.code,
        label: category.label,
        maxCategoriesInCollapsedMode: category.maxCategoriesInCollapsedMode,
        data: this.getFilterData(category.code),
        stickTop: !!category.stickTop,
      };

      if (category && category.itemLabels && data.data) {
        data.data.forEach(d => {
          d.name = category.itemLabels![d.code] ? translate(category.itemLabels![d.code]) : d.name;
        });
      }

      return data;
    });
  }

  get railCategoriesFilters() {
    return this.railFiltersMetadata.categoriesFilters
      .map((m) => {
        const data = {
          code: m.code,
          label: m.label,
          type: m.type,
          maxCategoriesInCollapsedMode: m.maxCategoriesInCollapsedMode,
          data: this.getRailFilterData(m.code),
          stickTop: !!m.stickTop,
        };

        if (m.translate && data.data) {
          data.data.forEach(d => {
            if (d.name) {
              d.name = translate('search-train-filters.' + d.code);
            }
          });
        }

        return data;
      })
      .filter(filter => this.railFilterVisible(filter) && filter.type === 'category')
      .filter(filter => filter.data.length > 0);
  }

  get hashFilters() {
    return this.filtersMetadata.hashFilters.map(m => {
      const data = {
        code: m.code,
        data: this.getFilterData(m.code)
      };

      return data;
    });
  }

  get rangeCo2EmissionFilter() {
    return this.rangeFilters
      .find((filter: any) => filter.type === 'rangeCo2');
  }

  get rangeCo2EmissionFilterVisible() {
    return this.rangeCo2EmissionFilter
      && this.rangeCo2EmissionFilter.data
      && this.rangeCo2EmissionFilter.data.maxLimit > this.rangeCo2EmissionFilter.data.minLimit;
  }

  get averageCo2Emission() {
    return AirRailSearchStore.averageCo2Emission;
  }

  get searchCompleted() {
    return AirRailSearchStore.searchCompleted;
  }



  legRangeFilter(m) {
    return -1 !== [...SearchConst.legNumberData].indexOf(m.code);
  }

  legFilterExistInFilters(filterName) {
    return !!this.filters.find(filter => filter.code === filterName);
  }

  railLegFilterExistInFilters(filterName) {
    return !!this.railFilters.find(filter => filter.code === filterName);
  }

  convertRailCategoryFilter(filter) {
    return {
      code: filter.code,
      requiredCategories: filter.data.filter(v => v.selected).map(v => v.code),
    };
  }

  buildRailFiltersDataPayload() {
    return {
      stringCategoriesFilters: this.railCategoriesFilters.map(f => this.convertRailCategoryFilter(f)),
    };
  }

  filterVisible(filterMetadata) {
    const isVisible = filterMetadata.isVisible;
    const roundtripVisible = filterMetadata.code === 'INBOUND_DEPART_TIME' && this.searchModeStatus === 'OneWay';
    const multilegTripVisible = this.legFilterExistInFilters(filterMetadata.code);

    const supplierFilterInvisible = filterMetadata.code === 'SUPPLIER' && !this.$hasAccess('CanSeeProviderName');
    
    if (isVisible !== undefined && roundtripVisible) {
      return isVisible(this);
    } else if (roundtripVisible || supplierFilterInvisible || !multilegTripVisible) {
      return false;
    } else {
      return true;
    }
  }

  railFilterVisible(filterMetadata) {
    const isVisible = filterMetadata.isVisible;
    const roundtripVisible = filterMetadata.code === 'INBOUND_DEPART_TIME' && this.searchModeStatus === 'OneWay';
    const multilegTripVisible = this.railLegFilterExistInFilters(filterMetadata.code);

    const supplierFilterInvisible = filterMetadata.code === 'SUPPLIER' && !this.$hasAccess('CanSeeProviderName');

    if (isVisible !== undefined && roundtripVisible) {
      return isVisible(this);
    } else if (roundtripVisible || supplierFilterInvisible || !multilegTripVisible) {
      return false;
    } else {
      return true;
    }
  }

  getFilterData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.values : null;
  }

  getRailFilterData(filterCode) {
    const filter = this.railFilters.find(f => f.code === filterCode);
    return filter ? filter.values : null;
  }

  getFilterTimeData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.data : null;
  }

  convertRangeFilter(filter) {
    const minEnabled = filter.data && filter.data.minValue !== filter.data.minLimit;
    const maxEnabled = filter.data && filter.data.maxValue !== filter.data.maxLimit;

    return {
      code: filter.code,
      min: minEnabled ? filter.data.minValue : null,
      max: maxEnabled ? filter.data.maxValue : null,
    };
  }

  convertCategoryFilter(filter) {
    return {
      code: filter.code,
      requiredCategories: filter.data ? filter.data.filter(v => v.selected).map(v => v.code) : [],
    };
  }

  convertHashFilter(filter) {
    return {
      code: filter.code,
      hashes: filter.data != null ? filter.data : [],
    };
  }

  buildFiltersDataPayload() {
    return {
      numericRangeFilters: this.rangeFilters.map(f => this.convertRangeFilter(f)),
      categoriesFilters: this.categoriesFilters.map(f => this.convertCategoryFilter(f)),
      hashFilters: this.hashFilters.map(f => this.convertHashFilter(f)),
    };
  }

  refreshFiltersComponents(refs) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      EventBus.$emit('refresh-filter-component', {
        serviceType: 'air',
        code: element.code,
        data: this.getFilterData(element.code),
      });
    });
  }

  refreshRailFiltersComponents(refs) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      EventBus.$emit('refresh-filter-component', {
        serviceType: 'rail',
        code: element.code,
        data: this.getRailFilterData(element.code),
      });
    });
  }

  callFiltersChanged() {
    const searchId = this.$route.params.searchId;

    AirRailSearchStore.setFiltersRequest({
      filtersData: this.buildFiltersDataPayload(),
      railFiltersData: this.buildRailFiltersDataPayload(),
      searchId,
    });
    AirRailSearchStore.setFiltersChanging(true);
    this.filtersChanged();
  }

  @Debounce({
    delay: 300,
  })
  filtersChanged() {
    this.filterOffersMethod();
  }

  async filterOffersMethod() {
    const requestId = AirRailSearchStore.filtersRequestId + 1;
    try {
      const searchId = this.$route.params.searchId;
      if (!searchId) {
        AirRailSearchStore.setFiltersChanging(false);
        return;
      }

      await AirRailSearchStore.updateFilterOffers(AirRailSearchStore.filtersRequest);
      AirRailSearchStore.setFiltersChanging(false);
      if (!this.hasFiltersError && requestId === AirRailSearchStore.filtersRequestId) {
        AirRailSearchStore.getOffers(searchId);
      }
      EventBus.$emit('hash-refresh');
    } catch (error) {
      AirRailSearchStore.setErrMessages({ error, customParams: this.fareUnavailableCode, addMessageToError: true });
    }
  }

  updateHashFilter() {
    this.callFiltersChanged();
  }

  categoriesFilterUpdated(value) {
    AirRailSearchStore.clearOpenOffers();
    AirRailSearchStore.setFilterLoadingView(true);
    AirRailSearchStore.loader(true);
    AirRailSearchStore.updateOffers([]);
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values = value.data;
    this.callFiltersChanged();
  }

  railCategoriesFilterUpdated(value) {
    const filter: any = this.railFilters.find((f: any) => f.code === value.code);
    filter.values = value.data;
    this.callFiltersChanged();
  }

  rangeFilterUpdated(value) {
    AirRailSearchStore.clearOpenOffers();
    AirRailSearchStore.setFilterLoadingView(true);
    AirRailSearchStore.loader(true);
    AirRailSearchStore.updateOffers([]);
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values.minValue = value.data.min;
    filter.values.maxValue = value.data.max;

    this.callFiltersChanged();
  }

  showResults() {
    router.push({ name: 'airRail' });
  }



  @Watch('filters')
  onChangeFilters() {
    const refs = [
      this.$refs.topCategoriesFilters,
      this.$refs.categoriesFilters,
      this.$refs.rangeTimeFilters,
      this.$refs.rangeFilters,
    ];
    refs.forEach(refList => this.refreshFiltersComponents(refList));

    this.refreshRailFiltersComponents(this.$refs.railCategoriesFilters);
  }



  async created() {
    EventBus.$on('update-hashfilter', this.updateHashFilter);

    if (this.$route.meta.mobile) {
      let searchId = this.$route.params.searchId;

      if (searchId === AirRailSearchStore.searchId) {
        return;
      }
      AirRailSearchStore.clear();
    }
  }

  beforeDestroy() {
    EventBus.$off('update-hashfilter', this.updateHashFilter);
  }

}
