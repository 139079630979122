import FeeRuleHotel from '@/modules/settings/agency-fees/FeeRuleHotel.vue';

export default {
  path: ':configurationId?/hotel-fee-rule',
  name: 'fee-rule-hotel',
  component: FeeRuleHotel,
  displayName: 'Hotel fee rule definition',
  meta: {
    breadCrumb: 'Hotel fee rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    parentName: 'fees',
    depthCategory: 'fees',
    depth: 3,
  }
};