export const FareConditionSuppliers = [
    'AirFranceKlm',
    'BritishAirways',
    'Amadeus',
    'EasyJet',
    'Sabre',
    'Lufthansa',
    'TravelFusion',
    'AmericanAirlines',
    'NDCx',
    'Emirates',
];