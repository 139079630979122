












import { Vue, Component } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import BasketTravellerFillDateOfBirthForm from './BasketTravellerFillDateOfBirthForm.vue';

@Component({
  components: {
    BasketTravellerFillDateOfBirthForm,
  },
})
export default class BasketTravellersFillDateOfBirth extends Vue {

  get travellers() {
    return SearchStore.travellersSearch.travellers;
  }

  async mounted() {
    await BasketStore.updateBasket();
  }
}
