import TravellerCategories from '@/modules/settings/traveller-categories/TravellerCategories.vue';

export default {
  path: '',
  name: 'settings-traveller-categories',
  component: TravellerCategories,
  displayName: 'settings-sidebar.traveller-categories',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditTravellerCategories',
    parentName: 'traveller-categories',
  },
};
