



































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import messages from '@/const/error-messages.const';
import BasketStore from './basket.store';
import SearchStore from '@/modules/search/search.store';
import DictionaryStore from '@/store/dictionary.store';
import { TripApi } from '@/api/trip/trip.api';
import { BasketApi } from '@/api/trip/basket.api';
import { PhoneCode } from '@/api/profile/contacts.model';
import EventBus from '@/services/event-handler';
import BasketTravellerDocuments from './BasketTravellerDocuments.vue';
import BasketTravellerLoyaltyPrograms from './BasketTravellerLoyaltyPrograms.vue';
import BasketTravellerDataForm from './BasketTravellerDataForm.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import CustomerCodeSection from './CustomerCodeSection.vue';
import { isServiceTagAvailable } from '@/core/errors/handle-errors.service';
import { OffersApi } from '@/api/air-engine/offers.api';
import { DateOfBirthObligation } from '@/api/home/home.model';

@Component({
  components: {
    BasketTravellerDocuments,
    BasketTravellerLoyaltyPrograms,
    BasketTravellerDataForm,
    BasketExpirationCountdown,
    CustomerCodeSection,
  }
})
export default class BasketCheckTravellerData extends Vue {
  @Prop() basketExpired!: boolean;

  shouldValidate: boolean = false;
  phoneCodeEmptyValue: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: translate('common.phone-code'),
    fullPhoneCodeDisplayName: translate('common.none'),
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  errors: any[] = [];
  apisErrors: any[] = [];
  travellersForm: any[] = [];
  travellerFormsValid: any[] = [];
  travellerLoyaltiesEmpty: any[] = [];
  travellerFormsLoading: any[] = [];
  travellersDocumentsLoaded: any[] = [];
  loadingGuestureInfo: boolean = true;
  customerCodeInfo = {
    selectedCustomerCode: null,
    customers: [],
    allowCustomerSearch: false,
  };
  dateOfBirthLoading: boolean = false;
  apisLoading: boolean = false;
  dateOfBirthObligations: any[] = [];
  titleOptions = [
    {
      value: '',
      name: '',
    },
    {
      value: 'Mr',
      name: translate('common.mr'),
    },
    {
      value: 'Mrs',
      name: translate('common.mrs'),
    },
    {
      value: 'Miss',
      name: translate('common.miss'),
    },
  ];

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get travellerValidationError() {
    return BasketStore.travellerValidationError;
  }

  get isTravellerStrictError() {
    const validationType = this.travellerValidationError && this.travellerValidationError.validationType;
    const errors = (this.travellerValidationError && this.travellerValidationError.errors) || [];
    return validationType === 'Strict' && errors.length > 0;
  }

  get errorCodes() {
    const errors = (this.travellerValidationError && this.travellerValidationError.errors) || [];
    return [...new Set(errors.map(({ errorCode, severity }) => {
      if (errorCode === 'AGE_INCOMPATIBILITY') {
        return errorCode;
      }
      return severity;
    }))];
  }

  get dataLoading() {
    return this.dateOfBirthLoading || this.apisLoading;
  }

  get allErrors() {
    return [
      ...this.errors,
      ...this.apisErrors,
    ];
  }

  get hasApisRules() {
    return BasketStore.currentApisRules.length > 0;
  }

  get apisRulesWithCountryNames() {
    return BasketStore.currentApisRules
      .map(item => {
        const countryNameFromRecord = this.allPhoneCountryCodes
          .find(record => record.code === item.countryFrom);
        const countryNameToRecord = this.allPhoneCountryCodes
          .find(record => record.code === item.countryTo);
        const countryNameFrom = countryNameFromRecord ? countryNameFromRecord.countryName : item.countryFrom;
        const countryNameTo = countryNameToRecord ? countryNameToRecord.countryName : item.countryTo;

        return {
          ...item,
          countryNameFrom,
          countryNameTo,
          documents: item.apisTypeInfos
            .map(e => {
              if (e.apisType === 'DOB') {
                return translate('basket.starting-date-of-birth');
              }
              return translate('profile-documents-by-type.' + e.apisType);
            })
            .join(', '),
        };
      });
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get guestTravellers() {
    return BasketStore.guestBasketTravellers;
  }

  get currentTravellers() {
    return this.skipTravellers ? SearchStore.editedTravellers.travellers as any[] : this.travellers;
  }

  get loading() {
    const numberOfTravellers = this.currentTravellers.length;
    const numberOfFormsLoaded = this.travellerFormsLoading
      .filter(item => item.isLoading === false).length;
    const numberOfDocumentsLoaded = this.travellersDocumentsLoaded.length;
    const numberOfLoyaltyProgramsLoaded = this.travellerLoyaltiesEmpty.length;
    
    return numberOfFormsLoaded !== numberOfTravellers ||
      numberOfDocumentsLoaded !== numberOfTravellers ||
      numberOfLoyaltyProgramsLoaded !== numberOfTravellers;
  }

  get currentTravellersMapped() {
    return this.currentTravellers
      .map(traveller => {
        const dob = this.dateOfBirthObligations
          .find(data => data.travellerId === traveller.id);
        const apisDob = this.apisRulesWithCountryNames
          .find(rule => rule.dobRule);
        
        let dateOfBirthObligation: DateOfBirthObligation | null = null;
        if (dob) {
          dateOfBirthObligation = dob.dateOfBirthObligation;
        }
        if (apisDob && apisDob.apisTypeInfos[0].isMandatory) {
          dateOfBirthObligation = DateOfBirthObligation.Required;
        }
        return {
          ...traveller,
          dateOfBirthObligation,
        };
      });
  }

  get travellers() {
    return SearchStore.getTravellersState.travellers;
  }

  get allPhoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    let allPhoneCodes = DictionaryStore.allCountries.filter((country) => { return country && country.phoneCode!.length > 0; })
    .map((country) => {
      return { 
        ...country,
        shortPhoneCodeDisplayName: '+' + country.phoneCode,
        fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
      };
    });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get guestsData() {
    return BasketStore.guestBasketTravellersData;
  }

  get allTravellerFormsValid() {
    if (this.travellerFormsValid.length < this.currentTravellers.length) {
      return false;
    }
    if (
      !this.allTravellerFormsLoaded ||
      (this.customerCodeInfo.allowCustomerSearch && !this.customerCodeInfo.selectedCustomerCode)
    ) {
      return false;
    }
    if (this.travellerFormsValid && this.travellerFormsValid.length) {
      return this.travellerFormsValid.every(item => {
        return item.isValid === true;
      });
    } else {
      return true;
    }
  }

  @Watch('allTravellerFormsValid')
  onFormErrorChange(value) {
    BasketStore.setGuestBasketTravellersDataError(!value);
  }

  get everyTravellerLoyaltyEmptyEntryExist() {
    let valid: boolean[] = [];
    this.currentTravellers.forEach((item => {
      valid.push(
        -1 < this.travellerFormsValid.findIndex(entry => {
          return item.id === entry.id;
        })
      );
    }));
    return valid.length === this.travellerFormsValid.length ? valid.every(entry => { return entry; }) : false;
  }

  get everyTravellerLoyaltyEmpty() {
    if (this.travellerLoyaltiesEmpty && this.travellerLoyaltiesEmpty.length) {
      return this.travellerLoyaltiesEmpty.every(item => {
        return item.isEmpty === true;
      });
    } else {
      return true;
    }
  }

  @Watch('everyTravellerLoyaltyEmpty')
  async onTravelLoyaltiesChange(val, oldVal) {
    if (!this.everyTravellerLoyaltyEmptyEntryExist) {
      return;
    }
    if (!val && oldVal) {
      const code = BasketStore.bookingStepDef.code;
      const { data: steps } = await BasketApi.retrieveWizardSteps(this.$route.params.id);
      if (steps.some(step => {
        return step.code === 'LOYALTY_PROGRAMS';
      })) {
        let mappedSteps = steps.map(item => {
          let existing = this.wizardSteps.find(step => { return item.code === step.code && item.tripItemId === step.tripItemId; });
          if (existing) {
            return {
              ...item,
              selected: existing.selected
            };
          } else if (!existing && item.code === 'LOYALTY_PROGRAMS') {
            return {
              ...item,
              selected: true,
            };
          }
        }).filter(Boolean);

        BasketStore.updateWizardSteps({ steps: mappedSteps, code });
      }
    } else if (val && !oldVal) {
      BasketStore.removeWizardStepByCode('LOYALTY_PROGRAMS');
    }
  }

  get allTravellerFormsLoaded() {
    if (this.loadingGuestureInfo) {
      return false;
    }
    if (this.travellerFormsLoading && this.travellerFormsLoading.length) {
      return this.travellerFormsLoading.every(item => {
        return item.isLoading === false;
      });
    } else {
      return false;
    }
  }

  get basketId() {
    return BasketStore.basketId;
  }

  @Watch('allTravellerFormsLoaded', { immediate: true })
  onFormsLoadedChange(value) {
    BasketStore.setGuestBasketTravellersDataLoading(!value);
  }

  async getDateOfBirthObligation(id) {
    const dateOfBirthObligation = await BasketApi.getBasketTravellerDateOfBirth(id);
    if (dateOfBirthObligation && dateOfBirthObligation.data) {
      return dateOfBirthObligation.data;
    }
  }

  reloadCustomerCodess() {
    this.errors = [];
    this.loadingGuestureInfo = true;
    this.loadGuestureInfo();
  }

  async loadGuestureInfo() {
    BasketStore.setCustomerCodeSectionLoadingError(false);
    try {
      const response = await TripApi.getTripCustomerCodeInfo(this.basketId);
      this.customerCodeInfo = response.data;
    } catch (error) {
      this.errors = [{
        message: translate('common-error.unavailable'),
      }];
      if (isServiceTagAvailable(error)) {
        this.errors[0].message += [
          translate(messages.serviceTagPrefix),
          (error as any).response.data.error.serviceTag,
          messages.serviceTagSufix
        ].join('');
      }
      BasketStore.setCustomerCodeSectionLoadingError(true);
    } finally {
      this.loadingGuestureInfo = false;
    }
  }

  async fetchUserData() {
    this.dateOfBirthLoading = true;
    BasketStore.setTravellersDateOfBirthCheckFailed(false);
    this.dateOfBirthObligations = [];
    try {
      let dateOfBirthObligationData = await this.getDateOfBirthObligation(this.basketId);
      if (dateOfBirthObligationData) {
        this.dateOfBirthObligations = dateOfBirthObligationData;
      }

      this.currentTravellers.forEach((trav, index) => {
        let traveller: any = {
          id: trav.isVirtual ? this.travellers[index].id : trav.id,
          profileId: trav.id,
          oldId: trav.oldId,
          firstName: trav.firstName,
          middleName: trav.middleName || '',
          lastName: trav.lastName,
          isVirtual: trav.isVirtual,
          isGuest: trav.isGuest,
          isMainTraveller: trav.isMainTraveller,
          businessUnitName: trav.businessUnitName,
          businessUnitId: trav.businessUnitId,
          passengerTypeCode: trav.passengerTypeCode,
        };

        if (trav.isVirtual) {
          traveller.dateOfBirth = moment(trav.dateOfBirth).toDate();
          traveller.email = trav.email;
          traveller.phone = {
            number: trav.phoneNumber,
          };
          traveller.phone.code = trav.phoneCode;
          let selectedCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === trav.phoneCode; })!;
          traveller.selectedPrimaryPhoneCode = selectedCode ? selectedCode : this.phoneCodeEmptyValue;
        }

        this.travellersForm.push(traveller);
      });
      this.shouldValidate = true;
    } catch (errors) {
      this.errors = this.$handleErrors(errors);
      BasketStore.setTravellersDateOfBirthCheckFailed(true);
    }
    this.dateOfBirthLoading = false;
  }

  async loadApisInfoWhenNeeded() {
    if (!BasketStore.itemsToCheckForApisValidation.length) {
      return;
    }
    BasketStore.setCurrentApisRules([]);
    BasketStore.setLoadApisInfoFailed(false);
    this.apisLoading = true;

    try {
      const response = await OffersApi.getApisValidationRules(BasketStore.queryStringForApisValidation);
      if (response && response.data && response.data.apisInfos) {
        const mandatoryRules = response.data.apisInfos
          .map(item => {
            return {
              ...item,
              apisTypeInfos: item.apisTypeInfos
                .filter(e => e.isMandatory),
            };
          })
          .filter(item => !!item.apisTypeInfos.length)
          .reduce((prev, cur) => {
            const dobRecord = cur.apisTypeInfos
              .find(item => item.apisType === 'DOB');
            const otherRecords = cur.apisTypeInfos
              .filter(item => item.apisType !== 'DOB');

            if (!!dobRecord && otherRecords.length) {
              return [...prev, {
                ...cur,
                dobRule: true,
                apisTypeInfos: [dobRecord],
              }, {
                ...cur,
                apisTypeInfos: otherRecords,
              }];
            }
            return [...prev, {
              ...cur,
              dobRule: !!dobRecord,
            }];
          }, []);
        const rules = response.data.apisInfos
          .map(item => {
            return {
              ...item,
              apisTypeInfos: item.apisTypeInfos,
            };
          })
          .filter(item => !!item.apisTypeInfos.length)
          .reduce((prev, cur) => {
            return [...prev, {
              ...cur,
              dobRule: false,
            }];
          }, []);
        BasketStore.setCurrentApisRules(rules);
        BasketStore.setCurrentMandatoryApisRules(mandatoryRules);
      }
    } catch (errors) {
      this.apisErrors = this.$handleErrors(errors);
      BasketStore.setLoadApisInfoFailed(true);
    }
    this.apisLoading = false;
  }

  @Watch('dataLoading')
  onDataLoading(value) {
    BasketStore.setBookingStepLoading(value);
  }

  goToProfile(travellerId) {
    const routeData = this.$router.resolve({
      name: 'personal',
      params: {
        id: travellerId
      }
    });
    window.open(routeData.href, '_blank');
  }

  assignTravellerValidation(item) {
    let index = this.travellerFormsValid.findIndex(e => {
      return e.id === item.id;
    });
    if (-1 < index) {
      this.travellerFormsValid.splice(index, 1, item);
    } else {
      this.travellerFormsValid.push(item);
    }
  }

  onTravellerLoyaltiesChange(item) {
    let index = this.travellerLoyaltiesEmpty.findIndex(e => {
      return e.id === item.id;
    });
    if (-1 < index) {
      this.travellerLoyaltiesEmpty.splice(index, 1, item);
    } else {
      this.travellerLoyaltiesEmpty.push(item);
    }
  }
  
  assignTravellerLoading(item) {
    let index = this.travellerFormsLoading.findIndex(e => {
      return e.id === item.id;
    });
    if (-1 < index) {
      this.travellerFormsLoading.splice(index, 1, item);
    } else {
      this.travellerFormsLoading.push(item);
    }
  }

  documentsLoaded(profileId) {
    let index = this.travellersDocumentsLoaded.findIndex(e => {
      return e === profileId;
    });
    if (-1 < index) {
      this.travellersDocumentsLoaded.splice(index, 1, profileId);
    } else {
      this.travellersDocumentsLoaded.push(profileId);
    }
  }

  async mounted() {
    EventBus.$on('traveller-validation-changed', this.assignTravellerValidation);
    EventBus.$on('traveller-loyalties-change', this.onTravellerLoyaltiesChange);
    EventBus.$on('traveller-data-loaded', this.assignTravellerLoading);
    EventBus.$on('travellers-documents-loaded', this.documentsLoaded);
    EventBus.$on('reload-customer-codes', this.reloadCustomerCodess);
    this.loadApisInfoWhenNeeded();
    this.loadGuestureInfo();
    this.fetchUserData();
    BasketStore.setGuestBasketTravellersDataError(!this.allTravellerFormsValid);
    BasketStore.setShowSelectDocumentsAsFoidError(false);
    BasketStore.clearSelectedDocumentsAsFoid();
    BasketStore.setTravellerValidationError();
  }

  beforeDestroy() {
    EventBus.$off('traveller-validation-changed', this.assignTravellerValidation);
    EventBus.$off('traveller-loyalties-change', this.onTravellerLoyaltiesChange);
    EventBus.$off('traveller-data-loaded', this.assignTravellerLoading);
    EventBus.$off('reload-customer-codes', this.reloadCustomerCodess);
    EventBus.$off('travellers-documents-loaded', this.documentsLoaded);
    BasketStore.setShouldShowApisRulesValidationErrors(false);
  }
}
