import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import AccountStore from '@/store/account.store';
import { GlobalSettingsRoutes } from './global-settings.const';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'globalSettings'
})
class GlobalSettingsStore extends VuexModule {
  
  get routes() {
    return GlobalSettingsRoutes
      .filter(item => !item.meta || !item.meta.permission || AccountStore.HasPermission(item.meta.permission));
  }

}

export default getModule(GlobalSettingsStore);
