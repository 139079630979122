










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import BasketItem from '../BasketItem.vue';
import BasketStore from '../basket.store';
import BasketExpirationCountdown from '../BasketExpirationCountdown.vue';
import AssignTravellersToRooms from './AssignTravellersToRooms.vue';

@Component({
  components: {
    BasketItem,
    BasketExpirationCountdown,
    AssignTravellersToRooms,
  }
})
export default class AssignTravellersToRoomsView extends Vue {
  @Prop() basketExpired!: boolean;

  get loading() {
    return BasketStore.loadingHotelAssignmentOptions;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get hotelAssignmentOptions() {
    return BasketStore.hotelAssignmentOptions;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }
 
  get basket() {
    return BasketStore.basket;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'ASSIGN_TRAVELLERS_TO_ROOMS') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped[0];
  }

  get errors() {
    return BasketStore.loadingHotelAssignmentErrors;
  }

  get basketTravellers() {
    if (!BasketStore.basket) {
      return [];
    }
    return BasketStore.basket.travellers;
  }



  change(value) {
    BasketStore.changeHotelAssignmentOptions(value);
  }

  addSlot(params) {
    BasketStore.addHotelAssignmentSlot(params);
  }

  removeSlot(params) {
    BasketStore.removeHotelAssignmentSlot(params);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.resetPriceChangesForStep({step: this.bookingStep, tripItemId: this.itemId});
    await BasketStore.loadHotelAssignmentOptions(this.itemId);
    BasketStore.setBookingStepLoading(false);
  }

  beforeDestroy() {
    BasketStore.resetHotelAssignmentOptions();
  }
}

