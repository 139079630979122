












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { router } from '@/router';
import AccountStore from '@/store/account.store';
import BasketStore from '@/modules/basket/basket.store';


@Component({})
export default class BasketExpirationCountdown extends Vue {
  @Prop() basketExpired!: boolean;
  @Prop() status!: string;

  dateTimeInterval: number = -1;
  currentDateTime: any = moment();

  get isHeld() {
    return this.status === 'Held';
  }

  get isPendingApproval() {
    return this.status === 'PendingApproval';
  }

  get isDelayedTicketing() {
    return this.status === 'DelayedTicketing';
  }

  get basketExpirationDate() {
    if (BasketStore.basketExpirationDate) {
      return BasketStore.basketExpirationDate;
    }
  }

  get basketExpirationTime() {
    return BasketStore.basketExpirationTime;
  }

  get isBasketExpired() {
    if (this.basketExpirationDate) {
      return this.currentDateTime.isAfter(this.basketExpirationDate);
    }
    return false;
  }

  get timeUntilBasketExpiration() {
    if (!this.currentDateTime || !this.basketExpirationDate) {
      return '';
    }
      let diffInSeconds: any = this.basketExpirationDate.diff(this.currentDateTime, 'seconds');
      let hours: any = diffInSeconds / 3600;
      let rhours: any = Math.floor(hours);
      if (rhours < 0) {
        return -1;
      }
      if (rhours < 10) {
        rhours = '0' + rhours;
      }
      if (rhours === '00') {
        rhours = false;
      }

      let minutes: any = (hours - rhours) * 60;
      let rminutes: any = Math.floor(minutes);
      if (rminutes < 0) {
        return -1;
      }
      if (rminutes < 10) {
        rminutes = '0' + rminutes;
      }
      let seconds: any = (minutes - rminutes) * 60;
      let rseconds: any = Math.round(seconds);
      if (rseconds < 10) {
        rseconds = '0' + rseconds;
      }
      return rhours ? `${rhours}:${rminutes}:${rseconds}` : `${rminutes}:${rseconds}`;
  }

  get isBasketDraft() {
    return BasketStore.status && BasketStore.status.status ? BasketStore.status.status === 'Draft' : false;
  }

  get isInProgress() {
    return BasketStore.status ? BasketStore.status.isInProgress : false;
  }

  get basketHoldDeadline() {
    return BasketStore.basketHoldDeadline;
  }

  get basketDelayedTicketingDeadline() {
    return BasketStore.basketDelayedTicketingDeadline;
  }

  get basketApprovalDeadline() {
    return BasketStore.basketApprovalDeadline;
  }

  get basketAutoApproval() {
    return BasketStore.willBasketBeApprovedAutomatically;
  }

  get user() {
    return AccountStore.current;
  }

  get dateFormat() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return 'D MMMM YYYY, HH:mm';
    }
    return 'Do MMMM YYYY, HH:mm';
  }

  dateTime(date) {
    return moment.parseZone(date).format(this.dateFormat) + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  refreshDateTime() {
    this.currentDateTime = moment();
  }
  
  @Watch('basketExpired')
  onExpirationChange(value, oldVal) {
    if (value && !oldVal && this.isBasketDraft && !this.isInProgress && this.$route.name === 'basket') {
      BasketStore.setBookingStep(0);
      setTimeout(() => {
        router.replace({
          name: 'basket-expired',
          params: this.$route.params,
        });
      });
    }
  }

  created() {
    this.dateTimeInterval = setInterval(this.refreshDateTime, 1000);
  }

  beforeDestroy() {
    clearInterval(this.dateTimeInterval);
  }
}
