import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  AgencyMarkupConfig,
  AgencyMarkupConfigurationResult
} from './agency-markup-configuration.model';
import { Permission } from '@/const/permission.enum';

class AirAgencyMarkupConfigurationApiClass {
  public create(params: AgencyMarkupConfig): Promise<AxiosResponse<AgencyMarkupConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/agency-markup-configurations',
      method: RequestMethod.POST,
      permission: Permission.CanEditFeesConfiguration,
    }, {}, params);
  }

  public getById(configurationId: string): Promise<AxiosResponse<AgencyMarkupConfig>> {
    return http.execute({
      path: settings.apiAirEngine + '/agency-markup-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.CanEditFeesConfiguration,
    }, { configurationId }, {});
  }

  public update(configurationId: string, params: AgencyMarkupConfig): Promise<AxiosResponse<AgencyMarkupConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/agency-markup-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.CanEditFeesConfiguration,
    }, { configurationId }, params);
  }
}

export const AirAgencyMarkupConfigurationApi: AirAgencyMarkupConfigurationApiClass = new AirAgencyMarkupConfigurationApiClass();