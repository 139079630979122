







import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EventBus from '@/services/event-handler';
import { TCoach, TRailSeatSelection } from '@/modules/basket/basket-rail-seats-extras/basket-rail-seats-extras.model';

@Component({})
export default class TrenitaliaCoachMap extends Vue {
  @Prop({ default: null }) coach!: TCoach | null;
  @Prop() segmentId!: string;
  @Prop({ default: () => [] }) seatSelections!: TRailSeatSelection[];

  get svgHtml() {
    if (!this.coach) {
      return '';
    }
    return this.coach.originLayout;
  }

  get availableSeats() {
    if (!this.coach) {
      return [];
    }
    return this.coach.seatMapItems.filter(item => {
      return item.seatAvailability === 'Open';
    });
  }

  get selectedSeats() {
    if (!this.coach) {
      return [];
    }
    return this.seatSelections.filter((item) => item.segmentId === this.segmentId && item.coachNumber === (this.coach && this.coach.number));
  }

  @Watch('coach', { immediate: true, deep: true })
  onCoachChange() {
    this.$nextTick(() => {
      this.availableSeats.forEach(seat => {
        const originalItem = document.getElementById('seat_' + seat.alternateNumber);
        if (!originalItem || !originalItem.parentNode) {
          return;
        }

        // cloning ensures seat item has no event listeners
        // (in case we switch coach to the one that has same items
        // - vue prevents from creating new DOM elements when possible)
        const item = originalItem.cloneNode(true);
        originalItem.parentNode.replaceChild(item, originalItem);

        item.addEventListener('mouseenter', $event => {
          this.onMouseEnter($event, seat);
        });
        item.addEventListener('mouseleave', $event => {
          this.onMouseLeave($event, seat);
        });
        item.addEventListener('click', $event => {
          this.onClick($event, seat);
        });
      });

      this.updateSeatsClasses();
    });
  }

  onClick($event, seat) {
    EventBus.$emit('select-seat', seat);

    this.updateSeatsClasses();
  }

  onMouseEnter($event, seat) {
    EventBus.$emit('mouseenter-seat', {
      item: seat,
      e: $event,
    });
  }

  onMouseLeave($event, seat) {
    EventBus.$emit('mouseleave-seat', {
      item: seat,
      e: $event,
    });
  }

  updateSeatsClasses() {
    this.availableSeats.forEach(seat => {
      const item = document.getElementById('seat_' + seat.alternateNumber);
      if (!item) {
        return;
      }

      const isSelected = this.selectedSeats.find(s => s.seatNumber === seat.seatNumber);
      if (isSelected) {
        item.classList.add('sedileevid');
        item.classList.remove('sedile');
      } else {
        item.classList.remove('sedileevid');
        item.classList.add('sedile');
      }
    });
  }
}
