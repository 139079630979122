























































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import BasketStore from './basket.store';
import BasketItem from './BasketItem.vue';
import BasketTripHistoryItemChanges from './BasketTripHistoryItemChanges.vue';
import SabreTripHistoryItemInvoluntaryChanges from './sabre/SabreTripHistoryItemInvoluntaryChanges.vue';

const numberOfElementsToShowAlways = 10;

@Component({
  components: {
    BasketItem,
    BasketTripHistoryItemChanges,
    SabreTripHistoryItemInvoluntaryChanges,
  },
})
export default class BasketTripHistoryItem extends Vue {
  @Prop() element!: any;



  params: any = {
    page: 1,
    start: 0,
    size: 100,
    sort: '',
    desc: true,
    usePageParam: false,
  };
  fields = {
    actionBy: {
      label: translate('basket-trip-history.action-by'),
      class: 'basket-trip-history__column-action-by',
    },
    actionType: {
      label: translate('basket-trip-history.action-type'),
      class: 'basket-trip-history__column-action-type',
    },
    actionDatetime: {
      label: translate('basket-trip-history.action-datetime'),
      class: 'basket-trip-history__column-action-datetime',
    },
    changes: {
      label: translate('basket-trip-history.action-details'),
      class: 'basket-trip-history__column-changes',
    },
  };
  showingMore: boolean = true;



  get basket() {
    return BasketStore.basket;
  }

  set currentPage(value: number) {
    this.params.page = value;
  }

  get currentPage(): number {
    return this.params.page;
  }

  get showMore() {
    return this.element.history.changes.length > numberOfElementsToShowAlways;
  }

  get changes() {
    return this.element.history.changes
      .filter((item, index) => {
        if (item.changeParameters && item.changeParameters.some(change => !!change.priceFrom || !!change.priceTo)) {
          return this.$canSeePrices();
        }

        if (!this.showingMore) {
          return true;
        }

        return index < numberOfElementsToShowAlways;
      });
  }



  userFullName(user) {
    return userFullName(user);
  }

  showMoreNow(value) {
    this.showingMore = !value;
  }
}
