import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  InitTrainSearchMessage,
  ResponseTrainModel,
  TrainSearchSession,
  TrainOffersResponseModel,
  TrainSetProposalsModel,
  OffersQuery,
} from './train-search.model';

class TrainApiClass {
  public initTrainSearch(params: InitTrainSearchMessage): Promise<AxiosResponse<ResponseTrainModel>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public addSegment(params: InitTrainSearchMessage, basketId: string): Promise<AxiosResponse<ResponseTrainModel>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, params);
  }

  public initTrainSearchForMission(params: InitTrainSearchMessage, missionId: string): Promise<AxiosResponse<ResponseTrainModel>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search?missionId={missionId}',
      method: RequestMethod.POST,
    }, { missionId }, params);
  }

  public addSegmentForMission(params: InitTrainSearchMessage, basketId: string, missionId: string): Promise<AxiosResponse<ResponseTrainModel>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search?basketId={basketId}&missionId={missionId}',
      method: RequestMethod.POST,
    }, { basketId, missionId }, params);
  }

  public getTrainSearchSession({ searchId }, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<TrainSearchSession>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}',
      method: RequestMethod.GET,
    }, { searchId }, {}, false, cancelTokenSource);
  }

  public getTrainOffers({ searchId, legNumber }, query, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<TrainOffersResponseModel>> {
    return http.execute({
      path: settings.apiRailEngine + `/api/v2/search/{searchId}/offers/{legNumber}?${query}`,
      method: RequestMethod.GET,
    }, { searchId, legNumber }, query, false, cancelTokenSource);
  }

  public loadMoreResults(searchId, legNumber, action) {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/more/{legNumber}',
      method: RequestMethod.POST,
    }, { searchId, legNumber }, {
      moreResultType: action,
    });
  }

  public setTrainProposal({ searchId }, params: TrainSetProposalsModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/leg',
      method: RequestMethod.PUT,
    }, { searchId }, params);
  }

  public updateFilterOffers(searchId, params, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/offers/filters',
      method: RequestMethod.PUT,
    }, searchId, params, false, cancelTokenSource);
  }

  public deleteSelectedLeg(searchId, legNumber): Promise<AxiosResponse<TrainSearchSession>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/leg/{legNumber}/delete',
      method: RequestMethod.POST,
    }, { searchId, legNumber }, {});
  }

  public getFareConditions(offerId: string, fareId: string, supplier: string, searchId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/fares/{fareId}/conditions?supplier={supplier}&searchId={searchId}',
      method: RequestMethod.GET,
    }, { offerId, fareId, supplier, searchId }, {});
  }

  public getFareConditionsForOrder(orderId: string, legNumber: number, fareId: string, supplier: string, searchId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/orders/{orderId}/conditions?supplier={supplier}&searchId={searchId}&fareId={fareId}&legNumber={legNumber}',
      method: RequestMethod.GET,
    }, { orderId, supplier, searchId, fareId, legNumber }, {});
  }

  public getAdvancedOptionsAvailability(searchCompanyId): Promise<AxiosResponse<string[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/advanced/availability?searchCompanyId={searchCompanyId}',
      method: RequestMethod.GET,
    }, { searchCompanyId }, {});
  }

  public getAdvancedOptions(railSupplier: string, request: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/advanced/options?railSupplier={railSupplier}',
      method: RequestMethod.POST,
    }, { railSupplier }, request);
  }

  public createAdvancedOptions(railSupplier: string, request: any): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/advanced?railSupplier={railSupplier}',
      method: RequestMethod.POST,
    }, { railSupplier }, request, true);
  }

  
  public updateSearchProposalFee(searchId: string, fareId: string, fee: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/offers/{fareId}/fee',
      method: RequestMethod.PUT,
    }, { searchId, fareId }, fee, true);
  }

  
  public updateSearchProposalAgencyMarkup(searchId: string, fareId: string, fee: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/{searchId}/offers/{fareId}/agency-markup',
      method: RequestMethod.PUT,
    }, { searchId, fareId }, fee, true);
  }

  public updateAdvancedOptions(railSupplier: string, advancedSearchParametersId: string, request: any): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/advanced/{advancedSearchParametersId}?railSupplier={railSupplier}',
      method: RequestMethod.PUT,
    }, { railSupplier, advancedSearchParametersId }, request, true);
  }

  public getSavedAdvancedOptions(railSupplier: string, advancedSearchParametersId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/search/advanced/{advancedSearchParametersId}?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { railSupplier, advancedSearchParametersId }, {});
  }
}

export const TrainApi: TrainApiClass = new TrainApiClass();
