import messages from '@/const/error-messages.const';
import { translate } from '@/i18n';

export interface ErrorsParsingParams {
  default?: string;
  unknown?: string;
  unavailable?: string;
  notFound?: string;
  noPermission?: string;
  codes?: {
    [code: number]: string;
  };
}

// order matters
const rules = [
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return params && params.codes && error.response && params.codes[error.response.status] !== undefined;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params!.codes![error.response.status],
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return error &&
        error.response &&
        403 === error.response.status &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.details &&
        error.response.data.error.details[0].code === 'MFA_REQUIRED';
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        // message: translate(messages.noPermission),
        message: 'custom',
        isMessage: true,
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return true === isForm && error.response &&
        400 === error.response.status &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.details &&
        error.response.data.error.details.length;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return error.response.data.error.details;
    }
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return !error.response || [500, 504].indexOf(error.response.status) > -1;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params && params.unknown ? params.unknown : translate(messages.unknown),
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return [503, 505].indexOf(error.response.status) > -1;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params && params.unavailable ? params.unavailable : translate(messages.unavailable),
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return 404 === error.response.status && true !== isForm;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params && params.notFound ? params.notFound : translate(messages.notFound),
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return 404 === error.response.status && true === isForm && params && params.codes;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params && params.codes ? params.codes[404] : error.response.data.error.message
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return 403 === error.response.status;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      return [{
        message: params && params.noPermission ? params.noPermission : translate(messages.noPermission),
      }];
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return 418 === error.response.status &&
        error.response && 
        error.response.data && 
        error.response.data.error &&
        -1 < [
          'PROVIDER_EXECUTION_FAILURE',
          'LHG_Prov_Error',
        ].indexOf(error.response.data.error.code);
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      let errors = [{
        message: error.response.data.error.message
      }];
  
      if (
        error.response.data.error.details &&
        error.response.data.error.details.length
      ) {
        error.response.data.error.details.forEach(element => {
          errors.push(element);
        });
      }

      return errors;
    },
  },
  {
    match: (error, isForm?: boolean, params?: ErrorsParsingParams) => {
      return error.response && error.response.data && error.response.data.error;
    },
    action: (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
      // message from server
      let errors: any = [];
      if (error.response.status === 418 && error.response.data.error.code === 'CANNOT_OVERRIDE_MAIN_TRAVELLER') {
        errors.push({ message: translate('common-error.traveller-mismatch') });
      } else {
        errors.push({ message: error.response.data.error.message });
      }
  
      if (!!addMessagetoError) {
        errors[0].message += [
          translate(messages.expired),
        ].join('');
      }
      return errors;
    },
  },
];

const defaultActionWhenNoRule = (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean): any[] => {
  // default error message (uses unknown by defualt)
  return [{
    message: params && params.default ? params.default : translate(messages.unknown),
  }];
};

export const isServiceTagAvailable = (error) => {
  return error.response && error.response.data && 
    error.response.data.error && 
    error.response.data.error.serviceTag;
};

export default (error, isForm?: boolean, params?: ErrorsParsingParams, addMessagetoError?: boolean) => {
  if (!window.navigator.onLine) {
    return [{
      message: translate(messages.offline),
    }];
  }

  let errors: any[] = [];
  const firstMatchedRule: any = rules.find(rule => {
    return rule.match(error, isForm, params);
  });

  if (firstMatchedRule) {
    errors = firstMatchedRule.action(error, isForm, params, addMessagetoError);
  } else {
    errors = defaultActionWhenNoRule(error, isForm, params, addMessagetoError);
  }

  // adding service tag if possible
  if (isServiceTagAvailable(error)) {
    errors[0].message += [
      translate(messages.serviceTagPrefix),
      error.response.data.error.serviceTag,
      messages.serviceTagSufix
    ].join('');
  }

  return errors;
};
