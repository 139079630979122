import MissedSavingsConfigurations from '@/modules/settings/missed-savings/MissedSavingsConfigurations.vue';

export default {
  path: '',
  name: 'missed-savings-configurations',
  component: MissedSavingsConfigurations,
  displayName: `Client email notifications configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditMissedSavings',
    parentName: 'missedSavings',
    depthCategory: 'missedSavings',
    depth: 1,
  }
};