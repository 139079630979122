export interface DelayTicketingConfigurationModel {
  rootCompanyId?: string;
  name: string;
  rules: RuleItem[];
}

export class RuleItem {
  timeBeforeTicketing: number | string = 1;
  isPriceChangeRatioEnabled: boolean = false;
  priceChangeRatio: number | null = null;
  isProviderEnabled: boolean = true;
  providers: any[] = [];
  carriers: any[] = [];
  isCarriersEnabled: boolean = false;
  visible: boolean = true;
}

export interface Provider {
  name: string;
  code: string;
}

export class DelayTicketingConfiguration {
  rootCompanyId?: string = '';
  configurationId?: string = '';
  name: string = '';
  rules: RuleItem[] = [
    new RuleItem(),
  ];
}