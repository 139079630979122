





























































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import LodgeCardsStore from '@/modules/settings/lodge-cards/lodge-cards.store';
import { CompanyPaymentCardProvider } from '@/api/lodge-cards/lodge-cards.model';
import { LodgeCardsConfigurationDataRow } from './lodge-cards-configuration.model';
import SettingsStore from '@/modules/settings/settings.store';
import { translate } from '@/i18n';
import hasAccessFn from '@/core/permissions/has-access.service';

@Component({})
export default class LodgeCardsConfiguration extends BaseTable<LodgeCardsConfigurationDataRow> {
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'displayName',
  });
  fields = {
    cardNumber: {
      sortable: false,
      label: translate('settings-lodge-cards.card-number')
    },
    expirationDate: {
      sortable: false,
      label: translate('settings-lodge-cards.expiration-date')
    },
    displayName: {
      sortable: false,
      label: translate('settings-lodge-cards.display-name')
    },
    status: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  noProvider = CompanyPaymentCardProvider.None;
  serverErrors: any = [];
  company = this.currentCompany;
  cardToRemove: any | null = null;
  menuActive: boolean = false;
  paymentCard: any = null;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  tempIdPrefix: string = 'paymentCard_';
  $v;

  @Validation()
  validationObject() {
    return {
      configuration: {
        configurationName: {
          required,
          maxLength: maxLength(64),
          whitespaced: (model: string) => {
            return model && model.length ? !!(model.trim()) : true;
          }
        },
      }
    };
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get lodgeCards() {
    return LodgeCardsStore.lodgeCardConfiguration.lodgeCards;
  }

  get configuration() {
    return LodgeCardsStore.lodgeCardConfiguration;
  }

  get configurationId() {
    return this.$route.params.configurationId ? this.$route.params.configurationId : 'new';
  }

  get loadingData() {
    return LodgeCardsStore.loading;
  }

  get count() {
    return this.lodgeCards.length;
  }

  get fromManage() {
    return LodgeCardsStore.isFromManage;
  }

  get errMessages() {
    return LodgeCardsStore.errMessages;
  }

  get showError() {
    return LodgeCardsStore.showError;
  }

  get canReadNotWrite() {
    return hasAccessFn('ReadBackOfficeConfiguration') && !hasAccessFn('WriteBackOfficeConfiguration');
  }

  get canShowCustomError() {
    return LodgeCardsStore.canShowCustomError;
  }

  get configIsEdit() {
    return !!(this.$route.params.configurationId && this.$route.params.configurationId !== 'new');
  }

  backToConfigurationsList() {
    router.push({
      name: 'lodge-card-configurations',
      params: {
        id: router.currentRoute.params.id
      }
    });
  }

  rowClicked(item) {
    if (item) {
      this.resetData();
      this.paymentCard = item;
      LodgeCardsStore.setSelectedLodgeCard(item);

      if (this.$hasAccess('WriteCompanyPaymentCards')) {
        router.push({
          name: 'lodge-card-form',
          params: {
            id: router.currentRoute.params.id,
            configurationId: router.currentRoute.params.configurationId
          }
        });
      } else {
        router.push({
          name: 'lodge-card-form',
          params: {
            id: router.currentRoute.params.id,
            configurationId: router.currentRoute.params.configurationId
          },
          query: {
            readOnly: 'true'
          }
        });
      }
    }
  }

  remove(item) {
    this.menuActive = false;
    this.removePopup = true;
    this.cardToRemove = item;
  }

  removeCard() {
    LodgeCardsStore.removeLodgeCard(this.cardToRemove);
    this.cardToRemove = null;
    this.removePopup = false;
  }

  resetData() {
    LodgeCardsStore.setSelectedLodgeCard({});
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  @Watch('currentCompany')
  companyChange(value) {
    this.company = value;
    this.getItems();
  }


  async getItems() {
    LodgeCardsStore.setCustomError(false);
    try {
      await LodgeCardsStore.getLodgeCardsConfiguration(router.currentRoute.params.configurationId);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        LodgeCardsStore.setCustomError(true);
      } else {
        LodgeCardsStore.setErrMessages(this.$handleErrors(error, true));
        LodgeCardsStore.setShowError(true);
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.name < b.name) {
        result = -1;
      }
      else if (a.name > b.name) {
        result = 1;
      }
      return result;
    });
  }

  mapLodgeCards(cards: any[]) {
    return JSON.parse(JSON.stringify(cards)).forEach(element => {
      delete element.maskedCardNumber;
    });
  }

  async updateConfiguration() {
    this.$v.configuration.$touch();
    if (!this.$v.configuration.$invalid) {
      let lodgeCards = JSON.parse(JSON.stringify(this.configuration.lodgeCards));
      lodgeCards.forEach(element => {
        delete element.maskedCardNumber;
        if (element.id.includes(this.tempIdPrefix)) {
          delete element.id;
        }
        if (element.transactionId && element.transactionId.includes('*')) {
          delete element.transactionId;
        }
        delete element.status;
      });
      let message = {
        configurationId: this.configurationId,
        params: {
          configurationName: this.configuration.configurationName,
          lodgeCards
        },
      };
      await LodgeCardsStore.updateLodgeCardsConfiguration(message);
      if (!this.errMessages.length) {
        await this.getItems();
      }
    }
  }

  async saveConfiguration() {
    this.$v.configuration.$touch();
    if (!this.$v.configuration.$invalid) {
      let lodgeCards = JSON.parse(JSON.stringify(this.configuration.lodgeCards));
      lodgeCards.forEach(element => {
        delete element.maskedCardNumber;
        delete element.id;
        delete element.status;
      });
      let message = {
        rootCompanyId: router.currentRoute.params.id,
        configurationName: this.configuration.configurationName,
        lodgeCards
      };
      await LodgeCardsStore.addLodgeCardsConfiguration(message);
    }
  }

  toggleCardsMenu(item) {
    if (!this.paymentCard || this.paymentCard.id !== item.id) {
      this.paymentCard = item;
      this.menuActive = true;
    } else {
      this.paymentCard = item;
      this.menuActive = !this.menuActive;
    }
  }

  async created() {
    LodgeCardsStore.setErrMessages([]);
    LodgeCardsStore.setShowError(false);
    LodgeCardsStore.setLoading(false);
    if (this.configurationId && this.configurationId !== 'new' && !this.fromManage) {
      await this.getItems();
    }
  }
}

