


























































































































































































































import { Vue, Component, Watch, Prop, Model, Emit } from 'vue-property-decorator';

import { BasketPaymentCardModel } from '@/api/trip/basket.model';
import { translate } from '@/i18n';
import accountStore from '@/store/account.store';
import { getSecureFields } from '@/plugins/secure-fields';
import messages from '@/const/error-messages.const';
import settings from '@/settings';
import EventBus from '@/services/event-handler';

@Component({})
export default class ExchangePaymentMethods extends Vue {
  @Model('change') value!: any;
  @Prop() paymentMethods!: any;
  @Prop() item!: any;
  @Prop() index!: any;
  @Prop({default: false}) disabledPaymentMethod!: boolean;

  selectedPaymentMethod: any = null;
  selectedPaymentCard: BasketPaymentCardModel | undefined | null = null;
  selectedLodgeCard: BasketPaymentCardModel | undefined | null = null;
  displayValidationMessages: boolean = false;
  selectedPaymentCardDoesNotHaveTypeInProfile: boolean = false;
  paymentErrors: string | null = null;
  secureFields: any | null = null;
  cvvValid: boolean = true;
  cvvLength: number = 0;
  cardOpened: boolean = false;
  methodOpened: boolean = false;
  secureFieldsReady: boolean = false;

  get isPaymentCardMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'PaymentCard' : false;
  }

  get isLodgeCardMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'LodgeCard' : false;
  }

  get canUserSelectPaymentCards() {
    return accountStore.HasPermission('ReadPaymentCards');
  }

  get pciProxyMerchantId() {
    return settings.pciProxy_MerchantId;
  }

  get pciProxyMockEnabled() {
    return settings.pciProxy_MockEnabled === 'true';
  }

  get paymentMethodValidationError() {
    return this.displayValidationMessages && !this.selectedPaymentMethod;
  }

  get paymentCardValidationError() {
    if (this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'LodgeCard' && !this.selectedLodgeCard) {
      return true;
    }
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard' && !this.selectedPaymentCard;
  }

  get transactionIdValidationError() {
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard'
    && this.selectedPaymentCard
    && this.cvvLength === 0 && !this.cvvValid && !this.pciProxyMockEnabled;
  }

  get invalidCvvErorr() {
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard'
    && this.selectedPaymentCard
    && this.cvvLength > 0 && !this.cvvValid && !this.pciProxyMockEnabled;
  }

  get isOpened() {
    return this.cardOpened || this.methodOpened;
  }

  get paymentCards() {
    return (this.paymentMethods.availablePaymentCards || [])
      .map(item => {
        return {
          ...item,
          $isDisabled: item.isSupportedByProvider === false || item.isExpired === true,
        };
      });
  }

  get supportedPaymentMethods() {
    return (this.paymentMethods.supportedPaymentMethods || [])
      .map(item => {
        return {
          ...item,
          $isDisabled: item.isNotAcceptable
        };
      });
  }

  get changingPaymentMethodAllowed() {
    return this.item.supplier !== 'Amadeus';
  }

  async onPaymentCardSelect(selectedCard) {
    if (selectedCard) {
      let oldSelectedCard = this.selectedPaymentCard;
      if (this.selectedPaymentCardDoesNotHaveTypeInProfile && oldSelectedCard && oldSelectedCard.paymentCardId !== selectedCard.paymentCardId) {
        oldSelectedCard!.type = null;
        oldSelectedCard!.isSupportedByProvider = false;
        oldSelectedCard!.cardFee = null;
      }
      if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
        this.selectedLodgeCard = selectedCard;
      } else {
        this.selectedPaymentCard = selectedCard;
      }
      this.selectedPaymentCardDoesNotHaveTypeInProfile = !selectedCard.type;
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard') {
        if (this.pciProxyMockEnabled) {
          this.selectedPaymentCard!.transactionId = 'MOCK';
        } else {
          this.selectedPaymentCard!.transactionId = null;
          this.$nextTick(() => {
            this.initSecureFields();
          });
        }
      }
      
      this.updatePaymentInfo();
    }
  }

  updatePaymentMethod(selectedMethod) {
    this.secureFieldsReady = false;
    this.selectedPaymentMethod = selectedMethod;

    if (this.selectedPaymentMethod.paymentType !== 'PaymentCard' || this.selectedPaymentMethod.paymentType !== 'LodgeCard') {
      this.selectedPaymentCard = null;
    }

    this.updatePaymentInfo();
  }
  
  @Emit('change')
  onChange(value) {
    return value;
  }

  updatePaymentInfo() {
    if (this.selectedPaymentMethod.paymentType === 'PaymentCard') {
      if (this.selectedPaymentCard) {
        this.onChange({
          paymentMethod: this.selectedPaymentMethod.paymentType,
          cardId: this.selectedPaymentCard.paymentCardId,
          transactionId: this.selectedPaymentCard.transactionId,
        });
      } else {
        this.onChange({
          paymentMethod: this.selectedPaymentMethod.paymentType,
          cardId: null,
          transactionId: null,
        });
      }
      
      this.$emit('item-paymentcard-changed', this.item);
    } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
      this.$emit('item-paymentcard-changed', this.item);
      this.onChange({
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: this.selectedLodgeCard ? this.selectedLodgeCard.paymentCardId : null,
      });
    } else if (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType !== 'PaymentCard' && this.selectedPaymentMethod.paymentType !== 'LodgeCard') {
      this.$emit('item-paymentcard-changed', this.item);
      this.onChange({
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: null,
      });
    }
  }

  onReady() {
    this.secureFieldsReady = true;
  }

  onValidate(data) {
    if (data.fields.cvv) {
      this.cvvValid = data.fields.cvv.valid;
      this.cvvLength = data.fields.cvv.length;
      if (!this.cvvValid) {
        this.onChange({
          hasErrors: true,
          paymentMethod: this.selectedPaymentMethod.paymentType,
          cardId: this.selectedPaymentCard!.paymentCardId,
          transactionId: null,
        });
        this.$emit('payment-validation-errors');
        this.displayValidationMessages = true;
      }
    }
  }

  onCVVChange(data) {
    if (data.fields.cvv) {
      this.cvvValid = data.fields.cvv.valid;
      this.cvvLength = data.fields.cvv.length;
    }
  }

  onSuccess(data) {
    if (data.transactionId) {
      this.selectedPaymentCard!.transactionId = data.transactionId;
      this.updatePaymentInfo();
      this.onChange({
        hasErrors: false,
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: this.selectedPaymentCard!.paymentCardId,
        transactionId: this.selectedPaymentCard!.transactionId,
      });
      this.$emit('payment-validation-success');
    }
  }

  onError(data) {
    if (data) {
      this.paymentErrors = translate(messages.unknown);

      this.$emit('payment-validation-errors');
      this.onChange({
        hasErrors: true,
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: this.selectedPaymentCard ? this.selectedPaymentCard.paymentCardId : null,
        transactionId: null,
      });
      this.displayValidationMessages = true;
    }
  }

  initSecureFields() {
    this.paymentErrors = null;
    this.secureFieldsReady = false;

    if (this.isPaymentCardMethod) {
      this.secureFields = getSecureFields();

      const styles = {
        '*': 'background: white; border-radius: 5px; border: 1px solid #999999;min-height: 70px; margin:5px 0; text-transform: inherit; height: 70px;color: #4D4D4D; padding-left: 13px; font-family: Orkney, "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, sans-serif; font-size: 14px; width: 99%; outline: 0;',
        '*::placeholder': 'color: #D8D8D8',
        '*:-ms-input-placeholder': 'color: #D8D8D8',
        '@font-face': {
          '*': {
              fontFamily: 'Orkney',
              fontStyle: 'normal',
              fontWeight: 400,
              src: 'url(\'Orkney Regular.woff2\') format(\'woff2\'), url(\'Orkney Regular.eot\') format(\'embedded-opentype\'), url(\'Orkney Regular.woff\') format(\'woff2\'),url(\'Orkney Regular.otf\') format(\'opentype\'),url(\'Orkney Regular.ttf\') format(\'truetype\')',
          }
        }
      };

      this.secureFields.on('ready', this.onReady);
      this.secureFields.on('change', this.onCVVChange);
      this.secureFields.on('validate', this.onValidate);
      this.secureFields.on('success', this.onSuccess);
      this.secureFields.on('error', this.onError);

      this.secureFields.initTokenize(this.pciProxyMerchantId, {
        cvv: {
          placeholderElementId: 'cvvPlaceholder' + this.index,
          placeholder: translate('basket.your-cvv'),
        }
      }, 
      {            
        styles,
      });
    }
  }

  async paymentValidation() {
    this.displayValidationMessages = false;
    let value: any = {
      tripItemId: this.paymentMethods.tripItemId,
      hasErrors: false,
      paymentMethod: null,
      cardId: '',
      cardTypeOverride: null,
      saveTypeOverrideIntoCardDefinition: true,
      transactionId: null,
      needSubmit: false,
    };
    
    if (!this.selectedPaymentMethod) {
      value.hasErrors = true;
    } else {
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && !this.selectedPaymentCard) {
        value.tripItemId = this.paymentMethods.tripItemId;
        value.hasErrors = true;
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.selectedPaymentCard && !this.pciProxyMockEnabled && !this.secureFieldsReady) {
        value.tripItemId = this.paymentMethods.tripItemId;
        value.hasErrors = true;
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.cardTypeOverride = this.selectedPaymentCard!.type;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.selectedPaymentCard && !this.pciProxyMockEnabled) {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.cardId = this.selectedPaymentCard!.paymentCardId;
        value.cardTypeOverride = this.selectedPaymentCard!.type;
        value.needSubmit = true;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.selectedPaymentCard && this.pciProxyMockEnabled) {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.cardId = this.selectedPaymentCard!.paymentCardId;
        value.cardTypeOverride = this.selectedPaymentCard!.type;
        value.needSubmit = false;
        value.transactionId = 'MOCK';
      } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.hasErrors = this.selectedLodgeCard ? false : true;
        value.cardId = this.selectedLodgeCard ? this.selectedLodgeCard.paymentCardId : null;
      } else if (this.selectedPaymentMethod.paymentType !== 'PaymentCard') {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
      }
    }
    this.onChange({
      paymentMethod: value.paymentMethod,
      cardId: value.cardId,
      transactionId: value.transactionId,
      hasErrors: value.hasErrors,
    });
    if (value.hasErrors) {
      this.displayValidationMessages = true;
      this.$emit('payment-validation-errors');
    } else {
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && !this.pciProxyMockEnabled) {
        this.secureFields.submit();
      } else {
        this.$emit('payment-validation-success');
      }
    }
  }

  onOpenMethod() {
    this.methodOpened = true;
  }

  onCloseMethod() {
    this.methodOpened = false;
  }

  onOpenCard() {
    this.cardOpened = true;
  }

  onCloseCard() {
    this.cardOpened = false;
  }

  @Watch('isOpened')
  onIsOpenedChange(value) {
    this.$emit('dropdown-change', value);
  }
   
  created() {
    if (this.paymentMethods && this.paymentMethods.selectedPaymentMethod) {
      this.selectedPaymentMethod = this.paymentMethods.supportedPaymentMethods
        .find(payment => payment.paymentType === this.paymentMethods.selectedPaymentMethod && !payment.isNotAcceptable);
    }
    
    if (!this.selectedPaymentMethod) {
      const defaultPaymentMethod = this.paymentMethods.supportedPaymentMethods.find(payment => payment.isDefault && !payment.isNotAcceptable);
      if (defaultPaymentMethod) {
        this.selectedPaymentMethod = defaultPaymentMethod;
      }
    }

    if (this.paymentMethods && this.selectedPaymentMethod && this.paymentMethods.selectedPaymentCardId) {
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.paymentMethods.availablePaymentCards) {
        this.selectedPaymentCard = this.paymentMethods.availablePaymentCards
          .find(card => card.paymentCardId === this.paymentMethods.selectedPaymentCardId) || null;

        this.onPaymentCardSelect(this.selectedPaymentCard);
      } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard' && this.paymentMethods.availableLodgeCards) {
        this.selectedPaymentCard = this.paymentMethods.availableLodgeCards
          .find(card => card.paymentCardId === this.paymentMethods.selectedPaymentCardId) || null;

        this.onPaymentCardSelect(this.selectedPaymentCard);
      }
    }

    EventBus.$on('exchange-payment-method-validation', this.paymentValidation);
  }

  mounted() {
    if (this.paymentMethods && this.paymentMethods.selectedPaymentMethod) {
      this.updatePaymentInfo();
    }

    if (this.isPaymentCardMethod && !this.pciProxyMockEnabled) {
      this.$nextTick(() => {
        this.initSecureFields();
      });
    }
  }

  beforeDestroy() {
    EventBus.$off('exchange-payment-method-validation', this.paymentValidation);
  }
}

