





















































import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

import BasketStore from './basket.store';
import AccountStore from '@/store/account.store';

@Component
export default class BasketHeader extends Vue {
  timeoutId: number = -1;
  focused: boolean = false;
  preventSave: boolean = false;

  get titleText(): string {
    return BasketStore.basketTitle;
  }

  set titleText(value) {
    BasketStore.setBasketTitle(value);
  }

  get titleError() {
    if (!this.titleErrors.length) {
      return null;
    }
    return this.titleErrors[0];
  }

  get isDisabled () {
    if (window.innerWidth < 800) {
      return true;
    } else {
      return false;
    }
  }

  get titleErrors() {
    return BasketStore.basketTitleErrors;
  }

  get shouldShowTitle() {
    return BasketStore.shouldShowTripTitle;
  }

  get searchId() {
    return this.$route.query.searchId;
  }

  get basketName() {
    if (!BasketStore.basket) {
      return '';
    }
    return BasketStore.basket.name;
  }

  get canShowFormattedBookingDate() {
    return !!(this.dateFormat && this.bookingDate && this.formattedBookingDate);
  }

  get bookingDate() {
    return BasketStore.basket ? BasketStore.basket.bookingDate : null;
  }

  get dateFormat() {
    return AccountStore.current && AccountStore.current.profile ? AccountStore.current.profile.shortDateFormat : null;
  }

  get formattedBookingDate() {
    return this.bookingDate && this.dateFormat ? moment(this.bookingDate).format(this.dateFormat) : null;
  }

  focusNow() {
    if (window.innerWidth < 800) {
      return;
    }
    clearTimeout(this.timeoutId);
    this.focused = true;
  }

  blur() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.focused = false;
      if (this.preventSave) {
        this.preventSave = false;
        return;
      }
      this.saveTitle();
    }, 300);
  }

  blurNow() {
    clearTimeout(this.timeoutId);
    this.focused = false;
  }

  onCancelClick() {
    this.titleText = this.basketName;
    this.blurNow();
  }

  saveTitle() {
    BasketStore.saveBasketName();
    this.blurNow();
  }

  keydown(e) {
    if (e.keyCode === 27) {
      this.focused = false;
      this.titleText = this.basketName;
      this.preventSave = true;
      (this.$refs.tripTitle as HTMLTextAreaElement).blur();
    } else if (e.keyCode === 13) {
      e.preventDefault();
      (this.$refs.tripTitle as HTMLTextAreaElement).blur();
    }
  }

  focusOnTripTitle() {
    setTimeout(() => {
      (this.$refs.tripTitle as HTMLTextAreaElement).focus();
    }, 50);
  }

}

