
























































































































import { Prop, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { AccomodationRuleConfigurationUpdateModel, AccomodationRuleModel } from '@/api/travel-policy/travel-policy.model';
import SettingsStore from '@/modules/settings/settings.store';
import TravelPolicyAccomodationStore from '@/modules/settings/travel-policy/travel-policy-accomodation.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import { CategoriesOptions } from '@/const/travel-policy.const';
import { translate } from '@/i18n';

@Component({})
export default class TravelPolicyAccomodation extends BaseTable<AccomodationRuleConfigurationUpdateModel> {
  @Prop({ default: false }) formPending: boolean = false;
  @Prop({ default: false }) readOnly?;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    location: {
      sortable: false,
      label: translate('settings-travel-policy.location'),
    },
    price : {
      sortable: false,
      label: translate('settings-travel-policy.price'),
    },
    category: {
      sortable: false,
      label: translate('settings-travel-policy.category'),
    },
    numberOfPeopleInRoom: {
      sortable: false,
      label: translate('settings-travel-policy.number-of-people-in-the-room-title'),
      class: 'travel-policy__column--number-of-people-in-the-room'
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    seeAll: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  $v;
  menuActive: boolean = false;
  rule: AccomodationRuleConfigurationUpdateModel | null = null;
  modifyPopup: boolean = false;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 0;
  itemList: AccomodationRuleModel[] = [];
  categoriesOptions = CategoriesOptions;

  get ruleList() {
    return TravelPolicyAccomodationStore.getAccomodationRules;
  }

  get hideNonCompliantRates() {
    return TravelPolicyAccomodationStore.travelPolicy.hideNonCompliantRates;
  }

  set hideNonCompliantRates(value) {
    TravelPolicyAccomodationStore.setHideNonCompliantRates(value);
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  get count() {
    if (!this.results) {
      return 0;
    }

    return this.results.length;
  }

  get loadingList() {
    return TravelPolicyStore.loading;
  }

  categoryForTable(categories) {
    if (categories.length === 1) {
      return `1 ${translate('settings-travel-policy.category-lowercase')}`;
    } else {
      return `${categories.length} ${translate('settings-travel-policy.categories-lowercase')}`;
    }
  }

  modifyRule(item) {
    TravelPolicyAccomodationStore.selectedAccomodationRule(item);
    router.push({
      name: 'accomodation-rule',
      params: {
        configurationId: this.currentConfigurationId,
      },
      query: {
        readOnly: this.readOnly.toString()
      }
    });
  }

  removeRule(selected) {
    if (selected) {
      let filterList = _.filter(this.ruleList, (item) => {
        return item.id !== selected.id;
      });
      TravelPolicyAccomodationStore.setAccomodationRules(filterList);
      this.reload();
    }
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.ruleList,
    };
  }

  toggleRuleMenu(item) {
    if (this.rule !== item) {
      this.rule = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  addRule() {
    TravelPolicyAccomodationStore.selectedAccomodationRule({});
    if (this.currentConfigurationId) {
      router.push({
        name: 'accomodation-rule',
        params: {
          configurationId: this.currentConfigurationId
        },
      });
    } else {
      router.push({
        name: 'accomodation-rule',
      });
    }
  }

  returnToConfigurations() {
    router.push({
      name: 'travel-policy-configurations'
    });
  }

  onReceive() {
    this.reload();
  }

  created() {
    EventBus.$on('refresh-data', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-data', this.onReceive);
  }

  @Watch('ruleList')
  onRuleListChange() {
    this.reload();
  }
}
