import AF_CANCELLED_REBOOKED_NOTIFICATION_ISSUED_ELIGIBLE from '@/modules/notifications/messages/AFCancelledRebookedNotificationIssuedEligible.vue';
import AF_CANCELLED_REBOOKED_NOTIFICATION_ISSUED from '@/modules/notifications/messages/AFCancelledRebookedNotificationIssued.vue';
import AF_CANCELLED_REBOOKED_NOTIFICATION from '@/modules/notifications/messages/AFCancelledRebookedNotification.vue';
import AF_REBOOKED_NOTIFICATION_ISSUED_ELIGIBLE from '@/modules/notifications/messages/AFRebookedNotificationIssuedEligible.vue';
import AF_REBOOKED_NOTIFICATION_ISSUED from '@/modules/notifications/messages/AFRebookedNotificationIssued.vue';
import AF_REBOOKED_NOTIFICATION from '@/modules/notifications/messages/AFRebookedNotification.vue';
import AF_CANCELLED_NOTIFICATION_ISSUED from '@/modules/notifications/messages/AFCancelledNotificationIssued.vue';
import AF_CANCELLED_NOTIFICATION from '@/modules/notifications/messages/AFCancelledNotification.vue';
import DefaultMessage from '@/modules/notifications/messages/DefaultMessage.vue';

const Component = {
  AF_CANCELLED_REBOOKED_NOTIFICATION_ISSUED_ELIGIBLE,
  AF_CANCELLED_REBOOKED_NOTIFICATION_ISSUED,
  AF_CANCELLED_REBOOKED_NOTIFICATION,
  AF_REBOOKED_NOTIFICATION_ISSUED_ELIGIBLE,
  AF_REBOOKED_NOTIFICATION_ISSUED,
  AF_REBOOKED_NOTIFICATION,
  AF_CANCELLED_NOTIFICATION_ISSUED,
  AF_CANCELLED_NOTIFICATION,
};

type TKeys = keyof typeof Component;
type TComponents = typeof Component[TKeys] | DefaultMessage;

export const getMessages = (code: string): TComponents => {
  return Component[code] || DefaultMessage;
};
