import BillingCustomFieldsConfiguration from '@/modules/settings/billing-custom-fields/BillingCustomFieldsConfiguration.vue';

export default {
  path: ':configurationId?/billing-custom-fields-configuration',
  name: 'billing-custom-fields-configuration',
  component: BillingCustomFieldsConfiguration,
  displayName: 'Billing Custom Fields Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditBackOfficeCustomFields',
    parentName: 'billing-custom-fields-configurations',
    depthCategory: 'billing-custom-fields-configurations',
    depth: 2,
  }
};