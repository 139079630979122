




































































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import EventHandler from '@/services/event-handler';
import SettingsStore from '../settings.store';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import {
  ConfigurationRow,
  companyConfigurationType
} from '@/api/profile/configurations.model';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import NegoFaresStore from '@/modules/settings/nego-fare/nego-fares.store';
import NegoFaresFlightStore from '@/modules/settings/nego-fare/nego-fares-flight.store';
import negoFaresTrainStore from './nego-fares-train.store';
import NegoFaresAccommodationStore from './nego-fares-accommodation.store';
import { sanitizeUrl } from '@braintree/sanitize-url';


@Component({})
export default class NegoFareConfigurations extends BaseTable<ConfigurationRow> {
  useQueryParams: boolean = true;
  editMode: boolean = false;
  loading: boolean = false;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: '',
      class: 'settings-subpage__actions-column',
    }
  };
  items: ConfigurationRow[] = [];
  selectedSet: ConfigurationRow | null = null;
  selectedSetId: string | null = null;
  company = this.currentCompany;
  errors: any[] = [];
  errorsOnPopup: any[] = [];
  itemToRemove: ConfigurationRow | null = null;
  removalConfigurationMessage: any[] = [];
  removePopup: boolean = false;
  menuActive: boolean = false;
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.items.length;
  }

  get isInRootBusinessUnit() {
    return SettingsStore.currentCompany ? SettingsStore.currentCompany.isRoot : false;
  }

  get addNewUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/nego-fare-configurations/new');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/nego-fare-configurations/' + item.id);
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  toggleConfigurationSetMenu(item) {
    if (!this.selectedSet || this.selectedSet.id !== item.id) {
      this.selectedSet = item;
      this.menuActive = true;
    } else {
      this.selectedSet = item;
      this.menuActive = !this.menuActive;
    }
  }

  addConfigrations() {
    NegoFaresStore.setCurrentConfigurationName('');
    NegoFaresFlightStore.clearCurrentConfiguration();
    negoFaresTrainStore.clearCurrentConfiguration();
    NegoFaresAccommodationStore.clearCurrentConfiguration();
    NegoFaresStore.setConfigurationId('');
    NegoFaresStore.setConfiguration({});
    NegoFaresFlightStore.setCorporateCodesList([]);
    negoFaresTrainStore.setCorporateCodesList([]);
    NegoFaresAccommodationStore.setCorporateCodesList([]);
  }

  editConfigurations(item, preventRedirect = false) {
    NegoFaresStore.setCurrentConfiguration(item);
    NegoFaresStore.setIsFromManage(false);
    if (!preventRedirect) {
      this.$router.push({
        name: 'nego-fare-configuration',
        params: {
          configurationId: item.id,
        },
      });  
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    if (this.company) {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      try {
        const result = await ProfileConfigurationsApi.getByRootCompanyId(
          this.company.rootCompanyId,
          companyConfigurationType.NegoFareConfiguration,
          this.params.size,
          this.params.page,
          Permission.ReadNegoFare,
          request.searchName
        );
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      } catch (error) {
        this.errors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
      }
    }
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.selectedSet!.id, Permission.WriteNegoFare);
      await this.reload();

      const obj = {
        type: translate('common.success'),
        title: translate('settings-nego-fare.configuration-removed'),
        message: translate('settings-nego-fare.configuration-removed-message')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
      this.loading = false;
    } finally {
      this.removePopup = false;
    }
  }
}
