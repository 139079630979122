



















import { Vue, Component, Watch } from 'vue-property-decorator';

import EventHandler from '@/services/event-handler';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import { AirSearchStateParams } from '@/modules/search/air/air-search.params';
import { HotelSearchStateParams } from '@/modules/search/hotel/hotel-search.params';
import formatDateMoment from '@/filters/format-data-moment.filter';
import { serviceClassEnum } from '@/api/home/home.model';
import { TripApi } from '@/api/trip/trip.api';

@Component({
  components: {
  },
})
export default class ExternalMissionRedirect extends Vue {
  missionId: string | null = null;
  loading: boolean = true;
  searchMode: string = 'RoundTrip';
  serverErrors: any[] = [];

  @Watch('$route.params.missionId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  async initView() {
    this.serverErrors = [];
    this.missionId = this.$route.params.missionId;

    if (this.missionId) {
      try {
        const result = await TripApi.getExternalMissionTrips(this.missionId);
        
        if (result && result.data) {
          if (result.data.tripId) {
            let next: any = {
              name: 'basket', 
              params: { 
              id: result.data.tripId, 
              }
            };
            this.$router.push(next);
          } else if (result.data.mission) {
            let userObj = {
              businessUnitId: result.data.mission.traveller.businessUnitId,
              businessUnitName: null,
              companyId: result.data.mission.traveller.companyId,
              companyName: result.data.mission.traveller.companyName,
              dateOfBirth: result.data.mission.traveller.dateOfBirth,
              firstName: result.data.mission.traveller.firstName,
              middleName: result.data.mission.traveller.middleName || '',
              id: result.data.mission.traveller.id,
              isMainTraveller: true,
              lastName: result.data.mission.traveller.lastName
            };

            SearchStore.updateTravellersDefaultState({travellers: [userObj]});
            SearchStore.selectNavigated(serviceClassEnum.Hotel);
            SearchStore.selectBasket('');

            const locationHotel = {
              ...result.data.mission.destination,
              identifier: result.data.mission.destination.cityCode,
              displayText: result.data.mission.destination.cityName + ', ' + result.data.mission.destination.countryCode,
            };

            SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
              to: locationHotel,
              checkInDate: formatDateMoment(result.data.mission.missionStart),
              checkOutDate: formatDateMoment(result.data.mission.missionEnd),
            }));
            
            SearchStore.updateAirDefaultState(new AirSearchStateParams({
              searchMode: this.searchMode,
              from: result.data.mission.departure,
              to: result.data.mission.destination,
              departureDate: formatDateMoment(result.data.mission.missionStart),
              returnDate: formatDateMoment(result.data.mission.missionEnd),
            }));
          
            let next: any = {
              name: 'search',
              query: {
                missionId: this.missionId,
              }
            };

            this.$router.push(next);
          }
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
      }
    }
  }
}

