import SystemMarkupRuleHotel from '@/modules/settings/system-markup/SystemMarkupRuleHotel.vue';

export default {
  path: ':configurationId?/hotel-system-markup-rule',
  name: 'system-markup-rule-hotel',
  component: SystemMarkupRuleHotel,
  displayName: 'Hotel system markup rule definition',
  meta: {
    breadCrumb: 'Hotel system markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    parentName: 'system-markup',
    depthCategory: 'system-markup',
    depth: 3,
  }
};