import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import moment from 'moment';
import axios, { CancelTokenSource } from 'axios';

import { store } from '@/store';
import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import filtersConst from '@/const/filter.const';
import EventBus from '@/services/event-handler';
import {
  serviceClassEnum,
} from '@/api/home/home.model';
import {
  AccommodationOfferSearchResult,
  SortOptions,
  SearchState,
  MapCoordinates,
  MapProperty,
  MapPropertiesResponse,
  AccommodationSearchProviderError,
  SearchTraveller,
  GuestSearchTraveller,
} from '@/api/accommodation-engine/accommodation-search.model';
import { HotelSearchStateParams } from './hotel-search.params';
import { TravellersStateParams } from '../travellers.params';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import layoutStore from '@/modules/layout/layout.store';
import { translate } from '@/i18n';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import AccountStore from '@/store/account.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import settings from '@/settings';
import { isCancelError } from '@/core/utils';
import BasketStore from '@/modules/basket/basket.store';
import { AccommodationFeesApi } from '@/api/accommodation-engine/accommodation-fees.api';

const currency = {
  code: 'EUR',
  symbol: '€',
};

const mockedStats = [
  {
    code: 'PRICE',
    max: 4119.632,
    maxLimit: 4119.632,
    min: 189.98,
    minLimit: 189.98,
  },
  {
    code: 'STAR_CATEGORY',
    items: [
      {
        code: 'UNRATED',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Unrated',
        selected: false,
      },
      {
        code: 'ONE',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '*',
        selected: false,
      },
      {
        code: 'TWO',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '**',
        selected: false,
      },
      {
        code: 'THREE',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '***',
        selected: false,
      },
      {
        code: 'FOUR',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '****',
        selected: true,
      },
      {
        code: 'FIVE',
        matches: 3,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '*****',
        selected: false,
      }
    ],
  },
  {
    code: 'DISTANCE',
    value: 3,
    maxLimit: 20,
    minLimit: 0,
    unit: 'km',
  },
  {
    code: 'HOTEL_INPUTS',
    value1: '',
    value2: '',
    value3: '',
  },
  {
    code: 'MEAL_TYPE',
    items: [
      {
        code: 'NONE',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Room only',
        selected: false,
      },
      {
        code: 'WITH_MEAL',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'With meal',
        selected: true,
      },
    ],
  },
  {
    code: 'ROOM_TYPE',
    items: [
      {
        code: 'SINGLE',
        matches: 29,
        minPrice: {
          amount: 140.12,
          currency,
        },
        name: 'Single room',
        selected: true,
      },
      {
        code: 'DOUBLE',
        matches: 17,
        minPrice: {
          amount: 195.28,
          currency,
        },
        name: 'Double room',
        selected: false,
      },
      {
        code: 'TWIN',
        matches: 9,
        minPrice: {
          amount: 239.99,
          currency,
        },
        name: 'Twin room',
        selected: false,
      },
    ],
  },
  {
    code: 'CONDITIONS',
    items: [
      {
        code: 'FREE',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Free cancellation',
        selected: false,
      },
      {
        code: 'COMPLAINT_ONLY',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Complaint rates only',
        selected: true,
      },
    ],
  },
  {
    code: 'TRIP_ADVISOR_RATING',
    items: [
      {
        code: 'EXCELLENT',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Excellent: 5',
        selected: false,
      },
      {
        code: 'VERY_GOOD',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Very good: 4+',
        selected: false,
      },
      {
        code: 'AVERAGE',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Average: 3+',
        selected: false,
      },
      {
        code: 'POOR',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Poor: 2+',
        selected: false,
      },
      {
        code: 'UNRATED',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Unrated',
        selected: false,
      },
    ],
  },
  {
    code: 'AMENITIES',
    items: [
      {
        code: 'Restaurant',
        matches: 12,
        minPrice: {
          amount: 249.06,
          currency,
        },
        name: 'Restaurant',
        selected: false,
      },
      {
        code: 'Free wifi',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Free wifi',
        selected: false,
      },
      {
        code: '24h desk',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: '24h desk',
        selected: false,
      },
      {
        code: 'Parking',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Parking',
        selected: false,
      },
      {
        code: 'Free airport transportation',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Free airport transportation',
        selected: false,
      },
      {
        code: 'Disability support',
        matches: 13,
        minPrice: {
          amount: 199.06,
          currency,
        },
        name: 'Disability support',
        selected: false,
      },
    ],
  },
  // {
  //   code: 'ROOM_TYPES',
  //   items: [
  //     {
  //       code: 'SINGLE',
  //       matches: 12,
  //       minPrice: {
  //         amount: 249.06,
  //         currency,
  //       },
  //       name: 'Single room',
  //       selected: false,
  //     },
  //     {
  //       code: 'DOUBLE',
  //       matches: 13,
  //       minPrice: {
  //         amount: 199.06,
  //         currency,
  //       },
  //       name: 'Double room',
  //       selected: true,
  //     },
  //     {
  //       code: 'TWIN',
  //       matches: 13,
  //       minPrice: {
  //         amount: 199.06,
  //         currency,
  //       },
  //       name: 'Twin room',
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   code: 'PROPERTY_TYPE',
  //   items: [
  //     {
  //       code: 'HOSTEL',
  //       matches: 12,
  //       minPrice: {
  //         amount: 249.06,
  //         currency,
  //       },
  //       name: 'Hostel',
  //       selected: false,
  //     },
  //     {
  //       code: 'HOTEL',
  //       matches: 13,
  //       minPrice: {
  //         amount: 199.06,
  //         currency,
  //       },
  //       name: 'Hotel',
  //       selected: true,
  //     },
  //     {
  //       code: 'APPARTMENT',
  //       matches: 13,
  //       minPrice: {
  //         amount: 199.06,
  //         currency,
  //       },
  //       name: 'Appartment',
  //       selected: false,
  //     },
  //   ],
  // },
];

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'hotelSearch'
})
class HotelSearchStore extends VuexModule {
  loading: boolean = false;
  sortOptions: SortOptions = {
    DISTANCE: 'Ascending',
  };
  offersVisible: number = 0;
  totalOffers: number = 0;
  filtersResponse: any[] = [];
  convertedFilters: any[] = [];
  stateId: string = '';
  searchId: string = '';
  searchIdChanged: boolean = false;
  searchLoaded: boolean = false;
  shouldStopSearch: boolean = false;
  selectedOfferId: string = '';
  offers: any = [];
  propertyOffersLoading: string | null = null;
  selectingOffer: boolean = false;
  searchInProgress: boolean = false;
  scrollTimeout: number = 0;
  isMapView: boolean = false;
  detailedOffer: any = {};
  lastKnownCoords: MapCoordinates = {
    latLng: {
      lat: 0,
      lon: 0,
    },
    zoom: 15,
  };
  cancelToken: CancelTokenSource | null = null;
  recommendationsHotelCount: number = 0;
  mapProperties: MapProperty[] = [];
  mapPropertiesLoading: boolean = false;
  searchCompleted: boolean = false;
  serverErrors: any[] = [];
  showError: boolean = false;
  agencyMarkup: number = 0;
  searchTimeout: number = -1;
  searchFreezed: boolean = false;
  filtersRequestId: number = 0;
  filtersChanging: boolean = false;
  filtersRequestBody: any = null;
  filtersError: boolean = false;
  providersErrors: any[] = [];
  currentCurrency: any = null;
  roomsOptions: any[] = [];
  guestRooms: any[] = [];
  editedFeeOffer: any = null;
  editedFeeResult: any = null;


  get canShowAccommodationPackageRates() {
    return 'true' === settings.enableExternalAccommodationPackageRates;
  }

  get filters() {
    if (this.filtersResponse.length === 0) {
      return [];
    }
    const statsList = this.filtersResponse;
    return filtersConst.hotel.filters.map(filter => {
      let rest: any = {};
      const stats = statsList.find(item => item.code === filter.code);

      if (!stats) {
        if (filter.type === 'textbox') {
          rest = {
            data: {
              value: '',
              ...stats,
            },
          };
        }
      } else if (filter.type === 'range') {
        rest = {
          data: {
            minValue: stats.min,
            minLimit: stats.minLimit,
            maxValue: stats.max,
            maxLimit: stats.maxLimit
          }
        };
      } else if (filter.type === 'category' && stats.items) {
        rest = {
          values: stats.items.map(i => {

            if (filter.translate) {
              if (i.name) {
                i.name = translate('search-hotel-filters.' + i.code);
              }
            }

            const item: any = {
              code: i.code,
              name: i.name,
              selected: i.selected,
              matches: i.matches,
              minPrice: i.minPrice,
            };

            if (filter.code === 'CATEGORY') {
              item.stars = Number(i.code);
              if ((item.stars < 2) || (item.stars > 4)) {
                item.shouldHide = true;
              } else {
                item.shouldHide = false;
              }
            }

            return item;
          }),
        };

        if (filter.code === 'CATEGORY') {
          rest.customHideOptions = true;
        }
      } else {
        rest = {
          data: {
            ...stats,
          },
        };
      }

      let data = {
        ...filter,
        label: translate(filter.label)
      };

      return {
        ...data,
        ...rest,
      };
    });
  }

  get offerFilters() {
    const statsList = mockedStats;
    return filtersConst.hotel.offersFilters.map(filter => {
      let rest: any = {};
      const stats = statsList.find(item => item.code === filter.code);
      if (!stats) {
      } else if (filter.type === 'range') {
        rest = {
          data: {
            minValue: stats.min,
            minLimit: stats.minLimit,
            maxValue: stats.max,
            maxLimit: stats.maxLimit
          }
        };
      } else if (filter.type === 'category' && stats.items) {
        rest = {
          values: stats.items.map(i => {
            return {
              code: i.code,
              name: i.name,
              selected: i.selected,
              matches: i.matches,
              minPrice: i.minPrice ? i.minPrice.amount : null,
            };
          }),
        };
      } else {
        rest = {
          data: {
            ...stats,
          },
        };
      }

      let data = {
        ...filter,
        label: translate(filter.label)
      };

      return {
        ...data,
        ...rest,
      };
    });
  }

  get filtersRequest() {
    const result = {};
    this.filters.forEach(filter => {
      if (!result[filter.category + 'Filters']) {
        result[filter.category + 'Filters'] = [];
      }
      const item: any = {
        code: filter.code,
      };
      switch (filter.category) {
        case 'string':
          item.value = filter.data.value;
          break;
        case 'numericRange':
          if (!filter.data) {
            item.min = null;
            item.max = null;
            item.minLimit = null;
            item.maxLimit = null;
          } else {
            if (filter.data.minValue <= filter.data.minLimit) {
              item.min = null;
            } else {
              item.min = filter.data.minValue;
            }
            if (filter.data.maxValue >= filter.data.maxLimit) {
              item.max = null;
            } else {
              item.max = filter.data.maxValue;
            }
            item.maxLimit = filter.data.maxLimit;
            item.minLimit = filter.data.minLimit;
          }
          break;
        case 'stringCategories':
          item.requiredCategories = filter.values ? 
            filter.values
              .filter(item => item.selected)
              .map(item => item.code) :
            [];
          break;
        case 'numericCategories':
          item.requiredCategories = filter.values
            .filter(item => item.selected)
            .map(item => Number(item.code));
          break;
        case 'bool':
          item.value = filter.data !== undefined && filter.data.selected;
          break;
      }
      result[filter.category + 'Filters'].push(item);
    });

    return result;
  }



  @Mutation
  setNewSort({ sorterCode, isAscending = true }) {
    this.sortOptions = {
      [sorterCode]: isAscending ? 'Ascending' : 'Descending',
    };
  }

  @Mutation
  updateSearchStateId(value: string) {
    this.stateId = value;
  }

  @Mutation
  setSearchCompleted(state: boolean) {
    this.searchCompleted = state;
  }

  @Mutation
  setServerErrors(error, addMessagetoError?) {
    this.serverErrors = $handleErrors(error, true, undefined, addMessagetoError || false);
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setSearchId(searchId) {
    this.searchId = searchId;
  }

  @Mutation
  setSearchFilters(filters) {
    this.filtersResponse = filters;
  }

  @Mutation
  setRoomsOptions(value) {
    this.roomsOptions = value;
  }

  @Mutation
  setGuestRooms(value) {
    this.guestRooms = value;
  }

  @Mutation
  setCurrency(value) {
    this.currentCurrency = value;
  }

  @Mutation
  updateFilterValue(filter) {
    const def = filtersConst.hotel.filters.find(item => item.code === filter.code);

    if (!def) {
      return;
    }
    let f = this.filtersResponse.find(f => f.code === filter.code);
    let notFound = false;
    if (!f) {
      notFound = true;
      f = {
        code: filter.code,
      };
    }
    switch (def.type) {
      case 'range':
        f.min = filter.data.min;
        f.max = filter.data.max;
        break;
      case 'category':
        f.items = filter.data;
        break;
      case 'checkbox':
        f.selected = filter.data;
        break;
      case 'textbox':
        f.value = filter.value;
        break;
    }
    if (notFound) {
      this.filtersResponse.push(f);
    }
  }

  @Mutation
  setTimeoutInterval(state: number) {
    this.scrollTimeout = state;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setLoadingPropertyOffers(value) {
    this.propertyOffersLoading = value;
  }

  @Mutation
  setOffersVisible(num) {
    this.offersVisible = num;
  }

  @Mutation
  setTotalOffers(value) {
    this.totalOffers = value;
  }

  @Mutation
  setRecommendationsHotelCount(value) {
    this.recommendationsHotelCount = value;
  }

  @Mutation
  setOffers(offers) {
    this.offers = [...offers];
  }

  @Mutation
  setMapProperties(properties) {
    this.mapProperties = properties;
  }

  @Mutation
  setMapPropertiesLoading(value) {
    this.mapPropertiesLoading = value;
  }

  @Mutation
  updateOfferStats({ stats, offerId }) {
    const property = this.offers.find(item => item.property.id === offerId);
    if (!property) {
      return;
    }
    property.propertySearchStatistics = stats;
  }

  @Mutation
  setSelectingOffer(value) {
    this.selectingOffer = value;
  }

  @Mutation
  setSearchInProgress(state: boolean) {
    this.searchInProgress = state;
  }

  @Mutation
  setMapViewMode(value) {
    this.isMapView = value;
  }

  @Mutation
  setDetailedOffer(value) {
    this.detailedOffer = value;
  }

  @Mutation
  setLastKnownCoords(value = {
    latLng: {
      lat: 0,
      lon: 0,
    },
    zoom: 15,
  }) {
    this.lastKnownCoords = value;
  }

  @Mutation
  setSelectedOfferId(value) {
    this.selectedOfferId = value;
  }

  @Mutation
  clearSearchTimeout() {
    clearTimeout(this.searchTimeout);
  }

  @Mutation
  setSearchTimeout(timeout) {
    this.searchTimeout = setTimeout(timeout, 1000);
  }

  @Mutation
  finishLoading() {
    if (this.searchIdChanged) {
      this.searchLoaded = true;
      this.searchIdChanged = false;
    }
  }

  @Mutation
  setupSearchLoaded(searchId) {
    if (this.searchId !== searchId) {
      this.searchIdChanged = true;
      this.searchId = searchId;
      this.searchLoaded = false;
    } else {
      this.searchIdChanged = false;
    }
  }

  @Mutation
  updateCancelToken(tokenSource: CancelTokenSource | null) {
    this.cancelToken = tokenSource;
  }

  @Mutation
  createCancelTokenIfNeeded() {
    if (this.cancelToken !== null) {
      return;
    }
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    this.cancelToken = cancelTokenSource;
  }

  @Mutation
  setNewAgencyMarkup(value) {
    this.agencyMarkup = value;
  }

  @Mutation
  setSearchFreezed(value: boolean) {
    this.searchFreezed = value;
  }

  @Mutation
  updateFiltersRequestId() {
    this.filtersRequestId++;
  }

  @Mutation
  setFiltersError(value) {
    this.filtersError = value;
  }

  @Mutation
  setFiltersChanging(value) {
    this.filtersChanging = value;
  }

  @Mutation
  setFiltersRequestBody(value) {
    this.filtersRequestBody = value;
  }

  @Mutation
  setProvidersErrors(errors: AccommodationSearchProviderError[]) {
    this.providersErrors = errors;
  }

  @Mutation
  setEditedOffer({ offer, result }) {
    this.editedFeeOffer = offer;
    this.editedFeeResult = result;
  }



  @Action
  async initHotelSearch(basketId?: string) {
    this.setOffers([]);
    this.setTotalOffers(0);
    this.setRecommendationsHotelCount(0);
    this.setSearchInProgress(true);
    this.setShowError(false);
    this.setSelectingOffer(false);
    this.setSelectedOfferId('');
    this.setSearchCompleted(false);
    this.setNewSort({ sorterCode: 'DISTANCE', isAscending: true });
    const params = SearchStore.getHotelDefaultState;
    const travellersList = SearchStore.getTravellersState;
    this.setMapViewMode(false);
    this.setLastKnownCoords();

    router.push({
      name: 'hotel',
      params: { searchId: '-' },
    });

    try {
      const missionId = SearchStore.missionId;
      const hasVirtualTravellers = travellersList.travellers
        .some(traveller => !!traveller.isVirtual);
      const searchCompanyId = hasVirtualTravellers ? SearchStore.skipTravellersCompany.id : null;
      const request = {
        travellers: travellersList.travellers as (SearchTraveller | GuestSearchTraveller)[],
        checkInDate: moment(params.checkInDate).format('YYYY-MM-DD'),
        checkOutDate: moment(params.checkOutDate).format('YYYY-MM-DD'),
        destinationLocation: params.to,
        radius: params.distance,
        searchCompanyId,
      };
      const hasBasketMetadata = BasketStore.basketMetadata ? !!BasketStore.basketMetadata.metadata : false;
      const hasCorrectPermission = AccountStore.HasPermission('CanSearchMixedHotelRooms');
      if (this.canShowAccommodationPackageRates) {
        request['packageRates'] = params.packageRates;
      }
      if (
        hasCorrectPermission &&
        !hasBasketMetadata &&
        router.currentRoute.name !== 'basketAddSegment' &&
        SearchStore.skipTravellers &&
        AccountStore.HasPermission('SelectTravellersForSearch')
      ) {
        const roomsMapped = this.guestRooms
          .map((opts, index) => {
            return {
              profiles: opts.profiles.map(t => {
                return {
                  ...t,
                  roomId: index,
                };
              }),
            };
          }) as any[];
        const travellers = roomsMapped.reduce((prev, cur) => {
            return [...prev, ...cur.profiles];
          }, []);
        request.travellers = travellers as any;
      } else if (hasCorrectPermission) {
        const roomsFiltered = this.roomsOptions
          .filter(room => !!room.profiles.length);
        request.travellers = (travellersList.travellers as SearchTraveller[])
          .map(traveller => {
            const roomId = roomsFiltered.findIndex(opts => {
              return -1 < opts.profiles.indexOf(traveller.id);
            });
            return {
              ...traveller,
              roomId,
            };
          });
      }

      let response;

      if (basketId && !missionId) {
        response = await AccommodationSearchApi.addSegment(request, basketId);
        await BasketStore.getBasketMetadata(basketId);
      } else if (missionId && !basketId) {
        response = await AccommodationSearchApi.initSearchForMission(request, missionId);
      } else if (missionId && basketId) {
        response = await AccommodationSearchApi.addSegmentForMission(request, basketId, missionId);
        await BasketStore.getBasketMetadata(basketId);
      } else {
        response = await AccommodationSearchApi.initSearch(request);
        BasketStore.setBasketMetadata(null);
      }

      setTimeout(() => {
        SearchStore.select(serviceClassEnum.Hotel);
      });

      if (response && response.data && this.searchInProgress) {
        this.setSearchInProgress(false);
        SearchStore.selectBasket(response.data.basketId);
        SearchStore.setMissionId(response.data.missionId);

        if (response.data.missionId !== null) {
          await SearchStore.getMissionDetails();
        }
        this.setSearchInProgress(false);
        clearInterval(this.scrollTimeout);
        this.setTimeoutInterval(setInterval(() => {
          if (!layoutStore.slots.intro.transitioning) {
            clearInterval(this.scrollTimeout);
            router.replace({
              name: 'hotel',
              params: { searchId: response.data.searchId }
            });
          }
        }, 30));
      }
    } catch (error) {
      this.setServerErrors(error);
      this.setShowError(true);
      this.setLoading(false);
    }
  }

  @Action
  async updateSearchFilters(searchId: string) {
    if (this.searchId !== searchId) {
      return;
    }

    this.updateFiltersRequestId();
    this.setLoading(true);
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.updateCancelToken(null);
    }
    this.clearSearchTimeout();
    this.setSearchFreezed(true);
    this.createCancelTokenIfNeeded();

    try {
      const response = await AccommodationSearchApi.updateSearchFilters(
        this.searchId,
        this.filtersRequestBody.filtersData,
        this.cancelToken || undefined
      );
    } catch (error) {
      if (!isCancelError(error)) {
        this.setServerErrors(error);
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getSearchSession(searchId) {
    try {
      const sessionResponse = await AccommodationSearchApi.getSearchSession(searchId);

      if (this.searchId !== searchId) {
        return;
      }

      const session = sessionResponse.data.requestMessage;
      const meta = sessionResponse.data.metadata;
      const sortQuery = await this.buildSortQuery();
      SearchStore.selectBasket(meta.basketId);
      if (meta.agencyMarkup) {
        this.setNewAgencyMarkup(meta.agencyMarkup.amount);
      }
      SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
        checkInDate: session.checkInDate,
        checkOutDate: session.checkOutDate,
        distance: session.radius,
        packageRates: session.packageRates,
        to: {
          ...session.destinationLocation,
        },
      }));
      SearchStore.updateTravellersDefaultState(new TravellersStateParams({
        travellers: session.travellers,
      }));
      SearchStore.updateEditedTravellers(new TravellersStateParams({
        travellers: session.travellers,
      }));
      SearchStore.updateSkipTravellers(session.travellers!.some(trav => {
        return trav.isVirtual ? trav.isVirtual : false;
      }));

      let isVirtualTraveller = session.travellers!.find(trav => trav.isVirtual === true);

      if (isVirtualTraveller) {
        let companyModel =  {
          name: isVirtualTraveller.businessUnitName,
          code: isVirtualTraveller.companyCode,
          id: isVirtualTraveller.businessUnitId,
          isDisabled: false,
          parentId: null,
          rootId: isVirtualTraveller.companyId,
          isSelected: false,
        };                             

        SearchStore.setSkipTravellersCompany(companyModel);
        EventBus.$emit('update-skip-travellers-company');
      }
      
      SearchStore.setMissionId(meta.missionId);
      SearchStore.selectBasket(meta.basketId);
      if (meta.missionId !== null) {
        await SearchStore.getMissionDetails();
      }
      if (!BasketStore.basketMetadata || BasketStore.basketMetadata.basketId !== meta.basketId) {
        await BasketStore.getBasketMetadata(meta.basketId);
      }
    } catch (error) {
      this.setServerErrors(error);
      this.setShowError(true);
      this.setLoading(false);
    }
  }

  @Action
  async search(searchId: string) {
    this.setLoading(true);
    if (this.searchId !== searchId) {
      this.setSearchFilters([]);
      this.setSearchId(searchId);
    }
    try {
      this.createCancelTokenIfNeeded();
      const sortQuery = await this.buildSortQuery();
      const offersResponse = await AccommodationSearchApi
        .getProperties({ searchId, sortQuery }, this.cancelToken || undefined);
      const offers = offersResponse.data.properties;

      if (offersResponse.data.filtersStatistics !== null && !this.filtersChanging) {
        this.setSearchFilters(offersResponse.data.filtersStatistics);
      }

      if (offersResponse.data && offersResponse.data.currency) {
        this.setCurrency(offersResponse.data.currency);
      }
      this.setOffers(offers);
      this.setTotalOffers(offers.length);
      this.setRecommendationsHotelCount(offersResponse.data.propertiesCount);
      this.setOffersVisible(10);
      this.setSearchFreezed(false);
    } catch (error) {
      if (!isCancelError(error)) {
        this.setServerErrors(error);
        this.setShowError(true);
      }
      this.setLoading(false);
    }
  }

  @Action
  public async stepLoading(searchId) {
    if (this.searchId !== searchId) {
      return;
    }

    try {
      this.createCancelTokenIfNeeded();

      let searchCompleted = this.searchCompleted;
      const response = await AccommodationSearchApi.getSearchState(searchId, this.stateId || undefined, this.cancelToken || undefined);

      if (this.searchId !== searchId) {
        return;
      }

      searchCompleted = response.data.searchState === SearchState.Completed;

      this.setProvidersErrors(response.data.providersErrors);

      if (response.data.newResultsAvailable) {
        this.updateSearchStateId(response.data.stateId);
        await this.search(searchId);
        if (this.searchId !== searchId) {
          return;
        }
      }

      if (searchCompleted) {
        this.finishLoading();
        this.setSearchCompleted(true);
        this.setLoading(false);
        return;
      }

      if (this.searchId !== searchId) {
        return;
      }

      this.clearSearchTimeout();
      this.setSearchTimeout(() => {
        this.stepLoading(searchId);
      });
    } catch (error) {
      if (!isCancelError(error)) {
        this.setServerErrors(error);
        this.setShowError(true);
      }
      this.setLoading(false);
    }
  }

  @Action
  async loadProperties(searchId: string) {
    if (this.searchId !== searchId) {
      return;
    }
    this.setShowError(false);
    try {
      this.createCancelTokenIfNeeded();
      
      const sortQuery = await this.buildSortQuery();
      const offersResponse = await AccommodationSearchApi
        .getProperties({
          searchId, sortQuery
        }, this.cancelToken || undefined);
      const offers = offersResponse.data.properties;

      if (offersResponse.data.filtersStatistics !== null && !this.filtersChanging) {
        this.setSearchFilters(offersResponse.data.filtersStatistics);
      }
      
      if (offersResponse.data && offersResponse.data.currency) {
        this.setCurrency(offersResponse.data.currency);
      }
      this.setOffers(offers);
      this.setTotalOffers(offers.length);
      this.setRecommendationsHotelCount(offersResponse.data.propertiesCount);
      this.setOffersVisible(10);
      this.setSearchFreezed(false);
      EventBus.$emit('hotel-filters-reloaded');
    } catch (error) {
      if (!isCancelError(error)) {
        this.setServerErrors(error);
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateAgencyMarkup(searchId) {
    AccommodationSearchApi.setAgencyMarkupFilters(searchId, {
      agencyMarkup: {
        amount: this.agencyMarkup,
        currency: {
          code: 'EUR'
        }
      }
    });
  }

  @Action
  async loadPropertyOffers({ searchId, propertyId }): Promise<AccommodationOfferSearchResult> {
    let data;
    this.setLoadingPropertyOffers(propertyId);
    try {
      const response = await AccommodationSearchApi.getOffers(searchId, propertyId);
      data = response.data;
    } catch (error) {
      this.setServerErrors(error);
      this.setShowError(true);
      this.setLoading(false);
    } finally {
      this.setLoadingPropertyOffers(null);
    }

    return data;
  }

  @Action
  public getOffers(searchId) {
    if (this.searchCompleted) {
      return this.loadProperties(searchId);
    }
    this.setShowError(false);
    this.clearSearchTimeout();
    this.setupSearchLoaded(searchId);
    this.setLoading(true);
    this.updateSearchStateId('');
    this.stepLoading(searchId);
  }

  @Action
  public stopSearch() {
    this.setSearchInProgress(false);
    this.setSearchId('');
    this.finishLoading();
    clearInterval(this.scrollTimeout);
    this.clearSearchTimeout();
    this.setLoading(false);
  }

  @Action
  async getMapProperties(searchId: string) {
    try {
      this.setMapPropertiesLoading(true);
      const response = await AccommodationSearchApi.getMapProperties(searchId);
      if (response && response.data) {
        this.setSearchFilters(response.data.filtersStatistics);
        this.setMapProperties(response.data.properties);
        this.setRecommendationsHotelCount(response.data.propertiesCount);
        this.setCurrency(response.data.currency);
      }
    } catch (error) {
      this.setServerErrors(error);
      this.setShowError(true);
      this.setLoading(false);
    } finally {
      this.setMapPropertiesLoading(false);
    }
  }

  @Action
  updateMapPropertiesData(data: MapPropertiesResponse) {
    this.setSearchFilters(data.filtersStatistics);
    this.setMapProperties(data.properties);
    this.setRecommendationsHotelCount(data.propertiesCount);
    this.setCurrency(data.currency);
  }

  @Action
  buildSortQuery() {
    const data: String[] = [];
    const paramName = 'sortOptions';
    let i = 0;
    for (let key in this.sortOptions) {
      if (this.sortOptions.hasOwnProperty(key)) {
        let urlParam = `${paramName}[${i}].Code=${key}`;
        data.push(urlParam);
        urlParam = `${paramName}[${i}].Direction=${this.sortOptions[key]}`;
        data.push(urlParam);

        i++;
      }
    }

    const result = data.join('&');

    return result;
  }

  @Action
  async selectOffer({ offer, property }) {
    this.setSelectingOffer(true);
    this.setSelectedOfferId(offer.id);
    try {
      const result = await BasketItemApi.addItemAndCreateTrip({
        type: 'Accommodation',
        searchId: router.currentRoute.params.searchId,
        selectionId: offer.id,
        bookerId: AccountStore.CurrentUser!.profile.id,
      });
      SearchStore.selectBasket(result.data.tripId);
      router.push({
        name: 'basket',
        params: {
          id: result.data.tripId,
        },
      });
      this.stopSearch();
      this.setSelectingOffer(false);
      this.setSelectedOfferId('');
    } catch (error) {
      this.setShowError(true);
      this.setLoading(false);
      this.setSelectingOffer(false);
      this.setSelectedOfferId('');
      if (error && error.response && error.response.status === 404) {
        this.setServerErrors(error, true);
        router.push({
          name: 'hotelResultsSearch',
          params: router.currentRoute.params,
        });
      } else {
        this.setServerErrors(error);
      }
    }
  }

  @Action
  async updateFee({ feeValue, totalPrice }) {
    let errors: any[] = [];
    try {
      await AccommodationFeesApi.updateSearchOfferFee(this.searchId, this.editedFeeOffer.id, {
        amount: feeValue,
        currency: {
          code: this.editedFeeOffer.agencyFee.currency.code,
          symbol: this.editedFeeOffer.agencyFee.currency.symbol,
        },
      });

      EventBus.$emit('offer-fee-edited', {
        offerId: this.editedFeeOffer.id,
        feeValue,
        totalPrice,
      });
    } catch (error) {
      errors = $handleErrors(error, true);
    }

    return {
      errors,
    };
  }

  @Action
  async updateAgencyMarkupInOffer({ agencyMarkupValue, totalPrice }) {
    let errors: any[] = [];
    try {
      await AccommodationFeesApi.updateSearchOfferAgencyMarkup(
        this.searchId,
        this.editedFeeOffer.id,
        {
          amount: agencyMarkupValue,
          currency: {
            code: this.editedFeeOffer.agencyMarkup.currency.code,
            symbol: this.editedFeeOffer.agencyMarkup.currency.symbol,
          },
        }
      );

      EventBus.$emit('offer-agency-markup-edited', {
        offerId: this.editedFeeOffer.id,
        agencyMarkupValue,
        totalPrice,
      });
    } catch (error) {
      errors = $handleErrors(error, true);
    }

    return {
      errors,
    };
  }


}

export default getModule(HotelSearchStore);
