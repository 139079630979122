



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class HotelRoomName extends Vue {
  @Prop() roomType!: string;
  @Prop() roomStandard!: string;

  withEllipsis: boolean = true;

  mounted() {
    const wrapper = this.$refs.wrapper as HTMLElement;
    const content = this.$refs.content as HTMLElement;

    if (content.offsetWidth < wrapper.offsetWidth) {
      this.withEllipsis = false;
    }
  }
}
