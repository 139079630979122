













































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { CustomFieldDictionariesApi } from '@/api/custom-fields/custom-fields-dictionary.api';
import { DictionaryModel } from '@/api/custom-fields/custom-fields.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class CustomFieldDictionaries extends BaseTable<DictionaryModel> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
    sortDesc: false,
    sortCompareOptions: '{sensitivity: \'base\'}'
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: DictionaryModel | null = null;
  selectedIndex: number = -1;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-custom-fields.cf-dictionary-name'),
    },
    description: {
      sortable: false,
      label: translate('settings-custom-fields.description')
    },
    active: {
      sortable: false,
      label: translate('settings-custom-fields.active')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get entries() {
    return CustomFieldsStore.dictionaries;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get count() {
    return this.entries.length;
  }

  get loadingPage() {
    return CustomFieldsStore.loading;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  toggleMenu(row) {
    if (!this.menuActive || (this.selected !== row.item)) {
      this.selected = row.item;
      this.selectedIndex = row.index;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    CustomFieldsStore.setDictionaryData(item);
    let params: any = {
      id: router.currentRoute.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'dictionary-definition',
      params: params,
    });
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeDictionary() {
    try {
      this.loading = true;
      await CustomFieldDictionariesApi.deleteCustomFieldDictionary(this.selected!.id);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.configuration-removed'),
        message: translate('settings-gds.info-removed')
      };

      EventHandler.$emit('show-toast', obj);
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    } finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
        CustomFieldsStore.getConfigurationCustomFieldDictionaries({ companyId: this.currentCompany!.rootCompanyId, configurationId: router.currentRoute.params.configurationId });
      }
      this.loading = false;
    }
  }
}
