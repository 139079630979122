






































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import TrainSearchStore from './train-search.store';
import SearchStore from '@/modules/search/search.store';
import { Proposal, Train } from '@/api/train-engine/train-search.model';
import TrainFareConditions from './TrainFareConditions.vue';
import TrainFareDiscount from './TrainFareDiscount.vue';
import { translate } from '@/i18n';
import TrainProposalFareName from './TrainProposalFareName.vue';
import TrainPriceDetails from './TrainPriceDetails.vue';
import formatPrice from '@/filters/format-price.filter';
import EventBus from '@/services/event-handler';
import { TravellerModel } from '@/api/home/home.model';

const passengerTypeMap = [
  {
    code: 'ADT',
    travellerType: 'profile-personal.adult'
  },
  {
    code: 'CHD',
    travellerType: 'profile-personal.child',
  },
  {
    code: 'INF',
    travellerType: 'search.infant',
  }
];

@Component({
  components: {
    TrainFareConditions,
    TrainProposalFareName,
    TrainFareDiscount,
    TrainPriceDetails,
  },
})
export default class TrainProposal extends Vue {
  @Prop() provider!: any;
  @Prop() train!: Train;
  @Prop() proposal!: Proposal;
  @Prop() isInSearch!: boolean;
  @Prop() offer: any;
  @Prop() disabled!: boolean;
  @Prop() supplier!: string;

  showFullConditionsPopup: boolean = false;
  showDiscountInfoPopup: boolean = false;
  disabledInfo: string = translate('search-air.max-selected');
  prepareOfferCheck: boolean = false;
  isPriceChanging: boolean = false;
  showPriceDetailsPopup: boolean = false;


  get isAirRailRoundTrip() {
    return SearchStore.airRailSearchCurrentState && SearchStore.airRailSearchCurrentState.searchMode === 'RoundTrip';
  }

  get trainProposal() {
    return {
      ...this.proposal,
      segmentKey: this.train.segmentKey,
    };
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get shouldBeLoading() {
    return this.offer.id === TrainSearchStore.selectedOfferId;
  }

  get searchId() {
    if (this.offer.sourceSearchId) {
      return this.offer.sourceSearchId;
    }
    return this.$route.params.searchId;
  }

  get searchMode() {
    return -1 < [
      'airRail',
      'airRailDetails',
      'airRailTravellers',
      'train',
      'trainModification',
      'trainDetails',
      'trainResultDetailsView',
      'trainModificationDetailsView',
    ].indexOf(this.$route.name || '');
  }

  get canShowEditFee() {
    return 'trainModification' !== this.$route.name;
  }

  get canShowPriceDetails() {
    return this.$canSeePrices() && this.$hasAccess('CanSeePriceDetails');
  }

  userFullName(user) {
    return userFullName(user);
  }

  priceTooltip() {
    let tooltip = '';

    if (this.proposal.rawProviderPrice) {
      tooltip += `<div>${translate('basket.raw-provider-price')} ${this.proposal.rawProviderPrice.currency.symbol || this.proposal.rawProviderPrice.currency.code} ${formatPrice(this.proposal.rawProviderPrice.amount)}</div>`;
    }
    if (this.proposal.systemMarkup) {
      tooltip += `<div class="mb-3">${translate('basket.system-markup')} ${this.proposal.systemMarkup.currency.symbol || this.proposal.systemMarkup.currency.code} ${formatPrice(this.proposal.systemMarkup.amount)}</div>`;
    }
    if (this.proposal.basePrice) {
      tooltip += `<div>${translate('basket.incl')} ${this.proposal.basePrice.currency.symbol || this.proposal.basePrice.currency.code} ${formatPrice(this.proposal.basePrice.amount)} ${translate('search-air.provider-price')}`;

      tooltip += `</div>`;
    }

    if (this.proposal.agencyMarkup) {
      tooltip += `<div>${translate('basket.incl')} ${this.proposal.agencyMarkup.currency.symbol || this.proposal.agencyMarkup.currency.code} ${formatPrice(this.proposal.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</div>`;
    }
    if (this.proposal.agencyFee !== null) {
      tooltip += `<div>${translate('basket.incl')} ${this.proposal.agencyFee.currency.symbol || this.proposal.agencyFee.currency.code} ${formatPrice(this.proposal.agencyFee.amount)} ${translate('search-air.fee')}</div>`;
    }
    return tooltip;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  getTravellerDate(passengers) {
    if (!passengers) {
      return [];
    }
    const groupedTravellers = Object.values(this.travellersSearchList.travellers.reduce((acc, obj: TravellerModel) => {
    
    const passenger = passengers.find((item) => item.travellerId === obj.id);
    const passengerFareNames = passenger.fareNames.join('');
    
    if (obj.isVirtual && obj.passengerTypeCode) {
      const key = `${obj.passengerTypeCode}-${passengerFareNames}`;
      const { count = 0 } = acc[key] || {};
      return {
        ...acc,
        [key]: {
          ...obj,
          ...passenger,
          count: count + 1,
          firstName: translate(`passenger-types.${obj.passengerTypeCode.toLowerCase()}`),
        },
      };
    }
    return {
      ...acc,
      [obj.id]: {
        ...obj,
        ...passenger,
      },
    };
  }, {} as {
    count?: number,
  } & TravellerModel));
  return groupedTravellers;
}


  getTravellerType(code) {
    let typeFound = passengerTypeMap.find(e => {
      return e.code === code;
    });
    return typeFound ? translate(typeFound.travellerType) : '';
  }

  selectedProposal() {
    this.proposal.isSelected = !this.proposal.isSelected;
    this.selectProposal();
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  showDiscountInfo() {
    this.showDiscountInfoPopup = true;
  }

  showPriceDetails() {
    this.showPriceDetailsPopup = true;
  }

  editBookingFee() {
    TrainSearchStore.setEditedOffer({
      offer: this.offer,
      proposal: this.proposal,
    });
    EventBus.$emit('show-train-edit-fee-popup');
  }

  editAgencyMarkup() {
    TrainSearchStore.setEditedOffer({
      offer: this.offer,
      proposal: this.proposal,
    });
    EventBus.$emit('show-train-edit-agency-markup-popup');
  }

  selectProposal() {
    if (this.proposal) {
      this.$emit('selected-proposal', this.proposal);
    }
  }

  @Watch('proposal.totalPrice', { deep: true })
  onPriceChange() {
    this.isPriceChanging = true;
    this.$nextTick(() => {
      this.isPriceChanging = false;
    });
  }
}
