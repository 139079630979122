import AirSearchShortParams from '@/modules/search/air/AirSearchShortParams.vue';
import AirFiltersSubintro from '@/modules/search/air/AirFiltersSubintro.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/air-modification-results-filters/:searchId/basket/:basketId',
  name: 'airModificationFilters',
  components: {
    default: AirResultsFilters,
    intro: AirSearchShortParams,
    subintro: AirFiltersSubintro,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    mobile: true,
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
      },
      subintro: {
        air: AnimationName.UP,
      },
    }
  }
};
