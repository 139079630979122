import FeeRuleRail from '@/modules/settings/agency-fees/FeeRuleRail.vue';

export default {
  path: ':configurationId?/rail-fee-rule',
  name: 'fee-rule-rail',
  component: FeeRuleRail,
  displayName: 'Rail fee rule definition',
  meta: {
    breadCrumb: 'Rail fee rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    parentName: 'fees',
    depthCategory: 'fees',
    depth: 3,
  }
};