var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.splitBookingPermission)?_c('div',{staticClass:"show-hint-box w-30",on:{"mouseover":_vm.onMouseOver,"mouseleave":function($event){_vm.showSplitHint = false}}},[_c('UiLabel',{attrs:{"type":"checkbox","inline":true}},[_c('template',{slot:"label"},[_vm._v("\n      "+_vm._s(_vm.$t("search-air.split-booking"))+"\n    ")]),_c('UiCheckbox',{attrs:{"seleniumId":"Search_SplitBooking"},model:{value:(_vm.airSearchParameters.isSplitSearchEnabled),callback:function ($$v) {_vm.$set(_vm.airSearchParameters, "isSplitSearchEnabled", $$v)},expression:"airSearchParameters.isSplitSearchEnabled"}})],2),(_vm.showSplitHint)?_c('div',{staticClass:"ui-class-select__class-box show-hint-box__box"},[(_vm.loaderSplitProvidersForCompany)?_c('div',{staticClass:"ui-class-select__class-box-loader"},[_c('img',{ref:"image",staticClass:"sort-section__loader",attrs:{"src":_vm.imagesConst,"alt":_vm.$t('basket.loading')}})]):_vm._e(),(_vm.errSplitProvidersForCompanyMessages &&
      _vm.errSplitProvidersForCompanyMessages.length === 0 && !_vm.permissionErrSplitProvidersForCompanyMessages)?_c('div',[(
          !_vm.loaderSplitProvidersForCompany &&
          _vm.splitProvidersForCompany &&
          _vm.splitProvidersForCompany.length
        )?_c('div',{staticClass:"ui-class-select__class-box-selection-wrapper show-hint-box__wrapper"},[_c('div',{staticClass:"ui-class-select__class-box-selection-text show-hint-box__wrapper-text"},[_vm._v("\n          "+_vm._s(_vm.$t("search-air.split-booking-info-1"))+"\n        ")]),_c('ul',{staticClass:"show-hint-box__wrapper-text"},_vm._l((_vm.splitProvidersForCompany),function(provider){return _c('li',{key:provider},[_vm._v("\n            "+_vm._s(_vm.getSupplierName(provider))+"\n          ")])}),0)]):_vm._e(),(
          !_vm.loaderSplitProvidersForCompany &&
          _vm.splitProvidersForCompany &&
          _vm.splitProvidersForCompany.length === 0
        )?_c('div',{staticClass:"ui-class-select__class-box-selection-wrapper show-hint-box__wrapper"},[_c('div',{staticClass:"ui-class-select__class-box-selection-text show-hint-box__wrapper-text"},[_vm._v("\n          "+_vm._s(_vm.$t("search-air.split-booking-no-providers"))+"\n        ")])]):_vm._e()]):_vm._e(),(
        _vm.errSplitProvidersForCompanyMessages &&
        _vm.errSplitProvidersForCompanyMessages.length
      )?_c('div',[_c('UiErrors',{attrs:{"errors":_vm.errSplitProvidersForCompanyMessages}})],1):_vm._e(),(_vm.permissionErrSplitProvidersForCompanyMessages)?_c('div',{staticClass:"ui-class-select__class-box-selection-text show-hint-box__wrapper-text"},[_vm._v("\n      "+_vm._s(_vm.$t('search-air.split-booking-no-permission'))+"\n    ")]):_vm._e()]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }